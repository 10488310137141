import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CInput,
  CLabel,
  CSelect,
  CCollapse,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { fetchPatients, deletePatient } from "actions/patients/patients";
import { LIMIT } from "constants/constants";
import { fetchCountries, fetchCities } from "actions/cities/cities";
import { RenderSelectOptions } from "./Users";
import CIcon from "@coreui/icons-react";
import { cilPencil, cilSearch, cilTrash } from "@coreui/icons";
import i18next from "i18next";

const Patients = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [filters, setFilters] = useState({});
  const [collapse, setCollapse] = useState(false);
  const dispatch = useDispatch();
  const patients = useSelector((state) => state.patients.data);
  const meta = useSelector((state) => state.patients.meta);
  const countries = useSelector((state) =>
    state.countries ? state.countries.data : []
  );
  const cities = useSelector((state) =>
    state.cities.data ? state.cities.data : []
  );
  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/users/patient?page=${newPage}`);
    }
  };

  const handleDeletePatient = (id: string, e) => {
    e.stopPropagation();
    confirmAlert({
      title: "Are you sure?",
      message: "You Want To Delete This Patient.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deletePatient(id));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSearchBoxChange = (event) => {
    setFilters({ q: event.target.value });
    dispatch(fetchPatients(page, LIMIT, { ...filters, q: event.target.value }));
  };

  const handleCountryChange = (event) => {
    setFilters({ country: event.target.value });
    dispatch(
      fetchPatients(page, LIMIT, { ...filters, country: event.target.value })
    );
    dispatch(fetchCities(event.target.value));
  };

  const handleCityChange = (event) => {
    setFilters({ city: event.target.value });
    dispatch(
      fetchPatients(page, LIMIT, { ...filters, city: event.target.value })
    );
  };

  const handleGenderChange = (event) => {
    setFilters({ gender: event.target.value });
    dispatch(
      fetchPatients(page, LIMIT, { ...filters, gender: event.target.value })
    );
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchPatients(page, LIMIT));
    dispatch(fetchCountries());
  }, [dispatch, page]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">
            {i18next.t("users")}
          </div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            href="/users/create"
            className="create-button float-right wi"
          >
            New User
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-center align-items-center py-2">
                <CCol xs="12" md="6">
                  <div className="search-wrap">
                    <CInput
                      className="search-input"
                      type="text"
                      placeholder="Search"
                      onChange={handleSearchBoxChange}
                    />
                    <CIcon
                      content={cilSearch}
                      className="c-icon-xl search-icon"
                    ></CIcon>
                  </div>
                </CCol>
                <CCol xs="12" md="6">
                  <CButton
                    color
                    className="advanced-search float-right"
                    onClick={() => {
                      setCollapse(!collapse);
                    }}
                  >
                    Advanced Search
                  </CButton>
                </CCol>
              </CRow>
              <CCollapse show={collapse} className="my-3 p-3">
                <hr />
                <CRow className="justify-content-center">
                  <CCol xs="12" md="4">
                    <CLabel htmlFor="country" className="float-left">
                      Country
                    </CLabel>
                    <CSelect
                      custom
                      name="country"
                      id="country"
                      onChange={handleCountryChange}
                    >
                      <option key="0" value="" selected>
                        Select Country
                      </option>
                      {RenderSelectOptions(countries || [])}
                    </CSelect>
                  </CCol>
                  <CCol xs="12" md="4">
                    <CLabel htmlFor="city" className="float-left">
                      City
                    </CLabel>
                    <CSelect
                      custom
                      name="city"
                      id="city"
                      onChange={handleCityChange}
                    >
                      <option key="0" value="" selected>
                        Select City
                      </option>
                      {RenderSelectOptions(cities || [])}
                    </CSelect>
                  </CCol>
                  <CCol xs="12" md="4">
                    <CLabel htmlFor="city" className="float-left">
                      Gender
                    </CLabel>
                    <CSelect
                      custom
                      name="gender"
                      id="gender"
                      onChange={handleGenderChange}
                    >
                      <option key="0" value="" selected>
                        Select Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </CSelect>
                  </CCol>
                </CRow>
              </CCollapse>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={patients}
                noItemsViewSlot="No Available Items"
                fields={[
                  "avatar",
                  { key: "first_name", label: "First Name" },
                  "last_name",
                  { key: "email", label: "Email" },
                  { key: "ID", label: "Personal Id" },
                  { key: "patientNumber", label: "Phone" },
                  "gender",
                  {key: "actions", label: i18next.t("Actions")}
                ]}
                hover
                striped
                clickableRows
                scopedSlots={{
                  actions: (item) => (
                    <td>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle
                          className="action-toggle m-0"
                          color="light"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="c-icon"
                            role="img"
                          >
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                              class="ci-primary"
                            ></path>
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                              class="ci-primary"
                            ></path>
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                              class="ci-primary"
                            ></path>
                          </svg>
                        </CDropdownToggle>
                        <CDropdownMenu className="p-0">
                          <CDropdownItem
                            href={`/users/${item.id}/edit`}
                            className="text-primary"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <CIcon content={cilPencil} />
                            <span class="mx-1">Edit</span>
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={(e) => handleDeletePatient(item.id, e)}
                            className="text-danger"
                          >
                            <CIcon content={cilTrash} />
                            <span class="mx-1">Delete</span>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                  avatar: (item) => (
                    <td style={{ width: "12%" }}>
                      <img
                        style={{ width: "75px" }}
                        alt="NA"
                        src={item.avatar ? item.avatar : "/avatars/avatar.png"}
                        onError={(e) => {
                          e.target.src = "/avatars/avatar.png"; // eslint-disable-line no-param-reassign
                        }}
                        className="mb-2 avatar-img"
                      />
                    </td>
                  ),
                  ID: (item) => <td>{item.uuid}</td>,
                  patientNumber: (item) => (
                    <td>{item.phone ? item.phone : "No Available Data"}</td>
                  ),
                }}
                onRowClick={(row) => {
                  history.push(`/users/${row.id}/view`);
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Patients;
