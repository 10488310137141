import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {fetchPublicSubmissions, createPublicSubmissions} from "actions/specialties-form/specialtiesForm";
import { listSpecialtyForms } from "actions/specialties-form/specialtiesForm";
import AnswerForm from "../form/AnswerForm";
import { LIMIT } from "constants/constants";
import {FETCH_SPECIALTIES_FORM} from "actions/specialties-form/types"
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const AnswerPublicSurveys = ({ toggle, modal, type, hospital, patient }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const utilities = useSelector((state) => state.specialtiesFormUtilities);
  const authId = useSelector((state) => state.auth.jwt.user.id);
  const publicSurvey = useSelector((state) => state.publicSurveys);
  const specialtyForm = useSelector(
    (state) => state.specialtiesForm.specialtyForm
  );
  const specialtyFormsList = useSelector(
    (state) => state.specialtiesForm.specialtyFormsList
  );
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      type: 'general',
      model_id: patient ? patient.id : formData.patient.id,
      patient_id: patient? patient.id : formData.patient.id,
      model_type: "patient",
      creator_id: authId,
      survey_id: specialtyForm ? specialtyForm.id : 0,
    };
    if (data.draft != 2) {
      confirmAlert({
        title: "Are you sure?",
        message: "that form is ready to be submitted?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(createPublicSubmissions(data));
              toggle();
              dispatch(
                fetchPublicSubmissions(1, LIMIT, { patientId: data.patient_id })
              );
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      dispatch(createPublicSubmissions(data));
      toggle();
      if(patient){
        dispatch(fetchPublicSubmissions(1, LIMIT,  { patientId: patient.id }));
      }else{
        dispatch(fetchPublicSubmissions(1, LIMIT));

      }
    }
  };
  const onCancel = () => {
    toggle();
    if(patient){
      dispatch(fetchPublicSubmissions(1, LIMIT, { patientId: patient.id }));
    }else{
      dispatch(fetchPublicSubmissions(1, LIMIT));
    }
  };

  const onSpecialtyFormChange = (event) => {
    const selectedForm = specialtyFormsList.find(item => item.id == event.target.value);
    dispatch({ type: FETCH_SPECIALTIES_FORM, payload: {"data": selectedForm} });
  };
  const onSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty: event.target.value });
    getSpecialtyForm({ ...filters, specialty: event.target.value });

  };
  const onLocationChange = (event) => {
    setFilters({ ...filters, location: event.target.value });
    getSpecialtyForm({ ...filters, location: event.target.value });
  };
  const getSpecialtyForm = (filters) => {
    if(!filters.location){
      filters.location = hospital.id;
    }
    if (filters.location && filters.specialty) {
      dispatch(listSpecialtyForms(filters.location, filters.specialty));
    }
    else {
      //reset
    }
  };

  return (
    <AnswerForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal || 0}
      specialties={utilities.specialties || []}
      hospitals={utilities.hospitals || []}
      specialtyForm={specialtyForm}
      specialtyFormsList={specialtyFormsList}
      onSpecialtyFormChange={onSpecialtyFormChange}
      onSpecialtyChange={onSpecialtyChange}
      onLocationChange={onLocationChange}
      FormTitle="Survey"
      type={type}
      key={`create_${type}`}
      hospital={hospital}
      patient={patient}

    />
  );
};
export default AnswerPublicSurveys;
