import React from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CInputFile,
  CSpinner,
} from "@coreui/react";
import { useHistory, useLocation } from "react-router-dom";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import i18next from "i18next";

const Document = ({ onSubmit }) => {
  const { register, handleSubmit, watch, errors, formState } = useForm({
    mode: "onBlur",
  });

  const Validation = getValidation(watch);
  const history = useHistory();
  const location = useLocation();
  const { isSubmitting } = formState;
  const onCancel = () => {
    history.push(`${location.pathname.split("/").slice(0, -1).join("/")}`);
  };

  return (
    <CRow>
      <CCol xs="12">
        <CForm
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          className="form-horizontal"
          encType="multipart/form-data"
        >
          <CCard>
            <CCardHeader>
              <h4 className="header-label-title">{i18next.t("Create New Document")}</h4>
            </CCardHeader>
            <CCardBody>
              <CFormGroup row>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel htmlFor="name" className="float-left required labels">
                    {i18next.t("Name")}
                  </CLabel>
                  <CInput
                    className={`${getClassName("name", "name", watch, errors)}`}
                    type="text"
                    id="name"
                    name="name"
                    innerRef={register(Validation.name)}
                    placeholder={i18next.t("Enter Document Name")}
                  />
                </CCol>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel
                    htmlFor="document"
                    className="float-left required labels"
                  >
                    {i18next.t("Document")}
                  </CLabel>
                  <CInputFile
                    className={`${getClassName(
                      "document",
                      "document",
                      watch,
                      errors
                    )}`}
                    style={{ top: "0" }}
                    id="custom-file-input"
                    name="document"
                    innerRef={register(Validation.file)}
                  />
                  <CLabel
                    style={{
                      top: "1em",
                      right: "1em",
                      left: "1em",
                      marginTop: ".5em",
                    }}
                    className={`${getClassName(
                      "document",
                      "document",
                      watch,
                      errors
                    )}`}
                    htmlFor="custom-file-input"
                    variant="custom-file"
                  >
                    {(watch("document") &&
                      watch("document")[0] &&
                      watch("document")[0].name) ||
                      "Choose file..."}
                  </CLabel>
                  {errors.document?.message ? (
                    <strong style={{ color: "red" }}>
                      {i18next.t("The Document must be a file of type: jpeg, jpg, png, pdf.")}
                    </strong>
                  ) : (
                    <strong>
                      {i18next.t("The Document must be a file of type: jpeg, jpg, png, pdf.")}
                    </strong>
                  )}
                </CCol>
              </CFormGroup>
            </CCardBody>
            <CCardFooter>
              <CFormGroup row>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    type="submit"
                    color="primary"
                    className="mx-1 btn-form"
                    disabled={isSubmitting}
                    >
                 {isSubmitting ? (
                      <>
                        <CSpinner color="primary" size="sm" variant="grow" />
                        <span style={{ color: "dark" }}>{i18next.t("Loading")}...</span>
                      </>
                    ) : (
                      <span>{i18next.t("Save")}</span>
                    )}
                  </CButton>
                </CCol>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  );
};

export default Document;
