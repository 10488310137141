import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { fetchLevel } from "actions/levels/levels";
import i18next from "i18next";

const Level = ({ match }) => {
  const dispatch = useDispatch();
  const level = useSelector((state) => state.levels.level);

  useEffect(() => {
    dispatch(fetchLevel(match.params.id));
  }, [dispatch, match.params.id]);

  const renderLevel = () => {
    if (level) {
      return (
        <table className="table table-striped table-hover">
          <tbody>
            <tr>
              <td className="labels">{i18next.t("Level Name")}</td>
              <td>
                <strong>{level.name}</strong>
              </td>
            </tr>
            <tr>
              <td className="labels">{i18next.t("Desciption")}</td>
              <td>
                <strong>
                  {level.description ? level.description : i18next.t("No Available Data")}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
    return (
      <h4 className="text-align-center not-found">
        <strong>{i18next.t("Not Found")}</strong>
      </h4>
    );
  };

  return (
    <CRow>
      <CCol lg={12}>
        <CCard>
          <h4 className="table-title">{i18next.t("Level Details")}</h4>
          <CCardBody>{renderLevel()}</CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Level;
