import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CPagination,
} from "@coreui/react";
import find from "lodash/find";
import { fetchVitalSign, fetchVitalSigns } from "actions/vital-signs/vitalSigns";
import CIcon from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";
import i18next from "i18next";


const List = ({ toggle, setIsEditing, setVitalSignId, episodeStatus }) => {
  const dispatch = useDispatch();
  const vitalSigns = useSelector((state) => state.vitalSigns);
  const utilities = useSelector((state) => state.vitalSignUtilities);
  const [page, setPage] = useState(1);
  const pageChange = (newpage) => {
    setPage(newpage)
    dispatch(fetchVitalSigns(vitalSigns.data[0].episode_id, newpage));
  }

  const fields = [
    { key: "type", label:  i18next.t("Type") },
    { key: "date", label:  i18next.t("date") },
    { key: "rate", label:  i18next.t("Value") },
    { key: "author", label:  i18next.t("Author") },
    { key: "actions", label:  i18next.t("Actions") },
    { key: "note", label:  i18next.t("Note") },
  ];
  const getVitalSignsListView = () => {
    if (vitalSigns.data) {
      return vitalSigns.data.map((item) => {
        const list = { ...item };
        const type = find(utilities.types, {
          id: parseInt(item.type_id, 10),
        });
        list.type = type ? type.title : item.type_id;

        return list;
      });
    }
    return [];
  };

  const EditVitalSign = (id: string) => {
    dispatch(fetchVitalSign(id));
    toggle();
    setIsEditing(true);
    setVitalSignId(id);
  };

  const renderActions = (item: Object) => (
    <>
      <td className="w-full">
        <CDropdown variant="btn-group">
          {item.editable && (
            <CDropdownToggle
              className="action-toggle m-0"
              color="light"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="c-icon"
                role="img"
              >
                <path
                  fill="var(--ci-primary-color, currentColor)"
                  d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                  className="ci-primary"
                />
                <path
                  fill="var(--ci-primary-color, currentColor)"
                  d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                  className="ci-primary"
                />
                <path
                  fill="var(--ci-primary-color, currentColor)"
                  d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                  className="ci-primary"
                />
              </svg>
            </CDropdownToggle>
          )}
          <CDropdownMenu className="p-0" placement="right-end">
            {item.editable && (
              <CDropdownItem
                onClick={() => EditVitalSign(item.id)}
                className="text-dark"
              >
                <CIcon content={cilPencil} />
                <span class="mx-1">{i18next.t("Edit")}</span>
              </CDropdownItem>
            )}
          </CDropdownMenu>
        </CDropdown>
      </td>
    </>
  );
  return (
    <>
      <CRow>
        <CCol xs={12} md={10}>
          <CCard className="border-0">
            <CCardBody>
              <CRow>
                <CCol xs={12} md={10}>
                  <CDataTable
                    items={getVitalSignsListView()}
                    fields={fields}
                    noItemsViewSlot={i18next.t("No Available Items")}
                    // striped
                    // itemsPerPage={5}
                    // pagination
                    scopedSlots={{
                      date: (item) => (
                        <td>{`${item.date ? item.date : "NA"}`}</td>
                      ),
                      author: (item) => (
                        <td>
                          {`${item.staff.first_name} ${item.staff.last_name}`}
                        </td>
                      ),
                      actions: episodeStatus === 0 ? renderActions : () => <td></td>,
                    }}
                  />
                  {episodeStatus === 0 ? (
                    <CLink
                      className="d-inline-block avh-btn-add py-3 text-decoration-none text-white avh-font-bold text-uppercase float-right"
                      style={{position:"relative", zIndex:150}}
                      onClick={toggle}
                    >
                      +
                    </CLink>
                  ) : (
                    ""
                  )}
                  <CPagination
                    activePage={page}
                    onActivePageChange={pageChange}
                    pages={vitalSigns.meta ? vitalSigns.meta.last_page : 1}
                    doubleArrows={false}
                    align="center"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
export default List;
