import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownItem,
  CDropdownMenu,
} from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  cancelAppointment,
  getAppointmentUtilities,
} from "actions/appointments/appointment";
import { fetchAppointments } from "actions/discharge-form/dischargeForm";
import { CREATE_FOLLOW_UP_PLAN } from "constants/constants";
import { cilOptions, cilXCircle } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const ListAppointments = ({ setActiveScreen }) => {
  const { episodeId } = useParams();
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.dischargeForm.appointments);
  const appointmentUtilities = useSelector(
    (state) => state.appointmentUtilities
  );
  const fields = [
    { key:"specialty", label:  i18next.t("Specialty") },
    { key:"doctor", label:  i18next.t("Doctor") },
    { key:"patient", label:  i18next.t("Patient") },
    { key:"schedule_time", label:  i18next.t("Schedule Time") },
    { key:"clinic", label:  i18next.t("Clinic") },
    { key:"status_text", label:  i18next.t("status") },
    { key:"actions", label:  i18next.t("Actions") },
  ];

  useEffect(() => {
    dispatch(getAppointmentUtilities());
    dispatch(fetchAppointments(episodeId));
  }, [dispatch, episodeId]);

  const onCancelAppointment = (id) => {
    dispatch(cancelAppointment(id));
  };

  const onCreateAppointment = () => {
    setActiveScreen(CREATE_FOLLOW_UP_PLAN);
  };

  const showActions = (item) => (
    <td className="py-2">
      {item.status !== appointmentUtilities.status_canceled && (
        <CDropdown variant="btn-group">
          <CDropdownToggle
            className="action-toggle m-0"
            color="light"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CIcon content={cilOptions} />
          </CDropdownToggle>
          <CDropdownMenu className="py-0" placement="right-end">
            <CDropdownItem
              onClick={() => onCancelAppointment(item.id)}
              className="text-danger"
              key={`sh-${item.id}`}
            >
              <CIcon content={cilXCircle}></CIcon>
              <span className="mx-1">{i18next.t("Cancel")}</span>
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      )}
    </td>
  );

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="table-title">{i18next.t("Follow Up Plans")}</div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CCardBody>
              <CDataTable
                items={appointments || []}
                noItemsViewSlot={i18next.t("No Available Items")}
                fields={fields}
                striped
                itemsPerPage={5}
                pagination
                scopedSlots={{
                  actions: showActions,
                  
                }}
              />
            </CCardBody>
          </CCard>
          <CLink
            className="d-inline-block avh-btn-add py-3 text-decoration-none text-white avh-font-bold text-uppercase float-right"
            onClick={() => onCreateAppointment()}
          >
            +
          </CLink>
        </CCol>
      </CRow>
    </>
  );
};

export default ListAppointments;
