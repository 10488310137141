import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createSpecialty } from "actions/specialties/specialties";
import SpecialtyForm from "../form/SpecialtyForm";
import i18next from "i18next";

/**
 * @param props
 */
function CreateSpecialty() {
  const history = useHistory();
  const label = `${i18next.t("Create New Specialty")}`;
  const dispatch = useDispatch();
  const isCreated = useSelector((state) => state.specialties.isCreated);

  const onSubmit = (formData) => {
    dispatch(createSpecialty(formData));
  };

  if (isCreated) {
    history.push("/specialties");
  }

  return <SpecialtyForm onSubmit={onSubmit} label={label} />;
}

export default CreateSpecialty;
