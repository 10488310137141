import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { LABS, RADS, TYPE_APPOINTMENT } from "constants/constants";
import LabOrders from "./LabOrders";
import RadOrders from "./RadOrders";
import Summary from "./Summary";
import { fetchInvestigations } from "actions/episodes/investigations";

const Screens = ({ appointment }) => {
  const dispatch = useDispatch();
  const [activeScreen, setActiveScreen] = useState(null);
  useEffect(() => {
    dispatch(fetchInvestigations(appointment.id, TYPE_APPOINTMENT));
  }, [dispatch, appointment]);

  switch (activeScreen) {
    case RADS:
      return (
        <RadOrders
          setActiveScreen={setActiveScreen}
        />
      );
    case LABS:
      return (
        <LabOrders
          setActiveScreen={setActiveScreen}
        />
      );

    default:
      return <Summary setActiveScreen={setActiveScreen} appointment={appointment} />;
  }
};
export default Screens;
