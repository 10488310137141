import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFollowUp } from "actions/follow-ups/followUps";
import EditFollowUpForm from "./form/EditForm";
import i18next from "i18next";

const EditFollowUp = ({ toggle, setIsEditing, modal, followUp }) => {
  const dispatch = useDispatch();
  const staffId = useSelector((state) => state.auth.jwt.user.id);
  const episode = useSelector((state) => state.episodes.episode);

  const onSubmit = (formData) => {
    const data = {
      ...formData,
      editor_id: staffId,
      patient_id: episode.patient_id,
    };
    dispatch(updateFollowUp(data, followUp.id));
    setIsEditing(false);
    toggle();
  };
  const onCancel = () => {
    toggle();
    setIsEditing(false);
  };

  return (
    <EditFollowUpForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal}
      followUp={followUp}
      FormTitle={i18next.t("Edit FollowUp")}
    />
  );
};
export default EditFollowUp;
