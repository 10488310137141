import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CForm,
  CCard,
  CCardBody,
  CCardHeader,
  CInput,
  CSelect,
  CCardFooter,
  CButton,
} from "@coreui/react";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import {
  VACATION_TYPE_DOCTOR,
  VACATION_TYPE_CLINIC,
  VACATION_TYPE_HOSPITAL,
  VACATION_TYPE_NATIONAL,
} from "constants/constants";
import {
  DoctorAsyncSelect,
  ClinicAsyncSelect,
  HospitalAsyncSelect,
  CountryAsyncSelect,
} from "views/partials/AsyncSelectComponents";

import permissions from "constants/permissions";
import { getValidation, getClassName } from "./VacationFormValidation";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

const VacationForm = ({ onSubmit, vacation, label }) => {
  const userPermissions = useSelector((state) =>
    state.auth.jwt ? state.auth.jwt.user.permissions : []
  );

  let defaultValues = {
    date_from: new Date(),
    date_to: new Date(),
  };
  if (vacation) {
    defaultValues = {
      ...vacation,
      doctor: vacation.doctor
        ? {
            ...vacation.doctor,
            name: `${vacation.doctor.first_name}  ${vacation.doctor.last_name}`,
          }
        : null,
      date_from: parseISO(vacation.date_from_original),
      date_to: parseISO(vacation.date_to_original),
      country: vacation.country
        ? {
            ...vacation.country,
            name: vacation.country.name,
          }
        : null,
    };
  }
  const { handleSubmit, register, control, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const Validation = getValidation(watch);

  const renderNationalVacationType = () => {
    if (userPermissions.indexOf(permissions.manageNationalVacations) !== -1) {
      return <option value={VACATION_TYPE_NATIONAL}>{i18next.t("National Vacation")}</option>;
    }
    return "";
  };
  const history = useHistory();
  const RenderTypesOptions = () => (
    <>
      <option value={VACATION_TYPE_DOCTOR}>{i18next.t("Doctor Vacation")}</option>
      <option value={VACATION_TYPE_CLINIC}>{i18next.t("Clinic Vacation")}</option>
      <option value={VACATION_TYPE_HOSPITAL}>{i18next.t("Hospital Vacation")}</option>
      {renderNationalVacationType()}
    </>
  );
  const onCancel = () => {
    history.push("/vacations");
  };
  const renderRelatedModelInput = () => {
    switch (Number(watch("type"))) {
      case VACATION_TYPE_DOCTOR:
        return (
          <DoctorAsyncSelect
            label={i18next.t("Doctor")}
            name="doctor"
            control={control}
            rules={Validation.doctor}
            isValid={!errors.doctor}
          />
        );
      case VACATION_TYPE_CLINIC:
        return (
          <ClinicAsyncSelect
            label={i18next.t("Clinic")}
            name="clinic"
            control={control}
            rules={Validation.clinic}
            isValid={!errors.clinic}
          />
        );
      case VACATION_TYPE_HOSPITAL:
        return (
          <HospitalAsyncSelect
            label={i18next.t("Hospital")}
            name="hospital"
            control={control}
            rules={Validation.hospital}
            isValid={!errors.hospital}
          />
        );
      case VACATION_TYPE_NATIONAL:
        return (
          <CountryAsyncSelect
            label={i18next.t("Country")}
            name="country"
            control={control}
            rules={Validation.country}
            isValid={!errors.country}
          />
        );
      default:
        return "";
    }
  };

  return (
    <>
      <CRow>
        <CCol xs="12">
          <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
            <CCard>
              <CCardHeader>
                <CCol xs="12">
                  <h4 className="header-label-title">{label}</h4>
                </CCol>
              </CCardHeader>
              <CCardBody>
                <CFormGroup row>
                  <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CLabel className="labels required" htmlFor="type">
                      {i18next.t("Type")}
                    </CLabel>
                    <CSelect
                      className={getClassName("type", "type", watch, errors)}
                      id="type"
                      name="type"
                      innerRef={register(Validation.type)}
                    >
                      <option key="0" value={null} selected disabled>
                        {i18next.t("Select Type")}
                      </option>
                      {RenderTypesOptions()}
                    </CSelect>
                  </CCol>
                  <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                    {renderRelatedModelInput()}
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CLabel className="labels required" htmlFor="title">
                      {i18next.t("Title")}
                    </CLabel>
                    <CInput
                      className={getClassName("title", "title", watch, errors)}
                      type="text"
                      id="title"
                      name="title"
                      innerRef={register(Validation.title)}
                    />
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                    <CLabel className="labels required" htmlFor="date_from">
                      {i18next.t("From")}
                    </CLabel>
                    <br />
 <Controller
                      control={control}
                      rules={Validation.date_from}
                      errors={errors.date_from}

                      id="date_from"
                      name="date_from"
                      valueName="selected"
                      render={(props) => (
                        <DatePicker
                          minDate={new Date()}
                          maxDate={watch("date_to")}
                          className={`form-control ${getClassName(
                            "date_from",
                            "date_from",
                            watch,
                            errors
                          )}`}
                          onChange={(e) => props.onChange(e)}
                          selected={props.value}
                          dateFormat="dd/MM/yyyy"
                          />
                      )}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                    <CLabel htmlFor="date_to" className="labels required">
                      {i18next.t("To")}
                    </CLabel>
                    <br />
                  
                   <Controller
                      control={control}
                      rules={Validation.date_to}
                      errors={errors.date_to}
                      id="date_to"
                      name="date_to"
                      valueName="selected"
                      render={(props) => (
                        <DatePicker
                          minDate={watch("date_from")}
                          className={`form-control ${getClassName(
                            "date_to",
                            "date_to",
                            watch,
                            errors
                          )}`}
                          onChange={(e) => props.onChange(e)}
                          selected={props.value}
                          dateFormat="dd/MM/yyyy"
                          />
                      )}
                    />
                  </CCol>
                </CFormGroup>
              </CCardBody>
              <CCardFooter>
                <CFormGroup row>
                  <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CButton
                      type="submit"
                      color="primary"
                      className="mx-1 btn-form"
                    >
                      {i18next.t("Save")}
                    </CButton>
                  </CCol>
                  <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CButton
                      color="danger"
                      type="reset"
                      onClick={onCancel}
                      className="mx-1 btn-form"
                    >
                      {i18next.t("Cancel")}
                    </CButton>
                  </CCol>
                </CFormGroup>
              </CCardFooter>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </>
  );
};

export default VacationForm;
