import { Api, getTokenHeader } from "services/Api";
import {
  CREATE_SPECIALTY,
  FETCH_SPECIALTY,
  UPDATE_SPECIALTY,
  FETCH_SPECIALTIES,
  DELETE_SPECIALTY,
} from "./types";

export const createSpecialty = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(`/api/admin/specialties`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_SPECIALTY, payload: response.data });
};

export const fetchSpecialty = (id: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/specialty/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_SPECIALTY, payload: response.data });
};

export const updateSpecialty = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(`/api/admin/specialties/${id}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_SPECIALTY, payload: response.data });
};

export const fetchSpecialties = (page: string, limit: string): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/specialties?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_SPECIALTIES, payload: response.data });
};

export const deleteSpecialty = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/specialties/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_SPECIALTY, payload: id });
};
