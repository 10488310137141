import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchSpecialty,
  updateSpecialty,
} from "actions/specialties/specialties";
import SpecialtyForm from "../form/SpecialtyForm";
import Loading from "views/partials/Loading";
import i18next from "i18next";

const EditSpecialty = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const specialty = useSelector((state) => state.specialties.specialty);
  const isUpdated = useSelector((state) => state.specialties.isUpdated);
  const label = `${i18next.t("Edit specialty")}`;

  const onSubmit = (formData) => {
    dispatch(updateSpecialty(formData, match.params.id));
  };

  if (isUpdated) {
    history.push("/specialties");
  }

  useEffect(() => {
    dispatch(fetchSpecialty(match.params.id));
  }, [dispatch, match.params.id]);

  if (specialty) {
    return (
      <SpecialtyForm onSubmit={onSubmit} label={label} specialty={specialty} />
    );
  }
  return <Loading/>
};

export default EditSpecialty;
