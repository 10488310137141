import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createAppointment,
  getAppointmentUtilities,
  fetchSpecialtyDoctors,
  fetchDoctorClinics,
  fetchClinicSlots,
} from "actions/appointments/appointment";
import { FOLLOW_UP_PLANS } from "constants/constants";
import AppointmentForm from "../form/AppointmentForm";

/**
 * @param {*} props required props
 * @returns {*}  appointment form component
 * @class
 */
function CreateAppointment({ episode, setActiveScreen }) {
  const dispatch = useDispatch();
  const appointmentUtilities = useSelector(
    (state) => state.appointmentUtilities
  );
  const {
    specialties,
    clinic_type_virtual,
    doctors,
    doctors_error,
    clinics,
    clinics_error,
    slots,
    slots_error,
  } = appointmentUtilities;

  useEffect(() => {
    dispatch(getAppointmentUtilities());
  }, [dispatch]);

  const onSubmit = (formData) => {
    const preparedData = {
      ...formData,
      patient_id: episode.patient_id,
      episode_id: episode.id,
    };
    dispatch(createAppointment(preparedData));
    setActiveScreen(FOLLOW_UP_PLANS);
  };

  const onCancel = () => {
    setActiveScreen(FOLLOW_UP_PLANS);
  };

  const onSelectSpecialty = (specialtyId, date) => {
    dispatch(fetchSpecialtyDoctors(specialtyId, {date}));
  };

  const onSelectDoctor = (doctorId, date) => {
    dispatch(fetchDoctorClinics(doctorId, date));
  };

  const onSelectClinic = (clinicId, date) => {
    dispatch(fetchClinicSlots(clinicId, date));
  };
  return (
    <AppointmentForm
      onSubmit={onSubmit}
      onSelectSpecialty={onSelectSpecialty}
      onSelectDoctor={onSelectDoctor}
      onSelectClinic={onSelectClinic}
      specialties={specialties || []}
      doctors={doctors ? doctors.data : []}
      clinics={clinics || []}
      slots={slots || []}
      clinic_type_virtual={clinic_type_virtual}
      onCancel={onCancel}
      doctors_error={doctors_error}
      clinics_error={clinics_error}
    />
  );
}

export default CreateAppointment;
