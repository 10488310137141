import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  CCard,
  CCardBody,
  CCardTitle,
  CDataTable,
  CCardHeader,
  CRow,
  CCol,
  CLink,
  CCallout,
  CLabel,
  CSelect,
  CButton,
  CInput,
} from "@coreui/react";
import { listActiveReceivedRequest } from "actions/requests/requests";
import { cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import {
  REQUEST_STATUS,
  SEVERITY,
  STATUS_COLOR,
  REQUEST_SEVERITY,
  REQUEST_SEVERITY_COLORS,
} from "constants/constants";
import RequestCard from "views/requests/components/RequestCard";
import i18next from "i18next";
import Avatar from "views/partials/Avatar";

const MyRequests = () => {
  const [filters, setFilters] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  const requests = useSelector((state) => state.requests.listReceivedRequests);

  useEffect(() => {
    dispatch(listActiveReceivedRequest());
  }, [dispatch]);

  const handleStatusChange = (event) => {
    setFilters({ status: event.target.value });
    dispatch(
      listActiveReceivedRequest({ ...filters, status: event.target.value })
    );
  };

  const handleSearchBoxChange = (event) => {
    setFilters({ q: event.target.value });
    listActiveReceivedRequest({ ...filters, status: event.target.value });
    dispatch(listActiveReceivedRequest({ ...filters, q: event.target.value }));
  };

  const handleSeverityChange = (event) => {
    setFilters({ severity: event.target.value });
    dispatch(
      listActiveReceivedRequest({ ...filters, severity: event.target.value })
    );
  };
  return (

    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol xs="12" md="4" className="p-2">
          <div className="search-wrap">
            <CInput
              // className="search-input"
              type="text"
              placeholder={i18next.t("Search by Name")}
              onChange={handleSearchBoxChange}
            />
            <CIcon content={cilSearch} className="c-icon-xl search-icon" />
          </div>
        </CCol>
      </CRow>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol md="6" className="p-2">
          <CLabel htmlFor="status" className="float-left labels">
            {i18next.t("status")}
          </CLabel>
          <CSelect
            custom
            name="status"
            id="status"
            onChange={handleStatusChange}
          >
            <option key="0" value="" selected>
              {i18next.t("Select Status")}
            </option>
            {Object.keys(REQUEST_STATUS).map((key) => (
              <option value={key}>{i18next.t(REQUEST_STATUS[key])}</option>
            ))}
          </CSelect>
        </CCol>
        <CCol md="6" className="p-2">
          <CLabel htmlFor="status" className="float-left labels">
            {i18next.t("Severity")}
          </CLabel>
          <CSelect
            custom
            name="severity"
            id="severity"
            onChange={handleSeverityChange}
          >
            <option key="0" value="" selected>
              {i18next.t("Select Severity")}
            </option>
            {Object.keys(REQUEST_SEVERITY).map((key) => (
              <option value={key}>{i18next.t(REQUEST_SEVERITY[key])}</option>
            ))}
          </CSelect>
        </CCol>

      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader />
            <CCardBody>
              <CDataTable
                items={requests || []}
                fields={[
                  { key: "sender_avatar", label:  i18next.t("Sender Avatar") },
                  { key: "sender_name", label:  i18next.t("Sender Name") },
                  { key: "patient_avatar", label:  i18next.t("Patient Avatar") },
                  { key: "patient_name", label:  i18next.t("Patient Name") },
                  { key: "status", label:  i18next.t("status") },
                  { key: "severity", label:  i18next.t("Severity") },
                  { key: "actions", label:  i18next.t("Actions") },
                ]}
                hover
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                scopedSlots={{
                  sender_avatar: (item) => (
                    <td style={{ width: "12%" }}>
                      <Avatar
                        src={item.avatar}
                        style={{ width: "50px" }}
                        alt="NA"
                        className="mb-2 avatar-img"
                      />
                    </td>
                  ),
                  sender_name: (item) => <td>{item.sender_name}</td>,
                  patient_avatar: (item) => (
                    <td style={{ width: "12%" }}>
                      <Avatar
                        src={item.patient_avatar}
                        style={{ width: "50px" }}
                        alt="NA"
                        className="mb-2 avatar-img"
                      />
                    </td>
                  ),
                  patient_name: (item) => <td>{item.patient_name}</td>,
                  status: (item) => (
                    <td>
                      <CButton
                        disabled
                        style={{
                          textTransform: "capitalize",
                        }}
                        color={STATUS_COLOR[item.status - 1]}
                        className={`bg-${
                          STATUS_COLOR[item.status - 1]
                        }  avh-font-bold opacity-100`}
                        href="#"
                      >
                        {i18next.t(REQUEST_STATUS[item.status])}
                      </CButton>
                    </td>
                  ),
                  severity: (item) => (
                    <td>
                      <CButton
                        disabled
                        style={{
                          textTransform: "capitalize",
                        }}
                        className={`bg-${
                          REQUEST_SEVERITY_COLORS[item.severity]
                        }  avh-font-bold`}
                        color={REQUEST_SEVERITY_COLORS[item.severity]}
                        href="#"
                      >
                        {i18next.t(REQUEST_SEVERITY[item.severity])}
                      </CButton>
                    </td>
                  ),

                  actions: (item) => (
                    <td>
                      <CButton
                        onClick={() =>
                          history.push(
                            `episodes/spok/case/${item.episode_id}`
                          )
                        }
                        color="primary"
                      >
                        {i18next.t("Preview")}
                      </CButton>
                    </td>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default MyRequests;
