import { Api, getTokenHeader } from "services/Api";
import { ICD_AUTH, FETCH_APPOINTMENT_ICDS } from "./types";

export const icdAuth = (): Object => async (dispatch) => {
  const response = await Api.get("api/admin/icd/auth", getTokenHeader());
  dispatch({ type: ICD_AUTH, payload: response.data });
};

export const insertIcds = (appointmentId, formData): Object => async () => {
  await Api.post(
    `api/admin/icd/insert/${appointmentId}`,
    formData,
    getTokenHeader()
  );
};

export const fetchAppointmenIcds = (appointmentId): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `api/admin/icd/fetch/appointment/${appointmentId}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_APPOINTMENT_ICDS, payload: response.data });
};
