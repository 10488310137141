import React, { Suspense, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { CContainer, CFade, CSpinner } from "@coreui/react";
import { SUPER_ADMIN_ROLE } from "constants/constants";

// routes config
import routes from "../routes";
import TheErrorNotification from "./TheErrorNotification";
import NoPermission from "views/pages/noPermission/NoPermission";
import Page404 from "views/pages/page404/Page404";

const loading = (
  <CSpinner
    style={{ "margin-left": "45%", "margin-top": "11%" }}
    className="spinner"
    variant="grow"
  />
);

const TheContent = () => {
  const location = useRouteMatch("/physician/call/:token");
  useEffect(() => {
    console.log("okba>>>", location);
    if (location?.path === "/physician/call/:token") {
      document.querySelectorAll(".c-main").forEach((element) => {
        element.style.paddingTop = "0px";
      });

      document
        .querySelectorAll(".c-main .container-fluid")
        .forEach((element) => {
          element.classList.add("mx-0");
          element.classList.add("px-0");
        });
    } else {
      document.querySelectorAll(".c-main").forEach((element) => {
        element.style.paddingTop = "";
      });

      document
        .querySelectorAll(".c-main .container-fluid")
        .forEach((element) => {
          element.classList.remove("mx-0");
          element.classList.remove("px-0");
        });
    }
  }, [location?.path]);

  const accessToken = useSelector((state) => state.auth.access_token);

  const verifyAccount = useSelector((state) => state.auth.verifyAccount);
  const userPermissions = useSelector((state) =>
    state.auth.jwt ? state.auth.jwt.user.permissions : []
  );
  const userRoles = useSelector((state) =>
    state.auth.jwt ? state.auth.jwt.user.roles : []
  );
  return accessToken ? (
    <main className="c-main">
      <CContainer fluid>
        <TheErrorNotification />
        <Suspense fallback={loading}>
          <Switch>
              {routes.map((route, idx) =>
                route.component && (
                  (userRoles.indexOf(SUPER_ADMIN_ROLE) !== -1 ||
                  userPermissions.indexOf(route.permission) !== -1 ||
                  route.name === 'Home') ? (
                    <Route
                      key={`key-${idx + 1}`}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => {
                        const PrePairedProps = route.props ? { ...props, ...route.props } : props;
                        return (
                          <CFade>
                            <route.component {...PrePairedProps} />
                          </CFade>
                        );
                      }}
                    />
                  ) : (
                      <Route
                      key={`key-${idx + 1}`}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <NoPermission  {...props}/>}
                    />
                  )
                )
              )}
              <Route path="*"
                render={() => <Page404 />}
              />

          </Switch>
        </Suspense>
      </CContainer>
    </main>
  ) : (
    <Redirect to={{ pathname: "/login" }} />
  );
};

export default React.memo(TheContent);
