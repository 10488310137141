import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	CCard,
	CCardBody,
	CCardHeader,
	CCol,
	CLabel,
	CRow,
	CSelect,
	CInput,
	CSwitch,
} from "@coreui/react";
import AsyncSelect from "react-select/async";
import { Api, getTokenHeader } from "services/Api";
import {
	patientAnsweredQuestions,
	patientQuestionAnswers,
	resetStatistics
} from "actions/surveys/questions/questions";
import { CChart } from "@coreui/react-chartjs";
import Loading from "views/partials/Loading";
import i18next from "i18next";

const QuestionChartComponent = (props) => {
	const { patientId, chartColWidth = 8 } = props;
	const dispatch = useDispatch();
	const [selectedPatientId, setSelectedPatientId] = useState(patientId);
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [filters, setFilters] = useState({});
	const [chartData, setChartData] = useState(false);
	const [chartType, setChartType] = useState('bar');
	const questionsList = useSelector((state) => state.questions.answeredQuestions);
	const questionAnswers = useSelector((state) => state.questions.questionAnswers);
	useEffect(() => {
		dispatch(resetStatistics());
	}, []);

	useEffect(() => {
		if (patientId) {
			dispatch(patientAnsweredQuestions(patientId))
		}
	}, [patientId])

	useEffect(() => {
		dispatch(patientQuestionAnswers(selectedPatientId, selectedQuestion, filters));
	}, [selectedPatientId, selectedQuestion, filters])

	useEffect(() => {
		console.log("Chart Type>>>", chartType);
		const data = questionAnswers && questionAnswers.length ? questionAnswers : [];
		// Extracting answer values from data
		const answerValues = data.map(item => parseFloat(item.answer_value));

		// Filtering out non-numeric values
		const validAnswerValues = answerValues.filter(value => !isNaN(value));

		// Building dataset for Chart.js
		const chartData = {
			labels: data.map(item => item.created_at.split('T')[0]),
			datasets: [
				{
					label: `${i18next.t("Answer Value")}`,
					backgroundColor: 'rgba(75,192,192,0.4)',
					borderColor: 'rgba(75,192,192,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(75,192,192,0.6)',
					hoverBorderColor: 'rgba(75,192,192,1)',
					data: validAnswerValues
				}
			]
		}
		setChartData(chartData);
	}, [questionAnswers, chartType]);

	const getPatientOptions = async (inputValue) => {
		const url = `/api/admin/patients/list?q=${inputValue}`;
		const response = await Api.get(url, getTokenHeader());
		return response.data.data ? response.data.data : [];
	};

	const handlePatientSelect = async (value) => {
		setSelectedPatientId(value.id);
		dispatch(resetStatistics());
		dispatch(patientAnsweredQuestions(value.id))
	};

	const handleQuestionSelect = async (event) => {
		setSelectedQuestion(event.target.value);
	};

	const handleFromDateChange = (event) => {
		const updatedFilters = { ...filters, from: event.target.value };
		if (filters.to && new Date(updatedFilters.from).getTime() > new Date(filters.to).getTime()) {
			updatedFilters.to = '';
		}
		setFilters(updatedFilters);
	};

	const handleToDateChange = (event) => {
		const updatedFilters = { ...filters, to: event.target.value };
		setFilters(updatedFilters);
	};

	const RenderQuestionOptions = (options) =>
		options.map((item) => (
			<option key={item.id} value={item.id}>
				{item.question}
			</option>
		));

	const getSelectedQuestion = () => {
		if (!selectedQuestion || !questionsList) return '';
		const question = questionsList.find(item => item.id == selectedQuestion);
		return question?.question;
	}

	if (patientId && !questionsList) {
		return <Loading />;
	}

	return (
		<CCol xl={12}>
			<CCard>
				<CCardHeader>
					<CRow>
						{!patientId && (
							<CCol xs={12} sm={12} md={4} lg={4} xl={4} className="left-side">
								<CLabel className="labels">{i18next.t("Patient")}</CLabel>
								<AsyncSelect
									id="patient_select"
									cacheOptions
									loadOptions={(inputValue) => getPatientOptions(inputValue)}
									getOptionLabel={(option) =>
										`${option.first_name} ${option.last_name}`
									}
									getOptionValue={(option) => option.id}
									onChange={handlePatientSelect}
								/>
							</CCol>
						)}
						<CCol xs={12} sm={12} md={4} lg={4} xl={4}>
							<CLabel className="labels" htmlFor="question">
								{i18next.t("Question")}
							</CLabel>
							<CSelect
								custom
								id="question"
								defaultValue=""
								onChange={handleQuestionSelect}
							>
								<option key="0" value="" >
									{i18next.t("Select Question")}
								</option>
								{RenderQuestionOptions(questionsList || [])}
							</CSelect>
						</CCol>
						<CCol>
							<CLabel className="labels" htmlFor="from">
							{i18next.t("From")}
							</CLabel>
							<CInput
								type="date"
								id="from"
								name="from"
								value={filters.from}
								max={new Date().toISOString().substr(0, 10)}
								onChange={handleFromDateChange}
							/>
						</CCol>
						<CCol>
							<CLabel className="labels" htmlFor="to">
							{i18next.t("To")}
							</CLabel>
							<CInput
								type="date"
								id="to"
								name="to"
								value={filters.to}
								max={new Date().toISOString().substr(0, 10)}
								min={filters.from ? new Date(filters.from).toISOString().substr(0, 10) : undefined}
								onChange={handleToDateChange}
							/>
						</CCol>
					</CRow>
					<hr />
					<CRow>
						<CCol sm={12} md={4} xs={12} lg={4} xl={4}>
							<CLabel
								className="labels mx-3"
								style={{ "vertical-align": "middle", fontSize: "1em" }}
								htmlFor="chart_type"
							>
								{i18next.t("Chart Type")}
							</CLabel>
							<CSwitch
								id="chart_type"
								checked={chartType === 'bar'}
								style={{ "margin-left": "15%" }}
								className="mr-3"
								size="lg"
								color="primary"
								shape="pill"
								onChange={(e) => {
									console.log(e.target.checked ? 'bar' : 'line'); setChartType(e.target.checked ? 'bar' : 'line')
								}}
							/>
							<CLabel
								className="labels mx-3"
								style={{ "vertical-align": "middle", fontSize: "1em" }}
							>
								{chartType === 'bar' ? `${i18next.t("Bar Chart")}` : `${i18next.t("Line Chart")}`}
							</CLabel>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<CRow>
						<CCol xs={12} sm={12} md={chartColWidth} lg={chartColWidth} xl={chartColWidth} className="mb-4">
							<CCard>
								<CCardHeader>{getSelectedQuestion()}</CCardHeader>
								<CCardBody>
									{chartData && chartType === 'line' && (
										<CChart type='line' datasets={chartData.datasets} labels={chartData.labels} options={{
											responsive: true,
											maintainAspectRatio: false,
										  }}/>
									)}
									{chartData && chartType === 'bar' && (
										<CChart type='bar' datasets={chartData.datasets} labels={chartData.labels} options={{
											responsive: true,
											maintainAspectRatio: false,
										  }}/>
									)}
								</CCardBody>
							</CCard>
						</CCol>
					</CRow>
				</CCardBody>
			</CCard>
		</CCol>
	);

};

export default QuestionChartComponent;