import React, { useState } from "react";
import { CInput, CButton, CRow, CCol } from "@coreui/react";

const ChatInput = ({ conversation }) => {
  const [messageBody, setMessageBody] = useState("");

  const handleSendMessage = (message: string) => {
    conversation.sendMessage(message.trim());
    setMessageBody("");
  };

  const handleChange = (event) => {
    setMessageBody(event.target.value);
  };

  return (
    <>
      <CRow className="align-items-center">
        <CCol xs="9">
          <CInput
            type="text"
            id="name"
            name="name"
            value={messageBody}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs="3">
          <CButton
            onClick={() => handleSendMessage(messageBody)}
            size="sm"
            color="primary"
            className="mt-0"
          >
            Send
          </CButton>
        </CCol>
      </CRow>
    </>
  );
};
export default ChatInput;
