import React from "react";
import find from "lodash/find";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const PrepareFields = (prescription, utilities) => {
  const timeUnit = find(utilities.time_units, {
    id: parseInt(prescription.frequency_time_unit, 10),
  });

  prescription.frequency = `Once every ${prescription.frequency} ${
    timeUnit ? timeUnit.title : "NA"
  }`;

  const route = find(utilities.routes, {
    id: parseInt(prescription.route, 10),
  });
  prescription.route = route ? route.title : prescription.route;

  return prescription;
};

const RenderPrescription = (prescription, prescriptionId, utilities) => {
  if (prescription && prescription.id === prescriptionId) {
    const data = PrepareFields(prescription, utilities);
    return (
      <table className="table table-striped table-hover">
        <tbody>
          <tr>
            <td className="labels">{i18next.t("Drug Name")}</td>
            <td>{`${prescription.drug.drug_name}`}</td>
          </tr>
          <tr>
            <td className="labels">{i18next.t("frequency")}</td>
            <td>{`${prescription.frequency}`}</td>
          </tr>
          <tr>
            <td className="labels">{i18next.t("Route")}</td>
            <td>{`${prescription.route}`}</td>
          </tr>

          <tr>
            <td className="labels">{i18next.t("Note")}</td>
            <td>
              <strong>
                {prescription.notes
                  ? prescription.notes
                  : i18next.t("No Additional Notes")}
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  return <></>;
};

const Show = ({
  prescription,
  prescriptionId,
  showModal,
  toggleShowModal,
  utilities,
}) =>
  showModal && prescription ? (
    <CModal show={showModal} size="lg">
      <h4 className="table-title">{i18next.t("Prescription Details")}</h4>
      <CModalBody>
        {RenderPrescription(prescription, prescriptionId, utilities)}
      </CModalBody>
      <CModalFooter>
        <CButton type="reset" color="danger" onClick={toggleShowModal}>
          {i18next.t("Close")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    <></>
  );
export default Show;
