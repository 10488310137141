import React from "react";
import { CCol, CFormGroup, CLabel } from "@coreui/react";
import SurveyQuestions from "../SurveyQuestions";

const SurveyCompositeQuestion = ({ question, register, setValue, watch }) => {
  return (
    <CCol md={12}>
      <br />
      <CFormGroup row>
        <CLabel className="labels" htmlFor={`survey_question_${question.id}`}>
          <h5>{question.question}</h5>
        </CLabel>
        <SurveyQuestions
          questions={question.sub_questions}
          register={register}
          setValue={setValue}
          watch={watch}
          questionNamePrefix={`composite_question_${question.id}`}
        />
      </CFormGroup>
    </CCol>
  );
};

export default SurveyCompositeQuestion;
