import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchUtilities, createDocument } from "actions/users/users";
import DocumentForm from "./form/DocumentForm";

/**
 * @param props
 */
function CreateDocument() {
  const { userId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.users.redirect);

  const onSubmit = async (formData) => {
    const newFormData = new FormData();
    newFormData.append("name", formData.name);
    newFormData.append("document", formData.document[0]);
    await dispatch(createDocument(newFormData, userId));
  };

  if (redirect) {
    history.push(`/users/${userId}/documents`);
  }

  useEffect(() => {
    dispatch(fetchUtilities());
  }, [dispatch]);

  return <DocumentForm onSubmit={onSubmit} />;
}

export default CreateDocument;
