import {
  CREATE_LAB,
  FETCH_LAB,
  UPDATE_LAB,
  FETCH_LABS,
  DELETE_LAB,
  FETCH_LAB_STAFF,
  FETCH_LAB_EXCLUDED_USERS,
  FETCH_CENTER_INVESTIGATIONS,
  DELETE_LAB_MEMBER,
} from "actions/labs/types";
import { UNAUTHORIZED_STATUS_CODE } from "constants/constants";

/**
 * @param state
 * @param action
 */
export default function labs(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_LAB:
    case UPDATE_LAB:
      if(action.payload.status == UNAUTHORIZED_STATUS_CODE){
        return {...state, redirect: false}
      }
      return { lab: action.payload.data, redirect: true };
    case FETCH_LAB:
      return { lab: action.payload.data };
    case FETCH_LABS:
      return { ...action.payload };
    case DELETE_LAB:
      return {
        ...state,
        data: state.data.filter(item => item.id !== action.payload)
      };
    case FETCH_LAB_STAFF:
      return { ...state, staff: { ...action.payload } };
    case FETCH_LAB_EXCLUDED_USERS:
      return { ...state, excludedUsers: action.payload.data };
      case DELETE_LAB_MEMBER:
      return {
        ...state,
        staff: {
          ...state.staff,
          data: state.staff.data.filter(
            (item) => item.id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
