import React, { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import i18next from "i18next";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
} from "@coreui/react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { Api, getTokenHeader } from "services/Api";
import { getValidation } from "views/hospitals/form/ServiceProviderValidation";
import { useHistory } from "react-router-dom";
import { customStyles } from "views/rounds/form/RoundFormValidation";

const AssignUserForm = ({ user, onSubmit, assignedHospitals }) => {
  let defaultValues = {};
  if (assignedHospitals) {
    defaultValues = { rows: assignedHospitals };
  }

  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const { handleSubmit, control, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rows",
  });

  const getPatientOptions = async (inputValue) => {
    const url = `/api/admin/hospitals?q=${inputValue}&excludedIds=${JSON.stringify(
      selectedHospitals
    )}`;
    const response = await Api.get(url, {
      ...getTokenHeader(),
    });
    return response.data.data || [];
  };

  const addRow = () => {
    const rows = watch().rows || [];
    getSelectedHospitals(rows);
    append({});
  };

  const Validation = getValidation(watch);
  const history = useHistory();

  const onCancel = () => {
    history.push("/users");
  };

  const getSelectedHospitals = (rows) => {
    const data = rows.map((row) => {
      return row.hospital ? row.hospital.id : 0;
    });
    setSelectedHospitals(data);
  };

  const renderRowsFields = () =>
    fields.map((row, index) => (
      <>
        <CFormGroup row key={row.id}>
          <CCol xs={5} sm={5} md={5} lg={5} xl={5}>
            <CLabel className="labels required">{i18next.t("Hospital")}</CLabel>
            <Controller
              control={control}
              name={`rows[${index}].hospital`}
              id="hospital"
              styles={customStyles(!errors.rows)}
              rules={Validation.require}
              cacheOptions
              value={row.hospital || 0}
              loadOptions={(inputValue) => getPatientOptions(inputValue)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              as={<AsyncSelect />}
            />
          </CCol>
          <CCol xs={5} sm={5} md={5} lg={5} xl={5}>
            <CLabel className="labels required">{i18next.t("Role")}</CLabel>
            <Controller
              control={control}
              id="roles"
              styles={customStyles(!errors.rows)}
              rules={Validation.require}
              name={`rows[${index}].roles`}
              cacheOptions
              options={user ? user.roles : []}
              isMulti
              value={row ? row.roles : []}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              as={Select}
            />
          </CCol>
          <CCol xs={2} sm={2} md={2} lg={2} xl={2}>
            <CButton
              style={{ marginTop: "15%" }}
              color="danger"
              onClick={() => remove(index)}
            >
              {i18next.t("Remove")}
            </CButton>
          </CCol>
        </CFormGroup>
      </>
    ));

  return (
    <CRow>
      <CCol xs="12">
        <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
          <CCard>
            <h4 className="table-title">
              {i18next.t("Assign")} {`${user.first_name} ${user.last_name}`} {i18next.t("To")} {i18next.t("Hospital")}
            </h4>
            <CCardBody>
              <CFormGroup row>
                <CCol
                  className="text-align-center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <CButton
                    style={{
                      "background-color": "rgb(48, 197, 182)",
                      "border-color": "rgb(48, 197, 182)",
                      width: "11em",
                    }}
                    color="info"
                    onClick={addRow}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Add Hospital")}
                  </CButton>
                </CCol>
              </CFormGroup>
              {renderRowsFields()}
            </CCardBody>
            <CCardFooter>
              <CFormGroup row>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    type="submit"
                    color="primary"
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Save")}
                  </CButton>
                </CCol>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  );
};

export default AssignUserForm;
