import React from "react";
import { CCol, CCard, CCardHeader, CCardBody } from "@coreui/react";
import { useSelector } from "react-redux";
import i18next from "i18next";

const LegalGuardianInfo = () => {
  const guardian = useSelector((state) => state.episodes.episode.guardian);

  const renderLegalGuardian = () =>
    guardian ? (
      <table className="table table-striped" style={{textAlign:"left"}}>
        <tbody>
          <tr>
            <td>
              <strong>{i18next.t("Name")}</strong>
            </td>
            <td>{`${guardian.first_name} ${guardian.last_name}`}</td>
          </tr>
          <tr>
            <td>
              <strong>{i18next.t("Email")}</strong>
            </td>
            <td>{guardian.email}</td>
          </tr>
          <tr>
            <td>
              <strong>{i18next.t("Address")}</strong>
            </td>
            <td>{guardian.address}</td>
          </tr>
          <tr>
            <td>
              <strong>{i18next.t("Phone")}</strong>
            </td>
            <td>{guardian.phone}</td>
          </tr>
        </tbody>
      </table>
    ) : (
      <h4 className="text-align-center not-found">
        <strong>{i18next.t("Not Found")}</strong>
      </h4>
    );

  return (
    <CCard className="col-12 border-0" style={{padding:"0px"}}>
      <CCardHeader row className="border-0 text-theme-blue" style={{padding:"0px"}}>
        <CCol md="12">
          <h3 className="table-title" md="12">{i18next.t("Legal Guardian Contact Info")}</h3>
        </CCol>
      </CCardHeader>
      <CCardBody className="legal-guardian-info" >{renderLegalGuardian()}</CCardBody>
    </CCard>
  );
};

export default LegalGuardianInfo;
