const Permissions = {
  /**    Admin Users */
  homePermission: "homePermission",
  dashboardPermission: "dashboardPermission",
  // user permissions
  createUser: "create-user",
  updateUser: "update-user",
  showUser: "show-user",
  deleteUser: "delete-user",
  listUsers: "list-users",
  showDocument: "show-document",
  createDocument: "create-document",
  updateProfile: "update-profile",
  assignUserToHospitals: "assign-user-to-hospitals",

  // roles permissions
  createRole: "create-role",
  updateRole: "update-role",
  showRole: "show-role",
  deleteRole: "delete-role",
  listRoles: "list-roles",

  // levels permissions
  createLevel: "create-level",
  updateLevel: "update-level",
  showLevel: "show-level",
  deleteLevel: "delete-level",
  listLevels: "list-levels",

  // specialties permissions
  createSpecialty: "create-specialty",
  updateSpecialty: "update-specialty",
  showSpecialty: "show-specialty",
  deleteSpecialty: "delete-specialty",
  listSpecialties: "list-specialties",

  // patients permissions
  deletePatient: "delete-patient",
  listPatients: "list-patients",

  /**    Admin Clinics */
  // Clinics Permissions ,
  createClinic: "create-clinic",
  updateClinic: "update-clinic",
  showClinic: "show-clinic",
  deleteClinic: "delete-clinic",
  listClinics: "list-clinics",
  startClinic: "start-clinic",
  listMyClinics: "list-my-clinics",
  listClinicAppointments: "list-clinic-appointment",
  viewLobby: "view-Lobby",
  // vacations ,
  createVacation: "create-vacation",
  updateVacation: "update-vacation",
  showVacation: "show-vacation",
  deleteVacation: "delete-vacation",
  listVacations: "list-vacations",
  manageNationalVacations: "manage-national-vacations",
  // appointments
  createAppointment: "create-appointment",
  updateAppointment: "update-appointment",
  showAppointment: "show-appointment",
  deleteAppointment: "delete-appointment",
  listAppointments: "list-appointments",

  /**    Admin serviceProviders */
  // Hospitals
  createHospital: "create-hospital",
  updateHospital: "update-hospital",
  showHospital: "show-hospital",
  deleteHospital: "delete-hospital",
  listHospitals: "list-hospitals",
  manageHospitalMember: "manage-hospital-member",

  // Labs
  createLab: "create-lab",
  updateLab: "update-lab",
  showLab: "show-lab",
  deleteLab: "delete-lab",
  listLabs: "list-labs",
  manageLabMember: "manage-lab-member",
  // Radiology
  createRadiology: "create-radiology",
  updateRadiology: "update-radiology",
  showRadiology: "show-radiology",
  deleteRadiology: "delete-radiology",
  listRadiology: "list-radiology",
  manageRadiologyMember: "manage-Radiology-member",

  /**    Admin episodes */
  // prescriptions
  createPrescription: "create-prescription",
  updatePrescription: "update-prescription",
  showPrescription: "show-prescription",
  deletePrescription: "delete-prescription",
  listPrescriptions: "list-prescriptions",
  // episodes
  createEpisode: "create-episode",
  updateEpisode: "update-episode",
  showEpisode: "show-episode",
  deleteEpisode: "delete-episode",
  closeEpisode: "close-episode",
  listEpisodes: "list-episodes",
  // vital signs
  createVitalSign: "create-vital-sign",
  updateVitalSign: "update-vital-sign",
  showVitalSign: "show-vital-sign",
  deleteVitalSign: "delete-vital-sign",
  listVitalSigns: "list-vital-signs",
  // Specialties Forms
  createSpecialtiesForm: "create-specialties-form",
  updateSpecialtiesForm: "update-specialties-form",
  showSpecialtiesForm: "show-specialties-form",
  deleteSpecialtiesForm: "delete-specialties-form",
  listSpecialtiesForms: "list-specialties-forms",
  // FollowUps
  createFollowUp: "create-follow-up",
  updateFollowUp: "update-follow-up",
  showFollowUp: "show-follow-up",
  deleteFollowUp: "delete-follow-up",
  listFollowUps: "list-follow-ups",
  // Investigation
  createInvestigation: "create-investigation",
  updateInvestigation: "update-investigation",
  showInvestigation: "show-investigation",
  deleteInvestigation: "delete-investigation",
  listInvestigations: "list-investigations",

  /**    Admin locations */
  createCity: "create-city",
  updateCity: "update-city",
  showCity: "show-city",
  deleteCity: "delete-city",
  listCities: "list-cities",

  /**    Admin calendar */
  showCalendar: "show-calendar",
  createEvent: "create-events",
  editEvent: "edit-events",

  /**    Patient appointments */
  scheduleAppointment: "schedule-appointment",
  patientShowAppointment: "patient-show-appointment",
  patientEnterLobby: "patientEnterLobby",
  patientTimeLine: "patientTimeLine",
  /** Requests */
  listRequests: "list-requests",

  /** Reports */
  listReports: "list-reports",
  showReport: "show-report",
  // Rounds
  createRound: "create-round",
  updateRound: "update-round",
  showRound: "show-round",
  deleteRound: "delete-round",
  listRounds: "list-rounds",

  // Question
  createQuestion: "create-question",
  updateQuestion: "update-question",
  showQuestion: "show-question",
  deleteQuestion: "delete-question",
  listQuestions: "list-questions",
  publishQuestion: "publish-question",
  listPatientSurveyInstances: "list-all-patients-survey-instances",
  listPatientSurveyStatistics: "list-all-survey-statistics",
  // Surveys
  managePublicSurveys: "manage-public-surveys",
  patientListSurveyInstances: "patient-list-survey-instances",
  patientShowSurveyInstance: "patient-show-survey-instance",
  patientCreateSurveyInstance: "patient-create-survey-instance",
  patientUpdateSurveyInstance: "patient-update-survey-instance",
  doctorListSurveyInstances: "doctor-list-survey-instances",
  // Notifications
  listMyNotifications: "list-my-notifications",
};
export default Permissions;
