import React from "react";
import i18next from "i18next";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import Screens from "./summary/Screens";

const ClinicSummary = ({ setShowModal, showModal, appointment }) => {
  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  return showModal ? (
    <CModal show={showModal} size="lg">
      <h4 className="table-title">{i18next.t("Details")}</h4>
      <CModalBody>
        <Screens appointment={appointment} />
      </CModalBody>
      <CModalFooter>
        <CButton type="reset" color="danger" onClick={toggleShowModal}>
          {i18next.t("Close")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    <></>
  );
};
export default ClinicSummary;
