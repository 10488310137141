import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLobby } from "actions/lobby/lobby";
import { CLINIC_TYPE_PHYSICAL } from "constants/clinic";
import PhysicalLobby from "./PhysicalLobby";
import VirtualLobby from "./VirtualLobby";
import Loading from "views/partials/Loading";

const LobbyFactory = ({ match, hideActions, hideLobby }) => {
  const dispatch = useDispatch();
  const lobby = useSelector((state) => state.lobby.lobby);
  useEffect(() => {
    dispatch(fetchLobby(match.params.token));
  }, [match.params.token]);

  if (lobby && lobby.clinic) {
    if (lobby.clinic.type === CLINIC_TYPE_PHYSICAL) {
      return (
        <PhysicalLobby
          match={match}
          hideActions={hideActions}
          hideLobby={hideLobby}
        />
      );
    }
    return (
      <VirtualLobby
        match={match}
        hideActions={hideActions}
        hideLobby={hideLobby}
      />
    );
  }
  return <Loading/>
};

export default LobbyFactory;
