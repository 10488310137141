import React from "react";

import {Translation} from 'react-i18next';
import permissions from "../constants/permissions";

export default [
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Dashboard",
  //   to: "/dashboard",
  //   icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  //   badge: {
  //     color: "info",
  //     text: "NEW",
  //   },
  // },
  // {
  //   _tag: "CSidebarNavTitle",
  //   _children: ["Components"],
  // },
  {
    _tag: "CSidebarNavTitle",
    icon: "cil-location-pin",
    _children: [
      {
        permission: permissions.listPatients,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Administrative')}</>}</Translation>,
        className: "test-nav-administrative c-sidebar-section",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('users')}</>}</Translation>,
    route: "/users",
    icon: "cil-people",
    className: "test-nav-users",
    _children: [
      {
        permission: permissions.listUsers,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('list')}</>}</Translation>,
        to: "/users",
        className: "test-nav-users-list",
      },
      // {
      //   permission: permissions.listPatients,
      //   _tag: "CSidebarNavItem",
      //   name: <Translation>{(t) => <>{t('patient')}</>}</Translation>,
      //   to: "/users/patient",
      // },
      {
        permission: permissions.listRoles,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Roles')}</>}</Translation>,
        to: "/roles",
        className: "test-nav-users-roles",
      }
    ],
  },
  // {
  //   _tag: "CSidebarNavDropdown",
  //   name: <Translation>{(t) => <>{t('roles')}</>}</Translation>,
  //   route: "/roles",
  //   icon: "cil-star",
  //   _children: [
  //     {
  //       permission: permissions.listRoles,
  //       _tag: "CSidebarNavItem",
  //       name: <Translation>{(t) => <>{t('list')}</>}</Translation>,
  //       to: "/roles",
  //     }
  //   ],
  // },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('specialties')}</>}</Translation>,
    route: "/specialties",
    icon: "cil-layers",
    className: "test-nav-specialties",
    _children: [
      {
        permission: permissions.listSpecialties,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('list')}</>}</Translation>,
        to: "/specialties",
        className: "test-nav-specialties-list",
      }
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('experiences')}</>}</Translation>,
    route: "/levels",
    icon: "cil-layers",
    className: "test-nav-experiences",
    _children: [
      {
        permission: permissions.listLevels,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('list')}</>}</Translation>,
        to: "/levels",
        className: "test-nav-experiences-list",
      }
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('cities')}</>}</Translation>,
    route: "/cities",
    icon: "cil-location-pin",
    className: "test-nav-cities",
    _children: [
      {
        permission: permissions.listCities,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('list')}</>}</Translation>,
        to: "/cities",
        className: "test-nav-cities-list",
      }
    ],
  },
  {
    _tag: "CSidebarNavTitle",
    icon: "cil-location-pin",
    _children: [
      {
        permission: permissions.listRequests,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Location management')}</>}</Translation>,
        className: "test-nav-location-management c-sidebar-section",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('service providers')}</>}</Translation>,
    route: "/service/providers",
    icon: "cil-list-rich",
    className: "test-nav-service-providers",
    _children: [
      {
        permission: permissions.listHospitals,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('locations')}</>}</Translation>,
        to: "/service/providers/hospitals",
        className: "test-nav-sp-locations",
      },
      {
        permission: permissions.listLabs,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('labs')}</>}</Translation>,
        to: "/service/providers/labs",
        className: "test-nav-sp-labs",
      },
      {
        permission: permissions.listRadiology,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('radiology centers')}</>}</Translation>,
        to: "/service/providers/radiology-centers",
        className: "test-nav-sp-rads",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('calendar')}</>}</Translation>,
    route: "/calendar",
    icon: "cil-calendar",
    className: "test-nav-calendar",
    _children: [
      {
        permission: permissions.showCalendar,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('calendar')}</>}</Translation>,
        to: "/calendar",
        className: "test-nav-calendar-calendar",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('episode management')}</>}</Translation>,
    route: "/episodes",
    icon: "cil-hospital",
    className: "test-nav-em",
    _children: [
      {
        permission: permissions.listEpisodes,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('locations')}</>}</Translation>,
        to: "/episodes/select-spoke",
        className: "test-nav-em-locations",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('requests')}</>}</Translation>,
    route: "/requests",
    icon: "cil-list",
    className: "test-nav-requests",
    _children: [
      {
        permission: permissions.listRequests,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('list')}</>}</Translation>,
        to: "/requests",
        className: "test-nav-requests-list",
      },
    ]
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('clinics')}</>}</Translation>,
    route: "/clinics",
    icon: "cil-hospital",
    className: "test-nav-clinics",
    _children: [
      {
        permission: permissions.listClinics,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Clinics List')}</>}</Translation>,
        to: "/clinics",
        className: "test-nav-clinics-list",
      },
      {
        permission: permissions.listAppointments,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Appointments List')}</>}</Translation>,
        to: "/appointments",
        className: "test-nav-clinics-appointments-list",
      },
      {
        permission: permissions.listAppointments,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Cancelled Appointments')}</>}</Translation>,
        to: "/cancelled-appointments",
        className: "test-nav-clinics-cancelled-appointments",
      },
      {
        permission: permissions.listVacations,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Vacations')}</>}</Translation>,
        to: "/vacations",
        className: "test-nav-clinics-vacations",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('rounds')}</>}</Translation>,
    route: "/rounds",
    icon: "cil-video",
    className: "test-nav-rounds",
    _children: [
      {
        permission: permissions.createRound,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('list')}</>}</Translation>,
        to: "/rounds",
        className: "test-nav-rounds-list",
      },
      // {
      //   permission: permissions.listRounds,
      //   _tag: "CSidebarNavItem",
      //   name: <Translation>{(t) => <>{t('my rounds')}</>}</Translation>,
      //   to: "/rounds/my-rounds",
      //   className: "test-nav-rounds-my",
      // },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('forms')}</>}</Translation>,
    route: "/surveys",
    icon: "cil-list-rich",
    className: "test-nav-forms",
    _children: [
      {
        permission: permissions.listQuestions,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('questions')}</>}</Translation>,
        to: "/questions",
        className: "test-nav-forms-questions",
      },
      {
        permission: permissions.createSpecialtiesForm,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('specialties forms')}</>}</Translation>,
        to: "/specialties-forms",
        className: "test-nav-forms-specialties-forms",
      },
      // {
      //   permission: permissions.managePublicSurveys,
      //   _tag: "CSidebarNavItem",
      //   name: <Translation>{(t) => <>{t('Shared Specialties Forms')}</>}</Translation>,
      //   to: "/shared-forms",
      // },
      {
        permission: permissions.listPatientSurveyInstances,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Public Submissions')}</>}</Translation>,
        to: "/public-surveys",
        className: "test-nav-forms-public-submissions",
      },
      {
        permission: permissions.listPatientSurveyInstances,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Send Surveys')}</>}</Translation>,
        to: "/send-surveys",
        className: "test-nav-forms-send-surveys",
      },
      {
        permission: permissions.listPatientSurveyStatistics,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Survey Statistics')}</>}</Translation>,
        to: "/survey-statistics",
        className: "test-nav-forms-survey-statistics",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: <Translation>{(t) => <>{t('reports')}</>}</Translation>,
    route: "/reports",
    icon: "cil-list",
    className: "test-nav-reports",
    _children: [
      {
        permission: permissions.listReports,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Open Reports')}</>}</Translation>,
        to: "/reports",
        className: "test-nav-reports-list",
      },
      {
        permission: permissions.listReports,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('Old Reports')}</>}</Translation>,
        to: "/old-reports",
        className: "test-nav-reports-old",
      },
    ],
  },
  {
    _tag: "CSidebarNavTitle",
    icon: "cil-location-pin",
    _children: [
      {
        permission: permissions.scheduleAppointment,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('My Menu')}</>}</Translation>,
        className: "c-sidebar-section test-nav-my-menu",
     },
    ],
  },
  {
    _tag: "CSidebarNavDivider",
    name: <Translation>{(t) => <>{t('My Menu')}</>}</Translation>,
    _children: [
      {
        permission: permissions.listMyClinics,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('My Clinics')}</>}</Translation>,
        icon: "cil-hospital",
        to: "/my-clinics",
        className: "test-nav-my-menu-clinics",
      },
      {
        permission: permissions.scheduleAppointment,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('appointments')}</>}</Translation>,
        to: "/patient/appointments",
        icon: "cil-calendar",
        className: "test-nav-my-menu-appointments",
      },
      {
        _tag: "CSidebarNavDropdown",
        name: <Translation>{(t) => <>{t('Public surveys')}</>}</Translation>,
        icon: "cil-list",
        className: "test-nav-my-menu-public-surveys",
        _children: [
          {
            permission: permissions.patientListSurveyInstances,
            _tag: "CSidebarNavItem",
            name: <Translation>{(t) => <>{t('My Submissions')}</>}</Translation>,
            to: "/patient/survey-instances",
            className: "test-nav-my-menu-public-surveys-submissions",
            // icon: "cil-list-rich",
          },
          {
            permission: permissions.listPatientSurveyInstances,
            _tag: "CSidebarNavItem",
            name: <Translation>{(t) => <>{t('My Surveys')}</>}</Translation>,
            to: "/matched-sent-surveys",
            className: "test-nav-my-menu-public-surveys-surveys",
            // icon: "cil-list-rich",
          },
        ],
      },
      {
				permission: permissions.listRounds,
				_tag: "CSidebarNavItem",
				name: <Translation>{(t) => <>{t('my rounds')}</>}</Translation>,
				to: "/rounds/my-rounds",
				icon: "cil-video",
				className: "test-nav-rounds-my",
			},
      {
        permission: permissions.patientTimeLine,
        _tag: "CSidebarNavItem",
        name: <Translation>{(t) => <>{t('timeline')}</>}</Translation>,
        to: "/patient/timeLine",
        icon: "cil-list",
        className: "test-nav-my-menu-timeline",
      },
    ],
  },
];
