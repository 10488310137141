import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  CCol,
  CForm,
  CLabel,
  CInput,
  CButton,
  CSelect,
  CFormGroup,
} from "@coreui/react";
import {
  TYPE_PHYSICIAN_CLINICS,
  TYPE_PHYSICIAN_CLINICS_APPOINTMENTS,
  TYPE_PHYSICIAN_APPOINTMENTS,
  TYPE_PHYSICIAN_DURATION,
  TYPE_PHYSICIAN_DELAY,
  TYPE_PATIENT_EPISODE_DISCHARGE,
  TYPE_PATIENT_EPISODE_TELE_CONSULT_TIME,
  TYPE_PHYSICIAN_RATING,
  TYPE_HOSPITAL_EPISODE_DISCHARGE,
  TYPE_EPISODE_LAST_UPDATE,
  TYPE_ACTIVE_EPISODE_PRESC_EXEC_PERCENTAGE,
  TYPE_EPISODE_STAY_LENGTH,
  TYPE_DOCTOR_CALLS_COUNT,
  TYPE_DOCTOR_CLINIC_BOOKED_PERCENTAGE,
  TYPE_CLINIC_INCOME,
  TYPE_PATIENTS_APPOINTMENTS,
  TYPE_OUTPATIENTS_PHYSICIAN,
  TYPE_OUTPATIENTS_PATIENTS,
  TYPE_OUTPATIENTS_CLINICS,
  TYPE_INPATIENTS_ROUNDS,
  TYPE_INPATIENTS_PATIENTS,
} from "constants/reports";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { fetchSpecialties } from "actions/specialties/specialties";
import {
  DoctorAsyncSelect,
  PatientAsyncSelect,
  HospitalAsyncSelect,
} from "views/partials/AsyncSelectComponents";

const ReportFilters = ({ type, onFiltersSubmit }) => {
  const dispatch = useDispatch();
  const specialties = useSelector((state) =>
    state.specialties.data ? state.specialties.data : []
  );
  const { register, handleSubmit, watch, control } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    dispatch(fetchSpecialties());
  }, [dispatch]);

  const renderDateFilters = () => (
    <>
      <CCol xs={12} sm={12} md={5} lg={5} xl={5}>
        <CLabel htmlFor="date_from" className="labels">
          From
        </CLabel>
        <CInput
          innerRef={register}
          type="date"
          id="date_from"
          name="date_from"
          defaultValue={new Date().toISOString().substr(0, 10)}
        />
      </CCol>
      <CCol xs={12} sm={12} md={5} lg={5} xl={5}>
        <CLabel htmlFor="date_to" className="labels">
          To
        </CLabel>
        <CInput
          innerRef={register}
          type="date"
          id="date_to"
          name="date_to"
          defaultValue={watch("date_from")}
          min={watch("date_from")}
        />
      </CCol>
    </>
  );
  const renderSubmitButton = () => (
    <CCol xs={12} sm={12} md={2} lg={2} xl={2} style={{ "margin-top": "2em" }}>
      <CButton type="submit" color="primary">
        Filter
      </CButton>
    </CCol>
  );

  const renderReportFilters = () => {
    switch (type) {
      case TYPE_CLINIC_INCOME:
      case TYPE_DOCTOR_CLINIC_BOOKED_PERCENTAGE:
      case TYPE_OUTPATIENTS_PHYSICIAN:
      case TYPE_OUTPATIENTS_PATIENTS:
      case TYPE_INPATIENTS_ROUNDS:
      case  TYPE_PATIENTS_APPOINTMENTS:
        return (
          <>
            <CFormGroup row>
              {renderDateFilters()}
              {renderSubmitButton()}
            </CFormGroup>
          </>
        );
      case TYPE_PHYSICIAN_CLINICS:
      case TYPE_PHYSICIAN_CLINICS_APPOINTMENTS:
      case TYPE_PHYSICIAN_APPOINTMENTS:
      case TYPE_PHYSICIAN_DURATION:
      case TYPE_PHYSICIAN_DELAY:
      case TYPE_PHYSICIAN_RATING:
      case TYPE_OUTPATIENTS_CLINICS: {
        return (
          <>
            <CFormGroup row>
              <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                <DoctorAsyncSelect
                  label="Doctor"
                  name="doctor"
                  control={control}
                />
              </CCol>
              <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                <HospitalAsyncSelect
                  label="Location"
                  name="hospital"
                  control={control}
                />
              </CCol>
              <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                <CLabel htmlFor="specialty_id" className="labels">
                  Specialty
                </CLabel>
                <CSelect
                  custom
                  name="specialty_id"
                  id="specialty_id"
                  innerRef={register}
                >
                  <option value="" key="0" selected disabled>
                    Select Specialty
                  </option>
                  {RenderSelectOptions(specialties)}
                </CSelect>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              {renderDateFilters()}
              {renderSubmitButton()}
            </CFormGroup>
          </>
        );
      }
      case TYPE_PATIENT_EPISODE_TELE_CONSULT_TIME:
      case TYPE_PATIENT_EPISODE_DISCHARGE: {
        return (
          <>
            <CFormGroup row>
              <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                <PatientAsyncSelect
                  label="Patient"
                  name="patient"
                  control={control}
                />
              </CCol>
              <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                <HospitalAsyncSelect
                  label="Location"
                  name="hospital"
                  control={control}
                />
              </CCol>
              <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                <CLabel htmlFor="specialty_id" className="labels">
                  Specialty
                </CLabel>
                <CSelect
                  custom
                  name="specialty_id"
                  id="specialty_id"
                  innerRef={register}
                >
                  <option value="" key="0" selected disabled>
                    Select Specialty
                  </option>
                  {RenderSelectOptions(specialties)}
                </CSelect>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              {renderDateFilters()}
              {renderSubmitButton()}
            </CFormGroup>
          </>
        );
      }

      case TYPE_EPISODE_STAY_LENGTH:
      case TYPE_ACTIVE_EPISODE_PRESC_EXEC_PERCENTAGE:
      case TYPE_EPISODE_LAST_UPDATE:
      case TYPE_HOSPITAL_EPISODE_DISCHARGE:
      case TYPE_INPATIENTS_PATIENTS: {
        return (
          <>
            <CFormGroup row>
              <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                <HospitalAsyncSelect
                  label="Location"
                  name="hospital"
                  control={control}
                />
              </CCol>
              <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                <CLabel htmlFor="specialty_id">Specialty</CLabel>
                <CSelect
                  custom
                  name="specialty_id"
                  id="specialty_id"
                  innerRef={register}
                >
                  <option value="" key="0" selected disabled>
                    Select Specialty
                  </option>
                  {RenderSelectOptions(specialties)}
                </CSelect>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              {renderDateFilters()}
              {renderSubmitButton()}
            </CFormGroup>
          </>
        );
      }
      case TYPE_DOCTOR_CALLS_COUNT: {
        return (
          <>
            <CFormGroup row>
              <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                <DoctorAsyncSelect
                  label="Doctor"
                  name="doctor"
                  control={control}
                />
              </CCol>
              <CCol xs={6} sm={6} md={4} lg={4} xl={4}>
                <CLabel htmlFor="hour" className="hour">
                  Hour
                </CLabel>
                <CInput
                  innerRef={register}
                  type="number"
                  id="hour"
                  name="hour"
                  defaultValue={0}
                  min={0}
                  max={23}
                />
              </CCol>
              <CCol xs={6} sm={6} md={4} lg={4} xl={4}>
                <CLabel htmlFor="party" className="labels">
                  Doctor Is
                </CLabel>
                <CSelect
                  custom
                  name="party"
                  id="party"
                  innerRef={register}
                >
                  <option value="" key="0" disabled>
                    Select Type
                  </option>
                  <option value="sender_id" key="1" selected>
                    Sender
                  </option>
                  <option value="receiver_id" key="2">
                    Receiver
                  </option>
                </CSelect>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              {renderDateFilters()}
              {renderSubmitButton()}
            </CFormGroup>
          </>
        );
      }
      default:
        return "";
    }
  };

  return (
    <CForm
      onSubmit={handleSubmit(onFiltersSubmit)}
      key={`form_${type}`}
      style={{ width: "100%", "padding-left": "2em" }}
    >
      {renderReportFilters()}
    </CForm>
  );
};

export default ReportFilters;
