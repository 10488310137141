import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CRow,
  CLabel,
  CSelect,
  CButton,
  CCard,
  CCardImg,
  CCardTitle,
} from "@coreui/react";
import { Api, getTokenHeader } from "services/Api";
import AsyncSelect from "react-select/async";
import { filterCases, endRound } from "actions/rounds/rounds";
import { HIDE_CONSULTATION_NOTE_NOTIFICATION } from "actions/rounds/types";
import { TYPE_APPOINTMENT, TYPE_EPISODE } from "constants/constants";
import ConsultationNote from "./ConsultationsNote";
import ActiveCase from "./ActiveCase";
import i18next from "i18next";

const CaseList = ({ cases, onSelectCase, selectedCase, selectedPatient }) =>
  cases.map((item) => {
    if (
      selectedCase &&
      selectedCase.id === item.id &&
      selectedCase.round_consultation_text !== item.round_consultation_text
    ) {
      onSelectCase(item);
    }
    if (selectedPatient) {
      const nameIntials = `${selectedPatient.first_name[0].toUpperCase()}. ${selectedPatient.last_name[0].toUpperCase()}.`;
      return (
        <option key={item.id} value={item.id}>
          {`${nameIntials}_${selectedPatient.gender}_${item.case_location}_Date: ${item.case_date}`}
        </option>
      );
    }
    return "";
  });

const OrganizerControls = ({
  onSelectCaseType,
  caseType,
  onSelectPatient,
  selectedPatient,
  onSelectCase,
  selectedCase,
}) => {
  const dispatch = useDispatch();
  const cases = useSelector((state) =>
    state.roundsUtilities.cases
      ? Object.values(state.roundsUtilities.cases)
      : []
  );
  const conferenceRound = useSelector(
    (state) => state.roundsUtilities.conferenceRound
  );

  const getPatientOptions = async (inputValue) => {
    const url = `/api/admin/rounds/accessible-patients?q=${inputValue}`;
    const options = await Api.get(url, {
      ...getTokenHeader(),
    }).then((response) => response.data.data);
    return options || [];
  };

  const onFilterCases = () => {
    if (selectedPatient) {
      dispatch(filterCases(caseType, selectedPatient.id, conferenceRound.id));
    }
  };
  return (
    <>
      <CCol xs="12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <CCard className="p-5 share-section text-theme-blue">
          <CCardTitle
            className="text-left mt-2"
            style={{ marginBottom: "30px" }}
          >
            <h3 className="round-title-style">{i18next.t("Patients")}</h3>
          </CCardTitle>
          <CRow>
            <CCol className="justify-content-center">
              <CRow className="align-items-end">
                <CCol className="justify-content-center">
                  <CLabel>{i18next.t("Type")}</CLabel>
                  <CSelect value={caseType} onChange={onSelectCaseType}>
                    <option value={TYPE_EPISODE}>{i18next.t("Episode")}</option>
                    <option value={TYPE_APPOINTMENT}>{i18next.t("Appointment")}</option>
                  </CSelect>
                </CCol>
                <CCol className="justify-content-center">
                  <CLabel>{i18next.t("Available Cases")}</CLabel>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={(inputValue) => getPatientOptions(inputValue)}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    getOptionValue={(option) => option.id}
                    value={selectedPatient}
                    onChange={onSelectPatient}
                  />
                </CCol>
                {/* <CCol className="justify-content-center mr-4">
                  <CButton
                    color="primary"
                    className="btn btn-primary btn-lg"
                    style={{
                      textAlign: "center",
                      boxShadow: "0px 3px 3px #0000003E",
                      backgroundColor: "#35488D",
                    }}
                    onClick={onFilterCases}
                  >
                    {i18next.t("show")}
                  </CButton>
                </CCol> */}
              </CRow>
            </CCol>
          </CRow>
        </CCard>
      </CCol>

      {/* result case list  */}

      <CCol xs="12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <CCard className="mt-3 p-5 share-section text-theme-blue">
          <CCardTitle
            className="text-left mt-2"
            style={{ marginBottom: "30px" }}
          >
            <h3 className="round-title-style">
              {caseType === TYPE_EPISODE ? i18next.t("Episodes") : i18next.t("Appointments")}
            </h3>
          </CCardTitle>
          <CRow>
            <CCol xs="2">
              <CCardImg
                src="https://cdn.pixabay.com/photo/2018/08/28/12/41/avatar-3637425_960_720.png"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  marginTop: "7px",
                  objectFit: "cover",
                }}
              />
            </CCol>
            <CCol xs="10" className="d-flex flex-column justify-content-center">
              {(cases && (
                <>
                  <CSelect
                    onChange={(event) => {
                      const valCase = cases.find(
                        (item) => Number(item.id) === Number(event.target.value)
                      );
                      if (valCase) {
                        onSelectCase(valCase);
                        dispatch({ type: HIDE_CONSULTATION_NOTE_NOTIFICATION });
                      }
                    }}
                  >
                    <option value={0}>Select Case</option>
                    <CaseList
                      cases={cases}
                      selectedPatient={selectedPatient}
                      onSelectCase={onSelectCase}
                      selectedCase={selectedCase}
                    />
                  </CSelect>
                </>
              )) || <p>{i18next.t("No Available Cases")}.</p>}
            </CCol>
          </CRow>
        </CCard>
      </CCol>

      {selectedCase && selectedPatient && (
        <ActiveCase
          activeCase={selectedCase}
          caseType={caseType}
          patient={selectedPatient}
        />
      )}

      {/* Consultation Note */}

      {selectedCase && (
        <ConsultationNote selectedCase={selectedCase} caseType={caseType} />
      )}
    </>
  );
};

export default OrganizerControls;
