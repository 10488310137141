import React, { useEffect } from "react";
import {
  CCol,
  CRow,
  CCardBody,
  CButton,
  CCardTitle,
  CLink,
  CCard,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPatientAppointments } from "actions/appointments/patientAppointment";
import AppointmentCardGroup from "../../partials/AppointmentCardGroup";
import { SearchCard } from "../../../partials/partials";
import i18next from "i18next";

const Appointment = () => {
  const dispatch = useDispatch();
  const patientAppointments = useSelector((state) => state.patientAppointments);

  useEffect(() => {
    dispatch(fetchPatientAppointments());
  }, [dispatch]);

  return (
    // <SearchCard>
    <CCardBody>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol md="6">
          <div className="float-left table-label-title">
            {i18next.t("appointments")}
          </div>
        </CCol>
        <CCol md="6">
          <CButton
            color="success"
            href="/patient/clinic/search"
            className="create-button float-right wi"
          >
            {i18next.t("New Appointment")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          {/*  */}
          <CCard>
            <CCardBody>
              <CTabs activeTab="upcoming">
                <CNav variant="tabs">
                  <CNavItem>
                    <CNavLink className="navchange" data-tab="upcoming">
                      {i18next.t("Upcoming Appointments")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink className="navchange" data-tab="past">
                      {i18next.t("Past Appointments")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink className="navchange" data-tab="missed">
                      {i18next.t("Missed Appointments")}
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent>
                  <CTabPane data-tab="upcoming">
                    <AppointmentCardGroup
                      Appointments={
                        patientAppointments.upcomingAppointments
                          ? patientAppointments.upcomingAppointments
                          : []
                      }
                      date_background="bg-info py-3"
                      type="Upcoming Appointments"
                    />
                  </CTabPane>
                  <CTabPane data-tab="past">
                    <AppointmentCardGroup
                      Appointments={
                        patientAppointments.pastAppointments
                          ? patientAppointments.pastAppointments
                          : []
                      }
                      type="Past Appointments"
                      // color="primary"
                      date_background="bg-primary py-3"
                    />
                  </CTabPane>
                  <CTabPane data-tab="missed">
                    <AppointmentCardGroup
                      Appointments={
                        patientAppointments.missedAppointments
                          ? patientAppointments.missedAppointments
                          : []
                      }
                      // color="danger"
                      type="Missed Appointments"
                      date_background="bg-danger py-3"
                    />
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCardBody>
    // </SearchCard>
  );
};

export default Appointment;
