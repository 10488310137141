import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  CRow,
  CLink,
  CCol,
  CDataTable,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CCard,
  CCardBody,
} from "@coreui/react";
import "./styles/investigation.css";
import fileDownload from "js-file-download";
import { Api, getTokenHeader } from "services/Api";
import {
  cilCloudDownload,
  cilNewspaper,
  cilNoteAdd,
  cilPencil,
  cilSearch,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import ExecuteInvestigation from "views/labs/screens/executInvestigation";
import i18next from "i18next";
import Slider from "./Slider";
import Show from "./Show";
import CreateInvestigation from "./Create";

const List = ({
  centerType,
  investigations,
  investigationTypes,
  modelId,
  patientId,
  episodeStatus,
  isPatientView,
  statusList,
  showDownload,
}) => {
  const [modal, setModal] = useState(false);
  const [processInvestigation, setProcessInvestigation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sliderModal, setSliderModal] = useState(false);
  const [investigation, setInvestigation] = useState();

  const toggleFormModal = () => {
    setModal(!modal);
  };

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  const toggleSliderModal = () => {
    setSliderModal(!sliderModal);
  };
  const showSlides = (investigation) => {
    setInvestigation(investigation);
    toggleSliderModal();
  };
  const stopSlides = () => {
    setInvestigation(null);
    toggleSliderModal();
  };
  const ShowInvestigation = (investigation) => {
    investigations.map((i) => console.log("i", i));
    setInvestigation(investigation);
    toggleShowModal();
  };
  const onCancelForm = () => {
    setInvestigation(null);
    toggleFormModal();
  };

  const editInvestigation = (investigation) => {
    setInvestigation(investigation);
    toggleFormModal();
  };

  const handleDownload = async (id) => {
    const response = await Api.get(
      `/api/admin/investigation/${id}/media/download`,
      {
        ...getTokenHeader(),
        responseType: "blob",
      }
    );
    console.log("res", response);
    fileDownload(response.data, "investigations.zip");
  };

  const handleUploadExecution = (item) => {
    setProcessInvestigation(item);
  };
  const fields = !isPatientView
    ? [
        { key: "code", label: i18next.t("code") },
        { key: "name", label: i18next.t("Type") },
        { key: "author", label: i18next.t("Author") },
        { key: "date", label: i18next.t("date") },
        { key: "status", label: i18next.t("status") },
        { key: "actions", label: i18next.t("Actions") },
      ]
    : [
        { key: "name", label: i18next.t("Type") },
        { key: "author", label: i18next.t("Author") },
        { key: "date", label: i18next.t("date") },
        { key: "actions", label: i18next.t("Actions") },
      ];

  return (
    <>
      {episodeStatus === 0
        ? !isPatientView && (
            <>
              <CreateInvestigation
                toggle={toggleFormModal}
                onCancelForm={onCancelForm}
                setInvestigation={setInvestigation}
                modal={modal}
                centerType={centerType}
                investigationTypes={investigationTypes}
                investigation={investigation}
                modelId={modelId}
                patientId={patientId}
              />
            </>
          )
        : ""}
      <CCol xs={12}>
        <CCard className="past-medical-history-col-mobile">
          <CCardBody>
            <CDataTable
              responsive
              items={investigations}
              noItemsViewSlot={i18next.t("No Available Items")}
              fields={fields}
              striped
              scopedSlots={{
                name: (item) => (
                  <td>{`${item.investigation_types
                    .map((item) => item.name)
                    .toString()}`}</td>
                ),
                author: (item) => (
                  <td>{`${item.doctor.first_name} ${item.doctor.last_name}`}</td>
                ),
                status: (item) => (
                  <td>
                    {
                      statusList.find((status) => status.id === item.status)
                        .title
                    }
                  </td>
                ),
                actions: (item) => (
                  <td>
                    <CDropdown variant="btn-group">
                      <CDropdownToggle
                        className="action-toggle m-0"
                        color="light"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="c-icon"
                          role="img"
                        >
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                            className="ci-primary"
                          />
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                            className="ci-primary"
                          />
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                            className="ci-primary"
                          />
                        </svg>
                      </CDropdownToggle>
                      <CDropdownMenu className="p-0" placement="right-end">
                        <CDropdownItem
                          onClick={() => ShowInvestigation(item)}
                          className="text-primary"
                        >
                          <CIcon content={cilSearch} />
                          <span className="mx-1">{i18next.t("show")}</span>
                        </CDropdownItem>
                        <CDropdownItem
                          onClick={() => showSlides(item)}
                          className="text-info"
                        >
                          <CIcon content={cilNewspaper} />
                          <span className="mx-1">{i18next.t("Slides")}</span>
                        </CDropdownItem>
                        {item.status === 2 && isPatientView && (
                          <CDropdownItem
                            onClick={() => handleDownload(item.id)}
                            className="text-dark"
                          >
                            <CIcon content={cilCloudDownload} />
                            <span className="mx-1">
                              {i18next.t("Download")}
                            </span>
                          </CDropdownItem>
                        )}
                        {item.editable && !isPatientView && (
                          <CDropdownItem
                            onClick={() => editInvestigation(item)}
                            className="text-dark"
                          >
                            <CIcon content={cilPencil} />
                            <span className="mx-1">{i18next.t("Edit")}</span>
                          </CDropdownItem>
                        )}
                        {statusList.find((status) => status.id === item.status)
                          .title === "Done" ? (
                          ""
                        ) : (
                          <>
                            {!isPatientView && (
                              <CDropdownItem
                                onClick={() => handleUploadExecution(item)}
                                className="text-dark"
                              >
                                <CIcon content={cilNoteAdd} />
                                <span className="mx-1">
                                  {i18next.t("Execute")}
                                </span>
                              </CDropdownItem>
                            )}
                          </>
                        )}
                      </CDropdownMenu>
                    </CDropdown>
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
      {/* <CCol xs="12" className="create-new-btn-absolute">
            <CLink
              className="d-inline-block avh-btn avh-new-btn-width py-3 text-decoration-none text-white avh-font-bold text-uppercase"
              onClick={toggleFormModal}
            >
              Add New
            </CLink>
          </CCol> */}
      <br />
      <CCol xs={12}>
        <CCard className="past-medical-history-col-desktop">
          <CCardBody>
            <CDataTable
              responsive
              noItemsViewSlot={i18next.t("No Available Items")}
              items={investigations}
              fields={fields}
              striped
              scopedSlots={{
                name: (item) => (
                  <td>{`${item.investigation_types
                    .map((item) => item.name)
                    .toString()}`}</td>
                ),
                author: (item) => (
                  <td>{`${item.doctor.first_name} ${item.doctor.last_name}`}</td>
                ),
                status: (item) => (
                  <td>
                    {
                      statusList.find((status) => status.id === item.status)
                        .title
                    }
                  </td>
                ),
                actions: (item) => (
                  <td>
                    <CDropdown variant="btn-group">
                      <CDropdownToggle
                        className="action-toggle m-0"
                        color="light"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="c-icon"
                          role="img"
                        >
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                            className="ci-primary"
                          />
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                            className="ci-primary"
                          />
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                            className="ci-primary"
                          />
                        </svg>
                      </CDropdownToggle>
                      <CDropdownMenu className="p-0" placement="right-end">
                        <CDropdownItem
                          onClick={() => ShowInvestigation(item)}
                          className="text-primary"
                        >
                          <CIcon content={cilSearch} />
                          <span className="mx-1">{i18next.t("show")}</span>
                        </CDropdownItem>
                        <CDropdownItem
                          onClick={() => showSlides(item)}
                          className="text-info"
                        >
                          <CIcon content={cilNewspaper} />
                          <span className="mx-1">{i18next.t("Slides")}</span>
                        </CDropdownItem>
                        {item.status === 2 && isPatientView && showDownload && (
                          <CDropdownItem
                            onClick={() => handleDownload(item.id)}
                            className="text-dark"
                          >
                            <CIcon content={cilCloudDownload} />
                            <span className="mx-1">
                              {i18next.t("Download")}
                            </span>
                          </CDropdownItem>
                        )}
                        {item.editable && !isPatientView && (
                          <CDropdownItem
                            onClick={() => editInvestigation(item)}
                            className="text-dark"
                          >
                            <CIcon content={cilPencil} />
                            <span className="mx-1">{i18next.t("Edit")}</span>
                          </CDropdownItem>
                        )}
                        {statusList.find((status) => status.id === item.status)
                          .title === "Done" ? (
                          ""
                        ) : (
                          <>
                            {episodeStatus === 0 && !isPatientView && (
                              <CDropdownItem
                                onClick={() => handleUploadExecution(item)}
                                className="text-dark"
                              >
                                <CIcon content={cilNoteAdd} />
                                <span className="mx-1">
                                  {i18next.t("Execute")}
                                </span>
                              </CDropdownItem>
                            )}
                          </>
                        )}
                      </CDropdownMenu>
                    </CDropdown>
                  </td>
                ),
              }}
            />
            {episodeStatus === 0 && !isPatientView && (
              <CLink
                className="d-inline-block avh-btn-add py-3 text-decoration-none text-white avh-font-bold text-uppercase float-right"
                style={{position:"relative", zIndex:150}}
                onClick={toggleFormModal}
              >
                +
              </CLink>
            )}
          </CCardBody>
        </CCard>
      </CCol>
      <Show
        investigation={investigation}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
        statusList={statusList}
      />
      <ExecuteInvestigation
        modal={processInvestigation}
        external
        investigation={processInvestigation}
        onCancel={() => setProcessInvestigation(null)}
      />{" "}
      {sliderModal && (
        <Slider
          investigationId={investigation ? investigation.id : null}
          slides={investigation ? investigation.media : []}
          sliderModal={sliderModal}
          stopSlides={stopSlides}
        />
      )}
    </>
  );
};

export default List;
