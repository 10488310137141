import i18next from "i18next";

export const CLINIC_TYPE_PHYSICAL = "physical";
export const CLINIC_TYPE_VIRTUAL = "virtual";
export const CLINIC_SEARCH_MAX_DAYS = 31;
export const DAYS = [
  `${i18next.t("saturday")}`,
  `${i18next.t("sunday")}`,
  `${i18next.t("monday")}`,
  `${i18next.t("tuesday")}`,
  `${i18next.t("wednesday")}`,
  `${i18next.t("thursday")}`,
  `${i18next.t("friday")}`,
];
export const DAYSSTATUS = {
  saturday: false,
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
};
export const DAYSCONFIRMATION = {
  saturday: false,
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
};
