import React from "react";
import ReactFlow, { MiniMap, Controls, Background } from "react-flow-renderer";
import { hierarchyToReactFlow } from "helpers/helpers";

const nodeStyle = {
  background: "#fff",
  color: "#3f5192",
  padding: "25px",
  borderRadius: "8px",
  border: "1px solid #000",
  width: "300px",
  height: "160px",
  display: "flex",
  flexDirection: "column",
};

const textStyle = {
  fontSize: "12px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "5px",
  textAlign: "left",
};

const createNodeLabel = (data) => (
  <div style={nodeStyle}>
    <div style={{ fontWeight: "bold", marginBottom: "10px" }}>{data.hospital_name? data.hospital_name: 'All'}</div>
    <div style={textStyle}>
      {data.hospitals_count && <div>Hospitals:{data.hospitals_count}</div>}
      {data.hospital_id? <div>Hospital ID:{data.hospital_id}</div>: null}
      <div>Patients:{data.in_patients}</div>

      <div>Doctors:{data.doctors}</div>
      <div>Coordinators:{data.coordinators}</div>
      <div>Nurses:{data.nurses}</div>
      <div>Admins:{data.admins}</div>
      <div>Total Users:{data.total_users}</div>
      <div />
    </div>
  </div>
);

const SuperAdminFigures = ({ dashboard }) => {
  const hierarchy = {...dashboard.data, hospital_id: 0, hospitals: Object.values(dashboard.data_for_hospitals)};
  const {nodes, edges} = hierarchyToReactFlow(hierarchy, (node) => {
    return createNodeLabel(node);
  }, 350, 200);
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <ReactFlow nodes={nodes} edges={edges} fitView>
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default SuperAdminFigures;
