import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CAlert } from "@coreui/react";
import { HIDE_ERROR } from "actions/error/types";
import {Link} from "react-router-dom";
import { isArray } from "lodash";

const TheErrorNotification = () => {
  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();

  function handleClose(showVal) {
    if(error.isOpen && !showVal) {
      dispatch({type: HIDE_ERROR});
    }
  }
  const renderLink = (link) => {
    if (link) {
      return (
        <Fragment key={link}>
          <Link to={link}> or click here</Link>
          <br />
        </Fragment>);
    }
    return <></>;
  }

  const renderMessage = (errors) => {
    if (errors && isArray(errors)) {
      return errors.map((item) => (
        <Fragment key={item}>
          <span>{item}</span>
          <br />
        </Fragment>
      ));
    }
    return <span>{errors}</span>;
  };

  return (
    <CAlert
      color="danger"
      closeButton
      onShowChange={(showVal) => handleClose(showVal)}
      fade
      show={error.isOpen}
    >
      {renderMessage(error.error)}
      {renderLink(error.redirectPath?.redirectUrl)}
    </CAlert>
  );
};

export default TheErrorNotification;
