import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CButton,
  CCardFooter,
} from "@coreui/react";

// import { patientCreateAppointment } from "actions/appointments/patientAppointment";
import { getSignature, paymobPaymnetIntention } from "actions/payments/payments";
import { hideError, setError } from "actions/error/error";

const Paymob = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
	const [loading, setLoading] = useState(false);
	// const [error, setError] = useState('');
  const paymobIntention = useSelector((state) => state.payments.paymob_intention);
  const patientAppointmentSelection = useSelector(
    (state) => state.patientAppointmentSelection
  );
  const Amount = patientAppointmentSelection.price;

  useEffect(() => {
    if (!patientAppointmentSelection.appointment_id) {
      history.push("/patient/clinic/search");
    }

	}, [dispatch, history]);

	useEffect(() => {
		if (paymobIntention && paymobIntention.client_secret) {
			window.location.href = `https://accept.paymob.com/unifiedcheckout/?publicKey=${process.env.REACT_APP_PAYMOB_PUBLIC_SECRET}&clientSecret=${paymobIntention.client_secret}`;

		} else if (paymobIntention && paymobIntention.check_error) {
			setLoading(false);
			dispatch(setError({ message: ['Paymob Payment Intention Failed, Please try again later!'] }));
		}
	}, [paymobIntention])

  const checkout = () => {
		setLoading(true);
		dispatch(hideError());
    dispatch(paymobPaymnetIntention(patientAppointmentSelection.appointment_id));
  };

  return (
    <>
      <CRow>
        <CCol xs="12">
          <CCard>
            <h4 className="header-label-title">
              Pay By PAYMOB
            </h4>
            <CCardBody>
              <CRow>
                <CCol className="labels" xs="6" md="6">
                  Amount :
                </CCol>
                <CCol xs="6" md="6">
                  <strong>{Amount}</strong>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              {patientAppointmentSelection.appointment_code && (
                <CButton
                  onClick={checkout}
                  className="btn-form"
                  color="primary"
									disabled={loading}
                >
                  Pay
                </CButton>
              )}
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
export default Paymob;
