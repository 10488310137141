import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CSpinner } from "@coreui/react";
import {
  fetchSpecialtiesForm,
  updateSpecialtiesForm,
  fetchSpecialtiesFormUtilities,
} from "actions/specialties-form/specialtiesForm";
import { SURVEY_AVAILABILITY_RESTRICTED } from "constants/constants";
import SpecialtiesForm from "../form/SpecialtiesForm";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const EditLevel = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const specialtyForm = useSelector(
    (state) => state.specialtiesForm.specialtyForm
  );
  const utilities = useSelector((state) => state.specialtiesFormUtilities);
  const redirect = useSelector((state) => state.specialtiesForm.redirect);
  const label = "Edit Survey";

  const onSubmit = (formData) => {
    const data = {
      ...formData,
      hospital_id: formData.hospital ? formData.hospital.id : null,
      survey_availability: specialtyForm.survey_availability,
    };
    if (data.draft != 2) {
      confirmAlert({
        title: "Are you sure?",
        message: "that form is ready to be submitted?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(updateSpecialtiesForm(data, match.params.id));
              history.push("/specialties-forms")
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      dispatch(updateSpecialtiesForm(data, match.params.id));
      history.push("/specialties-forms")
    }
  };

  useEffect(() => {
    dispatch(fetchSpecialtiesForm(match.params.id));
    dispatch(fetchSpecialtiesFormUtilities());
  }, [dispatch, match.params.id]);

  if (redirect) {
    history.goBack();
  }

  const checkIsRestrictedSurvey =
    specialtyForm &&
    specialtyForm.parent &&
    specialtyForm.parent.survey_availability === SURVEY_AVAILABILITY_RESTRICTED;

  if (specialtyForm) {
    return (
      <SpecialtiesForm
        restricted={checkIsRestrictedSurvey}
        onSubmit={onSubmit}
        specialtyForm={specialtyForm}
        specialties={utilities.specialties || []}
        types={utilities.types || []}
        label={label}
        hospitals={utilities.hospitals || []}
        fields={utilities.fields || []}
      />
    );
  }
  return <CSpinner className="spinner" variant="grow" />;
};

export default EditLevel;
