import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import AppointmentForm from "../form/AppointmentForm";
import {
  fetchAppointment,
  editAppointment,
} from "../../../../actions/appointments/appointment";
import Loading from "views/partials/Loading";

/**
 * @param {*} props required props
 * @returns {*} appointment form component
 * @class
 */
function EditAppointment(props) {
  const history = useHistory();
  const { id: appointmentId } = useParams();
  const { appointment } = props;
  useEffect(() => {
    console.log("here did mount");
    props.fetchAppointment(appointmentId);
  }, [appointmentId, props]);

  const onSubmit = (formData) => {
    props.editAppointment(appointmentId, formData);
    history.push("/appointments");
  };

  if (appointment) {
    return (
      <>
        <AppointmentForm onSubmit={onSubmit} appointment={props.appointment} />
      </>
    );
  }
  return <Loading/>
}

const mapStateToProps = (state, ownProps) => {
  console.log("own", ownProps.match.params.id, state);
  return {
    appointment: state.appointments[ownProps.match.params.id],
  };
};

export default connect(mapStateToProps, { fetchAppointment, editAppointment })(
  EditAppointment
);
