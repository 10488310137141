import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import fileDownload from "js-file-download";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CLink,
  CImg,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { Api, getTokenHeader } from "services/Api";
import { fetchDocuments, deleteDocument } from "actions/users/users";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload, cilPencil, cilTrash } from "@coreui/icons";
import i18next from "i18next";

const Documents = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.users.documents);

  const handleDeleteDocument = (id: string) => {
    console.log(id);
    dispatch(deleteDocument(id));
  };

  const handleDownloadDocument = async (item) => {
    console.log(item.id);
    const response = await Api.get(
      `/api/admin/users/documents/${item.id}/download`,
      {
        ...getTokenHeader(),
        responseType: "blob",
      }
    );
    fileDownload(
      response.data,
      `${item.name}.${item.file_name.split(".").pop()}`
    );
  };

  useEffect(() => {
    dispatch(fetchDocuments(userId));
  }, [dispatch, userId]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">{i18next.t("Documents")}</div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            href={`/users/${userId}/documents/create`}
            className="create-button float-right wi"
          >
            {i18next.t("New Documents")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={documents ? documents.data : []}
                fields={[
                  { key: "name", label:  i18next.t("Name") },
                  { key: "actions", label:  i18next.t("Actions") },
                ]}
                hover
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                clickableRows
                scopedSlots={{
                  actions: (item) => (
                    <td>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle
                          className="action-toggle m-0"
                          color="light"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="c-icon"
                            role="img"
                          >
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                              class="ci-primary"
                            ></path>
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                              class="ci-primary"
                            ></path>
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                              class="ci-primary"
                            ></path>
                          </svg>
                        </CDropdownToggle>
                        <CDropdownMenu className="p-0">
                          <CDropdownItem
                            onClick={() => handleDownloadDocument(item)}
                            className="text-primary"
                          >
                            <CIcon content={cilCloudDownload} />
                            <span class="mx-1">{i18next.t("Download")}</span>
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={() => handleDeleteDocument(item.id)}
                            className="text-danger"
                          >
                            <CIcon content={cilTrash} />
                            <span class="mx-1">{i18next.t("Delete")}</span>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                  avatar: (item) => (
                    <td style={{ width: "12%" }}>
                      <CImg src={item.avatar} thumbnail className="mb-2" />
                    </td>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Documents;
