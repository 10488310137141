import React, { useEffect } from "react";
import { CCard, CCardBody, CCardTitle } from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAppointmentByCode } from "actions/appointments/patientAppointment";
import { PATIENT_APPOINTMENT_SELECTION } from "actions/appointments/types";
import PaymentMethod from "views/pages/payment/screens/PaymentMethod";
import AppointmentDetails from "../../partials/QuickPaymentAppointmentDetails";
import Loading from "views/partials/Loading";

const AppointmentQuickPayment = () => {
  const dispatch = useDispatch();
  const { appointmentCode } = useParams();
  const patientAppointment = useSelector((state) =>
    state.appointments ? state.patientAppointments.appointment : {}
  );
  const patientAppointmentSelection = useSelector(
    (state) => state.patientAppointmentSelection
  );

  useEffect(() => {
    dispatch(fetchAppointmentByCode(appointmentCode));
  }, [dispatch, appointmentCode]);

  useEffect(() => {
    if (patientAppointment && !patientAppointment.error) {
      const preparedData = {
        ...patientAppointment,
        appointment_id: patientAppointment.id,
        appointment_code: patientAppointment.code,
        clinic_id: patientAppointment.clinic.id,
      };
      dispatch({ type: PATIENT_APPOINTMENT_SELECTION, payload: preparedData });
    }
  }, [patientAppointment]);

  const renderErrorMsg = () => (
    <span className="alert-danger">{patientAppointment.message}</span>
  );

  const render = () => {
    if (patientAppointment && patientAppointment.error) {
      return renderErrorMsg();
    }
    if (patientAppointmentSelection.appointment_id) {
      return (
        <>
          <CCard>
            <CCardTitle>
              <h3>Follow up Appointment Payment</h3>
            </CCardTitle>
            <CCardBody>
              <p>
                you have an appointment was created for you as a follow up
                plane,if you accept it pay its fees to activate it, otherwise it
                will be canceled automatically after 3 days.
              </p>
              <AppointmentDetails
                appointment={{
                  ...patientAppointment,
                  doctor: `${patientAppointment.doctor.first_name} ${patientAppointment.doctor.last_name}`,
                  specialty: patientAppointment.specialty.name,
                }}
              />
            </CCardBody>
          </CCard>
          <PaymentMethod />
        </>
      );
    }
    return <Loading/>
  };

  return render();
};

export default AppointmentQuickPayment;
