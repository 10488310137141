import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CLink,
} from "@coreui/react";
import {
  fetchSpecialtiesFormUtilities,
  fetchPublicSubmissions,
  fetchPublicSubmission,
  deleteSpecialtiesForm,
} from "actions/specialties-form/specialtiesForm";
import { LIMIT } from "constants/constants";
import { RESET_SPECIALTIES_FORM } from "actions/specialties-form/types";
import FiltersPublicSection from "../form/FiltersPublicSection";
import PreviewSurvey from "../form/PreviewSurvey";
import AnswerPublicSurveys from "./AnswerPublicSurveys";
import EditPublicSurveys from "./EditPublicSurveys";
import i18next from "i18next";
import Show from "../../episode-management/screens/spoke/cases/tabs/followUp/Show";
import { fetchFollowUp } from "actions/follow-ups/followUps";

const PublicSurveys = ({patientId, hospital, patient}) => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const type = 'survey';
  const [modal, setModal] = useState(false);
  const [surveyInstanceId, setSurveyInstanceId] = useState(null);
  const surveyInstance = useSelector((state) => state.followUps.followUp);
  const showSurveyInstance = (id: string) => {
    dispatch(fetchFollowUp(id));
    setSurveyInstanceId(id);
    toggleShowModal();
  };
  const editPublicSurveys= (id: string) => {
    dispatch(fetchPublicSubmission(id));
    setSurveyInstanceId(id);
    toggleEditModal();
  };
  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  const toggleEditModal = () => {
    setEditModal(!editModal);
  };
  const toggle = () => {
    setModal(!modal);
    dispatch({ type: RESET_SPECIALTIES_FORM });//patients

  };

  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [previewSurvey, setPreviewSurvey] = useState(false);
  const dispatch = useDispatch();
  const specialtiesForm = useSelector((state) => state.specialtiesForm.publicSubmissions);
  const meta = useSelector((state) => state.specialtiesForm.meta);
  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/public-surveys?page=${newPage}`);
    }
  };
  const handleDeleteLevel = (id: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This Public Survey.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteSpecialtiesForm(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchSpecialtiesFormUtilities());
    if (patientId) {
      dispatch(fetchPublicSubmissions(page, LIMIT, {patientId: patient.id}));
    }else{
      dispatch(fetchPublicSubmissions(page, LIMIT));
    }
  }, [dispatch, currentPage, page, patient, patientId]);

  const onFilter = (filters) => {
    dispatch(fetchPublicSubmissions(page, LIMIT, filters));
  };
  const onClosePreview = () => {
    setPreviewSurvey(false);
    if (patientId) {
      dispatch(fetchPublicSubmissions(page, LIMIT, {patientId: patient.id}));
    }else{
      dispatch(fetchPublicSubmissions(page, LIMIT));
    }
  };

  return (
    <>
      <Show
          followUp={surveyInstance}
          showModal={showModal}
          toggleShowModal={toggleShowModal}
          followUpId={surveyInstanceId}
        />
      <EditPublicSurveys
          modal={editModal}
          toggle={toggleEditModal}
          id={surveyInstanceId}
        />
      <AnswerPublicSurveys toggle={toggle} modal={modal} type={type} patient={patient} hospital={hospital} />
      <CRow className="justify-content-between align-items-center my-1">
        <CCol md="6">
          <div className="float-left table-label-title">{i18next.t("Survey Submissions")}</div>
        </CCol>
        <CCol md="6">
          <CButton
            color="success"
            onClick={toggle}
            className="create-button float-right wi"
            style={{ width: "13em" }}
          >
            {i18next.t("New Public Survey")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
            {!patientId &&(
              <FiltersPublicSection onFilter={onFilter} />
             )}
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={specialtiesForm || []}
                fields={[
                  { key: "id", label:  i18next.t("id") },
                  { key: "title", label:  i18next.t("title") },
                  { key: "patient", label:  i18next.t("patient") },
                  { key: "specialty", label:  i18next.t("Specialty") },
                  { key: "hospital", label:  i18next.t("hospital") },
                  { key: "date", label:  i18next.t("date") },
                  { key: "actions", label:  i18next.t("Actions") },
               ]}
                hover
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                scopedSlots={{
                  title:(item) => (
                    <td>
                    {item.survey.title}
                    </td>
                  ),
                  patient:(item) => (
                    <td>
                    {`${item.patient.first_name} ${item.patient.last_name}`}
                    </td>
                  ),
                  specialty: (item) => <td>{item.specialty?.name}</td>,
                  hospital:(item) => (
                    <td>
                    {item.survey.hospital.name}
                    </td>
                  ),
                  date: (item) => (
                    <td>{item.created_at}</td>
                  ),
                  actions: (item) => (
                    <td>
                      <CButton
                        onClick={() => showSurveyInstance(item.id)}
                        style={{ marginRight: "5px" }}
                        className="btn btn-primary"
                      >
                        {i18next.t("Preview")}
                      </CButton>
                      {item.editable && <a
                        onClick={() => editPublicSurveys(item.id)}
                        style={{
                          marginRight: "5px",
                          "background-color": "rgb(48, 197, 182)",
                          "border-color": "rgb(48, 197, 182)",
                          color: "white",
                          width: "7em",
                        }}
                        className="btn"
                        >
                        {i18next.t("Edit")}
                      </a>
                    }
                    </td>
                  ),
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <PreviewSurvey
          surveyId={previewSurvey}
          onCancel={onClosePreview}
          modal={previewSurvey}
        />
      </CRow>
    </>
  );
};

export default PublicSurveys;