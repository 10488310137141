import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CSelect,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CTextarea,
} from "@coreui/react";
import { confirmAlert } from "react-confirm-alert";
import "./Form.css";
import AsyncSelect from "react-select/async";
import {
  getClassName,
  getValidation,
} from "views/pages/register/RegisterFormValidation";
import i18next from "i18next";
import { customStyles } from "views/rounds/form/RoundFormValidation";
import { TYPE_DISCHARGE_PRESCRIPTION, TYPE_APPOINTMENT } from "constants/constants";

const RenderSelectOptions = (options) => {
  if (options)
    return options.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name ? item.name : item.title}
      </option>
    ));
  return <></>;
};

const PrescriptionForm = ({
  utilities,
  onSubmit,
  modal,
  prescription,
  label,
  onCancel,
  getDrugOptions,
  canConfirmPrescription,
  type,
}) => {
  let defaultValues = {};
  if (prescription) {
    defaultValues = {
      ...prescription,
      form: prescription.form ? prescription.form.id : null,
    };
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    errors,
    setValue,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const Validation = getValidation(watch);

  useEffect(() => {
    if (prescription) {
      // const data = prescription;
      reset(prescription);
    }
    else {
      reset({});
      defaultValues = {};
    }
  }, [prescription, reset]);

  const renderDrugDetails = () => {
    const { drug } = watch();
    if (drug) {
      return (
        <>
          <table
            style={{ border: ".1em solid #3f5192" }}
            className="drug-details-table table table-borderless"
          >
            <tbody>
              <tr>
                <th>Form:</th>
                <td>{drug.dosage_form}</td>
              </tr>
              <tr>
                <th>Composition:</th>
                <td>{drug.composition}</td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
    return "";
  };

  const formAction = (status) => {
    setValue("status", status);
    handleSubmit(onSubmit)();
  };
  const cancelForm = () => {
    onCancel();
  };
  const handleConfirmPrescription = () => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Activate This Prescription")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            formAction(utilities.status_active);
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => { },
        },
      ],
    });
  };

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} onClose={cancelForm} size="lg">
        <CModalHeader>{label}</CModalHeader>
        <CModalBody>
          <CCol xs="12">
            <h4 className="header-label-title">{label}</h4>
          </CCol>
          <CFormGroup row>
            <CCol
              sm={12}
              md={6}
              xs={12}
              lg={6}
              xl={6}
              className="d-flex flex-column mb-2"
            >
              <CLabel className="labels required">{i18next.t("drugs")}</CLabel>
              <Controller
                control={control}
                id="drug"
                name="drug"
                rules={{ required: "please choose an option" }}
                styles={customStyles(!errors.drug)}
                cacheOptions
                autocomplete
                getOptionLabel={(option) => (option.drug_name +' - '+ option.composition)}
                getOptionValue={(option) => option.id}
                loadOptions={(inputValue) => getDrugOptions(inputValue)}
                as={AsyncSelect}
              />
            </CCol>
            <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
              {renderDrugDetails()}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol sm={12} md={6} xs={12} lg={6} xl={6} className="mb-2">
              <CLabel className="labels required">{i18next.t("Dosage")}</CLabel>
              <CInput
                className={`${getClassName(
                  "dosage",
                  i18next.t("dosage"),
                  watch,
                  errors
                )}`}
                type="text"
                id="dosage"
                name="dosage"
                innerRef={register(Validation.require)}
              />
            </CCol>
            <CCol sm={12} md={6} xs={12} lg={6} xl={6} className="mb-2">
              <CLabel className="labels required">{i18next.t("Form")}</CLabel>
              <CSelect
                className={`${getClassName(
                  "form_id",
                  i18next.t("form_id"),
                  watch,
                  errors
                )}`}
                custom
                name="form_id"
                id="form_id"
                innerRef={register(Validation.require)}
                placeholder="ddd"
              >
                <option value="" disabled selected>
                   {i18next.t("Select Form")}
                </option>
                {RenderSelectOptions(utilities.forms)}
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol sm={12} md={12} xs={12} lg={12} xl={12} className="mb-2">
              <CLabel className="labels required">{i18next.t("Route")}</CLabel>
              <CSelect
                custom
                className={`${getClassName(
                  "route_id",
                  "Select Route",
                  watch,
                  errors
                )}`}
                name="route_id"
                id="route_id"
                innerRef={register(Validation.require)}
              >
                <option value="" selected disabled>
                {i18next.t("Select Route")}
                </option>
                {RenderSelectOptions(utilities.routes)}
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
              <CLabel className="labels required">{i18next.t("Once Every")}:</CLabel>
              <CInput
                className={`${getClassName(
                  "frequency",
                  i18next.t("frequency"),
                  watch,
                  errors
                )}`}
                type="number"
                id="frequency"
                name="frequency"
                innerRef={register(Validation.require)}
                min={1}
              />
              <CSelect
                className={`${getClassName(
                  "frequency_time_unit",
                  "Time Unit",
                  watch,
                  errors
                )}`}
                custom
                name="frequency_time_unit"
                id="frequency_time_unit"
                innerRef={register(Validation.require)}
              >
                <option value="" selected disabled>
                  {i18next.t("Time Unit")}
                </option>
                {RenderSelectOptions(utilities.time_units)}
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
              <CLabel className="labels required">{i18next.t("For")}:</CLabel>
              <CInput
                className={`${getClassName(
                  "duration",
                  i18next.t("duration"),
                  watch,
                  errors
                )}`}
                type="number"
                id="duration"
                name="duration"
                innerRef={register(Validation.require)}
                min={1}
              />
              <CSelect
                className={`${getClassName(
                  "time_unit",
                  "Time Unit",
                  watch,
                  errors
                )}`}
                custom
                name="time_unit"
                id="time_unit"
                innerRef={register(Validation.require)}
              >
                <option value="" selected disabled>
                  {i18next.t("Time Unit")}
                </option>
                {RenderSelectOptions(utilities.time_units)}
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup>
            <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
              <CLabel className="labels">{i18next.t("Notes")}</CLabel>
              <CTextarea
                name="notes"
                id="notes"
                rows="3"
                placeholder ={`${i18next.t("Notes")}`+"..."}
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CInput type="hidden" name="status" innerRef={register} />
          {type !== TYPE_DISCHARGE_PRESCRIPTION && type !== TYPE_APPOINTMENT && (<CButton
              color="primary"
              onClick={() => formAction(utilities.status_draft)}
            >
              
            {i18next.t("Save Draft")}
            </CButton>
          )}
          {canConfirmPrescription && (
            <CButton
              style={{
                backgroundColor: "#30c5b6",
                borderColor: "#30c5b6",
                color: "white",
              }}
              onClick={handleConfirmPrescription}
            >
            {i18next.t("Confirm")}
              
            </CButton>
          )}
          <CButton type="reset" color="danger" onClick={cancelForm}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default PrescriptionForm;
