import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateAccessToken } from "actions/communications/communications";
import Room from "./ChatRoom";

const Chat = ({ room, lobby_id, participants, readOnly }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.communications.token);

  useEffect(() => {
    dispatch(generateAccessToken());
  }, [dispatch]);
  return token ? <Room token={token} room={room} lobby_id={lobby_id} participants={participants} readOnly={readOnly}/> : <></>;
};
export default Chat;
