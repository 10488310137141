import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { fetchVacation, updateVacation } from "actions/vacations/vacations";
import VacationForm from "../form/VacationForm";
import Loading from "views/partials/Loading";
import i18next from "i18next";

/**
 * @param root0
 * @param root0.match
 * @returns {*} vacation form
 * @class
 */
function EditVacation({ match }) {
  const history = useHistory();
  const label = `${i18next.t("Edit Vacation")}`;
  const dispatch = useDispatch();
  const vacation = useSelector((state) => state.vacations.vacation);
  const redirect = useSelector((state) => state.vacations.redirect);
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      date_from: format(formData.date_from, "Y-MM-dd"),
      date_to: format(formData.date_to, "Y-MM-dd"),
      doctor_id: formData.doctor ? formData.doctor.id : null,
      hospital_id: formData.hospital ? formData.hospital.id : null,
      clinic_id: formData.clinic ? formData.clinic.id : null,
    };
    dispatch(updateVacation(data, match.params.id));
  };

  if (redirect) {
    history.push("/vacations");
  }

  useEffect(() => {
    dispatch(fetchVacation(match.params.id));
  }, [dispatch, match.params.id]);
  if (vacation) {
    return (
      <>
        <VacationForm onSubmit={onSubmit} label={label} vacation={vacation} />
      </>
    );
  }

  return  <Loading/>
}

export default EditVacation;
