import { Api, getTokenHeader } from "services/Api";
import { NEW_RECEIVED_REQUEST } from "actions/requests/types";
import { NOTIFICATION_TYPE_REQUEST_CALL } from "constants/notifications";
import {
  RECEIVE_NOTIFICATION,
  LIST_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  LIST_MY_NOTIFICATIONS,
} from "./types";

export const receiveNotification = (notification: Object): Object => async (
  dispatch
) => {
  dispatch({ type: RECEIVE_NOTIFICATION, payload: notification });
  const notificationObj = JSON.parse(notification.notification);
  if (notificationObj.type === NOTIFICATION_TYPE_REQUEST_CALL) {
    dispatch({ type: NEW_RECEIVED_REQUEST, payload: notificationObj.request });
  }
};

export const listNotifications = (): Object => async (disptach) => {
  const response = await Api.get(
    `/api/admin/user-notifications`,
    getTokenHeader()
  );
  disptach({ type: LIST_NOTIFICATIONS, payload: response.data });
};

export const readNotification = (notificationId): Object => async (disptach) => {
  await Api.get(
    `/api/admin/notifications/${notificationId}/read`,
    getTokenHeader()
  );
  disptach({ type: REMOVE_NOTIFICATION, payload: notificationId });
};

export const listMyNotifications = (page: string, limit: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/my-notifications?page=${page}&limit=${limit}`, getTokenHeader());
  dispatch({ type: LIST_MY_NOTIFICATIONS, payload: response.data});
};
