import React from "react";
import {
  TEXT,
  CHECKBOX,
  RADIO,
  DROPDOWN,
  COMPOSITE,
} from "constants/questions";
import SurveyTextQuestion from "./questions/SurveyTextQuestion";
import SurveyCheckboxQuestion from "./questions/SurveyCheckboxQuestion";
import SurveyRadioQuestion from "./questions/SurveyRadioQuestion";
import SurveyDropDownQuestion from "./questions/SurveyDropDownQuestion";
import SurveyCompositeQuestion from "./questions/SurveyCompositeQuestion";

const SurveyQuestions = ({ questions, register, setValue, watch, questionNamePrefix = "" }) => {
  if (questions) {
    return questions.map((question, index) => {
      const questionName = questionNamePrefix
        ? `${questionNamePrefix}[${index}].survey_question_${question.id}`
        : `survey_question_${question.id}`;
      const questionValueName = questionNamePrefix
        ? `${questionNamePrefix}[${index}].question_${question.id}_value`
        : `question_${question.id}_value`;
      switch (Number(question.type)) {
        case TEXT:
          return (
            <SurveyTextQuestion
              question={question}
              register={register}
              setValue={setValue}
              questionName={questionName}
              key={questionName}
              questionValueName={questionValueName}
            />
          );
        case CHECKBOX:
          return (
            <SurveyCheckboxQuestion
              question={question}
              register={register}
              setValue={setValue}
              watch={watch}
              questionName={questionName}
              key={questionName}
              questionValueName={questionValueName}
            />
          );
        case RADIO:
          return (
            <SurveyRadioQuestion
              question={question}
              register={register}
              setValue={setValue}
              questionName={questionName}
              key={questionName}
              questionValueName={questionValueName}
            />
          );
        case DROPDOWN:
          return (
            <SurveyDropDownQuestion
              question={question}
              register={register}
              setValue={setValue}
              questionName={questionName}
              key={questionName}
              questionValueName={questionValueName}
            />
          );
        case COMPOSITE:
          return (
            <SurveyCompositeQuestion
              question={question}
              register={register}
              setValue={setValue}
              watch={watch}
              questionName={questionName}
              key={questionName}
            />
          );
        default:
          return "NA-type";
      }
    });
  }
  return "";
};
export default SurveyQuestions;