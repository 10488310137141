import React, { useState } from "react";
import CreateDiagnosis from "./Create";
import EditDiagnosis from "./Edit";
import List from "./List";

const SymptomsAndDiagnosis = () => {
  const [modal, setModal] = useState(false);
  const [diagnosis, setDiagnosis] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const toggle = (diagnosisObject) => {
    setDiagnosis(diagnosisObject);
    setModal(!modal);
  };
  if (isEditing) {
    return (
      <>
        <EditDiagnosis
          toggle={toggle}
          modal={modal}
          diagnosis={diagnosis}
          setIsEditing={setIsEditing}
        />
        <List toggle={toggle} setIsEditing={setIsEditing} />
      </>
    );
  }
  return (
    <>
      <CreateDiagnosis toggle={toggle} modal={modal} />
      <List toggle={toggle} setIsEditing={setIsEditing} />
    </>
  );
};
export default SymptomsAndDiagnosis;
