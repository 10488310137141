import React from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const AttendeeModal = ({ onSubmit, onCancel, modal, FormTitle }) => {
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
  });

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} size="lg" onClose={() => onCancel()}>
        <CModalHeader>{FormTitle}</CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol md="4">
              <CLabel>{i18next.t("First Name")}: </CLabel>
              <CInput
                required
                type="text"
                id="first_name"
                name="first_name"
                innerRef={register}
              />
            </CCol>
            <CCol md="4">
              <CLabel>{i18next.t("Last Name")}: </CLabel>
              <CInput
                required
                type="text"
                id="last_name"
                name="last_name"
                innerRef={register}
              />
            </CCol>
            <CCol md="4">
              <CLabel>{i18next.t("Email")}: </CLabel>
              <CInput
                required
                type="email"
                id="email"
                name="email"
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" size="sm" color="primary">
            <CIcon name="cil-scrubber" /> {i18next.t("Save")}
          </CButton>
          <CButton type="reset" size="sm" color="danger" onClick={onCancel}>
            <CIcon name="cil-ban" /> {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default AttendeeModal;
