import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { fetchUser } from "actions/users/users";
import i18next from "i18next";

const User = ({ match }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    dispatch(fetchUser(match.params.id));
  }, [dispatch, match.params.id]);
  if (user) {
    return (
      <CRow>
        <CCol lg={12}>
          <CCard>
            <h4 className="table-title">{i18next.t("User Details")}</h4>
            <CCardBody>
              <table className="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td className="labels">{i18next.t("User Name")}</td>
                    <td>
                      <strong>
                        {user.first_name} {user.last_name}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="labels">{i18next.t("Email")}</td>
                    <td>
                      <strong>{user.email}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="labels">{i18next.t("Birth Date")}</td>
                    <td>
                      <strong>{user.birth_date_formatted || i18next.t("No Available Data")}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="labels">{i18next.t("National ID")}</td>
                    <td>
                      <strong>{user.national_id || i18next.t("No Available Data")}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="labels">{i18next.t("Created At")}</td>
                    <td>
                      <strong>{user.created_at || i18next.t("No Available Data")}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="labels">{i18next.t("Updated At")}</td>
                    <td>
                      <strong>{user.updated_at || i18next.t("No Available Data")}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }
  return (
    <h4 className="text-align-center not-found">
      <strong>Not Found</strong>
    </h4>
  );
};

export default User;
