import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CLink,
} from "@coreui/react";
import find from "lodash/find";
import { Api } from "services/Api";
import { codes } from "./Form";
import i18next from "i18next";

const List = ({ toggle, setIsEditing }) => {
  const [diagnosisList, setDiagnosisList] = useState([]);
  const fields = ["code", "chornic", "note", "actions"];
  const getDiagnosisListView = () => {
    console.log(diagnosisList);
    const dataList = Object.values(diagnosisList);
    console.log(dataList);
    if (dataList) {
      return dataList.map((item) => {
        const element = { item };
        const code = find(codes, { id: parseInt(item.code, 10) });
        element.code = code ? code.title : item.code;
        element.chornic = item.chornic ? "Chronic" : "-";
        return element;
      });
    }
    return [];
  };

  const EditDiagnosis = (id: string) => {
    Api.get(`symptomsAndDiagnosis/${id}`)
      .then((response) => {
        if (response.data) {
          toggle(response.data);
          setIsEditing(true);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });

    /* async function fetchPrescription() {
          let result = null;
          try {
            result = await Api.get(`prescriptions/${id}`)
          } catch (error) {
            console.error(error.message);
          }
          console.log('result')
          props.toggle(result.data)
        }
        fetchPrescription(); */
  };
  const fetchList = async () => {
    let result = null;
    try {
      result = await Api.get("/symptomsAndDiagnosis");
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("fetchList Request canceled due to component un-mount");
      } else {
        console.error(error);
      }
      return;
    }
    console.log("result");
    setDiagnosisList(result.data);
  };

  const DeleteDiagnosis = (id: string) => {
    Api.delete(`symptomsAndDiagnosis/${id}`)
      .then((response) => {
        if (response.data.statusText === "OK") {
          console.log("success");
          fetchList();
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    console.log("list prescriptions");
    const source = axios.CancelToken.source();
    // fetchList();
    return () => {
      source.cancel();
    };
  }, [setDiagnosisList]);
  console.log(diagnosisList);
  return (
    <>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CCardHeader>
              <CLink onClick={toggle}>{i18next.t("Create New")}</CLink>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={getDiagnosisListView()}
                fields={fields}
                striped
                noItemsViewSlot={i18next.t("No Available Items")}
                itemsPerPage={5}
                pagination
                scopedSlots={{
                  actions: (item) => (
                    <>
                      <td>
                        <CLink onClick={() => EditDiagnosis(item.id)}>
                          {i18next.t("Edit")}
                        </CLink>
                      </td>
                      <td>
                        <CLink onClick={() => DeleteDiagnosis(item.id)}>
                          {i18next.t("Delete")}
                        </CLink>
                      </td>
                    </>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
export default List;
