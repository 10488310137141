import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import moment from "moment";
import { CSpinner } from "@coreui/react";
import {
  fetchClinicUtilities,
  fetchClinic,
  updateClinic,
} from "actions/clinics/clinics";
import { fetchDoctors, clearDoctorsList } from "actions/doctors/doctors";
import {
  fetchCoordinators,
  clearCoordinatorsList,
} from "actions/coordinators/coordinators";
import { removeEmptyProperties, transformData } from "helpers/helpers";
import ClinicForm from "../form/ClinicForm";
import i18next from "i18next";

/**
 * @param root0
 * @param root0.match
 * @returns {*} clinic form binded with clinic data
 * @class
 */
function EditClinic({ match }) {
  const history = useHistory();
  const label = `${i18next.t("Edit Clinic")}`;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const utilities = useSelector((state) => state.clinicsUtilities);
  const doctors = useSelector((state) => state.doctors);
  const coordinators = useSelector((state) => state.coordinators);
  const clinic = useSelector((state) => state.clinics.clinic);
  const redirect = useSelector((state) => state.clinics.redirect);
  const onSubmit = async (formData) => {
    const data = {
      ...removeEmptyProperties(formData),
      start_date: moment(formData.start_date).format("YYYY-MM-DD"),
      end_date: formData.end_date
        ? moment(formData.end_date).format("YYYY-MM-DD")
        : null,
      coordinators: JSON.stringify(transformData(formData.coordinators)),
      title: formData.title,
      time_zone: formData.time_zone.value
        ? formData.time_zone.value
        : formData.time_zone,
    };
    await dispatch(updateClinic(data, match.params.id));
    
  //   return new Promise(resolve => {
  //     setTimeout(() => {
  //       dispatch(updateClinic(data, match.params.id));
  //       resolve();
  //     }, 5000);
  // });

  };
  const handleSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty: event.target.value });
    dispatch(fetchDoctors({ ...filters, specialty: event.target.value }));
  };

  const handleLevelChange = (event) => {
    setFilters({ ...filters, level: event.target.value });
    dispatch(fetchDoctors({ ...filters, level: event.target.value }));
  };

  const handlSpokeChange = (event) => {
    setFilters({ ...filters, hospital: event.target.value });
    dispatch(fetchDoctors({ ...filters, hospital: event.target.value }));
    dispatch(fetchCoordinators({ hospital: event.target.value }));
  };

  if (redirect) {
    history.push("/clinics");
  }

  useEffect(() => {
    dispatch(fetchClinicUtilities());
    dispatch(fetchClinic(match.params.id));
    dispatch(clearDoctorsList());
    dispatch(clearCoordinatorsList());
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (clinic) {
      dispatch(
        fetchDoctors({
          specialty: clinic.specialty_id,
          level: clinic.level_id,
          hospital: clinic.hospital_id,
        })
      );
    }
  }, [dispatch, clinic]);

  if (clinic && doctors.data) {
    return (
      <ClinicForm
        onSubmit={onSubmit}
        clinic={clinic}
        levels={utilities.levels || []}
        specialties={utilities.specialties || []}
        time_units={utilities.time_units || []}
        hospitals={utilities.hospitals || []}
        label={label}
        handleSpecialtyChange={handleSpecialtyChange}
        handlSpokeChange={handlSpokeChange}
        handleLevelChange={handleLevelChange}
        doctors={doctors.data || []}
        coordinators={coordinators.data || []}
      />
    );
  }
  return <CSpinner className="spinner" variant="grow" />;
}

export default EditClinic;
