// @flow
import { Api, getTokenHeader } from "services/Api";
import { PAY_BY_FAWRY, PAY_BY_CARD, GET_SIGNATURE, LOG_PAYMENT_RESPONSE, PAYMOB_PAYMENT_INTENTION, PAYMOB_PAYMENT_RESULT_PROCESS } from "./types";

export const payByFawry = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/payments/fawry`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: PAY_BY_FAWRY, payload: response.data });
};

export const getSignature = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/payments/fawry/signature`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: GET_SIGNATURE, payload: response.data });
};

export const payByCard = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/payments/fawry/card`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: PAY_BY_CARD, payload: response.data });
};

export const logPaymentResponse = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/payments/log`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: LOG_PAYMENT_RESPONSE, payload: response.data });
};

/***************** PayMob ****************/
export const paymobPaymnetIntention = (appointmentId: String) => async (dispatch) => {
	const response = await Api.post(`/api/admin/payments/paymob/intention`, { appointmentId }, {
		...getTokenHeader(),
	});
  dispatch({ type: PAYMOB_PAYMENT_INTENTION, payload: response.data });
};

export const paymobPaymentResultProcess = (appointmentId: String, paymentResult: Object) => async (dispatch) => {
	const response = await Api.post(`/api/admin/payments/paymob/result`, { appointmentId, paymentResult}, {
		...getTokenHeader(),
	});
  dispatch({ type: PAYMOB_PAYMENT_RESULT_PROCESS, payload: response.data });
};
