import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCol,
  CCard,
  CCardBody,
  CRow,
  CCardHeader,
  CLink,
  CDataTable,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import i18next from "i18next";
import { fetchPrescription } from "actions/prescriptions/prescriptions";
import ExecutionFrom from "./ExecutionForm";
import Show from "../prescription/Show";
import CIcon from "@coreui/icons-react";
import {
  cilCheck,
  cilCheckCircle,
  cilOptions,
  cilPencil,
  cilSearch,
  cilXCircle,
} from "@coreui/icons";

const ListPrescriptionExecution = () => {
  const dispatch = useDispatch();
  const [executionModal, setExecutionModal] = useState(false);
  const [executionData, setExecutionData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [prescriptionId, setPrescriptionId] = useState(null);

  const prescriptionExecutions = useSelector(
    (state) => state.prescriptionExecutions
  );
  const prescription = useSelector((state) => state.prescriptions.prescription);
  const prescriptionUtilities = useSelector(
    (state) => state.prescriptionUtilities
  );

  const { todayExecutions, utilities } = prescriptionExecutions;
  const fields = [

    { key:"drug_name", label:  i18next.t("Drug Name") },
    { key:"execution_time", label:  i18next.t("Execution Time") },
    { key:"executed_at", label:  i18next.t("Executed At") },
    { key:"status", label:  i18next.t("status") },
    { key:"notes", label:  i18next.t("Notes") },
    { key: "actions", label:  i18next.t("Actions") },
  ];

  const toggle = () => {
    if (executionModal) {
      setExecutionData(false);
    }
    setExecutionModal(!executionModal);
  };

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const ShowPrescription = (id) => {
    setPrescriptionId(id);
    dispatch(fetchPrescription(id));
    toggleShowModal();
  };

  const toggleUpdateExecutionStatus = (id, status, item) => {
    setExecutionData({ id, status, item });
    toggle();
  };

  const renderAction = (item) => {
    if (
      Number(item.status) === Number(utilities.status_generated) ||
      item.editable
    ) {
      return (
        <>
          <td>
            <CDropdown variant="btn-group">
              <CDropdownToggle
                className="action-toggle m-0"
                color="light"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <CIcon content={cilOptions} />
              </CDropdownToggle>
              <CDropdownMenu placement="right-end" className="py-0">
                <CDropdownItem
                  className="text-primary"
                  onClick={() =>
                    toggleUpdateExecutionStatus(
                      item.id,
                      utilities.status_given,
                      item
                    )
                  }
                >
                  <CIcon content={cilCheckCircle} />
                  <span className="mx-1">{i18next.t("Given")}</span>
                </CDropdownItem>
                <CDropdownItem
                  className="text-info"
                  onClick={() =>
                    toggleUpdateExecutionStatus(
                      item.id,
                      utilities.status_not_given,
                      item
                    )
                  }
                >
                  <CIcon content={cilXCircle} />
                  <span className="mx-1">{i18next.t("Not Given")}</span>
                </CDropdownItem>
                <CDropdownItem
                  onClick={() => ShowPrescription(item.prescription_id)}
                  className="text-dark"
                >
                  <CIcon content={cilSearch} />
                  <span className="mx-1">{i18next.t("show")}</span>
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </td>
        </>
      );
    }
    return <td />;
  };
  const getStatusText = (status) => {
    switch (status) {
      case utilities.status_given:
        return "Given";
      case utilities.status_not_given:
        return "Not Given";
      default:
        return "Not Executed";
    }
  };

  return (
    <CRow>
      <CCol xs="12">
        <CCard>
          <CCardHeader>
            <h3 className="header-label-title">
              {i18next.t("prescription executions for today")}
            </h3>
          </CCardHeader>
          <CCardBody>
            <ExecutionFrom
              executionData={executionData}
              toggle={toggle}
              modal={executionModal}
            />
            <CDataTable
              items={todayExecutions ? todayExecutions.data : []}
              fields={fields}
              noItemsViewSlot={i18next.t("No Available Items")}
              striped
              scopedSlots={{
                drug: (item) => <td>{item.prescription.drug.drug_name}</td>,
                executed_at: (item) => <td>{item.executed_at ? item.executed_at : '-'}</td>,
                execution_time: (item) => <td>{item.execution_time ? item.execution_time : '-'}</td>,
                status: (item) => <td>{getStatusText(item.status)}</td>,
                notes: (item) => <td>{item.notes ? item.notes : '-'}</td>,
                actions: renderAction,
              }}
            />
            <Show
              prescription={prescription}
              prescriptionId={prescriptionId}
              showModal={showModal}
              toggleShowModal={toggleShowModal}
              utilities={prescriptionUtilities}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default ListPrescriptionExecution;
