import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CPagination,
  CDataTable,
  CButton,
  CInput,
  CSwitch,
  CLabel,
} from "@coreui/react";
import AsyncSelect from "react-select/async";
import { Api, getTokenHeader } from "services/Api";
import { fetchEpisodes } from "actions/episodes/episodes";
import { fetchPatient } from "actions/patients/patients";
import { LIMIT } from "constants/constants";
import { getAge } from "helpers/helpers";
import Avatar from "views/partials/Avatar";
import "../../../../../scss/style.scss";
import { cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const Cases = () => {
  const { hospitalId } = useParams();
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [episodeStatus, setEpisodeStatus] = useState(false);
  const dispatch = useDispatch();
  const episodes = useSelector((state) => state.episodes.data);
  const meta = useSelector((state) => state.episodes.meta);
  const [filters, setFilters] = useState({});
  const location = useLocation();

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/episodes/cases/${hospitalId}?page=${newPage}`);
    }
  };

  const getPatientOptions = async (inputValue) => {
    let options = [];
    const url = `/api/admin/patients/list?q=${inputValue}`;
    options = await Api.get(url, {
      ...getTokenHeader(),
    }).then((response) => response.data.data);
    return options;
  };

  const handlePatientChange = (e) => {
    dispatch(fetchPatient(e.id));
    history.push(`/episodes/spock/cases/${hospitalId}/create`);
  };

  const Show = (item) => {
    history.push(
      `/episodes/spok/case/${item.id}?logo=${location.search.split("=")[1]}`
    );
  };

  const handleSearchBoxChange = (event) => {
    setFilters({ q: event.target.value });
    dispatch(
      fetchEpisodes(page, hospitalId, LIMIT, episodeStatus, {
        ...filters,
        q: event.target.value,
      })
    );
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchEpisodes(page, hospitalId, LIMIT, episodeStatus));
  }, [dispatch, hospitalId, page, episodeStatus]);
  useEffect(() => {
    window.Echo.join(`hospital.${hospitalId}`).error((error) => {
      console.error(error);
    });
  }, [hospitalId]);
  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol sm={12} md={4} xs={12} lg={4} xl={4}>
          <div className="float-left table-label-title text-mobile-small-size">
            {i18next.t("Episodes")}
          </div>
        </CCol>
        <CCol sm={12} md={4} xs={12} lg={4} xl={4}>
          <CButton
            color="success"
            href={`/episodes/spock/cases/${hospitalId}/create`}
            className="create-button float-right episode-cases-table wi"
          >
            {i18next.t("New Episode")}
          </CButton>
        </CCol>
        <CCol className="float-right" sm={12} md={4} xs={12} lg={4} xl={4}>
          <AsyncSelect
            cacheOptions
            loadOptions={(inputValue) => getPatientOptions(inputValue)}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            getOptionValue={(option) => option.id}
            onChange={handlePatientChange}
            placeholder={i18next.t("Select From Previous Patients")}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCard className="episode-cases-table">
            <CCardHeader>
              <CRow>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CInput
                    className="search-input"
                    type="text"
                    placeholder={i18next.t("Search By Name , NationalID , Mobile")}
                    onChange={handleSearchBoxChange}
                  />
                  <CIcon
                    style={{ "margin-right": "0.5em" }}
                    content={cilSearch}
                    className="c-icon-xl search-icon"
                  ></CIcon>
                </CCol>
                <CCol
                  style={{ "margin-left": "11%" }}
                  sm={12}
                  md={4}
                  xs={12}
                  lg={4}
                  xl={4}
                >
                  <CLabel
                    className="labels mx-3"
                    style={{ "vertical-align": "middle", fontSize: "1em" }}
                    htmlFor="is_staff_member"
                  >
                    {i18next.t("Show Discharged")} 
                  </CLabel>
                  <CSwitch
                    style={{ "margin-left": "15%" }}
                    labelOn={i18next.t("Yes")}
                    labelOff={i18next.t("No")}
                    className="mr-3"
                    size="lg"
                    color="primary"
                    shape="pill"
                    onChange={(e) => setEpisodeStatus(e.target.checked ? 1 : 0)}
                  />
                  {/* <CLink href={`/episodes/spock/cases/${hospitalId}/create`}>
                    Create
                  </CLink> */}
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={episodes || []}
                fields={[ 
                { key: "name", label:  i18next.t("Name") },
                { key: "age", label:  i18next.t("Age") },
                { key: "uuid", label:  i18next.t("National ID") },
                { key: "department", label:  i18next.t("Department") },
                { key: "phone", label:  i18next.t("Phone") },
                { key: "checkin", label:  i18next.t("Check In") },
                { key: "status", label:  i18next.t("status") },
                { key: "discharge_time", label:  i18next.t("Discharge Time") },
                ]}
                hover
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                clickableRows
                scopedSlots={{
                  name: (item) => (
                    <td>
                      <div className="d-flex flex-row align-items-center">
                        <Avatar
                          src={item.patient.avatar}
                          alt="NA"
                          className="mx-4 avatar-img episode-cases-avatar"
                        />
                        <div>{`${item.patient.first_name} ${item.patient.last_name}`}</div>
                      </div>
                    </td>
                  ),
                  department: (item) => (
                    <td>
                      {item.department
                        ? item.department.name
                        : i18next.t("No Specific Department")}
                    </td>
                  ),
                  age: (item) => (<td>{getAge(item.patient.birth_date)}</td>),
                  checkin: (item) => (
                    <td>{item.created_at}</td>
                  ),
                  discharge_time: (item) => (
                    <td>{item.discharge_time ? item.discharge_time : "-"}</td>
                  ),
                  uuid: (item) => (
                    <td>{item.patient.uuid ? item.patient.uuid : "-"}</td>
                  ),
                  phone: (item) => (
                    <td>{item.patient?.phone ? item.patient?.phone : "-"}</td>
                  ),
                  status: (item) => (
                    <td>{item.status ? "Discharged" : "Active"}</td>
                  )
                }}
                onRowClick={(item) => Show(item)}
              />
            </CCardBody>
            <CPagination
              activePage={page}
              onActivePageChange={pageChange}
              pages={meta ? meta.last_page : 1}
              doubleArrows={false}
              align="center"
            />
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Cases;
