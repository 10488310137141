import React from "react";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import { connect } from "react-redux";

import {
  fetchAppointment,
  deleteAppointment,
} from "../../../../actions/appointments/appointment";

class AppointmentDelete extends React.Component {
  componentDidMount() {
    const { fetchAppointment: propsFetchAppointment, match } = this.props;
    propsFetchAppointment(match.params.id);
  }

  renderBody = () => {
    const { appointment } = this.props;
    if (appointment) {
      return `Are you sure to delete Appointment with id '${appointment.id}' ?`;
    }
    return "Are you sure to delete Appointment ?";
  };

  onConfirm = () => {
    const {
      deleteAppointment: propsDeleteAppointment,
      match,
      history,
    } = this.props;
    propsDeleteAppointment(match.params.id);
    history.push("/appointments");
  };

  render() {
    const { history } = this.props;
    return (
      <>
        <CModal show onClose={() => history.push("/appointments")}>
          <CModalHeader closeButton>Delete Hospital</CModalHeader>
          <CModalBody>{this.renderBody()}</CModalBody>
          <CModalFooter>
            <CButton color="danger" onClick={this.onConfirm}>
              Yes
            </CButton>

            <CButton
              color="success"
              onClick={() => history.push("/appointments")}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  hospital: state.hospitals[ownProps.match.params.id],
});

export default connect(mapStateToProps, {
  deleteAppointment,
  fetchAppointment,
})(AppointmentDelete);
