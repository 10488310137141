import { DAYS } from "constants/clinic";

const requiredMessage = "Field is required";
const requiredIf = (inputValue, getValue, otherName, otherValue) => {
  if (otherValue) {
    return getValue(otherName) === otherValue ? !!inputValue : true;
  }
  if (!getValue(otherName)) {
    // required if other is null
    return !!inputValue;
  }
  return true;
};

const convertTimeToMinutes = (time) => {
  const arr = time.split(":");
  return Number(arr[0]) * 60 + Number(arr[1]);
};
const validateTimeFromTo = (fromTime, toTime) => {
  return convertTimeToMinutes(fromTime) < convertTimeToMinutes(toTime);
};
const addDayTimesValidation = (watch, validation) => {
  const result = { ...validation };
  DAYS.map((item) => {
    result[`${item}_from`] = {
      validate: (value) => requiredIf(value, watch, item, true),
    };
    result[`${item}_to`] = {
      validate: (value) =>
        watch(`${item}_from`)
          ? validateTimeFromTo(watch(`${item}_from`), value)
          : true,
    };
    result[`${item}_slot_time`] = {
      validate: (value) => (watch(item) ? value > 0 : true),
    };
  });
  return result;
};
const getValidation = (watch) => {
  const validation = {
    specialty_form_id: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "Invalid Specialty Form",
      },
    },
    locations_id: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "Invalid location",
      },
    },
    con_locations_id: {
      pattern: {
        value: /^[0-9]+/i,
        message: "Invalid location",
      },
    },
    specialty_id: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid specialty",
      },
    },
    con_specialty_id: {
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid specialty",
      },
    },
    survey_id: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid survey",
      },
    }, 
    con_survey_id: {
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid survey",
      },
    },
    level_id: { required: requiredMessage },
    hospital_id: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid location",
      },
    },
    doctor_id: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid doctor",
      },
    },
    title: { required: requiredMessage, maxLength: 255 },
    type: { required: requiredMessage },
    price: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid price",
      },
    },
    address: { required: requiredMessage },
    start_date: { required: requiredMessage },
    end_date: { required: requiredMessage },
    physician_cancellation_tolerance: { required: requiredMessage },
    physician_tolerance_unit: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid unite",
      },
    },
    patient_cancellation_tolerance: { required: requiredMessage },
    patient_tolerance_unit: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid unite",
      },
    },
    last_appointment_tolerance: { required: requiredMessage },
    last_appointment_unit: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid unite",
      },
    },
    coordinators: { required: requiredMessage },
    follow_up_period: { required: requiredMessage },
    follow_up_unit: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid unite",
      },
    },
    follow_up_price: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid price",
      },
    },
    time_zone: {
      validate: (value) => value.value !== undefined || value.length,
    },
  };
  return addDayTimesValidation(watch, validation);
};
export default getValidation;
