import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import {
  CContainer,
  CCol,
  CRow,
  CCard,
  CCardBody,
  CImg,
  CButton,
  CCardFooter,
  CCardHeader,
  CBadge,
  CLink,
  CCardTitle,
  CCardSubtitle,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CTabs,
  CCardImg,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { confirmAlert } from "react-confirm-alert";
import {
  startCall,
  nextCall,
  endCall,
  completeCurrent,
  abortCurrent,
} from "actions/calls/calls";
import Lobby from "views/lobby/LobbyFactory";
import VideoChat from "views/communications/VideoChat";
import { CLINIC_TYPE_VIRTUAL } from "constants/clinic";
import CIcon from "@coreui/icons-react";
import {
  cilAudio,
  cilCalendar,
  cilCheckAlt,
  cilHospital,
  cilMediaPause,
  cilMediaPlay,
  cilMic,
  cilNotes,
  cilPencil,
  cilTrash,
  cilVideo,
  cilWarning,
} from "@coreui/icons";

import { TYPE_APPOINTMENT } from "constants/constants";
import Prescription from "views/episode-management/screens/spoke/cases/tabs/prescription/Prescription";
import Investigations from "views/episode-management/screens/spoke/cases/tabs/investigation/Investigation";
import Recommendations from "views/physician/screens/clinics/Recommendations";
import {
  fetchPrescriptionUtilities,
  fetchPrescriptions,
} from "actions/prescriptions/prescriptions";
import { fetchInvestigations } from "actions/episodes/investigations";
import CreateInvestigation from "views/episode-management/screens/spoke/cases/tabs/investigation/Create";
import QuestionChartComponent from "views/charts/QuestionsChartComponent";
import Examination from "./Examination";
import Diagnostics from "./DiagnosticsTab";
import PatientAttachmentsTab from "./PatientAttachmentsTab";

import PublicSurveys from "../../../specialties-forms/screens/PublicSurveys";
import meetingDoctor from "./meeting-doctor.jpg";
import meetingPerson from "./meeting-person.jpg";
import Container from "./Container";
import PatientCallCard from "./PatientCallCard";
import { fetchHospital } from "actions/hospitals/hospitals";

const VideoCall = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showLobby, setShowLobby] = useState(false);
  const [showLabsModal, setShowLabsModal] = useState(false);
  const [showRadsModal, setShowRadsModal] = useState(false);

  const [isEdittingLab, setIsEdittingLab] = useState(false);
  const [isEdittingRad, setIsEdittingRad] = useState(false);
  const [currentLab, setCurrentLab] = useState(null);
  const [currentRad, setCurrentRad] = useState(null);

  const [activeRotatedTab, setActiveRotatedTab] = useState("FOR_DOCTOR");
  const callsData = useSelector((state) => state.calls);
  const hospital = useSelector((state) => state.hospitals.hospital);
  const { currentAppointment, remaining_patients } = callsData;
  const lobbyToken = match.params.token;
  const { clinic } = callsData;



  const labInvestigations = useSelector(
    (state) => state.investigation.labInvestigations
  );

  const labInvestigationTypes = useSelector(
    (state) => state.investigation.labInvestigationTypes
  );

  const radInvestigationTypes = useSelector(
    (state) => state.investigation.radInvestigationTypes
  );

  const radInvestigations = useSelector(
    (state) => state.investigation.radInvestigations
  );

  const [showOverlay, setShowOverlay] = useState(false);

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const [overlayPosition, setOverlayPosition] = useState({ right: 0 });

  useEffect(() => {
    dispatch(startCall(lobbyToken));
  }, [dispatch, lobbyToken]);

  useEffect(() => {
    if (currentAppointment) {
      dispatch(fetchPrescriptionUtilities());
      dispatch(fetchPrescriptions(currentAppointment.id, TYPE_APPOINTMENT));
      dispatch(fetchInvestigations(currentAppointment.id, TYPE_APPOINTMENT));
      dispatch(fetchHospital(clinic.hospital_id));

    }
  }, [currentAppointment, dispatch]);

  const getNextCall = () => {
    dispatch(nextCall(lobbyToken));
  };

  const handleEndRoom = () => {
    confirmAlert({
      title: "Are you sure?",
      message: `There Are ${
        remaining_patients
      } Patients In Lobby, Do you Want To End The Clinic Room !`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(endCall(lobbyToken));
            history.push("/clinics/my-clinics");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleComplete = () => {
    dispatch(completeCurrent(lobbyToken));
  };

  const handleAbort = () => {
    dispatch(abortCurrent(lobbyToken));
  };

  const toggleLabsModal = () => {
    setShowLabsModal(!showLabsModal);
  };

  const toggleRadsModal = () => {
    setShowRadsModal(!showRadsModal);
  };

  const onEditLab = (item) => {
    console.log("onEditLab", item);
    setCurrentLab(item);
    setIsEdittingLab(true);
  };
  const onDeleteLab = (item) => {
    setCurrentLab(item);
    setIsEdittingLab(false);
  };
  const onEditRad = (item) => {
    console.log("onEditRad", item);
    setCurrentRad(item);
    setIsEdittingRad(true);
  };
  const onDeleteRad = (item) => {
    setCurrentRad(item);
    setIsEdittingRad(false);
  };

  const EmptyContent = () => (
    <>
      <CRow className="justify-content-center clinic-empty-content">
        <CCol xs="auto">
          <CIcon content={cilHospital} className="clinic-empty-content-icon" />
          <div className="clinic-empty-content-text">Empty</div>
        </CCol>
      </CRow>
    </>
  );

  const renderLabsRads = (data, onClick, onEdit, onDelete, rad = true) => (
    <>
      {data &&
        data.map((item) => {
          console.log(item);
          return (
            <CRow className="justify-content-center">
              <CCol xs={12}>
                <CCard className="order-lab-card">
                  <CRow className="align-items-center">
                    <CCol xs="2">
                      <CCard
                        style={{
                          width: "50px",
                          height: "50px",
                          margin: "3px",
                          borderRadius: "20px",
                          backgroundColor: rad ? "red" : "#9C72D6",
                        }}
                      />
                    </CCol>
                    <CCol
                      xs="8"
                      className="d-flex text-align-center px-2 flex-column"
                    >
                      <h3 className="font-bold order-lab-card-text">
                        {item?.investigation_types?.map((innerItem) => (
                          <div>
                            {item?.investigation_types?.length > 1 && (
                              <span>&#8226; </span>
                            )}

                            {innerItem.name}
                          </div>
                        ))}
                      </h3>
                      <div className="order-lab-card-date d-flex flex-row align-items-center">
                        {/* <CIcon content={cilCalendar} /> */}
                        <span className="px-1">{item?.date}</span>
                      </div>
                      {item?.order_notes && (
                        <div className="order-lab-card-note d-flex flex-row align-items-center">
                          {/* <CIcon content={cilNotes} /> */}
                          <span className="px-1">{item?.order_notes}</span>
                        </div>
                      )}
                    </CCol>
                    <CCol xs="2">
                      <CRow className="justify-content-end">
                        <CCol>
                          <CDropdown variant="btn-group">
                            <CDropdownToggle
                              className="action-toggle m-0"
                              color="light"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="c-icon"
                                role="img"
                              >
                                <path
                                  fill="var(--ci-primary-color, currentColor)"
                                  d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                                  className="ci-primary"
                                />
                                <path
                                  fill="var(--ci-primary-color, currentColor)"
                                  d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                                  className="ci-primary"
                                />
                                <path
                                  fill="var(--ci-primary-color, currentColor)"
                                  d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                                  className="ci-primary"
                                />
                              </svg>
                            </CDropdownToggle>
                            <CDropdownMenu className="p-0">
                              <CDropdownItem
                                onClick={() => {
                                  onEdit(item);
                                  onClick();
                                }}
                                className="text-primary"
                              >
                                <CIcon content={cilPencil} />
                                <span className="mx-1">Edit</span>
                              </CDropdownItem>
                              {/* <CDropdownItem onClick={() => {
                              onDelete(item);
                              onClick();
                            }} className="text-danger">
                              <CIcon content={cilTrash} />
                              <span class="mx-1">Delete</span>
                            </CDropdownItem> */}
                            </CDropdownMenu>
                          </CDropdown>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCard>
              </CCol>
            </CRow>
          );
        })}
      {(!data || data?.length == 0) && <EmptyContent />}
      <CRow className="justify-content-center">
        <CCol xs={12}>
          <CButton
            className="lab-add-new w-100 text-decoration-none btn-sm py-2"
            onClick={() => {
              setCurrentLab(null);
              setCurrentRad(null);
              onClick();
            }}
          >
            {i18next.t("Add New")}
          </CButton>
        </CCol>
      </CRow>
    </>
  );

  const renderCurrentPatientData = () => {
    if (currentAppointment) {
      console.log(currentAppointment);
      return (
        <>
          <PatientCallCard
            item={currentAppointment}
            complaint={currentAppointment.complaint}
          />
          {/* <CRow>
            {clinic && clinic.type === CLINIC_TYPE_VIRTUAL && (
              <VideoChat roomName={`appointment.${currentAppointment.id}`} />
            )}
          </CRow> */}
        </>
      );
    }
    return (
      <h4>
        <h4>
          {i18next.t(
            "There is no patient in the room, press next to get the next one, or end to end the clinic room."
          )}
        </h4>
      </h4>
    );
  };

  const nextAppointment = () => (
    <CRow>
      <CCol xs="12">
        <h4>
          {i18next.t(
            "There is no patient in the room, press next to get the next one, or end to end the clinic room."
          )}
        </h4>
      </CCol>
      <CCol xs="12">
        <CRow className="justify-content-center">
          <CButton
            className="float-right mx-1"
            color="success"
            onClick={getNextCall}
          >
            <CIcon content={cilMediaPlay} className="mx-1" />
            {i18next.t("Start Next")}
          </CButton>
        </CRow>
      </CCol>

      {/* <CCard className="my-1 text-theme-blue clinic-card card-zoom-out">
          <CCardBody className="py-1">
            <CRow className="align-items-center justify-content-between">
              <CCol md="1">
                <CRow>
                  <CCol xs="12">
                    <CImg
                      src="https://picsum.photos/200/300?random=1"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        objectFit: "cover"
                      }}
                      className="float-left"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="8">
                <CRow className="align-items-center justify-content-center">
                  <CCol xs="auto" className="d-flex flex-column p-1">
                    <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                      Mohamed Ali
                    </CCardTitle>
                    <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                      Head Ache
                    </CCardSubtitle>
                  </CCol>
                  <CCol xs="auto" className="d-flex flex-column p-1">
                    <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">Time</CCardTitle>
                    <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                      2021-11-30 02:45:00
                    </CCardSubtitle>
                  </CCol>
                  <CCol xs="auto" className="d-flex flex-column p-1">
                    <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">Status</CCardTitle>
                    <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                      Confirmed
                    </CCardSubtitle>
                  </CCol>
                  <CCol xs="auto" className="d-flex flex-column p-1">
                    <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">Order</CCardTitle>
                    <CCardSubtitle className="clinic-subtitle float-left w-fcontent">10</CCardSubtitle>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="3">
                <CRow className="justify-content-end align-items-center">
                  <CCol xs="12" className="d-flex flex-column">
                    <CButton
                      className="float-right"
                      color="success"
                    >
                      <CIcon content={cilMediaPlay} className="mx-1" />
                      Start Next
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard> */}
    </CRow>
  );
  return (
    <CContainer fluid className="px-0 mx-0">
      <CRow
        className="px-0 mx-0 position-relative"
        // onMouseMove={handleMouseMove}
        // onMouseUp={handleMouseUp}
        // onMouseLeave={handleMouseLeave}
      >
        <CCol md="12" className="mt-5 px-lg-5 px-md-0 position-relative">
          <div className="px-lg-5 px-md-0">
            <CRow className="justify-content-between align-items-center my-1">
              <CCol>
                <div
                  className="float-left table-title title-before"
                  onClick={() => {
                    history.push(`/lobby/${lobbyToken}`);
                  }}
                >
                  {i18next.t("appointments")}
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" className="my-2">
                <CRow className="justify-content-end">
                  {currentAppointment && (
                    <>
                      <CButton
                        color="success"
                        onClick={handleComplete}
                        className="mx-1"
                      >
                        <CIcon content={cilCheckAlt} className="mx-1" />
                        {i18next.t("Finish Successfully")}
                      </CButton>
                      <CButton
                        color="warning"
                        onClick={handleAbort}
                        className="mx-1"
                      >
                        <CIcon content={cilWarning} className="mx-1" />
                        {i18next.t("Abort")}
                      </CButton>
                    </>
                  )}
                  <CButton
                    className="mx-1"
                    color="info"
                    onClick={() => setShowLobby(!showLobby)}
                  >
                    {showLobby
                      ? i18next.t("Hide Lobby")
                      : i18next.t("Show Lobby")}
                  </CButton>

                  <CButton
                    color="danger"
                    className="float-right mx-1"
                    onClick={handleEndRoom}
                  >
                    <CIcon content={cilMediaPause} className="mx-1" />
                    {i18next.t("End Today’s Clinic")}
                  </CButton>
                </CRow>
              </CCol>
              {!showOverlay && (
                <CCol
                  className="position-absolute"
                  style={{ right: 0, zIndex: 1, paddingTop: 150, marginRight: 11, maxWidth: '1%'}}
                  // onMouseDown={handleMouseDown}
                >
                  {/* <div className="rotated-tabs-wrap px-0 position-absolute"> */}
                  <div
                    className="rotated-tabs-wrap"
                    style={{ display: "flex" }}
                  >
                    <ul
                      className="rotated-tabs"
                      style={{ display: "flex", listStyle: "none", padding: 0 }}
                    >
                      <li
                        // className={activeRotatedTab == "close" ? "active" : ""}
                        onClick={() => {
                          toggleOverlay();
                          setActiveRotatedTab("FOR_DOCTOR");
                        }}
                      >
                        <CLink>{i18next.t("Open")}</CLink>
                      </li>
                      <li
                        className={activeRotatedTab == "ORDER" ? "active" : ""}
                        onClick={() => {
                          toggleOverlay();
                          setActiveRotatedTab("ORDER");
                        }}
                      >
                        <CLink>{i18next.t("ORDERS")}</CLink>
                      </li>
                      <li
                        className={
                          activeRotatedTab == "FOR_DOCTOR" ? "active" : ""
                        }
                        onClick={() => {
                          toggleOverlay();
                          setActiveRotatedTab("FOR_DOCTOR");
                        }}
                      >
                        <CLink>{i18next.t("DOCTOR")}</CLink>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={[
                      "rotated-tabs-content float-right ",
                      activeRotatedTab == "ORDER" ? "d-block" : "d-none",
                    ].join(" ")}
                  >
                    <CTabs activeTab="OrdersPrescription">
                      <CNav
                        variant="tabs"
                        className="flex-nowrap rotated-tabs-content-tabs"
                      >
                        <CNavItem>
                          <CNavLink
                            data-tab="OrdersPrescription"
                            className="text-body font-weight-bold p-3"
                          >
                            {i18next.t("Prescription")}
                          </CNavLink>
                        </CNavItem>
                        <CNavItem>
                          <CNavLink
                            data-tab="Labs"
                            className="text-body font-weight-bold p-3"
                          >
                            {i18next.t("labs")}
                          </CNavLink>
                        </CNavItem>
                        <CNavItem>
                          <CNavLink
                            data-tab="Rads"
                            className="text-body font-weight-bold p-3"
                          >
                            {i18next.t("Radiology")}
                          </CNavLink>
                        </CNavItem>
                        <CNavItem>
                          <CNavLink
                            data-tab="OrdersRecommendations"
                            className="text-body font-weight-bold p-3"
                          >
                            {i18next.t("recommendations")}
                          </CNavLink>
                        </CNavItem>
                      </CNav>
                      <CTabContent>
                        <CTabPane data-tab="OrdersPrescription">
                          <Prescription
                            type={TYPE_APPOINTMENT}
                            modelId={
                              currentAppointment ? currentAppointment.id : 0
                            }
                            showStatus={0}
                            canConfirm
                            showCreate
                          />
                          {/* <div style={{ marginTop: "35%" }}>
                    <CRow className="d-flex justify-content-center">
                      <CCol>
                        <CImg className="" src="https://www.iconpacks.net/icons/2/free-medicine-icon-3260-thumb.png" width={100} height={100} />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <CButton className="btn btn-primary mb-3 text-center item-align-center" style={{ borderRadius: "20px", width: "130px", backgroundColor: "#35488D", fontFamily: " Arial, sans-serif" }} >
                          <CIcon name="cil-hospital" className="mfe-2" />
                          ADD NEW
                        </CButton>
                      </CCol>
                    </CRow>
                  </div> */}
                        </CTabPane>
                        <CTabPane data-tab="Labs" className="px-2">
                          {renderLabsRads(
                            labInvestigations,
                            toggleLabsModal,
                            onEditLab,
                            onDeleteLab
                          )}

                          {/* <Investigations
                    modelId={currentAppointment ? currentAppointment.id : 0}
                    patientId={currentAppointment ? currentAppointment.patient_id : 0}
                  /> */}
                        </CTabPane>
                        <CTabPane data-tab="Rads" className="px-2">
                          {renderLabsRads(
                            radInvestigations,
                            toggleRadsModal,
                            onEditRad,
                            onDeleteRad,
                            false
                          )}
                        </CTabPane>
                        <CTabPane
                          data-tab="OrdersRecommendations"
                          className="clinic-recommendations"
                        >
                          {currentAppointment && (
                            <Recommendations appointment={currentAppointment} />
                          )}
                          <div />
                        </CTabPane>
                      </CTabContent>
                    </CTabs>
                  </div>
                </CCol>
              )}
            </CRow>

            {clinic &&
            clinic.type === CLINIC_TYPE_VIRTUAL &&
            currentAppointment ? (
              <VideoChat roomName={`appointment.${currentAppointment.id}`} />
            ) : (
              <></>
            )}
            {currentAppointment && (
              <PatientCallCard
                item={currentAppointment}
                complaint={currentAppointment.complaint}
              />
            )}
            {!currentAppointment && (
              <div className="separator my-1">
                {i18next.t("Next Appointment")}
              </div>
            )}
            {!currentAppointment && nextAppointment()}

            <div className="py-2">
              {showLobby && (
                <Lobby
                  match={match}
                  hideLobby={() => setShowLobby(false)}
                  hideActions
                />
              )}
            </div>
          </div>
        </CCol>
        {showOverlay && (
          <CCol
            md="8"
            className="px-0 position-absolute"
            style={{ right: overlayPosition.right }}
          >
            <div className="rotated-tabs-wrap">
              <ul className="rotated-tabs">

                <li

                  onClick={() => {
                    toggleOverlay();
                    setActiveRotatedTab("");
                  }}
                >
                  <CLink>{i18next.t("CLOSE")}</CLink>
                </li>
                <li
                  className={activeRotatedTab == "ORDER" ? "active" : ""}
                  onClick={() => {
                    setActiveRotatedTab("ORDER");
                  }}
                >
                  <CLink>{i18next.t("ORDERS")}</CLink>
                </li>
                <li
                  className={activeRotatedTab == "FOR_DOCTOR" ? "active" : ""}
                  onClick={() => {
                    setActiveRotatedTab("FOR_DOCTOR");
                  }}
                >
                  <CLink>{i18next.t("FOR DOCTOR")}</CLink>
                </li>
              </ul>
            </div>
            <div
              className={[
                "rotated-tabs-content float-right ",
                activeRotatedTab == "ORDER" ? "d-block" : "d-none",
              ].join(" ")}
            >
              <CTabs activeTab="OrdersPrescription">
                <CNav
                  variant="tabs"
                  className="flex-nowrap rotated-tabs-content-tabs"
                >
                  <CNavItem>
                    <CNavLink
                      data-tab="OrdersPrescription"
                      className="text-body font-weight-bold p-3"
                    >
                      {i18next.t("Prescription")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="Labs"
                      className="text-body font-weight-bold p-3"
                    >
                      {i18next.t("labs")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="Rads"
                      className="text-body font-weight-bold p-3"
                    >
                      {i18next.t("Radiology")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="OrdersRecommendations"
                      className="text-body font-weight-bold p-3"
                    >
                      {i18next.t("recommendations")}
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent>
                  <CTabPane data-tab="OrdersPrescription">
                    {currentAppointment?.id && <Prescription
                      type={TYPE_APPOINTMENT}
                      modelId={currentAppointment ? currentAppointment.id : 0}
                      showStatus={0}
                      canConfirm
                      showCreate
                    />}
                    {/* <div style={{ marginTop: "35%" }}>
                    <CRow className="d-flex justify-content-center">
                      <CCol>
                        <CImg className="" src="https://www.iconpacks.net/icons/2/free-medicine-icon-3260-thumb.png" width={100} height={100} />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <CButton className="btn btn-primary mb-3 text-center item-align-center" style={{ borderRadius: "20px", width: "130px", backgroundColor: "#35488D", fontFamily: " Arial, sans-serif" }} >
                          <CIcon name="cil-hospital" className="mfe-2" />
                          ADD NEW
                        </CButton>
                      </CCol>
                    </CRow>
                  </div> */}
                  </CTabPane>
                  <CTabPane data-tab="Labs" className="px-2">
                    {currentAppointment && renderLabsRads(
                      labInvestigations,
                      toggleLabsModal,
                      onEditLab,
                      onDeleteLab
                    )}

                    {/* <Investigations
                    modelId={currentAppointment ? currentAppointment.id : 0}
                    patientId={currentAppointment ? currentAppointment.patient_id : 0}
                  /> */}
                  </CTabPane>
                  <CTabPane data-tab="Rads" className="px-2">
                    {currentAppointment && renderLabsRads(
                      radInvestigations,
                      toggleRadsModal,
                      onEditRad,
                      onDeleteRad,
                      false
                    )}
                  </CTabPane>
                  <CTabPane
                    data-tab="OrdersRecommendations"
                    className="clinic-recommendations"
                  >
                    {currentAppointment && (
                      <Recommendations appointment={currentAppointment} />
                    )}
                    <div />
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </div>
            <div />
            <div
              className={[
                "rotated-tabs-content float-right",
                activeRotatedTab == "FOR_DOCTOR" ? "d-block" : "d-none",
              ].join(" ")}
            >
              <CTabs activeTab="ForDoctorExamination">
                <CNav
                  variant="tabs"
                  className="flex-nowrap rotated-tabs-content-tabs"
                >
                  <CNavItem>
                    <CNavLink
                      data-tab="ForDoctorExamination"
                      className="text-body font-weight-bold p-3"
                    >
                      {i18next.t("Examination")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="ForDoctorDiagnostics"
                      className="text-body font-weight-bold p-3"
                    >
                      {i18next.t("ICD Codes")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="ForDoctorPatientAttachments"
                      className="text-body font-weight-bold p-3"
                    >
                      {i18next.t("Attachments")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="ForDoctorPatientSubmitions"
                      className="text-body font-weight-bold p-3"
                    >
                      {i18next.t("Survey Submissions")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="ForDoctorSurveyStatistics"
                      className="text-body font-weight-bold p-3"
                    >
                      {i18next.t("Survey Statistics")}
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent>
                  <CTabPane
                    data-tab="ForDoctorExamination"
                    className="clinic-examination"
                  >
                    {currentAppointment && (
                      <Examination appointment={currentAppointment} />
                    )}
                  </CTabPane>
                  <CTabPane
                    data-tab="ForDoctorDiagnostics"
                    className="clinic-diagnostics"
                  >
                    {currentAppointment && (
                      <Diagnostics appointment={currentAppointment} />
                    )}
                  </CTabPane>
                  <CTabPane data-tab="ForDoctorPatientAttachments">
                    {currentAppointment && (
                      <PatientAttachmentsTab appointment={currentAppointment} />
                    )}
                  </CTabPane>
                  <CTabPane data-tab="ForDoctorPatientSubmitions">
                    {currentAppointment && (
                      <PublicSurveys
                        patientId={currentAppointment.patient_id}
                        patient={currentAppointment.patient}
                        hospital={hospital}
                      />
                    )}
                  </CTabPane>
                  <CTabPane data-tab="ForDoctorSurveyStatistics">
                    {currentAppointment && (
                      <QuestionChartComponent
                        patientId={currentAppointment.patient_id}
                        chartColWidth={12}
                      />
                    )}
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </div>
          </CCol>
        )}
      </CRow>
      {/* {!showOverlay && (
        <button className="button button-container" onClick={toggleOverlay}>
          Open
        </button>
      )} */}

      <CreateInvestigation
        toggle={() => setShowLabsModal(!showLabsModal)}
        // onCancelForm={onCancelForm}
        setInvestigation={(item) => setCurrentLab(item)}
        modal={showLabsModal}
        centerType={1}
        investigationTypes={labInvestigationTypes}
        investigation={currentLab}
        modelId={currentAppointment ? currentAppointment.id : 0}
        patientId={currentAppointment ? currentAppointment.patient_id : 0}
      />

      <CreateInvestigation
        toggle={() => setShowRadsModal(!showRadsModal)}
        // onCancelForm={onCancelForm}
        setInvestigation={(item) => setCurrentRad(item)}
        modal={showRadsModal}
        centerType={2}
        investigationTypes={radInvestigationTypes}
        investigation={currentRad}
        modelId={currentAppointment ? currentAppointment.id : 0}
        patientId={currentAppointment ? currentAppointment.patient_id : 0}
      />
    </CContainer>
  );
};

export default VideoCall;
