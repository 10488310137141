const requiredMessage = "Field is required";

export const getValidation = (watch) => {
  const validation = {
    name: { required: requiredMessage, maxLength: 255 },
    require: { required: requiredMessage },
    phone: {
      required: requiredMessage,
      maxLength: 255
    },
    address: { required: requiredMessage, maxLength: 255 },
    email: {
      required: requiredMessage,
      pattern: {
        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i,
        message: "invalid Email",
      },
    },
    file: {
      required: requiredMessage,
      pattern: {
        value: /(\.(png|jpg|jpeg|pdf|dcm|Dcm))$/i,
        message: "invalid file",
      },
    },
    file2: {
      pattern: {
        value: /(\.(png|jpg|jpeg|pdf|dcm|Dcm))$/i,
        message: "invalid file",
      },
    },
};
  return watch, validation;
};

export const getClassName = (inputName, defaultValue, watch, errors) => {
  const targetValue = watch(inputName);
  if (errors[inputName]) {
    return "is-invalid";
  }
  if (targetValue && targetValue !== defaultValue) {
    return "is-valid";
  }
  return "";
};
