import React from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CSelect,
} from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { useHistory } from "react-router-dom";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import i18next from "i18next";

const CityForm = ({ onSubmit, city, label, countries, onCountryChange }) => {
  let defaultValues = {};
  if (city) {
    defaultValues = city;
  }

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const Validation = getValidation(watch);
  const history = useHistory();

  const onCancel = () => {
    history.push("/cities");
  };

  return (
    <CRow>
      <CCol xs="12">
        <CCard>
          <CForm onSubmit={handleSubmit(onSubmit)} method="post">
            <h4 className="header-label-title">{label}</h4>
            <CCardBody>
              <CFormGroup>
                <CFormGroup row className="mx-1">
                  <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CLabel className="labels required" htmlFor="name">
                      {i18next.t("Name")}
                    </CLabel>
                    <CInput
                      className={`${getClassName(
                        "name",
                        "name",
                        watch,
                        errors
                      )}`}
                      type="text"
                      id="name"
                      name="name"
                      placeholder={i18next.t("Enter City Name")}
                      innerRef={register(Validation.name)}
                    />
                  </CCol>
                  <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CLabel className="labels required" htmlFor="name_ar">
                      {i18next.t("Name In Arabic")}
                    </CLabel>
                    <CInput
                      className={`${getClassName(
                        "name_ar",
                        "name_ar",
                        watch,
                        errors
                      )}`}
                      type="text"
                      id="name_ar"
                      name="name_ar"
                      placeholder={i18next.t("Enter City Name In Arabic")}
                      innerRef={register(Validation.name)}
                    />
                  </CCol>
                </CFormGroup>
              </CFormGroup>
              <CFormGroup row className="mx-1">
                <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CLabel className="labels required" htmlFor="country_id">
                    {i18next.t("Country")}
                  </CLabel>
                  <CSelect
                    className={`${getClassName(
                      "country_id",
                      "country_id",
                      watch,
                      errors
                    )}`}
                    custom
                    name="country_id"
                    id="country_id"
                    onChange={onCountryChange}
                    innerRef={register(Validation.require)}
                  >
                    <option key="0" value="" selected disabled>
                      {i18next.t("Select Country")}
                    </option>
                    {RenderSelectOptions(countries)}
                  </CSelect>
                </CCol>
              </CFormGroup>
            </CCardBody>
            <CCardFooter>
              <CFormGroup row>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    type="submit"
                    color="primary"
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Save")}
                  </CButton>
                </CCol>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default CityForm;
