import React from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
  CImg,
  CCardHeader,
  CCardFooter,
  CButton,
  CInput,
  CInputGroup,
  CInputGroupAppend,
} from "@coreui/react";

import { Messages } from "../../partials/AppointmentData";

const render = (messages) =>
  messages.map((item) => (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol xs="3">
            <CImg
              src="https://picsum.photos/200/300?random=7"
              style={{ width: "60%", height: "60%", borderRadius: "50%" }}
            />
          </CCol>
          <CCol xs="8">
            <CCardSubtitle style={{ marginTop: "20px" }}>
              {item.name}
            </CCardSubtitle>
            <CCardSubtitle style={{ marginTop: "10px" }}>
              {item.message}
            </CCardSubtitle>
            <CCardSubtitle style={{ marginTop: "10px" }}>
              {item.time}
            </CCardSubtitle>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  ));
const MessageCard = (Props) => render(Props.Messages);

const LobbyMessage = () => (
  <>
    <CRow>
      <CCol>
        <CRow>
          <CCol xs="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Lobby Message</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <MessageCard Messages={Messages} />
              </CCardBody>
              <CCardFooter>
                <CInputGroup>
                  <CInput id="appendedInputButton" size="16" type="text" />
                  <CInputGroupAppend>
                    <CButton color="secondary">Send</CButton>
                  </CInputGroupAppend>
                </CInputGroup>
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </>
);

export default LobbyMessage;
