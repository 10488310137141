import React, { useState } from "react";
import { CCol, CLabel, CRow, CSwitch } from "@coreui/react";
import DoctorInputs from "./DoctorInputs";
import i18next from "i18next";

const StaffInputs = ({
  register,
  isStaff,
  specialties,
  levels,
  watch,
  errors,
  user,
}) => {
  const [isDoctor, setIsDoctor] = useState(user ? user.is_doctor : false);
  const handelOnchange = (e) => {
    setIsDoctor(e.target.checked);
  };
  if (isStaff) {
    return (
      <>
        {/* <div className="d-flex flex-row align-items-end mb-3">
          <div className="d-flex flex-row w-25">
            <CLabel htmlFor="is_doctor">Is Doctor</CLabel>
            <CSwitch
              id="is_doctor"
              labelOn="Yes"
              labelOff="No"
              name="is_doctor"
              className="mx-2"
              size="lg"
              color="primary"
              shape="pill"
              innerRef={register}
              onChange={(e) => handelOnchange(e)}
            />
          </div>
          <div className="d-flex flex-column w-25">
            <CLabel htmlFor="hospitals">Hospitals</CLabel>
            <Controller
              control={control}
              id="hospitals"
              name="hospitals"
              cacheOptions
              isMulti
              options={hospitals}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={user ? user.hospitals : []}
              as={Select}
            />
          </div>
        </div> */}
        <CRow className="d-flex flex-row justify-content-start mb-3">
          <CCol
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            className="d-flex flex-column justify-content-end align-items-center"
          >
            <CRow className="d-flex flex-row">
              <CLabel style={{fontSize:"1.3em"}} htmlFor="is_doctor">{i18next.t("Is Doctor")}</CLabel>
              <CSwitch
                id="is_doctor"
                labelOn={i18next.t("Yes")}
                labelOff={i18next.t("No")}
                name="is_doctor"
                className="mx-3"
                size="lg"
                color="primary"
                shape="pill"
                innerRef={register}
                onChange={(e) => handelOnchange(e)}
              />
            </CRow>
          </CCol>
        </CRow>
        <DoctorInputs
          register={register}
          isDoctor={isDoctor}
          specialties={specialties}
          levels={levels}
          watch={watch}
          errors={errors}
        />
      </>
    );
  }
  return "";
};
export default StaffInputs;
