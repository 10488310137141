import React from "react";
import { useForm, Controller } from "react-hook-form";
import { parseISO } from "date-fns";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CSelect,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CTextarea,
} from "@coreui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import i18next from "i18next";
import {
  getClassName,
  getValidation,
} from "views/pages/register/RegisterFormValidation";

const Types = (types) =>
  types.map((item) => (
    <option key={item.id} value={item.id}>
      {item.title}
    </option>
  ));

const VitalSignsForm = ({
  utilities,
  onSubmit,
  modal,
  vitalSign,
  lable,
  onCancel,
}) => {
  let defaultValues = { date: new Date() };
  if (vitalSign) {
    defaultValues = { ...vitalSign, date: parseISO(vitalSign.date_original) };
  }

  const { register, handleSubmit, control, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const Validation = getValidation(watch);

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} size="lg">
        <h4 className="header-label-title">{lable}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
              <CLabel className="float-left labels required">{i18next.t("Type")}</CLabel>
              <CSelect
                custom
                name="type_id"
                className={`${getClassName(
                  "type_id",
                  i18next.t("type_id"),
                  watch,
                  errors
                )}`}
                id="type_id"
                innerRef={register(Validation.require)}
              >
                <option value="" selected disabled>
                  {i18next.t("Select")}
                </option>
                {Types(utilities.types || [])}
              </CSelect>
            </CCol>
            <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
              <CLabel className="float-left labels required">{i18next.t("Value")}</CLabel>
              <CInput
                step="0.01"
                className={`${getClassName(
                  "rate",
                  i18next.t("rate"),
                  watch,
                  errors
                )}`}
                type="number"
                id="rate"
                name="rate"
                innerRef={register(Validation.require)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column labels"
            >
              <CLabel className="float-left w-fcontent">{i18next.t("date")}</CLabel>
              <Controller
                control={control}
                name="date"
                valueName="selected"
                rules={{ required: "please choose an option" }}
                render={(props) => (
                  <DatePicker
                    className="form-control"
                    showTimeSelect
                    onChange={(e) => props.onChange(e)}
                    selected={props.value}
                    dateFormat="dd/MM/yyyy HH:mm"
                  />
                )}
              />
            </CCol>
            <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
              <CLabel className="float-left labels">{i18next.t("Note")}</CLabel>
              <CTextarea
                name="note"
                id="note"
                cols="7"
                rows="5"
                placeholder={i18next.t("Note")}
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
            {i18next.t("Save")}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default VitalSignsForm;
