import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CRow,
  CCol,
  CCarousel,
  CCarouselIndicators,
  CCarouselInner,
  CCarouselItem,
  CCarouselCaption,
  CCarouselControl,
  CCardHeader,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { getInvestigation } from "actions/episodes/investigations";
import { Api, getTokenHeader } from "services/Api";

const Slider = ({ investigationId, sliderModal, stopSlides }) => {
  const [media, setMedia] = useState([]);
  const [dicomViewerSwitch, setDicomViewerSwitch] = useState(false);
  const dispatch = useDispatch();
  const investigation = useSelector(
    (state) => state.investigation.investigation
  );
  const darkStyle = {color:"black", filter:'invert(1) grayscale(100)'};
  useEffect(() => {
    if (investigationId) {
      dispatch(getInvestigation(investigationId));
    }
  }, [investigationId]);

  useEffect(() => {
    const slides = investigation ? investigation.normalMedia : [];
    const viewMedia = async (id) => {
      let response;
      try {
        response = await Api.get(`/api/admin/investigation/media/${id}`, {
          ...getTokenHeader(),
          responseType: "blob",
        });
      } catch (err) {
        return null;
      }
      return URL.createObjectURL(response.data);
    };
    const getMedia = async () => {
      const mediaArray = [];
      for (const slide of slides) {
        mediaArray.push({'blob': await viewMedia(slide.id), 'note':slide.execution_notes});
      }
      setMedia(mediaArray);
    };
    getMedia();
  }, [investigation]);

  const renderSlides = () => {
    if (media.length) {
      return media.map((item, index) => (
        <CCarouselItem key={`key-${index}`}>
          <embed className="d-block w-100" src={item.blob} alt="slide 1" height="500 px"/>
          <CCarouselCaption style={{color:"black", position: "initial", darkStyle}}>
            <h3>{item.note}</h3>
            <p>{`image ${index + 1} of ${media.length}`}</p>
          </CCarouselCaption>
        </CCarouselItem>
      ));
    }
    return (
      <h4 className="text-align-center">
        <strong>{i18next.t("No Media Available")}</strong>
      </h4>
    );
  };

  const renderDicoms = () => {
    const dicomMediaIds = investigation.dicomMedia.map((item) => item.id);
    return (
      <CCol sm={12}>
        <div className="embed-responsive embed-responsive-16by9">
          <h3>{`${process.env.REACT_APP_OHIF_URL}/?mediaUrl=${
            process.env.REACT_APP_SERVICE_URL
          }&mediaIds=${JSON.stringify(dicomMediaIds)}`}</h3>
          <iframe
            title="Dicom Viewer"
            className="embed-responsive-item"
            src={`${process.env.REACT_APP_OHIF_URL}/?mediaUrl=${
              process.env.REACT_APP_SERVICE_URL
            }&mediaIds=${JSON.stringify(dicomMediaIds)}`}
          />
        </div>
      </CCol>
    );
  };

  const renderModalBody = () => {
    if (dicomViewerSwitch) {
      return renderDicoms();
    }
    return (
      <CCol sm={12}>
        <CCarousel activeIndex={0} controls className="carousel slide carousel-dark">
          <CCarouselIndicators style={darkStyle} />
          <CCarouselInner style={{width:'70%', right:'15%', left:'15%'}}>{renderSlides()}</CCarouselInner>
          <CCarouselControl direction="prev" style={darkStyle} />
          <CCarouselControl direction="next" style={darkStyle} />
        </CCarousel>
      </CCol>
    );
  };
  const renderSwitchViewerBtns = () => {
    if (investigation && investigation.dicomMedia.length) {
      return (
        <CButton
          color="warning"
          onClick={() => setDicomViewerSwitch(!dicomViewerSwitch)}
        >
          {dicomViewerSwitch
            ? i18next.t("View Media")
            : i18next.t("View Dicom")}
        </CButton>
      );
    }
    return "";
  };

  return sliderModal ? (
    <CModal show={sliderModal} onClose={stopSlides} size="xl">
      <h4 className="table-title">{i18next.t("Details")}</h4>
      <CModalBody>
        <CRow>{renderSwitchViewerBtns()}</CRow>
        <CRow>{renderModalBody()}</CRow>
      </CModalBody>
      <CModalFooter>
        <CButton type="reset" color="danger" onClick={stopSlides}>
          {i18next.t("Close")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    <></>
  );
};
export default Slider;
