import React from "react";

const RenderSelectOptions = (options, optionSelected = {}) =>
  options.map((item) => (
    <option key={item.id}
      value={item.id}
      selected={(optionSelected.name || optionSelected.title) === (item.name || item.title)}
    >
      {item.name || item.title}
    </option>
  ));

export default RenderSelectOptions;
