// @flow
import { Api, getTokenHeader } from "services/Api";
import setQueryString from "helpers/helpers";
import {
  CREATE_QUESTION,
  FETCH_QUESTION,
  UPDATE_QUESTION,
  FETCH_QUESTIONS,
  DELETE_QUESTION,
  DELETE_SUB_QUESTION,
	PATIENT_ANSWERED_QUESTIONS,
	PATIENT_QUESTION_ANSWERS,
	RESET_STATISTICS
} from "./types";

export const createQuestion = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(`/api/admin/surveys/questions`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_QUESTION, payload: response.data });
};

export const fetchQuestion = (id: number): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/surveys/question/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_QUESTION, payload: response.data });
};

export const updateQuestion = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(
    `/api/admin/surveys/questions/${id}`,
    formData,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: UPDATE_QUESTION, payload: response.data });
};

export const fetchQuestions = (
  page: string,
  limit: string,
  filters: Object = {}
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/surveys/questions?page=${page}&limit=${limit}${setQueryString(
      filters
    )}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_QUESTIONS, payload: response.data });
};

export const deleteQuestion = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/surveys/questions/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_QUESTION, payload: id });
};

export const removeSubQuestion = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/surveys/questions/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_SUB_QUESTION, payload: id });
};

export const publishQuestions = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/surveys/questions/publish`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: FETCH_QUESTIONS, payload: response.data });
};

export const patientAnsweredQuestions = (patientId: String): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/survey-statistics/patient/${patientId}/questions`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_ANSWERED_QUESTIONS, payload: response.data });
};

export const patientQuestionAnswers = (patientId: String, questionId: String, filters: Object = {}): Object => async (
	dispatch
) => {
	if (patientId && questionId) {
		const response = await Api.get(
			`/api/admin/survey-statistics/patient/${patientId}/answers/${questionId}?${setQueryString(filters)}`,
			getTokenHeader()
		);
		dispatch({ type: PATIENT_QUESTION_ANSWERS, payload: response.data });
	}
};

export const resetStatistics = (): Object => async (
  dispatch
) => {
  dispatch({ type: RESET_STATISTICS });
};


