import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  createAppointment,
  getAppointmentUtilities,
  fetchSpecialtyDoctors,
  fetchDoctorClinics,
  fetchClinicSlots,
} from "actions/appointments/appointment";
import AppointmentForm from "views/pages/appointments/form/AppointmentForm";

/**
 * @param {*} props required props
 * @returns {*}  appointment form component
 * @class
 */
function CreateAppointment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointmentUtilities = useSelector(
    (state) => state.appointmentUtilities
  );
  const {
    specialties,
    clinic_type_virtual,
    doctors,
    clinics,
    slots,
    national_vacations,
    redirect,
  } = appointmentUtilities;

  useEffect(() => {
    dispatch(getAppointmentUtilities());
  }, []);

  useEffect(() => {
    if (redirect){
      history.push("/appointments");
    }
  },[appointmentUtilities])



  const onSubmit = (formData, e) => {
    e.preventDefault();
    const preparedData = {
      ...formData,
      patient_id: formData.patient ? formData.patient.id : null,
    };
    dispatch(createAppointment(preparedData));
  };

  const onSelectSpecialty = (specialtyId, date) => {
    if (date && specialtyId) {
      dispatch(fetchSpecialtyDoctors(specialtyId, {date}));
    }
  };

  const onSelectDoctor = (doctorId, date) => {
    if (date && doctorId) {
      dispatch(fetchDoctorClinics(doctorId, date));
    }
  };

  const onSelectClinic = (clinicId, date) => {
    if (date && clinicId) {
      dispatch(fetchClinicSlots(clinicId, date));
    }
  };
  return (
    <AppointmentForm
      onSubmit={onSubmit}
      onSelectSpecialty={onSelectSpecialty}
      onSelectDoctor={onSelectDoctor}
      onSelectClinic={onSelectClinic}
      specialties={specialties || []}
      doctors={doctors ? doctors.data : []}
      clinics={clinics || []}
      slots={slots || []}
      clinic_type_virtual={clinic_type_virtual}
      national_vacations={national_vacations || []}
    />
  );
}

export default CreateAppointment;
