import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CSelect,
  CModal,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchEventFormUtilities } from "actions/calendar/events";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import { customStyles } from "views/rounds/form/RoundFormValidation";
import i18next from "i18next";

const EventForm = ({
  editedEvent,
  onSubmit,
  onCancel,
  modal,
  FormTitle,
  hospital,
  specialty,
  start,
  end,
  handleDeleteEvent,
}) => {
  const dispatch = useDispatch();
  const { doctors, eventTypes } = useSelector(
    (state) => state.calendarUtilities
  );

  useEffect(() => {
    dispatch(fetchEventFormUtilities(hospital.id, specialty.id));
  }, [hospital, specialty, dispatch]);

  const defaultValues = editedEvent || { start, end };
  const { register, handleSubmit, control, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const renderTypesOptions = (types) =>
    Object.keys(types).map((key) => (
      <option key={key} value={key}>
        {types[key]}
      </option>
    ));

  const Validation = getValidation(watch);

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CInput
        type="hidden"
        name="hospital_id"
        innerRef={register}
        value={hospital.id}
      />
      <CInput
        type="hidden"
        name="specialty_id"
        innerRef={register}
        value={specialty.id}
      />
      <CModal show={modal} size="lg" onClose={onCancel}>
        <h4 className="header-label-title">{FormTitle}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
              <CLabel className="labels">{i18next.t("Hospital Name")}</CLabel>
              <p className="text-align-center">
                <strong>{hospital.name}</strong>
              </p>
            </CCol>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
              <CLabel className="labels">{i18next.t("Specialty Name")}</CLabel>
              <p className="text-align-center">
                <strong>{specialty.name}</strong>
              </p>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
              <CLabel className="labels required">{i18next.t("Type")}</CLabel>
              <CSelect
                custom
                name="type"
                className={`${getClassName("type", "type", watch, errors)}`}
                innerRef={register(Validation.require)}
              >
                <option value="" selected disabled>
                  {i18next.t("Select")}
                </option>
                {renderTypesOptions(eventTypes || [])}
              </CSelect>
            </CCol>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
              <CLabel htmlFor="doctors" className="labels required">
                {i18next.t("Doctors")}
              </CLabel>
              <Controller
                rules={Validation.require}
                styles={customStyles(!errors.doctors)}
                as={<Select />}
                control={control}
                id="doctors"
                name="doctors"
                isMulti
                options={doctors || []}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
              <CLabel className="labels required">{i18next.t("Start In")} </CLabel>
              <Controller
                control={control}
                name="start"
                valueName="selected"
                render={(props) => (
                  <DatePicker
                    className={` form-control ${getClassName(
                      "start",
                      "start",
                      watch,
                      errors
                    )}`}
                    showTimeSelect
                    onChange={(e) => props.onChange(e)}
                    selected={props.value}
                    dateFormat="dd/MM/yyyy HH:mm"
                    innerRef={register(Validation.require)}
                  />
                )}
              />
            </CCol>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
              <CLabel className="labels required">{i18next.t("End In")} </CLabel>
              <Controller
                control={control}
                name="end"
                valueName="selected"
                render={(props) => (
                  <DatePicker
                    className={` form-control ${getClassName(
                      "end",
                      "end",
                      watch,
                      errors
                    )}`}
                    showTimeSelect
                    onChange={(e) => props.onChange(e)}
                    selected={props.value}
                    dateFormat="dd/MM/yyyy HH:mm"
                    innerRef={register(Validation.require)}
                  />
                )}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
            {i18next.t("Save")}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
          {editedEvent && (
            <CButton
              type="reset"
              color="danger"
              onClick={() => handleDeleteEvent(editedEvent.id)}
              style={{width:"9em"}}
            >
              {i18next.t("Delete Event")}
            </CButton>
          )}
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default EventForm;
