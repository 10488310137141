import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { CCol, CRow, CCard, CCardBody, CCardTitle } from "@coreui/react";
import { confirmAlert } from "react-confirm-alert";
import {
  loadRoomData,
  patinetSubmitRate,
} from "actions/appointments/patientAppointment";
import { PATIENT_ROOM_FINISH } from "actions/appointments/types";
import { AppointmentDetailsRow, Lobby } from "views/pages/partials/partials";
import VideoChat from "views/communications/VideoChat";
import { CLINIC_TYPE_VIRTUAL } from "constants/clinic";
import DoctorOrders from "./DoctorOrders";
import RatingForm from "./RatingForm";

const render = (appointment, clinic) => (
  <CCard>
    <CCardBody>
      <AppointmentDetailsRow
        appointment={appointment}
        imgSrc="https://picsum.photos/200/300?random=10"
        imgStyle={{ width: "80%", height: "80%", borderRadius: "50%" }}
      />
      <CRow>
        {clinic && clinic.type === CLINIC_TYPE_VIRTUAL && (
          <VideoChat roomName={`appointment.${appointment.id}`} />
        )}
      </CRow>
      <CRow>
        <CCol>
          <CCardTitle className="text-center">Doctor Orders</CCardTitle>
          <DoctorOrders appointment={appointment} />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
);
const AppointmentCard = ({ Appointment, clinic }) => (
  <CRow>
    <CCol>{render(Appointment, clinic)}</CCol>
  </CRow>
);

const Video = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { lobbySession } = useParams();
  const patientRoomData = useSelector((state) => state.patientRoomData);
  const { appointment, appointmentFinished, clinic } = patientRoomData;

  useEffect(() => {
    dispatch(loadRoomData(lobbySession));
  }, [dispatch, lobbySession]);

  useEffect(() => {
    if (appointment) {
      window.Echo.private(`appointment.${appointment.id}`).listen(
        "FinishAppointment",
        (eventData) => {
          dispatch({
            type: PATIENT_ROOM_FINISH,
            payload: { appointmentFinished: eventData.appointmentFinished, appointment: appointment},
          });
        }
      );
    }
  }, [appointment, dispatch]);

  const submitRate = (formData) => {
    dispatch(patinetSubmitRate(formData));
  };

  if (appointmentFinished) {
    confirmAlert({
      title: "Appointment is Finished",
      customUI: ({ onClose }) => (
        <RatingForm
          onClose={onClose}
          model_id={appointment.id}
          submitRate={submitRate}
          message="Your Doctor Has finished Your Appointment, Thanks For Using Our System."
        />
      ),
      afterClose: () => history.push("/patient/appointments"),
    });
  }

  return appointment ? (
    <Lobby>
      <AppointmentCard Appointment={appointment} clinic={clinic} />
    </Lobby>
  ) : (
    ""
  );
};

export default Video;
