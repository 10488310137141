// @flow
import { Api, getTokenHeader } from "services/Api";
import setQueryString from "helpers/helpers";
import {
  FETCH_UTILITIES,
  CREATE_USER,
  FETCH_USER,
  UPDATE_USER,
  FETCH_USERS,
  DELETE_USER,
  FETCH_DOCUMENTS,
  CREATE_DOCUMENT,
  DELETE_DOCUMENT,
  FETCH_USER_PROFILE,
  UPDATE_USER_PROFILE,
  ASSIGN_USER_TO_HOSPITALS,
  GET_ASSIGNED_HOSPITALS,
  GET_USER_DASHBOARD,
} from "./types";

const transformData = (data) => (data ? data.map((item) => item.id) : []);

const prepareUserData = (userData) => {
  const formData = new FormData();
  formData.append("first_name", userData.first_name);
  formData.append("last_name", userData.last_name);
  formData.append("email", userData.email);
  formData.append("password", userData.password);
  formData.append("roles", JSON.stringify(transformData(userData.roles)));
  formData.append("uuid", userData.uuid);
  formData.append("country", userData.country);
  formData.append("city", userData.city);
  formData.append("address", userData.address);
  formData.append('time_zone', userData.time_zone.value || '');
  formData.append("gender", userData.gender);
  formData.append("is_staff_member", userData.is_staff_member ? "1" : "0");
  formData.append("uuid_picture", userData.uuid_picture[0]);
  if (userData.birth_date) {
  formData.append("birth_date", userData.birth_date);
  }
  if (userData.phone) {
    formData.append("phone", userData.phone);
  }
  if (userData.avatar[0]) {
    formData.append("avatar", userData.avatar[0]);
  }
  if (userData.is_staff_member) {
    formData.append("is_doctor", userData.is_doctor ? "1" : "0");
    formData.append(
      "hospitals",
      JSON.stringify(transformData(userData.hospitals))
    );
  }
  if (userData.is_doctor) {
    formData.append("level_id", userData.level_id);
    formData.append("specialty_id", userData.specialty_id);
    formData.append("bio", userData.bio);
  }
  return formData;
};

const prepareUserMediaData = (userData) => {
  const mediaData = new FormData();
  mediaData.append("avatar", userData.avatar[0]);
  mediaData.append("uuid_picture", userData.uuid_picture[0]);
  return mediaData;
};

export const fetchUtilities = (): Object => async (dispatch) => {
  const response = await Api.get("/api/admin/users/utilities", {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_UTILITIES, payload: response.data });
};

export const createUser = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(
    `/api/admin/users`,
    prepareUserData(formData),
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: CREATE_USER, payload: response.data });
};

export const fetchUser = (id: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/user/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_USER, payload: response.data });
};

export const updateUser = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(
    `/api/admin/users/${id}`,
    {
      ...formData,
      roles: JSON.stringify(transformData(formData.roles)),
      hospitals: JSON.stringify(transformData(formData.hospitals)),
      is_doctor: formData.is_doctor ? 1 : 0,
      is_staff_member: formData.is_staff_member ? 1 : 0,
      time_zone: formData.time_zone.value || formData.time_zone,
      birth_date: formData.birth_date ? formData.birth_date : null
    },
    {
      ...getTokenHeader(),
    }
  );
  await Api.post(
    `/api/admin/users/${id}/update-media`,
    prepareUserMediaData(formData),
    { ...getTokenHeader() }
  );
  dispatch({ type: UPDATE_USER, payload: response.data });
};

export const fetchUsers = (
  page: string,
  limit: string,
  is_staff: Boolean,
  filters: Object = {}
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/users?page=${page}&limit=${limit}&is_staff=${is_staff}${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_USERS, payload: response.data });
};

export const deleteUser = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/users/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_USER, payload: id });
};

export const fetchDocuments = (userId: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/users/${userId}/documents`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_DOCUMENTS, payload: response.data });
};

export const createDocument = (
  formData: Object,
  userId: string
): Object => async (dispatch) => {
  const response = await Api.post(
    `/api/admin/users/${userId}/documents`,
    formData,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: CREATE_DOCUMENT, payload: response.data });
};

export const deleteDocument = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/users/documents/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_DOCUMENT, payload: id });
};

export const fetchUserProfile = () => async (dispatch) => {
  const response = await Api.get(`/api/admin/profile/show`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_USER_PROFILE, payload: response.data });
};

export const updateUserProfile = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(
    `/api/admin/profile/update`,
    formData,
    {
      ...getTokenHeader(),
    }
  );
  await Api.post(
    `/api/admin/users/${id}/update-media`,
    prepareUserMediaData(formData),
    { ...getTokenHeader() }
  );
  dispatch({ type: UPDATE_USER_PROFILE, payload: response.data });
};

export const assignUserToHospitals = (formData: Object, id: string): Object => async (dispatch) => {
  const response = await Api.post(
    `/api/admin/users/${id}/assign`,
      formData,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: ASSIGN_USER_TO_HOSPITALS, payload: response.data });
};

export const getAssignedHospitals = (id: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/users/${id}/assign`, {
    ...getTokenHeader(),
  });
  dispatch({ type: GET_ASSIGNED_HOSPITALS, payload: response.data });
};

export const getUserDashboard = (specialty_id = 0): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/statistics?specialty_id=${specialty_id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: GET_USER_DASHBOARD, payload: response.data });
};
