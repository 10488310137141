import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
  CCardHeader,
  CButton,
  CCollapse,
  CCardFooter,
  CAlert,
  CLink,
} from "@coreui/react";
import { clinicSearch } from "actions/appointments/patientAppointment";
import { PATIENT_APPOINTMENT_SELECTION } from "actions/appointments/types";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import Avatar from "views/partials/Avatar";
import CIcon from "@coreui/icons-react";
import {
  cilBuilding,
  cilCalendar,
  cilCash,
  cilClock,
  cilHeart,
  cilLibraryAdd,
  cilScreenDesktop,
  cilUser,
} from "@coreui/icons";
import i18next from "i18next";

const ClinicsCard = (Props) => {
  const { doctor } = Props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const handleSelectClinic = (clinic) => {
    dispatch({
      type: PATIENT_APPOINTMENT_SELECTION,
      payload: { ...clinic, doctor },
    });

		history.push("/patient/slot/select");
    // if (clinic.type === "virtual") {
    //   history.push("/patient/slot/select");
    // } else {
    //   //no booking for physicall clinic right now
    //   // we were working to enable cash payment for physical clinic but it is not completed yet
    //   //history.push("/payment/methods");
    // }
  };

  return (
    <>
      <CRow style={{ "margin-left": "1em" }}>
        <CCol md="auto">
          <CCardTitle className="avh-font-bold avh-blue float-left w-fcontent mb-3 test-select-appointment-details">
            <CButton
              style={{ "background-color": "aliceblue" }}
              onClick={() => setVisible(!visible)}
            >
              {i18next.t("Details")}
            </CButton>
          </CCardTitle>
        </CCol>
      </CRow>
      <CCol xs="auto">
        <CCollapse className={visible ? "show" : ""}>
          <ScrollMenu>
            {Props.clinics.map((item) => {
              return (
                <CCard className="mt-3">
                  <CCardBody>
                    <CCol xs="auto">
                      <CCard style={{ width: "25em" }}>
                        <CCardHeader className="bg-blue py-3 text-center font-weight-bold">
                          <CIcon
                            size="xl"
                            content={cilBuilding}
                            className="mx-1"
                          />
                          <span className="text-capitalize">{item.type === "virtual"?i18next.t("Virtual"):i18next.t("Physical")}</span>
                        </CCardHeader>
                        <CCardBody>
                          <CCardSubtitle className="mb-3">
                            <CIcon
                              size="lg"
                              content={cilScreenDesktop}
                              className="mx-1"
                            />
                            {item.title}
                          </CCardSubtitle>
                          <CCardSubtitle className="mb-3">
                            <CIcon
                              size="lg"
                              content={cilCalendar}
                              className="mx-1"
                            />
                            {item.date_formatted}
                          </CCardSubtitle>
                          <CCardSubtitle className="mb-3">
                            <CIcon
                              size="lg"
                              content={cilClock}
                              className="mx-1"
                            />
                            {`${item.time.split(" ")[1].split(":")[0]}` < 12
                              ? `${item.time.split(" ")[0]}  ${
                                  item.time.split(" ")[1].split(":")[0] % 12
                                }:${item.time.split(" ")[1].split(":")[1]} ${i18next.t("AM")} ${
                                  item.time.split(" ")[2]
                                } `
                              : `${item.time.split(" ")[0]}  ${
                                  item.time.split(" ")[1].split(":")[0] % 12
                                }:${item.time.split(" ")[1].split(":")[1]} ${i18next.t("PM")} ${
                                  item.time.split(" ")[2]
                                } `}
                            {`${item.time.split(" ")[3].split(":")[0]}` < 12
                              ? `${
                                  item.time.split(" ")[3].split(":")[0] % 12
                                }:${item.time.split(" ")[3].split(":")[1]} ${i18next.t("AM")}  `
                              : ` ${
                                  item.time.split(" ")[3].split(":")[0] % 12
                                }:${item.time.split(" ")[3].split(":")[1]} ${i18next.t("PM")} `}
                          </CCardSubtitle>
                          <CCardSubtitle>
                            <CIcon
                              size="lg"
                              content={cilCash}
                              className="mx-1"
                            />
                            {item.price}
                          </CCardSubtitle>
                        </CCardBody>
                        <CCardFooter className="bg-dark-blue py-3 text-center font-weight-bold">
                        {/* item.type === "virtual" ? */
												 <CButton
                            onClick={() => handleSelectClinic(item)}
                            className={"text-light test-select-appointment-more"}
                          >
                            <CIcon
                              size="lg"
                              content={cilLibraryAdd}
                              className="mx-1"
                            />
                            {i18next.t("More")}
                          </CButton> /*: <span style={{lineHeight:3}}>{i18next.t("Physical Only")}</span>*/}
                        </CCardFooter>
                      </CCard>
                    </CCol>
                  </CCardBody>
                </CCard>
              );
            })}
          </ScrollMenu>
        </CCollapse>
      </CCol>
    </>
  );
};

const render = (doctorClinics) =>
  doctorClinics.map((item) => (
    <>
      <CRow>
        <CCol>
          <CCard className="card-3d">
            <CCardBody>
              <CRow className="align-items-center">
                <CCol md="auto">
                  <CRow>
                    <CCol xs="auto">
                      <Avatar
                        src={item.avatar}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        className="float-left"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="auto">
                  <CRow>
                    <CCol xs="auto" className="d-flex flex-column">
                      <CCardTitle className="avh-font-bold float-left w-fcontent mb-3">
                        {/* <CIcon size="xl" content={cilUser} className="mx-1" /> */}
                        <span>{item.name}</span>
                      </CCardTitle>
                    </CCol>
                    <CCol xs="auto" className="d-flex flex-column">
                      <CRow>
                        <CCol md="auto">
                          <CCardTitle className="avh-font-bold float-left w-fcontent mb-3">
                            <CIcon
                              size="xl"
                              content={cilHeart}
                              className="mx-1"
                            />
                            <span>{i18next.t("Specialty")}</span>
                          </CCardTitle>
                        </CCol>
                        <CCol md="auto">
                          <CCardTitle className="avh-font-bold avh-blue float-left w-fcontent mb-3">
                            <span className="avh-font-normal">
                              {item.specialty}
                            </span>
                          </CCardTitle>
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol xs="auto" className="d-flex flex-column">
                      <CRow>
                        <CCol md="auto">
                          <CCardTitle className="avh-font-bold float-left w-fcontent mb-3">
                            <CIcon
                              size="xl"
                              content={cilCalendar}
                              className="mx-1"
                            />
                            <span>{i18next.t("From")}</span>
                          </CCardTitle>
                        </CCol>
                        <CCol md="auto">
                          <CCardTitle className="avh-font-bold avh-blue float-left w-fcontent mb-3">
                            <span className="avh-font-normal">{item.from_formatted}</span>
                          </CCardTitle>
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol xs="auto" className="d-flex flex-column">
                      <CRow>
                        <CCol md="auto">
                          <CCardTitle className="avh-font-bold float-left w-fcontent mb-3">
                            <CIcon
                              size="xl"
                              content={cilCalendar}
                              className="mx-1"
                            />
                            <span>{i18next.t("To")}</span>
                          </CCardTitle>
                        </CCol>
                        <CCol md="auto">
                          <CCardTitle className="avh-font-bold avh-blue float-left w-fcontent mb-3">
                            <span className="avh-font-normal">{item.to_formatted}</span>
                          </CCardTitle>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow className="align-items-center">
                <ClinicsCard
                  clinics={item.clinicsData}
                  doctor={{
                    id: item.id,
                    name: item.name,
                    specialty: item.specialty,
                  }}
                />
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  ));
const AppointmentCard = (Props) => (
  <CRow>
    <CCol>{render(Props.clinics)}</CCol>
  </CRow>
);

/**
 *
 */
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SelectAppointment = (Props) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const from = query.get("from");
  const to = query.get("to");
  const type = query.get("type");
  const specialty_id = query.get("specialty_id");
  const country = query.get("country");
  const patientClinicSearch = useSelector((state) => state.patientClinicSearch);
  const patientClinicSearchValues = Object.values(patientClinicSearch);
  useEffect(() => {
    if (from && to && specialty_id) {
      dispatch(clinicSearch({ specialty_id, to, from, type, country }));
    }
  }, [type, from, to, specialty_id, dispatch]);

  const renderAppointmentCards = () => {
    if (patientClinicSearchValues && patientClinicSearchValues.length > 0) {
      return <AppointmentCard clinics={patientClinicSearchValues || []} />;
    }
    return (
      <>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol>
                <h4 className="text-align-center not-found">
                  <strong>
                  {i18next.t("There is No Available Clinics On These Date, Try To Select Another Dates")}
                    
                  </strong>
                </h4>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CLink href="/patient/clinic/search">{i18next.t("Go Back")}</CLink>
      </>
    );
  };
  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">{i18next.t("Select Appointment")}</div>
        </CCol>
      </CRow>
      <CRow>
        <CCol>{renderAppointmentCards()}</CCol>
      </CRow>
    </>
  );
};

export default SelectAppointment;
