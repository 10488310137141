import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CCol, CRow, CCallout } from "@coreui/react";

const ChatLog = ({ conversation }) => {
  const [messages, setMessages] = useState([]);
  const author = useSelector((state) => state.auth.jwt.user.uuid);
  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message) =>
        setMessages((oldMessages) => [...oldMessages, message]);
      conversation.getMessages().then((newMessages) => {
        setMessages(newMessages.items);
      });
      conversation.on("messageAdded", handleMessageAdded);
      return () => {
        conversation.off("messageAdded", handleMessageAdded);
      };
    }
  }, [conversation]);

  return (
    <CRow style={{ overflowY: "auto", maxHeight: "400px" }}>
      {messages.map((message) => {
        const color = message.state.author === author ? "primary" : "success";
        return (
          <CCol xs="12">
            <CCallout color={color} className="bg-secondary" style={{ overflowWrap: "break-word" }}>
              {message.state.body}
            </CCallout>
          </CCol>
        );
      })}
    </CRow>
  );
};
export default ChatLog;
