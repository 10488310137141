import React from "react";
import { CCardTitle, CRow, CCol, CCardSubtitle } from "@coreui/react";
import i18next from "i18next";

const TimeLineAppointmentCard = ({ appointment }) => (
  <>
    <CRow>
      <CCol xs="3" md="3" className="bg-info py-3">
        <CCardSubtitle className="text-center font-weight-bold">
          {`${+appointment?.schedule_time?.split(" ")[1].split(":")[0]}` < 12
            ? `${appointment?.schedule_time?.split(" ")[0]} ${
                appointment?.schedule_time?.split(" ")[1].split(":")[0] % 12
              }:${
                appointment?.schedule_time?.split(" ")[1].split(":")[1]
              } ${i18next.t("AM")}`
            : `${appointment?.schedule_time?.split(" ")[0]} ${
                appointment?.schedule_time?.split(" ")[1].split(":")[0] % 12
              }:${
                appointment?.schedule_time?.split(" ")[1].split(":")[1]
              } ${i18next.t("PM")}`}
        </CCardSubtitle>
        <br />
      </CCol>
      <CCol xs="9" md="9">
        <CRow>
          <CCol xs="12">
            <CCardTitle>{appointment.doctor}</CCardTitle>
          </CCol>
          <CCol xs="12">
            <CCardSubtitle className="font-weight-bold py-1">
              {appointment.speciality}
            </CCardSubtitle>
          </CCol>
          <CCol xs="12">
            <CCardSubtitle className="font-weight-bold py-1">
              {appointment.clinic}
            </CCardSubtitle>
          </CCol>
          <CCol xs="12">
            <CCardSubtitle className="font-weight-bold py-1">
              {appointment.clinic_type}
            </CCardSubtitle>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </>
);

export default TimeLineAppointmentCard;
