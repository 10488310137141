import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CButton,
  CCardFooter,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import {
  patientCreateAppointment,
  patientAppointmentSuccessPayment,
} from "actions/appointments/patientAppointment";
import { payByCard } from "actions/payments/payments";
import { SUCCESS_STATUS_CODE } from "constants/constants";

const cardValidator = require("card-validator");

const Card = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const dispatch = useDispatch();
  const card = useSelector((state) => state.payments.card);
  // const MerchantRefNum = "99900642041";
  const history = useHistory();
  const patientAppointmentSelection = useSelector(
    (state) => state.patientAppointmentSelection
  );
  useEffect(() => {
    if (patientAppointmentSelection.clinic_id) {
      dispatch(patientCreateAppointment(patientAppointmentSelection));
    } else {
      history.push("/patient/clinic/search");
    }
  }, [dispatch, history, patientAppointmentSelection]);
  const validateCardNumber = (value) => {
    const numberValidation = cardValidator.number(value);
    return numberValidation.isValid;
  };

  const validatExpirationMonth = (value) => {
    const numberValidation = cardValidator.expirationMonth(value);
    return numberValidation.isValid;
  };

  const validatExpirationYear = (value) => {
    const numberValidation = cardValidator.expirationYear(value);
    return numberValidation.isValid;
  };

  const validateCvv = (value) => {
    const numberValidation = cardValidator.cvv(value);
    return numberValidation.isValid;
  };

  const onSubmit = (formData) => {
    const data = {
      ...formData,
      merchantRefNum: patientAppointmentSelection.appointment_code,
    };
    dispatch(payByCard(data));
  };

  if (card && card.statusCode === SUCCESS_STATUS_CODE) {
    console.log(card);
    const { appointment_id } = patientAppointmentSelection;
    dispatch(patientAppointmentSuccessPayment(appointment_id));
  }
  return (
    <>
      <CRow>
        <CCol xs="12">
          <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
            <CCard>
              <CCardHeader>Pay By Visa</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol xs="12">
                    <CFormGroup>
                      <CLabel htmlFor="cardNumber">Credit Card Number</CLabel>
                      <CInput
                        id="cardNumber"
                        placeholder="0000 0000 0000 0000"
                        required
                        name="cardNumber"
                        innerRef={register}
                        {...register("cardNumber", {
                          validate: validateCardNumber,
                        })}
                      />
                      {errors.cardNumber && <span>Invalid Card Number</span>}
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs="4">
                    <CFormGroup>
                      <CLabel htmlFor="cardExpiryMonth">Month</CLabel>
                      <CSelect
                        custom
                        name="cardExpiryMonth"
                        id="cardExpiryMonth"
                        innerRef={register}
                        {...register("cardExpiryMonth", {
                          validate: validatExpirationMonth,
                        })}
                      >
                        <option key="0" selected disabled>
                          Select
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </CSelect>
                      {errors.cardExpiryMonth && (
                        <span>Invalid Expiry Month</span>
                      )}
                    </CFormGroup>
                  </CCol>
                  <CCol xs="4">
                    <CFormGroup>
                      <CLabel htmlFor="cardExpiryYear">Year</CLabel>
                      <CInput
                        name="cardExpiryYear"
                        id="cardExpiryYear"
                        placeholder="0000"
                        required
                        innerRef={register}
                        {...register("cardExpiryYear", {
                          validate: validatExpirationYear,
                        })}
                      />
                      {errors.cardExpiryYear && (
                        <span>Invalid Expiry Year</span>
                      )}
                    </CFormGroup>
                  </CCol>
                  <CCol xs="4">
                    <CFormGroup>
                      <CLabel htmlFor="cvv">CVV/CVC</CLabel>
                      <CInput
                        id="cvv"
                        name="cvv"
                        placeholder="123"
                        required
                        innerRef={register}
                        {...register("cvv", {
                          validate: validateCvv,
                        })}
                      />
                      {errors.cvv && <span>Invalid Cvv</span>}
                    </CFormGroup>
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                <CButton type="submit" size="sm" color="primary">
                   Pay
                </CButton>
              </CCardFooter>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </>
  );
};
export default Card;
