import {
  CREATE_RADIOLOGY_CENTER,
  FETCH_RADIOLOGY_CENTER,
  UPDATE_RADIOLOGY_CENTER,
  FETCH_RADIOLOGY_CENTERS,
  DELETE_RADIOLOGY_CENTER,
  FETCH_RADIOLOGY_STAFF,
  FETCH_RADIOLOGY_EXCLUDED_USERS,
  DELETE_RAD_MEMBER,
} from "actions/radiology-centers/types";
import { UNAUTHORIZED_STATUS_CODE } from "constants/constants";

/**
 * @param state
 * @param action
 */
export default function radiologyCenters(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case CREATE_RADIOLOGY_CENTER:
    case UPDATE_RADIOLOGY_CENTER:
      if(action.payload.status == UNAUTHORIZED_STATUS_CODE){
        return {...state, redirect: false }
      }
      return { radiologyCenter: action.payload.data, redirect: true };
    case FETCH_RADIOLOGY_CENTER:
      return { radiologyCenter: action.payload.data };
    case FETCH_RADIOLOGY_CENTERS:
      return { ...action.payload };
    case DELETE_RADIOLOGY_CENTER:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case FETCH_RADIOLOGY_STAFF:
      return { ...state, staff: { ...action.payload } };
    case FETCH_RADIOLOGY_EXCLUDED_USERS:
      return { ...state, excludedUsers: action.payload.data };
      case DELETE_RAD_MEMBER:
        return {
          ...state,
          staff: {
            ...state.staff,
            data: state.staff.data.filter(
              (item) => item.id !== action.payload
            ),

          },



        };
    default:
      return state;
  }
}
