import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import {
  createInvestigation,
  updateInvestigation,
} from "actions/episodes/investigations";
import { TYPE_EPISODE, TYPE_APPOINTMENT } from "constants/constants";
import InvestigationForm from "./Form";

const CreateInvestigation = (props) => {
  const {
    toggle,
    onCancelForm,
    setInvestigation,
    modal,
    centerType,
    investigationTypes,
    investigation,
    modelId,
    patientId,
  } = props;

  const dispatch = useDispatch();
  const { episodeId } = useParams();
  const doctorId = useSelector((state) => state.auth.jwt.user.id);

  const onSubmit = (formData) => {
    if (investigation) {
      // case edit
      const preparedData = {
        ...formData,
        model_type: investigation.model_type,
        model_id: investigation.model_id,
        center_type: investigation.center_type,
        editor_id: doctorId,
      };
      dispatch(updateInvestigation(investigation.id, preparedData));
    } else {
      // case create
      dispatch(
        createInvestigation(modelId || episodeId, {
          center_type: centerType,
          model_type: modelId ? TYPE_APPOINTMENT : TYPE_EPISODE,
          ...formData,
          doctor_id: doctorId,
          patient_id: patientId,
        })
      );
    }
    setInvestigation(null);
    toggle();
  };

  return (
    <InvestigationForm
      onSubmit={onSubmit}
      onCancel={onCancelForm}
      modal={modal}
      FormTitle={i18next.t("Order Investigation")}
      investigationTypes={investigationTypes}
      investigation={investigation}
    />
  );
};
export default CreateInvestigation;
