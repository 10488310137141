import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  listSpecialtyForms,
  listSpecialtyFormsCondition,
} from "actions/specialties-form/specialtiesForm";
import { LIMIT } from "constants/constants";
import {
  fetchCities,
  fetchCountries,
  fetchCountryCities,
} from "actions/cities/cities";
import { fetchPrescriptionUtilities } from "actions/prescriptions/prescriptions";
import { fetchDoctors } from "actions/doctors/doctors";
import { fetchDoctorClinicsSurvey } from "actions/appointments/appointment";
import { fetchHospitals } from "actions/hospitals/hospitals";
import { fetchSpecialties } from "actions/specialties/specialties";
import { removeEmptyProperties } from "helpers/helpers";
import {
  fetchSentSurvey,
  fetchSentSurveys,
  updateSentSurvey,
} from "actions/sent-surveys/sentSurveys";
import SendForm from "../form/SendForm";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import i18next from "i18next";

const EditSendSurveys = ({ toggle, modal, id }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [conditionFilters, setConditionFilters] = useState({});
  const prescriptionUtilities = useSelector(
    (state) => state.prescriptionUtilities
  );
  const countries = useSelector((state) => state.countries);
  const cities = useSelector((state) => state.cities);

  const specialtyFormsList = useSelector(
    (state) => state.specialtiesForm.specialtyFormsList
  );
  const specialtyFormsList_2 = useSelector(
    (state) => state.specialtiesForm.specialtyFormsListCon
  );
  const doctors = useSelector((state) => state.doctors);
  const clinics = useSelector((state) => state.appointmentUtilities.clinics);
  const hospitals = useSelector((state) => state.hospitals.data);
  const specialties = useSelector((state) => state.specialties.data);

  const sentSurvey = useSelector((state) => state.sentSurveys.sentSurvey);

  const onSubmit = (formData) => {

    const data = {
      ...removeEmptyProperties(formData),

    };
    if (!data.draft){
      confirmAlert({
        title: "Are you sure?",
        message: "that form is ready to be submitted?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(updateSentSurvey(data,id));
              toggle();
              dispatch(fetchSentSurveys(1, LIMIT));

            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      dispatch(updateSentSurvey(data, id));
      toggle();
      dispatch(fetchSentSurveys(1, LIMIT));

    }
  };
  const onCancel = () => {
    toggle();
    dispatch(fetchSentSurveys(1, LIMIT));
  };

  const onSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty: event.target.value });
    getSpecialtyForm({ ...filters, specialty: event.target.value });
  };
  const onLocationChange = (event) => {
    setFilters({ ...filters, location: event.target.value });
    getSpecialtyForm({ ...filters, location: event.target.value });
  };
  const getSpecialtyForm = (filters) => {
    if (filters.location && filters.specialty) {
      dispatch(listSpecialtyForms(filters.location, filters.specialty));
    } else {
      // reset
    }
  };
  const onConditionLocationChange = (event) => {
    setConditionFilters({ ...conditionFilters, location: event.target.value });
    getConditionSpecialtyForm({
      ...conditionFilters,
      location: event.target.value,
    });
    getDoctorList({ ...conditionFilters, location: event.target.value });
  };
  const onConditionSpecialtyChange = (event) => {
    setConditionFilters({ ...conditionFilters, specialty: event.target.value });
    getConditionSpecialtyForm({
      ...conditionFilters,
      specialty: event.target.value,
    });
    // dispatch(fetchDoctors({ ...conditionFilters, specialty: event.target.value }));
    getDoctorList({ ...conditionFilters, specialty: event.target.value });
  };

  const getConditionSpecialtyForm = (conditionFilters) => {
    if (conditionFilters.location && conditionFilters.specialty) {
      dispatch(
        listSpecialtyFormsCondition(
          conditionFilters.location,
          conditionFilters.specialty
        )
      );
    } else {
      // reset
    }
  };

  const getDoctorList = (conditionFilters) => {
    if (conditionFilters.location && conditionFilters.specialty) {
      dispatch(
        fetchDoctors({
          hospital: conditionFilters.location,
          specialty: conditionFilters.specialty,
        })
      );
      // dispatch(fetchDoctors({ ...conditionFilters, hospital: event.target.value }));
    }
  };

  const onDoctorChange = (event) => {
    setConditionFilters({ ...conditionFilters, doctor: event.target.value });
    console.log(conditionFilters);
    const type = "all";
    dispatch(fetchDoctorClinicsSurvey(event.target.value, type));
  };
  const onSelectTypeClinic = (ClinicType) => {
    dispatch(fetchDoctorClinicsSurvey(conditionFilters.doctor, ClinicType));
  };
  const onCountryChange = (e) => {
    dispatch(fetchCountryCities(e.target.value));
  };
  useEffect(() => {
    dispatch(fetchSentSurvey(id));
    dispatch(fetchCountries());
    dispatch(fetchCities());
    dispatch(fetchPrescriptionUtilities());
    dispatch(fetchHospitals(1, LIMIT));
    dispatch(fetchSpecialties());
  }, [dispatch, id]);

  return (
    <SendForm
    onSubmit={onSubmit}
    onCancel={onCancel}
    toggle={toggle}
    modal={modal || 0}
    countries={countries.data || []}
    cities={cities.data || []}
    prescriptionUtilities={prescriptionUtilities || []}
    specialties={specialties || []}
    hospitals={hospitals || []}
    specialtyFormsList={specialtyFormsList}
    specialtyFormsList_2={specialtyFormsList_2}
    onSpecialtyChange={onSpecialtyChange}
    onLocationChange={onLocationChange}
    onCountryChange={onCountryChange}
    onConditionLocationChange={onConditionLocationChange}
    onConditionSpecialtyChange={onConditionSpecialtyChange}
    onDoctorChange={onDoctorChange}
    doctors={doctors.data || []}
    clinics={clinics || []}
    onSelectTypeClinic={onSelectTypeClinic}
    parent_id
    FormTitle={i18next.t("Edit Survey")}
    sentPublicSurvey={sentSurvey}
  />
  );
};

export default EditSendSurveys;
