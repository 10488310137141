import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CCol, CRow, CCard, CCardBody, CButton, CAlert } from "@coreui/react";

import { paymobPaymentResultProcess } from "actions/payments/payments";
import { resetPatientAppointmentSelection } from "actions/appointments/patientAppointment";
import Loading from "views/partials/Loading";

const PaymobPaymentDetails = () => {
  const history = useHistory();
	const search = useLocation().search;
  const dispatch = useDispatch();
	const [urlResultObject, setUrlResultObject] = useState({});
  const patientAppointmentSelection = useSelector(
    (state) => state.patientAppointmentSelection
  );
  const paymnetProcessResult = useSelector(
    (state) => state.payments.payment_result
  );

	useEffect(() => {
		const getUrlParams = () => {
			const params = {};
			const query = new URLSearchParams(search);
			for (const [key, value] of query.entries()) {
				params[key] = value;
			}
			return params;
		};

		const paymobResultObject = getUrlParams();
		setUrlResultObject(paymobResultObject);
		dispatch(paymobPaymentResultProcess(patientAppointmentSelection.appointment_id, paymobResultObject));
	},[])
	
  const handleNextButton = () => {
    // if (referenceNumber) {
      dispatch(resetPatientAppointmentSelection());
      history.push(`/patient/appointments`);
    // }
  };

	if(!paymnetProcessResult){
		return <Loading/>
	}

	return (
		<>
			<CRow>
				<CCol lg={12}>
					<CCard>
						<CCardBody>
							<h4 className="table-title">Payment Details</h4>
							<table className="table table-striped table-hover">
								<tbody>
									<tr>
										<td className="labels">Amount</td>
										<td>
											<strong>{urlResultObject.amount_cents? urlResultObject.amount_cents/100 : ''} {'EGP'}</strong>
										</td>
									</tr>
									<tr>
										<td className="labels">Order Reference</td>
										<td>
											<strong>{urlResultObject.order? urlResultObject.order : '-'}</strong>
										</td>
									</tr>
									<tr>
										<td className="labels">Status</td>
										<td>
											<strong>{paymnetProcessResult.success? 'Successful Payment' : 'Failed Payment'}</strong>
										</td>
									</tr>
									<tr>
										<td className="labels">Message</td>
										<td>
											<strong>{paymnetProcessResult.message}</strong>
										</td>
									</tr>
									<tr className="text-align-center">
                    <td></td>
                    <td>
                      <CButton onClick={handleNextButton} color="primary">
                        Next
                      </CButton>
                    </td>
                  </tr>
								</tbody>
							</table>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</>
	);
};
export default PaymobPaymentDetails;
