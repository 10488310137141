import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchLab, updateLab } from "actions/labs/labs";
import { fetchCountries, fetchCities } from "actions/cities/cities";
import LabForm from "../form/LabForm";
import Loading from "views/partials/Loading";
import i18next from "i18next";

const EditLab = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const lab = useSelector((state) => state.labs.lab);
  const redirect = useSelector((state) => state.labs.redirect);
  const cities = useSelector((state) => state.cities);
  const countries = useSelector((state) => state.countries);
  const label = `${i18next.t("Edit Lab")}`;

  const onSubmit = async (formData) => {
    await dispatch(updateLab(formData, match.params.id));
  };

  useEffect(() => {
    dispatch(fetchLab(match.params.id));
    dispatch(fetchCountries());
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (lab && lab.country_id) {
      dispatch(fetchCities(lab.country_id));
    }
  }, [dispatch, lab]);

  if (redirect) {
    history.push("/service/providers/labs");
  }
  const onCountryChange = (e) => {
    dispatch(fetchCities(e.target.value));
  };

  if (lab) {
    return (
      <LabForm
        onSubmit={onSubmit}
        label={label}
        lab={lab}
        agreementView={lab.agreement}
        countries={countries.data ? countries.data : []}
        cities={cities.data ? cities.data : []}
        onCountryChange={onCountryChange}
      />
    );
  }
  return <Loading/>
};

export default EditLab;
