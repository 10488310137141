import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchEpisodeUtilities,
  setDischargeReason,
} from "actions/episodes/episodes";
import { createDischargeForm } from "actions/discharge-form/dischargeForm";
import { listAdminSpecialtyForms } from "actions/specialties-form/specialtiesForm";
import { RESET_SPECIALTIES_FORM, FETCH_SPECIALTIES_FORM } from "actions/specialties-form/types";
import { LABS, DOCTOR_SPECIALITY_FORM } from "constants/constants";
import SpecialtyForm from "./SpecialtyForm";

const Create = ({ episode, setActiveScreen, onCancel, dischargData, setDischargData }) => {
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.episodeUtilities);
  const redirect = useSelector((state) => state.dischargeForm.redirect);
  const staffId = useSelector((state) => state.auth.jwt.user.id);
  const specialtyForm = useSelector(
    (state) => state.specialtiesForm.specialtyForm
  );
  const specialtyFormsList = useSelector(
    (state) => state.specialtiesForm.adminSpecialtyFormsList
  );
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      episode_id: episode.id,
      staff_id: staffId,
      survey_id: specialtyForm ? specialtyForm.id : 0,
    };
    const episodeData = {
      discharge_reason: formData.discharge_reason,
      icd_codes: formData.icd_codes,
    };
    dispatch(setDischargeReason(episodeData, episode.id));
    dispatch(createDischargeForm(data));
  };
  const onSpecialtyFormChange = (event) => {
    const selectedForm = specialtyFormsList.find(item => item.id == event.target.value);
    dispatch({ type: FETCH_SPECIALTIES_FORM, payload: {"data": selectedForm} });
  };

  useEffect(() => {
    dispatch(fetchEpisodeUtilities());
    //dispatch({ type: RESET_SPECIALTIES_FORM });
  }, [dispatch]);

  if (redirect) {
    setActiveScreen(LABS);
  }

  const onSpecialtyChange = (event) => {
    dispatch(
      listAdminSpecialtyForms(
        episode.hospital_id,
        event.target.value,
        DOCTOR_SPECIALITY_FORM
      )
    );
  };

  return (
    <SpecialtyForm
      onSubmit={onSubmit}
      specialties={utilities.specialties || []}
      discharge_reasons={utilities.discharge_reasons || []}
      specialtyForm={specialtyForm}
      specialtyFormsList={specialtyFormsList}
      onSpecialtyChange={onSpecialtyChange}
      onSpecialtyFormChange={onSpecialtyFormChange}
      onCancel={onCancel}
      dischargData={dischargData}
      setDischargData={setDischargData}
    />
  );
};

export default Create;
