import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CButton,
  CCardFooter,
} from "@coreui/react";

// import { patientCreateAppointment } from "actions/appointments/patientAppointment";
import { getSignature } from "actions/payments/payments";

const Fawry = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedInUser = useSelector((state) => state.auth.jwt.user);
  const signature = useSelector((state) => state.payments.signature);
  const patientAppointmentSelection = useSelector(
    (state) => state.patientAppointmentSelection
  );
  const Amount = patientAppointmentSelection.price;
  useEffect(() => {
    if (!patientAppointmentSelection.appointment_id) {
      // if (!patientAppointmentSelection.appointment_id) {
      //   dispatch(patientCreateAppointment(patientAppointmentSelection));
      // }
      // } else {
      history.push("/patient/clinic/search");
    }
    console.log("ddd",patientAppointmentSelection.appointment_code,patientAppointmentSelection.price);
  }, [dispatch, history]); // eslint-disable-line

  const checkout = () => {
    const formData = {
      merchantRefNum: patientAppointmentSelection.appointment_code,
      itemQuantity: 1,
      amount: patientAppointmentSelection.price,
    };
    dispatch(getSignature(formData));
  };

  const buildChargeRequest = () => {
    const chargeRequest = {
      merchantCode: process.env.REACT_APP_MERCHANTCODE,
      merchantRefNum: patientAppointmentSelection.appointment_code,
      customerMobile: loggedInUser.phone,
      customerEmail: loggedInUser.email,
      customerName: loggedInUser.first_name + " " + loggedInUser.last_name,
      //customerProfileId: '12123',
      //paymentExpiry: '1641906781000', //epoch timestap milliseconds 1641906781000 = 11/01/2022
      chargeItems: [
        {
          itemId: patientAppointmentSelection.appointment_code,
          description: "Appointment",
          price: patientAppointmentSelection.price,
          quantity: "1",
          imageUrl: "https://your-site-link.com/photos/45566.jpg",
        },
      ],
      returnUrl: process.env.REACT_APP_FAWRY_RETURN_URL,
      authCaptureModePayment: false,
      signature,
    };
    return chargeRequest;
  };

  if (signature) {
    console.log(signature);
    const configuration = {
      locale: "en", //default en
      mode: window.DISPLAY_MODE.SEPARATED, // required, set to SEPARATED for checkout link integration
    };
    window.FawryPay.checkout(buildChargeRequest(), configuration);
  }

  return (
    <>
      <CRow>
        <CCol xs="12">
          <CCard>
            <h4 className="header-label-title">
              Pay By {location.search.split("=")[1].toUpperCase()}
            </h4>
            <CCardBody>
              <CRow>
                <CCol className="labels" xs="6" md="6">
                  Amount :
                </CCol>
                <CCol xs="6" md="6">
                  <strong>{Amount}</strong>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              {patientAppointmentSelection.appointment_code && (
                <CButton
                  onClick={checkout}
                  className="btn-form"
                  color="primary"
                >
                  Pay
                </CButton>
              )}
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
export default Fawry;
