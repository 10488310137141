import React, { useEffect } from "react";
import { HashRouter, Route, Switch, useHistory, BrowserRouter } from "react-router-dom";


import { useSelector } from "react-redux";
import "./scss/style.scss";
import { ARABIC_TEXT_CODE } from "./constants/constants";
import { CSpinner } from "@coreui/react";
import { onMessage } from "firebase/messaging";
import messaging from "services/firebase";
import { Toast } from "@coreui/coreui";
import LoginInModal from "views/pages/login/LoginInModal";


const loading = (
  <CSpinner
    style={{ "marginLeft": "45%", "marginTop": "11%" }}
    className="spinner"
    variant="grow"
  />
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Terms = React.lazy(() => import("./views/pages/register/Terms"));
const VerifyAccount = React.lazy(() => import("./views/pages/register/VerifyAccount"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const PublicRounds = React.lazy(() =>
  import("./views/rounds/public/PublicRounds")
);
const ForgetPasswrod = React.lazy(() =>
  import("./views/pages/forgetpassword/ForgetPasswrod")
);
const ResetPasswrod = React.lazy(() =>
  import("./views/pages/resetpassword/Resetpassword")
);

const App = () => {
  const history = useHistory();

  const language = useSelector((state) => state.language);
  if (language === ARABIC_TEXT_CODE) {
    document.dir = "rtl";
  }
	Notification.requestPermission().then(p => console.log(p === 'granted'? 'Notification permission granted.': p));
  onMessage(messaging, (payload) => {
    //window.registration.showNotification(payload.notification.title, {body: payload.notification.body, icon: payload.notification.icon});
    console.log("incoming msg", payload);
    // Toast(<p>{JSON.stringify(payload.notification)}</p>);
  });

  const listener = new BroadcastChannel('onBackgroundMessage');
  listener.onmessage = function(e) {
    //window.registration.showNotification(e.data.notification.title, {body: e.data.notification.body, icon: e.data.notification.icon});
    console.log('onBackgroundMessage', e.data);
  };

  return (
    <BrowserRouter>
    <LoginInModal />
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/Terms"
            name="Terms Page"
            render={(props) => <Terms />}
          />
          <Route
            exact
            path="/verify-account/:token/:email"
            name="Verify Account"
            render={(props) => <VerifyAccount {...props} />}
          />
          <Route
            exact
            path="/verify-account"
            name="Verify Account"
            render={(props) => <VerifyAccount {...props} />}
          />
          <Route
            exact
            path="/forgetpasswrod"
            name="Forget Passwrod Page"
            render={(props) => <ForgetPasswrod {...props} />}
          />
          <Route
            exact
            path="/resetpasswrod/:token/:email"
            name="Reset Passwrod Page"
            render={(props) => <ResetPasswrod {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>

  );
};

export default App;
