import React  from "react";
import ReactFlow, { MiniMap, Controls, Background } from "react-flow-renderer";
import { hierarchyToReactFlow } from "helpers/helpers";

const nodeStyle = {
  background: "#fff",
  color: "#3f5192",
  padding: "25px",
  borderRadius: "8px",
  border: "1px solid #FFF",
  width: "300px",
  height: "250px",
  display: "flex",
  flexDirection: "column",
};

const textStyle = {
  fontSize: "12px",
  display: "grid",
  gap: "5px",
  textAlign: "left",
};

const createNodeLabel = (data) => (
  <div style={nodeStyle}>
    <div style={{ fontWeight: "bold", marginBottom: "10px" }}>{ data.hospital_name? data.hospital_name: 'All' }</div>
    <div style={textStyle}>
      <div>
        {data.specialty_name} Patients: {data.in_patients}
      </div>
      <div>Completed Follow Up Notes: {data.follow_up_notes}</div>
      <div>Radiology: {data.radiology}</div>
      <div>Labs: {data.lab}</div>
      <div>Requested Consult.: {data.requested_consult}</div>
      <div>Until now prescription. Exec.: {data.prescriptions}</div>
    </div>
  </div>
);

const NurseFigures = ({ dashboard }) => {
  const hierarchy = {...dashboard.data, hospital_id: 0, hospitals: Object.values(dashboard.data_for_hospitals)};
  const {nodes, edges} = hierarchyToReactFlow(hierarchy, createNodeLabel, 330, 300);
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <ReactFlow nodes={nodes} edges={edges} fitView>
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default NurseFigures;
