import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { fetchPatientTimeLine } from "actions/appointments/patientAppointment";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { LocalHotel, LocalHospital } from '@mui/icons-material';

const TimeLine = () => {
  const dispatch = useDispatch();
	const timeLineData = useSelector((state) => Object.values(state.patientTimeLine) || []);

  useEffect(() => {
    dispatch(fetchPatientTimeLine());
  }, [dispatch]);

  // Render function
	const appointmentCard = (appointment, dateBackground) => (
		<Link
			to={`/patient/timeline/appointment/${appointment.id}/show`}
			style={{ textDecoration: "none", color: "inherit" }}
			key={appointment.id}
		>
			<Paper elevation={3} className="bg-secondary p-3">
				<Typography variant="h6" component="span">{appointment.clinic}</Typography>
				<Typography>DR: {appointment.doctor}</Typography>
			</Paper>
		</Link>
	);

	const renderItem = (item, dateBackground) => {
		if (item.clinic) {
			return appointmentCard(item, dateBackground);
		}
		 else {
			return (
				<div
					style={{ textDecoration: "none", color: "inherit" }}
					key={item.id}
				>
					<Paper elevation={3} className="bg-info p-3">
						<Typography variant="h6" component="h1">
							<span>{item.hospital.name}</span><br/>
							<small>Episode</small>
						</Typography>
					</Paper>
				</div>
			);
		}
	}

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {i18next.t("timeline")}
      </Typography>
      <Timeline position="alternate">
        {/* Render Time Line List */}
        {timeLineData.map((item, index) => (
					<TimelineItem key={index}>
						<TimelineOppositeContent
							sx={{ m: 'auto 0' }}
							align="right"
							variant="body2"
							color="text.secondary"
						>
							{`${item.created_at.replace('T', ' ').split('.')[0]}`}
						</TimelineOppositeContent>
            <TimelineSeparator>
							<TimelineDot color={item.clinic ? "secondary" : "info"} variant="outlined">
								{item.clinic ? <LocalHospital/> : <LocalHotel /> }
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
							{renderItem(item, "bg-info p-3")}
						</TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
};

export default TimeLine;
