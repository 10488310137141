import React from 'react';
import { getColumnOptions } from "helpers/helpers";
import {
  CCol,
  CRow,
  CLabel,
  CSelect,
} from "@coreui/react";
const OrderSection = ({ schema, selectedTable, selectedJoins, register, defaultValue,selectedGroup,setValue }) => {
  const handleOrderByChange = (event) => {
    const { value } = event.target;
    setValue("direction", '')
  };

  return (
    <div>
      {selectedTable && (
        <CRow >
          <CCol md={6}>
          <CLabel htmlFor="description" className="labels">Order
          </CLabel>
          <CSelect name="order" innerRef={register}
          onChange={handleOrderByChange}
          >
            <option value="">No Ordering</option>
            {getColumnOptions(schema, selectedTable, selectedJoins,selectedGroup).map((column) => (
              <option key={column.name} value={column.name}>
                {column.label}
              </option>
            ))}
          </CSelect>
          </CCol>
          <CCol md={6}>
          <CLabel>Directions</CLabel>
          <CSelect innerRef={register} name="direction">
            <option value="">Select Ditection</option>
            <option value="asc">ASC</option>
            <option value="desc">DESC</option>
          </CSelect>
          </CCol>

        </CRow>
      )}
    </div>
  );
};

export default OrderSection;