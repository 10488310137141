import React from "react";
import { Api } from "services/Api";
import DiagnosisForm from "./Form";

const EditDiagnosis = ({ toggle, modal, setIsEditing, diagnosis }) => {
  const onSubmit = (formData) => {
    Api.put(`symptomsAndDiagnosis/${diagnosis.id}`, formData)
      .then((response) => {
        if (response.data && response.statusText === "OK") {
          toggle();
          setIsEditing(false);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  const onCancel = () => {
    toggle();
    setIsEditing(false);
  };
  return (
    <DiagnosisForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal}
      diagnosis={diagnosis}
      FormTitle="Edit Diagnosis"
    />
  );
};
export default EditDiagnosis;
