import React, { useState } from "react";
import { CDataTable, CCard, CCardBody, CButton } from "@coreui/react";
import MediaViewerModal from "./MediaViewerModal";
import i18next from "i18next";

const PatientAttachmentsTab = ({ appointment }) => {
  const [showMediaList, setShowMediaList] = useState([]);
  const attachments = appointment ? appointment.attachments : [];
  const fields = [
    { key: "name", label:  i18next.t("name") },
    { key: "type", label:  i18next.t("type") },
    { key: "actions", label:  i18next.t("Actions") },
  ];
  const renderAction = (item) => (
    <td>
      <CButton
        key={`sh-${item.id}`}
        color="success"
        className="m-2"
        onClick={() => setShowMediaList(item.media)}
      >
        {("show")}
      </CButton>
    </td>
  );
  return (
    <CCard>
      <CCardBody>
        <CDataTable
          items={attachments}
          fields={fields}
          striped
          noItemsViewSlot={i18next.t("No Available Items")}
          scopedSlots={{
            actions: renderAction,
          }}
        />
      </CCardBody>
      <MediaViewerModal
        medialList={showMediaList}
        showModal={showMediaList.length > 0}
        onCloseModal={() => setShowMediaList([])}
      />
    </CCard>
  );
};

export default PatientAttachmentsTab;
