import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import ObjectDetails from "views/partials/ObjectDetails";
import Staff from "views/partials/Staff";
import {
  fetchLab,
  fetchLabExcudedUsers,
  fetchLabStaff,
  addLabStaff,
  deleteLabMember,
} from "actions/labs/labs";
import InvestigationTab from "./investigationTab";
import { confirmAlert } from "react-confirm-alert";
import i18next from "i18next";

const Lab = ({ match }) => {
  const dispatch = useDispatch();
  const lab = useSelector((state) => state.labs.lab);
  const staff = useSelector((state) => state.labs.staff);
  const excludedUsers = useSelector((state) =>
    state.labs.excludedUsers ? state.labs.excludedUsers : []
  );
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    dispatch(fetchLab(match.params.id));
  }, [dispatch, match.params.id]);

  const toggle = () => {
    setModal(!modal);
  };

  const onActiveTabChange = (activeTab) => {
    setTab(activeTab);
    switch (activeTab) {
      case "staff":
        dispatch(fetchLabStaff(match.params.id));
        break;
      default:
        break;
    }
  };

  const onActivePageChange = (activePage) => {
    setPage(activePage);
    switch (tab) {
      case "staff":
        dispatch(fetchLabStaff(match.params.id, activePage));
        break;
      default:
        break;
    }
  };

  const handleSelectRoleChange = (event) => {
    dispatch(fetchLabExcudedUsers(match.params.id, event.target.value));
  };

  const prepareFormData = (data) => {
    const usersIds = data.users ? data.users.map((item) => item.id) : [];
    return { ...data, users: usersIds };
  };
  const onSubmit = (formData) => {
    dispatch(addLabStaff(match.params.id, prepareFormData(formData)));
    toggle();
  };
  const handleRemoveMember = (memberId: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Remove This Staff Member.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteLabMember(match.params.id, memberId));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const renderLab = () => {
    if (lab) {
      return (
        <CRow>
          <CCol lg={12}>
            <CCard>
              <CCardBody>
                <CTabs
                  activeTab="details"
                  onActiveTabChange={(activeTab) =>
                    onActiveTabChange(activeTab)
                  }
                >
                  <CNav variant="tabs">
                    <CNavItem>
                      <CNavLink className="navchange" data-tab="details">{i18next.t("Details")}</CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink  className="navchange" data-tab="staff">{i18next.t("Staff")}</CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink className="navchange" data-tab="investigations">
                        {i18next.t("Investigations")}
                      </CNavLink>
                    </CNavItem>
                  </CNav>
                  <CTabContent>
                    <CTabPane data-tab="details">
                      <ObjectDetails
                        object={lab}
                        listItems={[
                          "name", "phone", "address", "description"
                        ]}
                      />
                    </CTabPane>
                    <CTabPane data-tab="staff">
                      <Staff
                        staff={staff}
                        onActivePageChange={onActivePageChange}
                        page={page}
                        onSubmit={onSubmit}
                        modal={modal}
                        toggle={toggle}
                        handleSelectRoleChange={handleSelectRoleChange}
                        excludedUsers={excludedUsers}
                        handleRemoveMember={handleRemoveMember}
                        staffMembers={lab.staffMembers}
                        medicalProvider= 'manage-lab-member'
                      />
                    </CTabPane>
                    <CTabPane data-tab="investigations">
                      <InvestigationTab
                        centerType={1}
                        centerId={match.params.id}
                      />
                    </CTabPane>
                  </CTabContent>
                </CTabs>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      );
    }
    return (
      <h4 className="text-align-center not-found">
        <strong>{i18next.t("Not Found")}</strong>
      </h4>
    );
  };

  return (
    <CRow>
      <CCol lg="12">{renderLab()}</CCol>
    </CRow>
  );
};

export default Lab;
