import { Api, getTokenHeader } from "services/Api";
import setQueryString from "helpers/helpers";
import {
  CREATE_REQUEST,
  ACTIVE_RECEIVED_REQUESTS,
  START_REQUEST,
  END_REQUEST,
  LIST_ACTIVE_RECEIVED_REQUESTS,
  REJECT_REQUEST,
} from "./types";

export const createRequest = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/episodes/requests`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_REQUEST, payload: response.data });
};

export const activeReceivedRequest = (filters: Object = {}): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/episodes/requests/received?${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: ACTIVE_RECEIVED_REQUESTS, payload: response.data });
};

export const startRequest = (request: Object): Object => async (dispatch) => {
  await Api.get(`/api/admin/episodes/requests/${request.id}/start`, {
    ...getTokenHeader(),
  });
  dispatch({ type: START_REQUEST, payload: request });
};

export const rejectRequest = (
  formData: Object,
  request: Object
): Object => async (dispatch) => {
  const response = await Api.put(
    `/api/admin/episodes/requests/${request.id}/reject`,
    formData,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: REJECT_REQUEST, payload: request });
};

export const endRequest = (request: Object): Object => async (dispatch) => {
  await Api.get(`/api/admin/episodes/requests/${request.id}/end`, {
    ...getTokenHeader(),
  });
  dispatch({ type: END_REQUEST, payload: request });
};

export const listActiveReceivedRequest = (
  filters: Object = {}
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/episodes/requests/list-received?${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: LIST_ACTIVE_RECEIVED_REQUESTS, payload: response.data });
};
