import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import { CButton } from "@coreui/react";
import { isObject, isArray } from "helpers/helpers";
import { fetchFollowUp } from "actions/follow-ups/followUps";
import { LABS } from "constants/constants";

const renderAnswerScore = (question, questionAnswer) => {
  if (question.sub_questions && question.sub_questions.length > 0) {
    return questionAnswer.answer_value
      ? `${i18next.t("Total")}: ${questionAnswer.answer_value}`
      : "-";
  }
  return questionAnswer.answer_value && !isNaN(+questionAnswer.answer_value)
    ? `${i18next.t("Score")}: ${questionAnswer.answer_value}`
    : "-";
};

const renderAnswerBody = (answerBody) => {
  let ans = JSON.parse(answerBody)
    ? JSON.parse(answerBody)
    : i18next.t("No Available Data");

  if (isArray(ans)) {
    return ans.map((a) => {
      const obj = JSON.parse(a);
      return <tr>{obj.label ? obj.label : i18next.t("No Available Data")}</tr>;
    });
  }
  try {
    ans = JSON.parse(ans);
    return isObject(ans) ? ans.label : ans;
  } catch {
    return ans;
  }
};

const renderGroupQuestions = (questions) => {
  if (questions) {
    return questions.map((question) => {
      const questionAnswer = question.surveys_answers
        ? question.surveys_answers[0]
        : null;
      return (
        <>
          <tr>
            <td>
              <strong>{question.question}</strong>
            </td>
            <td>
              {questionAnswer
                ? renderAnswerBody(questionAnswer.answer_body)
                : i18next.t("No Available Data")}
            </td>
            <td>
              {questionAnswer
                ? renderAnswerScore(question, questionAnswer)
                : i18next.t("No Available Data")}
            </td>
          </tr>
          {question.sub_questions
            ? renderGroupQuestions(question.sub_questions)
            : ""}
        </>
      );
    });
  }
  return <></>;
};

const renderSurveyGroups = (followUp) => {
  if (followUp && followUp.survey && followUp.survey.groups) {
    return followUp.survey.groups.map((group) => (
      <>
        <h4 className="table-title">{i18next.t("Survey Details")}</h4>
        <table className="table table-striped table-hover">
          <tbody>
            <tr>
              <td>
                <strong>{i18next.t("Survey Title")}</strong>
              </td>
              <td>
                <h3>{followUp.survey.title}</h3>
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Question Group Title")}</strong>
              </td>
              <td>
                <h3>{group.group_title}</h3>
              </td>
            </tr>
            {renderGroupQuestions(group.questions)}
          </tbody>
        </table>
      </>
    ));
  }
  return "";
};

const RenderFollowUp = (followUp, followUpId) => {
  if (followUp && followUp.id === followUpId) {
    return <>{renderSurveyGroups(followUp)}</>;
  }
  return <></>;
};

const DischargeSummary = ({ episode, setActiveScreen }) => {
  const dispatch = useDispatch();
  const followUp = useSelector((state) => state.followUps.followUp);
  const discharge_reason = useSelector((state) =>
    state.episodeUtilities?.discharge_reasons?.find(
          (reason) => reason.id == episode.discharge_reason
        )
  );

  useEffect(() => {
    if (episode.discharge_form) {
      dispatch(fetchFollowUp(episode.discharge_form.id));
    }
  }, [dispatch]);

  return (
    <>
      <table className="table table-striped table-hover">
        <tbody>
          <tr>
            <td>
              {" "}
              <strong className="labels">
                {i18next.t("Discharge Reason")}
              </strong>{" "}
            </td>
            <td>
              {discharge_reason ? discharge_reason.name : i18next.t("Not Discharged Yet")}
            </td>
          </tr>
          {followUp && followUp.id === episode.discharge_form.id ? (
            <>
              <tr>
                <td>
                  <strong className="labels">{i18next.t("Author")}</strong>
                </td>
                <td>{`${followUp.creator.first_name} ${followUp.creator.last_name}`}</td>
              </tr>
              <tr>
                <td>
                  <strong className="labels">{i18next.t("Specialty")}</strong>
                </td>
                <td>
                  {followUp.specialty
                    ? followUp.specialty.name
                    : i18next.t("No Specific Specialty")}
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="labels">{i18next.t("Note")}</strong>
                </td>
                <td>{followUp.note ? followUp.note : i18next.t("No Additional Notes")}</td>
              </tr>
            </>
          ) : (
            ""
          )}
        </tbody>
      </table>
      <>
        {episode.discharge_form &&
          RenderFollowUp(followUp, episode.discharge_form.id)}
      </>
      <div className="my-2 float-right">
        <CButton
          color="primary"
          className="mx-1"
          onClick={() => setActiveScreen(LABS)}
        >
          {i18next.t("next")}
        </CButton>
      </div>
    </>
  );
};

export default DischargeSummary;
