import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CLink,
  CImg
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import initBroadcasting from "../broadcasting";
import RefreshTokenManager from "./../authService";
import { TOGGLE_SIDEBAR } from "../constants/constants";
// routes config
import routes from "../routes";

import logo from '../logo.png'

import {
  TheHeaderDropdown,
  TheHeaderDropdownMssg,
  TheHeaderDropdownNotif,
  TheHeaderDropdownTasks,
  Language
} from "./index";

const TheHeader = () => {
  initBroadcasting();
  const dispatch = useDispatch();
  const sidebarShow = useSelector(state => {
    return state.sidebarShow;
  });
  const tokenExpiryTime = useSelector(state => state.auth.expires_in);

  useEffect(() => {
    if (tokenExpiryTime) {
      RefreshTokenManager.refreshToken(tokenExpiryTime, dispatch);
    } else {
      RefreshTokenManager.abortRefreshToken();
    }
  }, [tokenExpiryTime]);


  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: TOGGLE_SIDEBAR, sidebarShow: val });
  };

  return (
    <CHeader withSubheader fixed={false}>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CImg src={logo} style={{height:"70px"}}/>
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none main-header">
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <Language />
        <TheHeaderDropdownNotif />
        <TheHeaderDropdown />
      </CHeaderNav>
    </CHeader>
  );
};

export default TheHeader;
