import React from "react";

import {
  CCol,
  CContainer,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CImg,
  CButton,
} from "@coreui/react";
import { PatientsData } from "../../CasesData";

const PatientCard = (Props) => (
  <CCard className="bg-info" style={{ borderRadius: "15px", marginTop: "2%" }}>
    <CCardBody>
      <CRow>
        <CCol xs="3" md="2">
          <CImg
            src="https://picsum.photos/200/300?random=2"
            style={{ width: "60%", height: "60%", borderRadius: "50%" }}
          />
        </CCol>
        <CCol xs="9" md="10">
          <CRow>
            <CCol md="6">{Props.patient.name}</CCol>
            <CCol md="6">{Props.patient.gender}</CCol>
            <CCol md="6">{Props.patient.age}</CCol>
            <CCol md="6">{Props.patient.national_id}</CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="3" md="2" />
        <CCol xs="9" md="10">
          {Props.patient.complaint}
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
);

const StartCall = () => (
  <CContainer>
    <CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <CCard
              className="bg-light text-dark"
              style={{ borderRadius: "15px" }}
            >
              <CCardBody>
                <CRow style={{ textAlign: "center" }}>
                  <CCol xs="12">
                    <CCardTitle>Waiting for patient...</CCardTitle>
                  </CCol>
                </CRow>
                <PatientCard patient={PatientsData[0]} />
                <CRow style={{ textAlign: "center" }}>
                  <CCol xs="12">
                    <CCardTitle>Patient appointment is 1:00 PM.</CCardTitle>
                    <CCardTitle>15 min delay</CCardTitle>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs="4" />
                  <CCol xs="4">
                    <CButton color="danger"> End </CButton>
                  </CCol>
                  <CCol xs="4">
                    <CButton color="info"> Next </CButton>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
);

export default StartCall;
