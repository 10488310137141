// @flow
import { Api, getTokenHeader } from "services/Api";
import {
  CREATE_CITY,
  FETCH_CITY,
  UPDATE_CITY,
  FETCH_CITIES,
  DELETE_CITY,
  FETCH_COUNTRIES,
} from "./types";

export const createCity = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/cities`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_CITY, payload: response.data });
};

export const fetchCity = (id: number): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/city/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_CITY, payload: response.data });
};

export const updateCity = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(`/api/admin/cities/${id}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_CITY, payload: response.data });
};

export const fetchCities = (page: string = 1, limit: string = 40): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/cities?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_CITIES, payload: response.data });
};

export const deleteCity = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/cities/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_CITY, payload: id });
};

export const fetchCountries = (): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/country`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_COUNTRIES, payload: response.data });
};

export const fetchCountryCities = (countryId): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/country/${countryId}/cities`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_CITIES, payload: response.data });
};
