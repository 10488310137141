import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import i18next from "i18next";
import Select from "react-select";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CSelect,
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CInput,
  CTextarea,
} from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import SurveyGroupsSection from "./SurveyGroupsSection";
import { useHistory } from "react-router-dom";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import { customStyles } from "views/rounds/form/RoundFormValidation";
import SurveyForm from "views/partials/survey-components/SurveyForm";

const PublicSurveyForm = ({
  onSubmit,
  onCancel,
  publicSurvey,
  modal,
}) => {
  const { register, setValue, handleSubmit, control, reset, watch, errors } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (publicSurvey) {
      console.log("reset form",publicSurvey);
      reset(publicSurvey);
    }
  }, [publicSurvey]);
  const history = useHistory();
  const Validation = getValidation(watch);
  let survey = publicSurvey?.survey

  const ref = useRef(null);
  const saveDraft = () => {
    setValue("draft", 2);
    const buttonRef = ref.current && ref.current.children[0];
    buttonRef.click()
  }
  return (
    <CRow>
      <CCol xs="12">
        <CForm
          onSubmit={handleSubmit(onSubmit)}
          className="form-horizontal"
        >
          <CModal show={modal} size="lg" onClose={onCancel}>
            <h4 className="header-label-title">{i18next.t("Edit Public Survey")}</h4>
            <CModalBody>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CLabel>{i18next.t("Note")}</CLabel>
                  <CTextarea
                    name="note"
                    id="note"
                    rows="5"
                    placeholder="Notes..."
                    innerRef={register}
                  />
                </CCol>
              </CFormGroup>
              <SurveyForm
                register={register}
                setValue={setValue}
                watch={watch}
                groups={survey ? survey.groups : []}
              />
            </CModalBody>
            <CModalFooter>
              <CInput type="hidden" innerRef={register} name="draft" />
              {publicSurvey?.status == 2 && <CButton type="button" onClick={saveDraft} color="info">
                {i18next.t("Save Draft")}
                
              </CButton>}
              <div ref={ref}>
                <CButton type="submit" color="primary">
                {i18next.t("Submit")}
                </CButton>
              </div>
              <CButton type="reset" color="danger" onClick={onCancel}>
                
                {i18next.t("Cancel")}
                
              </CButton>
            </CModalFooter>
          </CModal>
        </CForm>
      </CCol>
    </CRow>
  );

};

export default PublicSurveyForm;
