import {
  VACATION_TYPE_DOCTOR,
  VACATION_TYPE_CLINIC,
  VACATION_TYPE_HOSPITAL,
  VACATION_TYPE_NATIONAL,
} from "constants/constants";

const requiredMessage = "Field is required";
const requiredIf = (inputValue, getValue, otherName, otherValue) => {
  if (otherValue) {
    return getValue(otherName) == otherValue ? !!inputValue : true;
  }
  if (!getValue(otherName)) {
    // required if other is null
    return !!inputValue;
  }
  return true;
};
const validateDateFromTo = (from, to) => {
  // console.log('###', typeof from, from, typeof to, to);
  // const fromDate = new Date(from);
  // const toDate = new Date(to);
  return from <= to;
};

export const getValidation = (watch) => {
  return {
    type: {
      required: requiredMessage,
      pattern: {
        value: /^[0-9]+/i,
        message: "invalid Type",
      },
    },
    title: { required: requiredMessage, maxLength: 255 },
    date_from: { required: requiredMessage },
    date_to: {
      required: requiredMessage,
      validate: (value) => validateDateFromTo(watch("date_from"), value),
    },
    doctor: {
      validate: (value) =>
        requiredIf(value, watch, "type", VACATION_TYPE_DOCTOR),
    },
    hospital: {
      validate: (value) =>
        requiredIf(value, watch, "type", VACATION_TYPE_HOSPITAL),
    },
    clinic: {
      validate: (value) =>
        requiredIf(value, watch, "type", VACATION_TYPE_CLINIC),
    },
    country: {
      validate: (value) =>
        requiredIf(value, watch, "type", VACATION_TYPE_NATIONAL),
    },
  };
};

export const getClassName = (inputName, defaultValue, watch, errors) => {
  const targetValue = watch(inputName);
  if (errors[inputName]) {
    return "is-invalid";
  } else if (targetValue === "") {
    return "is-invalid";
  }
  if (targetValue && targetValue !== defaultValue) {
    return "is-valid";
  } else if (targetValue === "") {
    return "is-invalid";
  }
  return "";
};
