import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CFormGroup,
  CDataTable,
  CTabContent,
  CCardTitle,
} from "@coreui/react";
import i18next from "i18next";
import Investigation from "views/episode-management/screens/spoke/cases/tabs/investigation/Investigation";
import find from "lodash/find";
import {
  fetchPrescriptionUtilities,
  fetchPrescriptions,
} from "actions/prescriptions/prescriptions";
import {
  fetchVitalSignUtilities,
  fetchVitalSigns,
} from "actions/vital-signs/vitalSigns";
import { getEpisodeChronicDiseases } from "actions/episodes/episodes";
import { fetchInvestigations } from "actions/episodes/investigations";
import { TYPE_APPOINTMENT, TYPE_EPISODE } from "constants/constants";
import ObjectDetails from "views/partials/ObjectDetails";
import QuestionChartComponent from "views/charts/QuestionsChartComponent";
import VitalSidebar from "views/episode-management/screens/spoke/cases/tabs/vitalSigns/VitalSidebar";
import VitalSigns from "views/episode-management/screens/spoke/cases/tabs/vitalSigns/VitalSigns";
import CaseCard from "./CaseCard";
import { includes } from "lodash";

const ActiveCase = ({ activeCase, caseType, patient }) => {
  // const { episodeId } = useParams();
  const dispatch = useDispatch();
  const prescriptions = useSelector((state) => state.prescriptions.data);
  const utilities = useSelector((state) => state.prescriptionUtilities);
  const chronicDiseases = useSelector(
    (state) => state.episodeUtilities.chronicDiseases
  );

  const removeHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

  const getPrescriptionsList = () => {
    if (prescriptions) {
      return prescriptions.map((item) => {
        const timeUnit = find(utilities.time_units, {
          id: parseInt(item.frequency_time_unit, 10),
        });
        const Ifrequency = `Once every ${item.frequency} ${
          timeUnit && timeUnit.title ? timeUnit.title : "NA"
        }`;
        const route = find(utilities.routes, {
          id: parseInt(item.route, 10),
        });
        const Iroute = route ? route.title : item.route;
        return { ...item, frequency: Ifrequency, route: Iroute };
      });
    }
    return [];
  };
  const fields = [
    { key: "drug", label: i18next.t("drug") },
    { key: "statusText", label: i18next.t("status") },
    { key: "dosage", label: i18next.t("Dosage") },
    { key: "frequency", label: i18next.t("frequency") },
  ];

  const [currentTab, setCurrentTab] = useState('Diagnosis');

  useEffect(() => {
    if(['Prescription','Investigation','VitalSigns'].includes(currentTab)){
        onActiveTabChange(currentTab);
    }
  }, [activeCase]);

  const onActiveTabChange = (activeTab) => {
    switch (activeTab) {
      case "Prescription":
        dispatch(fetchPrescriptionUtilities());
        dispatch(fetchPrescriptions(activeCase.id, caseType));
        break;
      case "Investigation":
        dispatch(fetchInvestigations(activeCase.id, caseType));
        break;
      case "VitalSigns":
        dispatch(fetchVitalSignUtilities());
        dispatch(fetchVitalSigns(activeCase.id));
        break;
      default:
        // TBD - Add the default active tab here!
        break;
    }
  };

  const SideBar = () => (
    <CNav variant="tabs" className="nav-tabs">
      <CNavItem>
        <CNavLink className="navchange" data-tab="Diagnosis">
          {i18next.t("Diagnosis")}
        </CNavLink>
      </CNavItem>
      {caseType === TYPE_EPISODE && (
        <>
          <CNavItem>
            <CNavLink className="navchange" data-tab="MedicalHistory">
              {i18next.t("medical history")}
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink className="navchange" data-tab="VitalSigns">
              {i18next.t("Vital Signs")}
            </CNavLink>
          </CNavItem>
        </>
      )}
      {caseType === TYPE_APPOINTMENT && (
          <>
          <CNavItem>
            <CNavLink className="navchange" data-tab="MedicalHistoryAppoinments">
              {i18next.t("medical history")}
            </CNavLink>
          </CNavItem>
        </>
      )}
      <CNavItem>
        <CNavLink className="navchange" data-tab="Investigation">
          {i18next.t("Investigation")}
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink className="navchange" data-tab="Prescription">
          {i18next.t("Prescription")}
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink className="navchange" data-tab="SurveyStatistics">
          {i18next.t("Survey Statistics")}
        </CNavLink>
      </CNavItem>
    </CNav>
  );

  const TabsContent = () => (
    <CTabContent>
    <CTabPane data-tab="MedicalHistoryAppoinments">
        <ObjectDetails
          object={activeCase}
          listItems={[
            "recommendations",
            "examination",
            "medical_history",
          ]}
        />
    </CTabPane>
      <CTabPane data-tab="MedicalHistory">
        <ObjectDetails
          object={activeCase}
          listItems={[
            "chief_complaint",
            "examination",
            "present_illness_history",
          ]}
        />
      </CTabPane>
      <CTabPane data-tab="VitalSigns">
        <VitalSidebar chartColWidth={12}>
          <VitalSigns episodeStatus={1} />
        </VitalSidebar>
      </CTabPane>
      <CTabPane data-tab="Diagnosis">
        <CDataTable
          items={activeCase.icds ? activeCase.icds : []}
          fields={[
            { key: "code", label: i18next.t("code") },
            { key: "title", label: i18next.t("title") },
          ]}
          noItemsViewSlot={i18next.t("No Available Items")}
          striped
          scopedSlots={{
            title: (item) => <td>{removeHtmlTags(item.title)}</td>,
          }}
        />
      </CTabPane>
      <CTabPane data-tab="Investigation">
        <Investigation
          modelId={activeCase.id}
          patientId={activeCase.patient_id}
          isPatientView
          showDownload={false}
        />
      </CTabPane>
      <CTabPane data-tab="Prescription">
        <CDataTable
          items={getPrescriptionsList()}
          fields={fields}
          striped
          noItemsViewSlot={i18next.t("No Available Items")}
          scopedSlots={{
            drug: (item) => <td>{item.drug.drug_name}</td>,
          }}
        />
      </CTabPane>
      <CTabPane data-tab="SurveyStatistics">
        <QuestionChartComponent patientId={patient.id} />
      </CTabPane>
    </CTabContent>
  );

  return (
    <CCol xs="12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
      <CCard className="mt-3 p-5 share-section text-theme-blue">
        <CCardTitle className="text-left mt-2" style={{ marginBottom: "30px" }}>
          <h3 className="round-title-style">
            {i18next.t("Patient Shared Data")}{" "}
          </h3>
        </CCardTitle>
        <CCard>
          <CCardBody>
            <CRow style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <CCol lg={12}>
                <h3>
                  <lable>{i18next.t("Patient Name")} : </lable>
                  {`${patient.first_name[0].toUpperCase()}. ${patient.last_name[0].toUpperCase()}`}
                </h3>
                <CTabs
                  activeTab={
                    caseType === TYPE_EPISODE ? "MedicalHistory" : "Diagnosis"
                  }
                  onActiveTabChange={(activeTab) => {
                    onActiveTabChange(activeTab)
                    setCurrentTab(activeTab)
                  }}
                >
                  {SideBar()}
                  {TabsContent()}
                </CTabs>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCard>
    </CCol>
  );
};

export default ActiveCase;
