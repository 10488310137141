const requiredMessage = "Field is required";
const mandatorySpeakersRequiredRule = (inputValue, watch) => {
  console.log("WATCH>>", watch("spokes"));
  const spokesValue = watch("spokes");
  if (spokesValue.length > 0 && !!spokesValue[0].consultants) {
    return true;
  } else {
    // required if there are no presenters(consultants)
    return !!inputValue;
  }
};

const convertTimeToMinutes = (time) => {
  const arr = time.split(":");
  return Number(arr[0]) * 60 + Number(arr[1]);
};
const validateTimeFromTo = (fromTime, toTime) => {
  return convertTimeToMinutes(fromTime) < convertTimeToMinutes(toTime);
};

export const getValidation = (watch) => {
  const validation = {
    title: { required: requiredMessage, maxLength: 255 },
    name: { required: requiredMessage, maxLength: 255 },
    specialty_id: {
      required: requiredMessage,
      validate: (value) => value > 0,
    },
    organizer: { required: requiredMessage },
    unit: { required: requiredMessage },
    date: { required: requiredMessage },
    from: { required: requiredMessage },
    to: {
      required: requiredMessage,
      validate: (value) =>
        watch(`from`) ? validateTimeFromTo(watch(`from`), value) : true,
    },
    time_zone: { required: requiredMessage },
    type: { required: requiredMessage },
    mandatory_speakers: {
      validate: (value) => mandatorySpeakersRequiredRule(value, watch),
    },
    email: {
      required: requiredMessage,
      pattern: {
        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i,
        message: "invalid email",
      },
    },
  };
  return validation;
};

export const getClassName = (watch, errors, inputName, defaultValue) => {
  const targetValue = watch(inputName);
  if (errors[inputName]) {
    return "is-invalid";
  }
  console.log(">>>>", inputName, targetValue, defaultValue);
  if (targetValue && `${targetValue}` !== `${defaultValue}`) {
    return "is-valid";
  }
  return "";
};

export const customStyles = (isValid) => ({
  control: (base, state) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
    // overwrittes hover style
    "&:hover": {
      borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
    },
  }),
});
