import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createRadiologyCenter } from "actions/radiology-centers/radiologyCenters";
import { fetchCountries, fetchCities } from "actions/cities/cities";
import RadiologyCenterForm from "../form/RadiologyCenterForm";
import i18next from "i18next";

/**
 * @param props
 */
function CreateRadiologyCenter() {
  const history = useHistory();
  const label = `${i18next.t("Create New Radiology Center")}`;
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.radiologyCenters.redirect);
  const cities = useSelector((state) => state.cities);
  const countries = useSelector((state) => state.countries);

  const onSubmit = async (formData) => {
    await dispatch(createRadiologyCenter(formData));
  };

  if (redirect) {
    history.push("/service/providers/radiology-centers");
  }

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  const onCountryChange = (e) => {
    dispatch(fetchCities(e.target.value));
  };

  return (
    <RadiologyCenterForm
      onSubmit={onSubmit}
      label={label}
      countries={countries.data ? countries.data : []}
      cities={cities.data ? cities.data : []}
      onCountryChange={onCountryChange}
    />
  );
}

export default CreateRadiologyCenter;
