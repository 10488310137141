import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CLink,
} from "@coreui/react";
import {
  fetchSpecialtiesFormUtilities,
  fetchSpecialtiesForms,
  deleteSpecialtiesForm,
} from "actions/specialties-form/specialtiesForm";
import { LIMIT } from "constants/constants";
import FiltersSection from "../form/FiltersSection";
import PreviewSurvey from "../form/PreviewSurvey";
import i18next from "i18next";
import fileDownload from "js-file-download";
import { Api, getTokenHeader } from "services/Api";

const Levels = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [previewSurvey, setPreviewSurvey] = useState(false);
  const dispatch = useDispatch();
  const specialtiesForm = useSelector((state) => state.specialtiesForm.data);
  const meta = useSelector((state) => state.specialtiesForm.meta);
  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/specialties-forms?page=${newPage}`);
    }
  };

  const handleDeleteLevel = (id: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This Specialty Form.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteSpecialtiesForm(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchSpecialtiesFormUtilities());
    dispatch(fetchSpecialtiesForms(page, LIMIT));
  }, [dispatch, page]);

  const onFilter = (filters) => {
    dispatch(fetchSpecialtiesForms(page, LIMIT, filters));
  };

  const onClosePreview = () => {
    setPreviewSurvey(false);
    dispatch(fetchSpecialtiesForms(page, LIMIT));
  };
  const handleDownload = async (id, title) => {
    const response = await Api.get(
      `/api/admin/survey-instances/patients/export/${id}`,
      {
        ...getTokenHeader(),
        responseType: "blob",
      }
    );
    fileDownload(response.data, `${title}-${id}.xlsx`);
  };

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol md="6">
          <div className="float-left table-label-title">{i18next.t("specialties forms")}</div>
        </CCol>
        <CCol md="6">
          <CButton
            color="success"
            href="/specialties-forms/create"
            className="create-button float-right wi"
            style={{ width: "13em" }}
          >
            {i18next.t("New Specialties Form")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <FiltersSection onFilter={onFilter} />
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={specialtiesForm || []}
                fields={[
                  { key: "id", label:  i18next.t("id") },
                  { key: "title", label:  i18next.t("title") },
                  { key: "specialty", label:  i18next.t("Specialty") },
                  { key: "hospital", label:  i18next.t("hospital") },
                  { key: "type", label:  i18next.t("Type") },
                  { key: "actions", label:  i18next.t("Actions") },
                  ]}
                hover
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                scopedSlots={{
                  actions: (item) => (
                    <td>
                      <CButton
                        onClick={() => handleDownload(item.id, item.title)}
                        style={{ marginRight: "5px" }}
                        className="btn btn-primary"
                      >
                        {i18next.t("Export data")}
                      </CButton>
                      {item.status == 2 && <a
                        href={`/specialties-forms/${item.id}/edit`}
                        style={{
                          marginRight: "5px",
                          "background-color": "rgb(48, 197, 182)",
                          "border-color": "rgb(48, 197, 182)",
                          color: "white",
                          width: "7em",
                        }}
                        className="btn"
                      >
                        {i18next.t("Edit")}
                      </a>}
                      <CButton
                        onClick={() => setPreviewSurvey(item.id)}
                        style={{ marginRight: "5px" }}
                        className="btn btn-primary"
                      >
                        {i18next.t("Preview")}
                      </CButton>
                      <CButton
                        onClick={() => handleDeleteLevel(item.id)}
                        color="danger"
                      >
                        {i18next.t("Delete")}
                      </CButton>
                    </td>
                  ),
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <PreviewSurvey
          surveyId={previewSurvey}
          onCancel={onClosePreview}
          modal={previewSurvey}
        />
      </CRow>
    </>
  );
};

export default Levels;
