import React from "react";
import i18next from "i18next";
import { CButton, CModal, CModalBody, CModalFooter } from "@coreui/react";
import { isObject, isArray } from "helpers/helpers";

const RenderSentSurvey = (sentSurvey, sentSurveyId) => {
  const removeHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

  console.log(sentSurvey)
  if (sentSurvey && sentSurvey.id === sentSurveyId) {
    console.log(sentSurvey.doctor);
    return (
      <>
        <table className="table table-striped table-hover">
          <tbody>

            <tr>
              <td>
                <strong>{i18next.t("Title")}</strong>
              </td>
              <td>{sentSurvey.survey.title}</td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("hospital")}</strong>
              </td>
              <td>
                {sentSurvey.location
                  ? sentSurvey.location.name
                  : i18next.t("No Specific Hospital")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Specialty")}</strong>
              </td>
              <td>
                {sentSurvey.specialty
                  ? sentSurvey.specialty.name
                  : i18next.t("No Specific Specialty")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Country")}</strong>
              </td>
              <td>
                {sentSurvey.country ? sentSurvey.country : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("City")}</strong>
              </td>
              <td>{sentSurvey.city ? sentSurvey.city : i18next.t("Not Selected")}</td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Duration")}</strong>
              </td>
              <td>
                {sentSurvey.duration ? sentSurvey.duration : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Duration Unit")}</strong>
              </td>
              <td>
                {sentSurvey.duration_unit
                  ? sentSurvey.duration_unit
                  : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Gender")}</strong>
              </td>
              <td>{sentSurvey.gender ? sentSurvey.gender : i18next.t("Not Selected")}</td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Age From")}</strong>
              </td>
              <td>
                {sentSurvey.age_from ? sentSurvey.age_from : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Age To")}</strong>
              </td>
              <td>{sentSurvey.age_to ? sentSurvey.age_to : i18next.t("Not Selected")}</td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Created From")}</strong>
              </td>
              <td>
                {sentSurvey.created_from
                  ? sentSurvey.created_from_formatted
                  : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Created To")}</strong>
              </td>
              <td>
                {sentSurvey.created_to
                  ? sentSurvey.created_to_formatted
                  : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Created At")}</strong>
              </td>
              <td>
                {sentSurvey.created_at
                  ? sentSurvey.created_at
                  : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
            <td>
                <strong>{i18next.t("Condition Hospital")}</strong>
              </td>
             <td>
                {sentSurvey.condition_location
                  ? sentSurvey.condition_location.name
                  : i18next.t("No Specific conditional Hospital")}
              </td>
            </tr>
            <tr>
            <td>
                <strong>{i18next.t("Condition Specialty")}</strong>
              </td>
             <td>
                {sentSurvey.condition_specialty
                  ? sentSurvey.condition_specialty.name
                  : i18next.t("No Specific conditional Specialty")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Condition survey")}</strong>
              </td>
              <td>
                {sentSurvey.condition_survey
                  ? sentSurvey.condition_survey.title
                  : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Condition Date From")}</strong>
              </td>
              <td>
                {sentSurvey.condition_date_from
                  ? sentSurvey.condition_date_from_formatted
                  : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Condition Date To")}</strong>
              </td>
              <td>
                {sentSurvey.condition_date_to
                  ? sentSurvey.condition_date_to_formatted
                  : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Condition Clinic")}</strong>
              </td>
              <td>
                {sentSurvey.clinic ? sentSurvey.clinic.title : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Condition Clinic Type")}</strong>
              </td>
              <td>
                {sentSurvey.condition_clinic_type
                  ? sentSurvey.condition_clinic_type
                  : i18next.t("Not Selected")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Condition Doctor")}</strong>
              </td>
              <td>
                {sentSurvey.doctor
                  ? `${sentSurvey.doctor.first_name} ${sentSurvey.doctor.last_name}`
                  : i18next.t("Not Selected")}
              </td>
            </tr>

            <tr>
              <td>
                <strong>{i18next.t("diagnostics")}</strong>
              </td>
              <td>
                {sentSurvey.diagnostics.length === 0 ? (
                    <>{i18next.t("No Available Data")}</>
                ) : (
                    sentSurvey.diagnostics.map((dia) => (
                        <ul className="text-start">
                          <li className="text-start"><strong>Code: </strong>{dia.code} </li>
                          <li className="text-start"> <strong>Title: </strong>{removeHtmlTags(dia.title)} </li>
                        </ul>
                    ))
                )}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Matched Users Count")}</strong>
              </td>
              <td>
                {sentSurvey.matched_users_count ?? 0}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Answered Users Count")}</strong>
              </td>
              <td>
                {sentSurvey.answered_users_count ?? 0}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
      </>
    );
  }
  return <></>;
};

const Show = ({ sentSurvey, sentSurveyId, showModal, toggleShowModal }) =>

  showModal ? (
    <CModal show={showModal} size="lg" onClose={toggleShowModal}>
      <h4 className="table-title" style={{ textTransform: "capitalize" }}>
        {sentSurvey?.survey?.title}
      </h4>
      <CModalBody>{RenderSentSurvey(sentSurvey, sentSurveyId)}</CModalBody>
      <CModalFooter>
        <CButton type="reset" color="danger" onClick={toggleShowModal}>
          {i18next.t("Cancel")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    <></>
  );
export default Show;
