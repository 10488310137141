import React, { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
  CInput,
  CCardHeader,
  CLink,
  CSelect,
  CButton,
} from "@coreui/react";
import Avatar from "views/partials/Avatar";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import {
  fetchDoctors,
  doctorsLoggedIn,
  doctorsLoggedOut,
} from "actions/doctors/doctors";
import { createRequest } from "actions/requests/requests";
import Request from "views/requests/Request";
import {
  IS_ONLINE,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
} from "constants/constants";
import i18next from "i18next";

const ConsultantCard = ({ consultant, handleCall }) => {
  const [disableBtn, setDisableBtn] = useState(false);
  return (
  <CCard className="text-dark bg-light" style={{ borderRadius: "15px" }}>
    <CCardBody>
      <CRow>
        <CCol xs={10} sm={4} md={4} lg={4} xl={2} >
          <Avatar
            src={consultant.avatar}
            style={{ width: "80%", height: "80%", borderRadius: "70%" }}
          />
        </CCol>
        <CCol xs={12} sm={8} md={8} lg={8} xl={5}>
          <CCardTitle>{consultant.name}</CCardTitle>
        </CCol>
        <CCol xs={12} sm={6} md={6} lg={6} xl={2}>
          <CCardSubtitle>
            {consultant.specialty ? consultant.specialty.name : ""}
          </CCardSubtitle>
        </CCol>
        <CCol xs={12} sm={6} md={6} lg={6} xl={3}>
          {consultant.is_online ? (
            <CButton
              className="btn btn-primary"
              disabled={disableBtn}
              onClick={() => { handleCall(consultant.id); setDisableBtn(true);}}
            >
              {i18next.t("Call")}
            </CButton>
          ) : (
            <span style={{ color: "red" }}>{i18next.t("Offline")}</span>
          )}
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
  );
}

const ConsultantsList = ({ consultants, handleCall }) =>
  consultants.map((consultant) => (
    <ConsultantCard consultant={consultant} handleCall={handleCall} />
  ));
const Consultants = ({ hospitalId }) => {
  const dispatch = useDispatch();
  const { episodeId } = useParams();
  const [queryObject, setQueryObject] = useState({
    hospital: hospitalId,
    in_shift: IS_ONLINE,
  });

  const loggedInUser = useSelector((state) => state.auth.jwt.user.id);
  const consultants = useSelector((state) =>
    state.doctors.data
      ? state.doctors.data.filter((item) => item.id != loggedInUser)
      : []
  );
  const request = useSelector((state) => state.requests.request);
  const specialties = useSelector(
    (state) => state.followUpUtilities.specialties
  );

  const handleCall = (consultantId) => {
    dispatch(
      createRequest({
        sender_id: loggedInUser,
        receiver_id: consultantId,
        hospital_id: hospitalId,
        episode_id: episodeId,
      })
    );
  };

  const search = (event) => {
    setQueryObject({ ...queryObject, q: event.target.value });
    dispatch(fetchDoctors({ ...queryObject, q: event.target.value }));
  };

  const onSpecialtyChange = (event) => {
    setQueryObject({ ...queryObject, specialty: event.target.value });
    dispatch(fetchDoctors({ ...queryObject, specialty: event.target.value }));
  };
  useEffect(() => {
    dispatch(
      fetchDoctors({
        hospital: hospitalId,
        in_shift: IS_ONLINE,
      })
    );
    window.Echo.private(`hospital.${hospitalId}`).notification(
      (notification) => {
        console.log(notification);
        if (notification.type === USER_LOGGED_IN) {
          dispatch(doctorsLoggedIn(notification.user));
        } else if (notification.type === USER_LOGGED_OUT) {
          dispatch(doctorsLoggedOut(notification.user));
        }
      }
    );
  }, [dispatch, hospitalId, loggedInUser]);
  if (request && request.episode_id == episodeId) {
    return <Request />;
  }
  return (
    <CCard className="text-dark bg-secondary" style={{ borderRadius: "15px" }}>
      <CCardHeader>
        <CRow>
          <CCol md={6}>
            <CInput placeholder={i18next.t("Search By Name")} onChange={search} />
          </CCol>
          <CCol md={6}>
            <CSelect custom onChange={onSpecialtyChange}>
              <option key="0" value="" selected disabled>
                {i18next.t("Select Specialty")}
              </option>
              {RenderSelectOptions(specialties || [])}
            </CSelect>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <ConsultantsList consultants={consultants} handleCall={handleCall} />
      </CCardBody>
    </CCard>
  );
};

export default Consultants;
