import React from "react";
import {CImg} from "@coreui/react";

const Avatar = ({src, style, alt, className}) => {
  return <CImg
            src={src ? src : "/avatars/avatar.png"}
            style={style}
            alt={alt}
            className={className}
            onError={(e) => {
                e.target.src = "/avatars/avatar.png"; // eslint-disable-line no-param-reassign
              }}
          />
};

export default Avatar;
