import React, { useState, useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
} from "@coreui/react";
import StaffForm from "./StaffForm";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";

const Staff = (props) => {
  const {
    staff,
    onActivePageChange,
    page,
    onSubmit,
    modal,
    toggle,
    handleSelectRoleChange,
    excludedUsers,
    handleRemoveMember,
    staffMembers,
    medicalProvider
  } = props;
  const onCancel = () => {
    toggle();
  };
  const [isFound, setIsFound] = useState(false);
  const authUser = useSelector((state) => state.auth.jwt.user);

  useEffect(() => {
    if (staffMembers) {
      const found = staffMembers.find((element) => {
        return Number(element.id) === Number(authUser.id);
      });
      const permissions = authUser.permissions.find((element) => {
        return element === medicalProvider;
      });

      if ((found && permissions) || authUser.roles.find((element) => {
        return element == "Super Admin";
      }) ) {
        setIsFound(true);
      }
    }
  }, [staff]);

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          {isFound && (
            <CCardHeader>
              <CRow className="justify-content-between align-items-center my-1">
                <CCol>
                  <div className="float-left table-title">{i18next.t("Staff")}</div>
                </CCol>

                <CCol>
                  <CButton
                    color="success"
                    onClick={toggle}
                    className="create-button float-right m-0 wi"
                  >
                    {i18next.t("New member")}
                  </CButton>
                </CCol>
              </CRow>

              <StaffForm
                onSubmit={onSubmit}
                modal={modal}
                onCancel={onCancel}
                rolesList={staff ? staff.rolesList : []}
                handleSelectRoleChange={handleSelectRoleChange}
                excludedUsers={excludedUsers}
              />
            </CCardHeader>
          )}

          <CCardBody>
            <CDataTable
              items={staff ? staff.data : []}
              noItemsViewSlot={i18next.t("No Available Items")}
              fields={[
                { key: "id", label:  i18next.t("id") },
                { key: "name", label:  i18next.t("Name") },
                { key: "role", label:  i18next.t("Role") },
                { key: "actions", label:  i18next.t("Actions") },
              ]}
              hover
              striped
              scopedSlots={{
                actions: (item) => (
                  <td>
                    {isFound && (
                      <CButton
                        onClick={() => handleRemoveMember(item.id)}
                        color="danger"
                      >
                        {i18next.t("Remove")}
                      </CButton>
                    )}
                  </td>
                ),
              }}
            />
            <CPagination
              activePage={page}
              onActivePageChange={onActivePageChange}
              pages={staff ? staff.meta.last_page : 1}
              doubleArrows={false}
              align="center"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Staff;
