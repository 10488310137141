import React from "react";
import { useFieldArray } from "react-hook-form";
import i18next from "i18next";
import { CCol, CLabel, CButton, CFormGroup, CInput } from "@coreui/react";
import {
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";

const Answers = ({ control, register, watch, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "answers",
  });

  const Validation = getValidation(watch);

  const renderAnswersFields = () =>
    fields.map((row, index) => (
      <>
        <CFormGroup row key={row.value}>
          <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
            <CLabel className="Option required">{i18next.t("Option")}</CLabel>
            <CInput
              type="text"
              id="label"
              name={`answers[${index}].label`}
              defaultValue={row.label}
              placeholder={i18next.t("Write Option")}
              innerRef={register(Validation.require)}
            />
          </CCol>
          <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
            <CLabel className="labels required">{i18next.t("Value")}</CLabel>
            <CInput
              type="text"
              id="value"
              placeholder={i18next.t("Write Value")}
              name={`answers[${index}].value`}
              defaultValue={row.value}
              innerRef={register(Validation.require)}
            />
          </CCol>
          <CCol
            style={{ "margin-top": "2em" }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >
            <CButton color="danger" onClick={() => remove(index)}>
              {i18next.t("Remove")}
            </CButton>
          </CCol>
        </CFormGroup>
      </>
    ));

  return (
    <>
      <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
        <h4 className="table-title">{i18next.t("Answers")}</h4>
      </CCol>
      {renderAnswersFields()}
      <CFormGroup row className="text-align-center">
        <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
          <CButton
            style={{
              marginRight: "5px",
              "background-color": "rgb(48, 197, 182)",
              "border-color": "rgb(48, 197, 182)",
              color: "white",
              width: "11em",
            }}
            onClick={() => append({})}
          >
            {i18next.t("Add")}
          </CButton>
        </CCol>
      </CFormGroup>
    </>
  );
};
export default Answers;
