import React from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CButton,
  CCardTitle,
  CCardSubtitle,
} from "@coreui/react";
import Avatar from "views/partials/Avatar";

const PatientCallCard = ({ item, complaint }) => (
  <>
    <CCard className="my-1 text-theme-blue clinic-card card-zoom-out">
      <CCardBody className="py-1">
        <CRow className="align-items-center justify-content-between">
          <CCol md="1">
            <CRow>
              <CCol xs="12">
                <Avatar
                  src={item.patient.avatar}
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  className="float-left"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="8">
            <CRow className="align-items-center justify-content-center">
              <CCol xs="auto" className="d-flex flex-column p-1">
                <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                  {`${item.patient.first_name} ${item.patient.last_name}`}
                </CCardTitle>
                <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                  {item.cause ? item.cause : "Cause Not Found"}
                </CCardSubtitle>
              </CCol>
              <CCol xs="auto" className="d-flex flex-column p-1">
                <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                  Time
                </CCardTitle>
                <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                  {item.schedule_time}
                </CCardSubtitle>
              </CCol>
              <CCol xs="auto" className="d-flex flex-column p-1">
                <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                  Status
                </CCardTitle>
                <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                  Confirmed
                </CCardSubtitle>
              </CCol>
              <CCol xs="auto" className="d-flex flex-column p-1">
                <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                  Order
                </CCardTitle>
                <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                  {item.lobby_order ? item.lobby_order : "None"}
                </CCardSubtitle>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="3">
            <CRow className="justify-content-end align-items-center">
              <CCol xs="12" className="d-flex flex-column">
                <CButton className="float-right" color="danger">
                  Cancel
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs="3" md="2">
            {/* todo hesham */}
          </CCol>
          <CCol
            xs="9"
            md="10"
            style={{
              flex: 1,
              borderColor: "#000",
              borderStyle: "dotted",
              borderWidth: 3,
              borderRadius: 13,
              // position: "relative",
            }}
          >
            {item.complaint}
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    {/* <CCard className="bg-info" style={{ borderRadius: "15px", marginTop: "2%" }}>
      <CCardBody>
        <CRow>
          <CCol xs="3" md="2">
            <CImg
              src="https://picsum.photos/200/300?random=3"
              style={{ width: "60%", height: "60%", borderRadius: "50%" }}
            />
          </CCol>
          <CCol xs="9" md="10">
            <CRow>
              <CCol md="6">{`${patient.first_name} ${patient.last_name}`}</CCol>
              <CCol md="6">{patient.gender}</CCol>
              <CCol md="6">{patient.age}</CCol>
              <CCol md="6">{patient.uuid}</CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs="3" md="2" />
          <CCol xs="9" md="10">
            {complaint}
          </CCol>
        </CRow>
      </CCardBody>
    </CCard> */}
  </>
);
export default PatientCallCard;
