import React from "react";
import i18next from "i18next";
import { CButton, CModal, CModalBody, CModalFooter } from "@coreui/react";
import Screens from "./discharge-form/Screens";

const DischargeFormSummary = ({ setShowModal, showModal, episode }) => {
  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  return showModal ? (
    <CModal show={showModal} size="lg">
      <h4 className="table-title">{i18next.t("Details")}</h4>
      <CModalBody>
        <Screens episode={episode} />
      </CModalBody>
      <CModalFooter>
        <CButton type="reset" color="danger" onClick={toggleShowModal}>
          {i18next.t("Close")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    <></>
  );
};
export default DischargeFormSummary;
