import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  patientAddComplain,
  patientCreateAppointment,
} from "actions/appointments/patientAppointment";
import { editAppointment } from "actions/appointments/appointment";
import Loading from "views/partials/Loading";
import ComplaintForm from "./ComplaintForm";

const Complaint = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const patientAppointmentSelection = useSelector(
    (state) => state.patientAppointmentSelection
  );
  const patientClinicSlots = useSelector((state) => state.patientClinicSlots);
  const { reserved_appointment } = patientClinicSlots;

  useEffect(() => {
    console.log("##", patientAppointmentSelection);
    if (!patientAppointmentSelection.appointment_id) {
      dispatch(patientCreateAppointment(patientAppointmentSelection));
    } else if (reserved_appointment) {
      const updateData = {schedule_time: patientAppointmentSelection.schedule_time};
      dispatch(
        editAppointment(reserved_appointment, updateData))
    }
  }, [patientAppointmentSelection]); // eslint-disable-line

  const onSubmit = (formData) => {
    const { appointment_id } = patientAppointmentSelection;
    const preparedFormData = new FormData();
    preparedFormData.append("cause", formData.cause);
    preparedFormData.append("complaint", formData.complaint);
    dispatch(patientAddComplain(appointment_id, preparedFormData));
    // dispatch(resetPatientAppointmentSelection());
    history.push(`/payment/methods`);
  };

  return patientAppointmentSelection.appointment_id ? (
    <ComplaintForm
      appointmentId={patientAppointmentSelection.appointment_id}
      onSubmit={onSubmit}
    />
  ) : (
    <Loading />
  );
};
export default Complaint;
