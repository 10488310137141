import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  patientAddComplain,
  fetchAppointment,
} from "actions/appointments/patientAppointment";
import Loading from "views/partials/Loading";
import ComplaintForm from "./ComplaintForm";

const EditComplaint = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { appointmentId } = useParams();
  const patientAppointment = useSelector((state) =>
    state.appointments ? state.patientAppointments.appointment : {}
  );
  console.log("patientAppointment>>", patientAppointment);
  useEffect(() => {
    dispatch(fetchAppointment(appointmentId));
  }, [dispatch, appointmentId]);

  const onSubmit = (formData) => {
    const preparedFormData = new FormData();
    preparedFormData.append("cause", formData.cause);
    preparedFormData.append("complaint", formData.complaint);
    dispatch(patientAddComplain(appointmentId, preparedFormData));
    // dispatch(resetPatientAppointmentSelection());
    history.push(`/patient/appointments`);
  };

  return patientAppointment ? (
    <ComplaintForm
      appointmentId={appointmentId}
      onSubmit={onSubmit}
      appointmentBind={{ complaint: patientAppointment.complaint }}
    />
  ) : (
    <Loading />
  );
};
export default EditComplaint;
