import { Api, getTokenHeader } from "services/Api";
import {
  FETCH_PERMISSIONS,
  CREATE_ROLE,
  FETCH_ROLE,
  UPDATE_ROLE,
  FETCH_ROLES,
} from "./types";

export const fetchPermissions = (): Object => async (dispatch) => {
  const response = await Api.get("/api/admin/permissions", {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_PERMISSIONS, payload: response.data });
};

export const createRole = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/roles`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_ROLE, payload: response.data });
};

export const fetchRole = (id: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/role/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_ROLE, payload: response.data });
};

export const updateRole = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(`/api/admin/roles/${id}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_ROLE, payload: response.data });
};

export const fetchRoles = (page: string, limit: string): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/roles?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_ROLES, payload: response.data });
};
