import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTabs,
  CNav,
  CNavLink,
  CNavItem,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import {
  fetchHospital,
  fetchHospitalStaff,
  addHospitalStaff,
  fetchHospitalExcludedUsers,
  deleteHospitalMember,
} from "actions/hospitals/hospitals";
import ObjectDetails from "views/partials/ObjectDetails";
import Staff from "views/partials/Staff";
import { confirmAlert } from "react-confirm-alert";
import i18next from "i18next";

const Hospital = ({ match }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState(null);
  const [modal, setModal] = useState(false);
  const hospital = useSelector((state) => state.hospitals.hospital);
  const staff = useSelector((state) => state.hospitals.staff);
  const excludedUsers = useSelector((state) =>
    state.hospitals.excludedUsers ? state.hospitals.excludedUsers : []
  );
  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    dispatch(fetchHospital(match.params.id));
  }, [dispatch, match.params.id]);

  const onActiveTabChange = (activeTab) => {
    setTab(activeTab);
    switch (activeTab) {
      case "staff":
        dispatch(fetchHospitalStaff(match.params.id));
        break;
      default:
        break;
    }
  };

  const onActivePageChange = (activePage) => {
    setPage(activePage);
    switch (tab) {
      case "staff":
        dispatch(fetchHospitalStaff(match.params.id, activePage));
        break;
      default:
        break;
    }
  };

  const handleSelectRoleChange = (event) => {
    dispatch(fetchHospitalExcludedUsers(match.params.id, event.target.value));
  };

  const prepareFormData = (data) => {
    const usersIds = data.users ? data.users.map((item) => item.id) : [];
    return { ...data, users: usersIds };
  };

  const onSubmit = (formData) => {
    dispatch(addHospitalStaff(match.params.id, prepareFormData(formData)));
    toggle();
  };
  const handleRemoveMember = (memberId: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Remove This Staff Member.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteHospitalMember(match.params.id, memberId));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const renderHospital = () => {
    if (hospital) {
      return (
        <CRow>
      <CCol lg={12}>
        <CCard>
          <CCardBody>
            <CTabs
              activeTab="details"
              onActiveTabChange={(activeTab) => onActiveTabChange(activeTab)}
            >
              <CNav variant="tabs">
                <CNavItem>
                  <CNavLink className="navchange" data-tab="details">{i18next.t("Details")}</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink className="navchange"data-tab="staff">{i18next.t("Staff")}</CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent>
                <CTabPane data-tab="details">
                  <ObjectDetails
                    object={hospital}
                    listItems={["name", "phone", "address", "description"]}
                  />
                </CTabPane>
                <CTabPane data-tab="staff">
                  <Staff
                    staff={staff}
                    onActivePageChange={onActivePageChange}
                    page={page}
                    onSubmit={onSubmit}
                    modal={modal}
                    toggle={toggle}
                    handleSelectRoleChange={handleSelectRoleChange}
                    excludedUsers={excludedUsers}
                    handleRemoveMember={handleRemoveMember}
                    staffMembers={hospital.staffMembers}
                    medicalProvider='manage-hospital-member'
                    />
                </CTabPane>
              </CTabContent>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
        </CRow>
      );
    }
    return (
      <h4 className="text-align-center not-found">
        <strong>Not Found</strong>
      </h4>
    );
  };
  return (
    <CRow>
      <CCol lg="12">{renderHospital()}</CCol>
    </CRow>
  );

};

export default Hospital;
