import React from "react";
import { CCol, CFormGroup, CLabel, CInput, CInputRadio } from "@coreui/react";
import "../../../../scss/style.scss";
import { getClassName } from "views/hospitals/form/ServiceProviderValidation";
import { calculateAge } from "helpers/helpers";
import i18next from "i18next";

const PatientSimpleForm = ({
  register,
  setValue,
  Validation,
  watch,
  errors,
}) => {
  const handleBirthDateChange = (e) => {
    const birthDate = e.target.value;
    let age = calculateAge(birthDate);
    setValue("age",age)
  };
  return(
  <>
    <CFormGroup row>
      <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
        <CLabel className="labels required">{i18next.t("First Name")}</CLabel>
        <CInput
          className={`${getClassName(
            "first_name",
            "first_name",
            watch,
            errors
          )}`}
          innerRef={register(Validation.first_name)}
          type="text"
          id="first_name"
          name="first_name"
        />
      </CCol>
      <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
        <CLabel className="labels required">{i18next.t("Last Name")}</CLabel>
        <CInput
          className={`${getClassName("last_name", "last_name", watch, errors)}`}
          innerRef={register(Validation.last_name)}
          type="text"
          id="last_name"
          name="last_name"
        />
      </CCol>
      <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
        <CLabel className="labels required">{i18next.t("Birth Date")}</CLabel>
        <CInput
          className={`${getClassName(
            "birth_date",
            "birth_date",
            watch,
            errors
          )}`}
          innerRef={register(Validation.bed)}
          type="date"
          id="birth_date"
          name="birth_date"
          onChange={handleBirthDateChange}
          max={new Date().toISOString().substr(0, 10)}
        />
      </CCol>
      <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
        <CLabel className="labels">{i18next.t("Age")}</CLabel>
        <CInput
          type="text"
          id="age"
          name="age"
          innerRef={register}
          readOnly
        />
      </CCol>
    </CFormGroup>
    <CFormGroup row>
      <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
        <CLabel className="labels required" htmlFor="uuid">
          {i18next.t("National ID")}
        </CLabel>
        <CInput
          className={`${getClassName("uuid", "uuid", watch, errors)}`}
          innerRef={register(Validation.uuid)}
          type="text"
          id="uuid"
          name="uuid"
        />
      </CCol>
      <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
        <CLabel className="labels required" htmlFor="email">
          {i18next.t("Email")}
        </CLabel>
        <CInput
          className={`${getClassName("email", "email", watch, errors)}`}
          innerRef={register(Validation.email)}
          type="email"
          id="email"
          name="email"
          placeholder={i18next.t("Enter Email")}
          autoComplete="email"
        />
      </CCol>
      <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
        <CLabel className="labels required">{i18next.t("Mobile No")}.</CLabel>
        <CInput
          className={`${getClassName("phone", "phone", watch, errors)}`}
          innerRef={register(Validation.bed)}
          type="tel"
          id="phone"
          name="phone"
        />{" "}
      </CCol>
      <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
        <CLabel className="labels required">{i18next.t("Gender")}</CLabel>
        <br></br>
        <CFormGroup variant="custom-radio" inline>
          <CInputRadio
            className={`${getClassName("gender", "gender", watch, errors)}`}
            innerRef={register(Validation.gender)}
            custom
            id="inline-radio1"
            name="gender"
            value="male"
          />
          <CLabel
            className="labels"
            variant="custom-checkbox"
            htmlFor="inline-radio1"
          >
            {i18next.t("Male")}
          </CLabel>
        </CFormGroup>
        <CFormGroup variant="custom-radio" inline>
          <CInputRadio
            className={`${getClassName("gender", "gender", watch, errors)}`}
            innerRef={register(Validation.gender)}
            custom
            id="inline-radio2"
            name="gender"
            value="female"
          />
          <CLabel
            className="labels"
            variant="custom-checkbox"
            htmlFor="inline-radio2"
          >
            {i18next.t("Female")}
          </CLabel>
        </CFormGroup>
      </CCol>
    </CFormGroup>

    <CCol xs="12">
      <h4 className="header-label">{i18next.t("Legal Guardian Contact Info")}</h4>
    </CCol>
    <CFormGroup row className="add-new-appontment-titels">
      <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
        <CLabel className="labels" htmlFor="guardian_firts_name">
          {i18next.t("First Name")}
        </CLabel>
        <CInput
          type="text"
          id="guardian_first_name"
          name="guardian_first_name"
          innerRef={register}
        />
      </CCol>
      <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
        <CLabel className="labels" htmlFor="guardian_last_name">
          {i18next.t("Last Name")}
        </CLabel>
        <CInput
          type="text"
          id="guardian_last_name"
          name="guardian_last_name"
          innerRef={register}
        />
      </CCol>
      <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
        <CLabel className="labels">{i18next.t("Email")}</CLabel>
        <CInput
          type="email"
          id="email-input"
          name="guardian_email"
          placeholder={i18next.t("Enter Email")}
          autoComplete="email"
          innerRef={register}
        />
      </CCol>
    </CFormGroup>
    <CFormGroup row>
      <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
        <CLabel className="labels" htmlFor="guardian_address">
          {i18next.t("Address")}
        </CLabel>
        <CInput
          type="text"
          id="guardian_address"
          name="guardian_address"
          innerRef={register}
        />
      </CCol>
      <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
        <CLabel className="labels" htmlFor="guardian_uuid">
          {i18next.t("Guardian ID")}
        </CLabel>
        <CInput
          type="text"
          id="guardian_uuid"
          name="guardian_uuid"
          innerRef={register}
        />
      </CCol>
      <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
        <CLabel className="labels">{i18next.t("Mobile No")}.</CLabel>
        <CInput
          type="tel"
          id="mobile"
          name="guardian_phone"
          innerRef={register}
        />
      </CCol>
    </CFormGroup>
  </>
  )
};

export default PatientSimpleForm;
