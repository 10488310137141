import React, { useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CCol, CRow, CInput, CLabel, CSelect, CFormGroup } from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSearch } from "@coreui/icons";
import i18next from "i18next";

const FilterSection = ({ onFilter }) => {
  const [filters, setFilters] = useState({});
  const utilities = useSelector((state) => state.specialtiesFormUtilities);
  const { types, hospitals, specialties } = utilities;

  const renderTypes = () => {
    if (types) {
      const keys = Object.keys(types);
      return keys.map((item) => (
        <option value={item} key={item}>
          {item}
        </option>
      ));
    }
    return "";
  };

  const handleSearchBoxChange = (event) => {
    setFilters({ ...filters, title: event.target.value });
    onFilter({ ...filters, title: event.target.value });
  };

  const handleLocationChange = (event) => {
    setFilters({ ...filters, hospital_id: event.target.value });
    onFilter({ ...filters, hospital_id: event.target.value });
  };

  const handleSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty_id: event.target.value });
    onFilter({ ...filters, specialty_id: event.target.value });
  };

  const handleTypeChange = (event) => {
    setFilters({ ...filters, type: event.target.value });
    onFilter({ ...filters, type: event.target.value });
  };

  return (
    <>
      <CFormGroup row>
        <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
          <CLabel className="labels" htmlFor="location">
            {i18next.t("Location")}
          </CLabel>
          <CSelect
            custom
            name="location"
            id="location"
            onChange={handleLocationChange}
          >
            <option key="0" value="" selected>
              {i18next.t("Select Location")}
            </option>
            {RenderSelectOptions(hospitals || [])}
          </CSelect>
        </CCol>
        <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
          <CLabel className="labels" htmlFor="specialty">
            {i18next.t("Specialty")}
          </CLabel>
          <CSelect
            custom
            name="specialty"
            id="specialty"
            onChange={handleSpecialtyChange}
          >
            <option key="0" value="" selected>
              {i18next.t("Select Specialty")}
            </option>
            {RenderSelectOptions(specialties || [])}
          </CSelect>
        </CCol>
        <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
          <CLabel className="labels" htmlFor="specialty">
            {i18next.t("Type")}
          </CLabel>
          <CSelect custom name="type" id="type" onChange={handleTypeChange}>
            <option key="0" value="" selected>
              {i18next.t("Select Type")}
            </option>
            {renderTypes()}
          </CSelect>
        </CCol>
      </CFormGroup>
      <CRow>
        <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
          <div>
            <CInput
              className="search-input"
              type="text"
              placeholder={i18next.t("Search by Title")}
              onBlur={handleSearchBoxChange}
            />
            <CIcon
              style={{
                width: "2.5rem",
              }}
              content={cilSearch}
              className="c-icon-xl search-icon"
            ></CIcon>
          </div>
        </CCol>
      </CRow>
    </>
  );
};

export default FilterSection;
