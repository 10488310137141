import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import i18next from "i18next";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CSelect,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import Screens from "views/surveys/questions/components/Screens";
import { TEXT, CHECKBOX, RADIO, DROPDOWN } from "constants/questions";
import { updateQuestion } from "actions/surveys/questions/questions";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";

const EditSubQuestion = ({ dispatch, question, toggle, modal }) => {
  const [activeScreen, setActiveScreen] = useState(null);
  const { register, handleSubmit, control, reset ,watch,errors} = useForm({
    mode: "onBlur",
  });

  const onSubmit = (formData) => {
    const data = {
      ...formData,
      category_id: 1,
    };
    dispatch(updateQuestion(data, question.id));
  };

  useEffect(() => {
    if (question) {
      setActiveScreen(question.type);
      reset({ ...question, answers: question.answers || [] });
    }
  }, [question, reset]);

  const handelOnchange = (e) => {
    setActiveScreen(e.target.value);
  };

  const Validation = getValidation(watch);

  return modal ? (
    <CRow>
      <CCol xs="12">
        <CForm method="post" className="form-horizontal">
          <CModal show={modal} size="lg">
            <h4 className="header-label-title">{i18next.t("Edit Sub Question")}</h4>
            <CModalBody>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                  <CLabel className="labels required">{i18next.t("Question Type")}</CLabel>
                  <CSelect
                    custom
                    name="type"
                    id="type"
                    onChange={handelOnchange}
                    className={`${getClassName("type", "type", watch, errors)}`}
                    innerRef={register(Validation.require)}
                  >
                    <option key="0" selected disabled>
                      {i18next.t("Select Type")}
                    </option>
                    <option key={TEXT} value={TEXT}>
                      {i18next.t("Text")}
                    </option>
                    <option key={CHECKBOX} value={CHECKBOX}>
                      {i18next.t("Checkbox")}
                    </option>
                    <option key={RADIO} value={RADIO}>
                      {i18next.t("Radio")}
                    </option>
                    <option key={DROPDOWN} value={DROPDOWN}>
                      {i18next.t("Dropdown")}
                    </option>
                  </CSelect>
                </CCol>
                <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                  <CLabel className="labels required" htmlFor="question">{i18next.t("Question")}</CLabel>
                  <CInput
                    type="text"
                    id="question"
                    name="question"
                    className={`${getClassName("question", "question", watch, errors)}`}
                    innerRef={register(Validation.require)}                  />
                </CCol>
                <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                  <CLabel className="labels required">{i18next.t("Data type")}</CLabel>
                  <CSelect
                    custom
                    name="data_type"
                    id="data_type"
                    className={`${getClassName("data_type", "data_type", watch, errors)}`}
                    innerRef={register(Validation.require)}                    >
                    <option key="0" selected disabled>
                      {i18next.t("Select Type")}
                    </option>
                    <option key="1" value="1">
                      {i18next.t("String")}
                    </option>
                    <option key="2" value="2">
                      {i18next.t("Integer")}
                    </option>
                    <option key="3" value="3">
                      {i18next.t("Float")}
                    </option>
                  </CSelect>
                </CCol>
              </CFormGroup>
              <Screens
                activeScreen={activeScreen}
                register={register}
                control={control}
              />
            </CModalBody>
            <CModalFooter>
              <CButton
                type="reset"
                onClick={handleSubmit(onSubmit)}
                color="primary"
              >
                {i18next.t("Save")}
              </CButton>
              <CButton type="reset" color="danger" onClick={toggle}>
                {i18next.t("Cancel")}
              </CButton>
            </CModalFooter>
          </CModal>
        </CForm>
      </CCol>
    </CRow>
  ) : (
    ""
  );
};

export default EditSubQuestion;
