import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RESET_SPECIALTIES_FORM } from "actions/specialties-form/types";
import CreateFollowUp from "./Create";
import EditFollowUp from "./Edit";
import List from "./List";
import Show from "./Show";

const FollowUp = ({ type, episodeStatus }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [followUpId, setfollowUpId] = useState(null);
  const followUp = useSelector((state) => state.followUps.followUp);
  const toggle = () => {
    setModal(!modal);
    dispatch({ type: RESET_SPECIALTIES_FORM });
  };
  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  if (isEditing && followUp && followUp.id === followUpId) {
    return (
      <>
        <EditFollowUp
          toggle={toggle}
          modal={modal}
          setIsEditing={setIsEditing}
          followUp={followUp}
        />
        <List
          toggle={toggle}
          toggleShowModal={toggleShowModal}
          setIsEditing={setIsEditing}
          setfollowUpId={setfollowUpId}
          episodeStatus={episodeStatus}
        />
        <Show
          followUp={followUp}
          showModal={showModal}
          toggleShowModal={toggleShowModal}
          followUpId={followUpId}
        />
      </>
    );
  }
  return (
    <>
      <CreateFollowUp toggle={toggle} modal={modal} type={type} />
      <List
        toggle={toggle}
        toggleShowModal={toggleShowModal}
        setIsEditing={setIsEditing}
        setfollowUpId={setfollowUpId}
        episodeStatus={episodeStatus}
      />
      <Show
        followUp={followUp}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
        followUpId={followUpId}
      />
    </>
  );
};
export default FollowUp;
