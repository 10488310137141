import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSpecialtiesForm } from "actions/specialties-form/specialtiesForm";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import i18next from "i18next";

const PreviewSurvey = ({ surveyId, onCancel, modal }) => {
  const dispatch = useDispatch();
  const specialtyForm = useSelector(
    (state) => state.specialtiesForm.specialtyForm
  );
  useEffect(() => {
    if (modal) {
      dispatch(fetchSpecialtiesForm(surveyId));
    }
  }, [modal]);

  return modal ? (
    <CModal show={modal} onClose={onCancel} size="lg">
      <h4 className="table-title" closeButton>{specialtyForm ? specialtyForm.title : "Specialty Form"}</h4>
      <CModalBody>
        <fieldset disabled>
          <SurveyForm groups={specialtyForm ? specialtyForm.groups : []} />
        </fieldset>
      </CModalBody>
      <CModalFooter>
        <CButton type="reset" color="danger" onClick={onCancel}>
        {i18next.t("Close")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    ""
  );
};

export default PreviewSurvey;
