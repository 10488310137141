import { getTokenHeader } from "services/Api";
import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

const initBroadcasting = () => {
  window.Echo = new Echo({
    broadcaster: process.env.REACT_APP_BROADCAST_BROADCASTER,
    key: process.env.REACT_APP_BROADCAST_KEY,
    wsHost: process.env.REACT_APP_BROADCAST_WS_HOST,
    wsPort: process.env.REACT_APP_BROADCAST_WS_PORT,
    wssPort: process.env.REACT_APP_BROADCAST_WSS_PORT,
    forceTLS: true,
    disableStats: true,
    authEndpoint: process.env.REACT_APP_BROADCAST_AUTH_ENDPOINT,
    enabledTransports: ['ws', 'wss'],
    auth: getTokenHeader(),
  });
};

export default initBroadcasting;
