import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { createVitalSign } from "actions/vital-signs/vitalSigns";
import VitalSignsForm from "./form/Form";
import i18next from "i18next";

const CreateVitalSign = ({ toggle, modal }) => {
  const { episodeId } = useParams();
  const lable = `${i18next.t("Create Vital Signs")}`;
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.vitalSignUtilities);
  const staffId = useSelector((state) => state.auth.jwt.user.id);
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      date: format(formData.date, "Y-MM-d HH:mm"),
      staff_id: staffId,
      episode_id: episodeId,
    };
    dispatch(createVitalSign(data));
    toggle();
  };
  const onCancel = () => {
    toggle();
  };
  return (
    <VitalSignsForm
      utilities={utilities}
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal}
      lable={lable}
    />
  );
};
export default CreateVitalSign;
