import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardFooter,
} from "@coreui/react";
import { useHistory, useParams } from "react-router-dom";

import {
  patientAppointmentLobbyInterval,
  loadLobbyData,
} from "actions/appointments/patientAppointment";
import ChatRooms from "./ChatRooms";
import { AppointmentDetailsRow, Lobby } from "../../../partials/partials";
import { PATIENT_APPOINTMENT_LOBBY_CHECK } from "../../../../../actions/appointments/types";
import Loading from "views/partials/Loading";

const CustomRow = (props) => {
  const { title, value } = props;
  return (
    <>
      <br />
      <CRow>
        <CCol xs="3" />
        <CCol xs="6">
          <CCardSubtitle className="text-center font-weight-bold">
            {title}
          </CCardSubtitle>
        </CCol>
        <br />
        <CCol xs="4" />
        <CCol xs="4" className="bg-light py-3">
          <CCardSubtitle className="text-dark text-center font-weight-bold">
            {value}
          </CCardSubtitle>
        </CCol>
      </CRow>
      <br />
    </>
  );
};

const AppointmentCard = ({ appointment, room_current_order, lobbySession }) => {
  const render = () => (
    <CCard>
      <CCardBody>
        <AppointmentDetailsRow
          appointment={appointment}
          imgSrc={appointment.patient.avatar}
          imgStyle={{ width: "30%", height: "60%", borderRadius: "70%" }}
        />
        <CustomRow
          title="Your Number"
          value={appointment ? appointment.lobby_order : "NA"}
        />
        <CustomRow
          title="In The Clinic"
          value={room_current_order || "No One"}
        />
      </CCardBody>
      <CCardFooter>
        <CRow>
          <ChatRooms appointment={appointment} lobbySession={lobbySession}/>
        </CRow>
      </CCardFooter>
    </CCard>
  );

  return (
    <CRow>
      <CCol>{render()}</CCol>
    </CRow>
  );
};

const ClinicLobby = () => {
  const [doctorOnline, setDoctorOnline] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const patientAppointmentLobbyCheck = useSelector(
    (state) => state.patientAppointmentLobbyCheck
  );
  const { lobbySession } = useParams();
  const { appointment, room_current_order } = patientAppointmentLobbyCheck;

  useEffect(() => {
    if (!appointment) {
      dispatch(loadLobbyData(lobbySession));
    }
    dispatch(patientAppointmentLobbyInterval(lobbySession));
  }, [appointment, dispatch, lobbySession]);

  useEffect(() => {
    if (appointment) {
      window.Echo.private(`clinic.${appointment.clinic_id}`).listen(
        "RoomOrder",
        (eventData) => {
          dispatch({
            type: PATIENT_APPOINTMENT_LOBBY_CHECK,
            payload: { room_current_order: eventData.room_current_order },
          });
        }
      );

      window.Echo.join(`lobby.${appointment.lobby_id}`)
        .here((users) => {
          setDoctorOnline(
            users.find((item) => item.id === appointment.doctor_id)
          );
        })
        .leaving((user) => {
          if (user.id === appointment.doctor_id) {
            setDoctorOnline(false);
          }
        })
        .joining((user) => {
          if (user.id === appointment.doctor_id) {
            setDoctorOnline(true);
          }
        })
        .error((error) => {
          console.error(error);
        });
    }
  }, [appointment, dispatch]);

  if (appointment && appointment.lobby_order === room_current_order) {
    history.push(`/patient/video/${lobbySession}`);
  }

  return appointment ? (
    <Lobby>
      <h3 className="table-title">The Doctor is : {doctorOnline ? "Online" : "Offline" }</h3>
      <AppointmentCard
        appointment={appointment}
        room_current_order={room_current_order}
        lobbySession={lobbySession}
      />
    </Lobby>
  ) : (
    <Loading/>
  );
};

export default ClinicLobby;
