import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller } from "react-hook-form";
import { Api, getTokenHeader } from "services/Api";
import AsyncSelect from "react-select/async";
import { CSelect, CInput } from "@coreui/react";

import { fetchHospitals } from "actions/hospitals/hospitals";
import { fetchSpecialties } from "actions/specialties/specialties";
import { fetchRoles } from "actions/roles/roles";
import { fetchCities, fetchCountries } from "actions/cities/cities";

export const FilterAsyncSelect = ({
  register,
  control,
  conditionColumn,
  id,
  name,
  defaultValue,
  conditionsObject,
  isRequired
}) => {
  const dispatch = useDispatch();

  const hospitals = useSelector((state) => state.hospitals.data);
  const specialties = useSelector((state) => state.specialties.data);
  const roles = useSelector((state) => state.roles.data);
  const countries = useSelector((state) => state.countries.data);
  const cities = useSelector((state) => state.cities.data);

  useEffect(() => {
    
    switch (conditionColumn) {
      case "episodes__specialty_id":
      case "specialties__id":
        dispatch(fetchSpecialties());
        break;

      case "episodes__hospital_id":
      case "hospitals__id":
        dispatch(fetchHospitals(1, 50));
        break;

      case "roles__id":
        dispatch(fetchRoles(1, 100));
      case "countries__id":
        dispatch(fetchCountries());
        break;

      case "cities__id":
        dispatch(fetchCities(1, 100));
        break;

      default:
        break;
    }
  }, [dispatch, conditionColumn]);

  const getStaffOptions = async (inputValue) => {
    const response = await Api.get(
      `/api/admin/users?is_staff=${1}&q=${inputValue}`,
      {
        ...getTokenHeader(),
      }
    );
    return response.data.data ? response.data.data : [];
  };
  const getDoctorOptions = async (inputValue) => {
    const url = `/api/admin/doctors/list?q=${inputValue}`;
    const options = await Api.get(url, getTokenHeader()).then(
      (response) => response.data.data
    );
    return options || [];
  };

  const getUsersOptions = async (inputValue) => {
    const response = await Api.get(
      `/api/admin/users?is_staff=${0}&q=${inputValue}`,
      {
        ...getTokenHeader(),
      }
    );
    return response.data.data ? response.data.data : [];
  };

  let isStaff = false;
  let users = false;
  let isDoctor = false;
  let columnObject;

  switch (conditionColumn) {
    case "episodes__specialty_id":
    case "specialties__id":
      columnObject = specialties;
      
      break;

    case "episodes__hospital_id":
    case "hospitals__id":
      columnObject = hospitals;

      break;

    case "roles__id":
      columnObject = roles;
      break;

    case "countries__id":
      columnObject = countries;
      break;

    case "cities__id":
      columnObject = cities;
      break;

    case "users__id":
    case "appointments__patient_id":
    case "episodes__patient_id":
    case "round_users__attendance_state":
    case "round_users__user_id":
      users = true;
      isStaff = false;
      isDoctor = false;
      break;

    case "hospital_staff__user_id":
    case "rounds__organizer_id":
      isStaff = true;
      isDoctor = false;
      users = false;
      break;

    case "appointments__doctor_id":
    case "requests__sender_id":
    case "requests__receiver_id":
      isDoctor = true;
      isStaff = false;
      users = false;
      break;
    default:
      columnObject = null;
      isDoctor = false;
      isStaff = false;
      users = false;
      break;
  }

  return (
    <>
      {columnObject ? (
        <CSelect
          id={id}
          name={name}
          defaultValue={defaultValue}
          innerRef={register({ required: isRequired  })}
        >
          <option value="" selected disabled>
            Select option
          </option>
          {columnObject.map((column) => (
            <option key={column.id} value={column.id}>
              {column.name}
            </option>
          ))}
        </CSelect>
      ) : isStaff ? (
        <Controller
          control={control}
          id={id}
          name={name}
          defaultValue={conditionsObject}
          cacheOptions
          loadOptions={(inputValue) => {getStaffOptions(inputValue)}}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name}`
          }
          getOptionValue={(option) => option.id}
          as={AsyncSelect}
          rules={{ required: isRequired }}

        />
      ) : users ? (
        <Controller
          control={control}
          id={id}
          name={name}
          defaultValue={conditionsObject}
          placeholder={conditionColumn}
          cacheOptions
          loadOptions={(inputValue) => getUsersOptions(inputValue)}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name}`
          }
          getOptionValue={(option) => `${option.id}`}
          as={AsyncSelect}
          rules={{ required: isRequired }}

        />
      ) : isDoctor ? (
        <Controller
          control={control}
          id={id}
          name={name}
          defaultValue={conditionsObject}
          cacheOptions
          loadOptions={(inputValue) => getDoctorOptions(inputValue)}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => option.id}
          as={AsyncSelect}
          rules={{ required: isRequired }}

        />
      ) : (
        <CInput
          type="number"
          id={id}
          name={name}
          innerRef={register({ required: isRequired  })}
          defaultValue={defaultValue}
        />
      )}
    </>
  );
};
