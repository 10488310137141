import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchEpisodeUtilities,
  createEpisode,
} from "actions/episodes/episodes";
import { listAdminSpecialtyForms } from "actions/specialties-form/specialtiesForm";
import { RESET_SPECIALTIES_FORM, FETCH_SPECIALTIES_FORM} from "actions/specialties-form/types";
import { DOCTOR_SPECIALITY_FORM } from "constants/constants";
import i18next from "i18next";
import EpisodeForm from "./forms/EpisodeForm";

const CreateEpisode = () => {
  const { hospitalId } = useParams();
  const history = useHistory();
  const label = `${i18next.t("Add New Episode")}`;
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.episodeUtilities);
  const redirect = useSelector((state) => state.episodes.redirect);
  const staffId = useSelector((state) => state.auth.jwt.user.id);
  const patient = useSelector((state) => state.patients.patient);
  const specialtyForm = useSelector(
    (state) => state.specialtiesForm.specialtyForm
  );
  const specialtyFormsList = useSelector(
    (state) => state.specialtiesForm.adminSpecialtyFormsList
  );

  const onSubmit = async (formData) => {
    const data = {
      ...formData,
      creator_id: staffId,
      survey_id: specialtyForm ? specialtyForm.id : null,
    };
    await dispatch(createEpisode(data)).catch((error) => {
      if (error.response && error.response.status === 422) {
        const validationErrors = error.response.data;
        console.error('Validation errors:', validationErrors);
      }
    });
  };
  const onSpecialtyFormChange = (event) => {
    const selectedForm = specialtyFormsList.find(item => item.id == event.target.value);
    dispatch({ type: FETCH_SPECIALTIES_FORM, payload: {"data": selectedForm} });
  };

  useEffect(() => {
    dispatch(fetchEpisodeUtilities(hospitalId));
    dispatch({ type: RESET_SPECIALTIES_FORM });
  }, [dispatch]);

  if (redirect) {
    history.push(`/episodes/cases/${hospitalId}`);
  }

  const onCancel = () => {
    history.push(`/episodes/cases/${hospitalId}`);
  };

  const onSpecialtyChange = (event) => {
    dispatch(
      listAdminSpecialtyForms(hospitalId, event.target.value, DOCTOR_SPECIALITY_FORM)
    );
  };

  return (
    <EpisodeForm
      onSubmit={onSubmit}
      label={label}
      specialties={utilities.specialties || []}
      departments={utilities.departments || []}
      chronicDiseases={utilities.chronicDiseases || []}
      severity={utilities.severity || []}
      hospitalId={hospitalId}
      specialtyForm={specialtyForm}
      specialtyFormsList={specialtyFormsList}
      onSpecialtyChange={onSpecialtyChange}
      onSpecialtyFormChange={onSpecialtyFormChange}
      patient={patient}
      onCancel={onCancel}
    />
  );
};

export default CreateEpisode;
