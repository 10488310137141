import React from "react";
import { CButton } from "@coreui/react";
import { LABS } from "constants/constants";
import i18next from "i18next";

const Summary = ({ appointment, setActiveScreen }) => {
  return (
    <>
      <table className="table table-striped table-hover">
        <tbody>
              {appointment.recommendations ? (<>
                <tr><td> {i18next.t("Recommendations")} </td></tr>
                <tr><td> {appointment.recommendations} </td></tr>
                </>) : (
                <tr><td>
                  <h4 className="text-align-center">
                    <strong>{i18next.t("No Available Recommendations For Now")}</strong>
                  </h4>
                </td></tr>
              )}

{appointment.diagnosis ? (<>
                <tr><td> {i18next.t("Diagnosis")} </td></tr>
                <tr><td> {appointment.diagnosis} </td></tr>
                </>) : (
                <tr><td>
                  <h4 className="text-align-center">
                    <strong>{i18next.t("No Available Diagnosis For Now")}</strong>
                  </h4>
                </td></tr>
              )}

              {appointment.examination ? (<>
                <tr><td> {i18next.t("Examination")} </td></tr>
                <tr><td> {appointment.examination} </td></tr>
                </>) : (
                <tr><td>
                  <h4 className="text-align-center">
                    <strong>{i18next.t("No Available Examination For Now")}</strong>
                  </h4>
                </td></tr>
              )}

              {appointment.icds ? (<>
                <tr><td> {i18next.t("ICDS")} </td></tr>

               {appointment.icds.map((icd) => (
                  <tr key={icd.code}>
                    <td>Code: {icd.code} </td>
                  </tr>
               ))}
                </>) : (
                <tr><td>
                  <h4 className="text-align-center">
                    <strong>{i18next.t("No Available Examination For Now")}</strong>
                  </h4>
                </td></tr>
              )}

              {/* {appointment.Diagnosis ? (<>
                <tr><td> {i18next.t("Diagnosis")} </td></tr>
                <tr><td> {appointment.diagnosis} </td></tr>
                </>) : (
                <tr><td>
                  <h4 className="text-align-center">
                    <strong>{i18next.t("No Available Diagnosis For Now")}</strong>
                  </h4>
                </td></tr>
              )}
              {appointment.Diagnosis ? (<>
                <tr><td> {i18next.t("Examination")} </td></tr>
                <tr><td> {appointment.examination} </td></tr>
                </>) : (
                <tr><td>
                  <h4 className="text-align-center">
                    <strong>{i18next.t("No Available Examination For Now")}</strong>
                  </h4>
                </td></tr>
              )} */}

        </tbody>
      </table>
      <div className="my-2 float-right">
        <CButton
          color="primary"
          className="mx-1"
          onClick={() => setActiveScreen(LABS)}
        >
            {i18next.t("next")}
        </CButton>
      </div>
    </>
  );
};

export default Summary;
