import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CInput,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CLabel,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CSelect,
  CDropdownItem,
} from "@coreui/react";
import { fetchClinics, deleteClinic } from "actions/clinics/clinics";
import { LIMIT } from "constants/constants";
import { cilOptions, cilPencil, cilTrash, cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { fetchSpecialties } from "actions/specialties/specialties";
import i18next from "i18next";

const ListClinics = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const specialties = useSelector((state) => state.specialties.data);
  const clinics = useSelector((state) => state.clinics.data);
  const meta = useSelector((state) => state.clinics.meta);

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/clinics?page=${newPage}`);
    }
  };

  const handleSearchBoxChange = (event) => {
    setFilters({ q: event.target.value });
    dispatch(fetchClinics(page, LIMIT, { ...filters, q: event.target.value }));
  };

  const handleSpecialtyChange = (event) => {
    setFilters({ specialty: event.target.value });
    dispatch(
      fetchClinics(page, LIMIT, {
        ...filters,
        specialty: event.target.value,
      })
    );
  };

  const handleDeleteClinic = (id: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This Clinic.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteClinic(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchClinics(page, LIMIT));
    dispatch(fetchSpecialties());
  }, [dispatch, page]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">{i18next.t("clinics")}</div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            href="/clinics/create"
            className="create-button float-right wi"
          >
            {i18next.t("New Clinic")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCardHeader>
            <CRow className="justify-content-center align-items-center py-2">
              <CCol className="py-1" xs="12" md="4">
                <div className="search-wrap">
                  <CInput
                    className="search-input"
                    type="text"
                    placeholder={i18next.t("Search by Name , Title")}
                    onChange={handleSearchBoxChange}
                  />
                  <CIcon
                    content={cilSearch}
                    className="c-icon-xl search-icon"
                  ></CIcon>
                </div>
              </CCol>
              <CCol className="py-1" xs="12" md="4">
                <CSelect
                  custom
                  name="specialty"
                  id="specialty"
                  onChange={handleSpecialtyChange}
                >
                  <option key="0" value="" selected>
                    {i18next.t("Select Specialty")}
                  </option>
                  {RenderSelectOptions(specialties ? specialties : [])}
                </CSelect>
              </CCol>
            </CRow>
          </CCardHeader>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={clinics || []}
                noItemsViewSlot={i18next.t("No Available Items")}
                fields={[
                  { key: "id", label:  i18next.t("id") },
                  { key: "title", label:  i18next.t("Title") },
                  { key: "specialty", label:  i18next.t("Specialty") },
                  { key: "doctor", label:  i18next.t("Doctor") },
                  { key: "start_date", label:  i18next.t("Start Date") },
                  { key: "end_date", label:  i18next.t("End Date") },
                  { key: "type", label:  i18next.t("Type") },
                  { key: "actions", label:  i18next.t("Actions") },
                ]}
                hover
                striped
                scopedSlots={{
                  specialty: (item) => <td>{item.specialty.name}</td>,
                  doctor: (item) => (
                    <td>{`${item?.doctor?.first_name} ${item?.doctor?.last_name}`}</td>
                  ),

                  actions: (item) => (
                    <td>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle
                          className="action-toggle m-0"
                          color="light"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <CIcon content={cilOptions} />
                        </CDropdownToggle>
                        <CDropdownMenu className="p-0">
                          <CDropdownItem
                            href={`/clinics/edit/${item.id}`}
                            className="text-primary"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <CIcon content={cilPencil} />
                            <span class="mx-1">{i18next.t("Edit")}</span>
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={() => handleDeleteClinic(item.id)}
                            className="text-danger"
                          >
                            <CIcon content={cilTrash} />
                            <span class="mx-1">{i18next.t("Delete")}</span>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default ListClinics;
