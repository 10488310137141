import axios from "axios";
import { setError } from "actions/error/error";
import { store } from "store";
import { BAD_REQUEST, UNAUTHORIZED_STATUS_CODE } from "constants/constants";
import { LOGIN_FAILURE, OPEN_SIGN_IN_MODAL } from "actions/auth/types";

export const Api = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
});

const { dispatch, getState } = store;
// Dispatch general error handler
Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === UNAUTHORIZED_STATUS_CODE) {
      dispatch({type: OPEN_SIGN_IN_MODAL});
      return {   data:{ status: UNAUTHORIZED_STATUS_CODE } }
    }
    if(error.response?.status == BAD_REQUEST){
      dispatch({type: LOGIN_FAILURE})
    }
    dispatch(setError(error));
    return Promise.reject(error);
    // return {"data":{}};
    // dispatch({type: OPEN_SIGN_IN_MODAL});
  }
);

export const getTokenHeader = () => {
  const accessToken = getState().auth.access_token;
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const getIcdTokenHeader = () => {
  const accessToken = getState().icd.auth.access_token;
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "API-Version": "v2",
      "Accept-Language": "en",
    },
  };
};
