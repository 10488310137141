import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  fetchRoundUtilities,
  fetchRound,
  updateRound,
} from "actions/rounds/rounds";
import { fetchDoctors, clearDoctorsList, fetchConsDoctors } from "actions/doctors/doctors";
import RoundForm from "./form/RoundForm";
import i18next from "i18next";

/**
 * @param {*} props required props
 * @returns {*}  appointment form component
 * @class
 */
function EditRound() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: roundId } = useParams();
  const [filters, setFilters] = useState({});
  const doctors = useSelector((state) => state.doctors);
  const roundUtilities = useSelector((state) => state.roundsUtilities);
  const round = useSelector((state) => state.rounds[roundId]);
  const redirect = useSelector((state) => state.rounds.redirect);
  const { spokes, specialties, units } = roundUtilities;
  const consultants = useSelector(
    (state) => state.consultantDoctors.consultantDoctors
  );

  useEffect(() => {
    dispatch(fetchRound(roundId));
    dispatch(fetchRoundUtilities());
    dispatch(clearDoctorsList());
  }, [dispatch, roundId]);

  useEffect(() => {
    if (round) {
      dispatch(
        fetchDoctors({
          specialty: round.specialty_id,
        })
      );
      (round.spokes).map((value, index) => {
        dispatch(fetchConsDoctors({ hospital: value.spoke }, index));
      })

    }
  }, [dispatch, round]);

  const handleSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty: event.target.value });
    dispatch(fetchDoctors({ specialty: event.target.value }));
  };

  const handleSpokeChange = (event, index) => {
    setFilters({ ...filters, hospital: event.target.value });
    dispatch(fetchConsDoctors({ hospital: event.target.value }, index));
  };

  const onSubmit = (formData) => {
    const preparedData = {
      ...formData,
      organizer_id: formData.organizer ? formData.organizer.id : null,
      shared_speakers: JSON.stringify(formData.shared_speakers),
      mandatory_speakers: formData.mandatory_speakers
        ? formData.mandatory_speakers.map((m) => m.id)
        : [],
      shared_attendees: JSON.stringify(formData.shared_attendees),
      mandatory_attendees: formData.mandatory_attendees
        ? formData.mandatory_attendees.map((m) => m.id)
        : [],
      time_zone: formData.time_zone.value
        ? formData.time_zone.value
        : formData.time_zone,
    //   from:
    //     +formData.from.split(":")[0] > 12
    //       ? `${formData.from.split(":")[0] % 12}`.length === 1
    //         ? `0${formData.from.split(":")[0] % 12}:${
    //             formData.from.split(":")[1]
    //           }`
    //         : `${formData.from.split(":")[0] % 12}:${
    //             formData.from.split(":")[1]
    //           }`
    //       : formData.from,
    //   to:
    //     +formData.to.split(":")[0] > 12
    //       ? `${formData.to.split(":")[0] % 12}`.length === 1
    //         ? `0${formData.to.split(":")[0] % 12}:${formData.to.split(":")[1]}`
    //         : `${formData.to.split(":")[0] % 12}:${formData.to.split(":")[1]}`
    //       : formData.to,
    };
    dispatch(updateRound(roundId, preparedData));
  };

  if (redirect) {
    history.push("/rounds");
  }

  return (
    <RoundForm
      round={round}
      onSubmit={onSubmit}
      formTitle={i18next.t("Edit Round")}
      spokes={spokes || []}
      specialties={specialties || []}
      units={units || []}
      organizers={doctors.data || []}
      consultants={consultants || []}
      handleSpokeChange={handleSpokeChange}
      handleSpecialtyChange={handleSpecialtyChange}
    />
  );
}

export default EditRound;
