import { Api, getTokenHeader } from "services/Api";
import setQueryString from "helpers/helpers";
import {
  LIST_DOCTORS,
  CLEAR_DOCTORS_LIST,
  DOCTOR_LOGGED_IN,
  DOCTOR_LOGGED_OUT,
  LIST_CONSULTANT_DOCTORS,
} from "./types";

export const fetchDoctors = (filters: Object = {}): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/doctors/list?${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: LIST_DOCTORS, payload: response.data });
};

export const fetchConsDoctors = (
  filters: Object = {},
  index: string
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/doctors/list?${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  const payload = { ...response.data, index };

  dispatch({ type: LIST_CONSULTANT_DOCTORS, payload });
};
export const clearDoctorsList = () => async (dispatch) => {
  dispatch({ type: CLEAR_DOCTORS_LIST, payload: [] });
};

export const doctorsLoggedIn = (doctor: Object) => async (dispatch) => {
  dispatch({ type: DOCTOR_LOGGED_IN, payload: doctor });
};

export const doctorsLoggedOut = (doctor: Object) => async (dispatch) => {
  dispatch({ type: DOCTOR_LOGGED_OUT, payload: doctor });
};
