import React, { useState } from "react";
import { useForm } from "react-hook-form";
import i18next from "i18next";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CTextarea,
  CCard,
  CCardHeader,
  CCardBody,
  CAlert,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { updateRecommendations } from "actions/calls/calls";

const Recommendations = ({ appointment }) => {
  const [alertVisible, setAlertVisible] = useState(false);
  const dispatch = useDispatch();

  const defaultValues = { recommendations: appointment.recommendations };
  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const onSubmit = async (formData) => {
    try {
      await dispatch(updateRecommendations(appointment.id, formData)); // Await the dispatch function if it's a promise
      setAlertVisible(true); // Show the alert on successful submission
      reset(formData); // Reset the form to the submitted values
      setTimeout(() => {
        setAlertVisible(false);
      }, 5000);
    } catch (error) {
      // Handle error if needed
      console.error("Update failed", error);
    }
  };

  return (
    <CCard>
      {alertVisible && (
        <CAlert color="success" dismissible>
          {i18next.t("Saved Successfully")}
        </CAlert>
      )}
      <CCardHeader row>
        <CCol md="8">
          <strong>{i18next.t("recommendations")}</strong>
        </CCol>
      </CCardHeader>
      <CCardBody>
        <CForm onSubmit={handleSubmit(onSubmit)}>
          <CFormGroup row>
            <CCol md="12">
              <CLabel>{i18next.t("recommendations")}: </CLabel>
              <CTextarea
                name="recommendations"
                id="recommendations"
                rows="9"
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="12">
              <CButton type="submit" size="sm" color="primary">
                Save
              </CButton>
            </CCol>
          </CFormGroup>
        </CForm>
      </CCardBody>
    </CCard>
  );
};

export default Recommendations;
