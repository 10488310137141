import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import i18next from "i18next";
import Select from "react-select";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CSelect,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CInput,
} from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import SurveyGroupsSection from "./SurveyGroupsSection";
import { useHistory } from "react-router-dom";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import { customStyles } from "views/rounds/form/RoundFormValidation";

const renderTypes = (types) => {
  const keys = Object.keys(types);
  return keys.map((item) => (
    <option value={item} key={item}>
      {item}
    </option>
  ));
};

const SpecialtiesForm = ({
  onSubmit,
  specialtyForm,
  label,
  specialties,
  types,
  hospitals,
  restricted,
  parent_id,
}) => {
  const { register, setValue, handleSubmit, control, reset, watch, errors } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (specialtyForm) {
      reset({ ...specialtyForm, parent_id });
    }
  }, [specialtyForm]);

  const history = useHistory();
  const Validation = getValidation(watch);

  const onCancel = () => {
    history.push("/specialties-forms");
  };
  const ref = useRef(null);
  const saveDraft = () => {
    setValue("draft", 2);
    const buttonRef = ref.current && ref.current.children[0];
    buttonRef.click()
  }

  return (
    <CRow>
      <CCol xs="12">
        <CForm
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          className="form-horizontal"
        >
          <CInput type="hidden" name="parent_id" innerRef={register} />
          <CCard>
            <CCardHeader>
              <h4 className="header-label-title">{label}</h4>
            </CCardHeader>
            <CCardBody>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CLabel className="labels required" htmlFor="title">
                    {i18next.t("Title")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "title",
                      "title",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.require)}
                    type="text"
                    placeholder={i18next.t("Write Survey Title")}
                    name="title"
                    id="title"
                  />
                </CCol>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CLabel className="labels required" htmlFor="hospital">
                    {i18next.t("Location")}
                  </CLabel>
                  <Controller
                    control={control}
                    id="hospital"
                    name="hospital"
                    cacheOptions
                    rules={Validation.require}
                    styles={customStyles(!errors.hospital)}
                    options={hospitals}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={specialtyForm ? specialtyForm.hospital : {}}
                    as={Select}
                  />
                </CCol>
              </CFormGroup>
              <fieldset disabled={restricted}>
                <CFormGroup row>
                  <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CLabel htmlFor="specialty">{i18next.t("Type")}</CLabel>
                    <CSelect custom name="type" id="type" innerRef={register}>
                      {renderTypes(types)}
                    </CSelect>
                  </CCol>
                  <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CLabel htmlFor="specialty">{i18next.t("Specialty")}</CLabel>
                    <CSelect
                      custom
                      name="specialty_id"
                      id="specialty_id"
                      innerRef={register}
                    >
                      {RenderSelectOptions(specialties)}
                    </CSelect>
                  </CCol>
                </CFormGroup>
                <CFormGroup
                  row
                  variant="checkbox"
                  className="checkbox"
                  style={{ paddingLeft: "5%" }}
                >
                  <SurveyGroupsSection
                    control={control}
                    register={register}
                  />
                </CFormGroup>
              </fieldset>
            </CCardBody>
            <CCardFooter>
              <CFormGroup row>
                <CCol xs={4} sm={4} md={4} lg={4} xl={4}>
                  <CInput type="hidden" innerRef={register} name="draft" />
                  <CButton type="button" onClick={saveDraft} color="info" className="mx-1 btn-form">
                    {i18next.t("Save Draft")}
                  </CButton>
                </CCol>
                <CCol xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div ref={ref}>
                    <CButton
                      type="submit"
                      color="primary"
                      className="mx-1 btn-form"
                    >
                      {i18next.t("Submit")}
                    </CButton>
                  </div>
                </CCol>
                <CCol xs={4} sm={4} md={4} lg={4} xl={4}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  );
};

export default SpecialtiesForm;
