import { Api, getTokenHeader } from "services/Api";
import {
  CREATE_SENT_SURVEY,
  FETCH_SENT_SURVEYS,
  FETCH_SENT_SURVEY,
  UPDATE_SENT_SURVEY,
  DELETE_SENT_SURVEY,
} from "./types";

export const fetchSentSurveys = (
  page: string,
  limit: string,
  survey_id: String,
  specialty_id: string,
  country: string,
  city: string,
  location_id: string,
  gender: string,
  age_from: string,
  age_to: string
): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/surveys/sent`, {
    ...getTokenHeader(),
    params: {
      location_id,
      specialty_id,
      survey_id,
      country,
      city,
      gender,
      age_from,
      age_to,
      page,
      limit,
    },
  });
  dispatch({ type: FETCH_SENT_SURVEYS, payload: response.data });
};

export const fetchSentSurvey = (id: string) => async (dispatch) => {
  const response = await Api.get(`/api/admin/surveys/sent/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_SENT_SURVEY, payload: response.data });
};

export const createSentSurvey = (formData: Object): Object => async (
  dispatch
) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.post(`/api/admin/surveys/sent${draft}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_SENT_SURVEY, payload: response.data });
};

export const updateSentSurvey = (
  formData: Object,
  id: string
): Object => async (dispatch) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.put(`/api/admin/surveys/sent/${id}${draft}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_SENT_SURVEY, payload: response.data });
};

export const deleteSentSurvey = (id: string) => async (dispatch) => {
  await Api.delete(`/api/admin/surveys/sent/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_SENT_SURVEY, payload: id });
};
