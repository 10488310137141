import React from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CTextarea,
} from "@coreui/react";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

const SpecialtyForm = ({ onSubmit, specialty, label }) => {
  let defaultValues = {};
  if (specialty) {
    defaultValues = specialty;
  }

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const Validation = getValidation(watch);
  const history = useHistory();

  const onCancel = () => {
    history.push("/specialties");
  };

  return (
    <CRow>
      <CCol xs="12">
        <CCard>
          <CForm onSubmit={handleSubmit(onSubmit)} method="post">
            <h4 className="header-label-title">{label}</h4>
            <CCardBody>
              <CFormGroup row className="mx-0">
                <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CLabel htmlFor="name" className="labels required">
                    {i18next.t("Name")}
                  </CLabel>
                  <CInput
                    className={`${getClassName("name", "name", watch, errors)}`}
                    type="text"
                    id="name"
                    name="name"
                    placeholder={i18next.t("Enter Specialty Name")}
                    innerRef={register(Validation.name)}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row className="mx-0">
                <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CLabel htmlFor="description" className="labels">
                    {i18next.t("Description")}
                  </CLabel>
                  <CTextarea
                    name="description"
                    id="description"
                    rows="9"
                    placeholder={i18next.t("Description")}
                    innerRef={register}
                  />
                </CCol>
              </CFormGroup>
            </CCardBody>
            <CCardFooter>
              <CFormGroup row>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    type="submit"
                    color="primary"
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Save")}
                  </CButton>
                </CCol>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default SpecialtyForm;
