import React from "react";
import i18next from "i18next";
import { CButton, CModal, CModalBody, CModalFooter } from "@coreui/react";
import { isObject, isArray } from "helpers/helpers";

const renderAnswerScore = (question, questionAnswer) => {

  // score and calculation
  if (question.sub_questions && question.sub_questions.length > 0) {
    return questionAnswer.answer_value
      ? `${i18next.t("Total")}: ${questionAnswer.answer_value}`
      : "-";
  }
  // don't show score at preview
  return false && questionAnswer.answer_value && !isNaN(+questionAnswer.answer_value)
    ? `${i18next.t("Score")}: ${questionAnswer.answer_value}`
    : "";
};

const renderAnswerBody = (answerBody) => {
  let ans = JSON.parse(answerBody)
    ? JSON.parse(answerBody)
    : i18next.t("No Available Data");

  if (isArray(ans)) {
    return ans.map((a) => {
      const obj = JSON.parse(a);
      return <tr>{obj.label ? obj.label : i18next.t("No Available Data")}</tr>;
    });
  }
  try {
    ans = JSON.parse(ans);
    return isObject(ans) ? ans.label : ans;
  } catch {
    return ans;
  }
};

const renderGroupQuestions = (questions) => {
  if (questions) {
    return questions.map((question) => {
      const questionAnswer = question.surveys_answers
        ? question.surveys_answers[0]
        : null;
      return (
        <>
          <tr>
            <td>
              <strong style={{ textTransform: "capitalize" }}>
                {question.question}
              </strong>
            </td>
            <td style={{ textTransform: "capitalize" }}>
              {questionAnswer
                ? renderAnswerBody(questionAnswer.answer_body)
                : i18next.t("No Available Data")}
            </td>
            <td style={{ textTransform: "capitalize" }}>
              {questionAnswer
                ? renderAnswerScore(question, questionAnswer)
                : i18next.t("No Available Data")}
            </td>
          </tr>
          {question.sub_questions.surveys_answers?.length
            ? renderGroupQuestions(question.sub_questions)
            : ""}
        </>
      );
    });
  }
  return <></>;
};

const renderSurveyGroups = (followUp) => {
  if (followUp && followUp.survey && followUp.survey.groups) {
    return followUp.survey.groups.map((group) => (
      <>
        <h3 style={{ textTransform: "capitalize", textAlign: "center" }}>
          {group.group_title || i18next.t("Question Group")}
        </h3>
        <table className="table table-striped table-hover">
          <tbody>
            {renderGroupQuestions(group.questions)}
          </tbody>
        </table>
        <hr/>
        <br></br>
      </>
    ));
  }
  return "";
};

const RenderFollowUp = (followUp, followUpId) => {
  if (followUp && followUp.id === followUpId) {
    return (
      <>
        <table className="table table-striped table-hover">
          <tbody>
            <tr>
              <td>
                <strong>{i18next.t("Author")}</strong>
              </td>
              <td>{`${followUp.creator.first_name} ${followUp.creator.last_name}`}</td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Specialty")}</strong>
              </td>
              <td>
                {followUp.specialty
                  ? followUp.specialty.name
                  : i18next.t("No Specific Specialty")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{i18next.t("Note")}</strong>
              </td>
              <td>{followUp.note ? followUp.note : i18next.t("No Additional Notes")}</td>
            </tr>
          </tbody>
        </table>
        <br></br>
        {followUp && followUp.survey && followUp.survey.groups ? 
          <>
            <h4 className="table-title">{i18next.t("Survey Details")}</h4>
            <>{renderSurveyGroups(followUp)}</>
          </>
        :
          ""
        }
      </>
    );
  }
  return <></>;
};

const Show = ({ followUp, followUpId, showModal, toggleShowModal }) =>
  showModal ? (
    <CModal show={showModal} size="lg" onClose={toggleShowModal}>
      <h4 className="table-title" style={{ textTransform: "capitalize" }}>{followUp?.survey?.title}</h4>
      <CModalBody>{RenderFollowUp(followUp, followUpId)}</CModalBody>
      <CModalFooter>
        <CButton type="reset" color="danger" onClick={toggleShowModal}>
          {i18next.t("Cancel")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    <></>
  );
export default Show;