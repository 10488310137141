import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CCol, CRow } from "@coreui/react";
import i18next from "i18next";
import QuestionChartComponent from "views/charts/QuestionsChartComponent";

const SurveyStatistics = () => {

	return (
		<>

			<CRow className="justify-content-between align-items-center my-1">
				<CCol md="6">
					<div className="float-left table-label-title">{i18next.t("Survey Statistics")}</div>
				</CCol>
			</CRow>
			<CRow>
				<QuestionChartComponent />
			</CRow>

		</>
	);
};

export default SurveyStatistics;