import React, {useRef} from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CSelect,
  CTextarea,
  CInput,
} from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import i18next from "i18next";
import { getClassName } from "views/pages/register/RegisterFormValidation";
import getValidation from "views/clinics/form/ClinicFormValidation";

const PublicSurveyForm = ({
  onSubmit,
  onCancel,
  matchedSurvey,
  FormTitle
  
}) => {
  let defaultValues = {};
  const { register, handleSubmit, setValue, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const Validation = getValidation(watch);
  const ref = useRef(null);
  const saveDraft = () => {
    setValue("draft", 2);
    const buttonRef = ref.current && ref.current.children[0];
    buttonRef.click()
  }

  

  return  (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
        <h4 className="header-label-title">{FormTitle}</h4>
        <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CLabel>{i18next.t("Note")}</CLabel>
              <CTextarea
                name="note"
                id="note"
                rows="5"
                placeholder={i18next.t("Note")}
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
          <SurveyForm
            register={register}
            setValue={setValue}
            watch={watch}
            groups={matchedSurvey ? matchedSurvey.groups : []}
          />
        <CModalFooter>
          <CInput type="hidden" innerRef={register} name="draft" />
          <CButton type="button" onClick={saveDraft} color="info">
              {i18next.t("Save Draft")}
          </CButton>
          <div ref={ref}>
            <CButton type="submit" color="primary" >
              {i18next.t("Submit")}
            </CButton>
          </div>
          <CButton type="reset" color="danger" onClick={onCancel}>
              {i18next.t("Cancel")}
            
          </CButton>
        </CModalFooter>
    </CForm>
  );
};
export default PublicSurveyForm;
