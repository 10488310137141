import React from "react";
import {
  CCard,
  CCardBody,
  CRow,
  CCol,
  CNav,
  CNavItem,
  CTabPane,
  CNavLink,
  CTabContent,
  CTabs,
  CDataTable,
  CContainer,
} from "@coreui/react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faIdCardClip,
  faChartColumn,
} from "@fortawesome/free-solid-svg-icons";
import QuestionChartComponent from "views/charts/QuestionsChartComponent";
import i18next from "i18next";

const ConsultationsSection = ({ children, showRoundData = true }) => {
  const consultations = useSelector(
    (state) => state.episodeUtilities.consultations
  );
  const episode = useSelector((state) => state.episodes.episode);
  const fields = [
    { key: "round_title", label: i18next.t("Round Title") },
    { key: "consultant_name", label: i18next.t("Consultant Name") },
    { key: "note", label: i18next.t("Note") },
    { key: "date", label: i18next.t("date") },
  ];

  return (
    <CCard className="border-0 w-100">
      <CCardBody className="text-dark p-0">
        <CContainer fluid>
          <CRow>
            <CTabs activeTab="follow-ups">
              <CCol md={3} xs={12} className="px-0 sidebar-tabs-wrap">
                <CNav variant="tabs" className="flex-column">
                  <CNavItem>
                    <CNavLink
                      className="text-body font-weight-bold p-3 d-flex align-items-center"
                      data-tab="follow-ups"
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="mr-2 episode-icon-desktop"
                      />
                      <span className="d-none d-md-inline">
                        {i18next.t("follow ups")}
                      </span>
                    </CNavLink>
                  </CNavItem>

                  {showRoundData && (
                    <CNavItem>
                      <CNavLink
                        className="text-body font-weight-bold p-3 d-flex align-items-center"
                        data-tab="round-consultations"
                      >
                        <FontAwesomeIcon
                          icon={faIdCardClip}
                          className="mr-2 episode-icon-desktop"
                        />
                        <span className="d-none d-md-inline">
                          {i18next.t("Round Consultations")}
                        </span>
                      </CNavLink>
                    </CNavItem>
                  )}

                  <CNavItem>
                    <CNavLink
                      className="text-body font-weight-bold p-3 d-flex align-items-center"
                      data-tab="survey-statistics"
                    >
                      <FontAwesomeIcon
                        icon={faChartColumn}
                        className="mr-2 episode-icon-desktop"
                      />
                      <span className="d-none d-md-inline">
                        {i18next.t("Survey Statistics")}
                      </span>
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CCol>

              <CCol md={9} xs={12} className="p-0">
                <CCard className="border-0">
                  <CTabContent>
                    <CTabPane data-tab="follow-ups">{children}</CTabPane>

                    {showRoundData && (
                      <CTabPane data-tab="round-consultations">
                        <CCardBody>
                          <CDataTable
                            items={consultations}
                            noItemsViewSlot={i18next.t("No Available Items")}
                            fields={fields}
                            striped
                          />
                        </CCardBody>
                      </CTabPane>
                    )}

                    <CTabPane data-tab="survey-statistics">
                      <QuestionChartComponent patientId={episode.patient_id} />
                    </CTabPane>
                  </CTabContent>
                </CCard>
              </CCol>
            </CTabs>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  );
};

export default ConsultationsSection;
