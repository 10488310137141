import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
} from "@coreui/react";
import {
  fetchReport,
  generateReport,
  fetchSchema,
} from "actions/reports/reports";
import ReportFilters from "../partials/ReportFilters";

const Report = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const generatedReport = useSelector((state) => state.reports.reportGenerate);
  const report = useSelector((state) => state.reports.report);
  const schema = useSelector((state) => state.report.schema);
  const [title, setTitle] = useState();

  useEffect(() => {
    if (id) {
      dispatch(fetchSchema());
      dispatch(generateReport(id));
      dispatch(fetchReport(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (report) {
      setTitle(report.name);
    }
  }, [report]);

  const onFiltersSubmit = (filterData) => {

    const urlParams = Object.entries(filterData)
      .map(([key, value]) => {
        if (value !== undefined && value !== "") {
          if (typeof value === 'object' && value !== null && 'id' in value) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value.id)}`;
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      }
      )
      .filter(Boolean)
      .join("&");

    dispatch(generateReport(id, urlParams));
  };
  const reportItems = generatedReport ? generatedReport : [];
  const fields =
    generatedReport && generatedReport[0]
      ? Object.keys(generatedReport[0])
      : [];

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <h4 className="table-title">{`Report : ${title}`}</h4>
            <CCardHeader>
              <CRow>
                <ReportFilters
                  report={report}
                  schema={schema}
                  id={id}
                  onFiltersSubmit={onFiltersSubmit}
                />
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={reportItems}
                fields={fields}
                noItemsViewSlot="No Available Items"
                scopedSlots
                hover
                striped
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Report;
