import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CForm,
  CLabel,
  CInput,
  CButton,
  CFormGroup,
} from "@coreui/react";
import { FilterAsyncSelect } from "./FilterAsyncSelect";

const ReportFilters = ({ schema, report, onFiltersSubmit }) => {
  const { register, handleSubmit, watch, control } = useForm({
    mode: "onBlur",
  });

  const [conditions, setConditions] = useState([]);

  useEffect(() => {
    if (report) {
      const { query } = report;

      if (query && typeof query === "string") {
        const queryJson = JSON.parse(query);
        setConditions(queryJson.conditions);
      }
    }
  }, [report]);
  const getColumnLabel = (columnName) => {
    const filteredColumns = schema.columns.filter(
      (column) => column.name == columnName
    )[0];
    return filteredColumns.label;
  };

  const renderDateFilters = (columnName) => (
    <>
      <CCol xs={12} sm={12} md={5} lg={5} xl={5}>
        <CLabel htmlFor="date_from" className="labels">
          {getColumnLabel(columnName)} From
        </CLabel>
        <CInput
          innerRef={register}
          type="date"
          id="date_from"
          name={columnName}
          max={new Date().toISOString().substr(0, 10)}
        />
      </CCol>
      <CCol xs={12} sm={12} md={5} lg={5} xl={5}>
        <CLabel htmlFor="date_to" className="labels">
          {getColumnLabel(columnName)} To
        </CLabel>
        <CInput
          innerRef={register}
          type="date"
          id="date_to"
          name={`${columnName}_value2`}
          defaultValue={watch("date_from")}
          min={watch("date_from")}
        />
      </CCol>
    </>
  );
  const renderSubmitButton = () => (
    <CCol xs={12} sm={12} md={2} lg={2} xl={2} style={{ "margin-top": "2em" }}>
      <CButton type="submit" color="primary">
        Filter
      </CButton>
    </CCol>
  );

  const renderReportFilters = () => {
    return (
      <div>
        {conditions?.map((condition) => {
          const column = schema?.columns?.find(
            (col) => col.name === condition.column
          );
          const columnType = column?.type;

          switch (columnType) {
            case "date":
              return (
                <CFormGroup row key={condition.column}>
                  {renderDateFilters(condition.column)}
                </CFormGroup>
              );

            case "string": {
              return (
                <CFormGroup row key={condition.column}>
                  <CCol xs={12} sm={12} md={5} lg={5} xl={5}>
                    <CLabel htmlFor={condition.column}>
                      {getColumnLabel(condition.column)}
                    </CLabel>
                    <CInput
                      type="text"
                      id={condition.column}
                      name={condition.column}
                      placeholder={getColumnLabel(condition.column)}
                      innerRef={register}
                    />
                  </CCol>
                </CFormGroup>
              );
            }

            case "Number": {
              return (
                <CFormGroup row key={condition.column}>

                <CCol xs={12} sm={12} md={5} lg={5} xl={5}>
                  <CLabel htmlFor={condition.column}>
                    {getColumnLabel(condition.column)}
                  </CLabel>
              <FilterAsyncSelect
              schema={schema}
              control={control}
              register={register}
              conditionColumn={condition.column}
              name={condition.column}
              id ={condition.column}
              defaultValue={null}
              isRequired={false}
            />
            </CCol></CFormGroup>
              )

            }
            default:
              return null;
          }
        })}
        {conditions.length > 0 && renderSubmitButton()}
      </div>
    );
  };

  return (
    <CForm
      onSubmit={handleSubmit(onFiltersSubmit)}
      style={{ width: "100%", "padding-left": "2em" }}
    >

      {renderReportFilters()}
    </CForm>
  );
};

export default ReportFilters;
