import React from "react";
import i18next from "i18next";
import { CButton, CDataTable } from "@coreui/react";

/**
 * @param props
 * @param props.subQuestions
 * @param props.questions
 * @param props.editSubQuestion
 * @param props.deleteSubQuestion
 * @param props.setIsEditing
 */
const SubQuestions = ({
  questions,
  editSubQuestion,
  deleteSubQuestion,
  setIsEditing,
}) =>
  questions.length == 0 ? (
    <></>
  ) : (
    <CDataTable
      items={questions}
      fields={[
      { key: "question", label:  i18next.t("question") },
      { key: "actions", label:  i18next.t("Actions") },
    ]}
      hover
      noItemsViewSlot={i18next.t("No Available Items")}
      striped
      scopedSlots={{
        actions: (item) => (
          <td>
            <CButton
              onClick={() => {
                editSubQuestion(item);
                setIsEditing(true);
              }}
              style={{
                marginRight: "5px",
                "background-color": "rgb(48, 197, 182)",
                "border-color": "rgb(48, 197, 182)",
                color: "white",
                width: "7em",
              }}
            >
              {i18next.t("Edit")}
            </CButton>
            <CButton onClick={() => deleteSubQuestion(item)} color="danger">
              {i18next.t("Delete")}
            </CButton>
          </td>
        ),
      }}
    />
  );

export default SubQuestions;
