import React from "react";
import {
  CCol,
  CCard,
  CCardBody,
  CRow,
  CNav,
  CNavItem,
  CTabPane,
  CNavLink,
  CTabContent,
  CTabs,
  CDataTable,
  CContainer,
} from "@coreui/react";
import { useSelector } from "react-redux";
import i18next from "i18next";
import {
  STATUS_GIVEN,
  STATUS_NOT_GIVEN,
  STATUS_GENERATED,
} from "constants/constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faHistory } from "@fortawesome/free-solid-svg-icons";
import ListPrescriptionExecution from "./ListPrescriptionExecution";

const PrescriptionExecution = () => {
  const prescriptionExecutions = useSelector(
    (state) => state.prescriptionExecutions
  );

  const { executionHistory, utilities } = prescriptionExecutions;
  const fields = [
    { key: "drug_name", label: i18next.t("Drug Name") },
    { key: "execution_time", label: i18next.t("Execution Time") },
    { key: "status", label: i18next.t("status") },
    { key: "author", label: i18next.t("Author") },
  ];

  const getStatusText = (status) => {
    switch (status) {
      case utilities.status_given:
        return STATUS_GIVEN;
      case utilities.status_not_given:
        return STATUS_NOT_GIVEN;
      default:
        return STATUS_GENERATED;
    }
  };

  return (
    <CCard className="border-0 w-100">
      <CCardBody className="text-dark p-0">
        <CContainer fluid>
          <CRow>
            <CTabs activeTab="today_executions">
              <CCol md={3} xs={12} className="px-0 sidebar-tabs-wrap">
                <CNav variant="tabs" className="flex-column">
                  <CNavItem>
                    <CNavLink
                      className="text-body font-weight-bold p-3 d-flex align-items-center"
                      data-tab="today_executions"
                    >
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        className="mr-2 episode-icon-desktop"
                      />
                      <span className="d-none d-md-inline">
                        {i18next.t("Today")}
                      </span>
                    </CNavLink>
                  </CNavItem>

                  <CNavItem>
                    <CNavLink
                      className="text-body font-weight-bold p-3 d-flex align-items-center"
                      data-tab="history"
                    >
                      <FontAwesomeIcon
                        icon={faHistory}
                        className="mr-2 episode-icon-desktop"
                      />
                      <span className="d-none d-md-inline">
                        {i18next.t("Executions History")}
                      </span>
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CCol>

              <CCol md={9} xs={12} className="p-0">
                <CTabContent>
                  <CTabPane data-tab="today_executions">
                    <ListPrescriptionExecution />
                  </CTabPane>
                  <CTabPane data-tab="history">
                    <CCard className="border-0">
                      <CCardBody>
                        <h3 className="header-label-title">
                          {i18next.t("list of executions history")}
                        </h3>
                        <CDataTable
                          items={executionHistory ? executionHistory.data : []}
                          fields={fields}
                          striped
                          noItemsViewSlot={i18next.t("No Available Items")}
                          scopedSlots={{
                            drug_name: (item) => <td>{item.drug_name}</td>,
                            status: (item) => (
                              <td>{getStatusText(item.status)}</td>
                            ),
                            author: (item) => (
                              <td>
                                {item.author
                                  ? item.author
                                  : i18next.t("No Author Identified")}
                              </td>
                            ),
                          }}
                        />
                      </CCardBody>
                    </CCard>
                  </CTabPane>
                </CTabContent>
              </CCol>
            </CTabs>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  );
};

export default PrescriptionExecution;
