import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updatePrescription, resetPrescription } from "actions/prescriptions/prescriptions";
import { TYPE_APPOINTMENT } from "constants/constants";
import PrescriptionForm from "./form/Form";
import i18next from "i18next";

const EditPrescription = ({
  toggle,
  modal,
  prescription,
  setIsEditing,
  getDrugOptions,
  type,
}) => {
  const lable = `${i18next.t("Edit Prescription")}`;
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.prescriptionUtilities);
  const userId = useSelector((state) => state.auth.jwt.user.id);

  const onSubmit = (formData) => {
    dispatch(
      updatePrescription(
        { ...formData, drug_id: formData.drug.id, editor_id: userId },
        prescription.id
      )
    );
    toggle();
    dispatch(resetPrescription());
    setIsEditing(false);
  };

  const onCancel = () => {
    toggle();
    setIsEditing(false);
    dispatch(resetPrescription());
  };
  return (
    <PrescriptionForm
      utilities={utilities}
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal}
      prescription={prescription}
      lable={lable}
      getDrugOptions={getDrugOptions}
      canConfirmPrescription={
        type === TYPE_APPOINTMENT || utilities.can_confirm_prescription
      }
      type={type}
    />
  );
};
export default EditPrescription;
