import React from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
} from "@coreui/react";

const AppointmentDetails = ({ appointment }) => (
  <CCard>
    <CCardBody>
      <CRow>
        <CCol xs="9" md="8">
          <CRow>
            <CCol xs="12">
              <CCardTitle>{appointment.doctor}</CCardTitle>
            </CCol>
            <CCol xs="6">
              <CCardSubtitle>{appointment.specialty}</CCardSubtitle>
            </CCol>
            <CCol xs="6">
              <CCardSubtitle>{appointment.clinic_type}</CCardSubtitle>
            </CCol>
            <br />
          </CRow>
        </CCol>
      </CRow>
      <br />
      <CRow>
        <CCol xs="4" />
        <CCol xs="6">
          <CCardSubtitle className="bg-dark py-3 text-center font-weight-bold">
            {appointment.schedule_time}
          </CCardSubtitle>
        </CCol>
      </CRow>
      <br />
      <CRow>
        <CCol xs="4" />
        <CCol xs="6">
          <CCard>
            <CCardBody>
              <CCardSubtitle>{appointment.price}</CCardSubtitle>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
);

export default AppointmentDetails;
