import React, { useEffect, useState } from "react";
import i18next from "i18next";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CRow,
  CCol,
  CCarousel,
  CCarouselIndicators,
  CCarouselInner,
  CCarouselItem,
  CCarouselCaption,
  CCarouselControl,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Api, getTokenHeader } from "services/Api";

const MediaViewerModal = ({ medialList, showModal, onCloseModal }) => {
  const [mediaImages, setMediaImages] = useState([]);
  const [dicomMediaList, setDicomMediaList] = useState([]);
  const [dicomViewerSwitch, setDicomViewerSwitch] = useState(false);
  const darkStyle = {color:"black", filter:'invert(1) grayscale(100)'};
  useEffect(() => {
    const slides = medialList.filter(
      (item) => !item.mime_type.includes("dicom")
    );
    const dicomMedia = medialList.filter((item) =>
      item.mime_type.includes("dicom")
    );
    setDicomMediaList(dicomMedia);

    const viewMedia = async (id) => {
      let response;
      try {
        response = await Api.get(`/api/admin/investigation/media/${id}`, {
          ...getTokenHeader(),
          responseType: "blob",
        });
      } catch (err) {
        return null;
      }
      return URL.createObjectURL(response.data);
    };

    const getMedia = async () => {
      const mediaArray = [];
      slides.map(async (slide) => {
        mediaArray.push({'blob': await viewMedia(slide.id), 'note':slide.execution_notes});
        setMediaImages(mediaArray);
      });
    };
    getMedia();
  }, [medialList]);

  const renderSlides = () => {
    if (mediaImages.length) {
      return mediaImages.map((item, index) => (
        <CCarouselItem key={`key-${index}`}>
          <embed className="d-block w-100" src={item.blob} alt="slide 1" height="500 px"/>
          <CCarouselCaption style={{color:"black", position: "initial", darkStyle}}>
            <h3 style={{ color: "blue" }}>{item.note}</h3>
            <p style={{ color: "blue" }}>{`image ${index + 1} of ${
              mediaImages.length
            }`}</p>
          </CCarouselCaption>
        </CCarouselItem>
      ));
    }
    return (
      <h4 className="text-align-center">
        <strong>{i18next.t("No Media Available")}</strong>
      </h4>
    );
  };

  const renderDicoms = () => {
    const dicomMediaIds = dicomMediaList.map((item) => item.id);
    return (
      <CCol sm={12}>
        <div className="embed-responsive embed-responsive-16by9">
          <h3>{`${process.env.REACT_APP_OHIF_URL}/?mediaUrl=${
            process.env.REACT_APP_SERVICE_URL
          }&mediaIds=${JSON.stringify(dicomMediaIds)}`}
          </h3>
          <iframe
            title="Dicom Viewer"
            className="embed-responsive-item"
            src={`${process.env.REACT_APP_OHIF_URL}/?mediaUrl=${
              process.env.REACT_APP_SERVICE_URL
            }&mediaIds=${JSON.stringify(dicomMediaIds)}`}
          />
        </div>
      </CCol>
    );
  };

  const renderModalBody = () => {
    if (dicomViewerSwitch) {
      return renderDicoms();
    }
    return (
      <CCol sm={12}>
        <CCarousel activeIndex={0} controls className="carousel slide carousel-dark">
          <CCarouselIndicators style={darkStyle} />
          <CCarouselInner style={{width:'70%', right:'15%', left:'15%'}}>{renderSlides()}</CCarouselInner>
          <CCarouselControl direction="prev" style={darkStyle} />
          <CCarouselControl direction="next" style={darkStyle} />
        </CCarousel>
      </CCol>
    );
  };
  const renderSwitchViewerBtns = () => {
    if (dicomMediaList.length) {
      return (
        <CButton
          color="warning"
          onClick={() => setDicomViewerSwitch(!dicomViewerSwitch)}
        >
          {dicomViewerSwitch ? "Normal Media Viewer" : "Dicom Media Viewer"}
        </CButton>
      );
    }
    return "No DICOM images available.";
  };

  return showModal ? (
    <CModal show={showModal} onClose={onCloseModal} size="lg">
      <CModalHeader>Media View</CModalHeader>
      <CModalBody>
        <CRow>
          {renderSwitchViewerBtns()}
          {renderModalBody()}
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton type="reset" size="sm" color="danger" onClick={onCloseModal}>
          {i18next.t("Close")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    <></>
  );
};
export default MediaViewerModal;
