import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import { CCard, CCardBody } from "@coreui/react";
import QuestionForm from "views/surveys/questions/components/QuestionForm";
import { createQuestion } from "actions/surveys/questions/questions";
import { COMPOSITE } from "constants/questions";

/**
 * @param props
 */
function CreateQuestion() {
  const history = useHistory();
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.questions.redirect);
  const subQuestions = useSelector((state) => state.questions.subQuestions);
  const label = i18next.t("Create New Question");
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      subQuestions,
      category_id: 1,
    };
    dispatch(createQuestion(data));
  };

  if (redirect) {
    history.push("/questions");
  }

  return (
    <CCard>
      <CCardBody>
        <QuestionForm onSubmit={onSubmit} label={label} />
      </CCardBody>
    </CCard>
  );
}

export default CreateQuestion;
