import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import { CCol, CLabel } from "@coreui/react";
import { Api, getTokenHeader } from "services/Api";
import SuperAdminFigures from "./SuperAdminFigures";
import NurseFigures from "./NurseFigures";
import LabFigures from "./LabFigures";
import DoctorFigures from "./DoctorFigures";
import OldHome from "./OldHome";
import { getUserDashboard } from "actions/users/users";

const getSpecialtyOptions = async (inputValue) => {
  const url = `/api/admin/statistics/specialties/list?q=${inputValue}`;
  const options = await Api.get(url, getTokenHeader()).then((response) => {
    console.log(response.data);
    return response.data.data;
  });
  return options || [];
};

const Home = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.users.dashboard);
  const [specialty, setSpecialty] = useState();

  useEffect(() => {
    dispatch(getUserDashboard(specialty? specialty.id: 0));
  }, [specialty]);

  return (
    <>
      {dashboard && dashboard.name === "SUPER_ADMIN" && <SuperAdminFigures dashboard={dashboard} />}
      {dashboard && dashboard.name === "NURSE" && <>
        <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
          <CCol sm={12} md={6} xs={12} lg={6} xl={12}>
            <CLabel className="labels required">Specialty</CLabel>
            <AsyncSelect
              id={'id'}
              name={'name'}
              cacheOptions
              loadOptions={getSpecialtyOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={specialty}
              onChange={(s) => setSpecialty(s)}
            />
          </CCol>
        </CCol>
        <NurseFigures dashboard={dashboard} />
      </>}
      {dashboard && dashboard.name === "LAB_OR_RADIOLOGY" && <LabFigures dashboard={dashboard} />}
      {dashboard && dashboard.name === "DOCTOR" && <DoctorFigures dashboard={dashboard} />}
      {(!dashboard || !dashboard.name) && <OldHome />}
    </>
  );
};

export default Home;
