import { Api, getTokenHeader } from "services/Api";
import {
  PATIENT_CLINIC_SEARCH,
  FETCH_PATIENT_APPOINTMENTS,
  PATIENT_CLINIC_SLOTS,
  PATIENT_APPOINTMENT_SELECTION,
  RESET_PATIENT_APPOINTMENT_SELECTION,
  PATIENT_APPOINTMENT_LOBBY_CHECK,
  PATIENT_FETCH_ATTACHMENTS,
  PATIENT_FETCH_ATTACHMENT,
  PATIENT_CREATE_ATTACHMENT,
  PATIENT_DELETE_ATTACHMENT,
  PATIENT_UPDATE_ATTACHMENT,
  PATIENT_LOAD_ROOM_DATA,
  PATIENT_APPOINTMENT_RESET_DATA,
  PATIENT_FETCH_APPOINTMENT,
  FETCH_APPOINTMENT_PRICE,
  PATIENT_FETCH_APPOINTMENT_RECOMMENDATIONS,
	PATIENT_TIME_LINE,
} from "./types";

export const fetchPatientAppointments = (): Object => async (disptach) => {
  const response = await Api.get(`/api/patient/appointments`, getTokenHeader());
  disptach({ type: FETCH_PATIENT_APPOINTMENTS, payload: response.data });
};

export const clinicSearch = (searchData): Object => async (disptach) => {
  const response = await Api.get(
    `/api/patient/search-clinics?type=${searchData.type}&from=${searchData.from}&to=${searchData.to}&specialty_id=${searchData.specialty_id}&country_id=${searchData.country}`,
    getTokenHeader()
  );
  disptach({ type: PATIENT_CLINIC_SEARCH, payload: response.data });
};

export const patientFetchClinicSlots = (clinicId, date): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/patient/appointments/clinic-slots/${clinicId}/${date}`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_CLINIC_SLOTS, payload: response });
};

export const getAppointmentPrice = (clinicId, date): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/patient/appointments/price/${clinicId}/${date}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_APPOINTMENT_PRICE, payload: response.data });
};

export const patientCreateAppointment = (formValues: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/patient/appointments`,
    formValues,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_APPOINTMENT_SELECTION, payload: response.data });
};

export const patientAppointmentSuccessPayment = (appointmentId) => async () => {
  await Api.get(
    `api/patient/update-payment/appointments/${appointmentId}`,
    getTokenHeader()
  );
};

export const patientAddComplain = (appointmentId, formValues) => async (
  dispatch
) => {
  await Api.post(
    `api/patient/appointments/${appointmentId}/complaint`,
    formValues,
    getTokenHeader()
  );
  dispatch({
    type: PATIENT_APPOINTMENT_RESET_DATA,
    payload: {},
  });
};

export const patientAddComplainAttachment = (
  appointmentId,
  formValues
) => async (dispatch) => {
  const response = await Api.post(
    `api/patient/appointments/${appointmentId}/complaint/save-attachment`,
    formValues,
    getTokenHeader()
  );
  if (response.data.status) {
    dispatch({ type: PATIENT_CREATE_ATTACHMENT, payload: response.data.data });
  }
};

export const patientFetchAttachments = (appointmentId): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `api/patient/appointments/${appointmentId}/complaint/attachments`,
    getTokenHeader()
  );
  console.log("fff",response)
  if (response.data.length > 0)
    dispatch({ type: PATIENT_FETCH_ATTACHMENTS, payload: response.data });
};

export const patientFetchAttachment = (attachmentId): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `api/patient/appointments/complaint/attachments/${attachmentId}`,
    getTokenHeader()
  );
  if (response.data.length > 0)
    dispatch({ type: PATIENT_FETCH_ATTACHMENT, payload: response.data });
};

export const patientDeleteAttachment = (attachmentId) => async (dispatch) => {
  await Api.delete(
    `api/patient/appointments/complaint/attachments/${attachmentId}`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_DELETE_ATTACHMENT, payload: attachmentId });
};

export const patientUpdateComplainAttachment = (
  attachmentId,
  formValues
) => async (dispatch) => {
  const response = await Api.post(
    `api/patient/appointments/complaint/attachments/${attachmentId}`,
    formValues,
    getTokenHeader()
  );
  // console.log('put response', response.data);
  dispatch({ type: PATIENT_UPDATE_ATTACHMENT, payload: response.data });
};

export const checkPatientAppointmentLobby = (appointmentId): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/clinics/check-start/${appointmentId}`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_APPOINTMENT_LOBBY_CHECK, payload: response.data });
};
export const patientAppointmentLobbyInterval = (lobbySession): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/patient/room/order/${lobbySession}`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_APPOINTMENT_LOBBY_CHECK, payload: response.data });
};

export const loadLobbyData = (lobbySession): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/patient/lobby/data/${lobbySession}`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_APPOINTMENT_LOBBY_CHECK, payload: response.data });
};

export const joinTheLobby = (lobbySession) => async () => {
  await Api.get(`/api/patient/lobby/join/${lobbySession}`, getTokenHeader());
};

export const loadRoomData = (lobbySession): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/patient/room/data/${lobbySession}`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_LOAD_ROOM_DATA, payload: response.data });
};

export const patinetSubmitRate = (formData) => () => {
  Api.post("/api/patient/rate/appointment", formData, getTokenHeader());
};

export const fetchAppointment = (id): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/patient/appointments/${id}`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_FETCH_APPOINTMENT, payload: response.data });
};

export const fetchAppointmentByCode = (code): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/patient/appointments/code/${code}`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_FETCH_APPOINTMENT, payload: response.data });
};

export const fetchAppointmentRecommendations = (id): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/patient/appointments/${id}/recommendations`,
    getTokenHeader()
  );
  dispatch({ type: PATIENT_FETCH_APPOINTMENT_RECOMMENDATIONS, payload: response.data });
};

export const patientCancelAppointments = (id): Object => async (disptach) => {
  const response = await Api.get(
    `/api/patient/appointments/${id}/cancel`,
    getTokenHeader()
  );
  disptach({ type: FETCH_PATIENT_APPOINTMENTS, payload: response.data });
};

export const resetPatientAppointmentSelection = (): Object => async (
  dispatch
) => {
  dispatch({ type: RESET_PATIENT_APPOINTMENT_SELECTION, payload:{}});
};

export const fetchPatientTimeLine = (): Object => async (disptach) => {
  const response = await Api.get(`/api/patient/time-line`, getTokenHeader());
  disptach({ type: PATIENT_TIME_LINE, payload: response.data });
};
