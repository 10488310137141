import React, {useRef, useEffect} from "react";
import { useForm } from "react-hook-form";
import {
  CFormGroup,
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CCol,
  CLabel,
  CTextarea,
  CInput,
} from "@coreui/react";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import i18next from "i18next";

const EditSurveyForm = ({
  onSubmit,
  surveyInstance,
  FormTitle,
  onCancel,
}) => {
  const { register, handleSubmit, setValue, watch, reset } = useForm({
    mode: "onBlur",
  });
  const survey = surveyInstance?.survey;
  const ref = useRef(null);
  const saveDraft = () => {
    setValue("draft", 2);
    const buttonRef = ref.current && ref.current.children[0];
    buttonRef.click()
  }
  useEffect(() => {
    if (surveyInstance) {
      reset(surveyInstance);
    }
  }, [surveyInstance]);
  return (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
        <h4 className="header-label-title">{FormTitle}</h4>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CLabel>{i18next.t("Note")}</CLabel>
              <CTextarea
                name="note"
                id="note"
                rows="5"
                placeholder="Notes..."
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
          <SurveyForm
            register={register}
            setValue={setValue}
            watch={watch}
            groups={survey ? survey.groups : []}
          />
        <CModalFooter>
          <CInput type="hidden" innerRef={register} name="draft" />
          {surveyInstance?.editable && <CButton type="button" onClick={saveDraft} color="info">
            {i18next.t("Save Draft")}
          </CButton>}
          <div ref={ref}>
            <CButton type="submit" color="primary">
              {i18next.t("Submit")}
            </CButton>
          </div>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>

    </CForm>
  );
};
export default EditSurveyForm;