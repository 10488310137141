import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import { CCol, CRow, CContainer, CCard, CCardBody } from "@coreui/react";
import { fetchMyClinics } from "actions/clinics/clinics";
import ClinicsCardGroup from "./components/ClinicsCardGroup";

const MyClinics = () => {
  const dispatch = useDispatch();
  const clinics = useSelector((state) => state.clinics.myClincs);
  const render = (items) => {
    if (items && items.data.length !== 0) {
      const data = Object.entries(items.data);
      return data.map(([key, value]) => (
        <ClinicsCardGroup date={key} clinics={value} />
      ));
    }
    return (
      <CCard>
        <CCardBody>
          <CRow>
            <CCol>
              <h4 className="text-align-center not-found">
                <strong>No Available Appointments For Now</strong>
              </h4>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    );
  };
  useEffect(() => {
    dispatch(fetchMyClinics());
  }, [dispatch]);
  return (
    <>
      <CContainer>
        <CRow>
          <CCol xs="12">
            <div className="float-left table-label-title"> {i18next.t("My Clinics")}</div>
          </CCol>
        </CRow>
        {render(clinics)}
      </CContainer>
      <div>
        {/* <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CRow>
                  <CCol xs="12" md="4" float-right>
                    <CInput placeholder="Search" />
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol xs="12"></CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow> */}
      </div>
    </>
  );
};

export default MyClinics;
