// @flow
import setQueryString from "helpers/helpers";
import { Api, getTokenHeader } from "services/Api";
import {
  FETCH_EPISODE_UTILITIES,
  CREATE_EPISODE,
  FETCH_EPISODE,
  UPDATE_EPISODE,
  FETCH_EPISODES,
  DELETE_EPISODE,
  CLOSE_EPISODE,
  GET_EPISODE_CHRONIC_DISEASES,
  GET_EPISODE_CONSULTATIONS,
  SET_DISCHARGE_REASON,
  GET_EPISODE_DIAGNOSTICS_DATA,
} from "./types";

export const fetchEpisodeUtilities = (hospitalId = null): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/episodes/utilities/${hospitalId}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_EPISODE_UTILITIES, payload: response.data });
};

export const createEpisode = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/episodes`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_EPISODE, payload: response.data });
};

export const fetchEpisode = (id: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/episode/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_EPISODE, payload: response.data });
};

export const updateEpisode = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(`/api/admin/episodes/${id}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_EPISODE, payload: response.data });
};

export const fetchEpisodes = (
  page: string,
  hospitalId: string,
  limit: string,
  status: Boolean,
  filters: Object = {}
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/hospitals/${hospitalId}/episodes?page=${page}&limit=${limit}&status=${status}${setQueryString(
      filters
    )}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_EPISODES, payload: response.data });
};

export const deleteEpisode = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/episodes/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_EPISODE, payload: id });
};

export const closeEpisode = (id: string): Object => async (dispatch) => {
  await Api.get(`/api/admin/episodes/${id}/close`, {
    ...getTokenHeader(),
  });
  dispatch({ type: CLOSE_EPISODE });
};

export const getEpisodeChronicDiseases = (id: string): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/episode/${id}/chronic-diseases`,
    getTokenHeader()
  );
  dispatch({ type: GET_EPISODE_CHRONIC_DISEASES, payload: response.data });
};

export const getEpisodeConsultations = (id: string): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/episode/${id}/consultations`,
    getTokenHeader()
  );
  dispatch({ type: GET_EPISODE_CONSULTATIONS, payload: response.data });
};

export const setDischargeReason = (
  formData: Object,
  id: string
): Object => async (dispatch) => {
  const response = await Api.put(
    `/api/admin/episode/${id}/set-discharge-reason`,
    formData,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: SET_DISCHARGE_REASON });
};

export const getEpisodeDiagnosticsData = (episodeId): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/episode/${episodeId}/diagnostics-data`,
    getTokenHeader()
  );
  dispatch({ type: GET_EPISODE_DIAGNOSTICS_DATA, payload: response.data });
};
