import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CButton,
} from "@coreui/react";
import { fetchLabOrders } from "actions/discharge-form/dischargeForm";
import { RADS } from "constants/constants";
import i18next from "i18next";

const LabOrders = ({ setActiveScreen, onCancel, episode }) => {
  const dispatch = useDispatch();
  const { episodeId } = useParams();
  const fields = [
    {key: "lab_orders_names",label: i18next.t("Lab Orders Names")},
    {key: "date",label: i18next.t("date")},
  ];
  const labOrders = useSelector((state) => state.dischargeForm.labs);

  useEffect(() => {
    dispatch(fetchLabOrders(episode.id));
  }, [dispatch, episode.id]);

  return (
    <>
        <CCol>
          <div className="table-title">{i18next.t("Lab Orders")}</div>
        </CCol>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CCardBody>
              <CDataTable
                items={labOrders}
                noItemsViewSlot={i18next.t("No Available Items")}
                fields={fields}
                striped
                itemsPerPage={5}
                pagination
                scopedSlots={{
                  lab_orders_names: (item) => (
                    <td>{`${item.investigationTypeText}`}</td>
                  ),
                  date: (item) => <td>{item.date}</td>,
                }}
              />
            </CCardBody>
          </CCard>
          <div className="my-2 float-right">
            <CButton
              style={{
                backgroundColor: "#30c5b6",
                borderColor: "#30c5b6",
                color: "white",
                "width":"7em"
              }}
              className="mx-1"
              onClick={() => setActiveScreen(null)}
            >
              {i18next.t("Previous")}
            </CButton>
            <CButton
              color="primary"
              className="mx-1"
              onClick={() => setActiveScreen(RADS)}
            >
              {i18next.t("next")}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </>
  );
};
export default LabOrders;
