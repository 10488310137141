import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CInputCheckbox,
  CInput,
} from "@coreui/react";
import {
  fetchQuestions,
  deleteQuestion,
  publishQuestions,
} from "actions/surveys/questions/questions";
import { LIMIT } from "constants/constants";
import Permissions from "constants/permissions";
import CIcon from "@coreui/icons-react";
import { cilSearch } from "@coreui/icons";
import i18next from "i18next";

const Questions = () => {
  const history = useHistory();
  const questionsRef = useRef();
  const userPermissions = useSelector((state) =>
    state.auth.jwt ? state.auth.jwt.user.permissions : []
  );
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions.data);
  const meta = useSelector((state) => state.questions.meta);

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/questions?page=${newPage}`);
    }
  };

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
  });

  const handleDeleteQuestion = (id: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This Question.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteQuestion(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const handlePublishQuestion = (id: string) => {
    const formData = {
      questions: [id],
    };
    dispatch(publishQuestions(formData));
  };

  const handlePublishQuestions = (formData) => {
    dispatch(publishQuestions(formData));
  };

  const handleSearchBoxChange = (event) => {
    dispatch(fetchQuestions(page, LIMIT, { q: event.target.value }));
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchQuestions(page, LIMIT));
  }, [dispatch, page]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol sm="12">
          <div className="float-left table-label-title">{i18next.t("questions")}</div>
        </CCol>
        <CCol sm="12">
          <CButton
            color="success"
            href="/questions/create"
            className="create-button float-right wi"
          >
            {i18next.t("New Question")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol xs="12" md="4">
                  <div className="search-wrap">
                    <CInput
                      className="search-input"
                      type="text"
                      placeholder={i18next.t("Search by Question")}
                      onChange={handleSearchBoxChange}
                    />
                    <CIcon
                      content={cilSearch}
                      className="c-icon-xl search-icon"
                    ></CIcon>
                  </div>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={questions || []}
                fields={[
                  { key: "id", label:  i18next.t("id") },
                  { key: "question", label:  i18next.t("question") },
                  { key: "author", label:  i18next.t("Author") },
                  { key: "actions", label:  i18next.t("Actions") },
                ]}
                hover
                striped
                noItemsViewSlot={i18next.t("No Available Items")}
                underTableSlot={
                  <tr style={{ float: "right" }}>
                    <td>
                      <CButton
                        onClick={handleSubmit(handlePublishQuestions)}
                        style={{
                          "background-color": "rgb(48, 197, 182)",
                          "border-color": "rgb(48, 197, 182)",
                          color: "white",
                          width: "11em",
                          height: "3em",
                        }}
                      >
                        {i18next.t("Publish All")}
                      </CButton>
                    </td>
                  </tr>
                }
                scopedSlots={{
                  author: (item) => (
                    <td>
                      {`${item.author.first_name} ${item.author.last_name}`}
                    </td>
                  ),
                  actions: (item) => (
                    <td>
                      {item.editable && <a
                        href={`/questions/${item.id}/edit`}
                        className="btn"
                        style={{
                          marginRight: "5px",
                          "background-color": "rgb(48, 197, 182)",
                          "border-color": "rgb(48, 197, 182)",
                          color: "white",
                          width: "7em",
                        }}
                      >
                        {i18next.t("Edit")}
                      </a>}
                      <a
                        href={`/questions/${item.id}/show`}
                        style={{ marginRight: "5px" }}
                        className="btn btn-primary"
                      >
                        {i18next.t("show")}
                      </a>
                      {!item.published && (
                        <CButton
                          onClick={() => handlePublishQuestion(item.id)}
                          style={{
                            marginRight: "5px",
                            "background-color": "rgb(48, 197, 182)",
                            "border-color": "rgb(48, 197, 182)",
                            color: "white",
                            width: "7em",
                          }}
                        >
                          {i18next.t("Publish")}
                        </CButton>
                      )}
                      <CButton
                        onClick={() => handleDeleteQuestion(item.id)}
                        color="danger"
                      >
                        {i18next.t("Delete")}
                      </CButton>
                    </td>
                  ),
                }}
              />
              <CPagination
                style={{ clear: "both" }}
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Questions;
