import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CCol, CRow, CCard, CCardBody } from "@coreui/react";
import {
  checkPatientAppointmentLobby,
  joinTheLobby,
} from "actions/appointments/patientAppointment";
import { PATIENT_APPOINTMENT_LOBBY_CHECK } from "actions/appointments/types";

const ClinicLobbyCheck = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { appointmentId } = useParams();
  const patientAppointmentLobbyCheck = useSelector(
    (state) => state.patientAppointmentLobbyCheck
  );

  useEffect(() => {
    dispatch(checkPatientAppointmentLobby(appointmentId));
  }, [dispatch, appointmentId]);

  if (patientAppointmentLobbyCheck) {
    window.Echo.private(
      `clinic.${patientAppointmentLobbyCheck.clinic_id}`
    ).listen("StartClinic", (eventData) => {
      const { lobby } = eventData;
      if (lobby && lobby.session_token) {
        dispatch({
          type: PATIENT_APPOINTMENT_LOBBY_CHECK,
          payload: { lobbySession: lobby.session_token },
        });
      }
    });
  }

  if (patientAppointmentLobbyCheck.lobbySession) {
    dispatch(joinTheLobby(appointmentId));
    history.push(
      `/patient/clinic/lobby/${patientAppointmentLobbyCheck.lobbySession}`
    );
  }

  return (
    <>
      <CRow>
        <CCol>
          <CRow>
            <CCol xs="12">
              <CCard>
                <CCardBody>
                  <h4 className="text-align-center not-found">
                    <strong>Waiting For The Doctor To Start The Clinic</strong>
                  </h4>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </>
  );
};

export default ClinicLobbyCheck;
