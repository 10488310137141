import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAppointments } from "actions/appointments/appointment";
import { APPOINTMENT_STATUS_CANCELED } from "constants/constants";
import { confirmAlert } from "react-confirm-alert";
import { cilActionRedo } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const ListCancelledAppointments = () => {
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.appointments);

  const fields = [
    { key:"id", label:  i18next.t("id") },
    { key:"specialty", label:  i18next.t("Specialty") },
    { key:"doctor", label:  i18next.t("Doctor") },
    { key:"patient", label:  i18next.t("Patient") },
    { key:"schedule_time", label:  i18next.t("Schedule Time") },
    { key:"clinic", label:  i18next.t("Clinic") },
    { key:"actions", label:  i18next.t("Actions") },
  ];
  useEffect(() => {
    dispatch(fetchAppointments({ status: APPOINTMENT_STATUS_CANCELED }));
  }, [dispatch]);

  const onRefundAppointment = (id) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Refund This Appointment")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            console.log("refund this appointment", id);
          },
        },
        {
          label: `${i18next.t("No")}`,
        },
      ],
    });
  };

  const showActions = (item) => (
    <td className="py-2">
      <>
        <CDropdown variant="btn-group">
          <CDropdownToggle
            className="action-toggle m-0"
            color="light"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="c-icon"
              role="img"
            >
              <path
                fill="var(--ci-primary-color, currentColor)"
                d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                class="ci-primary"
              ></path>
              <path
                fill="var(--ci-primary-color, currentColor)"
                d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                class="ci-primary"
              ></path>
              <path
                fill="var(--ci-primary-color, currentColor)"
                d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                class="ci-primary"
              ></path>
            </svg>
          </CDropdownToggle>
          <CDropdownMenu className="p-0">
            <CDropdownItem
              key={`sh-${item.id}`}
              onClick={() => onRefundAppointment(item.id)}
              className="text-danger"
            >
              <CIcon content={cilActionRedo} />
              <span class="mx-1">{i18next.t("Refund")}</span>
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </>
    </td>
  );

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">
            {i18next.t("Cancelled Appointments")}
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CCardBody>
              <CDataTable
                items={Object.values(appointments)}
                fields={fields}
                noItemsView={i18next.t("No Available Items")}
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                itemsPerPage={50}
                pagination
                scopedSlots={{
                  actions: showActions,
                  schedule_time: (item) => (
                    <td>
                      {`${+item?.schedule_time?.split(" ")[1].split(":")[0]}` <
                      12
                        ? `${item?.schedule_time?.split(" ")[0]} ${
                            item?.schedule_time?.split(" ")[1].split(":")[0] %
                            12
                          }:${
                            item?.schedule_time?.split(" ")[1].split(":")[1]
                          } ${i18next.t("AM")}`
                        : `${item?.schedule_time?.split(" ")[0]} ${
                            item?.schedule_time?.split(" ")[1].split(":")[0] %
                            12
                          }:${
                            item?.schedule_time?.split(" ")[1].split(":")[1]
                          } ${i18next.t("PM")}`}
                    </td>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default ListCancelledAppointments;
