import React from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CSelect,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CSwitch,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

const codes = [
  { id: 1, title: "Code - 1" },
  { id: 2, title: "Code - 2" },
  { id: 3, title: "Code - 3" },
];

const Codes = () =>
  codes.map((item) => (
    <option key={item.id} value={item.id}>
      {item.title}
    </option>
  ));

const DiagnosisForm = ({ onSubmit, modal, diagnosis, FormTitle, onCancel }) => {
  let defaultValues = {};
  if (diagnosis) {
    defaultValues = diagnosis;
  }
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  return (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} size="lg">
        <CModalHeader>{FormTitle}</CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol md="4">
              <CLabel>ICD 10 Codes</CLabel>
              <CSelect custom name="code" id="code" innerRef={register}>
                {Codes()}
              </CSelect>
            </CCol>
            <CCol md="4">
              <CLabel>Note</CLabel>
              <CInput type="text" id="note" name="note" innerRef={register} />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="2">
              <CLabel>Chronic</CLabel>
            </CCol>
            <CCol md="4">
              <CSwitch
                labelOn="Yes"
                labelOff="No"
                name="chornic"
                className="mr-3"
                size="lg"
                color="primary"
                defaultChecked
                shape="pill"
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" size="sm" color="primary">
             Save
          </CButton>
          <CButton type="reset" size="sm" color="danger" onClick={onCancel}>
             Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  );
};

export { codes };
export default DiagnosisForm;
