import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  CCol,
  CRow,
  CModal,
  CModalFooter,
  CModalBody,
  CCard,
  CCardBody,
  CCardTitle,
  CCardHeader,
  CInput,
  CButton,
  CCardSubtitle,
  CLink,
  CImg,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import PastMedicalHistory from "../../episode-management/screens/spoke/cases/tabs/pastMedicalHistory/PastMedicalHistory";
import { listClinicAppointments } from "actions/appointments/appointment";
import { fetchClinic, startClinic } from "actions/clinics/clinics";
import CIcon from "@coreui/icons-react";
import Avatar from "views/partials/Avatar";
import {
  cilCalendar,
  cilChatBubble,
  cilLocationPin,
  cilMediaPlay,
  cilOptions,
  cilPencil,
  cilScreenDesktop,
  cilTrash,
  cilX,
} from "@coreui/icons";
import Patients from "./components/Patients";
import i18next from "i18next";

const iconStyle = {
  fontSize: "1.3rem",
  width: "1.3rem",
  height: "1.3rem",
};

const ClinicAppointments = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointments = useSelector(
    (state) => state.appointments.clinic_appointments
  );
  const clinic = useSelector((state) => state.clinics.clinic);
  const lobby = useSelector((state) => state.lobby.lobby);
  const [medicalHistory, setMedicalHistory] = useState(false);
  const [patientId, setPatientId] = useState(false);
  const toggleMedicalHistory  = (patientId) => {
    setPatientId(patientId)
    setMedicalHistory(!medicalHistory);
  };

  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const [day, month, year] = match.params.date.split('-');
  const paramDate = `${year}-${month}-${day}`
  const newDate = new Date(paramDate);

  const dayName = days[newDate.getDay()];
  const nowtime = new Date().toLocaleTimeString("en-US", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });

  if (lobby && lobby.clinic_id == match.params.id) {
    history.push(`/lobby/${lobby.session_token}`);
  }

  useEffect(() => {
    dispatch(listClinicAppointments(match.params.id, paramDate));
    dispatch(fetchClinic(match.params.id));

  }, [dispatch, paramDate, match.params.id]);

  const handleStartClinic = (clinicId) => {
    dispatch(startClinic(clinicId));
    dispatch(fetchClinic(match.params.id));
  };
  const renderLobbyBtns = () => (
    // if (!clinic.started_today) {
    <CButton color="info" onClick={() => handleStartClinic(clinic.id)}>
      Start
    </CButton>
  );


  // const app = appointments.data.filter((appoinment) => {appoinment.patient.id == patientId.patientId});

  // console.log(app);
  // }
  let patient;
  if( appointments && patientId){
    const appointment =
    appointments.data.filter((app) => app.patient.id == patientId)
    patient = appointment[0].patient;
  }
  if (appointments && clinic) {
    const start = moment(clinic[`${dayName}_from`], ["h:m a", "H:m"]);
    const end = moment(clinic[`${dayName}_to`], ["h:m a", "H:m"]);
    return (
      <>
        <CRow className="justify-content-between align-items-center my-1">
          <CCol>
            <div
              className="float-left table-title title-before"
              onClick={() => {
                history.push(`/my-clinics`);
              }}
            >
              {i18next.t("clinics")}
            </div>
          </CCol>
        </CRow>
        <CCard className="text-theme-blue p-4 clinic-card">
          <CCardBody className="p-0">
            <CRow className="align-items-center justify-content-between">
              <CCol md="2">
                <CRow>
                  <CCol xs="12">
                    <Avatar
                      src={clinic.doctor.avatar}
                      style={{
                        width: "95px",
                        height: "95px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      className="float-left"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="8">
                <CRow>
                  <CCol xs="auto" className="d-flex flex-column" style={{maxWidth: '25%'}}>
                    <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                      {`${clinic.doctor.first_name} ${clinic.doctor.last_name}`}
                    </CCardTitle>
                    <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                      {clinic.specialty.name}
                    </CCardSubtitle>
                  </CCol>
                  <CCol xs="auto" className="d-flex flex-column" style={{maxWidth: '25%'}}>
                    <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                      {i18next.t("From")}
                    </CCardTitle>
                    <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                      {`${clinic[`${dayName}_from`]?.split(":")[0]}` < 12
                        ? ` ${clinic[`${dayName}_from`]?.split(":")[0] % 12}:${
                            clinic[`${dayName}_from`]?.split(":")[1]
                          } ${i18next.t("AM")}`
                        : `${clinic[`${dayName}_from`]?.split(":")[0] % 12}:${
                            clinic[`${dayName}_from`]?.split(":")[1]
                          } ${i18next.t("PM")}`}
                    </CCardSubtitle>
                  </CCol>
                  <CCol xs="auto" className="d-flex flex-column" style={{maxWidth: '25%'}}>
                    <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                    {i18next.t("To")}
                    </CCardTitle>
                    <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                      {`${clinic[`${dayName}_to`]?.split(":")[0]}` < 12
                        ? ` ${clinic[`${dayName}_to`]?.split(":")[0] % 12}:${
                            clinic[`${dayName}_to`]?.split(":")[1]
                          } ${i18next.t("AM")}`
                        : `${clinic[`${dayName}_to`]?.split(":")[0] % 12}:${
                            clinic[`${dayName}_to`]?.split(":")[1]
                          } ${i18next.t("PM")}`}
                    </CCardSubtitle>
                  </CCol>
                  <CCol xs="auto" className="d-flex flex-column" style={{maxWidth: '25%'}}>
                    <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                       {i18next.t("Location")}
                    </CCardTitle>
                    <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                      {clinic.hospital.name}
                    </CCardSubtitle>
                  </CCol>
                  <CCol xs="auto" className="d-flex flex-column"style={{maxWidth: '25%'}}>
                    <CRow>
                      <CCol xs="auto" className="d-flex flex-column">
                        {clinic.type == "virtual" ? (
                          <CIcon
                            className="clinic-icon"
                            content={cilScreenDesktop}
                          />
                        ) : (
                          <CIcon
                            className="clinic-icon"
                            content={cilLocationPin}
                          />
                        )}
                      </CCol>
                      <CCol xs="auto" className="d-flex flex-column p-0">
                        <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                          {i18next.t("Type")}
                        </CCardTitle>
                        <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                        {clinic.type== "virtual"?i18next.t("Virtual"):i18next.t("Physical")}
                        </CCardSubtitle>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="2">
                {clinic[`${dayName}`] &&
                nowtime >= start._i &&
                nowtime <= end._i ? (
                  <CRow className="justify-content-end align-items-center">
                    <CCol xs="10" className="d-flex flex-column">
                      <CLink
                        style={{ "padding-left": "2.5rem" }}
                        className="avh-btn py-3 text-decoration-none text-white avh-font-bold float-right d-color"
                        onClick={() => handleStartClinic(clinic.id)}
                      >
                        <CIcon
                          content={cilMediaPlay}
                          className="mx-1"
                          style={iconStyle}
                        />
                        {i18next.t("Start")}
                      </CLink>
                    </CCol>
                  </CRow>
                ) : (
                  <CRow className="justify-content-end align-items-center">
                    <CCol xs="10" className="d-flex flex-column">
                      <CLink className="avh-btn px-4 py-3 text-decoration-none text-white avh-font-bold float-right">
                        <CIcon
                          content={cilMediaPlay}
                          className="mx-1"
                          style={iconStyle}
                        />
                        {i18next.t("Waiting")} ...
                      </CLink>
                    </CCol>
                  </CRow>
                )}
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CRow>
          <CCol>
            <Patients appointments={appointments} toggleMedicalHistory={toggleMedicalHistory} />
          </CCol>
        </CRow>
      <CModal
        show={medicalHistory}
        onClose={() => setMedicalHistory(false)}
        size="xl"
      >
        <CModalBody>
          {patientId && appointments && (<>
          <PastMedicalHistory patientId={patientId}  hospital={clinic.hospital} patient={patient}/>
          </>
          )}
        </CModalBody>
        <CModalFooter>
            <CButton
              type="reset"
              color="danger"
              onClick={() => setMedicalHistory(false)}
            >
              {i18next.t("Close")}
            </CButton>
        </CModalFooter>
      </CModal>
      </>
    );
  }
  return <></>;
};

export default ClinicAppointments;
