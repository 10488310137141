import React, { useState } from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CContainer,
} from "@coreui/react";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUserDoctor,
  faFlask,
  faXRay,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import PastEpisodes from "./PastEpisodes";
import PastLabs from "./PastLabs";
import PastRads from "./PastRads";
import PastClinics from "./PastClinics";
import PublicSurveys from "../../../../../../specialties-forms/screens/PublicSurveys.js";

const SideBar = () => (
  <CNav variant="tabs" className="sidebar-tabs past-medical-menu">
    <CNavItem className="w-100 border-bottom">
      <CNavLink
        data-tab="episodes"
        className="text-body font-weight-bold p-3 episode-tab-desktop"
      >
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faBuilding} className="mr-3" />
          <span className="d-none d-md-inline">{i18next.t("episodes")}</span>
        </div>
      </CNavLink>
    </CNavItem>
    <CNavItem className="w-100 border-bottom">
      <CNavLink
        data-tab="clinics"
        className="text-body font-weight-bold p-3 episode-tab-desktop"
      >
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faUserDoctor} className="mr-3" />
          <span className="d-none d-md-inline">{i18next.t("clinics")}</span>
        </div>
      </CNavLink>
    </CNavItem>
    <CNavItem className="w-100 border-bottom">
      <CNavLink
        data-tab="labs"
        className="text-body font-weight-bold p-3 episode-tab-desktop"
      >
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faFlask} className="mr-3" />
          <span className="d-none d-md-inline">{i18next.t("labs")}</span>
        </div>
      </CNavLink>
    </CNavItem>
    <CNavItem className="w-100 border-bottom">
      <CNavLink
        data-tab="rad"
        className="text-body font-weight-bold p-3 episode-tab-desktop"
      >
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faXRay} className="mr-3" />
          <span className="d-none d-md-inline">{i18next.t("radiology")}</span>
        </div>
      </CNavLink>
    </CNavItem>
    <CNavItem className="w-100 border-bottom">
      <CNavLink
        data-tab="patient submitions"
        className="text-body font-weight-bold p-3 episode-tab-desktop"
      >
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faNoteSticky} className="mr-3" />
          <span className="d-none d-md-inline">{i18next.t("Surveys")}</span>
        </div>
      </CNavLink>
    </CNavItem>
  </CNav>
);

const PastMedicalHistory = ({ patientId, hospital, patient }) => {
  const [activeScreen, setActiveScreen] = useState("episodes");
  const TabsContent = () => (
    <CTabContent activeTab={activeScreen}>
      <CTabPane data-tab="episodes">
        <PastEpisodes patientId={patientId} setActiveScreen={setActiveScreen} />
      </CTabPane>
      <CTabPane data-tab="clinics">
        <PastClinics patientId={patientId} />
      </CTabPane>
      <CTabPane data-tab="labs">
        <PastLabs patientId={patientId} setActiveScreen={setActiveScreen} />
      </CTabPane>
      <CTabPane data-tab="rad">
        <PastRads patientId={patientId} setActiveScreen={setActiveScreen} />
      </CTabPane>
      <CTabPane data-tab="patient submitions">
        <PublicSurveys patientId={patientId} hospital={hospital} patient={patient}/>
      </CTabPane>
    </CTabContent>
  );

  return (
    <CCard className="border-0">
      <CCardBody className="p-0">
        <CContainer fluid>
          <CRow>
            <CTabs activeTab={activeScreen} onTabChange={setActiveScreen}>
              <CCol xs="12" md="2" className="text-dark sidebar-tabs-wrap px-0">
                <SideBar />
              </CCol>
              <CCol xs="12" md="10" className="text-dark">
                {TabsContent()}
              </CCol>
            </CTabs>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  );
};

export default PastMedicalHistory;
