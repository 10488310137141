import React, { useState, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
} from "@coreui/react";
import { useHistory, useLocation } from "react-router-dom";
import { LIMIT } from "constants/constants";
import i18next from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchMatchedSurveys } from "actions/matched-sent-surveys/matchedSentSurveys";

const MatchedSentSurveys = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/matched-sent-surveys?page=${newPage}`);
    }
  };

  const matchedSurveys = useSelector((state) => state.MatchedSentSurveys.data);
  const meta = useSelector((state) => state.MatchedSentSurveys.meta);



  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
    dispatch(fetchMatchedSurveys(page, LIMIT));
  }, [dispatch, currentPage, page]);

  const renderAction = (item) => {
    if (item.check === 'answer' ) {
      return (
        <>
          <td>
          <a
                      href={`/Answer-Survey/${item.id}/${item.survey.id}`}                     
                      className="btn"
                      style={{
                        marginRight: "5px",
                        "background-color": "rgb(48, 197, 182)",
                        "border-color": "rgb(48, 197, 182)",
                        color: "white",
                        width: "7em",
                      }
                    }
                    >
                      
                      {i18next.t("Answer")}
                    </a>
          </td>
        </>
      );
    }

    else if (item.check === 'missed' ) {
      return (
        <>
          <td>
            <h3 className="text-danger">{i18next.t("Missed")}</h3>
          </td>
        </>
      );
    }

    else if (item.check === 'continue' ) {
      return (
        <>
          <td>
          <a
                      href={`/Edit-Survey/${item.survey_instance_id}`}
                      
                      className="btn"
                      style={{
                        marginRight: "5px",
                        "background-color": "rgb(48, 197, 182)",
                        "border-color": "rgb(48, 197, 182)",
                        color: "white",
                        width: "7em",
                      }}
                    >
                      {i18next.t("Continue")}
                      
                    </a>
          </td>
        </>
      );
    }
    else if (item.check === 'completed' ) {
      return (
        <>
          <td>
            <h3 className="text-primary">{i18next.t("Completed")}</h3>
          </td>
        </>
      );
    }
    return <td />;
  };
  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={matchedSurveys || []}
                fields={[
                  {key: "id", label: i18next.t("id")},
                  {key: "title", label: i18next.t("title")},
                  {key: "latest_answered_time", label: i18next.t("latest answered time")},
                  {key: "sent_date_time", label: i18next.t("sent date time")},
                  {key: "actions", label: i18next.t("Actions")},
                  
                ]}
                hover
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                scopedSlots={{
                  title: (item) => <td>{item.survey.title}</td>,
                  latest_answered_time: (item) => (
                    <td>{item.latest_answered_time ? item.latest_answered_time : i18next.t("No date Answer")}</td>
                  ),
                  sent_date_time: (item) => (
                    <td>{`${item.sent_date_time}`}</td>
                  ),
                  actions: renderAction,

                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default MatchedSentSurveys;