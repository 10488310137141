import { mapKeys, omit } from "lodash";

import {
  FETCH_INDEX_UTILITIES,
  FETCH_EVENTS,
  CREATE_EVENT,
  FETCH_EVENT_FORM_UTILITIES,
  DELETE_EVENT,
  FETCH_CLINICS_EVENTS,
  UPDATE_EVENT,
} from "actions/calendar/types";

/**
 * @param state
 * @param action
 */
export default function calendarEvents(
  state: Object = {},
  action: Object
): Object {
  console.log(action);
  switch (action.type) {
    case FETCH_EVENTS:
      return { ...mapKeys(action.payload.data, "id") };
    case CREATE_EVENT:
      if (action.payload?.data?.id) {
        return { ...state, [action.payload.data.id]: action.payload.data };
      }
    case UPDATE_EVENT:
      if (action.payload?.data?.id) {
        return { ...state, [action.payload.data.id]: action.payload.data };
      }
    case DELETE_EVENT:
      return omit(state, action.payload);
    default:
      return state;
  }
}

/**
 * @param state previous state
 * @param action
 * @returns {*}
 */
export function calendarUtilities(state: Object = {}, action: Object): Object {
  console.log(action);
  switch (action.type) {
    case FETCH_INDEX_UTILITIES:
    case FETCH_EVENT_FORM_UTILITIES:
      return { ...state, ...action.payload };
    case FETCH_CLINICS_EVENTS:
      return { ...state, clinics_events: action.payload };
    default:
      return state;
  }
}
