import { refreshCurrentToken } from "actions/auth/auth";

const RefreshTokenManager = () => {
  let refreshTimeOutId;

  // This countdown feature is used to renew the JWT in a way that is transparent to the user.
  // before it"s no longer valid
  const refreshToken = (tokenExpiryTime, dispatch) => {
    if (tokenExpiryTime) {
      refreshTimeOutId = window.setInterval(
        () => dispatch(refreshCurrentToken()),
        tokenExpiryTime * 1000 - 5000
      ); // Validity period of the token in seconds, minus 5 seconds
    }
  };

  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearInterval(refreshTimeOutId);
    }
  };

  return {
    refreshToken,
    abortRefreshToken,
  };
};

export default RefreshTokenManager();
