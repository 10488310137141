import { Api, getTokenHeader } from "services/Api";
import {
  CREATE_LAB,
  FETCH_LAB,
  UPDATE_LAB,
  FETCH_LABS,
  DELETE_LAB,
  FETCH_LAB_STAFF,
  FETCH_LAB_EXCLUDED_USERS,
  FETCH_CENTER_INVESTIGATIONS,
  DELETE_LAB_MEMBER,
} from "./types";
import setQueryString from "helpers/helpers";

const prepareLabData = (labData) => {
  let formData = new FormData();
  formData.append("agreement", labData.agreement[0]);
  formData.append("name", labData.name);
  formData.append("phone", labData.phone);
  formData.append("address", labData.address);
  formData.append("description", labData.description);
  formData.append("country_id", labData.country_id);
  formData.append("city_id", labData.city_id);
  formData.append("c_name", labData.c_name);
  formData.append("c_phone", labData.c_phone);
  formData.append("c_email", labData.c_email);
  return formData;
};

const prepareLabUpdateMediaData = (labData) => {
  let updateMediaFormData = new FormData();
  updateMediaFormData.append('agreement', labData.agreement[0] || '');
  return updateMediaFormData;
};

export const createLab = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/labs`,
    prepareLabData(formData), {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_LAB, payload: response.data });
};

export const fetchLab = (id: string) => async (dispatch) => {
  const response = await Api.get(`/api/admin/lab/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_LAB, payload: response.data });
};

export const updateLab = (formData: Object, id: string): Object => async (dispatch) => {
  const response = await Api.put(`/api/admin/labs/${id}`, formData, {
    ...getTokenHeader(),
  });

  await Api.post(`/api/admin/labs/${id}/update-media`, prepareLabUpdateMediaData(formData), {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_LAB, payload: response.data });
};

export const fetchLabs = (page, limit, filters = {}) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/labs?page=${page}&limit=${limit}${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_LABS, payload: response.data });
};

export const deleteLab = (id: string) => async (dispatch) => {
  await Api.delete(`/api/admin/labs/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_LAB, payload: id });
};

export const fetchLabStaff = (id, page = 1) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/lab/${id}/staff?page=${page}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_LAB_STAFF, payload: response.data });
};


export const fetchLabExcudedUsers = (labId, role) => async (dispatch) => {
  const respons = await Api.get(`/api/admin/lab/${labId}/role/${role}/autocomplete`,
    {...getTokenHeader()}
  );
  dispatch({type: FETCH_LAB_EXCLUDED_USERS, payload: respons.data});
};

export const addLabStaff = (id, formData) => async (dispatch) => {
  const response = await Api.post(`/api/admin/lab/${id}/staff`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_LAB_STAFF, payload: response.data });
};
export const deleteLabMember = (id: string ,memberId: string) => async (dispatch) => {
  await Api.delete(`/api/admin/lab/${id}/${memberId}/staff`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_LAB_MEMBER, payload: memberId });
};
