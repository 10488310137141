import React from "react";
import { CCol, CFormGroup, CLabel, CTextarea, CSelect } from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import getValidation from "views/episode-management/screens/spoke/cases/forms/EpisodeFormValidation";
import { getClassName } from "views/hospitals/form/ServiceProviderValidation";
import i18next from "i18next";

const DoctorInputs = ({
  register,
  isDoctor,
  specialties,
  levels,
  watch,
  errors,
}) => {
  const Validation = getValidation(watch);
  if (isDoctor) {
    return (
      <>
        <CFormGroup row>
          <CCol
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="d-flex flex-column"
          >
            <CLabel
              htmlFor="specialty"
              className="float-left labels required w-fcontent"
            >
              {i18next.t("Specialty")}
            </CLabel>
            <CSelect
              className={`${getClassName(
                "specialty_id",
                "specialty_id",
                watch,
                errors
              )}`}
              custom
              name="specialty_id"
              id="specialty_id"
              innerRef={register(Validation.specialty_id)}
            >
              <option key="0" value="" selected disabled>
                {i18next.t("Select Specialty")}
              </option>
              {RenderSelectOptions(specialties)}
            </CSelect>
          </CCol>
          <CCol
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="d-flex flex-column"
          >
            <CLabel
              htmlFor="lebel"
              className="float-left labels required w-fcontent"
            >
              {i18next.t("experience")}
            </CLabel>
            <CSelect
              className={`${getClassName(
                "level_id",
                "level_id",
                watch,
                errors
              )}`}
              custom
              name="level_id"
              id="level_id"
              innerRef={register(Validation.hospital_id)}
            >
              <option key="0" value="" selected disabled>
                {i18next.t("Select Experience")}
              </option>
              {RenderSelectOptions(levels)}
            </CSelect>
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex flex-column"
          >
            <CLabel className="float-left labels required w-fcontent">
              {i18next.t("Bio")}
            </CLabel>
            <CTextarea
              className={`${getClassName("bio", "bio", watch, errors)}`}
              name="bio"
              id="bio"
              rows="9"
              placeholder={i18next.t("Bio")}
              innerRef={register(Validation.first_name)}
            />
          </CCol>
        </CFormGroup>
      </>
    );
  }
  return "";
};
export default DoctorInputs;
