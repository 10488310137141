import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createPrescription,
  updatePrescriptionStatus,
  resetPrescription,
} from "actions/prescriptions/prescriptions";
import { TYPE_APPOINTMENT, TYPE_DISCHARGE_PRESCRIPTION } from "constants/constants";
import PrescriptionForm from "./form/Form";
import i18next from "i18next";

const CreatePrescription = ({
  toggle,
  modal,
  getDrugOptions,
  setModifiedId,
  modifiedId,
  type,
  modelId,
  canConfirm = false,
}) => {
  const { episodeId } = useParams();
  const lable = `${i18next.t("Create Prescription")}`;
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.prescriptionUtilities);
  const doctorId = useSelector((state) => state.auth.jwt.user.id);
  const prescription = useSelector((state) => state.prescriptions.prescription);

  const onSubmit = (formData) => {
    const data = {
      ...formData,
      drug_id: formData.drug.id,
      doctor_id: doctorId,
      episode_id: type !== TYPE_APPOINTMENT ? episodeId : null,
      appointment_id: type === TYPE_APPOINTMENT ? modelId : null,
      type,
    };
    if (modifiedId) {
      dispatch(
        updatePrescriptionStatus(
          { status: utilities.status_modified },
          modifiedId
        )
      );
    }
    dispatch(createPrescription(data));
    setModifiedId(null);
    dispatch(resetPrescription());
    toggle();
  };

  const onCancel = () => {
    toggle();
    setModifiedId(null);
    dispatch(resetPrescription());
  };
  return (
    <PrescriptionForm
      utilities={utilities}
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal}
      lable={lable}
      getDrugOptions={getDrugOptions}
      prescription={prescription}
      canConfirmPrescription={
        canConfirm && (type === TYPE_APPOINTMENT || type === TYPE_DISCHARGE_PRESCRIPTION || utilities.can_confirm_prescription)
      }
      type={type}
    />
  );
};
export default CreatePrescription;
