import React from "react";
import { CButton } from "@coreui/react";
import i18next from "i18next";
const NextScreen = ({
  onCancel,
  setActiveScreen,
  nextScreen,
  previousScreen,
}) => (
  <>
    <div className="my-2 float-right">
      <CButton
        style={{
          backgroundColor: "#30c5b6",
          borderColor: "#30c5b6",
          color: "white",
          width:"7em"
        }}
        className="mx-1"
        onClick={() => setActiveScreen(previousScreen)}
      >
        {i18next.t("Previous")}
      </CButton>
      <CButton
        color="primary"
        className="mx-1"
        onClick={() => setActiveScreen(nextScreen)}
      >
        {i18next.t("Next")}
      </CButton>
      <CButton color="danger" className="mx-1" onClick={onCancel}>
      {i18next.t("Cancel")}
      </CButton>
    </div>
  </>
);
export default NextScreen;
