import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CRow,
  CForm,
  CFormGroup,
  CLabel,
  CButton,
  CTextarea,
} from "@coreui/react";
import StarRatingComponent from "react-star-rating-component";

export const RatingForm = ({ onClose, model_id, submitRate, message }) => {
  const [rateValue, setRateValue] = useState(5);
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
  });

  const onRateSubmit = (formData) => {
    const preparedData = {
      ...formData,
      rate: rateValue,
      model_id,
      model_type: 'appointment',
    };
    submitRate(preparedData);
    onClose();
  };
  return (
    <div className="react-confirm-alert-body">
      <p>{message}</p>
      <CRow>
        <CCol md="12">
          <CForm
            onSubmit={handleSubmit(onRateSubmit)}
            className="form-horizontal"
          >
            <CCol md="12">
              <CLabel htmlFor="roles">{`Rate: (${rateValue}/5)`}</CLabel>
              <div style={{ fontSize: "2em" }}>
                <StarRatingComponent
                  id="rate"
                  name="rate"
                  starCount={5}
                  value={rateValue}
                  onStarClick={(nextValue) => setRateValue(nextValue)}
                />
              </div>
            </CCol>
            <CCol md="12">
              <CFormGroup>
                <CLabel htmlFor="comment">Comment</CLabel>
                <CTextarea
                  name="comment"
                  id="comment"
                  innerRef={register}
                  rows="12"
                />
              </CFormGroup>
            </CCol>
            <CRow>
              <CCol md="6">
                <CButton type="submit" size="sm" color="primary">
                  Rate
                </CButton>
              </CCol>
              <CCol md="6">
                <CButton size="sm" color="danger" onClick={() => onClose()}>
                  Cancel
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
    </div>
  );
};

export default RatingForm;
