import React, {useState } from "react";
import { CCol, CRow, CButton } from "@coreui/react";
import Chat from "views/communications/chat/Chat";
import CIcon from "@coreui/icons-react";
import { cilChatBubble } from "@coreui/icons";

const ChatRooms = ({appointment, lobbySession}) => {
  const [selectedConversation, setSelectedConversation] = useState(`lobby-${lobbySession}`);
  const [showChat, setShowChat] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  console.log(readOnly);

  const DefaultConversation = () => {
    return( 
      <CCol>
        <CButton
          key="default-channel"
          id="default-channel"
          onClick={() => {setSelectedConversation(`lobby-${lobbySession}`); setReadOnly(true)}}
        >
          Lobby
        </CButton>
      </CCol>
    );
  };

  const DirectConversations = () => {
    return( 
      <CCol>
        <CButton
          key="default-channel"
          id="default-channel"
          onClick={() => {setSelectedConversation(`appointment-${appointment.id}`); setReadOnly(false)}}
        >
          Direct
        </CButton>
      </CCol>
    );
  };

    return (
      <>
        <div className="lobby-chat">
          <div className={["lobby-chat-messages", showChat ? "d-block" : "d-none"].join(" ")}>
            <CRow>
              <CCol xs={4}>
                <DefaultConversation />
                <DirectConversations />
              </CCol>
              <CCol xs={8}>
                  <Chat room={selectedConversation} lobby_id={appointment.lobby_id} readOnly={readOnly}/>
              </CCol>
            </CRow>
          </div>

          <CRow className="justify-content-end">
            <CButton className="lobby-chat-btn" onClick={() => setShowChat(!showChat)}>
              <CIcon className="lobby-chat-icon" content={cilChatBubble} />
              Chat
            </CButton>
          </CRow>

        </div>
      </>
    );
};
export default ChatRooms;
