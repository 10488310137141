// @flow
import jwtDecode from "jwt-decode";
import { Api, getTokenHeader } from "services/Api";
import {
  USER_LOGIN_SUCCEEDED,
  USER_LOGOUT,
  REFRESH_CURRENT_TOKEN,
  RESET_PASSWROD,
  FORGET_PASSWROD,
  RESEND_ACTIVATION,
  ACTIVATE,
} from "./types";
import { registerDeviceToken } from "actions/firebase/firebase";

export const resetpassword = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`api/admin/auth/reset-password`, formData);
  const reset= response.data;
  const resetresponse = { reset };
  dispatch({ type: RESET_PASSWROD, payload: resetresponse });
};

export const forgetpassword = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(`api/admin/auth/forget-password`, formData);
  const responseforget= response.data;
  const resetredirect = {responseforget };

  dispatch({ type: FORGET_PASSWROD, resetredirect });
};

export const resendActivation = (): Object => async (
  dispatch
) => {
  const response = await Api.get(`api/admin/auth/resend-verification`, { ...getTokenHeader()});

  dispatch({ type: RESEND_ACTIVATION, payload: response.data });
};

export const activate = (params: Object) : Object => async (
  dispatch
) => {
  const response = await Api.get(`api/admin/auth/verify-account/${params.token}/${params.email}`);

  dispatch({ type: ACTIVATE, payload: response.data });
};

export const patientRegister = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(`api/admin/auth/register`, formData);
  const jwt = jwtDecode(response.data.access_token);
  const payload = { ...response.data, jwt };
  dispatch({ type: USER_LOGIN_SUCCEEDED, payload });
};

export const login = (formData: Object): Object => async (dispatch) => {
  try {
    const response = await Api.post(`api/admin/auth/login`, formData);
    const jwt = jwtDecode(response.data.access_token);
    const payload = { ...response.data, jwt };
    dispatch({ type: USER_LOGIN_SUCCEEDED, payload });
  } catch (error) {

  }
};

export const refreshCurrentToken = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `api/admin/auth/refresh`,
    formData,
    getTokenHeader()
  );
  const jwt = jwtDecode(response.data.access_token);
  const payload = { ...response.data, jwt };
  dispatch({ type: REFRESH_CURRENT_TOKEN, payload });
};

export const logout = (): Object => async (dispatch) => {
  const response = await Api.post(
    `api/admin/auth/logout`,
    {},
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: USER_LOGOUT, payload: response.data });
};
