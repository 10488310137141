import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CRow,
  CCol,
  CImg,
  CContainer,
  CButton,
  CModal,
  CModalBody,
} from "@coreui/react";
import i18next from "i18next";
import { myHospitals } from "actions/hospitals/hospitals";
import avatar from "hospital.svg";
import Hubs from "./Hubs";

const ListSpokes = ({ spokes, toggle }) =>
  spokes.map((item) => (
    <>
      <CCol xs="12" md="6" lg="4" xl="4" xxl="3">
        <CCard
          title={item.created_at}
          className="card-height-spoke"
          style={{ width: "22em", padding: "1em 0em" }}
        >
          <a
            href={`/episodes/cases/${item.id}?logo=${item.logo}`}
            className="case-link"
          >
            <CImg
              className="mb-3 w-100"
              src={
                item.logo
                  ? process.env.REACT_APP_SERVICE_URL + item.logo
                  : avatar
              }
              style={{ height: "13em" }}
              onError={(e) => {
                console.log(e);
                e.target.src = avatar; // eslint-disable-line no-param-reassign
              }}
            />
            <CCardBody>
              <h5
                className="text-center text-mobile-size"
                style={{ "font-size": "1.3em" }}
              >
                {item.name}
              </h5>
            </CCardBody>
          </a>
          {item.children.length > 0 ? (
            <CButton
              className="spoke-hubs-btn"
              onClick={() => toggle(item.children)}
            >
              {i18next.t("network")}
            </CButton>
          ) : (
            <CButton className="spoke-hubs-btn" disabled="true">
              {i18next.t("network")}
            </CButton>
          )}
        </CCard>
      </CCol>
    </>
  ));

const SelectSpoke = () => {
  const dispatch = useDispatch();
  const spokes = useSelector((state) => state.hospitals.myHospitals);
  const [hubs, setHubs] = useState([]);
  const [modal, showModal] = useState(false);
  const toggle = (hubs) => {
    showModal(!modal);
    setHubs(hubs);
  };

  useEffect(() => {
    dispatch(myHospitals());
  }, [dispatch]);
  return (
    <>
      <CContainer>
        <CRow className="justify-content-between align-items-center my-1">
          <CCol>
            <div className="float-left table-label-title mb-3  text-mobile-small-size">
              {i18next.t("episode management")}
            </div>
          </CCol>
        </CRow>
        <CRow>
          <ListSpokes spokes={spokes ? spokes.data : []} toggle={toggle} />
        </CRow>
        <CModal show={modal} onClose={toggle}>
          <h4 className="header-label-title" closeButton>
            {i18next.t("network")}
          </h4>
          <CModalBody>
            <Hubs hubs={hubs} />
          </CModalBody>
        </CModal>
      </CContainer>
    </>
  );
};

export default SelectSpoke;
