import React, {useRef, useEffect} from "react";
import { useForm, Controller} from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CSelect,
  CTextarea,
  CInput,
} from "@coreui/react";
import {fetchPatientsBasics} from "actions/patients/patients"
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import i18next from "i18next";
import { getClassName } from "views/pages/register/RegisterFormValidation";
import getValidation from "views/clinics/form/ClinicFormValidation";
import AsyncSelect from "react-select/async";

const AnswerForm = ({
  onSubmit,
  modal,
  FormTitle,
  onCancel,
  specialties,
  hospitals,
  specialtyForm,
  specialtyFormsList,
  onSpecialtyFormChange,
  onSpecialtyChange,
  onLocationChange,
  hospital,
  patient = null
}) => {
  let defaultValues = {};
  const { register, handleSubmit, setValue, watch, errors, control } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const Validation = getValidation(watch);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const patients = useSelector((state) => state.patients.basics);
  const saveDraft = () => {
    setValue("draft", 2);
    const buttonRef = ref.current && ref.current.children[0];
    buttonRef.click()
  }
  const getPatientOptions = async (q) => {
    dispatch(fetchPatientsBasics(q));
    return patients;
  }


  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} size="lg" onClose={onCancel} >
        <h4 className="header-label-title">{FormTitle}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel className="labels required">Locations</CLabel>
              <CSelect
                className={`${getClassName(
                  "locations_id",
                  "Select Location",
                  watch,
                  errors
                )}`}
                custom
                name="locations_id"
                id="locations_id"
                innerRef={register(Validation.locations_id)}
                onChange={onLocationChange}
              >
                <option value=""> Select Location </option>
                {RenderSelectOptions(hospitals, hospital)}
              </CSelect>

              {patient ? (
                <>
                  <CLabel className="labels required">Patient</CLabel>
                  <CSelect
                    control={control}
                    id="patient"
                    name="patient"
                    custom
                    value={patient}
                    className="is-valid"
                  >
                    <option value={patient.id} selected disabled>
                      {patient.first_name} {patient.last_name}
                    </option>
                  </CSelect>
                  </>
              ): (
                <>
                  <CLabel className="labels required">Patient</CLabel>
                  <Controller
                    rules={{required: "Patient is required"}}
                    control={control}
                    id="patient"
                    name="patient"
                    loadOptions={(inputValue) =>
                      getPatientOptions(inputValue)
                    }
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    getOptionValue={(option) => option.id}
                    as={<AsyncSelect />}
                  />
                </>
              )}

            </CCol>
            <CCol xs="12" md="6">
              <CLabel className="labels required">Specialty </CLabel>
              <CSelect
                className={`${getClassName(
                  "specialty_id",
                  "Select Specialty",
                  watch,
                  errors
                )}`}
                custom
                name="specialty_id"
                id="specialty_id"
                innerRef={register(Validation.specialty_id)}
                onChange={onSpecialtyChange}
              >
                <option value="" >
                  Select Specialty
                </option>
                {RenderSelectOptions(specialties)}
              </CSelect>

              <CLabel className="labels required">Specialty Form</CLabel>
              <CSelect
                className={`${getClassName(
                  "specialty_form_id",
                  "Select Specialty Form",
                  watch,
                  errors
                )}`}
                custom
                name="specialty_form_id"
                id="specialty_form_id"
                innerRef={register(Validation.specialty_form_id)}
                onChange={onSpecialtyFormChange}
              >
                <option value="" >
                  Select Specialty Form
                </option>
                {RenderSelectOptions(specialtyFormsList || [])}
              </CSelect>


              <CLabel className="float-left labels">Note</CLabel>
              <CTextarea
                name="note"
                id="note"
                rows="3"
                placeholder="Notes..."
                innerRef={register}
              />
            </CCol>


          </CFormGroup>
          <SurveyForm
            register={register}
            setValue={setValue}
            watch={watch}
            groups={specialtyForm ? specialtyForm.groups : []}
          />
        </CModalBody>
        <CModalFooter>
          <CInput type="hidden" innerRef={register} name="draft" />
          <CButton type="button" onClick={saveDraft} color="info">
            Save Draft
          </CButton>
          <div ref={ref}>
            <CButton type="submit" color="primary" >
              Submit
            </CButton>
          </div>
          <CButton type="reset" color="danger" onClick={onCancel}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};
export default AnswerForm;
