import React, { useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import {
  CCol,
  CRow,
  CLabel,
  CSelect,
  CButton,
  CCardTitle,
  CInput,
  CFormGroup,
} from "@coreui/react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { fetchQuestions } from "actions/surveys/questions/questions";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const SurveyGroupsSection = ({ control, register }) => {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions.data);

  useEffect(() => {
    dispatch(fetchQuestions());
  }, [dispatch]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "groups",
  });

  const renderSpokesFields = () =>
    fields.map((row, index) => (
      <CCol md="12" key={row.id}>
        <CRow>
          <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
            <CLabel className="labels required">{i18next.t("Group Title")}</CLabel>
            <Controller
              placeholder={i18next.t("Write Group Title")}
              control={control}
              type="text"
              innerRef={register}
              name={`groups[${index}].group_title`}
              as={CInput}
            />
          </CCol>
          <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
            <CLabel className="labels required">{i18next.t("questions")}</CLabel>
            <Controller
              control={control}
              name={`groups[${index}].questions`}
              autocomplete
              isMulti
              options={questions}
              getOptionLabel={(option) => `${option.id}: ${option.question}`}
              getOptionValue={(option) => option.id}
              as={Select}
            />
          </CCol>
          <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
            <CButton
              style={{ marginTop: "10%" }}
              color="danger"
              onClick={() => remove(index)}
            >
              {i18next.t("Remove")}
            </CButton>
          </CCol>
        </CRow>
      </CCol>
    ));

  return (
    <>
      <CCol md="12" style={{ "margin-top": "1.5em" }}>
        <CCardTitle>
          <CFormGroup style={{ "justify-content": "center" }} row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
              <h4 className="text-align-center labels">
                {i18next.t("Question Group")} <CIcon name="cil-arrow-right" size="lg" />
              </h4>
            </CCol>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
              <CButton
                className="text-align-center"
                style={{
                  marginRight: "5px",
                  "background-color": "rgb(48, 197, 182)",
                  "border-color": "rgb(48, 197, 182)",
                  color: "white",
                  width: "9em",
                }}
                onClick={() => append({})}
              >
                {i18next.t("Add")}
              </CButton>
            </CCol>
          </CFormGroup>
        </CCardTitle>
      </CCol>
      {renderSpokesFields()}
    </>
  );
};
export default SurveyGroupsSection;
