import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CLink,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  receiveNotification,
  listNotifications,
  readNotification,
} from "actions/notifications/notifications";
import {
  REQUESTCREATED,
  NOTIFICATION_TYPE_APPOINTMENT,
  NOTIFICATION_TYPE_FOLLOWUP,
  NOTIFICATION_TYPE_REQUEST_CALL,
  NOTIFICATION_TYPE_ROUND,
} from "constants/notifications";

const NotificationsList = ({ notifications }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleNotificationClick = (notificationId, notification) => {
    dispatch(readNotification(notificationId));
    switch (notification.type) {
      case NOTIFICATION_TYPE_REQUEST_CALL:
        history.push({
          pathname: `/episodes/spok/case/${notification.request.episode_id}`,
          state: { openCall: true },
        });
        break;
      case NOTIFICATION_TYPE_APPOINTMENT:
      case NOTIFICATION_TYPE_FOLLOWUP:
        history.push(
          `/patient/appointments/${notification.appointment.id}/show`
        );
        break;
      case NOTIFICATION_TYPE_ROUND:
        history.push(`/rounds/my-rounds/${notification.round.id}/details`);
        break;
      default:
        break;
    }
  };
  return Object.entries(notifications).map(([key, value]) => {
    const notification = JSON.parse(value);
    return (
      <CDropdownItem key={key} onClick={() => handleNotificationClick(key, notification)}>
        <CIcon name="cil-user-follow" className="mr-2 text-success" />
        {notification.message}
      </CDropdownItem>
    );
  });
};

const TheHeaderDropdownNotif = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notifications.notifications || {}
  );
  const loggedInUser = useSelector((state) => {
    return state.auth.jwt ? state.auth.jwt.user.id : null;
  });
  const notificationLength = Object.values(notifications).length;
  useEffect(() => {
    dispatch(listNotifications());
    window.Echo.private(`my-notifications.${loggedInUser}`).notification(
      (notification) => {
        dispatch(receiveNotification(notification));
      }
    );
  }, [dispatch]);

  return (
    <CDropdown inNav className="c-header-nav-item mx-2">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-bell" />
        {notificationLength > 0 && (
          <CBadge shape="pill" color="danger">
            {notificationLength}
          </CBadge>
        )}
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="pt-0">
        <CDropdownItem header tag="div" className="text-center" color="light">
          <strong>You have {notificationLength} Notifications</strong>
        </CDropdownItem>
        <NotificationsList notifications={notifications} />
        <CDropdownItem
          color="light"
          onClick={() => history.push(`/my-notifications`)}
        >
          <strong>All Notifications</strong>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdownNotif;
