import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CPagination,
} from "@coreui/react";
import i18next from "i18next";
import CreateReport from "./CreateReport";
import EditReport from "./EditReport";
import {
  fetchReports,
  deleteReport,
} from "actions/reports/reports";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
const Reports = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [reportId, setReportId] = useState();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/reports?page=${newPage}`);
    }
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchReports(page));
  }, [dispatch, page]);

  const toggle = () => {
    setModal(!modal);
  };
  const EditToggle = () => {
    setEditModal(!editModal);
  };
  const editReport = (id) => {
    setReportId(id);
    EditToggle();
  };

  const handleDeleteReport = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "You Want To Delete This Report.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteReport(id));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };


  return (
    <>
      <CreateReport modal={modal} toggle={toggle} />
      <EditReport modal={editModal} toggle={EditToggle} id={reportId} />
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">
            {i18next.t("reports")}
          </div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            onClick={toggle}
            className="create-button float-right wi"
          >
            {i18next.t("New Report")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={reports.data || []}
                fields={["name", "actions"]}
                hover
                noItemsViewSlot="No Available Items"
                striped
                scopedSlots={{
                  actions: (item) => (
                    <>
                      <td>
                      <a
                           href={`/reports/${item.id}/result`}
                           className="btn btn-primary"
                           onClick={(e) => e.stopPropagation()}
                           style={{
                            marginRight: "5px",
                          }}
                         >
                          {i18next.t("Result")}
                        </a>
                        <a
                          onClick={() => editReport(item.id)}
                          style={{
                            marginRight: "5px",
                            backgroundColor: "rgb(48, 197, 182)",
                            borderColor: "rgb(48, 197, 182)",
                            color: "white",
                            width: "7em",
                          }}
                          className="btn"
                        >
                          {i18next.t("Edit")}
                        </a>

                        <CButton
                          onClick={() => handleDeleteReport(item.id)}
                          style={{ marginRight: "5px" }}
                          className="btn btn-danger"
                        >
                          {i18next.t("Delete")}
                        </CButton>
                      </td>
                    </>
                  ),
                }}
                striped
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={reports.last_page || 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Reports;
