import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CCol,
  CInput,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CInputRadio,
  CLabel,
  CFormGroup,
  CListGroup,
  CListGroupItem,
  CSelect,
} from "@coreui/react";
import { useForm } from "react-hook-form";
import {
  CLINIC_TYPE_PHYSICAL,
  CLINIC_TYPE_VIRTUAL,
  CLINIC_SEARCH_MAX_DAYS,
} from "constants/clinic";
import { getAppointmentUtilities } from "actions/appointments/appointment";
import { fetchCountries } from "actions/cities/cities";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import i18next from "i18next";

const SearchForClinic = () => {
  const { register, watch } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const appointmentUtilities = useSelector(
    (state) => state.appointmentUtilities
  );
  const specialties = appointmentUtilities.specialties
    ? appointmentUtilities.specialties
    : [];

  const countries = useSelector((state) => state.countries);
  useEffect(() => {
    dispatch(getAppointmentUtilities());
    dispatch(fetchCountries());
  }, [dispatch]);

  const onSelectSpecialty = (specialty_id) => {
    // dispatch(clinicSearch({ specialty_id, ...watch() }));
    history.push(
      `/patient/appointment/select?type=${watch("clinic_type")}&from=${watch(
        "from"
      )}&to=${watch("to")}&specialty_id=${specialty_id}&country=${watch(
        "country"
      )}`
    );
  };

  const renderSpecialties = (specialties) => (
    <CCol xs="12" md="3">
      <CLabel className="labels required">{i18next.t("Select Clinic Speciality")}</CLabel>
      {specialties.map((item) => (
        <CListGroupItem
          key={item.id}
          onClick={() => onSelectSpecialty(item.id)}
          style={{"clear":"both"}}
        >
          {item.name}
        </CListGroupItem>
      ))}
    </CCol>
  );

  const getMaxDate = () => {
    const result = watch("from") ? new Date(watch("from")) : new Date();
    result.setDate(result.getDate() + CLINIC_SEARCH_MAX_DAYS);
    return result.toISOString().substr(0, 10);
  };

  return (
    <CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol xs="12">
                <CCardTitle
                  className="header-label-title"
                  style={{
                    fontSize: "x-large",
                    opacity: "1",
                    color: "#35488D",
                    fontWeight: "530",
                    marginTop: "27px",
                  }}
                >
                  {i18next.t("Select Date Range")}
                </CCardTitle>
              </CCol>
              <CCol xs="12" md="12">
                <CCard>
                  <CCardBody>
                    <CRow>
                      <CCol xs="12" md="4">
                        <CLabel className="float-left w-fcontent labels required">
                          {i18next.t("From")}
                        </CLabel>
                        <CInput
                          innerRef={register}
                          type="date"
                          id="from"
                          name="from"
                          defaultValue={new Date().toISOString().substr(0, 10)}
                          min={new Date().toISOString().substr(0, 10)}
                        />
                      </CCol>
                      <CCol xs="12" md="4">
                        <CLabel className="float-left w-fcontent labels required">
                          {i18next.t("To")}
                        </CLabel>
                        <CInput
                          innerRef={register}
                          type="date"
                          id="to"
                          name="to"
                          defaultValue={watch("from")}
                          min={watch("from")}
                          max={getMaxDate()}
                        />
                      </CCol>

                      <CCol xs="12" md="4">
                        <CLabel className="float-left w-fcontent labels required">
                          {i18next.t("Country")}
                        </CLabel>
                        <CSelect
                          custom
                          name="country"
                          id="country"
                          innerRef={register}
                        >
                          <option key="0" selected disabled>
                            {i18next.t("Select Country")}
                          </option>
                          {RenderSelectOptions(countries.data || [])}
                        </CSelect>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        {/* <CInput
                          innerRef={register}
                          type="date"
                          id="from"
                          name="from"
                          defaultValue={new Date().toISOString().substr(0, 10)}
                          min={new Date().toISOString().substr(0, 10)}
                        /> */}
                      </CCol>
                      <CCol>
                        {/* <CInput
                          innerRef={register}
                          type="date"
                          id="to"
                          name="to"
                          defaultValue={watch("from")}
                          min={watch("from")}
                          max={getMaxDate()}
                        /> */}
                      </CCol>
                      <CCol>
                        {/* <CSelect
                          custom
                          name="country"
                          id="country"
                          innerRef={register}
                        >
                          <option key="0" selected disabled>
                            {i18next.t("Select Country")}
                          </option>
                          {RenderSelectOptions(countries.data || [])}
                        </CSelect> */}
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CCardTitle
                  className="header-label-title"
                  style={{
                    fontSize: "x-large",
                    opacity: "1",
                    color: "#35488D",
                    fontWeight: "530",
                    marginTop: "27px",
                    marginBottom: "27px",
                  }}
                >
                  {i18next.t("Select Speciality")}
                </CCardTitle>
              </CCol>
              <CCol xs="12" md="12">
                <CCard>
                  <CCardBody>
                    <CRow>
                      <CCol xs="12" md="3">
                        <CLabel className="labels required">
                          {i18next.t("Select Clinic Type")}
                        </CLabel>
                      </CCol>
                      <CCol xs="3" md="3">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio
                            custom
                            innerRef={register}
                            id="inline-radio3"
                            name="clinic_type"
                            value="0"
                            defaultChecked
                          />
                          <CLabel
                            variant="custom-checkbox"
                            htmlFor="inline-radio3"
                            className="labels"
                          >
                            {i18next.t("All")}
                          </CLabel>
                        </CFormGroup>
                      </CCol>
                      <CCol xs="3" md="3">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio
                            custom
                            innerRef={register}
                            id="inline-radio1"
                            name="clinic_type"
                            value={CLINIC_TYPE_VIRTUAL}
                          />
                          <CLabel
                            className="labels"
                            variant="custom-checkbox"
                            htmlFor="inline-radio1"
                          >
                            {i18next.t("Virtual")}
                          </CLabel>
                        </CFormGroup>
                      </CCol>
                      <CCol xs="3" md="3">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio
                            custom
                            innerRef={register}
                            id="inline-radio2"
                            name="clinic_type"
                            value={CLINIC_TYPE_PHYSICAL}
                          />
                          <CLabel
                            className="labels"
                            variant="custom-checkbox"
                            htmlFor="inline-radio2"
                          >
                            {i18next.t("Physical")}
                          </CLabel>
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    <br />
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol xs="12" md="12">
                <CCard>
                  <CCardBody>
                    <CRow className="justify-content-center">
                      <CCol xs="12" md="12">
                        <CListGroup>
                          {renderSpecialties(specialties)}
                        </CListGroup>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default SearchForClinic;
