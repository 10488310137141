import React, { useEffect, useState } from "react";
import find from "lodash/find";
import { useForm } from "react-hook-form";
import {
  CCol,
  CContainer,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CCard,
  CCardBody,
  CSelect,
  CButton,
} from "@coreui/react";
import { getClassName } from "views/pages/register/RegisterFormValidation";
import getValidation from "views/clinics/form/ClinicFormValidation";
import i18next from "i18next";

const AppointmentForm = (props) => {
  const [state, setState] = useState({});
  const {
    appointment,
    specialties,
    doctors,
    clinics,
    slots,
    onSelectSpecialty,
    onSelectDoctor,
    onSelectClinic,
    onSubmit,
    clinic_type_virtual,
    doctors_error,
    clinics_error,
  } = props;
  const {
    register,
    handleSubmit: reactHockSubmit,
    watch,
    errors,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: appointment,
  });
  const Validation = getValidation(watch);
  useEffect(() => {
    if (appointment) {
      setState({ ...appointment });
    } else {
      setState({
        date: "",
        specialty_id: "",
        doctor_id: "",
        clinic_id: "",
        paid: "",
        price: "",
      });
    }
  }, [appointment]);

  const onDateChange = () => {
    setState({ ...state, date: watch("date") });
    if (Number(watch("specialty_id")) !== 0) {
      onSelectSpecialty(watch("specialty_id"), watch("date"));
    }
    if (Number(watch("doctor_id")) !== 0) {
      onSelectDoctor(watch("doctor_id"), watch("date"));
    }
  };

  const Specialties = () =>
    specialties.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));

  const DoctorsList = () =>
    doctors.map((item) => (
      <option key={item.id} value={item.id}>
        {item.fullName}
      </option>
    ));

  const ClinicList = () =>
    clinics.map((item) => (
      <option key={item.id} value={item.id}>
        {item.title}
      </option>
    ));

  const renderClinicDetails = () => {
    const temp = watch("clinic_id") ? watch("clinic_id") : 0;
    const clinic = find(clinics, { id: parseInt(temp, 10) });
    if (clinic) {
      if (clinic.type === clinic_type_virtual) {
        return (
          <>
            <CFormGroup row style={{ marginTop: "1.5em" }}>
              <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
                <CLabel className="float-left">
                  {i18next.t("Clinic Type")} : {clinic.type}
                </CLabel>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
                <CLabel className="float-left">
                  {i18next.t("Follow Up Price")} : {clinic.follow_up_price}
                </CLabel>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
                <CLabel className="float-left">{i18next.t("Slots")} : </CLabel>
                {slots.length !== 0 ? (
                  <CSelect
                    custom
                    onChange={() =>
                      setState({ ...state, slot: watch("schedule_time") })
                    }
                    innerRef={register({ required: "slot is required" })}
                    id="schedule_time"
                    name="schedule_time"
                  >
                    {Object.keys(slots).map((item) => (
                      <option key={item} value={item}>
                        {slots[item]}
                      </option>
                    ))}
                  </CSelect>
                ) : (
                  <h4 className="text-align-center">{i18next.t("No Available Slots")}</h4>
                )}
              </CCol>
            </CFormGroup>
          </>
        );
      }
      return (
        <>
          <CFormGroup style={{ marginTop: "1.5em" }} row>
            <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
              <CLabel>Clinic Type : {clinic.type}</CLabel>
            </CCol>
            <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
              <CLabel>
                {i18next.t("Address")} : {clinic.address ? clinic.address : "NA"}
              </CLabel>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CLabel className="float-left">{i18next.t("Slots")}</CLabel>
              {slots.length !== 0 ? (
                <CSelect
                  custom
                  onChange={() =>
                    setState({ ...state, slot: watch("schedule_time") })
                  }
                  innerRef={register({ required: "slot is required" })}
                  id="schedule_time"
                  name="schedule_time"
                >
                  {Object.keys(slots).map((item) => (
                    <option key={item} value={item}>
                      {slots[item]}
                    </option>
                  ))}
                </CSelect>
              ) : (
                <h4 className="text-align-center">
                  <strong>{i18next.t("No Available Slots")}</strong>
                </h4>
              )}
            </CCol>
          </CFormGroup>
        </>
      );
    }
    return "";
  };

  return (
    <CContainer>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="table-title">{i18next.t("Add Follow Up Plan")}</div>
        </CCol>
      </CRow>
      <CCol xs="12">
        <CForm onSubmit={reactHockSubmit(onSubmit)} className="form-horizontal">
          <CCard>
            <CCardBody>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                  <CLabel className="labels required">{i18next.t("Select Specialty")}</CLabel>
                  <CSelect
                    className={`${getClassName(
                      "specialty_id",
                      "specialty_id",
                      watch,
                      errors
                    )}`}
                    custom
                    name="specialty_id"
                    id="specialty_id"
                    innerRef={register(Validation.specialty_id)}
                    onChange={() => {
                      setValue("doctor_id", "");
                      setValue("clinic_id", "");
                      setState({
                        ...state,
                        specialty_id: watch("specialty_id"),
                        date: watch("date"),
                      });
                      onSelectSpecialty(watch("specialty_id"), watch("date"));
                    }}
                  >
                    <option key="0" value="" selected disabled>
                      {i18next.t("Select Specialty")}
                    </option>
                    {Specialties()}
                  </CSelect>
                </CCol>
                <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                  <CLabel className="labels required">{i18next.t("date")}</CLabel>
                  <CInput
                    className={`${getClassName("date", "date", watch, errors)}`}
                    value={
                      watch("date")
                        ? watch("date")
                        : new Date().toISOString().substr(0, 10)
                    }
                    onChange={()=>{
                      setValue("doctor_id", "");
                      setValue("clinic_id", "");
                      onDateChange()
                    }}
                    innerRef={register({ required: "Date is required" })}
                    type="date"
                    id="date"
                    name="date"
                    placeholder="date"
                    min={new Date().toISOString().substr(0, 10)}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                  <CLabel className="labels required">{i18next.t("Doctor")}</CLabel>
                  <CSelect
                    className={`${getClassName(
                      "doctor_id",
                      "doctor_id",
                      watch,
                      errors
                    )}`}
                    custom
                    onChange={() => {
                      setValue("clinic_id", "");
                      setState({
                        ...state,
                        doctor_id: watch("doctor_id"),
                        date: watch("date"),
                      });
                      onSelectDoctor(watch("doctor_id"), watch("date"));
                    }}
                    innerRef={register(Validation.doctor_id)}
                    name="doctor_id"
                    id="doctor_id"
                  >
                    {doctors_error ? (
                      <option key="0" value="" selected disabled>
                        {doctors_error}
                      </option>
                    ) : (
                      <>
                        <option key="0" value="" selected disabled>
                          {i18next.t("Select Doctor")}
                        </option>
                        {DoctorsList()}
                      </>
                    )}
                  </CSelect>
                </CCol>
                <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                  <CLabel className="labels required">{i18next.t("Clinic")}</CLabel>
                  <CSelect
                    className={`${getClassName(
                      "clinic_id",
                      "clinic_id",
                      watch,
                      errors
                    )}`}
                    custom
                    onChange={() => {
                      setState({
                        ...state,
                        clinic_id: watch("clinic_id"),
                        date: watch("date"),
                      });
                      onSelectClinic(watch("clinic_id"), watch("date"));
                    }}
                    innerRef={register(Validation.specialty_id)}
                    name="clinic_id"
                    id="clinic_id"
                  >
                   {clinics_error ? (
                      <option key="0" value="" selected disabled>
                        {clinics_error}
                      </option>
                    ) : (
                      <>
                        <option key="0" value="" selected disabled>
                          {i18next.t("Select Clinic")}
                        </option>
                        {ClinicList()}
                      </>
                    )}
                  </CSelect>
                  {!clinics_error &&(
                  renderClinicDetails())}
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                  <CLabel className="labels required">{i18next.t("Payment")}</CLabel>
                  <CSelect
                    className={`${getClassName(
                      "payment_type",
                      "payment_type",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.require)}
                    custom
                    onChange={() =>
                      setState({
                        ...state,
                        payment_type: watch("payment_type"),
                      })
                    }
                    name="payment_type"
                    id="payment_type"
                  >
                    <option key="paid" value="1">
                      {i18next.t("Paid")}
                    </option>
                    <option key="free" value="2">
                      {i18next.t("Free")}
                    </option>
                  </CSelect>
                </CCol>
                <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                  {watch("payment_type") === "1" && (
                    <>
                      <CLabel className="labels required">{i18next.t("Price")}</CLabel>
                      <CInput
                        className={`${getClassName(
                          "price",
                          "price",
                          watch,
                          errors
                        )}`}
                        min={0}
                        type="number"
                        onChange={() =>
                          setState({ ...state, price: watch("price") })
                        }
                        id="price"
                        name="price"
                        innerRef={register(Validation.follow_up_price)}
                        placeholder={i18next.t("Follow Up Price")}
                      />
                    </>
                  )}
                </CCol>
              </CFormGroup>
            </CCardBody>
            <div className="my-2 float-right" style={{ "text-align": "end" }}>
              <CButton type="submit" color="primary" className="my-0 mx-1">
                {i18next.t("Next")}
              </CButton>
              <CButton
                color="danger"
                className="my-0 mx-1"
                onClick={() => props.onCancel()}
              >
                {i18next.t("Cancel")}
              </CButton>
            </div>
          </CCard>
        </CForm>
      </CCol>
    </CContainer>
  );
};

export default AppointmentForm;
