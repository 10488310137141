import React, {useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { editMatchedPatientSurvey} from "actions/patients/patients";
import { fetchFollowUp } from "actions/follow-ups/followUps";
import { useParams,useHistory } from "react-router-dom";
import EditSurveyForm from "./form/EditForm";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import i18next from "i18next";

const EditMatchedSurveyInstance = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const surveyInstanceId = params.id;
  const surveyInstance = useSelector((state) => state.followUps.followUp);
  const onSubmit = (formData) => {
    const data = {
      ...formData,
    };
    if (!data.draft) {
      confirmAlert({
        title: "Are you sure?",
        message: "that form is ready to be submitted?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(editMatchedPatientSurvey(data, surveyInstanceId));
              history.push(`/matched-sent-surveys`);

            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      dispatch(editMatchedPatientSurvey(data, surveyInstanceId));
      history.push(`/matched-sent-surveys`);
    }
  };
  useEffect(() => {
    if (surveyInstanceId) {
      dispatch(fetchFollowUp(surveyInstanceId));
    }
  }, [dispatch, surveyInstanceId]);

  const onCancel = () => {
    history.push("/matched-sent-surveys");
  };


  return (
    <EditSurveyForm
      onSubmit={onSubmit}
      surveyInstance={surveyInstance}
      FormTitle={i18next.t("Edit")}
      onCancel={onCancel}
    />
  );
};
export default EditMatchedSurveyInstance;