import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCardSubtitle,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CPagination,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilAccountLogout,
  cilCalendar,
  cilLocationPin,
  cilMenu,
  cilOptions,
  cilPin,
  cilScreenDesktop,
  cilPencil,
  cilTrash,
} from "@coreui/icons";

import { fetchRounds, deleteRound } from "actions/rounds/rounds";
import i18next from "i18next";
import { LIMIT } from "constants/constants";

const ListRounds = () => {
  const dispatch = useDispatch();
  const rounds = useSelector((state) => state.rounds.data);

  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const meta = useSelector((state) => state.rounds.meta);
  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/rounds?page=${newPage}`);
    }
  };
  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
    dispatch(fetchRounds(page, LIMIT));
  }, [currentPage, page]);

  const handleDeleteRound = (e, id) => {
    e.stopPropagation();
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This Round.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteRound(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">
            {i18next.t("rounds")}
          </div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            href="/rounds/create"
            className="create-button float-right wi"
          >
            {i18next.t("New Round")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={rounds || []}
                hover
                striped
                noItemsViewSlot={i18next.t("No Available Items")}
                fields={[
                  {key:"title",label:i18next.t("title")},
                  {key:"organizerName",label:i18next.t("Organizer Name")},
                  {key:"specialtyName",label:i18next.t("Specialty Name")},
                  {key:"type",label:i18next.t("Type")},
                  {key:"date",label:i18next.t("date")},
                  {key:"from",label:i18next.t("From")},
                  {key:"to",label:i18next.t("To")},
                  {key:"statusText",label:i18next.t("Status Text")},
                  {key:"actions",label:i18next.t("Actions")},
                ]}
                scopedSlots={{
                  from: (item) => (
                    <td>
                      {`${+item?.from?.split(":")[0]}` < 12
                        ? `${item?.from?.split(":")[0] % 12}:${
                            item?.from?.split(":")[1]
                          } ${i18next.t("AM")}`
                        : `${item?.from?.split(":")[0] % 12}:${
                            item?.from?.split(":")[1]
                          } ${i18next.t("PM")}`}
                    </td>
                  ),
                  to: (item) => (
                    <td>
                      {`${+item?.to?.split(":")[0]}` < 12
                        ? `${item?.to?.split(":")[0] % 12}:${
                            item?.to?.split(":")[1]
                          } ${i18next.t("AM")}`
                        : `${item?.to?.split(":")[0] % 12}:${
                            item?.to?.split(":")[1]
                          } ${i18next.t("PM")}`}
                    </td>
                  ),
                  actions: (item) => (
                    <td>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle
                          className="action-toggle m-0"
                          color="light"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <CIcon content={cilOptions} />
                        </CDropdownToggle>
                        <CDropdownMenu className="p-0">
                          <CDropdownItem
                            href={`/rounds/edit/${item.id}`}
                            className="text-primary"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <CIcon content={cilPencil} />
                            <span className="mx-1">{i18next.t("Edit")}</span>
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={(e) => handleDeleteRound(e, item.id)}
                            className="text-danger"
                          >
                            <CIcon content={cilTrash} />
                            <span className="mx-1">{i18next.t("Delete")}</span>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default ListRounds;
