const UpcomingAppointments = [
  {
    name: "Mohamed Mahmoud",
    speciality: "Neurology",
    type: "virtual",
    time: "From 5pm To 10pm",
    date: 17,
  },
  {
    name: "Ahmed Ali",
    speciality: "Dermatology",
    type: "physical",
    time: "From 6pm To 8pm",
    date: 18,
  },
  {
    name: "Ali Mostafa",
    speciality: "Ophthalmology",
    type: "virtuak",
    time: "From 8pm To 10pm",
    date: 22,
  },
];
const PastAppointments = [
  {
    name: "Hassan Mohamed",
    speciality: "Dermatology",
    type: "virtual",
    time: "From 5pm To 10pm",
    date: 29,
  },
  {
    name: "Ahmed Mahmoud",
    speciality: "Neurology",
    type: "physical",
    time: "From 6pm To 8pm",
    date: 11,
  },
];
const MissingAppointments = [
  {
    name: "Mostafa Abas",
    speciality: "Ophthalmology",
    type: "virtual",
    time: "From 2pm To 6pm",
    date: 21,
  },
];

const ClinicSpecialties = [
  { id: 1, title: "Neurology" },
  { id: 2, title: "Dermatology" },
  { id: 3, title: "Ophthalmology" },
];

const Appointments = [
  {
    name: "Mohamed Mahmoud",
    speciality: "Neurology",
    type: "virtual",
    from: "12/8/2020",
    to: "20/8/200",
  },
  {
    name: "Ahmed Moahmed",
    speciality: "Dermatology",
    type: "physical",
    from: "15/8/2020",
    to: "1/9/2020",
  },
  {
    name: "Ali Mostafa",
    speciality: "Ophthalmology",
    type: "virtuak",
    from: "2/9/2020",
    to: "23/9/2020",
  },
];
const AppointmentData = {
  name: "Mohamed",
  speciality: "Neurology",
  type: "virtual",
  time: " 5pm To 10pm",
  date: "20/8/200",
  price: "100LE",
  day: "18",
};

const Slots = [
  { id: 1, name: "slot 1", time: "From 5pm To 5.30pm" },
  { id: 2, name: "slot 2", time: "From 5.30pm To 6pm" },
  { id: 3, name: "slot 3", time: "From 6pm To 6.30pm" },
];

const Clinics = [
  { type: "virtual", time: " 5pm To 9pm", date: "20/8/200", price: "100LE" },
  { type: "physical", time: " 6pm To 10pm", date: "21/8/200", price: "100LE" },
  { type: "virtual", time: " 8pm To 11pm", date: "22/8/200", price: "100LE" },
  { type: "physical", time: " 4pm To 8pm", date: "23/8/200", price: "100LE" },
];

const Messages = [
  {
    name: "Patient. Karem Ahmed",
    message: "Hello, I have some questions",
    time: "14/5/2020 05:24:22",
  },
  {
    name: "Dr. Mohamed Mahmoud",
    message: "Sure go a head",
    time: "14/5/2020 05:25:00",
  },
  {
    name: "Patient. Karem Ahmed",
    message: "Thanks",
    time: "14/5/2020 05:25:34",
  },
  {
    name: "Dr. Mohamed Mahmoud",
    message: "you are welcome",
    time: "14/5/2020 05:25:55",
  },
];

const Labs = [
  {
    id: 1,
    title: "Diabetes",
    collapse: false,
    date: "2020-10-19",
    result: "110",
  },
  {
    id: 2,
    title: "CBC",
    collapse: false,
    date: "2020-10-19",
    result: "80",
  },
  {
    id: 3,
    title: "Lab Test",
    collapse: false,
    date: "2020-10-20",
    result: "50",
  },
];
const Rads = [
  {
    id: 1,
    title: "MRI",
    collapse: false,
    date: "2020-10-19",
    result: "110",
  },
  {
    id: 2,
    title: "X-Ray",
    collapse: false,
    date: "2020-10-19",
    result: "80",
  },
  {
    id: 3,
    title: "CT",
    collapse: false,
    date: "2020-10-20",
    result: "50",
  },
  {
    id: 4,
    title: "Brain MRI",
    collapse: false,
    date: "2020-10-22",
    result: "60",
  },
];

const RadsList = Rads;

export {
  UpcomingAppointments,
  PastAppointments,
  MissingAppointments,
  ClinicSpecialties,
  Appointments,
  AppointmentData,
  Slots,
  Clinics,
  Messages,
  Labs,
  RadsList,
  Rads,
};
