import React from "react";
import {
  CCol,
  CFormGroup,
  CLabel,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
} from "@coreui/react";
import AsyncSelect from "react-select/async";
import { Api, getTokenHeader } from "services/Api";
import i18next from "i18next";

const ClonePublicSurveyForm = ({
  setClonedPublicSurvey,
  onClonePublicSurvey,
}) => {
  const getSurveysOptions = async (inputValue) => {
    let options = [];
    const url = `/api/admin/public-surveys?title=${inputValue}`;
    options = await Api.get(url, {
      ...getTokenHeader(),
    }).then((response) => response.data.data);
    return options;
  };

  return (
    <CCard>
      <CCardHeader>
        <h4 className="header-label-title">{i18next.t("Clone Public Survey")}</h4>
      </CCardHeader>
      <CCardBody>
        <CFormGroup row>
          <CCol xs={12} sm={12} md={8} lg={8} xl={8}>
            <CLabel className="labels">{i18next.t("Public Survey")}</CLabel>
            <AsyncSelect
              cacheOptions
              name="survey"
              loadOptions={(inputValue) => getSurveysOptions(inputValue)}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.id}
              onChange={(val) => setClonedPublicSurvey(val)}
            />
          </CCol>
          <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
            <CButton
              onClick={onClonePublicSurvey}
              style={{ "margin-top": "2.1em" }}
              color="primary"
            >
              {i18next.t("Clone")}
            </CButton>
          </CCol>
        </CFormGroup>
      </CCardBody>
    </CCard>
  );
};

export default ClonePublicSurveyForm;
