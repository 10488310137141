import React from 'react'
import {  useEffect } from "react";
import { useParams,useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchMatchedSurvey } from "actions/matched-sent-surveys/matchedSentSurveys";
import { createPatientMatchedSurvey} from "actions/patients/patients";
import PublicSurveyForm from "./form/Form";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import i18next from "i18next";

const AnswerMatchedSurvey = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const listId = params.id;
  const surveyId = params.surveyId;
  
  // FETCH_MATCHED_SURVEYE
  const matchedSurvey = useSelector((state) => state.MatchedSentSurveys.survey);
  useEffect(() => {
    dispatch(fetchMatchedSurvey(surveyId));
  }, [dispatch, surveyId]);
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      survey_id: surveyId,
      specialty_id: matchedSurvey.specialty_id,
      listId: listId,
    };
    if (!data.draft) {
      confirmAlert({
        title: `${i18next.t("Are you sure?")}`,
        message: `${i18next.t("that form is ready to be submitted?")}`,
        buttons: [
          {
            label: `${i18next.t("Yes")}`,
            onClick: () => {
              dispatch(createPatientMatchedSurvey(data));
              history.push(`/matched-sent-surveys`);
            },
          },
          {
            label: `${i18next.t("No")}`,
            onClick: () => {},
          },
        ],
      });
    } else {
          dispatch(createPatientMatchedSurvey(data));
          history.push(`/matched-sent-surveys`);
    }
  };
  const onCancel = () => {
    history.push("/matched-sent-surveys");
  };

  return (
    <PublicSurveyForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      matchedSurvey={matchedSurvey}
      FormTitle={i18next.t("Answer Survey")}
    />
  );
};
export default AnswerMatchedSurvey;