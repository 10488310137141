import React, {useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { editPatientSurvey, fetchPatientsSurveys } from "actions/patients/patients";
import { fetchFollowUp } from "actions/follow-ups/followUps";
import { LIMIT } from "constants/constants";
import {
  CFormGroup,
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CCol,
  CLabel,
  CTextarea,
  CInput,
} from "@coreui/react";
import EditSurveyForm from "./form/EditForm";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import i18next from "i18next";

const EditSurveyInstance = ({ toggle, modal, surveyInstance, surveyInstanceId}) => {
  const dispatch = useDispatch();
  const patient_id = useSelector((state) => state.auth.jwt.user.id);
  const onSubmit = (formData) => {
    const data = {
      ...formData,
    };
    if (!data.draft) {
      confirmAlert({
        title: "Are you sure?",
        message: "that form is ready to be submitted?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(editPatientSurvey(data, surveyInstanceId));
              toggle();
              dispatch(fetchPatientsSurveys(1, LIMIT));
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      dispatch(editPatientSurvey(data, surveyInstanceId));
      toggle();
      dispatch(fetchPatientsSurveys(1, LIMIT));

    }
  };
  useEffect(() => {
    if (surveyInstanceId) {
      dispatch(fetchFollowUp(surveyInstanceId));
    }
  }, [dispatch, surveyInstanceId]);

  const onCancel = () => {
    toggle();
    dispatch(fetchPatientsSurveys(1, LIMIT));
  };


  return (
    <EditSurveyForm
      onSubmit={onSubmit}
      modal={modal}
      surveyInstance={surveyInstance}
      FormTitle={i18next.t("Edit Survey Submission")}
      onCancel={onCancel}
    />
  );
};
export default EditSurveyInstance;