import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CCard, CCardBody } from "@coreui/react";
import { fetchPastLabs } from "actions/past-medical-history/pastMedicalHistory";
import InvestigationsList from "./InvestigationsList";

const PastLabs = ({patientId}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const labs = useSelector((state) =>
    state.pastMedicalHistory.labs ? state.pastMedicalHistory.labs.data : []
  );
  const pages = useSelector((state) =>
    state.pastMedicalHistory.labs ? state.pastMedicalHistory.labs.last_page : 1
  );

  const pageChange = (newPage) => {
    dispatch(fetchPastLabs(patientId, newPage));
    setPage(newPage);
  };
  useEffect(() => {
    dispatch(fetchPastLabs(patientId));
  }, [dispatch, patientId]);

  return (
    <CCard>
      <CCardBody>
        <InvestigationsList
          investigations={labs}
          activePage={page}
          pageChange={pageChange}
          pages={pages}
        />
      </CCardBody>
    </CCard>
  );
};
export default PastLabs;
