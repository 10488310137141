import React from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CRow,
  CCol,
  CCardSubtitle,
  CCollapse,
} from "@coreui/react";

import { AppointmentData } from "../../partials/AppointmentData";
import { AppointmentCardContainer } from "../../../partials/partials";

class Diagnosis extends React.Component {
  constructor(props) {
    super(props);
    const collapse = false;
    this.state = { collapse };
  }

  toggle = (e) => {
    const { collapse: oldCollapse } = this.state;
    const collapse = !oldCollapse;
    this.setState({ collapse });
    e.preventDefault();
  };

  render() {
    const { collapse } = this.state;
    return (
      <AppointmentCardContainer
        title="Diagnosis"
        appointmentData={AppointmentData}
      >
        <CCard style={{ border: "0px" }}>
          <CCardHeader
            className="text-dark bg-light"
            style={{ marginTop: "1%", borderRadius: "10px" }}
          >
            <CRow>
              <CCol xs="10">
                <CCardTitle> {AppointmentData.speciality} </CCardTitle>
              </CCol>
              <CCol xs="2">
                <CCardTitle>
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    onClick={(e) => this.toggle(e)}
                  >
                    <i
                      className={
                        collapse ? "cil-chevron-bottom" : "cil-chevron-right"
                      }
                    />
                  </button>
                </CCardTitle>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCollapse show={collapse}>
            <CCardBody
              className="text-dark bg-secondary"
              style={{
                marginTop: "0.2%",
                paddingTop: "1%",
                borderRadius: "10px",
              }}
            >
              <CRow>
                <CCol md="6">
                  <CCardTitle>{AppointmentData.name}</CCardTitle>
                </CCol>
                <CCol md="4">
                  <CCardSubtitle> {AppointmentData.date} </CCardSubtitle>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">A00.1</CCol>
                <CCol md="12">A00.2</CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CCardTitle>Notes</CCardTitle>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">Notes Notes Notes</CCol>
                <CCol md="12"> Notes Notes Notes</CCol>
              </CRow>
            </CCardBody>
          </CCollapse>
        </CCard>
      </AppointmentCardContainer>
    );
  }
}

export default Diagnosis;
