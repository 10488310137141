import { Api, getTokenHeader } from "services/Api";
import { TYPE_EPISODE } from "constants/constants";
import {
  FETCH_INVESTIGATIONS,
  FETCH_INVESTIGATION,
  CREATE_INVESTIGATION,
  FETCH_CENTER_INVESTIGATIONS,
} from "./types";

const transformData = (data) => (data ? data.map((item) => item.id) : []);
const prepareInvestigationData = (modelId, formData) => {
  const data = new FormData();
  data.append("model_id", modelId);
  data.append("doctor_id", formData.doctor_id);
  data.append("patient_id", formData.patient_id);
  data.append("model_type", formData.model_type);
  data.append("center_type", formData.center_type);
  data.append(
    "investigation_types",
    JSON.stringify(transformData(formData.investigation_types))
  );
  data.append("date", formData.date);
  data.append("order_notes", formData.order_notes);
  data.append("external_investigation", formData.external_investigation || false);
  data.append("execution_notes", formData.execution_notes);
  if (formData.editor_id) {
    data.append("editor_id", formData.editor_id);
  }
  if (formData.external_investigation && formData.attachment) {
    formData.attachment.forEach((file) => {
      data.append("attachment[]", file);
    });
  }
  return data;
};

export const fetchInvestigations = (
  modelId,
  modelType = TYPE_EPISODE
) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/investigations/${modelType}/${modelId}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_INVESTIGATIONS, payload: response.data });
};

export const createInvestigation = (episodeId, formData) => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/episode/investigation`,
    prepareInvestigationData(episodeId, formData),
    getTokenHeader()
  );
  dispatch({ type: CREATE_INVESTIGATION, payload: response.data });
};

export const updateInvestigation = (investigationId, formData) => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/episode/investigation/${investigationId}`,
    prepareInvestigationData(formData.model_id, formData),
    getTokenHeader()
  );
  dispatch({ type: FETCH_INVESTIGATIONS, payload: response.data });
};

export const getCenterInvestigations = (centerType, centerId) => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/investigations/center/${centerType}/${centerId}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_CENTER_INVESTIGATIONS, payload: response.data });
};

export const assignInvestigation = (
  centerType,
  centerId,
  formData
) => async () => {
  await Api.post(
    `/api/admin/investigation/center-assign/${centerType}/${centerId}`,
    formData,
    getTokenHeader()
  );
};

export const executeInvestigation = (investigationId, formData) => async () => {
  await Api.post(
    `/api/admin/investigation/${investigationId}/execute`,
    formData,
    getTokenHeader()
  );
};

export const externalExecuteInvestigation = (investigationId, formData) => async () => {
  await Api.post(
    `/api/admin/investigation/${investigationId}/external-execute`,
    formData,
    getTokenHeader()
  );
};

export const getInvestigation = (investigationId) => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/episode/investigation/${investigationId}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_INVESTIGATION, payload: response.data });
};
