import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CTextarea,
  CInputFile,
  CSwitch,
  CSelect,
  CImg,
  CSpinner,
} from "@coreui/react";
import { TimeZoneSelect } from "views/partials/AsyncSelectComponents";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { useHistory } from "react-router-dom";
import { customStyles } from "views/rounds/form/RoundFormValidation";
import { fetchlogo, fetchagreement } from "actions/hospitals/hospitals";
import { getClassName, getValidation } from "./ServiceProviderValidation";
import i18next from "i18next";

const HospitalForm = ({
  onSubmit,
  hospital,
  getOptions,
  countries,
  lable,
  onCountryChange,
  cities,
  logo,
  agreement,
}) => {
  if (hospital) {
    delete hospital.logo;
    delete hospital.agreement;
  }

  let defaultValues = {};
  if (hospital) {
    const parentHub = hospital.hub
      ? { value: hospital.hub.id, label: hospital.hub.name }
      : {};

    defaultValues = {
      ...hospital,
      parent: parentHub,
    };
  }

  const [check, setCheck] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const logos = useSelector((state) => state.hospitals.logo);
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const agreements = useSelector((state) => state.hospitals.agreement);
  const [logostring, setLogostring] = useState("");
  const [agreementstring, setAgreementstring] = useState("");
  const { register, handleSubmit, control, watch, errors, setValue, formState } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const Validation = getValidation(watch);
  const { isSubmitting } = formState;
  const handleCheckChange = (e) => {
    setCheck(e.target.checked);
  };

  useEffect(() => {
    if (logo) {
      setLogostring(`${process.env.REACT_APP_SERVICE_URL}${logo}`);
      hospital.logo = `${process.env.REACT_APP_SERVICE_URL}${logo}.png`;
      dispatch(fetchlogo(logo));
    }

    if (agreement) {
      setAgreementstring(`${process.env.REACT_APP_SERVICE_URL}${agreement}`);
      hospital.agreement = `${process.env.REACT_APP_SERVICE_URL}${agreement}`;
      dispatch(fetchagreement(agreement));
    }
  }, [logo, agreement, dispatch, hospital?.logo, hospital?.agreement]);

  useEffect(() => {}, [agreements, logos]);

  const renderTimeZones = (time_zones) =>
    time_zones.map((zone, index) => (
      <option key={index} value={zone}>
        {zone}
      </option>
    ));

  const onCancel = () => {
    history.push(`/service/providers/hospitals/`);
  };

  return (
    <CRow>
      <CCol xs="12">
        <CForm
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          className="form-horizontal"
        >
          <CCard>
            <CCardHeader>
              <CCol xs="12">
                <h4 className="header-label-title">{lable}</h4>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CCol xs="12">
                <h4 className="header-label">{i18next.t("General Info")}</h4>
              </CCol>
              <CFormGroup row>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel className="float-left required labels" htmlFor="name">
                    {i18next.t("Name")}
                  </CLabel>
                  <CInput
                    className={`${getClassName("name", "name", watch, errors)}`}
                    type="text"
                    id="name"
                    name="name"
                    placeholder={i18next.t("Enter Hospital Name")}
                    innerRef={register(Validation.name)}
                  />
                </CCol>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel
                    className="float-left required labels"
                    htmlFor="address"
                  >
                    {i18next.t("Address")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "address",
                      "address",
                      watch,
                      errors
                    )}`}
                    type="text"
                    id="address"
                    name="address"
                    placeholder={i18next.t("Enter Hospital Address")}
                    innerRef={register(Validation.name)}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel
                    className="float-left required labels"
                    htmlFor="phone"
                  >
                    {i18next.t("Phone")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "phone",
                      "phone",
                      watch,
                      errors
                    )}`}
                    type="number"
                    id="phone"
                    name="phone"
                    placeholder={i18next.t("Enter Phone Number")}
                    innerRef={register(Validation.phone)}
                  />
                </CCol>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel
                    className="w-fcontent required labels"
                    htmlFor="parent"
                  >
                    {i18next.t("Parent")}
                  </CLabel>
                  <Controller
                    control={control}
                    id="parent"
                    styles={customStyles(!errors.parent)}
                    rules={check ? "" : Validation.name}
                    name="parent"
                    cacheOptions
                    loadOptions={(inputValue) => getOptions(inputValue)}
                    as={AsyncSelect}
                    onInputChange={(val) => {
                      if (val) setValue("parent", null);
                    }}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel
                    className="float-left required labels"
                    htmlFor="country"
                  >
                    {i18next.t("Country")}
                  </CLabel>
                  <CSelect
                    className={`${getClassName(
                      "country",
                      "country",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.name)}
                    custom
                    name="country"
                    id="country"
                    onChange={onCountryChange}
                  >
                    <option key="0" disabled>
                      {i18next.t("Select Country")}
                    </option>
                    {hospital && hospital.countryobj
                      ? RenderSelectOptions([hospital.countryobj])
                      : RenderSelectOptions(countries)}
                  </CSelect>
                </CCol>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel className="float-left labels" htmlFor="city">
                    {i18next.t("City")}
                  </CLabel>
                  <CSelect
                    className={`${getClassName("city", "city", watch, errors)}`}
                    custom
                    name="city"
                    id="city"
                    innerRef={register(Validation.name)}
                  >
                    <option key="0" disabled>
                      {i18next.t("Select City")}
                    </option>

                    {hospital && hospital.cityobj
                      ? RenderSelectOptions([hospital.cityobj])
                      : RenderSelectOptions(cities)}
                  </CSelect>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <TimeZoneSelect
                    label={i18next.t("Time Zone")}
                    control={control}
                    name="time_zone"
                  />
                </CCol>
                <CCol sm={12} md={1} xs={12} lg={1} xl={1} />
                <CCol sm={12} md={1} xs={12} lg={1} xl={1}>
                  <CLabel className="float-left labels" htmlFor="is_hub">
                    {i18next.t("Is Hub")}
                  </CLabel>
                  <CFormGroup inline>
                    <CSwitch
                      onChange={(e) => handleCheckChange(e)}
                      id="is_hub"
                      labelOn="Yes"
                      labelOff="No"
                      name="is_hub"
                      className="mx-3"
                      size="lg"
                      color="primary"
                      shape="pill"
                      innerRef={register}
                    />
                  </CFormGroup>
                </CCol>
                <CCol sm={12} md={1} xs={12} lg={1} xl={1} />
                <CCol sm={12} md={2} xs={12} lg={2} xl={2}>
                  <CLabel
                    className="float-left labels"
                    htmlFor="restrict_episodes"
                  >
                    {i18next.t("Restrict Episodes")}
                  </CLabel>
                  <CSwitch
                    id="restrict_episodes"
                    labelOn="Yes"
                    labelOff="No"
                    name="restrict_episodes"
                    className="mx-3"
                    size="lg"
                    color="primary"
                    shape="pill"
                    innerRef={register}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6} className="my-1">
                  <CLabel className="float-left labels required" htmlFor="logo">
                    {i18next.t("Logo")}
                  </CLabel>
                  {logostring ? (
                    <>
                      <CRow>
                        <CCol>
                          <CImg width={200} height={50} src={logostring} />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CInputFile
                            className={`${getClassName(
                              "logo",
                              "logo",
                              watch,
                              errors
                            )}`}
                            id="custom-file-input1"
                            name="logo"
                            innerRef={register(Validation.file2)}
                          />
                          <CLabel
                            htmlFor="custom-file-input1"
                            variant="custom-file"
                          >
                            {(watch("logo") &&
                              watch("logo")[0] &&
                              watch("logo")[0].name) ||
                              "Choose file..."}
                          </CLabel>
                          <CLabel
                            style={{
                              top: "1em",
                              right: "1em",
                              left: "1em",
                              marginTop: ".9em",
                              marginBottom: ".9em",
                            }}
                          >
                            {errors.logo?.message ? (
                              <strong
                                style={{
                                  color: "red",
                                }}
                              >
                                {i18next.t("The Logo must be a file of type: jpeg, jpg, png, pdf.")}
                              </strong>
                            ) : (
                              <strong>
                                {i18next.t("The Logo must be a file of type: jpeg, jpg, png, pdf.")}
                              </strong>
                            )}
                          </CLabel>
                        </CCol>
                      </CRow>
                    </>
                  ) : (
                    <>
                      <CInputFile
                        className={`${getClassName(
                          "logo",
                          "logo",
                          watch,
                          errors
                        )}`}
                        innerRef={register(Validation.file)}
                        id="custom-file-input1"
                        name="logo"
                      />
                      <CLabel
                        style={{
                          top: "1em",
                          right: "1em",
                          left: "1em",
                          marginTop: ".5em",
                        }}
                        htmlFor="custom-file-input1"
                        variant="custom-file"
                      >
                        {(watch("logo") &&
                          watch("logo")[0] &&
                          watch("logo")[0].name) ||
                          "Choose file..."}
                      </CLabel>

                      {errors.logo?.message ? (
                        <strong style={{ color: "red" }}>
                          & {i18next.t("The Logo must be a file of type: jpeg, jpg, png, pdf.")}
                        </strong>
                      ) : (
                        <strong>
                          {i18next.t("The Logo must be a file of type: jpeg, jpg, png, pdf.")}
                        </strong>
                      )}
                    </>
                  )}
                </CCol>

                <CCol sm={12} md={6} xs={12} lg={6} xl={6} className="my-1">
                  <CLabel
                    className="float-left required labels"
                    htmlFor="agreement"
                  >
                    {i18next.t("Agreement")}
                  </CLabel>
                  {agreementstring ? (
                    <>
                      <CRow>
                        <CCol>
                          <CImg
                            rounded
                            thumbnail
                            width={200}
                            height={50}
                            src={agreementstring}
                          />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CInputFile
                            className={`${getClassName(
                              "agreement",
                              "agreement",
                              watch,
                              errors
                            )}`}
                            // style={{ top: "0" }}
                            id="custom-file-input"
                            name="agreement"
                            innerRef={register(Validation.file2)}
                          />
                          <CLabel
                            // style={{
                            //   top: "1em",
                            //   right: "1em",
                            //   left: "1em",
                            //   marginTop: ".5em",
                            // }}
                            className={`${getClassName(
                              "agreement",
                              "agreement",
                              watch,
                              errors
                            )}`}
                            htmlFor="custom-file-input"
                            variant="custom-file"
                          >
                            {(watch("agreement") &&
                              watch("agreement")[0] &&
                              watch("agreement")[0].name) ||
                              "Choose file..."}
                          </CLabel>
                          <CLabel
                            style={{
                              top: "1em",
                              right: "1em",
                              left: "1em",
                              marginTop: ".9em",
                              marginBottom: ".9em",
                            }}
                          >
                            {errors.agreement?.message ? (
                              <strong style={{ color: "red" }}>
                                {i18next.t("The Agreement must be a file of type: jpeg, jpg,png, pdf.")}
                              </strong>
                            ) : (
                              <strong>
                                {i18next.t("The Agreement must be a file of type: jpeg, jpg, png, pdf.")}
                              </strong>
                            )}
                          </CLabel>
                        </CCol>
                      </CRow>
                    </>
                  ) : (
                    <>
                      <CInputFile
                        className={`${getClassName(
                          "agreement",
                          "agreement",
                          watch,
                          errors
                        )}`}
                        style={{ top: "0" }}
                        id="custom-file-input"
                        name="agreement"
                        innerRef={register(Validation.file)}
                      />
                      <CLabel
                        style={{
                          top: "1em",
                          right: "1em",
                          left: "1em",
                          marginTop: ".5em",
                        }}
                        className={`${getClassName(
                          "agreement",
                          "agreement",
                          watch,
                          errors
                        )}`}
                        htmlFor="custom-file-input"
                        variant="custom-file"
                      >
                        {(watch("agreement") &&
                          watch("agreement")[0] &&
                          watch("agreement")[0].name) ||
                          "Choose file..."}
                      </CLabel>
                      {errors.agreement?.message ? (
                        <strong style={{ color: "red" }}>
                          {i18next.t("The Agreement must be a file of type: jpeg, jpg, png, pdf.")}
                        </strong>
                      ) : (
                        <strong>
                          {i18next.t("The Agreement must be a file of type: jpeg, jpg, png, pdf.")}
                        </strong>
                      )}
                    </>
                  )}
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol sm={12} md={12} xs={12} lg={12} xl={12} className="my-1">
                  <CLabel
                    className="float-left required labels"
                    htmlFor="description"
                  >
                    {i18next.t("Description")}
                  </CLabel>
                  <CTextarea
                    className={`${getClassName(
                      "description",
                      "description",
                      watch,
                      errors
                    )}`}
                    name="description"
                    id="description"
                    rows="9"
                    placeholder={i18next.t("Description")}
                    innerRef={register(Validation.name)}
                  />
                </CCol>
              </CFormGroup>
              <CCol xs="12">
                <h4 className="header-label">Contact Info</h4>
              </CCol>
              <CFormGroup row>
                <CCol sm={12} md={12} xs={12} lg={4} xl={4}>
                  <CLabel
                    className="float-left required labels"
                    htmlFor="c_name"
                  >
                    {i18next.t("Name")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "c_name",
                      "c_name",
                      watch,
                      errors
                    )}`}
                    type="text"
                    id="c_name"
                    name="c_name"
                    placeholder={i18next.t("Enter Name")}
                    innerRef={register(Validation.name)}
                  />
                </CCol>
                <CCol sm={12} md={12} xs={12} lg={4} xl={4}>
                  <CLabel
                    className="float-left required labels"
                    htmlFor="c_phone"
                  >
                    {i18next.t("Phone")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "c_phone",
                      "c_phone",
                      watch,
                      errors
                    )}`}
                    type="number"
                    id="c_phone"
                    name="c_phone"
                    placeholder={i18next.t("Enter Phone Number")}
                    innerRef={register(Validation.phone)}
                  />
                </CCol>
                <CCol sm={12} md={12} xs={12} lg={4} xl={4}>
                  <CLabel
                    className="float-left required labels"
                    htmlFor="c_email"
                  >
                    {i18next.t("Email")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "c_email",
                      "c_email",
                      watch,
                      errors
                    )}`}
                    type="email"
                    id="c_email"
                    name="c_email"
                    placeholder={i18next.t("Enter Email")}
                    autoComplete="email"
                    innerRef={register(Validation.email)}
                  />
                </CCol>
              </CFormGroup>
            </CCardBody>
            <CCardFooter>
              <CFormGroup row>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    type="submit"
                    color="primary"
                    className="mx-1 btn-form"
                    disabled={isSubmitting}
                    >
                 {isSubmitting ? (
                      <>
                        <CSpinner color="primary" size="sm" variant="grow" />
                        <span style={{ color: "dark" }}>Loading...</span>
                      </>
                    ) : (
                      <span>{i18next.t("Save")}</span>
                    )}
                  </CButton>
                </CCol>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  );
};

export default HospitalForm;
