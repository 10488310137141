import { Api, getTokenHeader } from "services/Api";
import {
  CREATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  EDIT_APPOINTMENT,
  FETCH_APPOINTMENT,
  FETCH_APPOINTMENTS,
  APPOINTMENT_UTILITIES,
  CANCEL_APPOINTMENT,
  LIST_CLINIC_APPOINTMENTS,
  APPOINTMENT_UTILITIES_RESET_CLINICS,
  DOCTOR_CLINICS,
} from "./types";

export const fetchAppointments = (filters): Object => async (disptach) => {
  const response = await Api.get(`/api/admin/appointments`, {
    params: filters,
    ...getTokenHeader(),
  });
  disptach({ type: FETCH_APPOINTMENTS, payload: response.data });
};

export const createAppointment = (formValues: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/appointments`,
    formValues,
    getTokenHeader()
  );
  dispatch({ type: CREATE_APPOINTMENT, payload: response.data });
};

export const fetchAppointment = (id: string): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/appointments/${id}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_APPOINTMENT, payload: response.data });
};

export const editAppointment = (
  id: string,
  formValues: Object
): Object => async (dispatch) => {
  const response = await Api.put(
    `/api/admin/appointments/${id}`,
    formValues,
    getTokenHeader()
  );

  dispatch({ type: EDIT_APPOINTMENT, payload: response.data });
};

export const deleteAppointment = (id: string): Object => async (dispatch) => {
  await Api.post(`/api/admin/appointments/${id}`, {}, getTokenHeader());
  dispatch({ type: DELETE_APPOINTMENT, payload: id });
};

export const cancelAppointment = (id: string): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/appointments/${id}/cancel`,
    getTokenHeader()
  );
  dispatch({ type: CANCEL_APPOINTMENT, payload: response.data });
};

export const getAppointmentUtilities = (): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/appointments/utilities`,
    getTokenHeader()
  );
  dispatch({ type: APPOINTMENT_UTILITIES, payload: response });
};

export const fetchSpecialtyDoctors = (specialtyId: string, filters = {}): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/appointments/specialty-doctors/${specialtyId}`,
    {
      ...getTokenHeader(),
      params: filters,
    }
  );
  dispatch({ type: APPOINTMENT_UTILITIES, payload: response });
  dispatch({ type: APPOINTMENT_UTILITIES_RESET_CLINICS });
};

export const fetchDoctorClinics = (doctorId, date): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/appointments/doctor-clinic/${doctorId}/${date}`,
    getTokenHeader()
  );
  dispatch({ type: APPOINTMENT_UTILITIES, payload: response });
};

export const fetchDoctorClinicsSurvey = (doctorId,type): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/appointments/doctor-clinic-survey/${doctorId}/${type}`,
    // `/api/admin/appointments/doctor-clinic-survey/${doctorId}`,
    getTokenHeader()
  );
  console.log(doctorId)
  dispatch({ type: APPOINTMENT_UTILITIES, payload: response });
};

export const fetchClinicSlots = (clinicId, date): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/appointments/clinic-slots/${clinicId}/${date}`,
    getTokenHeader()
  );
  dispatch({ type: APPOINTMENT_UTILITIES, payload: response });
};

export const listClinicAppointments = (clinicId, date): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/clinics/${clinicId}/appointments/${date}`,
    getTokenHeader()
  );
  dispatch({ type: LIST_CLINIC_APPOINTMENTS, payload: response });
};
