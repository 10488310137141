import React from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CTextarea,
} from "@coreui/react";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import i18next from "i18next";

const RejectRequestNote = ({ onSubmit, modal, onCancel }) => {
  const { register, handleSubmit, type, errors, watch } = useForm({
    mode: "onBlur",
  });
  const Validation = getValidation(watch);

  return modal ? (
    <CForm
      onSubmit={handleSubmit(onSubmit)}
      key="form_reject"
      className="form-horizontal"
    >
      <CModal show={modal} size="lg" key={`modal_${type}`}>
        <h4 className="header-label-title">{i18next.t("Reject Note")}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol md="12">
              <CLabel className="labels required">{i18next.t("Note")}</CLabel>
              <CTextarea
                className={`${getClassName("note", "note", watch, errors)}`}
                name="note"
                id="note"
                rows="5"
                placeholder={i18next.t("Notes")}
                innerRef={register(Validation.require)}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
            {i18next.t("Save")}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};
export default RejectRequestNote;
