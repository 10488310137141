import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateAccessToken } from "actions/communications/communications";
import { CCol, CRow, CButton } from "@coreui/react";
import ChatRoom from "./ChatRoom"
import CIcon from "@coreui/icons-react";
import { cilChatBubble } from "@coreui/icons";

const ChatRooms = ({appointments}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.communications.token);
  const lobby = useSelector((state) => state.lobby.lobby);
  const [selectedConversation, setSelectedConversation] = useState(`lobby-${lobby.session_token}`);
  const [participants, setParticipants] = useState([]);
  const [showChat, setShowChat] = useState(false);

  const getParticipants = () => {
    let participants = [];
    if(appointments)
    for (var appointment of appointments) {
      participants = [...participants, appointment.patient.uuid]
    }
    return participants;
  }

  const defaultConversationData = () => {
    setSelectedConversation(`lobby-${lobby.session_token}`);
    setParticipants(getParticipants());
  }

  const conversationData = (appointment) => {
    setSelectedConversation(`appointment-${appointment.id}`)
    setParticipants([appointment.patient.uuid]);
  }

  const DefaultConversation = () => {
    return( 
      <CCol>
        <CButton
          key="default-channel"
          id="default-channel"
          onClick={defaultConversationData}
        >
          Lobby
        </CButton>
      </CCol>
    );
  };

  const RenderConversations = () => {
		if (!appointments) return "";
    return appointments.map((appointment, index) => (
      <CCol>
        <CButton
          key={index}
          id={index}
          onClick={() => conversationData(appointment)}
        >
          {appointment.patient.first_name + " " + appointment.patient.last_name}
        </CButton>
      </CCol>
    ));
  };


  useEffect(() => {
    dispatch(generateAccessToken());
    setParticipants(getParticipants());
  }, [dispatch]);

  if (token) {
    return (
      <>
        <div className="lobby-chat">
          <div className={["lobby-chat-messages", showChat ? "d-block" : "d-none"].join(" ")}>
            <CRow>
              <CCol xs={4}>
                <DefaultConversation />
                <RenderConversations />
              </CCol>
              <CCol xs={8}>
                  <ChatRoom token={token} room={selectedConversation} lobby_id={lobby.id} participants={participants} />
              </CCol>
            </CRow>
          </div>

          <CRow className="justify-content-end">
            <CButton className="lobby-chat-btn" onClick={() => setShowChat(!showChat)}>
              <CIcon className="lobby-chat-icon" content={cilChatBubble} />
              Chat
            </CButton>
          </CRow>

        </div>
      </>
    );
  }
  return <></>;
};
export default ChatRooms;
