import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CSpinner } from "@coreui/react";
import { Api, getTokenHeader } from "services/Api";
import {
  fetchHospital,
  updateHospital,
} from "actions/hospitals/hospitals";
import { fetchCountries, fetchCountryCities } from "actions/cities/cities";
import { TIME_ZONES } from "constants/constants";

import { logo } from "assets/icons/logo";
import HospitalForm from "../form/HospitalForm";
import i18next from "i18next";

const EditHospital = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.hospitals.hospital);

  const countries = useSelector((state) => state.countries);
  const cities = useSelector((state) => state.cities);
  const redirect = useSelector((state) => state.hospitals.redirect);
  const lable = `${i18next.t("Edit Location")}`;

  const onSubmit = (formData) => {
    const preparedData = {
      ...formData,
      time_zone: formData.time_zone.value
        ? formData.time_zone.value
        : formData.time_zone,
    };
    dispatch(updateHospital(preparedData, match.params.id));
  };

  const getOptions = async (inputValue) => {
    let options = [];
    const url = `/api/admin/hospitals?q=${inputValue}&is_hub=1`;
    options = await Api.get(url, {
      ...getTokenHeader(),
    }).then((response) =>
      response.data.data.map((item) => ({ value: item.id, label: item.name }))
    );
    return options;
  };

  const onCountryChange = (e) => {
    dispatch(fetchCountryCities(e.target.value));
  };

  useEffect(() => {
    dispatch(fetchHospital(match.params.id));
    dispatch(fetchCountries());
  }, [dispatch, match.params.id]);


  if (redirect) {
    history.push("/service/providers/hospitals");
  }

  if (hospital) {
    return (
      <HospitalForm
        onSubmit={onSubmit}
        lable={lable}
        hospital={hospital}
        getOptions={getOptions}
        countries={countries.data || []}
        cities={cities.data || []}
        onCountryChange={onCountryChange}
        logo={hospital.logo || ""}
        agreement={hospital.agreement || ""}
        time_zones={TIME_ZONES}
      />
    );
  }
  return <CSpinner className="spinner" variant="grow" />;
};

export default EditHospital;
