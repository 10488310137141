import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { fetchQuestion } from "actions/surveys/questions/questions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "views/partials/Loading";
import i18next from "i18next";

/**
 * @param props
 * @param props.match
 */
function ShowQuestion({ match }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const question = useSelector((state) => state.questions.question);
  const redirect = useSelector((state) => state.questions.redirect);
  if (redirect) {
    history.push("/questions");
  }
  useEffect(() => {
    dispatch(fetchQuestion(match.params.id));
  }, [dispatch, match.params.id]);

  if (question) {
    return (
      <CCard>
        <CCardHeader>
          <h4 className="table-title">{i18next.t("Question Details")}</h4>
        </CCardHeader>
        <CCardBody>
          <table className="table table-striped table-hover">
            <tbody>
              <tr>
                <td className="labels">{i18next.t("Question Title")}</td>
                <td>
                  <strong>{question.question}</strong>
                </td>
              </tr>
              {question?.answers ? (
                <>
                  <td className="labels">{i18next.t("Question Answers")}</td>
                  <td className="labels">{i18next.t("Label")}</td>
                  <td className="labels">{i18next.t("Value")}</td>

                  {Object.values(question.answers).map((answers) => (
                    <tr>
                      <td></td>
                      <td>
                        <strong>{answers.label}</strong>
                      </td>
                      <td>
                        <strong>{answers.value}</strong>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td className="labels">{i18next.t("Question Answers")}</td>
                  <td className="text-align-center not-found">
                    <strong>{i18next.t("No Answers Yet")}</strong>
                  </td>
                </tr>
              )}
              {question?.children.length !== 0 ? (
                <>
                  <td className="labels">{i18next.t("Sub Questions")}</td>
                  {question?.children.map((child) => (
                    <>
                      <tr>
                        <td>
                          <strong>{child?.question}</strong>
                        </td>
                        {child?.answers?.map((label) => (
                          <tr>
                            <td>{i18next.t("Label")} : {label?.label}</td>
                            <td>{i18next.t("Value")} : {label?.value}</td>
                          </tr>
                        ))}
                      </tr>
                    </>
                  ))}
                </>
              ) : (
                <tr>
                  <td className="labels">{i18next.t("Sub Questions")}</td>
                  <td className="text-align-center not-found">
                    <strong>{i18next.t("No Sub Questions")}</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CCardBody>
      </CCard>
    );
  }
  return <Loading />;
}
export default ShowQuestion;
