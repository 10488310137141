import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import i18next from "i18next";

import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CInputFile,
  CInputRadio,
  CRow,
  CInputCheckbox,
  CForm,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CButton,
  CImg,
  CSpinner,
} from "@coreui/react";
import generator from "generate-password";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { TimeZoneSelect } from "views/partials/AsyncSelectComponents";
import { useHistory } from "react-router-dom";
import getValidation from "views/episode-management/screens/spoke/cases/forms/EpisodeFormValidation";
import { getClassName } from "views/hospitals/form/ServiceProviderValidation";

const ProfileForm = ({
  onSubmit,
  user,
  label,
  countries,
  onCountryChange,
  cities,
  avatar,
  uuidimg,
}) => {
  const autoGeneratePassword = (e) => {
    const password = e.target.checked
      ? generator.generate({ length: 10, numbers: true })
      : "";
    setValue("password", password);
  };

  const history = useHistory();
  const [logostring, setLogostring] = useState("");
  const [uuidimgstring, setuuidimgstring] = useState("");
  const { register, handleSubmit, control, setValue, watch, errors, formState } = useForm({
    mode: "onBlur",
    defaultValues: { ...user, avatar: "" },
  });
  const Validation = getValidation(watch);
  const { isSubmitting } = formState;
  const onCancel = () => {
    history.push("/");
  };

  useEffect(() => {
    if (avatar) {
      setLogostring(avatar);
    }

    if (uuidimg) {
      setuuidimgstring(uuidimg);
    }
  }, [avatar, uuidimg]);

  useEffect(() => {}, [logostring, uuidimgstring]);

  return (
    <CRow>
      <CCol xs="12">
        <CForm
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          className="form-horizontal"
          encType="multipart/form-data"
        >
          <CCard>
            <CCardHeader>
              <h4 className="header-label-title">{label}</h4>
            </CCardHeader>
            <CCardBody>
              <CCol xs="12">
                <h4 className="header-label">{i18next.t("General Info")}</h4>
              </CCol>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel className="labels required" htmlFor="first_name">
                    {i18next.t("First Name")}
                  </CLabel>
                  <CInput
                    placeholder={i18next.t("Enter Your First Name")}
                    type="text"
                    id="first_name"
                    name="first_name"
                    className={`${getClassName(
                      "first_name",
                      "first_name",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.first_name)}
                  />
                </CCol>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel htmlFor="last_name" className="labels required">
                    {i18next.t("Last Name")}
                  </CLabel>
                  <CInput
                    type="text"
                    id="last_name"
                    name="last_name"
                    className={`${getClassName(
                      "last_name",
                      "last_name",
                      watch,
                      errors
                    )}`}
                    placeholder={i18next.t("Enter Your Last Name")}
                    innerRef={register(Validation.last_name)}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel htmlFor="email" className="labels required">
                    {i18next.t("Email")}
                  </CLabel>
                  <CInput
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email"
                    autoComplete="email"
                    className={`${getClassName(
                      "email",
                      "email",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.email)}
                  />
                </CCol>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel htmlFor="uuid" className="labels required">
                    {i18next.t("National ID")}
                  </CLabel>
                  <CInput
                    type="text"
                    id="uuid"
                    name="uuid"
                    className={`${getClassName("uuid", "uuid", watch, errors)}`}
                    innerRef={register(Validation.uuid)}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel className="labels required">{i18next.t("Mobile No")}.</CLabel>
                  <CInput
                    type="tel"
                    id="phone"
                    name="phone"
                    className={`${getClassName(
                      "phone",
                      "phone",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.hospital_id)}
                  />
                </CCol>
                <CCol
                  style={{ "margin-top": "1em" }}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <CRow>
                    <CCol xs="12">
                      <CLabel className="labels required">{i18next.t("Gender")}</CLabel>
                    </CCol>
                  </CRow>
                  <CFormGroup
                    style={{ "margin-left": "3.5em" }}
                    variant="custom-radio"
                    inline
                    className="float-left"
                  >
                    <CInputRadio
                      custom
                      id="inline-radio1"
                      name="gender"
                      value="male"
                      className={`${getClassName(
                        "gender",
                        "gender",
                        watch,
                        errors
                      )}`}
                      innerRef={register(Validation.gender)}
                    />
                    <CLabel variant="custom-checkbox" htmlFor="inline-radio1">
                      {i18next.t("Male")}
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup
                    style={{ "margin-left": "3.5em" }}
                    variant="custom-radio"
                    inline
                    className="float-left"
                  >
                    <CInputRadio
                      custom
                      id="inline-radio2"
                      name="gender"
                      value="female"
                      className={`${getClassName(
                        "gender",
                        "gender",
                        watch,
                        errors
                      )}`}
                      innerRef={register(Validation.gender)}
                    />
                    <CLabel variant="custom-checkbox" htmlFor="inline-radio2">
                      {i18next.t("Female")}
                    </CLabel>
                  </CFormGroup>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel htmlFor="password" className="labels">
                    {i18next.t("Password")}
                  </CLabel>
                  <CInput
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Write Your Password"
                    className={`${getClassName(
                      "password",
                      "password",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.password)}
                  />
                </CCol>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CFormGroup
                    style={{ "margin-left": "3.5em" }}
                    variant="custom-checkbox"
                    inline
                  >
                    <CInputCheckbox
                      custom
                      id="auto-generate-password"
                      name="auto-generate-password"
                      onChange={autoGeneratePassword}
                    />
                    <CLabel
                      style={{ "margin-top": " 2.6em" }}
                      className="labels"
                      variant="custom-checkbox"
                      htmlFor="auto-generate-password"
                    >
                      {i18next.t("Auto Generate")}
                    </CLabel>
                  </CFormGroup>
                </CCol>
              </CFormGroup>
              <CCol xs="12">
                <h4 className="header-label">{i18next.t("Contact Info")}</h4>
              </CCol>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel className="labels required" htmlFor="country">
                    {i18next.t("Country")}
                  </CLabel>
                  <CSelect
                    custom
                    name="country"
                    id="country"
                    onChange={onCountryChange}
                    className={`${getClassName(
                      "country",
                      "country",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.bed)}
                  >
                    <option key="0" value="" disabled>
                      {i18next.t("Select Country")}
                    </option>
                    {user && user.countryobj
                      ? RenderSelectOptions(countries, user.countryobj)
                      : RenderSelectOptions(countries)}
                  </CSelect>
                </CCol>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel htmlFor="city" className="labels required">
                    {i18next.t("City")}
                  </CLabel>
                  <CSelect
                    className={`${getClassName("city", "city", watch, errors)}`}
                    innerRef={register(Validation.bed)}
                    custom
                    name="city"
                    id="city"
                  >
                    <option key="0" value="" disabled>
                      {i18next.t("Select City")}
                    </option>
                    {user && user.cityobj
                      ? RenderSelectOptions(cities, user.cityobj)
                      : RenderSelectOptions(cities)}
                  </CSelect>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel htmlFor="address" className="labels required">
                    {i18next.t("Address")}
                  </CLabel>
                  <CInput
                    type="text"
                    id="address"
                    name="address"
                    className={`${getClassName(
                      "address",
                      "address",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.bed)}
                  />
                </CCol>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <TimeZoneSelect
                    label={i18next.t("Time Zone")}
                    control={control}
                    rules={Validation.time_zone}
                    isValid={!errors.time_zone}
                    name="time_zone"
                    defaultValue={user.time_zone}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
                  <CLabel
                    htmlFor="uuid_picture"
                    className="float-left labels required"
                  >
                    {i18next.t("ID Scan")}
                  </CLabel>
                  {uuidimgstring ? (
                    <>
                      <CRow>
                        <CCol>
                          <CImg width={200} height={50} src={uuidimgstring} />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CInputFile
                            className={`${getClassName(
                              "uuid_picture",
                              "uuid_picture",
                              watch,
                              errors
                            )}`}
                            style={{ top: "0" }}
                            id="uuid_picture"
                            class="form-control file-upload-button"
                            name="uuid_picture"
                            innerRef={register(Validation.file2)}
                          />
                          <CLabel
                            className={`${getClassName(
                              "uuid_picture",
                              "uuid_picture",
                              watch,
                              errors
                            )}`}
                            htmlFor="uuid_picture"
                            variant="custom-file"
                          >
                            {(watch("uuid_picture") &&
                              watch("uuid_picture")[0] &&
                              watch("uuid_picture")[0].name) ||
                              "Choose file..."}
                          </CLabel>
                          <CLabel
                            style={{
                              top: "1em",
                              right: "1em",
                              left: "1em",
                              marginTop: ".9em",
                              marginBottom: ".9em",
                            }}
                          >
                            {errors.uuid_picture?.message ? (
                              <strong style={{ color: "red" }}>
                                {i18next.t("The ID Scan must be a file of type image: jpeg, jpg, png.")}
                              </strong>
                            ) : (
                              <strong>
                                {i18next.t("The ID Scan must be a file of type image: jpeg, jpg, png.")}
                              </strong>
                            )}
                          </CLabel>
                        </CCol>
                      </CRow>
                    </>
                  ) : (
                    <>
                      <CInputFile
                        className={`${getClassName(
                          "uuid_picture",
                          "uuid_picture",
                          watch,
                          errors
                        )}`}
                        style={{ top: "0" }}
                        id="uuid_picture"
                        class="form-control file-upload-button"
                        name="uuid_picture"
                        innerRef={register(Validation.file)}
                      />
                      <CLabel
                        style={{
                          top: "1em",
                          right: "1em",
                          left: "1em",
                          marginTop: ".5em",
                        }}
                        className={`${getClassName(
                          "uuid_picture",
                          "uuid_picture",
                          watch,
                          errors
                        )}`}
                        htmlFor="uuid_picture"
                        variant="custom-file"
                      >
                        {(watch("uuid_picture") &&
                          watch("uuid_picture")[0] &&
                          watch("uuid_picture")[0].name) ||
                          "Choose file..."}
                      </CLabel>
                      {errors.uuid_picture?.message ? (
                        <strong style={{ color: "red" }}>
                          {i18next.t("The ID Scan must be a file of type image: jpeg, jpg, png.")}
                        </strong>
                      ) : (
                        <strong>
                          {i18next.t("The ID Scan must be a file of type image: jpeg, jpg, png.")}
                        </strong>
                      )}
                    </>
                  )}
                </CCol>
                <CCol xxs={12} sm={12} md={6} lg={6} xl={6} className="my-2 ">
                  <CLabel
                    htmlFor="avatar"
                    className="float-left labels required"
                  >
                    {i18next.t("Profile Picture")}
                  </CLabel>

                  {logostring ? (
                    <>
                      <CRow>
                        <CCol>
                          <CImg width={200} height={50} src={logostring} />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CInputFile
                            className={`${getClassName(
                              "avatar",
                              "avatar",
                              watch,
                              errors
                            )}`}
                            style={{ top: "0" }}
                            id="avatar"
                            class="form-control file-upload-button"
                            name="avatar"
                            innerRef={register(Validation.file2)}
                          />
                          <CLabel
                            htmlFor="avatar"
                            variant="custom-file"
                            className={`${getClassName(
                              "avatar",
                              "avatar",
                              watch,
                              errors
                            )}`}
                          >
                            {(watch("avatar") &&
                              watch("avatar")[0] &&
                              watch("avatar")[0].name) ||
                              "Choose file..."}
                          </CLabel>
                          <CLabel
                            style={{
                              top: "1em",
                              right: "1em",
                              left: "1em",
                              marginTop: ".9em",
                              marginBottom: ".9em",
                            }}
                          >
                            {errors.avatar?.message ? (
                              <strong style={{ color: "red" }}>
                                {i18next.t("The Profile Picture must be a file of type image: jpeg, jpg, png.")}
                              </strong>
                            ) : (
                              <strong>
                                {i18next.t("The Profile Picture must be a file of type image: jpeg, jpg, png.")}
                              </strong>
                            )}
                          </CLabel>
                        </CCol>
                      </CRow>
                    </>
                  ) : (
                    <>
                      <CInputFile
                        className={`${getClassName(
                          "avatar",
                          "avatar",
                          watch,
                          errors
                        )}`}
                        style={{ top: "0" }}
                        id="avatar"
                        class="form-control file-upload-button"
                        name="avatar"
                        innerRef={register(Validation.file)}
                      />
                      <CLabel
                        style={{
                          top: "1em",
                          right: "1em",
                          left: "1em",
                          marginTop: ".5em",
                        }}
                        htmlFor="avatar"
                        variant="custom-file"
                        className={`${getClassName(
                          "avatar",
                          "avatar",
                          watch,
                          errors
                        )}`}
                      >
                        {(watch("avatar") &&
                          watch("avatar")[0] &&
                          watch("avatar")[0].name) ||
                          "Choose file..."}
                      </CLabel>
                      {errors.avatar?.message ? (
                        <strong style={{ color: "red" }}>
                          {i18next.t("The Profile Picture must be a file of type image: jpeg, jpg, png.")}
                        </strong>
                      ) : (
                        <strong>
                          {i18next.t("The Profile Picture must be a file of type image: jpeg, jpg, png.")}

                        </strong>
                      )}
                    </>
                  )}
                </CCol>
              </CFormGroup>
            </CCardBody>
            <CCardFooter>
              <CFormGroup row>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    type="submit"
                    color="primary"
                    className="mx-1 btn-form"
                    disabled={isSubmitting}
                    >
                 {isSubmitting ? (
                      <>
                        <CSpinner color="primary" size="sm" variant="grow" />
                        <span style={{ color: "dark" }}>{i18next.t("Loading")}...</span>
                      </>
                    ) : (
                      <span>{i18next.t("Save")}</span>
                    )}
                  </CButton>
                </CCol>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  );
};

export default ProfileForm;
