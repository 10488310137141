import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CCarousel,
  CCarouselInner,
  CCarouselItem,
} from "@coreui/react";
import i18next from "i18next";
import {
  fetchPrescriptionUtilities,
  fetchPrescriptions,
} from "actions/prescriptions/prescriptions";
import {
  fetchVitalSignUtilities,
  fetchVitalSigns,
} from "actions/vital-signs/vitalSigns";
import { fetchFollowUps } from "actions/follow-ups/followUps";
import { getEpisodeConsultations } from "actions/episodes/episodes";
import { fetchInvestigations } from "actions/episodes/investigations";
import { fetchMedicalHistory } from "actions/episodes/medicalHistory";
import { fetchPrescriptionExecution } from "actions/episodes/prescriptionExecutions";
import { TYPE_EPISODE } from "constants/constants";
import Prescription from "./tabs/prescription/Prescription";
import SymptomsAndDiagnosis from "./tabs/symptomsAndDiagnosis/SymptomsAndDiagnosis";
import VitalSigns from "./tabs/vitalSigns/VitalSigns";
import MedicalHistory from "./tabs/medicalHistory/MedicalHistory";
import PastMedicalHistory from "./tabs/pastMedicalHistory/PastMedicalHistory";
import FollowUp from "./tabs/followUp/FollowUp";
import Investigations from "./tabs/investigation/Investigation";
import PrescriptionExecution from "./tabs/prescriptionExecution/PrescriptionExecutions";
import ConsultationsSection from "./tabs/followUp/ConsultationsSection";
import VitalSidebar from "./tabs/vitalSigns/VitalSidebar";

const SideBar = () => (
  <>
    <CNav variant="tabs" className="flex-nowrap case-tab">
      <CNavItem className="w">
        <CNavLink
          data-tab="PastMedicalHistory"
          className="text-body font-weight-bold p m"
        >
          {i18next.t("past medical history")}
        </CNavLink>
      </CNavItem>
      <CNavItem className="w">
        <CNavLink
          data-tab="MedicalHistory"
          className="text-body font-weight-bold p m"
        >
          {i18next.t("medical history")}
        </CNavLink>
      </CNavItem>
      <CNavItem className="w">
        <CNavLink
          data-tab="Investigation"
          className="text-body font-weight-bold p m"
        >
          {i18next.t("investigations")}
        </CNavLink>
      </CNavItem>
      <CNavItem className="w">
        <CNavLink
          data-tab="Prescription"
          className="text-body font-weight-bold p m"
        >
          {i18next.t("prescriptions")}
        </CNavLink>
      </CNavItem>
      <CNavItem className="w">
        <CNavLink
          data-tab="VitalSign"
          className="text-body font-weight-bold p m"
        >
          {i18next.t("vital signs")}
        </CNavLink>
      </CNavItem>
      <CNavItem className="w">
        <CNavLink
          data-tab="FollowUp"
          className="text-body font-weight-bold p m"
        >
          {i18next.t("follow ups")}
        </CNavLink>
      </CNavItem>
      <CNavItem className="w">
        <CNavLink
          data-tab="NurseFollowUp"
          className="text-body font-weight-bold p m"
        >
          {i18next.t("nurse follow ups")}
        </CNavLink>
      </CNavItem>

      {/*
    *** To Do ***
    - Check SymptomsAndDiagnosis will be still here or not
    <CNavItem>
      <CNavLink data-tab="SymptomsAndDiagnosis"> Symptoms And Diagnosis </CNavLink>
    </CNavItem> */}
      <CNavItem className="w">
        <CNavLink
          data-tab="PrescriptionExecution"
          className="text-body font-weight-bold p"
        >
          {i18next.t("prescription executions")}
        </CNavLink>
      </CNavItem>
    </CNav>
  </>
);

const Content = () => {
  const { episodeId } = useParams();
  const episode = useSelector((state) => state.episodes.episode);
  const dispatch = useDispatch();
  const followUpUtilities = useSelector((state) => state.followUpUtilities);
  const TabsContent = () => (
    <CTabContent>
      <CTabPane data-tab="PastMedicalHistory">
        <PastMedicalHistory patientId={episode.patient_id} patient={episode.patient} hospital={episode.spock}  />
      </CTabPane>
      <CTabPane data-tab="FollowUp">
        <ConsultationsSection>
          <FollowUp
            episodeStatus={episode.status}
            type={followUpUtilities.type_doctor}
          />
        </ConsultationsSection>
      </CTabPane>
      <CTabPane data-tab="NurseFollowUp">
        <ConsultationsSection showRoundData={false}>
          <FollowUp
            episodeStatus={episode.status}
            type={followUpUtilities.type_nurse}
          />
        </ConsultationsSection>
      </CTabPane>
      <CTabPane data-tab="Investigation">
        <Investigations
          episodeStatus={episode.status}
          patientId={episode.patient_id}
        />
      </CTabPane>
      <CTabPane data-tab="Prescription">
        <Prescription episodeStatus={episode.status} type={TYPE_EPISODE} />
      </CTabPane>
      <CTabPane data-tab="SymptomsAndDiagnosis">
        <SymptomsAndDiagnosis />
      </CTabPane>
      <CTabPane data-tab="VitalSign">
        <VitalSidebar>
          <VitalSigns episodeStatus={episode.status} />
        </VitalSidebar>
      </CTabPane>
      <CTabPane data-tab="MedicalHistory">
        <MedicalHistory />
      </CTabPane>
      <CTabPane data-tab="PrescriptionExecution">
        <PrescriptionExecution />
      </CTabPane>
    </CTabContent>
  );

  const onActiveTabChange = (activeTab) => {
    switch (activeTab) {
      case "Prescription":
        dispatch(fetchPrescriptionUtilities(episodeId));
        dispatch(fetchPrescriptions(episodeId, TYPE_EPISODE));
        break;
      case "PrescriptionExecution":
        dispatch(fetchPrescriptionExecution(episodeId));
        dispatch(fetchPrescriptionUtilities());
        break;
      case "VitalSign":
        dispatch(fetchVitalSignUtilities());
        dispatch(fetchVitalSigns(episodeId));
        break;
      case "MedicalHistory":
        dispatch(fetchMedicalHistory(episodeId));
        break;
      case "FollowUp":
        dispatch(
          fetchFollowUps(episodeId, "episode", followUpUtilities.type_doctor)
        );
        dispatch(getEpisodeConsultations(episodeId));
        break;
      case "NurseFollowUp":
        dispatch(
          fetchFollowUps(episodeId, "episode", followUpUtilities.type_nurse)
        );
        break;
      case "Investigation":
        dispatch(fetchInvestigations(episodeId));
        break;
      default:
        // TBD - Add the default active tab here!
        break;
    }
  };
  return (
    <CCard style={{ border: "1px" }}>
      <CCardBody className="p-0">
        <CRow>
          <CTabs
            activeTab="PastMedicalHistory"
            onActiveTabChange={(activeTab) => onActiveTabChange(activeTab)}
          >
            <CCol md="12" className="text-dark">
              <div className="case-tab-wrap">
                <SideBar />
              </div>
            </CCol>
            <CCol md="12" className="text-dark">
              {TabsContent()}
            </CCol>
          </CTabs>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default Content;
