import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { createFollowUp } from "actions/follow-ups/followUps";
import { listAdminSpecialtyForms } from "actions/specialties-form/specialtiesForm";
import FollowUpForm from "./form/Form";
import {FETCH_SPECIALTIES_FORM} from "actions/specialties-form/types"
import i18next from "i18next";

const CreateFollowUp = ({ toggle, modal, type }) => {
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.followUpUtilities);
  const staffId = useSelector((state) => state.auth.jwt.user.id);
  const episode = useSelector((state) => state.episodes.episode);
  const specialtyForm = useSelector(
    (state) => state.specialtiesForm.specialtyForm
  );
  const specialtyFormsList = useSelector(
    (state) => state.specialtiesForm.adminSpecialtyFormsList
  );
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      type,
      model_id: episode.id,
      model_type: "episode",
      patient_id: episode.patient_id,
      creator_id: staffId,
      survey_id: specialtyForm ? specialtyForm.id : 0,
    };
    dispatch(createFollowUp(data));
    toggle();
  };
  const onSpecialtyFormChange = (event) => {
    const selectedForm = specialtyFormsList.find(item => item.id == event.target.value);
    dispatch({ type: FETCH_SPECIALTIES_FORM, payload: {"data": selectedForm} });
  };
  const onCancel = () => {
    toggle();
  };

  const onSpecialtyChange = (event) => {
    dispatch(listAdminSpecialtyForms(episode.hospital_id, event.target.value, type));
  };

  return (
    <FollowUpForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal}
      specialties={utilities.specialties || []}
      specialtyForm={specialtyForm}
      specialtyFormsList={specialtyFormsList}
      onSpecialtyChange={onSpecialtyChange}
      onSpecialtyFormChange={onSpecialtyFormChange}
      FormTitle={i18next.t("Create FollowUp")}
      type={type}
      key={`create_${type}`}
    />
  );
};
export default CreateFollowUp;
