import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CPagination,
} from "@coreui/react";
import { fetchDetailedReport } from "actions/reports/reports";
import { LIMIT } from "constants/constants";

const DetailedReport = () => {
  const { type, modelId } = useParams();
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/old-reports/${type}/details/${modelId}?page=${newPage}`);
    }
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchDetailedReport({ type, modelId, page, limit: LIMIT }));
  }, [dispatch, page, type, modelId]);

  return (
    <CRow>
      <CCol xl={12}>
        <h4 className="table-title">{`Report : ${type}`}</h4>
        <CCard>
          <CCardBody>
            <CDataTable
              items={reports.data || []}
              noItemsViewSlot="No Available Items"
              fields={
                reports.data && reports.data[0]
                  ? Object.keys(reports.data[0])
                  : []
              }
              hover
              striped
            />
            <CPagination
              activePage={page}
              onActivePageChange={pageChange}
              pages={reports.last_page || 1}
              doubleArrows={false}
              align="center"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default DetailedReport;
