import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardHeader,
  CButton,
  CCardSubtitle,
} from "@coreui/react";
import { formatTime } from "helpers/helpers";
import { fetchUserRound, startRound } from "actions/rounds/rounds";
import { FETCH_ROUND } from "actions/rounds/types";
import {
  ROUND_STATUS_SCHEDULED,
  ROUND_STATUS_STARTED,
} from "constants/constants";
import CIcon from "@coreui/icons-react";
import { cilLocationPin, cilScreenDesktop } from "@coreui/icons";
import Loading from "views/partials/Loading";
import i18next from "i18next";

const RoundDetails = () => {
  const { id: roundId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const round = useSelector((state) => state.rounds[roundId]);
  const loggedInUser = useSelector((state) => state.auth.jwt.user.id);
  const [startedRound, setStartedRound] = useState(false);
  useEffect(() => {
    dispatch(fetchUserRound(roundId));
  }, [dispatch, roundId]);

  const onStartRound = () => {
    dispatch(startRound(round.id));
    setStartedRound(true);
  };
  if (round) {
    window.Echo.private(`round.${round.id}`).listen(
      "StartRound",
      (eventData) => {
        dispatch({
          type: FETCH_ROUND,
          payload: { data: eventData.round },
        });
      }
    );
  }

  if (startedRound && round.session_token) {
    history.push(`/round/${round.session_token}/conference`);
  }
  const d = new Date();
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  const hours = String(d.getHours()).padStart(2, "0");
  const minutes = String(d.getMinutes()).padStart(2, "0");
  const time = `${hours}:${minutes}`;
  const today = `${year}-${month}-${day}`;

  const renderOrganizerBtns = () => {
    if (
      round.status === ROUND_STATUS_SCHEDULED &&
      round.date == today &&
      time >= round.from
    ) {
      return (
        <CButton
          style={{ width: "100%" }}
          className="d-color"
          onClick={onStartRound}
        >
          {i18next.t("Start")}
        </CButton>
      );
    }
    if (round.status === ROUND_STATUS_STARTED) {
      return (
        <CButton
          color="primary"
          style={{ width: "100%" }}
          onClick={() =>
            history.push(`/round/${round.session_token}/conference`)
          }
        >
          {i18next.t("Enter")}
        </CButton>
      );
    }
    return (
      <h6 style={{ color: "red" }}>
        {i18next.t("The Round is")} {round.statusText}
      </h6>
    );
  };
  const renderAttendeeBtns = () => {
    if (round.status === ROUND_STATUS_SCHEDULED) {
      return (
        <h4 className="text-align-center">
          <strong>{i18next.t("Waiting the Round to start")}</strong>
        </h4>
      );
    }
    if (round.status === ROUND_STATUS_STARTED) {
      return (
        <CButton
          style={{ width: "100%" }}
          className="d-color"
          onClick={() =>
            history.push(`/round/${round.session_token}/conference`)
          }
        >
          {i18next.t("Join")}
        </CButton>
      );
    }
    return (
      <h4 style={{ color: "red" }}>
        <strong>
          {i18next.t("The Round is")} {round.statusText}
        </strong>
      </h4>
    );
  };

  const renderRoundBtns = () => {
    if (round.organizer_id === loggedInUser) {
      return renderOrganizerBtns();
    }
    return renderAttendeeBtns();
  };

  if (round) {
    return (
      <>
        <CRow className="justify-content-between align-items-center my-1">
          <CCol>
            <div className="float-left table-title">
              {i18next.t("Round Details")}
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard className="text-theme-blue p-4 clinic-card">
              <CCardBody className="p-0">
                <CRow className="align-items-center justify-content-center">
                  <CCol>
                    <CRow className="align-items-center justify-content-center">
                      <CCol
                        xs="8"
                        md="2"
                        className="d-flex flex-column mb-3 text-center text-md-left"
                      >
                        <CCardTitle className="clinic-title avh-font-bold w-fcontent">
                          {i18next.t("DATE")}
                        </CCardTitle>
                        <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                          {round.date_original}
                        </CCardSubtitle>
                      </CCol>
                      <CCol xs="8" md="2" className="d-flex flex-column mb-3">
                        <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                          {i18next.t("TIME")}
                        </CCardTitle>
                        <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                        {`${formatTime(round.from)} - ${formatTime(round.to)}`}
                        </CCardSubtitle>
                      </CCol>
                      <CCol xs="8" md="2" className="d-flex flex-column mb-3">
                        <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                          {i18next.t("Location")}
                        </CCardTitle>
                        <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                          {round.spokeName || i18next.t("No Location")}
                        </CCardSubtitle>
                      </CCol>
                      <CCol xs="8" md="2" className="d-flex flex-column mb-3">
                        <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                          {i18next.t("Unit")}
                        </CCardTitle>
                        <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                          {round.unit || ""}
                        </CCardSubtitle>
                      </CCol>
                      <CCol xs="8" md="2" className="d-flex flex-column mb-3">
                        <CRow>
                          <CCol xs="auto" className="d-flex flex-column">
                            {round.type == "virtual" ? (
                              <CIcon
                                className="clinic-icon"
                                content={cilScreenDesktop}
                              />
                            ) : (
                              <CIcon
                                className="clinic-icon"
                                content={cilLocationPin}
                              />
                            )}
                          </CCol>
                          <CCol xs="auto" className="d-flex flex-column p-0 mb-3">
                            <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                              {i18next.t("TYPE")}
                            </CCardTitle>
                            <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                              {round.type}
                            </CCardSubtitle>
                          </CCol>
                        </CRow>
                      </CCol>
                      {/* <CCol xs="8" md="2" className="d-flex flex-column">
                        {renderRoundBtns()}
                      </CCol>
                      <CCol xs="8" md="2" className="d-flex flex-column">
                        <CButton
                          style={{ width: "100%" }}
                          color="danger"
                          onClick={() => history.push("/rounds/my-rounds")}
                        >
                          {i18next.t("Cancel")}
                        </CButton>
                      </CCol> */}
                      <CCol
                        xs="8"
                        md="2"
                        className="d-flex flex-column align-items-center mb-3"
                      >
                        <div className="w-100 mb-3">{renderRoundBtns()}</div>
                        <div className="w-100">
                          <CButton
                            style={{ width: "100%" }}
                            color="danger"
                            onClick={() => history.push("/rounds/my-rounds")}
                            className="btn-sm" // Optional: makes the button smaller
                          >
                            {i18next.t("Cancel")}
                          </CButton>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <div>
              {/* <CCard>
                <CCardBody>
                  <CCard
                    className="bg-light text-dark"
                    style={{ borderRadius: "15px" }}
                  >
                    <CCardBody>
                      <CRow style={{ textAlign: "center" }}>
                        <CCol xs="12">
                          <CCardTitle
                            className="bg-dark"
                            style={{
                              borderRadius: "10px",
                              width: "50%",
                              marginLeft: "25%",
                            }}
                          >
                            {round.date}
                          </CCardTitle>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol xs="12">
                          <CCardTitle>
                            {`from  ${round.from} to ${round.to}`}
                          </CCardTitle>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol xs="4">{renderRoundBtns()}</CCol>
                        <CCol xs="4" />
                        <CCol xs="4">
                          <CButton
                            color="info"
                            onClick={() => history.push("/rounds/my-rounds")}
                          >
                            Cancel
                          </CButton>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard> */}
            </div>
          </CCol>
        </CRow>
      </>
    );
  }
  return <Loading />;
};

export default RoundDetails;
