import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
} from "@coreui/react";
import { fetchOldReports } from "actions/reports/reports";
import i18next from "i18next";

const OldReports = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(fetchOldReports());
  }, [dispatch]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">{i18next.t("reports")}</div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={reports.data || []}
                fields={["name", "actions"]}
                hover
                noItemsViewSlot="No Available Items"
                striped
                scopedSlots={{
                  actions: (item) => (
                    <td>
                      <a
                        href={`/old-reports/${item.name}`}
                        style={{ marginRight: "5px" }}
                        className="btn btn-primary"
                      >
                        Show
                      </a>
                    </td>
                  ),
                }}
                hover
                striped
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default OldReports;
