import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import "../../../scss/style.scss";
import logo from "../../../logo.png";
import cardImage from "../../../AVH-H-S.png";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CImg,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
} from "@coreui/react";
import Language from "../../../containers/Language";
import CIcon from "@coreui/icons-react";
import { login } from "../../../actions/auth/auth";
import TheErrorNotification from "../../../containers/TheErrorNotification";
import { PrependInputUser } from "../partials/partials";
import i18next from "i18next";
import { TheFooter } from "../../../containers/index";
import { registerDeviceToken } from "actions/firebase/firebase";
import { getToken } from "firebase/messaging";
import messaging from "services/firebase";
import { CLOSE_SIGN_IN_MODAL } from "actions/auth/types";

const LoginInModal = () => {
    // const history = useHistory();
    const dispatch = useDispatch();
	const  [submitFlag, setSubmitFlag] = useState(false);
    const accessToken = useSelector((state) => state.auth.access_token);
    const loggedInUserId = useSelector((state) => state.auth?.jwt?.user?.id);
    const firebase = useSelector((state) => state.firebase);
    const isOpen = useSelector((state) => state.auth.isSignInModalOpen);

// console.log("firebase state>>>", firebase, submitFlag);

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (formData) => {
	setSubmitFlag(true);
    dispatch(login(formData));
    dispatch({type: CLOSE_SIGN_IN_MODAL});
  };

	const generateFirebaseToken = async () => {
    const webPushKeyPair = "BLLnh8BODyC3CwhxJUT5MIV92qkdZkxzfIR-wkNyN7aHQwwUjqtlcQCZ4U-FRHIP7pUMMlKfLu_sNfEniZ_WAJg";
    const device_token = await getToken(messaging, {vapidKey: webPushKeyPair});
    if (firebase?.user_id === loggedInUserId) {
      if (firebase.device_token === device_token) return;
    }
		dispatch(registerDeviceToken());
	};

    useEffect(() => {
            if (accessToken) {
                if (submitFlag) {
                    generateFirebaseToken();
                }
            }
    }, [accessToken, submitFlag]);


    if(!isOpen) return null;

  return (
    <CModal show={isOpen} onClose={() => dispatch({type: CLOSE_SIGN_IN_MODAL})} size="lg" centered>
      <CModalBody>
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="12">
              <CCardGroup>
                <CCard className="form login-form-card">
                  <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
                    <CCardBody className="cardStyle">
                      <CForm className="mt-5 p-4">
                        <h2 className="loginText">{i18next.t("Login")}</h2>
                        <CInputGroup className="mb-3">
                          <CInputGroupPrepend>
                            <CInputGroupText>@</CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput type="email" name="email" placeholder="Email" innerRef={register} />
                        </CInputGroup>
                        <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="password"
                          name="password"
                          placeholder="Password"
                          innerRef={register}
                        />
                      </CInputGroup>
                        <CRow>
                          <CCol xs="12">
                            <CButton type="submit" color="primary" className="px-4 loginButton"
                            disabled={submitFlag}
>
                              {i18next.t("Login")}
                            </CButton>
                          </CCol>
                          <CCol xs="12">
                            <CButton color="danger" className="mt-2 px-4" style={{width:'100%', borderRadius: '10px'}} onClick={() => dispatch({type: CLOSE_SIGN_IN_MODAL})}>
                              {i18next.t("Cancel")}
                            </CButton>
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CForm>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default LoginInModal;
