import { Api, getTokenHeader } from "services/Api";
import {
  CREATE_LEVEL,
  FETCH_LEVEL,
  UPDATE_LEVEL,
  FETCH_LEVELS,
  DELETE_LEVEL,
} from "./types";

export const createLevel = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/levels`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_LEVEL, payload: response.data });
};

export const fetchLevel = (id: number): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/level/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_LEVEL, payload: response.data });
};

export const updateLevel = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(`/api/admin/levels/${id}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_LEVEL, payload: response.data });
};

export const fetchLevels = (page: string, limit: string): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/levels?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_LEVELS, payload: response.data });
};

export const deleteLevel = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/levels/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_LEVEL, payload: id });
};
