import React from "react";
import { CCol, CRow, CCard, CCardHeader, CCardBody } from "@coreui/react";
import i18next from "i18next";

const ObjectDetails = ({ object, listItems }) => {
  console.log('listItems',listItems)
  if (object) {
    return (
      <CRow className="w-full">
        <CCol>
          <CCard>
            {/* <CCardHeader>
              <CRow className="justify-content-between align-items-center my-1">
                <CCol>
                  <h4 className="header-label-title">{object.name}</h4>
                </CCol>
              </CRow>
            </CCardHeader> */}
            <CCardBody>
              <table className="rounds-show-table table table-striped table-hover">
                <tbody>
                  {Object.entries(object).map(([key, value], index) => {
                    if (listItems.includes(key)) {
                      return (
                        <tr key={index.toString() + key}>
                          <td className="text-align-center labels">{i18next.t(`${
                            key.replace("_", " ").charAt(0).toUpperCase() +
                            key.slice(1)
                          }`)}</td>
                          <td className="text-align-center"  style={{
                            wordWrap: 'break-word',
                            wordBreak: 'break-word'}}>
                            <strong>
                              {value ? value : i18next.t("No Available Data")}
                            </strong>
                          </td>
                        </tr>
                      );
                    }
                    return <></>;
                  })}
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }
  return (
    <h4 className="text-align-center not-found">
      <strong>{i18next.t("Not Found")}</strong>
    </h4>
  );
};

export default ObjectDetails;
