import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchUtilities, createUser } from "actions/users/users";
import { fetchCountryCities } from "actions/cities/cities";
import UserForm from "./form/UserForm";
import i18next from "i18next";

/**
 * @param props
 */
function CreateUser() {
  const history = useHistory();
  const label = `${i18next.t("Create New User")}`;
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.utilities);
  const cities = useSelector((state) => state.cities);
  const redirect = useSelector((state) => state.users.redirect);

  const onSubmit = async (formData) => {
    await dispatch(createUser(formData));
  };

  const onCountryChange = (e) => {
    dispatch(fetchCountryCities(e.target.value));
  };

  if (redirect) {
    history.push("/users");
  }

  useEffect(() => {
    dispatch(fetchUtilities());
  }, [dispatch]);

  return (
    <UserForm
      onSubmit={onSubmit}
      roles={utilities.roles || []}
      levels={utilities.levels || []}
      specialties={utilities.specialties || []}
      countries={utilities.countries || []}
      cities={cities.data || []}
      onCountryChange={onCountryChange}
      label={label}
      hospitals={utilities.hospitals || []}
    />
  );
}

export default CreateUser;
