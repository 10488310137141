import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CLabel,
  CButton,
  CTextarea,
  CForm,
  CCard,
  CRow,
  CCardImg,
  CAlert,
} from "@coreui/react";
import { addConsultationNote } from "actions/rounds/rounds";
import { HIDE_CONSULTATION_NOTE_NOTIFICATION } from "actions/rounds/types";
import i18next from "i18next";

const ConsultationNote = ({ caseType, selectedCase }) => {
  const dispatch = useDispatch();
  const roundsUtilities = useSelector((state) => state.roundsUtilities);
  const { conferenceRound, notify_consultation_note } = roundsUtilities;
  const { register, handleSubmit, setValue } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    setValue("note", '');
    if (selectedCase && selectedCase.round_consultation_text &&  selectedCase.round_consultation_text != 'NA-CON') {
      setValue("note", selectedCase.round_consultation_text );
    }
  }, [selectedCase]);

  const onConsultationSubmit = (data) => {
    if (selectedCase && conferenceRound) {
      const preparedData = {
        ...data,
        round_id: conferenceRound.id,
        case_type: caseType,
        case_id: selectedCase.id,
      };
      dispatch(addConsultationNote(conferenceRound.id, preparedData));
    }
  };

  return (
    <CCol xs="12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
      <CCard className="mt-3 p-5 share-section text-theme-blue">
        <CRow>
          <CCol xs="1">
            <CCardImg
              src="https://cdn.pixabay.com/photo/2018/08/28/12/41/avatar-3637425_960_720.png"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                marginTop: "7px",
                objectFit: "cover",
              }}
            />
          </CCol>
          <CCol className="d-flex flex-column justify-content-center">
            <CAlert
              color="success"
              closeButton
              onShowChange={(showVal) => {
                if (!showVal && notify_consultation_note) {
                  dispatch({ type: HIDE_CONSULTATION_NOTE_NOTIFICATION });
                }
              }}
              fade
              show={!!notify_consultation_note}
            >
              {i18next.t("Note Saved Successfully")}.
            </CAlert>
            <CForm
              onSubmit={handleSubmit(onConsultationSubmit)}
              className="form-inline"
              style={{ width: "100%" }}
            >
              <CCol md="12">
                <CLabel>{i18next.t("Consultation Note")}</CLabel>
                <CTextarea
                  style={{ width: "100%" }}
                  class="form-control"
                  name="note"
                  id="note"
                  rows="5"
                  placeholder={i18next.t("Notes")}
                  innerRef={register}
                />
              </CCol>
              <CCol md="12" className="d-flex justify-content-center mt-2">
                <CButton
                  className="btn btn-primary btn-lg mt-2"
                  color="primary"
                  style={{
                    textAlign: "center",
                    boxShadow: "0px 3px 3px #0000003E;",
                    width: "50%",
                    "margin-left":" 40%"
                  }}
                  type="submit"
                >
                  {i18next.t("Save")}
                </CButton>
              </CCol>
            </CForm>
          </CCol>
        </CRow>
      </CCard>
    </CCol>
  );
};

export default ConsultationNote;
