import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import {
  CCol,
  CFormGroup,
  CLabel,
  CCard,
  CCardBody,
  CSelect,
  CCardFooter,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import SubQuestionForm from "views/surveys/questions/components/SubQuestionForm";
import EditSubQuestion from "views/surveys/questions/components/EditSubQuestion";
import SubQuestions from "views/surveys/questions/components/SubQuestions";
import {
  createQuestion,
  removeSubQuestion,
} from "actions/surveys/questions/questions";
import { ADD_SUB_QUESTION } from "actions/surveys/questions/types";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";

/**
 * @param props
 * @param props.register
 * @param props.question
 */
function Composite({ register, question, watch, errors }) {
  const dispatch = useDispatch();
  const subQuestions = useSelector((state) => state.questions.subQuestions);
  const [modal, setModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [subQuestion, setSubQuestion] = useState(null);

  const addSubQuestion = () => {
    toggle();
  };

  const onSubmitSubQuestion = (formData) => {
    const data = {
      ...formData,
      category_id: 1,
    };
    if (question) {
      const data = {
        ...formData,
        category_id: 1,
        parent_id: question.id,
      };
      dispatch(createQuestion(data));
    }
    dispatch({ type: ADD_SUB_QUESTION, payload: data });
    toggle();
  };

  const editSubQuestion = (question) => {
    setSubQuestion(question);
    toggle();
  };
  const deleteSubQuestion = (question) => {
    dispatch(removeSubQuestion(question.id));
  };

  const toggle = () => {
    setModal(!modal);
  };

  const Validation = getValidation(watch);

  return (
    <CCard>
      <CCardBody>
        <CFormGroup row>
          <CCol md="4">
            <CLabel className="labels required">{i18next.t("Formula")}</CLabel>
            <CSelect
              className={`${getClassName("formula", "formula", watch, errors)}`}
              innerRef={register(Validation.require)}
              custom
              name="formula"
              id="formula"
            >
              <option key="0" value="" selected disabled>
                {i18next.t("Select Formula")}
              </option>
              <option key="1" value="1">
                {i18next.t("Total")}
              </option>
              <option key="2" value="2">
                {i18next.t("Average")}
              </option>
            </CSelect>
          </CCol>
        </CFormGroup>
        <CFormGroup>
          <CCol md="6">
            <SubQuestions
              questions={subQuestions || []}
              editSubQuestion={editSubQuestion}
              deleteSubQuestion={deleteSubQuestion}
              setIsEditing={setIsEditing}
            />
          </CCol>
        </CFormGroup>
        {isEditing ? (
          <EditSubQuestion
            dispatch={dispatch}
            toggle={toggle}
            modal={modal}
            question={subQuestion}
          />
        ) : (
          <SubQuestionForm
            onSubmit={onSubmitSubQuestion}
            toggle={toggle}
            modal={modal}
            question={subQuestion}
          />
        )}
      </CCardBody>
      <CCardFooter>
        <CFormGroup row className="text-align-center">
          <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
            <CButton
              style={{
                marginRight: "5px",
                "background-color": "rgb(48, 197, 182)",
                "border-color": "rgb(48, 197, 182)",
                color: "white",
                width: "11em",
              }}
              onClick={addSubQuestion}
            >
              {i18next.t("Add Sub Question")}
            </CButton>
          </CCol>
        </CFormGroup>
      </CCardFooter>
    </CCard>
  );
}

export default Composite;
