import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form/dist/index";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CTextarea,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { updatePrescriptionExecution } from "actions/episodes/prescriptionExecutions";
import i18next from "i18next";

const ExecutionForm = ({ executionData, modal, toggle }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (formData) => {
    if (executionData) {
      dispatch(
        updatePrescriptionExecution(executionData.id, {
          status: executionData.status,
          ...formData,
        })
      );
    }
    toggle();
  };

  const onCancel = () => {
    toggle();
  };

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} size="lg">
        <h4 className="header-label-title">{i18next.t("Execute Prescription")}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CLabel>{i18next.t("Notes")}</CLabel>
              <CTextarea
                id="notes"
                name="notes"
                innerRef={register}
                defaultValue={executionData ? executionData.item.notes : ""}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
             {i18next.t("Save")}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
             {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default ExecutionForm;
