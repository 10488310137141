import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInputFile,
  CForm,
  CButton,
  CSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CInput,
  CSpinner,
} from "@coreui/react";
import {
  ATTACHMENT_TYPE_LAB,
  ATTACHMENT_TYPE_RAD,
  ATTACHEMNT_TYPE_OTHERS,
} from "constants/constants";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import i18next from "i18next";

const AttachmentForm = ({ onSubmit, onCancel, modal, attachment }) => {
  const { register, handleSubmit, reset, watch, errors,formState } = useForm({
    mode: "onBlur",
  });

  const Validation = getValidation(watch);
  const { isSubmitting } = formState;
  
  useEffect(() => {
    if (attachment) {
      reset({
        attachment_name: attachment.name,
        attachment_type: attachment.type,
      });
    }
  }, [attachment, reset]);

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} onClose={() => onCancel()} size="lg">
        <h4 className="header-label-title" closeButton>
          {i18next.t("Attachments")}
        </h4>
        <CModalBody>
          <CFormGroup row>
            <CCol md="3">
              <CLabel className="labels required" htmlFor="name">
                {i18next.t("Name")}
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="attachment_name"
                name="attachment_name"
                placeholder={i18next.t("Write Attachment Name")}
                className={`${getClassName(
                  "attachment_name",
                  "attachment_name",
                  watch,
                  errors
                )}`}
                innerRef={register(Validation.name)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel className="labels required" htmlFor="type">
                {i18next.t("Type")}
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CSelect
                custom
                placeholder={i18next.t("Select Attachment Type")}
                name="attachment_type"
                id="select"
                className={`${getClassName(
                  "attachment_type",
                  "attachment_type",
                  watch,
                  errors
                )}`}
                innerRef={register(Validation.name)}
              >
                <option value="">{i18next.t("Select Attachment Type")}</option>
                <option value={ATTACHMENT_TYPE_LAB}>{i18next.t("labs")}</option>
                <option value={ATTACHMENT_TYPE_RAD}>{i18next.t("radiology")}</option>
                <option value={ATTACHEMNT_TYPE_OTHERS}>{i18next.t("Others")}</option>
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CLabel className="labels required" col md="3">
              {i18next.t("Attachment")}
            </CLabel>
            <CCol xs="12" md="9">
              <CInputFile
                custom
                id="custom-file-input1"
                name="attachment_file"
                className={`${getClassName(
                  "attachment_file",
                  "attachment_file",
                  watch,
                  errors
                )}`}
                innerRef={register(Validation.file)}
              />
              <CLabel htmlFor="custom-file-input1" variant="custom-file">
                {(watch("attachment_file") &&
                  watch("attachment_file")[0] &&
                  watch("attachment_file")[0].name) ||
                  "Choose file..."}
              </CLabel>
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary"
          disabled={isSubmitting}
          >
       {isSubmitting ? (
            <>
              <CSpinner color="primary" size="sm" variant="grow" />
              <span style={{ color: "dark" }}>{i18next.t("Loading")}...</span>
            </>
          ) : (
            <span>{i18next.t("Save")}</span>
          )}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default AttachmentForm;
