import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CSpinner, CAlert, CButton } from "@coreui/react";
import {
  fetchUtilities,
  updateUserProfile,
  fetchUserProfile,
} from "actions/users/users";
import { fetchCities, fetchCountryCities } from "actions/cities/cities";
import { removeEmptyPassword } from "helpers/helpers";
import ProfileForm from "./form/ProfileForm";
import i18next from "i18next";

const EditProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.utilities);
  const user = useSelector((state) => state.users.profile);
  const updatedProfile = useSelector((state) => state.users.updatedProfile);
  const cities = useSelector((state) => state.cities);
  const label = `${i18next.t("Edit Profile")}`;
  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(fetchUtilities());
    dispatch(fetchCities(false, 30));
    if (user && user.country_id) {
      dispatch(fetchCountryCities(user.country_id));
    }
  }, [dispatch]);

  const onSubmit = async (formData) => {
      await dispatch(
        updateUserProfile(
          removeEmptyPassword({
            ...formData,
            time_zone: formData.time_zone.value ? formData.time_zone.value : formData.time_zone ,
          }),
          user.id
        )
      );
  };

  const onCountryChange = (e) => {
    dispatch(fetchCountryCities(e.target.value));
  };

  if (updatedProfile) {
  }

  if (user) {
    return (
      <>
        {updatedProfile && (
          <>
          <CAlert color="success">
            {i18next.t("Your Profile Updated Successfully")}
          </CAlert>
          </>
        )}
        <ProfileForm
          onSubmit={onSubmit}
          countries={utilities.countries || []}
          cities={cities.data || []}
          onCountryChange={onCountryChange}
          label={label}
          user={user}
          avatar={user.avatar|| {}}
          uuidimg={user.uuidimg|| {}}
        />
      </>
    );
  }
  return (
    <CSpinner
      className="spinner"
      variant="grow"
    />
  );
};

export default EditProfile;
