import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CInputFile,
  CModal,
  CModalBody,
  CModalFooter,
  CTextarea,
  CSpinner,
} from "@coreui/react";
import { executeInvestigation, externalExecuteInvestigation } from "actions/episodes/investigations";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import i18next from "i18next";

const ExecuteInvestigation = ({
  onCancel,
  modal,
  investigation,
  external,
  loadCenterInvestigations,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, watch, errors , formState} = useForm({
    mode: "onBlur",
  });
  useEffect(() => {
    if (investigation) {
      reset({
        ...investigation,
        execution_date:
          investigation.execution_date ||
          new Date().toISOString().substr(0, 10),
      });
    }
  }, [investigation, reset]);
  const Validation = getValidation(watch);
  const { isSubmitting } = formState;

  const onSubmit = async (formData) => {
    const preparedFormData = new FormData();
    preparedFormData.append("execution_date", formData.execution_date);
    preparedFormData.append("execution_notes", formData.execution_notes);
    preparedFormData.append("attachment", formData.attachment[0]);
    Array.from(formData.attachment).forEach((file) => {
      preparedFormData.append("attachment[]", file);
    });
    if (external) {
      await dispatch(externalExecuteInvestigation(investigation.id, preparedFormData));
    }
    else {
      await dispatch(executeInvestigation(investigation.id, preparedFormData));
    }
    if (loadCenterInvestigations) {
      loadCenterInvestigations();
    }
    onCancel();
  };

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} onClose={onCancel} size="lg">
        <h4 className="header-label-title">
          {investigation?.investigationTypeText
            ? investigation.investigationTypeText
            : i18next.t("Execute Investigation Details")}
        </h4>

        <CModalBody>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CLabel className="labels">{i18next.t("Execution Date")}</CLabel>
              <CInput
                type="date"
                id="execution_date"
                name="execution_date"
                placeholder={i18next.t("Execution Date")}
                innerRef={register}
              />
            </CCol>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CLabel
                className="labels required"
                style={{ marginTop: "1.3em" }}
              >
                {i18next.t("Attachment")}
              </CLabel>
              <CInputFile
                className={`${getClassName(
                  "attachment",
                  "attachment",
                  watch,
                  errors
                )}`}
                style={{ top: "0" }}
                multiple
                custom
                id="custom-file-input"
                name="attachment"
                innerRef={register(Validation.file)}
              />
              <CLabel
                style={{
                  top: "2.5em",
                  right: "1em",
                  left: "1em",
                  marginTop: ".5em",
                }}
                htmlFor="custom-file-input"
                variant="custom-file"
              >
                {(watch("attachment") &&
                  watch("attachment")[0] &&
                  watch("attachment")[0].name) ||
                  "Choose file..."}
              </CLabel>
              {errors.attachment?.message ? (
                <strong style={{ color: "red" }}>
                  {i18next.t("The attachment must be a file of type: jpeg, jpg, png, Dcm.")}
                </strong>
              ) : (
                <strong>
                  {i18next.t("The attachment must be a file of type: jpeg, jpg, png, Dcm.")}
                </strong>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CLabel className="labels">{i18next.t("Execution Notes")}</CLabel>
              <CTextarea
                id="execution_notes"
                name="execution_notes"
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary"
           disabled={isSubmitting}
           >
        {isSubmitting ? (
             <>
               <CSpinner color="primary" size="sm" variant="grow" />
               <span style={{ color: "dark" }}>{i18next.t("Loading")}...</span>
             </>
           ) : (
             <span>{i18next.t("Save")}</span>
           )}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default ExecuteInvestigation;
