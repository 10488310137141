import React, { useState } from "react";
import { useSelector } from "react-redux";
import find from "lodash/find";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLabel,
  CRow,
  CSwitch,
} from "@coreui/react";
import { CChart } from "@coreui/react-chartjs";
import Select from "react-select";

const VitalChart = ({ chartColWidth }) => {
  const vitalSigns = useSelector((state) => state.vitalSigns.data);
  const utilities = useSelector((state) => state.vitalSignUtilities);
  const [selectedType, setSelectedType] = useState(null);
  const [chartType, setChartType] = useState("bar");

  const getVitalSignsListView = () => {
    const groupedVitalSigns = {};

    if (vitalSigns) {
      vitalSigns.forEach((item) => {
        const type = find(utilities.types, { id: parseInt(item.type_id, 10) });
        const typeName = type ? type.title : item.type_id;

        if (!groupedVitalSigns[typeName]) {
          groupedVitalSigns[typeName] = [];
        }

        groupedVitalSigns[typeName].push(item);
      });
    }


    const sortedGroupedVitalSigns = Object.fromEntries(
      Object.entries(groupedVitalSigns).map(([key, values]) => [
        key,
        values.sort((a, b) => new Date(a.date_original) - new Date(b.date_original))
      ])
    )
    return sortedGroupedVitalSigns;
  };

  const groupedVitalSigns = getVitalSignsListView();

  const getTypeOptions = () => {
    const allTypes = Object.keys(groupedVitalSigns);
    const options = allTypes.map((typeName) => ({
      value: typeName,
      label: typeName,
    }));
    return options;
  };

  const handleTypeSelect = (selectedOption) => {
    setSelectedType(selectedOption);
  };

  const renderCharts = () => {
    if (!selectedType) return null;

    const selectedTypeName = selectedType.label;

    if (!groupedVitalSigns[selectedTypeName]) return null;

    const data = groupedVitalSigns[selectedTypeName];
    const typeName = selectedTypeName;

    // Extracting values from data
    const values = data.map((item) => parseFloat(item.rate));

    // Filtering out non-numeric values
    const validValues = values.filter((value) => !Number.isNaN(value));

    // Building dataset for Chart.js
    const chartData = {
      labels: data.map((item) => item.date),
      datasets: [
        {
          label: typeName,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75,192,192,0.6)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: validValues,
        },
      ],
    };

    return (
      // <CCol
      //   xs={12}
      //   sm={12}
      //   md={chartColWidth}
      //   lg={chartColWidth}
      //   xl={chartColWidth}
      //   className="mb-4"
      // >
      <CCol xs={12} className="mb-4">
        <CCard>
          <CCardHeader>{typeName}</CCardHeader>
          <CCardBody>
            {chartData && chartType === "line" && (
              <CChart
                type="line"
                datasets={chartData.datasets}
                labels={chartData.labels}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            )}
            {chartData && chartType === "bar" && (
              <CChart
                type="bar"
                datasets={chartData.datasets}
                labels={chartData.labels}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    );
  };

  return (
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="5">
            <Select
              options={getTypeOptions()}
              onChange={handleTypeSelect}
              value={selectedType}
              isClearable
              placeholder="Select Type"
            />
          </CCol>
        </CRow>
        <hr />
        <CRow>
          {/* <CCol sm={12} md={12} xs={12} lg={12} xl={12}> */}
          <CCol xs="auto">
            <CLabel
              className="labels mx-3"
              style={{ "vertical-align": "middle", fontSize: "1em" }}
              htmlFor="chart_type"
            >
              Chart Type
            </CLabel>
            <CSwitch
              id="chart_type"
              checked={chartType === "bar"}
              style={{ "margin-left": "15%" }}
              className="mr-3"
              size="lg"
              color="primary"
              shape="pill"
              onChange={(e) => {
                console.log(e.target.checked ? "bar" : "line");
                setChartType(e.target.checked ? "bar" : "line");
              }}
            />
            <CLabel
              className="labels mx-3"
              style={{ "vertical-align": "middle", fontSize: "1em" }}
            >
              {chartType === "bar" ? "Bar Chart" : "Line Chart"}
            </CLabel>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>{renderCharts()}</CRow>
      </CCardBody>
    </CCard>
  );
};

export default VitalChart;
