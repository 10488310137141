import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CImg,
  CDropdownItem
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { TOGGLE_SIDEBAR } from "constants/constants";
import {SUPER_ADMIN_ROLE} from "constants/constants";
// sidebar nav config
import navigation from "./_nav";
import logoIcon from '../icon.png';
import logoText from '../text.png';
import logo from '../logo.png';

const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector(state => state.sidebarShow);
  const userPermissions = useSelector(state =>
    state.auth.jwt ? state.auth.jwt.user.permissions : []
  );
  const userRoles = useSelector((state) => state.auth.jwt? state.auth.jwt.user.roles : []);
  const preparedNavigation = navigation.filter(item => {
    const preparedChildren = item._children.filter(
      c => (userRoles.indexOf(SUPER_ADMIN_ROLE) !== -1 || userPermissions.indexOf(c.permission) !== -1)
    );
    return preparedChildren.length
      ? { ...item, _children: preparedChildren }
      : false;
  });

  return (
    <CSidebar
      style={{zIndex:"1313",backgroundColor:"#fff"}} 
      show={show}
      onShowChange={val => dispatch({ type: TOGGLE_SIDEBAR, sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/" style={{backgroundColor:"#fff"}}>
        <CImg src = {logo} style= {{height:"50px"}}  className="c-sidebar-brand-full" />
        <CImg src = {logoIcon} style= {{height:"30px"}}  className="c-sidebar-brand-minimized" />
      </CSidebarBrand>
      <CSidebarNav className="test-nav">
        <CCreateElement
          items={preparedNavigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
