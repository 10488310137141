import React, { useEffect } from "react";
import { CCol, CRow, CLink } from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMyRounds, fetchAttendeeRounds } from "actions/rounds/rounds";
import RoundCard from "./RoundCard";
import i18next from "i18next";

const MyRounds = (props) => {
	console.log("props>>>>", props);
  const dispatch = useDispatch();
  const organizerRounds = useSelector((state) => Object.values(state.rounds));
  const attendeeRounds = useSelector(
    (state) => state.roundsUtilities.attendeeRounds
  );
  useEffect(() => {
		if(props.attendee != true){
			dispatch(fetchMyRounds());
		}
    dispatch(fetchAttendeeRounds());
  }, []);

  const renderRounds = (rounds) => {
    if (rounds && rounds.length != 0) {
      return rounds.map((item) => (
        <CLink
          key={item.id}
          href={`/rounds/my-rounds/${item.id}/details`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <RoundCard round={item} year= {new Date(item.date_original).getFullYear()} dateBackground="bg-info py-3" />
        </CLink>
      ));
    } else {
      return (
        <h4 className="text-align-center">
          <strong>{i18next.t("No Available Rounds")}</strong>
        </h4>
      );
    }
  };

  return (
    <>
			{props.attendee != true && (<>
				<CRow className="justify-content-between align-items-center my-1">
					<CCol>
						<div className="float-left table-label-title">{i18next.t("Organizer Rounds")}</div>
					</CCol>
				</CRow>
				<CRow>
					<CCol xs="12">{renderRounds(organizerRounds)}</CCol>
				</CRow>
			</>)}
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">{i18next.t("Attendee Rounds")}</div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">{renderRounds(attendeeRounds)}</CCol>
      </CRow>
    </>
  );
};

export default MyRounds;
