import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchPermissions, createRole } from "actions/roles/roles";
import RoleForm from "../form/RoleForm";
import i18next from "i18next";

const CreateRole = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const label = `${i18next.t("Add New Role")}`;
  const permissions = useSelector((state) => state.permissions);
  const redirect = useSelector((state) => state.roles.redirect);
  const onSubmit = (formData) => {
    dispatch(createRole(formData));
  };

  if (redirect) {
    history.push("/roles");
  }

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  return (
    <RoleForm label={label} onSubmit={onSubmit} permissions={permissions} />
  );
};

export default CreateRole;
