import React, { useState, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import i18next from "i18next";
import {
  CCol,
  CLabel,
  CButton,
  CFormGroup,
  CInput,
  CSelect,
} from "@coreui/react";
import { getColumnOptions } from "helpers/helpers";
import CIcon from "@coreui/icons-react";
import { FilterAsyncSelect } from "./FilterAsyncSelect";

const ConditionsSection = ({
  control,
  register,
  watch,
  errors,
  schema,
  selectedTable,
  selectedJoins,
  selectedGroup,
  setValue,
}) => {
  const [columnType, setColumnType] = useState({});
  const [defaultOperators, setDefaultOperators] = useState({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: "conditions",
  });

  const columnOperators = {};
  const columnTypes = {};

  useEffect(() => {
    if (selectedTable) {
      const columns =
        getColumnOptions(schema, selectedTable, selectedJoins, selectedGroup) ||
        [];
      columns.forEach((column) => {
        columnTypes[column.name] = column.type;
        columnOperators[column.name] = column.operator;
      });
      setColumnType(columnTypes);
      setDefaultOperators(columnOperators);
    }
  }, [selectedTable, selectedGroup, selectedJoins]);


  const handleColumnSelection = (index) => {
    setValue(`conditions[${index}].operator`,'')
    setValue(`conditions[${index}].value`,'')
    if (`conditions[${index}].value2`)
      setValue(`conditions[${index}].value2`, '');
  };

  const renderConditionsSectionFields = () => 
    fields.map((row, index) => {
      const selectedColumn = watch(`conditions[${index}].column`);
      const allColumns = getColumnOptions(
        schema,
        selectedTable,
        selectedJoins,
        selectedGroup
      );
      return (
        <>
          <CFormGroup row key={row.value}>
            <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
              <CLabel className="Option required">{i18next.t("Filter")}</CLabel>
              <CSelect
                id="column"
                name={`conditions[${index}].column`}
                defaultValue={row.column}
                onChange={(event) => handleColumnSelection(index)}
                innerRef={register({ required: true })}
                >
                <option value="" selected disabled>
                  Select Column
                </option>
                {allColumns.map((column) => (
                  <option key={column.name} value={column.name}>
                    {column.label}
                  </option>
                ))}
              </CSelect>

            </CCol>
            <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
              <CLabel className="labels required">{i18next.t("Operator")}</CLabel>
              <CSelect
                custom
                required
                name={`conditions[${index}].operator`}
                defaultValue={row.operator}
                innerRef={register({ required: true })}
                >
                <option value="" disabled>
                  Select Operator
                </option>
                {(
                  (defaultOperators && defaultOperators[selectedColumn]) ||
                  []
                ).map((operator) => (
                  <option key={operator.value} value={operator.value}>
                    {operator.label}
                  </option>
                ))}
              </CSelect>
            </CCol>
            <CCol xs={12} sm={12} md={3} lg={3} xl={3}>

              {columnType[selectedColumn] == "date" || row.value2 ? (
                <>
                  <CLabel className="labels">from</CLabel>
                  <CInput
                    type="date"
                    id="value_1"
                    defaultValue={row.value}
                    name={`conditions[${index}].value`}
                    innerRef={register()}
                    />
                  <CLabel className="labels">To</CLabel>
                  <CInput
                    type="date"
                    id="value_2"
                    defaultValue={row.value2}
                    name={`conditions[${index}].value2`}
                    innerRef={register()}
                    />
                </>
              )
                : (<>
                  <CLabel className="labels ">{i18next.t("Value")}</CLabel>
                  {columnType[selectedColumn] == "string" ?
                    (<CInput
                      type="text"
                      id="value"
                      placeholder="Write Value"
                      name={`conditions[${index}].value`}
                      defaultValue={row.value}
                      innerRef={register()}
                      />) : (

                      <FilterAsyncSelect
                        control={control}
                        register={register}
                        conditionColumn={selectedColumn}
                        id="value"
                        defaultValue={row.value}
                        conditionsObject={row.conditionsObject}
                        name={`conditions[${index}].value`}
                        errors={errors}
                        isRequired={false}

                      />
                    )
                  }

                </>)
              }
            </CCol>
            <CCol
              style={{ "margin-top": "2em" }}
              xs={12}
              sm={12}
              md={3} lg={3} xl={3}
            >
              <CButton color="danger" onClick={() => remove(index)}>
              Remove
            </CButton>


            </CCol>
          </CFormGroup>
        </>
      )
    });

  
  return (
    <>
      {selectedTable && (
        <>
          <CCol className="text-align-center">
            <CButton
              style={{
                backgroundColor: "rgb(48, 197, 182)",
                borderColor: "rgb(48, 197, 182)",
                width: "11em",
                marginBottom: "2em",
              }}
              color="info"
              onClick={() => append({})}
            >
              <CIcon name="cil-pencil" /> Add Filter
            </CButton>
          </CCol>
          <br></br>
          {renderConditionsSectionFields()}

        </>
      )}
    </>
  );
};
export default ConditionsSection;
