import React from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { getClassName, getValidation } from "./RoundFormValidation";
import i18next from "i18next";

const SharedUserModal = ({ onSubmit, onCancel, modal, FormTitle, }) => {
  const { register, handleSubmit ,watch,errors} = useForm({
    mode: "onBlur",
  });
  const ValidationRules = getValidation(watch);

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} size="lg" onClose={() => onCancel()}>
        <h4 className="header-label-title">{FormTitle}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
              <CLabel className="labels required">{i18next.t("Name")} </CLabel>
              <CInput
                className={getClassName(watch, errors, "name")}
                type="text"
                id="name"
                name="name"
                innerRef={register(ValidationRules.name)}
              />
            </CCol>
            <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
              <CLabel className="labels required">{i18next.t("Email")}</CLabel>
              <CInput
                className={getClassName(watch, errors, "email")}
                type="email"
                id="email"
                name="email"
                innerRef={register(ValidationRules.email)}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
            {i18next.t("Save")}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default SharedUserModal;
