import React, { useState, useEffect } from "react";
import Video from "twilio-video";
import { CCol } from "@coreui/react";
import Participant from "./Participant";
import ParticipantList from "./ParticipantList";

const VideoChatRoom = ({ roomName, token }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants([...participants, participant]);
    };
    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((item) => item !== participant)
      );
    };
    Video.connect(token, {
      name: roomName,
    }).then((room) => {
      setRoom(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach((trackPublication) => {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        }
        return currentRoom;
      });
    };
  }, [roomName, token]); // eslint-disable-line

  return (
    <div className="video-meeting-wrap">
      <ParticipantList participants={participants} />
      {room ? (
        <Participant participant={room.localParticipant} isLocalParticipant />
      ) : (
        ""
      )}
    </div>
  );
};

export default VideoChatRoom;
