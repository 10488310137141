import React from "react";
import { CCol, CFormGroup, CLabel, CInputRadio, CInput } from "@coreui/react";

const SurveyRadioQuestion = (props) => {
  const {
    question,
    register,
    setValue,
    questionName,
    questionValueName,
  } = props;
  const calculateAnswerValue = (event) => {
    const ansObj = JSON.parse(event.target.value);
    setValue(questionValueName, ansObj.value);
  };
  const renderAnswers = (answers) =>
    answers.map((answer) => (
      <CCol key={question.value} variant="custom-radio" inline="true">
        <CInputRadio
          custom
          data-label={answer.label}
          innerRef={register}
          name={questionName}
          id={`${question.id}-${answer.value}`}
          value={JSON.stringify({ label: answer.label, value: answer.value })}
          onClick={calculateAnswerValue}
        />
        <CLabel
          variant="custom-checkbox"
          htmlFor={`${question.id}-${answer.value}`}
          className="labels"
        >
          {answer.label}
        </CLabel>
      </CCol>
    ));

  return (
    <CCol  md={5} className="d-flex flex-column mb-2 px-0">
      <br />
      <h6 className="w-fcontent">{`${question.question}`}</h6>
      <CFormGroup className="float-left labels" variant="custom-radio" >
        {renderAnswers(question.answers)}
        <CInput type="hidden" innerRef={register} name={questionValueName} />
      </CFormGroup>
    </CCol>
  );
};

export default SurveyRadioQuestion;
