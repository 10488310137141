import React from "react";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CRow,
  CCol,
  CCardSubtitle,
  CLink,
} from "@coreui/react";
import { cilCalendar, cilLocationPin, cilScreenDesktop } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Avatar from "views/partials/Avatar";
import i18next from "i18next";

const ClinicsCardGroup = ({ date, clinics }) => (
  <>
    <CRow>
      <CCol>
        <CRow className="mt-4">
          <CCol>
            <div className="float-left table-title">{date}</div>
          </CCol>
        </CRow>
        {clinics.map((clinic) => (
          <CCard className="text-theme-blue p-4 clinic-card">
            <CCardBody className="p-0">
              <CRow className="align-items-center justify-content-between">
                <CCol md="auto" style={{flexBasis:0}}>
                  <CRow>
                    <CCol xs="12">
                      <Avatar
                        src={clinic.avatar}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        className="float-left"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="auto" style={{flexBasis:0, flexGrow:6}}>
                  <CRow>
                    <CCol xs="auto" className="d-flex flex-column"
                    style={{maxWidth: '25%'}}>
                      <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                        {clinic.name}
                      </CCardTitle>
                      <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                        {clinic.specialty}
                      </CCardSubtitle>
                    </CCol>
                    <CCol xs="auto" className="d-flex flex-column"
                    style={{maxWidth: '25%'}}>
                      <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                         {i18next.t("From")}
                      </CCardTitle>
                      <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                        {`${clinic[`${clinic.day.toLowerCase()}_from`].split(
                          ":"
                        )[0]}` < 12
                          ? ` ${
                              clinic[`${clinic.day.toLowerCase()}_from`].split(
                                ":"
                              )[0] % 12
                            }:${
                              clinic[`${clinic.day.toLowerCase()}_from`].split(
                                ":"
                              )[1]
                            } ${i18next.t("AM")}`
                          : `${
                              clinic[`${clinic.day.toLowerCase()}_from`].split(
                                ":"
                              )[0] % 12
                            }:${
                              clinic[`${clinic.day.toLowerCase()}_from`].split(
                                ":"
                              )[1]
                            } ${i18next.t("PM")}`}
                      </CCardSubtitle>
                    </CCol>
                    <CCol xs="auto" className="d-flex flex-column"
                    style={{maxWidth: '25%'}}>
                      <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                        {i18next.t("To")}
                      </CCardTitle>
                      <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                        {`${clinic[`${clinic.day.toLowerCase()}_to`].split(
                          ":"
                        )[0]}` < 12
                          ? ` ${
                              clinic[`${clinic.day.toLowerCase()}_to`].split(
                                ":"
                              )[0] % 12
                            }:${
                              clinic[`${clinic.day.toLowerCase()}_to`].split(
                                ":"
                              )[1]
                            } ${i18next.t("AM")}`
                          : `${
                              clinic[`${clinic.day.toLowerCase()}_to`].split(
                                ":"
                              )[0] % 12
                            }:${
                              clinic[`${clinic.day.toLowerCase()}_to`].split(
                                ":"
                              )[1]
                            } ${i18next.t("PM")}`}
                      </CCardSubtitle>
                    </CCol>
                    <CCol xs="auto" className="d-flex flex-column"
                    style={{maxWidth: '25%'}}>
                      <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                         {i18next.t("Location")}
                      </CCardTitle>
                      <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                        {clinic.location}
                      </CCardSubtitle>
                    </CCol>
                    <CCol xs="auto" className="d-flex flex-column"
                    style={{maxWidth: '25%'}}>
                      <CRow>
                        <CCol xs="auto" className="d-flex flex-column">
                          {clinic.type == "virtual" ? (
                            <CIcon
                              className="clinic-icon"
                              content={cilScreenDesktop}
                            />
                          ) : (
                            <CIcon
                              className="clinic-icon"
                              content={cilLocationPin}
                            />
                          )}
                        </CCol>
                        <CCol xs="auto" className="d-flex flex-column p-0">
                          <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                          {i18next.t("Type")}
                          </CCardTitle>
                          <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                            {clinic.type== "virtual"?i18next.t("Virtual"):i18next.t("Physical")}

                          </CCardSubtitle>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol style={{flexBasis:0}}>
                  <CLink
                    className="avh-btn px-4 py-3 text-decoration-none text-white avh-font-bold float-right"
                    href={`/clinics/my-clinics/${clinic.id}/appointments/${date.replace(/\//g,'-')}`}
                  >
                    <CIcon
                      content={cilCalendar}
                      className="discharge-icon mx-1"
                    ></CIcon>
                    {i18next.t("Agenda")}
                  </CLink>
                </CCol>
                <div>
                  {/* <CCol>
                  <CDropdown variant="btn-group">
                    <CDropdownToggle className="action-toggle m-0" color="light" onClick={(e) => { e.stopPropagation() }}>
                      <CIcon content={cilOptions} />
                    </CDropdownToggle>
                    <CDropdownMenu className="p-0">
                      <CDropdownItem href={`/service/providers/labs/${item.id}/edit`} className="text-primary">
                        <CIcon content={cilPencil} />
                        <span class="mx-1">Edit</span>
                      </CDropdownItem>
                      <CDropdownItem onClick={() => handleDeleteLevel(item.id)} className="text-danger">
                        <CIcon content={cilTrash} />
                        <span class="mx-1">Delete</span>
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </CCol> */}
                </div>
              </CRow>
            </CCardBody>
          </CCard>
        ))}
      </CCol>
    </CRow>
    <div>
      {/* <CCard style={{ border: "0px" }}>
        <CCardHeader>
          <CCardTitle>{date}</CCardTitle>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            items={clinics}
            fields={["avatar", "name", "from", "to", "address", "type", "actions"]}
            hover
            striped
            clickableRows
            scopedSlots={{
              actions: (item) => (
                <td>
                  <a
                    href={`/clinics/my-clinics/${item.id}/appointments/${date}`}
                    style={{ marginRight: "5px" }}
                    className="btn btn-info"
                  >
                    Calendar
                  </a>
                </td>
              ),
              avatar: (item) => (
                <td style={{ width: "12%" }}>
                  <img
                    style={{ width: "75px" }}
                    alt="NA"
                    src={item.avatar}
                    onError={(e) => {
                      e.target.src = "/avatars/avatar.png"; // eslint-disable-line no-param-reassign
                    }}
                    className="mb-2"
                  />
                </td>
              ),
              from: (item) => <td>{item[`${item.day.toLowerCase()}_from`]}</td>,
              to: (item) => <td>{item[`${item.day.toLowerCase()}_to`]}</td>,
            }}
          />
        </CCardBody>
      </CCard> */}
    </div>
  </>
);

export default ClinicsCardGroup;
