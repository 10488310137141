import { Api, getTokenHeader } from "../../services/Api";
import {
  CREATE_PRESCRIPTION_EXECUTION,
  FETCH_PRESCRIPTION_EXECUTIONS,
} from "./types";

export const fetchPrescriptionExecution = (episodeId) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/episodes/prescription-execution/${episodeId}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_PRESCRIPTION_EXECUTIONS, payload: response.data });
};

export const createPrescriptionExecution = (formData) => async (dispatch) => {
  const response = await Api.post(
    `/api/admin/episodes/prescription-execution`,
    formData,
    { ...getTokenHeader() }
  );
  dispatch({ type: CREATE_PRESCRIPTION_EXECUTION, payload: response.data });
};
export const updatePrescriptionExecution = (id, formData) => async (
  dispatch
) => {
  const response = await Api.put(
    `/api/admin/episodes/prescription-execution/${id}`,
    formData,
    { ...getTokenHeader() }
  );
  dispatch({ type: FETCH_PRESCRIPTION_EXECUTIONS, payload: response.data });
};
