import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CCol, CRow, CCard, CCardBody } from "@coreui/react";
import {
  fetchEpisode,
  getEpisodeDiagnosticsData,
} from "actions/episodes/episodes";
import PatientData from "../PatientData";
import Screens from "./Screens";
import Loading from "views/partials/Loading";

const DischargeForm = () => {
  const { episodeId } = useParams();
  const dispatch = useDispatch();
  const [activeScreen, setActiveScreen] = useState(null);
  const episode = useSelector((state) => state.episodes.episode);
  const history = useHistory();
  const hospitalId = useSelector((state) => state.episodes.hospitalId);
  useEffect(() => {
    if (!episode) {
      dispatch(fetchEpisode(episodeId));
    }
    dispatch(getEpisodeDiagnosticsData(episodeId));
  }, [dispatch, episode, episodeId]);
  if (hospitalId) {
    history.push(`/episodes/cases/${hospitalId}`);
  }
  if (episode) {
    return (
      <CRow>
        <CCol>
          <CCard className="card-3d">
            <CCardBody>
              <CRow>
                <CCol xs="12">
                  <PatientData episode={episode} discharge={false} />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <Screens
            episode={episode}
            activeScreen={activeScreen}
            setActiveScreen={setActiveScreen}
          />
          {/* <CCard>
            <CCardBody>
              <CRow>
                <CCol xs="12">
                  
                </CCol>
              </CRow>
            </CCardBody>
          </CCard> */}
        </CCol>
      </CRow>
    );
  }
  return <Loading/>
};
export default DischargeForm;
