import { Api, getTokenHeader } from "services/Api";
import {
  FETCH_PATIENTS,
  FETCH_PATIENTS_BASICS,
  DELETE_PATIENT,
  FETCH_PATIENT,
  FETCH_PATIENTS_SURVEY_INSTANCES,
  FETCH_PATIENT_SURVEY_INSTANCE,
  CREATE_PATIENT_SURVEY_INSTANCE,
  UPDATE_PATIENT_SURVEY_INSTANCE,
  FETCH_SURVEY_UTILITIES,
  CREATE_PATIENT_MATCHED_SURVEY_INSTANCE,
} from "./types";

const setQueryString = (filters: Array<string>) => {
  let queryString: string = "";
  Object.entries(filters).forEach(([key, value]: any) => {
    queryString += `&${key}=${value}`;
    return queryString;
  });
  return queryString;
};

export const fetchPatientsBasics = (q: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/patients/list?q=${q}`, {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_PATIENTS_BASICS, payload: response.data });
};
export const fetchPatients = (
  page: string,
  limit: string,
  filters: Object = {}
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/patients?page=${page}&limit=${limit}${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_PATIENTS, payload: response.data });
};
export const editPatientSurvey = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.put(`/api/patient/survey-instances/${id}${draft}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_PATIENT_SURVEY_INSTANCE, payload: response.data });
  //dispatch({ type: RESET_SPECIALTIES_FORM });
};

export const editMatchedPatientSurvey = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.put(`/api/admin/survey-matched-instances/${id}${draft}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_PATIENT_SURVEY_INSTANCE, payload: response.data });
  //dispatch({ type: RESET_SPECIALTIES_FORM });
};
export const createPatientSurvey = (formData: Object): Object => async (
  dispatch
) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.post(`/api/patient/survey-instances${draft}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_PATIENT_SURVEY_INSTANCE, payload: response.data });
  //dispatch({ type: RESET_SPECIALTIES_FORM });
};

export const createPatientMatchedSurvey = (formData: Object): Object => async (
  dispatch
) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.post(`/api/admin/survey-matched-instances${draft}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_PATIENT_MATCHED_SURVEY_INSTANCE, payload: response.data });
  //dispatch({ type: RESET_SPECIALTIES_FORM });
};

export const fetchPatientsSurveys = (
  page: string,
  limit: string,
  filters: Object = {}
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/patient/survey-instances?page=${page}&limit=${limit}${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_PATIENTS_SURVEY_INSTANCES, payload: response });
};

export const fetchPatientSurvey = (id: string): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/patient/survey-instances/${id}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_PATIENT_SURVEY_INSTANCE, payload: response });
};

export const fetchSurveyUtilities = () => async (dispatch) => {
  const response = await Api.get("/api/patient/surveys/utilities", {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_SURVEY_UTILITIES, payload: response.data });
};

export const fetchPatient = (id: number): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/patients/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_PATIENT, payload: response.data });
};
export const deletePatient = (id: number): Object => async (dispatch) => {
  await Api.delete(`/api/admin/patients/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_PATIENT, payload: id });
};
