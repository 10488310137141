import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CLink,
  CInput,
  CLabel,
  CSelect,
  CCollapse,
  CDropdown,
  CDropdownToggle,
  CDropdownItem,
  CDropdownMenu,
  CSwitch
} from "@coreui/react";
import Avatar from "views/partials/Avatar";
import { fetchUsers, deleteUser, fetchUtilities } from "actions/users/users";
import { fetchCities } from "actions/cities/cities";

import { LIMIT } from "constants/constants";
import CIcon from "@coreui/icons-react";
import { cilNewspaper, cilPencil, cilSearch, cilTrash } from "@coreui/icons";
import i18next from "i18next";

export const RenderSelectOptions = (options) =>
  options.map((item) => (
    <option key={item.id} value={item.id}>
      {item.name}
    </option>
  ));

const Users = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [collapse, setCollapse] = useState(false);
  const [filters, setFilters] = useState({});
  const [staffStatus, setStaffStatus] = useState(false);

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.data);
  const meta = useSelector((state) => state.users.meta);
  const utilities = useSelector((state) => state.utilities);
  const cities = useSelector((state) =>
    state.cities.data ? state.cities.data : []
  );
  const authUser = useSelector((state) => state.auth.jwt.user);
  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/users?page=${newPage}`);
    }
  };

  const handleDeleteUser = (id: string, event) => {
    event.stopPropagation();
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This User.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteUser(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const handleSearchBoxChange = (event) => {
    setFilters({ ...filters, q: event.target.value });
    dispatch(fetchUsers(page, LIMIT,staffStatus, { ...filters, q: event.target.value }));
  };

  const handleSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty: event.target.value });
    dispatch(
      fetchUsers(page, LIMIT,staffStatus, { ...filters, specialty: event.target.value })
    );
  };

  const handleLevelChange = (event) => {
    setFilters({ ...filters, level: event.target.value });
    dispatch(
      fetchUsers(page, LIMIT,staffStatus, { ...filters, level: event.target.value })
    );
  };

  const handleCountryChange = (event) => {
    setFilters({...filters, country: event.target.value });
    dispatch(
      fetchUsers(page, LIMIT,staffStatus, { ...filters, country: event.target.value })
    );
    dispatch(fetchCities(event.target.value));
  };

  const handleCityChange = (event) => {
    setFilters({...filters, city: event.target.value });
    dispatch(fetchUsers(page, LIMIT,staffStatus, { ...filters, city: event.target.value }));
  };

  const handleHospitalChange = (hospital) => {
    setFilters({...filters, hospital: hospital.id });
    dispatch(fetchUsers(page, LIMIT,staffStatus, { ...filters, hospital: hospital.id }));
  };
  const handleRoleChange = (role) => {
    setFilters({...filters, role: role.id });
    dispatch(fetchUsers(page, LIMIT,staffStatus, { ...filters, role: role.id }));
  };
  const handleFromDateChange = (event) => {
    setFilters({...filters, from: event.target.value });
    dispatch(fetchUsers(page, LIMIT, staffStatus,{ ...filters, from: event.target.value }));
  };
  const handleToDateChange = (event) => {
    setFilters({...filters, to: event.target.value });
    dispatch(fetchUsers(page, LIMIT, staffStatus,{ ...filters, to: event.target.value }));
  };
  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchUsers(page, LIMIT,staffStatus, filters));
    dispatch(fetchUtilities());
  }, [dispatch, page, staffStatus]);

  // useEffect(() => {
  //   dispatch(fetchUsers(page, LIMIT,staffStatus));
  // }, [dispatch, page, staffStatus]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">
            {i18next.t("users")}
          </div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            href="/users/create"
            className="create-button float-right wi"
          >
            {i18next.t("New User")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-center align-items-center py-2">
                <CCol xs="12" md="4">
                  <div className="search-wrap">
                    <CInput
                      className="search-input"
                      type="text"
                      placeholder={i18next.t("Search By Name , Email")}
                      onChange={handleSearchBoxChange}
                    />
                    <CIcon
                      content={cilSearch}
                      className="c-icon-xl search-icon"
                    />
                  </div>
                </CCol>
                <CCol
                  // style={{ "margin-left": "11%" }}
                  sm={12}
                  md={4}
                  xs={12}
                  lg={4}
                  xl={4}
                >
                  <CLabel
                    className="labels mx-3"
                    style={{ "vertical-align": "middle", fontSize: "1em" }}
                    htmlFor="is_staff_member"
                  >
                    {i18next.t("Show Staff only")}
                  </CLabel>
                  <CSwitch
                    style={{ "margin-left": "15%" }}
                    labelOn={i18next.t("Yes")}
                    labelOff={i18next.t("No")}
                    className="mr-3"
                    size="lg"
                    color="primary"
                    shape="pill"
                    onChange={(e) => setStaffStatus(e.target.checked ? 1 : 0)}
                  />

                </CCol>
                <CCol xs="12" md="4">
                  <CButton
                    color
                    className="advanced-search float-right"
                    onClick={() => {
                      setCollapse(!collapse);
                    }}
                  >
                    {i18next.t("Advanced Search")}
                  </CButton>
                </CCol>
              </CRow>
              <CCollapse show={collapse} className="my-3 p-3">
                <hr />
                <CRow>
                  <CCol xs="4" className="p-2">
                    <CLabel htmlFor="specialty" className="float-left labels">
                      {i18next.t("Specialty")}
                    </CLabel>
                    <CSelect
                      custom
                      name="specialty"
                      id="specialty"
                      onChange={handleSpecialtyChange}
                    >
                      <option key="0" value="" selected>
                        {i18next.t("Select Specialty")}
                      </option>
                      {RenderSelectOptions(
                        utilities.specialties ? utilities.specialties : []
                      )}
                    </CSelect>
                  </CCol>
                  <CCol xs="4" className="p-2">
                    <CLabel htmlFor="lebel" className="float-left labels">
                      {i18next.t("experience")}
                    </CLabel>
                    <CSelect
                      custom
                      name="level"
                      id="level"
                      onChange={handleLevelChange}
                    >
                      <option key="0" value="" selected>
                        {i18next.t("Select Experience")}
                      </option>
                      {RenderSelectOptions(utilities.levels || [])}
                    </CSelect>
                  </CCol>
                  <CCol xs="4" className="p-2">
                    <CLabel htmlFor="country" className="float-left labels">
                      {i18next.t("Country")}
                    </CLabel>
                    <CSelect
                      custom
                      name="country"
                      id="country"
                      onChange={handleCountryChange}
                    >
                      <option key="0" value="" selected>
                        {i18next.t("Select Country")}
                      </option>
                      {RenderSelectOptions(utilities.countries || [])}
                    </CSelect>
                  </CCol>
                  <CCol xs="4" className="p-2">
                    <CLabel htmlFor="city" className="float-left labels">
                      {i18next.t("City")}
                    </CLabel>
                    <CSelect
                      custom
                      name="city"
                      id="city"
                      onChange={handleCityChange}
                    >
                      <option key="0" value="" selected>
                        {i18next.t("Select City")}
                      </option>
                      {RenderSelectOptions(cities || [])}
                    </CSelect>
                  </CCol>
                  <CCol xs="4" className="p-2">
                    <div className="d-flex flex-column">
                      <CLabel
                        htmlFor="city"
                        className="float-left labels"
                        style={{ width: "fit-content" }}
                      >
                        {i18next.t("Location")}
                      </CLabel>
                      <Select
                        options={utilities.hospitals || []}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={handleHospitalChange}
                      />
                    </div>
                  </CCol>
                  <CCol xs="4" className="p-2">
                    <div className="d-flex flex-column">
                      <CLabel
                        htmlFor="city"
                        className="float-left labels"
                        style={{ width: "fit-content" }}
                      >
                        {i18next.t("Role")}
                      </CLabel>
                      <Select
                        options={utilities.roles || []}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={handleRoleChange}
                      />
                    </div>
                  </CCol>
                  <CCol>
                    <CLabel className="labels" htmlFor="from">
                      {i18next.t("From")}
                    </CLabel>
                    <CInput
                      type="date"
                      id="from"
                      name="from"
                      max={new Date().toISOString().substr(0, 10)}
                      onChange={handleFromDateChange}
                    />
                  </CCol>
                  <CCol>
                    <CLabel className="labels" htmlFor="to">
                      {i18next.t("To")}
                    </CLabel>
                    <CInput
                      type="date"
                      id="to"
                      name="to"
                      max={new Date().toISOString().substr(0, 10)}
                      onChange={handleToDateChange}
                    />
                  </CCol>
                </CRow>
              </CCollapse>
            </CCardHeader>
            <CCardBody>
              <CDataTable
              className="align-items-center"
                items={users}
                noItemsViewSlot={i18next.t("No Available Items")}
                fields={[

                  { key: "id", label:  i18next.t("id") },
                  { key: "avatar", label:  i18next.t("avatar") },
                  { key: "fullName", label:  i18next.t("Full Name") },
                  { key: "email", label:  i18next.t("Email") },
                  { key: "ID", label:  i18next.t("Personal Id") },
                  { key: "role", label:  i18next.t("Role") },
                  { key: "phone", label:  i18next.t("Phone") },
                  { key: "location", label:  i18next.t("Location") },
                  { key: "gender", label:  i18next.t("Gender") },
                  { key: "actions", label:  i18next.t("Actions") },
                ]}
                hover
                striped
                clickableRows
                scopedSlots={{
                  actions: (item) => (
                    <td>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle
                          className="action-toggle m-0"
                          color="light"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="c-icon"
                            role="img"
                          >
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                              className="ci-primary"
                            />
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                              className="ci-primary"
                            />
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                              className="ci-primary"
                            />
                          </svg>
                        </CDropdownToggle>
                        <CDropdownMenu className="p-0">
                          <CDropdownItem
                            href={`/users/${item.id}/edit`}
                            className="text-primary"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <CIcon content={cilPencil} />
                            <span className="mx-1">{i18next.t("Edit")}</span>
                          </CDropdownItem>
                          <CDropdownItem
                            href={`/users/${item.id}/documents`}
                            className="text-success"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <CIcon content={cilNewspaper} />
                            <span className="mx-1">{i18next.t("Documents")}</span>
                          </CDropdownItem>
                          { authUser.roles.find((element) => {
                            return element == "Super Admin";
                          }) && (
                          <CDropdownItem
                            href={`/users/${item.id}/assign`}
                            className="text-info"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <CIcon name="cilHospital" size="xm" />
                            <span className="mx-1">{i18next.t("Assign To Hospitals")}</span>
                          </CDropdownItem>
                          )}
                          <CDropdownItem
                            onClick={(event) =>
                              handleDeleteUser(item.id, event)
                            }
                            className="text-danger"
                          >
                            <CIcon content={cilTrash} />
                            <span className="mx-1">{i18next.t("Delete")}</span>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                  avatar: (item) => (
                    <td style={{ width: "12%" }}>
                      <Avatar
                        src={item.avatar}
                        style={{ width: "50px" }}
                        alt="NA"
                        className="mb-2 avatar-img"
                      />
                    </td>
                  ),
                  ID: (item) => (
                    <td>{item.uuid ? item.uuid : i18next.t("No Available Data")}</td>
                  ),
                  Gender: (item) => (
                    <td>{item.gender ? item.gender : i18next.t("No Available Data")}</td>
                  ),
                  role: (item) => (
                    <td>
                      {item.role.length === 0 ? (
                        <>{i18next.t("No Available Data")}</>
                      ) : (
                        item.role.map((role) => (
                          <>
                            {role} <br />
                          </>
                        ))
                      )}
                    </td>
                  ),
                  location: (item) => (
                    <td>
                      {item.hospitals.length === 0 ? (
                        <>{i18next.t("No Available Data")}</>
                      ) : (
                        item.hospitals.map((hospital) => (
                          <>
                            {hospital.name} <br />
                          </>
                        ))
                      )}
                    </td>
                  ),
                }}
                onRowClick={(row) => {
                  history.push(`/users/${row.id}/view`);
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Users;
