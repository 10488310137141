import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createPatientSurvey, fetchPatientsSurveys } from "actions/patients/patients";
import { listSpecialtyForms } from "actions/specialties-form/specialtiesForm";
import PublicSurveyForm from "./form/Form";
import { LIMIT } from "constants/constants";
import {FETCH_SPECIALTIES_FORM} from "actions/specialties-form/types"
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import i18next from "i18next";

const CreateSurveyInstance = ({ toggle, modal, type }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const utilities = useSelector((state) => state.surveyUtilities);
  const patient_id = useSelector((state) => state.auth.jwt.user.id);
  const publicSurvey = useSelector((state) => state.publicSurveys);
  const specialtyForm = useSelector(
    (state) => state.specialtiesForm.specialtyForm
  );
  const specialtyFormsList = useSelector(
    (state) => state.specialtiesForm.specialtyFormsList
  );
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      survey_id: specialtyForm ? specialtyForm.id : 0,
    };
    if (!data.draft) {
      confirmAlert({
        title: "Are you sure?",
        message: "that form is ready to be submitted?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(createPatientSurvey(data));
              toggle();
              dispatch(fetchPatientsSurveys(1, LIMIT));
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      dispatch(createPatientSurvey(data));
      toggle();
      dispatch(fetchPatientsSurveys(1, LIMIT));
    }

  };
  const onCancel = () => {
    toggle();
    dispatch(fetchPatientsSurveys(1, LIMIT));
  };

  const onSpecialtyFormChange = (event) => {
    const selectedForm = specialtyFormsList.find(item => item.id == event.target.value);
    dispatch({ type: FETCH_SPECIALTIES_FORM, payload: {"data": selectedForm} });
  };
  const onSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty: event.target.value });
    getSpecialtyForm({ ...filters, specialty: event.target.value });

  };
  const onLocationChange = (event) => {
    setFilters({ ...filters, location: event.target.value });
    getSpecialtyForm({ ...filters, location: event.target.value });
  };
  const getSpecialtyForm = (filters) => {
    if (filters.location && filters.specialty) {
      dispatch(listSpecialtyForms(filters.location, filters.specialty));
    }
    else {
      //reset
    }
  };

  return (
    <PublicSurveyForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal || 0}
      specialties={utilities.specialties || []}
      hospitals={utilities.hospitals || []}
      specialtyForm={specialtyForm}
      specialtyFormsList={specialtyFormsList}
      onSpecialtyFormChange={onSpecialtyFormChange}
      onSpecialtyChange={onSpecialtyChange}
      onLocationChange={onLocationChange}
      FormTitle={i18next.t("Answer Survey")}
      type={type}
      key={`create_${type}`}
    />
  );
};
export default CreateSurveyInstance;
