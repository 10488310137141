import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CLabel,
  CInput,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { fetchLabs, deleteLab } from "actions/labs/labs";
import { LIMIT } from "constants/constants";
import { cilPencil, cilSearch, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const Labs = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const labs = useSelector((state) => state.labs.data);
  const meta = useSelector((state) => state.labs.meta);

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/service/providers/labs?page=${newPage}`);
    }
  };

  const handleDeleteLevel = (id: string, e) => {
    e.stopPropagation();
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This lab.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteLab(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const handleSearchBoxChange = (event) => {
    setFilters({ q: event.target.value });
    dispatch(fetchLabs(page, LIMIT, { ...filters, q: event.target.value }));
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchLabs(page, LIMIT));
  }, [dispatch, page]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">
            {i18next.t("labs")}
          </div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            href="/service/providers/labs/create"
            className="create-button float-right wi"
          >
            {i18next.t("New Lab")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center py-2">
                <CCol xs="12" md="6">
                  <div className="search-wrap">
                    <CInput
                      className="search-input"
                      type="text"
                      placeholder={i18next.t("Search by Name")}
                      onChange={handleSearchBoxChange}
                    />
                    <CIcon
                      content={cilSearch}
                      className="c-icon-xl search-icon"
                    ></CIcon>
                  </div>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={labs || []}
                fields={[
                  // { key: "id", _classes: "font-weight-bold" },
                  { key: "name", label:  i18next.t("Name") },
                  { key: "phone", label:  i18next.t("Phone") },
                  { key: "address", label:  i18next.t("Address") },
                  { key: "actions", label:  i18next.t("Actions") },
                ]}
                noItemsViewSlot={i18next.t("No Available Items")}
                hover
                striped
                itemsPerPage={LIMIT}
                activePage={page}
                clickableRows
                scopedSlots={{
                  actions: (item) => (
                    <td>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle
                          className="action-toggle m-0"
                          color="light"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="c-icon"
                            role="img"
                          >
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                              class="ci-primary"
                            ></path>
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                              class="ci-primary"
                            ></path>
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                              class="ci-primary"
                            ></path>
                          </svg>
                        </CDropdownToggle>
                        <CDropdownMenu className="p-0">
                          <CDropdownItem
                            href={`/service/providers/labs/${item.id}/edit`}
                            className="text-primary"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <CIcon content={cilPencil} />
                            <span class="mx-1">{i18next.t("Edit")}</span>
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={(e) => handleDeleteLevel(item.id, e)}
                            className="text-danger"
                          >
                            <CIcon content={cilTrash} />
                            <span class="mx-1">{i18next.t("Delete")}</span>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                }}
                onRowClick={(row) => {
                  history.push(`/service/providers/labs/${row.id}/show`);
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Labs;
