import React from "react";
import ReactFlow, { MiniMap, Controls, Background } from "react-flow-renderer";
import { hierarchyToReactFlow } from "helpers/helpers";

const nodeStyle = {
  background: "#fff",
  color: "#3f5192",
  padding: "25px",
  borderRadius: "8px",
  border: "1px solid #000",
  width: "500px",
  height: "auto",
  display: "flex",
  flexDirection: "column",
};

const gridContainerStyle = {
  display: "grid",
  gridTemplateColumns: "2fr 1fr", // 2/3 and 1/3 split
  gap: "10px",
  textAlign: "left",
};

// const summaryStyle = {
//   fontSize: "12px",
//   display: "flex",
//   flexDirection: "column",
//   gap: "5px",
// };

const clinicsStyle = {
  fontSize: "12px",
  backgroundColor: "#F4F6F8",
  padding: "10px",
  borderRadius: "8px",
  overflowY: "scroll",
  height: "300px"
};

const createNodeLabel = (data) => (
  <div style={nodeStyle}>
    <div style={gridContainerStyle}>
      <div style={clinicsStyle}>
        <div
          style={{
            fontWeight: "bold",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          {data.specialty.hospital_name}
        </div>
        <div style={{ marginBottom: "10px", textAlign: "center" }}>
        </div>
        <div>
          {data.specialty.specialty_name} Inpatients: {data.specialty.in_patients}
        </div>
        <div>Completed Follow Up Notes: {data.specialty.follow_up_notes}</div>
        <div>Radiology: {data.specialty.radiology}</div>
        <div>Labs: {data.specialty.lab}</div>
        <div>Requested Consult.: {data.specialty.requested_consult}</div>
        <div>Received Consult.: {data.specialty.received_consult}</div>
      </div>
      <div style={clinicsStyle}>
        <strong>My Clinics Today</strong>
        {data.clinics.map(c => (<>
          <br />
          {c.title}: {c.type}
          <br />
          {c.appointments_count} Patients
          <br />
          Time: {c.from} - {c.to}
          <br />
        </>))}
      </div>
    </div>
  </div>
);

const DoctorFigures = ({ dashboard }) => {
  const hierarchy = {...dashboard.data, hospital_id: 0, hospitals: Object.values(dashboard.data_for_hospitals)};
  const {nodes, edges} = hierarchyToReactFlow(hierarchy, createNodeLabel, 530, 400);
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <ReactFlow nodes={nodes} edges={edges} fitView>
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default DoctorFigures;
