import { Api, getTokenHeader } from "services/Api";

import { FETCH_MEDICAL_HISTORY, UPDATE_MEDICAL_HISTORY } from "./types";

export const fetchMedicalHistory = (episodeId: string): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/episodes/${episodeId}/medical-history`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_MEDICAL_HISTORY, payload: response.data });
};

export const updateMedicalHistory = (
  formData: Object,
  episodeId: string
): Object => async (dispatch) => {
  const response = await Api.put(
    `/api/admin/episodes/${episodeId}/medical-history`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: UPDATE_MEDICAL_HISTORY, payload: response.data });
};
