import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import i18next from "i18next";
import {
  CCol,
  CRow,
  CLabel,
  CSelect,
  CButton,
  CCardTitle,
  CFormGroup,
} from "@coreui/react";
import Select from "react-select";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { getClassName, customStyles } from "./RoundFormValidation";
import CIcon from "@coreui/icons-react";

const SpokesSection = ({
  control,
  register,
  handleSpokeChange,
  spokes,
  consultants,
  ValidationRules,
  errors,
  setValue
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "spokes",
  });
  const renderSpokesFields = () =>
    fields.map((row, index) => (
      <CCol md="12" key={row.id}>
        <CFormGroup row>
          <CCol
            sm={12}
            md={5}
            xs={12}
            lg={5}
            xl={5}
            className="d-flex flex-column"
          >
            <CLabel className="labels required">{i18next.t("Location")}</CLabel>
            <CSelect
              custom
              innerRef={register}
              name={`spokes[${index}].spoke`}
              defaultValue={row.spoke}
              onClick={(event) => {
                setValue(`spokes[${index}].consultants`, '')
                handleSpokeChange(event, index);
              }}
            >
              <option key="0" value="" selected disabled>
                {i18next.t("Select Spoke")}
              </option>
              {RenderSelectOptions(spokes)}
            </CSelect>
          </CCol>
          <CCol
            sm={12}
            md={5}
            xs={12}
            lg={5}
            xl={5}
            className="d-flex flex-column"
          >
            <CLabel className="float-left w-fcontent labels required">
              {i18next.t("Presenters")}
            </CLabel>
            <Controller
              styles={customStyles(!errors.mandatory_speakers)}
              control={control}
              name={`spokes[${index}].consultants`}
              autocomplete
              isMulti
              options={consultants[index]}
              getOptionLabel={(option) =>
                option.name
                  ? option.name
                  : `${option.first_name} ${option.last_name}`
              }
              getOptionValue={(option) => option.id}
              as={Select}
            />
          </CCol>
          <CCol
            style={{ alignSelf: "center", marginTop: "2em" }}
            sm={12}
            md={2}
            xs={12}
            lg={2}
            xl={2}
          >
            <CButton color="danger" onClick={() => remove(index)}>
              {i18next.t("Remove")}
            </CButton>
          </CCol>
        </CFormGroup>
      </CCol>
    ));

  return (
    <>
      <CCol className="text-align-center">
        <CButton
          style={{
            "background-color": "rgb(48, 197, 182)",
            "border-color": "rgb(48, 197, 182)",
            width: "11em",
            marginBottom: "2em",
          }}
          color="info"
          onClick={() => append({})}
        >
          <CIcon name="cil-pencil" /> {i18next.t("Add Location")}
        </CButton>
      </CCol>
      <br></br>
      {renderSpokesFields()}
    </>
  );
};
export default SpokesSection;
