import React from "react";
import { useSelector } from "react-redux";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CButton,
} from "@coreui/react";

import { OLD_PRESCRIPTION, LABS } from "constants/constants";
import i18next from "i18next";

const RadOrders = ({ setActiveScreen }) => {
  const fields = [
    { key: "name", label:  i18next.t("Name") },
    { key: "date", label:  i18next.t("date") },
  ];
  const radInvestigations = useSelector(
    (state) => state.investigation.radInvestigations
  );

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="table-title">{i18next.t("Radiology Orders")}</div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CCardBody>
              <CDataTable
                items={radInvestigations}
                fields={fields}
                striped
                noItemsViewSlot={i18next.t("No Available Items")}
                itemsPerPage={5}
                pagination
                scopedSlots={{
                  name: (item) => (
                    <td>{`${item.investigation_types
                      .map((item) => item.name)
                      .toString()}`}</td>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
          <div className="my-2 float-right">
            <CButton
              style={{
                backgroundColor: "#30c5b6",
                borderColor: "#30c5b6",
                color: "white",
                "width":"7em"
              }}
              className="mx-1"
              onClick={() => setActiveScreen(LABS)}
            >
              {i18next.t("Previous")}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </>
  );
};
export default RadOrders;
