import { cilMic, cilMinus, cilVideo, cilVolumeHigh, cilVolumeLow, cilVolumeOff } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react";
import React, { useState, useEffect, useRef } from "react";

const Participant = ({ participant, isLocalParticipant }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoStoped, setIsVideoStoped] = useState(false);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => {
        if (track !== null) {
          track.on("enabled", () => {
            // do something with the UI here
            console.log("enable");
          });
          track.on("disabled", () => {
            // do something with the UI here
            console.log("track was disabled");
          });
          return track;
        }
      });

  useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
      track.on("enabled", () => {
        // do something with the UI here
        console.log("track was enabled");
      });
      track.on("disabled", () => {
        console.log("track was disabled");
        // do something with the UI here
      });
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) =>
          videoTracks.filter((item) => item !== track)
        );
      } else {
        setAudioTracks((audioTracks) =>
          audioTracks.filter((item) => item !== track)
        );
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    if (isLocalParticipant) {
      participant.videoTracks.forEach((publication) =>
        publication.track.disable()
      );
      setIsVideoStoped(true);
    }

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [isLocalParticipant, participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  const mute = (e) => {
    console.log(e);
    if (e == "audio" && isLocalParticipant) {
      participant.audioTracks.forEach((publication) =>
        publication.track.disable()
      );
      setIsAudioMuted(true);
    }

    if (e == "video" && isLocalParticipant) {
      participant.videoTracks.forEach((publication) =>
        publication.track.disable()
      );
      setIsVideoStoped(true);
    }
  };

  const unMute = (e) => {
    if (e == "audio" && isLocalParticipant) {
      participant.audioTracks.forEach((publication) =>
        publication.track.enable()
      );
      setIsAudioMuted(false);
    }

    if (e == "video" && isLocalParticipant) {
      participant.videoTracks.forEach((publication) =>
        publication.track.enable()
      );
      setIsVideoStoped(false);
    }
  };

  const renderAudioControl = () => {
    let audioButton = (
      // <button
      //   id="mute-unmute-audio-button"
      //   value="audio"
      //   className="btn btn-primary"
      //   onClick={mute}
      // >
      //   Mute Audio
      // </button>
      <CButton
        className="audio-video-btn"
        onClick={() => mute("audio")}
        value="audio"
      >
        {!isAudioMuted && (
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M128,176a48,48,0,0,0,48-48V64a48,48,0,0,0-96,0v64A48,48,0,0,0,128,176Z"></path><path d="M200.4,128.1a8,8,0,0,0-8.8,7,64,64,0,0,1-127.2,0,8,8,0,0,0-8.8-7,7.9,7.9,0,0,0-7.1,8.8A79.7,79.7,0,0,0,120,207.3V232a8,8,0,0,0,16,0V207.3a79.7,79.7,0,0,0,71.5-70.4A7.9,7.9,0,0,0,200.4,128.1Z"></path></svg>
        )}
        {isAudioMuted && (
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M213.9,210.6l-160-176A8,8,0,0,0,42.1,45.4L80,87.1V128a48,48,0,0,0,73.9,40.4l10.9,12A64,64,0,0,1,64.4,135.1a8,8,0,1,0-15.9,1.8A79.9,79.9,0,0,0,120,207.6V232a8,8,0,0,0,16,0V207.6a79.2,79.2,0,0,0,39.6-15.3l26.5,29.1A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1A7.9,7.9,0,0,0,213.9,210.6Z"></path><path d="M162.1,129.8a7.9,7.9,0,0,0,5.9,2.6,7.4,7.4,0,0,0,2.9-.5,8,8,0,0,0,5.1-7.5V64a48,48,0,0,0-48-48A47.5,47.5,0,0,0,87.2,38.8a8,8,0,0,0,.8,9.6Z"></path><path d="M192.2,161.7a9.3,9.3,0,0,0,2.9.5,7.8,7.8,0,0,0,7.4-5.1,78.7,78.7,0,0,0,5-20.2,8,8,0,1,0-15.9-1.8,60.1,60.1,0,0,1-4,16.2A8.1,8.1,0,0,0,192.2,161.7Z"></path></svg>
        )}
      </CButton>
    );
    if (isAudioMuted) {
      audioButton = (
        <CButton
          className="audio-video-btn"
          onClick={() => unMute("audio")}
          value="audio"
        >
          {!isAudioMuted && (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M128,176a48,48,0,0,0,48-48V64a48,48,0,0,0-96,0v64A48,48,0,0,0,128,176Z"></path><path d="M200.4,128.1a8,8,0,0,0-8.8,7,64,64,0,0,1-127.2,0,8,8,0,0,0-8.8-7,7.9,7.9,0,0,0-7.1,8.8A79.7,79.7,0,0,0,120,207.3V232a8,8,0,0,0,16,0V207.3a79.7,79.7,0,0,0,71.5-70.4A7.9,7.9,0,0,0,200.4,128.1Z"></path></svg>
          )}
          {isAudioMuted && (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M213.9,210.6l-160-176A8,8,0,0,0,42.1,45.4L80,87.1V128a48,48,0,0,0,73.9,40.4l10.9,12A64,64,0,0,1,64.4,135.1a8,8,0,1,0-15.9,1.8A79.9,79.9,0,0,0,120,207.6V232a8,8,0,0,0,16,0V207.6a79.2,79.2,0,0,0,39.6-15.3l26.5,29.1A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1A7.9,7.9,0,0,0,213.9,210.6Z"></path><path d="M162.1,129.8a7.9,7.9,0,0,0,5.9,2.6,7.4,7.4,0,0,0,2.9-.5,8,8,0,0,0,5.1-7.5V64a48,48,0,0,0-48-48A47.5,47.5,0,0,0,87.2,38.8a8,8,0,0,0,.8,9.6Z"></path><path d="M192.2,161.7a9.3,9.3,0,0,0,2.9.5,7.8,7.8,0,0,0,7.4-5.1,78.7,78.7,0,0,0,5-20.2,8,8,0,1,0-15.9-1.8,60.1,60.1,0,0,1-4,16.2A8.1,8.1,0,0,0,192.2,161.7Z"></path></svg>
          )}
        </CButton>
      );
    }
    return isLocalParticipant ? audioButton : <></>;
  };

  const renderVideoControl = () => {
    let videoButton = (
      // <button
      //   id="mute-unmute-video-button"
      //   value="video"
      //   className="btn btn-primary"
      //   onClick={mute}
      // >
      //   Stop Video
      // </button>
      <CButton
        className="audio-video-btn"
        onClick={() => mute("video")}
        value="video"
      >
        {!isVideoStoped && (
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M176,92v96a16,16,0,0,1-16,16H48A40,40,0,0,1,8,164V68A16,16,0,0,1,24,52H136A40,40,0,0,1,176,92Zm68-18.9a7.7,7.7,0,0,0-8,0L196,95.9a8,8,0,0,0-4,7v50.2a8,8,0,0,0,4,7l40,22.8a8.3,8.3,0,0,0,4,1.1,8,8,0,0,0,4-1.1,7.8,7.8,0,0,0,4-6.9V80A7.8,7.8,0,0,0,244,73.1Z"></path></svg>
        )}
        {isVideoStoped && (
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M229.9,226.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,224,240a8,8,0,0,1-5.9-2.6l-42.4-46.6A15.9,15.9,0,0,1,160,204H24A16,16,0,0,1,8,188V68A16,16,0,0,1,24,52H49.6L29,29.4a8,8,0,0,1,.5-11.3,7.9,7.9,0,0,1,11.3.5ZM245.5,74.2a7.9,7.9,0,0,0-9.5-1.1L196,95.9a8,8,0,0,0-4,7v50.2a8,8,0,0,0,4,7l40,22.8a8.3,8.3,0,0,0,4,1.1,7.9,7.9,0,0,0,5.8-2.5,8,8,0,0,0,2.2-5.7V80.2A8,8,0,0,0,245.5,74.2Zm-83.4,54a8,8,0,0,0,9.8,1.6,8.2,8.2,0,0,0,4.1-7.3V68a16,16,0,0,0-16-16H111.1a8.5,8.5,0,0,0-6.6,3.1,8,8,0,0,0,.5,10.3Z"></path></svg>
        )}
      </CButton>
    );
    if (isVideoStoped) {
      videoButton = (
        <CButton
          className="audio-video-btn"
          onClick={() => unMute("video")}
          value="video"
        >
          {!isVideoStoped && (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M176,92v96a16,16,0,0,1-16,16H48A40,40,0,0,1,8,164V68A16,16,0,0,1,24,52H136A40,40,0,0,1,176,92Zm68-18.9a7.7,7.7,0,0,0-8,0L196,95.9a8,8,0,0,0-4,7v50.2a8,8,0,0,0,4,7l40,22.8a8.3,8.3,0,0,0,4,1.1,8,8,0,0,0,4-1.1,7.8,7.8,0,0,0,4-6.9V80A7.8,7.8,0,0,0,244,73.1Z"></path></svg>
          )}
          {isVideoStoped && (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M229.9,226.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,224,240a8,8,0,0,1-5.9-2.6l-42.4-46.6A15.9,15.9,0,0,1,160,204H24A16,16,0,0,1,8,188V68A16,16,0,0,1,24,52H49.6L29,29.4a8,8,0,0,1,.5-11.3,7.9,7.9,0,0,1,11.3.5ZM245.5,74.2a7.9,7.9,0,0,0-9.5-1.1L196,95.9a8,8,0,0,0-4,7v50.2a8,8,0,0,0,4,7l40,22.8a8.3,8.3,0,0,0,4,1.1,7.9,7.9,0,0,0,5.8-2.5,8,8,0,0,0,2.2-5.7V80.2A8,8,0,0,0,245.5,74.2Zm-83.4,54a8,8,0,0,0,9.8,1.6,8.2,8.2,0,0,0,4.1-7.3V68a16,16,0,0,0-16-16H111.1a8.5,8.5,0,0,0-6.6,3.1,8,8,0,0,0,.5,10.3Z"></path></svg>
          )}
        </CButton>
      );
    }
    return isLocalParticipant ? videoButton : <></>;
  };

  return (
    <>
      <video
        ref={videoRef}
        autoPlay
        className={!isLocalParticipant ? "patient-img" : "doctor-img"}
      />
      <div className="audio-video-wrap d-flex flex-row">
        {renderAudioControl()}
        {renderVideoControl()}
      </div>
      <audio ref={audioRef} autoPlay muted={false} />
    </>
  );
};

export default Participant;
