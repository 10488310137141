import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CONDITION_TYPE_SURVEY,
  CONDITION_TYPE_CLINIC,
  CONDITION_TYPE_EPISODE,
  CONDITION_TYPE_ALL_TYPES,
} from "constants/sentSurvey";
import { CLINIC_TYPE_PHYSICAL, CLINIC_TYPE_VIRTUAL } from "constants/clinic";
import { listSpecialtyForms,listSpecialtyFormsCondition } from "actions/specialties-form/specialtiesForm";
import { fetchDoctors } from "actions/doctors/doctors";
import { useSelector, useDispatch } from "react-redux";
import { fetchDoctorClinicsSurvey } from "actions/appointments/appointment";

import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CInput,
  CSelect,
  CInputRadio,
  CCardTitle,
  CCardSubtitle,
} from "@coreui/react";

import RenderSelectOptions from "views/partials/RenderSelectOptions";
import i18next from "i18next";
import { getClassName } from "views/pages/register/RegisterFormValidation";
import getValidation from "views/clinics/form/ClinicFormValidation";
import { Api, getIcdTokenHeader } from "services/Api";
import { icdAuth } from "actions/icd/icd";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { rest } from "lodash";
import { reset } from "enzyme/build/configuration";

const SendForm = ({
  onSubmit,
  modal,
  FormTitle,
  onCancel,
  specialties,
  hospitals,
  cities,
  prescriptionUtilities,
  countries,
  specialtyFormsList,
  specialtyFormsList_2,
  onSpecialtyChange,
  onLocationChange,
  onCountryChange,
  onConditionLocationChange,
  onConditionSpecialtyChange,
  onDoctorChange,
  doctors,
  clinics,
  onSelectTypeClinic,
  SendFormData,
  sentPublicSurvey,
}) => {
  const [ConditionType, setConditionType] = useState(CONDITION_TYPE_ALL_TYPES);
  let defaultValues = {};
  if (sentPublicSurvey) {
    defaultValues = {
      ...sentPublicSurvey,

    };
  }
  console.log("defaultValues", defaultValues);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(icdAuth());
    if (sentPublicSurvey) {
      setConditionType(sentPublicSurvey.condition_type);
      dispatch(
        listSpecialtyForms(
          sentPublicSurvey.location_id,
          sentPublicSurvey.specialty_id
        )
      );
      if (
        sentPublicSurvey.condition_location_id &&
        sentPublicSurvey.condition_specialty_id
      ) {
        dispatch(
          listSpecialtyFormsCondition(
            sentPublicSurvey.condition_location_id,
            sentPublicSurvey.condition_specialty_id
            ));
        dispatch(
          fetchDoctors({
            hospital:sentPublicSurvey.condition_location_id,
            specialty: sentPublicSurvey.condition_specialty_id
            }));
      }
      if (
        sentPublicSurvey.condition_doctor_id 
      ) {
        if(sentPublicSurvey.condition_clinic_id){
          dispatch(fetchDoctorClinicsSurvey(sentPublicSurvey.condition_doctor_id , sentPublicSurvey.condition_clinic_type));
        }
      }
      reset({...sentPublicSurvey, parent_id:sentPublicSurvey.id})
  }
  }, [dispatch, reset, sentPublicSurvey]);

 
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    errors,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues
  });
  const Validation = getValidation(watch);
  const ref = useRef(null);
  const saveDraft = () => {
    setValue("draft", 2);
    const buttonRef = ref.current && ref.current.children[0];
    buttonRef.click();
  };
  const [icdErrorMsg, setIcdErrorMessage] = useState(false);
  const getIcdOptions = async (inputValue) => {
    const url = `https://id.who.int/icd/release/11/2021-05/mms/search?q=${inputValue}`;
    const response = await Api.get(url, getIcdTokenHeader());
    console.log("get ICD11 options", response);
    if (response.data.error) {
      setIcdErrorMessage(response.data.errorMessage);
      return [];
    }
    setIcdErrorMessage(false);
    return response.data.destinationEntities
      ? response.data.destinationEntities
      : [];
  };
  const removeHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");
  const [ClinicType, setClinicType] = useState("all");
  const handelOnchange = (e) => {
    setConditionType(e.target.value);
  };
  const handelOnchangeTypeClinic = (e) => {
    setClinicType(e.target.value);
    onSelectTypeClinic(e.target.value);
  };

  const onClose = () => {
    setConditionType(CONDITION_TYPE_ALL_TYPES);
    setClinicType("all");
    
  };
  const renderType = () => {
    console.log(ConditionType);

    // eslint-disable-next-line eqeqeq
    if (ConditionType == CONDITION_TYPE_SURVEY) {
      return (
        <CCol xs="12">
          <CLabel
            className="labels"
            htmlFor="condition_survey"
            style={{ "margin-top": "0.5em" }}
          >
            {i18next.t("Survey")}
          </CLabel>
          <CSelect
            custom
            name="condition_survey_id"
            id="condition_survey"
            innerRef={register(Validation.con_survey_id)}
          >
            <option value="">{i18next.t("Select Survey")}</option>
            {RenderSelectOptions(specialtyFormsList_2 || [])}
          </CSelect>
          <CLabel
            className="labels"
            htmlFor="answered_from"
            style={{ "margin-top": "0.5em" }}
          >
            {i18next.t("Answered From")}
          </CLabel>
          <CInput
            type="date"
            id="answered_from"
            name="condition_date_from"
            max={new Date().toISOString().substr(0, 10)}
          />
          <CLabel
            className="labels"
            htmlFor="answered_to"
            style={{ "margin-top": "0.5em" }}
          >
            {i18next.t("Answered To")}
          </CLabel>
          <CInput
            type="date"
            id="answered_to"
            name="condition_date_to"
            max={new Date().toISOString().substr(0, 10)}
          />
        </CCol>
      );
    }
    // eslint-disable-next-line eqeqeq
    if (ConditionType == CONDITION_TYPE_CLINIC) {
      console.log(ConditionType);

      return (
        <CCol xs="12">
          <CLabel className="labels" style={{ "margin-top": "0.5em" }}>
            {i18next.t("Doctor")}
          </CLabel>
          <CSelect
            custom
            className={`${getClassName(
              "condition_doctor_id",
              "condition_doctor_id",
              watch,
              errors
            )}`}
            name="condition_doctor_id"
            id="condition_doctor_id"
            onChange={onDoctorChange}
            innerRef={register}
          >
            <option value="">{i18next.t("Select doctor")}</option>
            {RenderSelectOptions(doctors || [])}
          </CSelect>
          <CCol xs="12">
            <CFormGroup
              variant="custom-radio"
              inline
              style={{ "margin-top": "0.5em" }}
            >
              <CInputRadio
                className={`mb-3 ${getClassName(
                  "condition_clinic_type",
                  "condition_clinic_type",
                  watch,
                  errors
                )}`}
                custom
                id="virtual"
                name="condition_clinic_type"
                value={CLINIC_TYPE_VIRTUAL}
                innerRef={register(Validation.type)}
                onClick={(e) => handelOnchangeTypeClinic(e)}
              />
              <CLabel variant="custom-checkbox" htmlFor="virtual">
                {i18next.t("Virtual")}
              </CLabel>
            </CFormGroup>
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                className={`mb-3 ${getClassName(
                  "condition_clinic_type",
                  "condition_clinic_type",
                  watch,
                  errors
                )}`}
                custom
                id="physical"
                name="condition_clinic_type"
                value={CLINIC_TYPE_PHYSICAL}
                innerRef={register(Validation.type)}
                onClick={(e) => handelOnchangeTypeClinic(e)}
              />
              <CLabel variant="custom-checkbox" htmlFor="physical">
                {i18next.t("Physical")}
              </CLabel>
            </CFormGroup>
          </CCol>

          <CLabel className="labels" style={{ "margin-top": "0.5em" }}>
            {i18next.t("Clinic")}
          </CLabel>

          <CSelect
            custom
            className={`${getClassName(
              "condition_clinic_id",
              "condition_clinic_id",
              watch,
              errors
            )}`}
            name="condition_clinic_id"
            id="condition_clinic_id"
            innerRef={register}
          >
            <option value="">{i18next.t("Select clinc")}</option>
            {RenderSelectOptions(clinics|| [])}
          </CSelect>
          <CLabel
            className="labels"
            htmlFor="visited_from"
            style={{ "margin-top": "0.5em" }}
          >
            {i18next.t("Visited From")}
          </CLabel>
          <CInput
            type="date"
            id="visited_from"
            name="condition_date_from"
            max={new Date().toISOString().substr(0, 10)}
            innerRef={register}
          />
          <CLabel
            className="labels"
            htmlFor="visited_to"
            style={{ "margin-top": "0.5em" }}
          >
            {i18next.t("Visited To")}
          </CLabel>
          <CInput
            type="date"
            id="visited_to"
            name="condition_date_to"
            max={new Date().toISOString().substr(0, 10)}
            innerRef={register}
          />
        </CCol>
      );
    }
    if (ConditionType == CONDITION_TYPE_EPISODE) {
      return (
        <CCol xs="12">
          <CLabel
            className="labels"
            htmlFor="admitted_from"
            style={{ "margin-top": "0.5em" }}
          >
            {i18next.t("Admitted From")}
          </CLabel>
          <CInput
            type="date"
            id="admitted_from"
            name="condition_date_from"
            max={new Date().toISOString().substr(0, 10)}
            innerRef={register}
          />
          <CLabel
            className="labels"
            htmlFor="admitted_to"
            style={{ "margin-top": "0.5em" }}
          >
            {i18next.t("Admitted To")}
          </CLabel>
          <CInput
            type="date"
            id="admitted_to"
            name="condition_date_to"
            max={new Date().toISOString().substr(0, 10)}
            innerRef={register}
          />
        </CCol>
      );
    }
    return "";
  };

  return modal ? (
    <>
      <CModalHeader>
        {/* <CModalTitle>Send Survey</CModalTitle> */}
      </CModalHeader>
      <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
        <CModal show={modal} size="lg" onClose={onClose}>
          <h3 className="header-label-title">{FormTitle}</h3>
          {/* <CModalTitle className="header-label-title"><strong>{FormTitle}</strong></CModalTitle> */}

          <CModalBody>
            <CModalTitle>
              <h4 className="header-label">{i18next.t("Select Survey")}</h4>
            </CModalTitle>
            <CFormGroup row>
              <CCol xs="12" md="6">
                <CLabel className="labels required" htmlFor="locations_id">
                  {i18next.t("locations")}
                </CLabel>
                <CSelect
                  className={`mb-3 ${getClassName(
                    "location_id",
                    "location_id",
                    watch,
                    errors
                  )}`}
                  custom
                  name="location_id"
                  id="locations_id"
                  onChange={onLocationChange}
                  innerRef={register(Validation.locations_id)}
                  // value={sentPublicSurvey?.location?.name }
                >
                  <option key="0" value="">
                    {i18next.t("Select Location")}
                  </option>
                  {RenderSelectOptions(hospitals)}
                </CSelect>
              </CCol>

              <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                <CLabel className="labels required">
                  {i18next.t("Select Specialty")}
                </CLabel>
                <CSelect
                  className={`${getClassName(
                    "specialty_id",
                    "Select Specialty",
                    watch,
                    errors
                  )}`}
                  custom
                  name="specialty_id"
                  id="specialty"
                  innerRef={register(Validation.specialty_id)}
                  onChange={onSpecialtyChange}
                >
                  <option key="0" value="">
                    {i18next.t("Select Specialty")}
                  </option>
                  {RenderSelectOptions(specialties)}
                </CSelect>
              </CCol>

              <CCol xs={12} sm={12} md={12}>
                <CLabel className="labels required">
                  {i18next.t("Select Survey")}
                </CLabel>
                <CSelect
                  className={`${getClassName(
                    "survey_id",
                    "Select Survey",
                    watch,
                    errors
                  )}`}
                  custom
                  name="survey_id"
                  id="survey"
                  innerRef={register(Validation.survey_id)}
                >
                  <option key="0" value="">
                    {i18next.t("Select Survey")}
                  </option>
                  {RenderSelectOptions(specialtyFormsList || [])}
                </CSelect>
              </CCol>

              <CCol xs="12" md="6">
                <CLabel className="labels " style={{ "margin-top": "0.5em" }}>
                  {i18next.t("Once Every")}:
                </CLabel>
                <CInput
                  type="number"
                  id="duration"
                  name="duration"
                  min={1}
                  innerRef={register}
                  value={sentPublicSurvey?.duration}
                />
              </CCol>
              <CCol xs="12" md="6">
                <CLabel className="labels" style={{ "margin-top": "0.5em" }}>
                  {" "}
                  {i18next.t("Time Unit")}
                </CLabel>
                <CSelect
                  custom
                  name="duration_unit"
                  id="frequency_time_unit"
                  innerRef={register}
                  // value={sentPublicSurvey?.duration_unit}
                >
                  <option value="">{i18next.t("Time Unit")}</option>
                  {RenderSelectOptions(prescriptionUtilities.time_units)}
                </CSelect>
              </CCol>
              <CCol xs="12">
                <CModalTitle>
                  <h4 className="header-label">{i18next.t("General Conditions")}</h4>
                </CModalTitle>
              </CCol>
              <CCol xs="12" md="4">
                <CLabel className="float-left labels" htmlFor="country">
                  {i18next.t("Country")}
                </CLabel>
                <CSelect
                  className={`${getClassName(
                    "country",
                    "country",
                    watch,
                    errors
                  )}`}
                  innerRef={register}
                  custom
                  name="country"
                  id="country"
                  onChange={onCountryChange}
                >
                  <option key="0" value="">
                    {i18next.t("Select Country")}
                  </option>
                  {SendFormData && SendFormData.countryobj
                    ? RenderSelectOptions([SendFormData.countryobj])
                    : RenderSelectOptions(countries)}
                </CSelect>
                <CLabel className="labels" style={{ "margin-top": "0.5em" }}>
                  {i18next.t("Age From")}:
                </CLabel>
                <CInput
                  type="number"
                  id="age_from"
                  name="age_from"
                  min={0}
                  innerRef={register}
                  // value={sentPublicSurvey?.age_from}
                />

                <CLabel
                  className="labels"
                  htmlFor="registered_from"
                  style={{ "margin-top": "0.5em" }}
                >
                  {i18next.t("Registered From")}
                </CLabel>
                <CInput
                  type="date"
                  id="registered_from"
                  name="created_from"
                  max={new Date().toISOString().substr(0, 10)}
                  innerRef={register}
                />
              </CCol>
              <CCol xs="12" md="4">
                <CLabel className="float-left labels" htmlFor="city">
                  {i18next.t("City")}
                </CLabel>
                <CSelect
                  // className={`${getClassName("city", "city", watch, errors)}`}
                  custom
                  name="city"
                  id="city"
                  innerRef={register}
                >
                  <option key="0" value="">
                    {i18next.t("Select City")}
                  </option>
                  {SendFormData && SendFormData.cityobj
                    ? RenderSelectOptions([SendFormData.cityobj])
                    : RenderSelectOptions(cities)}
                </CSelect>
                <CLabel className="labels" style={{ "margin-top": "0.5em" }}>
                  {i18next.t("Age To")}:
                </CLabel>
                <CInput
                  custom
                  type="number"
                  id="age_to"
                  name="age_to"
                  min={0}
                  innerRef={register}
                  // value={sentPublicSurvey?.age_to}
                />
                <CLabel
                  className="labels"
                  htmlFor="registered_to"
                  style={{ "margin-top": "0.5em" }}
                >
                  {i18next.t("Registered To")}
                </CLabel>
                <CInput
                  type="date"
                  id="registered_to"
                  name="created_to"
                  max={new Date().toISOString().substr(0, 10)}
                  innerRef={register}
                />
              </CCol>
              <CCol xs="12" md="4">
                <CLabel className="labels ">{i18next.t("Gender")}</CLabel>
                <CSelect
                  custom
                  name="gender"
                  id="gender"
                  innerRef={register}
                  value={sentPublicSurvey?.gender}
                >
                  <option key="0" value="">
                    {i18next.t("Select Gender")}
                  </option>

                  <option key="1" value="male">
                    {i18next.t("Male")}
                  </option>
                  <option key="2" value="female">
                    {i18next.t("Female")}
                  </option>
                </CSelect>
              </CCol>
              <CCol xs="12">
                <CLabel className="labels" style={{ "margin-top": "0.5em" }}>
                  {i18next.t("ICD Codes")}
                </CLabel>
                <Controller
                  control={control}
                  id="diagnostics"
                  name="diagnostics"
                  isMulti
                  cacheOptions
                  loadOptions={(inputValue) => getIcdOptions(inputValue)}
                  getOptionLabel={(option) =>
                    `${
                      option.theCode ? option.theCode : option.code
                    } : ${removeHtmlTags(option.title)}`
                  }
                  getOptionValue={(option) => option.id}
                  as={AsyncSelect}
                />
                {icdErrorMsg && (
                  <span className="alert-danger">{icdErrorMsg}</span>
                )}
              </CCol>

              <CCol xs="12">
                <CModalTitle>
                  <h4 className="header-label">{i18next.t("Specific Conditions")}</h4>
                </CModalTitle>
              </CCol>
              <CCol xs="6">
                <CLabel className="labels" htmlFor="condition_location_id">
                  {i18next.t("locations")}
                </CLabel>
                <CSelect
                  custom
                  id="condition_location_id"
                  name="condition_location_id"
                  innerRef={register}
                  onChange={onConditionLocationChange}
                  // value={sentPublicSurvey?.condition_location?.name}
                >
                  <option key="0" value="">
                    {i18next.t("Select Condition Location")}
                  </option>
                  {RenderSelectOptions(hospitals)}
                </CSelect>
              </CCol>
              <CCol xs="16">
                <CLabel className="labels" htmlFor="condition_specialty_id">
                  {i18next.t("Specialty")}
                </CLabel>
                <CSelect
                  custom
                  id="condition_specialty_id"
                  name="condition_specialty_id"
                  innerRef={register}
                  onChange={onConditionSpecialtyChange}
                >
                  <option key="0" value="">
                    {i18next.t("Select Condition Specialty")}
                  </option>
                  {RenderSelectOptions(specialties)}
                </CSelect>
              </CCol>
            </CFormGroup>

            <CCol xs="12" md="12">
              <CRow
                style={{
                  flex: 2,
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <CFormGroup variant="custom-radio" inline>
                  <CInputRadio
                    custom
                    
                    id="conditin_survey"
                    name="condition_type"
                    value={CONDITION_TYPE_SURVEY}
                    innerRef={register}
                    onClick={(e) => handelOnchange(e)}
                  />
                  <CLabel variant="custom-checkbox" htmlFor="conditin_survey">
                    {i18next.t("Survey")}
                  </CLabel>
                </CFormGroup>
                <CFormGroup variant="custom-radio" inline>
                  <CInputRadio
                    custom
                    
                    id="conditin_clinic"
                    name="condition_type"
                    value={CONDITION_TYPE_CLINIC}
                    innerRef={register}
                    onClick={(e) => handelOnchange(e)}
                  />
                  <CLabel variant="custom-checkbox" htmlFor="conditin_clinic">
                    {i18next.t("Clinic")}
                  </CLabel>
                </CFormGroup>
                <CFormGroup variant="custom-radio" inline>
                  <CInputRadio
                    custom
                    
                    id="conditin_episode"
                    name="condition_type"
                    value={CONDITION_TYPE_EPISODE}
                    innerRef={register}
                    onClick={(e) => handelOnchange(e)}
                  />
                  <CLabel variant="custom-checkbox" htmlFor="conditin_episode">
                    {i18next.t("episode")}
                  </CLabel>
                </CFormGroup>
              </CRow>

              <CCol xs="12" md="12">
                {renderType()}
              </CCol>
            </CCol>
          </CModalBody>
          <CModalFooter>
            {/* <CButton type="submit" color="primary">
              Submit
            </CButton> */}

            <CInput type="hidden" innerRef={register} name="draft" />
            <CButton type="button" onClick={saveDraft} color="info">
              {i18next.t("Save Draft")}
            </CButton>
            <div ref={ref}>
              <CButton type="submit" color="primary">
                {i18next.t("Submit")}
              </CButton>
            </div>
            <CButton type="reset" color="danger" onClick={onCancel}>
              {i18next.t("Cancel")}
            </CButton>
          </CModalFooter>
        </CModal>
      </CForm>
    </>
  ) : (
    ""
  );
};
export default SendForm;
