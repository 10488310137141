import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { fetchFollowUp } from "actions/follow-ups/followUps";
import { cilPencil, cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const List = ({
  toggle,
  setIsEditing,
  episodeStatus,
  setfollowUpId,
  toggleShowModal,
}) => {
  const dispatch = useDispatch();
  const fields = [

    { key: "author", label:  i18next.t("Author") },
    { key: "date", label:  i18next.t("date") },
    { key: "actions", label:  i18next.t("Actions") },
    ];
  const followUps = useSelector((state) => state.followUps.data);

  const EditFollowUp = (id: string) => {
    dispatch(fetchFollowUp(id));
    toggle();
    setIsEditing(true);
    setfollowUpId(id);
  };

  const ShowFollowUp = (id: string) => {
    dispatch(fetchFollowUp(id));
    setfollowUpId(id);
    toggleShowModal();
  };

  const CreateFollowUp = () => {
    toggle();
  };

  const renderActions = (item: Object) => (
    <td>
      <CDropdown variant="btn-group">
        <CDropdownToggle
          className="action-toggle m-0"
          color="light"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="c-icon"
            role="img"
          >
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
              class="ci-primary"
            ></path>
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
              class="ci-primary"
            ></path>
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
              class="ci-primary"
            ></path>
          </svg>
        </CDropdownToggle>
        <CDropdownMenu className="p-0" placement="right-end">
          {item.editable && (
            <CDropdownItem
              onClick={() => EditFollowUp(item.id)}
              className="text-primary"
            >
              <CIcon content={cilPencil} />
              <span class="mx-1">{i18next.t("Edit")}</span>
            </CDropdownItem>
          )}
          <CDropdownItem
            onClick={() => ShowFollowUp(item.id)}
            className="text-info"
          >
            <CIcon content={cilSearch} />
            <span class="mx-1">{i18next.t("show")}</span>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </td>
  );

  return (
    <>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol xs="12">
                  <CDataTable
                    items={followUps}
                    fields={fields}
                    noItemsViewSlot={i18next.t("No Available Items")}
                    striped
                    itemsPerPage={5}
                    pagination
                    scopedSlots={{
                      author: (item) => (
                        <td>
                          {`${item.creator.first_name} ${item.creator.last_name}`}
                        </td>
                      ),
                      date: (item) => (
                        <td>{item.created_at}</td>
                      ),
                      actions: renderActions,
                    }}
                  />
                  {episodeStatus === 0 ? (
                    <CLink
                      className="d-inline-block avh-btn-add py-3 text-decoration-none text-white avh-font-bold text-uppercase float-right"
                      style={{position:"relative", zIndex:150}}
                      onClick={CreateFollowUp}
                    >
                      +
                    </CLink>
                  ) : (
                    ""
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
export default List;
