import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CInput,
  CTextarea,
} from "@coreui/react";
import TablesSection from "../partials/TablesSection";
import ConditionsSection from "../partials/ConditionsSection";
import OrderSection from "../partials/OrderSection";

const ReportForm = ({
  schema,
  onSubmit,
  modal,
  onCancel,
  report,
  title,
  selectedTable,
  setSelectedTable,
  selectedJoins,
  setSelectedJoins,
}) => {
  const [forceSelectedjoins, setForceSelectedjoins] = useState([]);
  const [defaultGrouping, setDefaultGrouping] = useState([]);

  const [defaultColumns, setDefaultColumns] = useState([]);
  const [defaultFunctions, setDefaultFunctions] = useState([]);
  const [defaultLabels, setDefaultLabels] = useState([]);
  const [defaultJoins, setDefaultJoins] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");

  const {
    control,
    handleSubmit,
    register,
    watch,
    errors,
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
  });


  const handleSubmission = (formData) => {
    onSubmit(formData);
    reset()
    setForceSelectedjoins([]);
    setSelectedGroup('')
  };
  const onCancelation =()=>{
    reset()
    onCancel()
  }

  let defaultValues = {};
  useEffect(() => {
    if (report) {
      const query = report.query;
      defaultValues = {
        name: report.name,
        description: report.description,
      };
      if (query && typeof query === "string") {
        const queryJson = JSON.parse(query);

        defaultValues = {
          ...defaultValues,
          table: queryJson.table,
          column: queryJson.column,
          joins: queryJson.joins,
          grouping: queryJson.grouping,
          order: queryJson.order,
          conditions: queryJson.conditions,
          function: queryJson.function,
          direction: queryJson.direction,
          label: queryJson.label,
        };
        setSelectedTable(queryJson.table);
        setDefaultColumns(queryJson.column);
        setDefaultFunctions(queryJson.function);
        setDefaultLabels(queryJson.label);
        setDefaultJoins(queryJson.joins);
        const joinTablesName = [];

        schema.tables.map((table) => {
          table.joins.map((join) => {
            if (queryJson.joins.includes(join.label)) {
              joinTablesName.push(join.table);
            }
          });
        });

        setSelectedJoins(joinTablesName);

        const grouping = schema?.tables
          .find((table) => table.name === queryJson.table)
          .group_by.find((group) => group.label === queryJson.grouping);
        const groupJoins = grouping?.joins;
        let joinsLabel = [];
        joinsLabel = groupJoins?.map((join) => {
          return join.label;
        });
        setForceSelectedjoins(joinsLabel);
        setDefaultGrouping(grouping);
      }
      reset(defaultValues);
    }

  }, [report]);

  return (
    <CForm
      onSubmit={handleSubmit(handleSubmission)}
      method="post"
      className="form-horizontal"
    >
      <CModal show={modal} onClose={onCancelation} size="lg">
        <h4 className="header-label-title">{title}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs="12" md="12">
              <CLabel htmlFor="name" className="labels required">
                Title
              </CLabel>
              <CInput
                type="text"
                id="name"
                name="name"
                placeholder="Report Name"
                innerRef={register({ required: true })}
                />
            </CCol>
          </CFormGroup>
          <CFormGroup row className="mx-0">
            <CLabel htmlFor="description" className="labels">
              Description
            </CLabel>
            <CTextarea
              name="description"
              id="description"
              rows="9"
              placeholder="Description..."
              innerRef={register({ required: true })}
              />
          </CFormGroup>
          <TablesSection
            schema={schema || []}
            selectedTable={selectedTable || defaultValues?.table}
            setSelectedTable={setSelectedTable}
            selectedJoins={selectedJoins || defaultValues?.joins}
            setSelectedJoins={setSelectedJoins}
            defaultColumns={defaultColumns || []}
            defaultFunctions={defaultFunctions || []}
            defaultLabels={defaultLabels || []}
            setDefaultColumns={setDefaultColumns}
            register={register}
            forceSelectedjoins={forceSelectedjoins}
            setForceSelectedjoins={setForceSelectedjoins}
            setValue={setValue}
            defaultGrouping={defaultGrouping}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            defaultJoins={defaultJoins || []}
          />
          <CFormGroup>
            <CCol md={12}>
              
                 <ConditionsSection
                schema={schema}
                selectedTable={selectedTable || defaultValues?.table}
                selectedJoins={selectedJoins || defaultValues?.joins}
                register={register}
                control={control}
                setValue={setValue}
                defaultValues={defaultValues}
                watch={watch}
                selectedGroup={selectedGroup}
                errors={errors}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md={12}>
              <OrderSection
                schema={schema}
                selectedTable={selectedTable || defaultValues?.table}
                selectedJoins={selectedJoins || defaultValues?.joins}
                register={register}
                selectedGroup={selectedGroup}
                setValue={setValue}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
            Save
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancelation}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  );
};

export default ReportForm;
