import React, { useEffect } from "react";
import { CSpinner } from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPublicSubmission,
  fetchPublicSubmissions,
  updatePublicSubmission,
  fetchSpecialtiesFormUtilities,
}  from "actions/specialties-form/specialtiesForm";
import { useForm, Controller } from "react-hook-form";
import i18next from "i18next";
import Select from "react-select";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CSelect,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CInput,
} from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import SurveyGroupsSection from "../form/SurveyGroupsSection";
import PublicSurveyForm from "../form/PublicSurveyForm";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import { customStyles } from "views/rounds/form/RoundFormValidation";
import { LIMIT } from "constants/constants";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const EditPublicSurveys = ({ toggle, modal, id}) => {
  const dispatch = useDispatch();
  const publicSurvey = useSelector((state) => state.specialtiesForm.publicSubmission?.data);
  const authId = useSelector((state) => state.auth.jwt.user.id);

  const onSubmit = (formData) => {
    const data = {
      ...formData,
      type: 'general',
      patient_id: formData.model_id,
      model_type: "patient",
      editor_id: authId,
    };
    if (data.draft != 2) {
      confirmAlert({
        title: "Are you sure?",
        message: "that form is ready to be submitted?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(updatePublicSubmission(data, id));
              toggle();
              dispatch(fetchPublicSubmissions(1, LIMIT));

            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      dispatch(updatePublicSubmission(data, id));
      toggle();
      dispatch(fetchPublicSubmissions(1, LIMIT, {patientId: publicSurvey.model_id}));
    }

  };
  const onCancel = () => {
    toggle();
    dispatch(fetchPublicSubmissions(1, LIMIT ,{patientId: publicSurvey.model_id}));
  };
  return (
    <PublicSurveyForm
      publicSurvey={publicSurvey}
      toggle={toggle}
      modal={modal}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};


export default EditPublicSurveys;