import { Api, getTokenHeader } from "../../services/Api";
import {
  FETCH_PRESCRIPTION_UTILITIES,
  CREATE_PRESCRIPTION,
  FETCH_PRESCRIPTION,
  UPDATE_PRESCRIPTION,
  FETCH_PRESCRIPTIONS,
  DELETE_PRESCRIPTION,
  CLONE_PRESCRIPTIONS,
  RESET_PRESCRIPTION,
} from "./types";

export const fetchPrescriptionUtilities = (episodeId) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/prescriptions/utilities?episodeId=${episodeId}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_PRESCRIPTION_UTILITIES, payload: response.data });
};

export const createPrescription = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/prescriptions`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: CREATE_PRESCRIPTION, payload: response.data });
};

export const fetchPrescription = (id: string) => async (dispatch) => {
  const response = await Api.get(`/api/admin/prescription/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_PRESCRIPTION, payload: response.data });
};

export const updatePrescription = (
  formData: Object,
  id: string
): Object => async (dispatch) => {
  const response = await Api.put(
    `/api/admin/prescriptions/${id}`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: UPDATE_PRESCRIPTION, payload: response.data });
};
export const updatePrescriptionStatus = (
  formData: Object,
  id: string
): Object => async (dispatch) => {
  const response = await Api.put(
    `/api/admin/prescriptions/${id}/update-status`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: UPDATE_PRESCRIPTION, payload: response.data });
};

export const fetchPrescriptions = (
  relatedId: String,
  type: String,
  page: string,
  limit: string,
  status: string
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/prescriptions/${type}/${relatedId}?page=${page}&limit=${limit}${
      status ? `&status=${status}` : ""
    }`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_PRESCRIPTIONS, payload: response.data });
};

export const deletePrescription = (id: string) => async (dispatch) => {
  await Api.delete(`/api/admin/prescriptions/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_PRESCRIPTION, payload: id });
};

export const clonePrescriptions = (
  episodeId: String,
  type: String,
  newType: String,
  status: string
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/episodes/${episodeId}/prescriptions/${type}/clone/${newType}${
      status ? `?status=${status}` : ""
    }`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: CLONE_PRESCRIPTIONS, payload: response.data });
};

export const resetPrescription = () => (dispatch) => {
  dispatch({ type: RESET_PRESCRIPTION, payload: null });
};
