import React from "react";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { Api, getTokenHeader } from "services/Api";
import AsyncSelect from "react-select/async";
import { CCol, CLabel } from "@coreui/react";
import TimezoneSelect, {allTimezones} from "react-timezone-select";

const customStyles = (isValid) => ({
  control: (base, state) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
    // overwrittes hover style
    "&:hover": {
      borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
    },
  }),
});

export const DoctorAsyncSelect = ({ label, control, rules, name, isValid }) => {
  const getDoctorOptions = async (inputValue) => {
    const url = `/api/admin/doctors/list?q=${inputValue}`;
    const options = await Api.get(url, getTokenHeader()).then(
      (response) => response.data.data
    );
    return options || [];
  };
  return (
    <>
      <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
        <CLabel className="labels required">{label}</CLabel>
        <Controller
          control={control}
          rules={rules}
          id={name}
          name={name}
          cacheOptions
          loadOptions={getDoctorOptions}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          styles={isValid !== undefined ? customStyles(isValid) : {}}
          as={<AsyncSelect />}
        />
      </CCol>
    </>
  );
};

export const PatientAsyncSelect = ({ label, control, rules, name, isValid }) => {
  const getPatientOptions = async (inputValue) => {
    const url = `/api/admin/patients/list?q=${inputValue}`;
    const options = await Api.get(url, getTokenHeader()).then(
      (response) => response.data.data
    );
    return options || [];
  };
  return (
    <>
      <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
        <CLabel className="labels required">{label}</CLabel>
        <Controller
          control={control}
          rules={rules}
          id={name}
          name={name}
          cacheOptions
          loadOptions={getPatientOptions}
          getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
          getOptionValue={(option) => option.id}
          styles={isValid !== undefined ? customStyles(isValid) : {}}
          as={<AsyncSelect />}
        />
      </CCol>
    </>
  );
};

export const HospitalAsyncSelect = ({
  label,
  control,
  rules,
  name,
  isValid,
}) => {
  const getDoctorOptions = async (inputValue) => {
    const url = `/api/admin/hospitals?q=${inputValue}`;
    const options = await Api.get(url, getTokenHeader()).then(
      (response) => response.data.data
    );
    return options || [];
  };
  return (
    <>
      <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
        <CLabel className="labels required">{label}</CLabel>
        <Controller
          control={control}
          rules={rules}
          id={name}
          name={name}
          cacheOptions
          loadOptions={getDoctorOptions}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          styles={isValid !== undefined ? customStyles(isValid) : {}}
          as={<AsyncSelect />}
        />
      </CCol>
    </>
  );
};

export const ClinicAsyncSelect = ({ label, control, rules, name, isValid }) => {
  const getDoctorOptions = async (inputValue) => {
    const url = `/api/admin/clinics/list?q=${inputValue}`;
    const options = await Api.get(url, getTokenHeader()).then(
      (response) => response.data.data
    );
    return options || [];
  };
  return (
    <>
      <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
        <CLabel className="labels required">{label}</CLabel>
        <Controller
          control={control}
          rules={rules}
          id={name}
          name={name}
          cacheOptions
          loadOptions={getDoctorOptions}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.id}
          styles={isValid !== undefined ? customStyles(isValid) : {}}
          as={<AsyncSelect />}
        />
      </CCol>
    </>
  );
};

export const TimeZoneSelect = ({
  label,
  control,
  rules,
  name,
  isValid,
  defaultValue,
}) => {
  const user = useSelector((state) => state.auth.jwt?.user);
  const getDefaultValue = () => {
    if (defaultValue === undefined) {
      return user && user.time_zone ? user.time_zone : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return defaultValue;
  };

  return (
    <>
      <CLabel className="labels required required">{label}</CLabel>
      <Controller
        control={control}
        rules={rules}
        id={name}
        name={name}
        styles={isValid !== undefined ? customStyles(isValid) : {}}
        defaultValue={getDefaultValue()}
        timezones={{
          ...allTimezones,
          'Europe/Bucharest': 'Cairo',
        }}
      
        as={TimezoneSelect}
      />
    </>
  );
};

export const CountryAsyncSelect = ({
  label,
  control,
  rules,
  name,
  isValid,
}) => {
  const getCountryOptions = async (inputValue) => {
    const url = `/api/admin/country?q=${inputValue}`;
    const options = await Api.get(url, getTokenHeader()).then((response) => {
      console.log(response.data);
      return response.data.data;
    });
    return options || [];
  };

  return (
    <>
      <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
        <CLabel className="labels required">{label}</CLabel>
        <Controller
          control={control}
          rules={rules}
          id={name}
          name={name}
          cacheOptions
          loadOptions={getCountryOptions}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          styles={isValid !== undefined ? customStyles(isValid) : {}}
          as={<AsyncSelect />}
        />
      </CCol>
    </>
  );
};
