export const TYPE_PHYSICIAN_CLINICS = 'PHYSICIAN_CLINICS';
export const TYPE_PHYSICIAN_CLINICS_APPOINTMENTS = 'PHYSICIAN_CLINICS_APPOINTMENTS';
export const TYPE_PHYSICIAN_APPOINTMENTS = 'PHYSICIAN_APPOINTMENTS';
export const TYPE_PHYSICIAN_DELAY = 'PHYSICIAN_DELAY';
export const TYPE_PATIENT_EPISODE_DISCHARGE = 'PATIENT_EPISODE_DISCHARGE';
export const TYPE_PATIENT_EPISODE_TELE_CONSULT_TIME = 'PATIENT_EPISODE_TELE_CONSULT_TIME';
export const TYPE_PHYSICIAN_DURATION = 'PHYSICIAN_DURATION';
export const TYPE_PHYSICIAN_RATING = 'PHYSICIAN_RATING';
export const TYPE_HOSPITAL_EPISODE_DISCHARGE = 'HOSPITAL_EPISODE_DISCHARGE';
export const TYPE_EPISODE_LAST_UPDATE = 'EPISODE_LAST_UPDATE';
export const TYPE_ACTIVE_EPISODE_PRESC_EXEC_PERCENTAGE = 'ACTIVE_EPISODE_PRESC_EXEC_PERCENTAGE';
export const TYPE_EPISODE_STAY_LENGTH = 'EPISODE_STAY_LENGTH';
export const TYPE_DOCTOR_CALLS_COUNT = 'DOCTOR_CALLS_COUNT';
export const TYPE_DOCTOR_CLINIC_BOOKED_PERCENTAGE = 'DOCTOR_CLINIC_BOOKED_PERCENTAGE';
export const TYPE_CLINIC_INCOME = 'CLINIC_INCOME';
export const TYPE_PATIENTS_APPOINTMENTS = 'PATIENTS_APPOINTMENTS';
export const TYPE_OUTPATIENTS_PHYSICIAN = "OUTPATIENTS_PHYSICIAN";
export const TYPE_OUTPATIENTS_PATIENTS = "OUTPATIENTS_PATIENTS";
export const TYPE_OUTPATIENTS_CLINICS = "OUTPATIENTS_CLINICS";
export const TYPE_INPATIENTS_ROUNDS = "INPATIENTS_ROUNDS";
export const TYPE_INPATIENTS_PATIENTS = "INPATIENTS_PATIENTS";
