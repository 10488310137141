import React, { useState, useEffect } from "react";
import {
  fetchReports,
  fetchReport,
  updateReport,
  fetchSchema,
} from "actions/reports/reports";
import { useSelector, useDispatch } from "react-redux";
import ReportForm from "../forms/ReportForm";

const EditReport = ({ modal, toggle, id }) => {
  const dispatch = useDispatch();
  const report = useSelector((state) => state.reports.report);
  const schema = useSelector((state) => state.report.schema);
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedJoins, setSelectedJoins] = useState([]);

  useEffect(() => {
    dispatch(fetchSchema());
  }, []);
  
  const onCancel = () => {
    toggle();
    dispatch(fetchReports());
  };
  const onSubmit = (formData) => {
    const conditions = ( formData.conditions?formData.conditions:[] ).map((condition) => {
      if (typeof condition.value === "object" && condition.value.id) {
        return { ...condition, value: condition.value.id,conditionsObject: condition.value };
      } else {
        return { ...condition, conditionsObject: null };
      }
    });

    const data = {
      grouping: formData.grouping,
      order: formData.order,
      table: formData.table,
      conditions: conditions,
      joins: formData.joins,
      column: formData.column,
      function: formData.function,
      direction: formData.direction,
      label: formData.label,
    };
    const name = formData.name;
    const description = formData.description;
    dispatch(updateReport(data, name, description, id));
    dispatch(fetchReports());
    toggle();
    dispatch(fetchReports()) 
   };
  useEffect(() => {
    if (id) dispatch(fetchReport(id));
  }, [dispatch, id]);

  return (
    <div>
      <ReportForm
        schema={schema}
        selectedTable={selectedTable}
        setSelectedTable={setSelectedTable}
        selectedJoins={selectedJoins}
        setSelectedJoins={setSelectedJoins}
        onSubmit={onSubmit}
        modal={modal}
        onCancel={onCancel}
        report={report}
        title="Edit Report"
      />
    </div>
  );
};

export default EditReport;
