import { Api, getTokenHeader } from "services/Api";
import {
  FETCH_INDEX_UTILITIES,
  FETCH_EVENTS,
  CREATE_EVENT,
  FETCH_EVENT_FORM_UTILITIES,
  DELETE_EVENT,
  FETCH_CLINICS_EVENTS,
  UPDATE_EVENT,
} from "./types";

export const fetchIndexUtilities = (): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/calendar/utilities`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_INDEX_UTILITIES, payload: response.data });
};

export const fetchEvents = (
  hospitalId: String,
  specialtyId: String,
  personalEvents = 0,
  currentDate:String
): Object => async (dispatch) => {
  const headers = [personalEvents,currentDate]
    ? { params: { personalEvents ,  currentDate }, ...getTokenHeader() }
    : getTokenHeader();
  const response = await Api.get(
    `/api/admin/calendar/events/${hospitalId}/${specialtyId}`,
    headers
  );
  dispatch({ type: FETCH_EVENTS, payload: response.data });
};

export const fetchEventFormUtilities = (
  hospitalId: String,
  specialtyId: String
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/calendar/events/utilities/${hospitalId}/${specialtyId}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_EVENT_FORM_UTILITIES, payload: response.data });
};

export const createCalendarEvent = (data: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/calendar/events`,
    data,
    getTokenHeader()
  );
  dispatch({ type: CREATE_EVENT, payload: response.data });
};

export const updateCalendarEvent = (eventId: String, data: Object) => async (
  dispatch
) => {
  const response = await Api.put(
    `/api/admin/calendar/events/${eventId}`,
    data,
    getTokenHeader()
  );
  dispatch({ type: UPDATE_EVENT, payload: response.data });
};

export const deleteCalendarEvent = (eventId: String) => async (dispatch) => {
  await Api.delete(`/api/admin/calendar/events/${eventId}`, getTokenHeader());
  dispatch({ type: DELETE_EVENT, payload: eventId });
};

export const fetchClinicsEvents = (
  clinicId: String,
  currentDate:String

): Object => async (dispatch) => {
  const headers = currentDate

    ? { params: {   currentDate    }, ...getTokenHeader() }
    : 
    getTokenHeader();
  const response = await Api.get(
    `/api/admin/calendar/clinics-events/${clinicId}`,
    headers
  );
  dispatch({ type: FETCH_CLINICS_EVENTS, payload: response.data });
};