import {
  APPOINTMENT_UTILITIES,
  APPOINTMENT_UTILITIES_RESET_CLINICS,
  CREATE_APPOINTMENT,
  FETCH_APPOINTMENT,
  EDIT_APPOINTMENT,
  CANCEL_APPOINTMENT,
  DOCTOR_CLINICS,
} from "actions/appointments/types";
import { UNAUTHORIZED_STATUS_CODE } from "constants/constants";

/**
 * @param state
 * @param action
 * @returns {*}
 */
export default function appointmentUtilities(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case APPOINTMENT_UTILITIES:
      return { ...state, ...action.payload.data, redirect: false };
    case DOCTOR_CLINICS:
      return { ...state, ...action.payload.data, redirect: false };
    case APPOINTMENT_UTILITIES_RESET_CLINICS:
      return { ...state, clinics: [], redirect: false };
    case CREATE_APPOINTMENT:
    case FETCH_APPOINTMENT:
    case EDIT_APPOINTMENT:
    case CANCEL_APPOINTMENT:
      if(action.payload.status == UNAUTHORIZED_STATUS_CODE){
        return { ...state, redirect: false };
      }
      return { ...state, redirect: true };
    default:
      return state;
  }
}
