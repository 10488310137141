import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CLink,
} from "@coreui/react";
// import {
//   deleteSpecialtiesForm,
// } from "actions/specialties-form/specialtiesForm";
import { LIMIT } from "constants/constants";
import { RESET_SPECIALTIES_FORM } from "actions/specialties-form/types";
// import FiltersPublicSection from "../form/FiltersPublicSection";
import {
  fetchSentSurvey,
  fetchSentSurveys,
  deleteSentSurvey,
} from "actions/sent-surveys/sentSurveys";
import i18next from "i18next";
import SendNewSurveys from "./SendNewSurveys";
import EditSendSurveys from "./EditSendSurveys";
import Show from "../form/PreviewSentSurvey";

const SendSurveys = () => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [sentSurveyId, setSentSurveyId] = useState(null);
  const sentSurvey = useSelector((state) => state.sentSurveys.sentSurvey);
  const sentSurveys = useSelector((state) => state.sentSurveys.data);
  const meta = useSelector((state) => state.sentSurveys.meta);

  const history = useHistory();
  const dispatch = useDispatch();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);


  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/send-surveys?page=${newPage}`);
    }
  };
  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const showSentSurvey = (id: string) => {
    dispatch(fetchSentSurvey(id));
    setSentSurveyId(id);
    toggleShowModal();
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };
  const editSentSurvey = (id: string) => {
    dispatch(fetchSentSurvey(id));
    setSentSurveyId(id);
    toggleEditModal();
  }


  const toggle = () => {
    setModal(!modal);
  };

  const handleDeleteLevel = (id: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This Public Survey.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteSentSurvey(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };
  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
 
    dispatch(fetchSentSurveys(page, LIMIT));
  }, [dispatch, page]);

  const onFilter = (filters) => {
    dispatch(fetchSentSurveys(page, LIMIT, filters));
  };

  return (
    <>
      <Show
        sentSurvey={sentSurvey}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
        sentSurveyId={sentSurveyId}
      />

      <EditSendSurveys
        modal={editModal}
        toggle={toggleEditModal}
        id={sentSurveyId}
      />
      <SendNewSurveys toggle={toggle} modal={modal} />
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">
            {i18next.t("Sent Surveys")}
          </div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            onClick={toggle}
            className="create-button float-right wi"
            style={{ width: "13em" }}
          >
            {i18next.t("Send New Surveys")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              {/* <FiltersPublicSection onFilter={onFilter} /> */}
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={sentSurveys || []}
                fields={[
                  { key: "id", label:  i18next.t("id") },
                  { key: "title", label:  i18next.t("title") },
                  { key: "date", label:  i18next.t("date") },
                  { key: "actions", label:  i18next.t("Actions") },
                ]}
                hover
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                scopedSlots={{
                  title: (item) => (
                    <td>{item?.survey?.title}</td>
                  ),
                  date: (item) => <td>{`${item.created_at}`}</td>,
                  actions: (item) => (
                    <td>
                      <CButton
                        onClick={() => showSentSurvey(item.id)}
                        style={{ marginRight: "5px" }}
                        className="btn btn-primary"
                      >
                        {i18next.t("Preview")}
                      </CButton>

                      {(item.editable) && (
                        <a
                          onClick={() => editSentSurvey(item.id)}
                          style={{
                            marginRight: "5px",
                            "background-color": "rgb(48, 197, 182)",
                            "border-color": "rgb(48, 197, 182)",
                            color: "white",
                            width: "7em",
                          }}
                          className="btn"
                        >
                          {i18next.t("Edit")}
                        </a>
                      )}

                      <CButton
                        onClick={() => handleDeleteLevel(item.id)}
                        color="danger"
                      >
                        {i18next.t("Delete")}
                      </CButton>
                    </td>
                  ),
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default SendSurveys;
