import { Api, getTokenHeader } from "services/Api";
import setQueryString from "helpers/helpers";
import {
  FETCH_SPECIALTIES_FORM_UTILITIES,
  CREATE_SPECIALTIES_FORM,
  FETCH_SPECIALTIES_FORM,
  LIST_SPECIALTIES_FORMS,
  LIST_CONDITION_SPECIALTIES_FORMS,
  LIST_ADMIN_SPECIALTIES_FORMS,
  UPDATE_SPECIALTIES_FORM,
  FETCH_SPECIALTIES_FORMS,
  DELETE_SPECIALTIES_FORM,
  FETCH_PUBLIC_SUBMISSIONS,
  FETCH_PUBLIC_SUBMISSION,
  CREATE_PUBLIC_SUBMISSIONS,
  UPDATE_PUBLIC_SUBMISSIONS,
  } from "./types";

export const fetchSpecialtiesFormUtilities = () => async (dispatch) => {
  const response = await Api.get("/api/admin/surveys/utilities", {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_SPECIALTIES_FORM_UTILITIES, payload: response.data });
};

export const createSpecialtiesForm = (formData: Object): Object => async (
  dispatch
) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.post(`/api/admin/surveys${draft}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_SPECIALTIES_FORM, payload: response.data });
};

export const fetchSpecialtiesForm = (id: string) => async (dispatch) => {
  const response = await Api.get(`/api/admin/survey/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_SPECIALTIES_FORM, payload: response.data });
};

export const updateSpecialtiesForm = (
  formData: Object,
  id: string
): Object => async (dispatch) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.put(
    `/api/admin/surveys/${id}${draft}`,
    formData,
    getTokenHeader()
  );

  dispatch({ type: UPDATE_SPECIALTIES_FORM, payload: response.data });
};

export const fetchSpecialtiesForms = (page, limit, filters = {}) => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/surveys?page=${page}&limit=${limit}&${setQueryString(filters)}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_SPECIALTIES_FORMS, payload: response.data });
};

export const fetchPublicSubmissions = (page, limit, filters = {}) => async (
  dispatch
) => {
    const response = await Api.get(
    `/api/admin/survey-instances/patients?page=${page}&limit=${limit}&${setQueryString(filters)}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_PUBLIC_SUBMISSIONS, payload: response.data });
  };
export const createPublicSubmissions = (formData: Object): Object => async (
  dispatch
) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.post(`/api/admin/survey-instances${draft}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_PUBLIC_SUBMISSIONS, payload: response.data });
};
export const updatePublicSubmission = (
  formData: Object,
  id: string
): Object => async (dispatch) => {
  const draft = formData.draft == 2 ? '/save' : '';
  const response = await Api.put(
    `/api/admin/survey-instance/${id}${draft}`,
    formData,
    getTokenHeader()
  );

  dispatch({ type: UPDATE_PUBLIC_SUBMISSIONS, payload: response.data });
};

export const fetchPublicSubmission = (id: string): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/survey-instance/${id}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_PUBLIC_SUBMISSION, payload: response });
};

export const deleteSpecialtiesForm = (id: string) => async (dispatch) => {
  await Api.delete(`/api/admin/surveys/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_SPECIALTIES_FORM, payload: id });
};

export const findSpecialtyForm = (hospitalId, specialtyId, type) => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/survey/find/${hospitalId}/${specialtyId}/${type}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_SPECIALTIES_FORM, payload: response.data });
};

export const listAdminSpecialtyForms = (hospitalId, specialtyId, type) => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/survey/list/${hospitalId}/${specialtyId}/${type}`,
    getTokenHeader()
  );
  dispatch({ type: LIST_ADMIN_SPECIALTIES_FORMS, payload: response.data });
};

export const listSpecialtyForms = (hospitalId, specialtyId) => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/patient/survey/find/${hospitalId}/${specialtyId}`,
    getTokenHeader()
  );
  dispatch({ type: LIST_SPECIALTIES_FORMS, payload: response.data });
};
export const listSpecialtyFormsCondition = (hospitalId, specialtyId) => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/patient/survey/find/${hospitalId}/${specialtyId}`,
    getTokenHeader()
  );
  dispatch({ type: LIST_CONDITION_SPECIALTIES_FORMS, payload: response.data });
};

export const fetchPublicSurveys = (page, limit, filters = {}) => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/public-surveys?page=${page}&limit=${limit}${setQueryString(
      filters
    )}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_SPECIALTIES_FORMS, payload: response.data });
};

export const addPublicSurvey = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/public-surveys`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: CREATE_SPECIALTIES_FORM, payload: response });
};

