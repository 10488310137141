import {
  CREATE_DISCHARGE_FORM,
  FETCH_EPISODE_LAB_ORDERS,
  FETCH_EPISODE_RAD_ORDERS,
  FETCH_EPISODE_APPOINTMENTS,
} from "actions/discharge-form/types";
import { CANCEL_APPOINTMENT } from "actions/appointments/types";
import { updateObjectInArray } from "helpers/helpers";
import { UNAUTHORIZED_STATUS_CODE } from "constants/constants";

/**
 * @param state
 * @param action
 */
export default function dischargeForm(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case CREATE_DISCHARGE_FORM:
      if(action.payload.status == UNAUTHORIZED_STATUS_CODE){
        return { ...state, redirect: false }
      }
      return { redirect: true };
    case FETCH_EPISODE_LAB_ORDERS:
      return { labs: action.payload.data };
    case FETCH_EPISODE_RAD_ORDERS:
      return { rads: action.payload.data };
    case FETCH_EPISODE_APPOINTMENTS:
      return { appointments: action.payload.data };
    case CANCEL_APPOINTMENT:
      return {
        appointments: state.appointments
          ? updateObjectInArray(state.appointments, action.payload.data)
          : [],
      };
    default:
      return state;
  }
}
