import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchRound } from "actions/rounds/rounds";
import ObjectDetails from "views/partials/ObjectDetails";
import i18next from "i18next";

/**
 * @param {*} props required props
 * @returns {*}  appointment form component
 * @class
 */
function ShowRound() {
  const dispatch = useDispatch();
  const { id: roundId } = useParams();
  const round = useSelector((state) => state.rounds[roundId]);

  useEffect(() => {
    dispatch(fetchRound(roundId));
  }, [dispatch]);

  const preparedViewObject = () => ({
    title: round.title,
    description: round.description,
    organizer: round.organizer ? round.organizer.name : "-",
    consultant: round.consultant ? round.consultant.name : "-",
    spoke: round.spoke ? round.spoke.name : "-",
    specialty: round.specialty ? round.specialty.name : "-",
    date: round.date,
    from: round.from,
    to: round.to,
    status: round.statusText,
    mandatory_speakers: round.mandatory_speakers
      ? round.mandatory_speakers.map((ma) => (
          <tr>
            <td>{`${ma.first_name}  ${ma.last_name}`}</td>
          </tr>
        ))
      : "-",
    shared_speakers: round.shared_speakers
      ? round.shared_speakers.map((sa) => (
          <tr>
            <td>{`${sa.name}: ${sa.email}`}</td>
          </tr>
        ))
      : "-",
    mandatory_attendees: round.mandatory_attendees
      ? round.mandatory_attendees.map((ma) => (
          <tr>
            <td>{`${ma.first_name}  ${ma.last_name}`}</td>
          </tr>
        ))
      : "-",
    shared_attendees: round.shared_attendees
      ? round.shared_attendees.map((sa) => (
          <tr>
            <td>{`${sa.name}: ${sa.email}`}</td>
          </tr>
        ))
      : "-",
  });

  return (
    <ObjectDetails
      object={round ? preparedViewObject() : {}}
      listItems={[
        "title",
        "description",
        "organizer",
        "specialty",
        "type",
        "status",
        "date",
        "from",
        "to",
        "mandatory_speakers",
        "shared_speakers",
        "mandatory_attendees",
        "shared_attendees",
      ]}
    />
  );
}

export default ShowRound;
