import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CSpinner } from "@coreui/react";
import { fetchLevel, updateLevel } from "actions/levels/levels";
import LevelForm from "../form/LevelForm";
import i18next from "i18next";

const EditLevel = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const level = useSelector((state) => state.levels.level);
  const redirect = useSelector((state) => state.levels.redirect);
  const label = `${i18next.t("Edit Level")}`;

  const onSubmit = (formData) => {
    dispatch(updateLevel(formData, match.params.id));
  };

  useEffect(() => {
    dispatch(fetchLevel(match.params.id));
  }, [dispatch, match.params.id]);

  if (redirect) {
    history.push("/levels");
  }

  if (level) {
    return <LevelForm onSubmit={onSubmit} label={label} level={level} />;
  }
  return <CSpinner className="spinner" variant="grow" />;
};

export default EditLevel;
