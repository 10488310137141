import {
  TYPE_OUTPATIENTS_CLINICS,
  TYPE_OUTPATIENTS_PATIENTS,
  TYPE_INPATIENTS_PATIENTS,
} from "constants/reports";

const getFields = (type) => {
  switch (type) {
    case TYPE_OUTPATIENTS_CLINICS: {
      return [
        "name",
        "clinics",
        "total_booking",
        "success",
        "missed",
        "actions",
      ];
    }
    case TYPE_OUTPATIENTS_PATIENTS: {
      return ["name", "total", "missed", "specialities", "actions"];
    }
    case TYPE_INPATIENTS_PATIENTS: {
      return ["name", "admissions", "Tele_consultations", "Tele_clinics", "prescription_executions_precentage", "actions"];
    }
    default:
      return false;
  }
};
export default getFields;
