import React, { useState } from "react";
import {
  CRow,
  CPagination,
  CCol,
  CDataTable,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import Show from "../investigation/Show";
import Slider from "../investigation/Slider";
import { cilNewspaper, cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const InvestigationsList = ({
  investigations,
  activePage,
  pageChange,
  pages,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [sliderModal, setSliderModal] = useState(false);
  const [investigation, setInvestigation] = useState();

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  const toggleSliderModal = () => {
    setSliderModal(!sliderModal);
  };
  const showSlides = (investigation) => {
    setInvestigation(investigation);
    toggleSliderModal();
  };
  const stopSlides = () => {
    setInvestigation(null);
    toggleSliderModal();
  };
  const ShowInvestigation = (investigation) => {
    setInvestigation(investigation);
    toggleShowModal();
  };

  return (
    <CRow>
      <CCol xs="12">
        <CDataTable
          items={investigations}
          noItemsViewSlot={i18next.t("No Available Items")}
          fields={[
            { key: "name", label: i18next.t("Lab/Radiology") },
            { key: "date", label: i18next.t("date") },
            { key: "actions", label: i18next.t("Actions") },

            ]}
          striped
          scopedSlots={{
            name: (item) => (
              <td>{`${item.investigation_types
                .map((item) => item.name)
                .toString()}`}</td>
            ),
            doctor: (item) => (
              <td className="episode-bold-text">{`${item.doctor.first_name} ${item.doctor.last_name}`}</td>
            ),

            actions: (item) => (
              <td>
                {/* <CDropdown variant="btn-group">
                  <CDropdownToggle className="action-toggle m-0" color="light" onClick={(e) => { e.stopPropagation() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="c-icon" role="img"><path fill="var(--ci-primary-color, currentColor)" d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z" class="ci-primary"></path><path fill="var(--ci-primary-color, currentColor)" d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z" class="ci-primary"></path><path fill="var(--ci-primary-color, currentColor)" d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z" class="ci-primary"></path></svg>
                  </CDropdownToggle>
                  <CDropdownMenu className="p-0" placement="right-end">
                    <CDropdownItem onClick={() => ShowInvestigation(item)} className="text-primary">
                      <CIcon content={cilSearch} />
                      <span class="mx-1">Show</span>
                    </CDropdownItem>
                    <CDropdownItem onClick={() => showSlides(item)} className="text-info">
                      <CIcon content={cilNewspaper} />
                      <span class="mx-1">Slides</span>
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown> */}

                <CDropdown variant="btn-group">
                  <CDropdownToggle
                    className="action-toggle m-0"
                    color="light"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      class="c-icon"
                      role="img"
                    >
                      <path
                        fill="var(--ci-primary-color, currentColor)"
                        d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                        className="ci-primary"
                      ></path>
                      <path
                        fill="var(--ci-primary-color, currentColor)"
                        d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                        class="ci-primary"
                      ></path>
                      <path
                        fill="var(--ci-primary-color, currentColor)"
                        d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                        class="ci-primary"
                      ></path>
                    </svg>
                  </CDropdownToggle>
                  <CDropdownMenu className="p-0">
                    <CDropdownItem
                      onClick={() => ShowInvestigation(item)}
                      className="text-primary"
                    >
                      <CIcon content={cilSearch} />
                      <span class="mx-1">{i18next.t("show")}</span>
                    </CDropdownItem>
                    <CDropdownItem
                      onClick={() => showSlides(item)}
                      className="text-info"
                    >
                      <CIcon content={cilNewspaper} />
                      <span class="mx-1">{i18next.t("Slides")}</span>
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </td>
            ),
          }}
        />
        <CPagination
          activePage={activePage}
          onActivePageChange={pageChange}
          pages={pages}
          doubleArrows={false}
          align="center"
        />
      </CCol>
      <Show
        investigation={investigation}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
      />
      {sliderModal && (
        <Slider
          slides={investigation ? investigation.media : []}
          sliderModal={sliderModal}
          stopSlides={stopSlides}
        />
      )}
    </CRow>
  );
};

export default InvestigationsList;
