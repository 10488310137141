import React from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CInputCheckbox,
} from "@coreui/react";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

const RoleForm = ({ onSubmit, permissions, role, label }) => {
  console.log(permissions, ">>>>>>>>");
  let defaultValues = {};
  if (role) {
    defaultValues = role;
  }

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const Validation = getValidation(watch);
  const history = useHistory();

  const onCancel = () => {
    history.push("/roles");
  };

  const PermissionsGroup = (props) => {
    const data = Object.entries(props.permissions);
    return data.map(([key, value]) => (
      <>
        <CCol>
          <h4 style={{ margin: "2em 0em" }} className="header-label">
            {value.title}
          </h4>
        </CCol>
        <CFormGroup
          style={{ "margin-left": "10%" }}
          row
          variant="custom-checkbox"
        >
          {value.privileges.length !== 0 ? (
            <Permissions permissions={value.privileges} />
          ) : (
            <h4 className="text-align-center not-found">
              <strong> {i18next.t("No Available Permissions")}</strong>
            </h4>
          )}
        </CFormGroup>
      </>
    ));
  };

  const Permissions = (props) =>
    props.permissions.map((item) => (
      <CCol
        sm={12}
        md={3}
        xs={12}
        lg={3}
        xl={3}
        style={{ "margin-bottom": ".5em" }}
      >
        <CInputCheckbox
          custom
          id={item}
          name="permissions"
          value={item}
          innerRef={register}
        />
        <CLabel
          variant="custom-checkbox"
          className="labels"
          htmlFor={item}
          style={{ textTransform: "capitalize" }}
        >
          {item.replaceAll("-", " ")}
        </CLabel>
      </CCol>
    ));

  return (
    <CRow>
      <CCol xs="12">
        <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
          <CCard>
            <CCardHeader>
              <h4 className="header-label-title">{label}</h4>
            </CCardHeader>
            <CCardBody>
              <CFormGroup row>
                <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
                  <CLabel htmlFor="name" className="labels required">
                    {i18next.t("Name")}
                  </CLabel>
                  <CInput
                    className={`${getClassName("name", "name", watch, errors)}`}
                    type="text"
                    id="name"
                    placeholder={i18next.t("Enter Role Name")}
                    name="name"
                    innerRef={register(Validation.name)}
                  />
                </CCol>
              </CFormGroup>
              <CCardHeader>
                <CCardTitle
                  className="table-title"
                  style={{ marginTop: "1.5em" }}
                >
                  {i18next.t("Admin Permissions")}
                </CCardTitle>
              </CCardHeader>
              {permissions.admin && (
                <PermissionsGroup permissions={permissions.admin} />
              )}
              <hr />
              <CCardHeader>
                <CCardTitle
                  className="table-title"
                  style={{ marginTop: "1.5em" }}
                >
                  {i18next.t("Patient Permissions")}
                </CCardTitle>
              </CCardHeader>
              {permissions.patient && (
                <PermissionsGroup permissions={permissions.patient} />
              )}
            </CCardBody>
            <CCardFooter>
              <CFormGroup row>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    type="submit"
                    color="primary"
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Save")}
                  </CButton>
                </CCol>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  );
};

export default RoleForm;
