import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CCol,
  CContainer,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CInputRadio,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CSelect,
  CCardTitle,
  CTextarea,
  CCollapse,
  CDataTable,
  CSpinner,
} from "@coreui/react";
import AsyncSelect from "react-select/async";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { Api, getIcdTokenHeader } from "services/Api";
import { useDispatch } from "react-redux";
import { icdAuth } from "actions/icd/icd";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import getValidation from "./EpisodeFormValidation";
import CIcon from "@coreui/icons-react";
import { calculateAge } from "helpers/helpers";
import i18next from "i18next";

const Severity = (options) =>
  options.map((item, index) => (
    <option key={item} value={index}>
      {item}
    </option>
  ));

const EpisodeForm = ({
  onSubmit,
  specialties,
  departments,
  severity,
  hospitalId,
  specialtyForm,
  onSpecialtyChange,
  specialtyFormsList,
  onSpecialtyFormChange,
  patient,
  onCancel,
}) => {
  const defaultValues = {};
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    errors,
    getValues,
    formState
  } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const Validation = getValidation(getValues);
  const dispatch = useDispatch();
  const { isSubmitting } = formState;

  const handleBirthDateChange = (e) => {
    const birthDate = e.target.value;
    let age = calculateAge(birthDate);
    setValue("age",age)
  };
  useEffect(() => {
    dispatch(icdAuth());
    if (patient) {
      const birthDate = new Date(patient.birth_date);
      const guardian =
        patient.episode && patient.episode.guardian
          ? patient.episode.guardian
          : {};
      const values = {
        ...patient,
        age: parseInt((Date.now() - birthDate) / 31557600000, 10),
        first_name: patient.first_name,
        last_name: patient.last_name,
        guardian_first_name: guardian.first_name,
        guardian_last_name: guardian.last_name,
        guardian_address: guardian.address,
        guardian_uuid: guardian.uuid,
        guardian_phone: guardian.phone,
        guardian_email: guardian.email,
      };
      reset(values);
    }
  }, [reset, patient]);

  const [showDiagnostics, setShowDiagnostics] = useState(false);
  const [showLegal, setShowLegal] = useState(false);
  const [icdErrorMsg, setIcdErrorMessage] = useState(false);

  const getIcdOptions = async (inputValue) => {
    const url = `https://id.who.int/icd/release/11/2021-05/mms/search?q=${inputValue}`;
    const response = await Api.get(url, getIcdTokenHeader());
    if (response.data.error) {
      setIcdErrorMessage(response.data.errorMessage);
      return [];
    }
    setIcdErrorMessage(false);
    return response.data.destinationEntities
      ? response.data.destinationEntities
      : [];
  };
  const removeHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

  const renderPreviousIcds = () => {
    if (patient && patient.previousDiagnostics) {
      return (
        <CCard style={{ border: "0px" }}>
          <CCardHeader
            className="text-dark bg-light"
            style={{ marginTop: "1%", borderRadius: "10px" }}
          >
            <CRow>
              <CCol xs="10">
                <CCardTitle>{i18next.t("Previous Diagnosis")}</CCardTitle>
              </CCol>
              <CCol xs="2">
                <CCardTitle>
                  <CIcon
                    name={showDiagnostics ? "cil-arrow-top" : "cil-arrow-right"}
                    size={"lg"}
                    onClick={() => setShowDiagnostics(!showDiagnostics)}
                    onKeyDown={() => setShowDiagnostics(!showDiagnostics)}
                  />
                </CCardTitle>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCollapse show={showDiagnostics}>
            <CDataTable
              items={patient.previousDiagnostics}
              noItemsViewSlot={i18next.t("No Available Items")}
              fields={[
                {key:"code",label:i18next.t("code")},
                {key:"title",label:i18next.t("title")},
              ]}
              scopedSlots={{
                title: (item) => <span>{removeHtmlTags(item.title)}</span>,
              }}
            />
          </CCollapse>
        </CCard>
      );
    }
    return "";
  };

  const getClassName = (inputName) => {
    if (errors[inputName]) {
      return "is-invalid";
    }
    if (watch(inputName)) {
      return "is-valid";
    }
    return "";
  };
  return (
    <CContainer>
      <CRow>
        <CCol xs="12">
          <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
            <CCard>
              <CCardHeader>
                <CCol xs="12">
                  <h4 className="header-label-title">{i18next.t("Add New Episode")}</h4>
                </CCol>
              </CCardHeader>
              <CCardBody>
                <CInput
                  type="hidden"
                  value={hospitalId}
                  name="hospital_id"
                  innerRef={register(Validation.hospital_id)}
                />

                <CCol xs="12">
                  <h4 className="header-label">{i18next.t("Patient Info")}</h4>
                </CCol>
                <CFormGroup row>
                  <CCol sm={12} md={6} xs={12} lg={3} xl={3}>
                    <CLabel className="required">{i18next.t("First Name")}</CLabel>
                    <CInput
                      className={getClassName("first_name")}
                      type="text"
                      id="first_name"
                      name="first_name"
                      innerRef={register(Validation.first_name)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={3} xl={3}>
                    <CLabel className="required">{i18next.t("Last Name")}</CLabel>
                    <CInput
                      className={getClassName("last_name")}
                      type="text"
                      id="last_name"
                      name="last_name"
                      innerRef={register(Validation.last_name)}
                    />
                    {/*{errors.last_name && (<span className="alert-danger">{errors.last_name.message}</span>)}*/}
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={3} xl={3}>
                    <CLabel className="required">{i18next.t("Birth Date")}</CLabel>
                    <CInput
                      className={getClassName("birth_date")}
                      type="date"
                      id="birth-date"
                      name="birth_date"
                      innerRef={register(Validation.birth_date)}
                      onChange={handleBirthDateChange}
                      max={new Date().toISOString().substr(0, 10)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={3} xl={3}>
                    <CLabel>{i18next.t("Age")}</CLabel>
                    <CInput
                      type="text"
                      id="age"
                      name="age"
                      innerRef={register}
                      readOnly
                    />
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol sm={12} md={6} xs={12} lg={3} xl={3}>
                    <CLabel className="required" htmlFor="uuid">
                      {i18next.t("National ID")}
                    </CLabel>
                    <CInput
                      className={getClassName("uuid")}
                      type="number"
                      id="uuid"
                      name="uuid"
                      innerRef={register(Validation.uuid)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={3} xl={3}>
                    <CLabel className="required" htmlFor="email">
                      {i18next.t("Email")}
                    </CLabel>
                    <CInput
                      className={getClassName("email")}
                      type="email"
                      id="email"
                      name="email"
                      placeholder={i18next.t("Enter Email")}
                      autoComplete="email"
                      innerRef={register(Validation.email)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={3} xl={3}>
                    <CLabel className="required">{i18next.t("Mobile No")}.</CLabel>
                    <CInput
                      className={getClassName("phone")}
                      type="tel"
                      id="phone"
                      name="phone"
                      innerRef={register(Validation.phone)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={3} xl={3} >
                    <CLabel>{i18next.t("Gender")}</CLabel>
                    <br></br>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio
                        custom
                        className={getClassName("gender")}
                        id="inline-radio1"
                        name="gender"
                        value="male"
                        innerRef={register(Validation.gender)}
                      />
                      <CLabel variant="custom-checkbox" htmlFor="inline-radio1">
                        {i18next.t("Male")}
                      </CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio
                        custom
                        className={getClassName("gender")}
                        id="inline-radio2"
                        name="gender"
                        value="female"
                        innerRef={register(Validation.gender)}
                      />
                      <CLabel variant="custom-checkbox" htmlFor="inline-radio2">
                        {i18next.t("Female")}
                      </CLabel>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol>
                    <h4
                      className="header-label"
                      onClick={() => setShowLegal(!showLegal)}
                      onKeyDown={() => setShowLegal(!showLegal)}
                    >
                      {i18next.t("Legal Guardian Contact Info")}{" "}
                      <CIcon
                        name={showLegal ? "cil-arrow-top" : "cil-arrow-right"}
                        size={"lg"}
                      />
                    </h4>
                  </CCol>
                </CFormGroup>
                <CCollapse show={showLegal}>
                  <CFormGroup row>
                    <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                      <CLabel
                        htmlFor="guardian_first_name"
                        className={showLegal ? "required" : ""}
                      >
                        {i18next.t("First Name")}
                      </CLabel>
                      <CInput
                        type="text"
                        id="guardian_first_name"
                        name="guardian_first_name"
                        innerRef={register}
                      />
                    </CCol>
                    <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                      <CLabel
                        htmlFor="guardian_last_name"
                        className={showLegal ? "required" : ""}
                      >
                        {i18next.t("Last Name")}
                      </CLabel>
                      <CInput
                        type="text"
                        id="guardian_last_name"
                        name="guardian_last_name"
                        innerRef={register}
                      />
                    </CCol>
                    <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                      <CLabel>{i18next.t("Email")}</CLabel>
                      <CInput
                        type="email"
                        id="email-input"
                        name="guardian_email"
                        placeholder="Enter Email"
                        autoComplete="email"
                        innerRef={register}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                      <CLabel
                        htmlFor="guardian_address"
                        className={showLegal ? "required" : ""}
                      >
                        {i18next.t("Address")}
                      </CLabel>
                      <CInput
                        type="text"
                        id="guardian_address"
                        name="guardian_address"
                        innerRef={register}
                      />
                    </CCol>
                    <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                      <CLabel
                        className={showLegal ? "required" : ""}
                        htmlFor="guardian_uuid"
                      >
                        {i18next.t("ID")}
                      </CLabel>
                      <CInput
                        className={getClassName("guardian_uuid")}
                        type="number"
                        id="guardian_uuid"
                        name="guardian_uuid"
                        innerRef={register(Validation.guardian_uuid)}
                      />
                    </CCol>
                    <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                      <CLabel className={showLegal ? "required" : ""}>
                        {i18next.t("Mobile No")}.
                      </CLabel>
                      <CInput
                        className={getClassName("guardian_phone")}
                        type="tel"
                        id="mobile"
                        name="guardian_phone"
                        innerRef={register(Validation.guardian_phone)}
                      />
                    </CCol>
                  </CFormGroup>
                </CCollapse>
                <CFormGroup row>
                  <CCol>
                    <h4 className="header-label">{i18next.t("Hospital Info")}</h4>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <CLabel className="required" htmlFor="specialty">
                      {i18next.t("Specialty")}
                    </CLabel>
                    <CSelect
                      custom
                      className={getClassName("specialty_id")}
                      name="specialty_id"
                      id="specialty"
                      innerRef={register(Validation.specialty_id)}
                      onChange={onSpecialtyChange}
                    >
                      <option key={0} value={0}>
                        -- {i18next.t("Select")} --
                      </option>
                      {RenderSelectOptions(specialties)}
                    </CSelect>
                    <CLabel className="labels required">{i18next.t("Specialty Form")}</CLabel>
                    <CSelect
                      className={`${getClassName(
                        "specialty_form_id",
                        "Select Specialty Form",
                        watch,
                        errors
                      )}`}
                      custom
                      name="specialty_form_id"
                      id="specialty_form_id"
                      innerRef={register(Validation.specialty_form_id)}
                      onChange={onSpecialtyFormChange}
                    >
                      <option value="" >
                        {i18next.t("Select Specialty Form")}
                      </option>
                      {RenderSelectOptions(specialtyFormsList || [])}
                    </CSelect>
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <CLabel className="required">{i18next.t("Department")}</CLabel>
                    <CSelect
                      custom
                      className={getClassName("department_id")}
                      name="department_id"
                      id="department"
                      innerRef={register(Validation.department_id)}
                    >
                      {RenderSelectOptions(departments)}
                    </CSelect>
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <CLabel className="required">{i18next.t("Severity")}</CLabel>
                    <CSelect
                      custom
                      className={getClassName("severity")}
                      name="severity"
                      id="severity"
                      innerRef={register(Validation.severity)}
                    >
                      {Severity(severity)}
                    </CSelect>
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                    <CLabel className="required">{i18next.t("Room")}</CLabel>
                    <CInput
                      type="text"
                      className={getClassName("bed")}
                      id="bed"
                      name="bed"
                      innerRef={register(Validation.bed)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                    <CLabel>{i18next.t("ICD Codes")}</CLabel>
                    <Controller
                      control={control}
                      id="icd_codes"
                      name="icd_codes"
                      isMulti
                      cacheOptions
                      loadOptions={(inputValue) => getIcdOptions(inputValue)}
                      getOptionLabel={(option) =>
                        `${option.theCode} : ${removeHtmlTags(option.title)}`
                      }
                      getOptionValue={(option) => option.id}
                      as={AsyncSelect}
                    />
                    {icdErrorMsg && (
                      <span className="alert-danger">{icdErrorMsg}</span>
                    )}
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                    {renderPreviousIcds()}
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                    <CLabel className="required">{i18next.t("Chief Complaint")}</CLabel>
                    <CTextarea
                      type="text"
                      className={getClassName("chief_complaint")}
                      id="chief_complaint"
                      name="chief_complaint"
                      rows="5"
                      innerRef={register(Validation.chief_complaint)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                    <CLabel>{i18next.t("History of Present Illness")} </CLabel>
                    <CTextarea
                      id="present_illness_history"
                      name="present_illness_history"
                      rows="5"
                      innerRef={register}
                    />
                  </CCol>
                  <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
                    <CLabel>{i18next.t("Examination")}</CLabel>
                    <CTextarea
                      id="examination"
                      name="examination"
                      rows="5"
                      innerRef={register}
                    />
                  </CCol>
                </CFormGroup>
                <SurveyForm
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  groups={specialtyForm ? specialtyForm.groups : []}
                />
                <CFormGroup row>
                  <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                      style={{ width: "100%" }}
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}>
                      {isSubmitting ? (
                          <>
                            <CSpinner color="primary" size="sm" variant="grow" />
                            <span style={{ color: "dark" }}>{i18next.t("Loading")}...</span>
                          </>
                        ) : (
                            <span>{i18next.t("Save")}</span>
                        )}
                  </CButton>
                  </CCol>
                  <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CButton
                      className="btn-form"
                      color="danger"
                      type="reset"
                      onClick={onCancel}
                    >
                      {i18next.t("Cancel")}
                    </CButton>
                  </CCol>
                </CFormGroup>
              </CCardBody>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default EpisodeForm;
