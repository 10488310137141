import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CTooltip,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import StarRatingComponent from "react-star-rating-component";
import { fetchOldReport } from "actions/reports/reports";
import { fetchEpisodeUtilities } from "actions/episodes/episodes";
import { LIMIT } from "constants/constants";
import { removeEmptyProperties, timeDifference } from "helpers/helpers";
import { Api, getTokenHeader } from "services/Api";
import fileDownload from "js-file-download";
import ReportFilters from "../partials/ReportFilters";
import getFields from "../partials/ReportColumns";
import { TYPE_PATIENT_EPISODE_DISCHARGE, TYPE_HOSPITAL_EPISODE_DISCHARGE } from "constants/reports";
import { mapKeys } from "lodash";

const OldReport = () => {
  const { type } = useParams();
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);
  const discharge_reason = useSelector((state) => mapKeys(state.episodeUtilities.discharge_reasons, 'id'));
  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/old-reports/${type}?page=${newPage}`);
    }
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchOldReport({ type, page, limit: LIMIT }));
  }, [dispatch, page, type]);

  useEffect(() => {
    if ([TYPE_PATIENT_EPISODE_DISCHARGE, TYPE_HOSPITAL_EPISODE_DISCHARGE].includes(type)) {
      dispatch(fetchEpisodeUtilities());
    }
  }, [type]);

  const handleExportReport = async () => {
    const response = await Api.get(`/api/admin/report/${type}/export`, {
      ...getTokenHeader(),
      responseType: "blob",
    });
    fileDownload(response.data, `${type}.xlsx`);
  };

  const renderExportBtn = () => {
    if (reports && reports.data && reports.data.length) {
      return (
        <CButton
          onClick={() => handleExportReport()}
          className="create-button"
          style={{ "margin-left": "40%", "margin-top": "2%", width: "15em" }}
        >
          <CIcon name="cil-cloud-download" />
          Export
        </CButton>
      );
    }
    return "";
  };

  const onFiltersSubmit = (filterData) => {
    const preparedData = {
      type,
      page,
      limit: LIMIT,
      ...filterData,
      doctor_id: filterData.doctor ? filterData.doctor.id : null,
      doctor: null,
      patient_id: filterData.patient ? filterData.patient.id : null,
      patient: null,
      hospital_id: filterData.hospital ? filterData.hospital.id : null,
      hospital: null,
    };
    dispatch(fetchOldReport(removeEmptyProperties(preparedData)));
  };

  const tableFields = () => {
    const customFields = getFields(type);
    if (customFields) {
      return customFields;
    }
    return reports.data && reports.data[0] ? Object.keys(reports.data[0]) : [];
  };
  return (
    <CCol xl={12}>
      <CCard>
        <h4 className="table-title">{`Report : ${type}`}</h4>
        <CCardHeader>
          <ReportFilters type={type} onFiltersSubmit={onFiltersSubmit} />
        </CCardHeader>
        {renderExportBtn()}
        <CCardBody>
          <CDataTable
            items={reports.data || []}
            fields={tableFields()}
            noItemsViewSlot="No Available Items"
            scopedSlots={{
              actions: (item) => (
                <td>
                  <a
                    href={`/old-reports/${type}/details/${item.id}`}
                    style={{
                      marginRight: "5px",
                      "background-color": "rgb(48, 197, 182)",
                      "border-color": "rgb(48, 197, 182)",
                      color: "white",
                      width: "7em",
                    }}
                    className="btn"
                  >
                    show
                  </a>
                </td>
              ),
              rate: (item) => (
                <td>
                  <CTooltip content={item.rate} placement="top">
                    <div>
                      <StarRatingComponent
                        id="rate"
                        name="rate"
                        starCount={5}
                        value={item.rate}
                      />
                    </div>
                  </CTooltip>
                </td>
              ),
              discharge_reason: (item) => (
                <td>
                  <CTooltip content={item.discharge_reason} placement="top">
                    <div>
                      {item.discharge_reason && discharge_reason[item.discharge_reason]?
                        discharge_reason[item.discharge_reason].name: '-'}
                    </div>
                  </CTooltip>
                </td>
              ),
              time_diff: (item) => (
                <td>
                  <CTooltip content={item.time_diff} placement="top">
                    <div>
                      {item.time_diff?
                        timeDifference(item.time_diff*1000): 'NA'}
                    </div>
                  </CTooltip>
                </td>
              ),
              attendance_precentage: (item) => (
                <td>{item.attendance_precentage * 100 + " "}%</td>
              ),
              prescription_executions_precentage: (item) => (
                <td>
                  {item.attendance_precentage
                    ? item.attendance_precentage * 100 + " "
                    : "0 "}
                  %
                </td>
              ),
            }}
            hover
            striped
          />
          <CPagination
            activePage={page}
            onActivePageChange={pageChange}
            pages={reports.last_page || 1}
            doubleArrows={false}
            align="center"
          />
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default OldReport;
