import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Api, getTokenHeader } from "services/Api";
import CreatePrescription from "./Create";
import EditPrescription from "./Edit";
import List from "./List";
import Show from "./Show";
import { TYPE_DISCHARGE_PRESCRIPTION } from "constants/constants";
import i18next from "i18next";

const Prescription = ({
  type,
  modelId,
  title,
  episodeStatus,
  showStatus = true,
  canConfirm = true,
  showCreate = false
}) => {
  if (type === TYPE_DISCHARGE_PRESCRIPTION) {
    showCreate = true;
  }
  const [modal, setModal] = useState(false);
  const prescription = useSelector((state) => state.prescriptions.prescription);
  const utilities = useSelector((state) => state.prescriptionUtilities);
  const [isEditing, setIsEditing] = useState(false);
  const [modifiedId, setModifiedId] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  const getDrugOptions = async (inputValue) => {
    let options = [];
    const url = `/api/admin/drugs?q=${inputValue}`;
    options = await Api.get(url, {
      ...getTokenHeader(),
    }).then((response) => response.data.data);
    return options;
  };
  if (isEditing && prescription && prescription.id === prescriptionId) {
    return (
      <>
        {episodeStatus === 0 ? (
          <EditPrescription
            toggle={toggle}
            modal={modal}
            prescription={prescription}
            setIsEditing={setIsEditing}
            getDrugOptions={getDrugOptions}
            type={type}
          />
        ) : (
          ""
        )}
        <List
          toggle={toggle}
          setIsEditing={setIsEditing}
          setPrescriptionId={setPrescriptionId}
          toggleShowModal={toggleShowModal}
          title={title}
          showStatus={showStatus}
          type={type}
          showCreate={showCreate}
        />
        <Show
          prescription={prescription}
          showModal={showModal}
          toggleShowModal={toggleShowModal}
          prescriptionId={prescriptionId}
          utilities={utilities}
        />
      </>
    );
  }

  return (

    <>
      {episodeStatus === 0 || showCreate ? (
        <CreatePrescription
          toggle={toggle}
          modal={modal}
          getDrugOptions={getDrugOptions}
          modifiedId={modifiedId}
          setModifiedId={setModifiedId}
          type={type}
          modelId={modelId}
          canConfirm={canConfirm}
        />
      ) : (
        ""
      )}
      <List
        toggle={toggle}
        episodeStatus={episodeStatus}
        setIsEditing={setIsEditing}
        setPrescriptionId={setPrescriptionId}
        setModifiedId={setModifiedId}
        toggleShowModal={toggleShowModal}
        title={title}
        type={type}
        showCreate={showCreate}
      />
      <Show
        prescription={prescription}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
        prescriptionId={prescriptionId}
        utilities={utilities}
      />
    </>
  );
};
export default Prescription;
