import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import AssignUserForm from "./form/AssignUserForm";
import { assignUserToHospitals, getAssignedHospitals } from "actions/users/users";

const AssignUser = ({ match}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const assignedHospitals = useSelector((state) => state.users.assignedHospitals);
  const redirect = useSelector((state) => state.users.redirect);

  const onSubmit = (data) => {
    let formData = [];
    if(data.rows){
      formData = data.rows.filter(row => {
        return row.hospital && row.roles;
      }).map(row => {
        return {hospital: row.hospital.id, roles: row.roles.map(role => role.id)}
      })
    }
    dispatch(assignUserToHospitals({rows: formData}, match.params.id));
  }
  if (redirect) {
    history.push("/users");
  }

  useEffect(() => {
    dispatch(getAssignedHospitals(match.params.id));
  }, [dispatch, match.params.id]);


  if(user){
    return <AssignUserForm
          onSubmit={onSubmit}
          user={user}
          assignedHospitals={assignedHospitals}
          />
  }else{
    return <></>
  }
};

export default AssignUser;
