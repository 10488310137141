import { getToken } from "firebase/messaging";
import { Api, getTokenHeader } from "services/Api";
import { REGISTER_FIREBASE_TOKEN } from "./types";
import messaging from "services/firebase";

export const registerDeviceToken = () => async (dispatch) => {
	try {

		// for project avh-backend cloud messaging tab web push config
		const webPushKeyPair = "BLLnh8BODyC3CwhxJUT5MIV92qkdZkxzfIR-wkNyN7aHQwwUjqtlcQCZ4U-FRHIP7pUMMlKfLu_sNfEniZ_WAJg";

		// const messaging = getMessaging(firebaseApp)
		const device_token = await getToken(messaging, {vapidKey: webPushKeyPair});
		const device_type = 'web_device';

		const response = await Api.post(`/api/admin/firebase/register`, { device_token , device_type }, {
		 	...getTokenHeader(),
		 });

		console.log("register token response>>>>", response.data);
		dispatch({ type: REGISTER_FIREBASE_TOKEN, payload: response.data });
		/* dispatch({ type: REGISTER_FIREBASE_TOKEN, payload: {
			'user_id': 2,
			'device_token': device_token,
		} }); */

		console.log("GENERATE DEVICE TOKEN>>>>>", device_token, device_type);
	} catch (error) {
		console.log("FIREBASE TOKE ERROR>>>", error);
	};
};