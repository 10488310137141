import {
  CREATE_QUESTION,
  FETCH_QUESTION,
  UPDATE_QUESTION,
  FETCH_QUESTIONS,
  DELETE_QUESTION,
  ADD_SUB_QUESTION,
  DELETE_SUB_QUESTION,
	PATIENT_ANSWERED_QUESTIONS,
	PATIENT_QUESTION_ANSWERS,
	RESET_STATISTICS,
} from "actions/surveys/questions/types";
import { UNAUTHORIZED_STATUS_CODE } from "constants/constants";

/**
 * @param state
 * @param action
 */
export default function questions(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_QUESTION:
    case UPDATE_QUESTION:
      if(action.payload.status == UNAUTHORIZED_STATUS_CODE){
        return {...state, redirect: false }
      }
      return { question: action.payload.data, redirect: true };
    case FETCH_QUESTION:
      return {
        question: action.payload.data,
        subQuestions: action.payload.data.children,
      };
    case FETCH_QUESTIONS:
      return { ...action.payload };
    case DELETE_QUESTION:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case ADD_SUB_QUESTION:
      return {
        ...state,
        subQuestions: state.subQuestions
          ? [...state.subQuestions, action.payload]
          : [action.payload],
      };
    case DELETE_SUB_QUESTION:
      return {
        ...state,
        subQuestions: state.subQuestions.filter(
          (item) => item.id !== action.payload
        ),
      };
    case PATIENT_ANSWERED_QUESTIONS:
      return {
        ...state,
        answeredQuestions: action.payload.data,
      };
    case PATIENT_QUESTION_ANSWERS:
      return {
        ...state,
        questionAnswers: action.payload.data,
      };
		case RESET_STATISTICS:
		  return {
				...state,
        answeredQuestions: undefined,
				questionAnswers: [],
			};
    default:
      return state;
  }
}
