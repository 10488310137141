import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CContainer,
  CCallout,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { fetchPublicRounds, registerAttendee } from "actions/rounds/rounds";
import AttendeeModal from "./AttendeeModal";
import i18next from "i18next";

const ListPublicRounds = ({ rounds, register }) =>
  rounds.map((item) => (
    <CCol sm={12} md={4}>
      <CCallout color="danger" className="bg-secondary">
        <strong className="text-muted">{item.title}</strong>
        <br />
        <strong className="h4">Organizer : {item.organizerName}</strong>
        <CButton onClick={() => register(item.id)}> Rigester</CButton>
      </CCallout>
    </CCol>
  ));

const PublicRounds = () => {
  const dispatch = useDispatch();
  const rounds = useSelector((state) => state.rounds);
  const [attendeeModal, setAttendeeModal] = useState(false);
  const [roundId, setRoundId] = useState(false);

  const register = (id) => {
    setRoundId(id);
    setAttendeeModal(true);
  };

  const onSubmit = (formData) => {
    if (roundId) {
      const preparedData = {
        ...formData,
        round_id: roundId,
      };
      console.log(preparedData);
      dispatch(registerAttendee(preparedData));
      setAttendeeModal(false);
    }
  };

  useEffect(() => {
    dispatch(fetchPublicRounds());
  }, [dispatch]);

  return (
    <CContainer>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title mb-3  text-mobile-small-size">
            {i18next.t("PUBLIC ROUNDS")}
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CRow>
                <ListPublicRounds
                  rounds={Object.values(rounds) || []}
                  register={register}
                />
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <AttendeeModal
        onSubmit={onSubmit}
        onCancel={() => setAttendeeModal(false)}
        modal={attendeeModal}
        FormTitle="Rigester"
      />
    </CContainer>
  );
};

export default PublicRounds;
