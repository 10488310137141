import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "@twilio/conversations";
import { CCard, CCardBody, CCardFooter, CCardHeader } from "@coreui/react";
import {
  addCoordinatorsToConversation,
  addDoctorsToConversation,
} from "actions/communications/communications";
import ChatInput from "./ChatInput";
import ChatLog from "./ChatLog";

const ChatRoom = ({ token, room, lobby_id, participants, readOnly }) => {
  const dispatch = useDispatch();
  const [conversation, setConversation] = useState(null);
  const loggedinUser = useSelector((state) => state.auth.jwt.user);

  const initConversation = () => {
    Client.create(token).then((client) => {
      clientInitiated(client);
    });
  };

  const addParticipants = (conversation) => {
    console.log('addParticipants');
    console.log(participants);
    if(participants){
      for (var participant of participants) {
        conversation.getParticipantByIdentity(participant)
          .catch((err) => {
            conversation.add(participant).catch((err) => {
          });
      });
    }
  }
  } 

  const clientInitiated = (ConversationsClient) => {
    ConversationsClient.getConversationByUniqueName(room)
      .then((conversation) => {
        if (conversation) {
          setConversation(conversation);
          conversation.join().catch((err) => {});
          addParticipants(conversation);
        }
      })
      .catch((err) => {
        if (err.body.code === 50300) {
          return ConversationsClient.createConversation({
            uniqueName: room,
            attributes: {
              patient: `${loggedinUser.first_name} ${loggedinUser.last_name}`,
              lobby_id,
            },
          });
        }
      }).catch((err) => {})
      .then((conversation) => {
        if (conversation) {
          setConversation(conversation);
          addParticipants(conversation);
          if (conversation.channelState !== "joined") {
            conversation.join();
            dispatch(
              addCoordinatorsToConversation({
                room,
                conversation_id: conversation.sid,
              })
            );
            
            dispatch(
              addDoctorsToConversation({
                room,
                conversation_id: conversation.sid,
              })
            );
          }
        }
      });
  };

  useEffect(() => {
    if (token) {
      initConversation();
    }
  }, [room]);

  if (conversation) {
    return (
          <CCard>
            <CCardHeader>
              {room}
            </CCardHeader>
            <CCardBody>
              {conversation.uniqueName == room && (<ChatLog conversation={conversation} />)}
            </CCardBody>
            {!readOnly && (
              <CCardFooter>
                <ChatInput conversation={conversation}/>
              </CCardFooter>
              )
            }
          </CCard>
    );
  }
  return <></>;
};
export default ChatRoom;
