import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { getBlobExt } from "helpers/helpers";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CTextarea,
  CInputFile,
  CSelect,
  CSpinner,
} from "@coreui/react";
import fileDownload from "js-file-download";
import { Api, getTokenHeader } from "services/Api";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

const RadiologyCenterForm = (props) => {
  const {
    onSubmit,
    radiologyCenter,
    agreementView,
    label,
    countries,
    cities,
    onCountryChange,
  } = props;
  const [agreementViewstring, setAgreementViewstring] = useState("");
  useEffect(() => {
    if (agreementView) {
      setAgreementViewstring(agreementView);
    }
  }, [agreementView]);

  let defaultValues = {};
  if (radiologyCenter) {
    delete radiologyCenter.agreement;
    defaultValues = radiologyCenter;
  }
  const handleDownload = async (agreement) => {
    const response = await Api.get(
      `${agreement}`,
      {
        ...getTokenHeader(),
        responseType: "blob",
      }
    );
    fileDownload(response.data, `${agreement}${getBlobExt(response.data)}`);
  };

  const { register, handleSubmit, watch, errors , formState} = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const Validation = getValidation(watch);
  const history = useHistory();
  const { isSubmitting } = formState;
  const onCancel = () => {
    history.push(`/service/providers/radiology-centers/`);
  };

  return (
    <CRow>
      <CCol xs="12">
        <CForm
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          className="form-horizontal"
        >
          <CCard>
            <CCardHeader>
              <CCol xs="12">
                <h4 className="header-label-title">{label}</h4>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CCol xs="12">
                <h4 className="header-label">{i18next.t("General Info")}</h4>
              </CCol>
              <CFormGroup row>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel className="labels float-left required" htmlFor="name">
                    {i18next.t("Name")}
                  </CLabel>
                  <CInput
                    className={`${getClassName("name", "name", watch, errors)}`}
                    type="text"
                    id="name"
                    name="name"
                    placeholder={i18next.t("Enter Center Name")}
                    innerRef={register(Validation.name)}
                  />
                </CCol>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel
                    className="labels float-left required"
                    htmlFor="address"
                  >
                    {i18next.t("Address")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "address",
                      "address",
                      watch,
                      errors
                    )}`}
                    type="text"
                    id="address"
                    name="address"
                    placeholder={i18next.t("Enter Center Address")}
                    innerRef={register(Validation.name)}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel
                    className="labels float-left required"
                    htmlFor="phone"
                  >
                    {i18next.t("Phone")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "phone",
                      "phone",
                      watch,
                      errors
                    )}`}
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder={i18next.t("Enter Phone Number")}
                    innerRef={register(Validation.phone)}
                  />
                </CCol>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel
                    className="labels float-left required"
                    htmlFor="country_id"
                  >
                    {i18next.t("Country")}
                  </CLabel>
                  <CSelect
                    className={`${getClassName(
                      "country_id",
                      "country_id",
                      watch,
                      errors
                    )}`}
                    custom
                    name="country_id"
                    id="country_id"
                    onChange={onCountryChange}
                    innerRef={register(Validation.require)}
                  >
                    <option key="0" value="" selected disabled>
                      {i18next.t("Select Country")}
                    </option>
                    {RenderSelectOptions(countries)}
                  </CSelect>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6}>
                  <CLabel
                    className="labels float-left required"
                    htmlFor="city_id"
                  >
                    {i18next.t("City")}
                  </CLabel>
                  <CSelect
                    className={`${getClassName(
                      "city_id",
                      "city_id",
                      watch,
                      errors
                    )}`}
                    custom
                    name="city_id"
                    id="city_id"
                    innerRef={register(Validation.require)}
                  >
                    <option key="0" value="" selected disabled>
                      {i18next.t("Select City")}
                    </option>
                    {RenderSelectOptions(cities)}
                  </CSelect>
                </CCol>
                <CCol sm={12} md={6} xs={12} lg={6} xl={6} className="my-1">
                  <CLabel className="labels float-left" htmlFor="agreement">
                    {i18next.t("Agreement")}
                  </CLabel>
                  <CInputFile
                    id="custom-file-input"
                    name="agreement"
                    innerRef={register}
                  />
                  <CLabel
                    style={{
                      top: "1em",
                      right: "1em",
                      left: "1em",
                      marginTop: ".5em",
                    }}
                    htmlFor="custom-file-input"
                    variant="custom-file"
                  >
                    {(watch("agreement") &&
                      watch("agreement")[0] &&
                      watch("agreement")[0].name) ||
                      "Choose file..."}
                  </CLabel>
                  {agreementViewstring && <CButton
                        onClick={() => handleDownload(agreementViewstring)}
                        style={{ marginRight: "5px", width: "auto", marginTop: "5px" }}
                        className="btn btn-primary"
                      >
                        {i18next.t("Download Agreement")}
                      </CButton>}
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol sm={12} md={12} xs={12} lg={12} xl={12} className="my-1">
                  <CLabel
                    className="labels float-left required"
                    htmlFor="description"
                  >
                    {i18next.t("Description")}
                  </CLabel>
                  <CTextarea
                    className={`${getClassName(
                      "description",
                      "description",
                      watch,
                      errors
                    )}`}
                    name="description"
                    id="description"
                    rows="9"
                    placeholder={i18next.t("Description")}
                    innerRef={register(Validation.name)}
                  />
                </CCol>
              </CFormGroup>
              <CCol xs="12">
                <h4 className="header-label">{i18next.t("Contact Info")}</h4>
              </CCol>
              <CFormGroup row>
                <CCol sm={12} md={12} xs={12} lg={4} xl={4}>
                  <CLabel
                    className="labels float-left required"
                    htmlFor="c_name"
                  >
                    {i18next.t("Name")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "c_name",
                      "c_name",
                      watch,
                      errors
                    )}`}
                    type="text"
                    id="c_name"
                    name="c_name"
                    placeholder={i18next.t("Enter Name")}
                    innerRef={register(Validation.name)}
                  />
                </CCol>
                <CCol sm={12} md={12} xs={12} lg={4} xl={4}>
                  <CLabel
                    className="labels float-left required"
                    htmlFor="c_phone"
                  >
                    Phone
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "c_phone",
                      "c_phone",
                      watch,
                      errors
                    )}`}
                    type="text"
                    id="c_phone"
                    name="c_phone"
                    placeholder={i18next.t("Enter Phone Number")}
                    innerRef={register(Validation.phone)}
                  />
                </CCol>
                <CCol sm={12} md={12} xs={12} lg={4} xl={4}>
                  <CLabel
                    className="labels float-left required"
                    htmlFor="c_email"
                  >
                    {i18next.t("Email")}
                  </CLabel>
                  <CInput
                    className={`${getClassName(
                      "c_email",
                      "c_email",
                      watch,
                      errors
                    )}`}
                    type="email"
                    id="c_email"
                    name="c_email"
                    placeholder={i18next.t("Enter Email")}
                    autoComplete="email"
                    innerRef={register(Validation.email)}
                  />
                </CCol>
              </CFormGroup>
            </CCardBody>
            <CCardFooter>
              <CFormGroup row>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    type="submit"
                    color="primary"
                    className="mx-1 btn-form"
                    disabled={isSubmitting}
                    >
                 {isSubmitting ? (
                      <>
                        <CSpinner color="primary" size="sm" variant="grow" />
                        <span style={{ color: "dark" }}>{i18next.t("Loading")}...</span>
                      </>
                    ) : (
                      <span>{i18next.t("Save")}</span>
                    )}
                  </CButton>
                </CCol>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  );
};

export default RadiologyCenterForm;
