import React from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
} from "@coreui/react";

const VisitCard = ({ visit }) => {
  console.log(visit);
  return (
    <CCard
      className="bg-secondary py-3 text-dark-"
      style={{ color: "black", borderRadius: "15px" }}
    >
      <CCardBody>
        <CRow>
          <CCol xs="12">
            <CCardSubtitle>{visit.created_at}</CCardSubtitle>
          </CCol>
          <CCol xs="12">
            <CCardTitle>{visit.spock.name}</CCardTitle>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default VisitCard;
