import React, { useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CCol, CRow, CInput, CLabel, CSelect, CFormGroup } from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSearch } from "@coreui/icons";
import i18next from "i18next";

const FiltersPublicSection = ({ onFilter }) => {
  const [filters, setFilters] = useState({});
  const utilities = useSelector((state) => state.surveyUtilities);
  const { types, hospitals, specialties } = utilities;

  const handleSearchBoxChange = (event) => {
    setFilters({ ...filters, title: event.target.value });
    onFilter({ ...filters, title: event.target.value });
  };

  const handleFromDateChange = (event) => {
    setFilters({ ...filters, from: event.target.value });
    onFilter({ ...filters, from: event.target.value });
  };

  const handleToDateChange = (event) => {
    setFilters({ ...filters, to: event.target.value });
    onFilter({ ...filters, to: event.target.value });
  };

  const handleLocationChange = (event) => {
    setFilters({ ...filters, hospital_id: event.target.value });
    onFilter({ ...filters, hospital_id: event.target.value });
  };

  const handleSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty_id: event.target.value });
    onFilter({ ...filters, specialty_id: event.target.value });
  };

  return (
    <>
      <CFormGroup row>
        <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
          <CLabel className="labels" htmlFor="location">
            {i18next.t("Location")}
          </CLabel>
          <CSelect
            custom
            name="location"
            id="location"
            defaultValue=""
            onChange={handleLocationChange}
          >
            <option key="0" value="" >
              {i18next.t("Select Location")}
            </option>
            {RenderSelectOptions(hospitals || [])}
          </CSelect>
        </CCol>
        <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
          <CLabel className="labels" htmlFor="specialty">
            {i18next.t("Specialty")}
          </CLabel>
          <CSelect
            custom
            name="specialty"
            id="specialty"
            defaultValue=""
            onChange={handleSpecialtyChange}
          >
            <option key="0" value="" >
              {i18next.t("Select Specialty")}
            </option>
            {RenderSelectOptions(specialties || [])}
          </CSelect>
        </CCol>
        <CCol>
          <CLabel className="labels" htmlFor="from">
            {i18next.t("From")}
          </CLabel>
          <CInput
              type="date"
              id="from"
              name="from"
              max={new Date().toISOString().substr(0, 10)}
              onChange={handleFromDateChange}
          />
        </CCol>
        <CCol>
          <CLabel className="labels" htmlFor="to">
            {i18next.t("To")}
          </CLabel>
          <CInput
              type="date"
              id="to"
              name="to"
              max={new Date().toISOString().substr(0, 10)}
              onChange={handleToDateChange}
          />
        </CCol>
      </CFormGroup>
      <CRow>
        <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
          <div>
            <CInput
              className="search-input"
              type="text"
              placeholder={i18next.t("Search by Title")}
              onBlur={handleSearchBoxChange}
            />
            <CIcon
              style={{
                width: "2.5rem",
              }}
              content={cilSearch}
              className="c-icon-xl search-icon"
            ></CIcon>
          </div>
        </CCol>
      </CRow>
    </>
  );
};

export default FiltersPublicSection;
