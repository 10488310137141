import React, { useState } from "react";
import { useForm } from "react-hook-form";
import i18next from "i18next";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CSelect,
} from "@coreui/react";
import Screens from "views/surveys/questions/components/Screens";
import {
  TEXT,
  CHECKBOX,
  RADIO,
  DROPDOWN,
  COMPOSITE,
} from "constants/questions";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import { useHistory } from "react-router-dom";

const QuestionForm = ({ onSubmit, question, label }) => {
  const [activeScreen, setActiveScreen] = useState(
    question ? question.type : null
  );
  let defaultValues = {};
  if (question) {
    defaultValues = { ...question, answers: question.answers || [] };
  }

  const { register, handleSubmit, control, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const handelOnchange = (e) => {
    setActiveScreen(e.target.value);
  };

  const Validation = getValidation(watch);
  const history=useHistory()

  const onCancel=()=>{
    history.push("/questions")
  }
  return (
    <CRow>
      <CCol xs="12">
        <CForm
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          className="form-horizontal"
        >
          <CCard>
            <CCardHeader>
              <h4 className="header-label-title">{label}</h4>
            </CCardHeader>
            <CCardBody>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                  <CLabel className="labels required">
                    {i18next.t("Question Type")}
                  </CLabel>
                  <CSelect
                    className={`${getClassName("type", "type", watch, errors)}`}
                    innerRef={register(Validation.require)}
                    custom
                    name="type"
                    id="type"
                    onChange={handelOnchange}
                  >
                    <option key="0" value="" selected disabled>
                      {i18next.t("Select Type")}
                    </option>
                    <option key={TEXT} value={TEXT}>
                      {i18next.t("Text")}
                    </option>
                    <option key={CHECKBOX} value={CHECKBOX}>
                      {i18next.t("Checkbox")}
                    </option>
                    <option key={RADIO} value={RADIO}>
                      {i18next.t("Radio")}
                    </option>
                    <option key={DROPDOWN} value={DROPDOWN}>
                      {i18next.t("Dropdown")}
                    </option>
                    <option key={COMPOSITE} value={COMPOSITE}>
                      {i18next.t("Composite")}
                    </option>
                  </CSelect>
                </CCol>
                <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                  <CLabel className="labels required" htmlFor="question">
                    {i18next.t("Question")}
                  </CLabel>
                  <CInput
                    type="text"
                    id="question"
                    name="question"
                    placeholder={i18next.t("Write Your Question")}
                    className={`${getClassName(
                      "question",
                      "question",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.require)}
                  />
                </CCol>
                <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                  <CLabel className="labels required">
                    {i18next.t("Data type")}
                  </CLabel>
                  <CSelect
                    custom
                    name="data_type"
                    id="data_type"
                    className={`${getClassName(
                      "data_type",
                      "data_type",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.require)}
                  >
                    <option key="0" value="" selected disabled>
                      {i18next.t("Select Type")}
                    </option>
                    <option key="1" value="1">
                      {i18next.t("String")}
                    </option>
                    <option key="2" value="2">
                      {i18next.t("Integer")}
                    </option>
                    <option key="3" value="3">
                      {i18next.t("Float")}
                    </option>
                  </CSelect>
                </CCol>
              </CFormGroup>
              <Screens
                activeScreen={activeScreen}
                register={register}
                control={control}
                question={question}
                watch={watch}
                errors={errors}
              />
            </CCardBody>
            <CCardFooter>
            <CFormGroup row>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    type="submit"
                    color="primary"
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Save")}
                  </CButton>
                </CCol>
                <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CButton
                    color="danger"
                    type="reset"
                    onClick={onCancel}
                    className="mx-1 btn-form"
                  >
                    {i18next.t("Cancel")}
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  );
};

export default QuestionForm;
