import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import notPermission from "./../../../noPermission.jpg";
import i18next from "i18next";

const NoPermission = () => (
  <div
    className="align-items-center justify-content-center"
    style={{
      height: "100vh",
      display: "flex",
    }}
  >
    <CContainer>
      <CRow className="justify-content-center">
        <CCol md="6">
          <div style={{width: '100%', height: '100%', marginBottom: '1rem'}}>
          <img src={notPermission} alt="NonPermission" style={{
            width: '100%',
            height: '100%'
          }}  />
          </div>
          <div className="clearfix">
            <h4 className="text-align-center">
              <strong>
                {i18next.t("You have no Permission to access this Page")}
              </strong>
            </h4>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
);

export default NoPermission;
