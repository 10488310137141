import React from "react";

import {
  TEXT,
  CHECKBOX,
  RADIO,
  DROPDOWN,
  COMPOSITE,
} from "constants/questions";
import Answers from "./Answers";
import Composite from "./Composite";

const Screens = ({
  activeScreen,
  register,
  control,
  question,
  watch,
  errors,
}) => {
  switch (Number(activeScreen)) {
    case COMPOSITE:
      return (
        <Composite
          watch={watch}
          errors={errors}
          register={register}
          control={control}
          question={question}
        />
      );
    case CHECKBOX:
    case RADIO:
    case DROPDOWN:
      return (
        <Answers
          watch={watch}
          errors={errors}
          register={register}
          control={control}
        />
      );
    default:
      return <></>;
  }
};
export default Screens;
