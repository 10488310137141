import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CCol, CRow, CCard, CCardBody, CSpinner } from "@coreui/react";
import { fetchEpisode, fetchEpisodeUtilities } from "actions/episodes/episodes";
import { fetchFollowUpUtilities } from "actions/follow-ups/followUps";
import { OPEN_NOTIFICATION } from "actions/notifications/types";
import CallsSection from "views/episode-management/screens/spoke/cases/partials/callsSection";
import PatientData from "./PatientData";
import Content from "./Content";
import { EPISODE_STATUS_OPENED } from "constants/constants";
import Loading from "views/partials/Loading";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Case = () => {
  const { episodeId } = useParams();
  const query = useQuery();
  const calling = query.get("calling") === "true";
  const dispatch = useDispatch();
  const episode = useSelector((state) => state.episodes.episode);
  useEffect(() => {
    dispatch(fetchEpisode(episodeId));
    dispatch(fetchFollowUpUtilities());
    dispatch(fetchEpisodeUtilities());
    dispatch({ type: OPEN_NOTIFICATION, payload: episodeId });
  }, [dispatch, episodeId]);
  if (episode) {
    return (
      <CRow>
        <CCol>
          <CCard className="card-3d">
            <CCardBody>
              <CRow>
                <CCol xs="12">
                  <PatientData
                    episode={episode}
                    discharge={true}
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard className="card-3d">
            {episode.status === EPISODE_STATUS_OPENED && (
              <CallsSection episodeId={episodeId} episode={episode} calling={calling} />
            )}
            <CCardBody className="p-0" style={{marginRight:'2rem'}}>
              <CRow>
                <CCol md="12">
                  <Content />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }
  return <Loading/>
};
export default Case;
