import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import i18next from "i18next";

import {
  LABS,
  RADS,
  PRESCRIPTION,
  OLD_PRESCRIPTION,
  TYPE_DISCHARGE_PRESCRIPTION,
  TYPE_EPISODE,
  PRESCRIPTION_STATUS_ACTIVE,
  FOLLOW_UP_PLANS,
  CREATE_FOLLOW_UP_PLAN,
} from "constants/constants";
import {
  fetchPrescriptionUtilities,
  fetchPrescriptions,
  clonePrescriptions,
} from "actions/prescriptions/prescriptions";
import LabOrders from "./LabOrders";
import RadOrders from "./RadOrders";
import Create from "./Create";
import OldPrescription from "./OldPrescription";
import Prescription from "../tabs/prescription/Prescription";
import End from "./End";
import CreateAppointment from "./follow-up-plans/screens/CreateAppointment";
import ListAppointments from "./follow-up-plans/screens/ListAppointments";
import NextScreen from "./NextScreen";
import { CCol, CRow } from "@coreui/react";

const Screens = ({ episode, activeScreen, setActiveScreen}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dischargData, setDischargData] = useState({'discharge_reason':'', 'specialty_id':'', 'note':''});

  const onCancel = () => {
    history.push(`/episodes/spok/case/${episode.id}`);
  };
  switch (activeScreen) {
    case RADS:
      return (
        <RadOrders setActiveScreen={setActiveScreen} onCancel={onCancel} />
      );
    case LABS:
      return (
        <LabOrders setActiveScreen={setActiveScreen} onCancel={onCancel} />
      );
    case OLD_PRESCRIPTION:
      return (
        <OldPrescription
          setActiveScreen={setActiveScreen}
          onCancel={onCancel}
          episode={episode}
        />
      );
    case PRESCRIPTION:
      dispatch(
        clonePrescriptions(
          episode.id,
          TYPE_EPISODE,
          TYPE_DISCHARGE_PRESCRIPTION,
          PRESCRIPTION_STATUS_ACTIVE
        )
      );
      dispatch(fetchPrescriptions(episode.id, TYPE_DISCHARGE_PRESCRIPTION));
      dispatch(fetchPrescriptionUtilities());
      return (
        <>
          <CRow className="justify-content-between align-items-center my-1">
            <CCol>
              <div className="table-title">{i18next.t("Discharge Prescriptions")}</div>
            </CCol>
          </CRow>
          <Prescription type={TYPE_DISCHARGE_PRESCRIPTION} title={i18next.t("Final Prescription")} episodeStatus={0}/>
          <NextScreen
            setActiveScreen={setActiveScreen}
            onCancel={onCancel}
            nextScreen={FOLLOW_UP_PLANS}
            previousScreen={OLD_PRESCRIPTION}
          />
        </>
      );
    case CREATE_FOLLOW_UP_PLAN:
      return (
        <CreateAppointment
          setActiveScreen={setActiveScreen}
          episode={episode}
        />
      );
    case FOLLOW_UP_PLANS:
      return (
        <>
          <ListAppointments setActiveScreen={setActiveScreen} />
          <End
            onCancel={onCancel}
            episode={episode}
            setActiveScreen={setActiveScreen}
          />
        </>
      );
    default:
      return (
        <Create
          episode={episode}
          setActiveScreen={setActiveScreen}
          onCancel={onCancel}
          dischargData={dischargData}
          setDischargData={setDischargData}
        />
      );
  }
};
export default Screens;
