export const TEXT = 1;
export const CHECKBOX = 2;
export const RADIO = 3;
export const DROPDOWN = 4;
export const COMPOSITE = 5;


//data_type Values
export const TYPE_TEXT = 1;
export const TYPE_INTEGER = 2;
export const TYPE_FLOAT = 3;
