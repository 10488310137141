import React from "react";
import {
  CCard,
  CCardBody,
  CRow,
  CCol,
  CNav,
  CNavItem,
  CTabPane,
  CNavLink,
  CTabContent,
  CTabs,
  CContainer,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUserDoctor,
  faFlask,
  faXRay,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import List from "./List";
import "../../../../../../../scss/style.scss";
import i18next from "i18next";

const Investigation = ({
  modelId,
  patientId,
  isPatientView,
  episodeStatus,
  showDownload,
}) => {
  const labInvestigationTypes = useSelector(
    (state) => state.investigation.labInvestigationTypes
  );
  const labInvestigations = useSelector(
    (state) => state.investigation.labInvestigations
  );

  const radInvestigationTypes = useSelector(
    (state) => state.investigation.radInvestigationTypes
  );
  const radInvestigations = useSelector(
    (state) => state.investigation.radInvestigations
  );
  const statusList = useSelector((state) => state.investigation.statusList);
  return (
    <CCard className="w-full border-0">
      <CCardBody className="text-dark p-0">
        <CContainer fluid>
          <CRow>
            <CTabs activeTab="labs">
              <CCol md={4} xs={12} className="text-dark sidebar-tabs-wrap px-0 ">
                <CNav variant="tabs" className="sidebar-tabs past-medical-menu">
                  {/* desktop */}
                  <CNavItem className="w-100 border-bottom episode-tab-desktop ">
                    <CNavLink
                      className="text-body font-weight-bold p-3 b"
                      data-tab="labs"
                    >
                      {/* Labs */}
                      <div className="row">
                        <div className="col-md-1">
                          <FontAwesomeIcon
                            icon={faFlask}
                            className="episode-icon-desktop"
                          />
                        </div>
                        <div className="col-md-1 text-no-wrap ml-3 align-self-center justify-content-center">
                          {i18next.t("labs")}
                        </div>
                      </div>
                    </CNavLink>
                  </CNavItem>

                  {/* mobile */}
                  <CNavItem className="w-full border-bottom episode-tab-mobile ">
                    <CNavLink
                      className="text-body font-weight-bold p-3 b text-sm"
                      data-tab="labs"
                    >
                      {/* Labs */}
                      <FontAwesomeIcon
                        icon={faFlask}
                        className="episode-icon-mobile"
                      />
                    </CNavLink>
                  </CNavItem>

                  {/* desktop */}
                  <CNavItem className="w-100 border-bottom episode-tab-desktop font-sm">
                    <CNavLink
                      className="text-body font-weight-bold p-3 b "
                      data-tab="rads"
                    >
                      {/* Rads */}
                      <div className="row">
                        <div className="col-md-1">
                          <FontAwesomeIcon
                            icon={faXRay}
                            className="episode-icon-desktop "
                          />
                        </div>
                        <div className="col-md-1 text-no-wrap ml-3 align-self-center justify-content-center">
                          {i18next.t("radiology")}
                        </div>
                      </div>
                    </CNavLink>
                  </CNavItem>

                  {/* mobile */}
                  <CNavItem className="w-100 border-bottom episode-tab-mobile ">
                    <CNavLink
                      className="text-body font-weight-bold p-3 b"
                      data-tab="rads"
                    >
                      {/* Rads */}
                      <FontAwesomeIcon
                        icon={faXRay}
                        className="episode-icon-mobile"
                      />
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CCol>
              <CCol md={8} xs={14} className="text-dark p-0 m-0 pt-3">
                <CCard className="border-0">
                  <CCardBody className="p-0 m-0">
                    <CTabContent className="p-0 m-0">
                      <CTabPane data-tab="labs" className="p-0 m-0">
                        <List
                          centerType={1}
                          episodeStatus={episodeStatus}
                          investigationTypes={labInvestigationTypes}
                          investigations={labInvestigations}
                          modelId={modelId}
                          patientId={patientId}
                          isPatientView={isPatientView}
                          statusList={statusList}
                          showDownload={showDownload}
                        />
                      </CTabPane>
                      <CTabPane data-tab="rads" className="p-0 m-0">
                        <List
                          centerType={2}
                          episodeStatus={episodeStatus}
                          investigationTypes={radInvestigationTypes}
                          investigations={radInvestigations}
                          modelId={modelId}
                          patientId={patientId}
                          isPatientView={isPatientView}
                          statusList={statusList}
                          showDownload={showDownload}
                        />
                      </CTabPane>
                    </CTabContent>
                  </CCardBody>
                </CCard>
              </CCol>
            </CTabs>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  );
};

export default Investigation;
