const CriticalCases = [
  {
    name: "Mohamed Mahmoud",
    age: 28,
    gender: "Male",
    duration: "30 Dayes",
    type: "Diabetes",
    status: "critical",
  },
  {
    name: "Ali Mostafa",
    age: 32,
    gender: "Male",
    duration: "20 Dayes",
    type: "Diabetes",
    status: "critical",
  },
  {
    name: "Sofia Lorin",
    age: 25,
    gender: "Female",
    duration: "10 Dayes",
    type: "Diabetes",
    status: "critical",
  },
  {
    name: "Mohamed Mahmoud",
    age: 28,
    gender: "Male",
    duration: "30 Dayes",
    type: "Diabetes",
    status: "critical",
  },
  {
    name: "Ali Mostafa",
    age: 32,
    gender: "Male",
    duration: "20 Dayes",
    type: "Diabetes",
    status: "critical",
  },
  {
    name: "Sofia Lorin",
    age: 25,
    gender: "Female",
    duration: "10 Dayes",
    type: "Diabetes",
    status: "critical",
  },
];
const SpokesCases = [
  {
    name: "Hassan Mohamed",
    age: 28,
    gender: "Male",
    duration: "18 Dayes",
    type: "Diabetes",
    status: "normal",
  },
  {
    name: "Ahmed Mahmoud",
    age: 35,
    gender: "Male",
    duration: "21 Dayes",
    type: "Diabetes",
    status: "normal",
  },
  {
    name: "Hassan Mohamed",
    age: 28,
    gender: "Male",
    duration: "18 Dayes",
    type: "Diabetes",
    status: "normal",
  },
  {
    name: "Ahmed Mahmoud",
    age: 35,
    gender: "Male",
    duration: "21 Dayes",
    type: "Diabetes",
    status: "normal",
  },
];

const MyClinics = [
  {
    name: "Mohamed Mohamed",
    type: "Virtual",
    day: "17",
    month: "June",
    time: "From 5pm To 10pm",
    patients: "48/50",
  },
  {
    name: "Mohamed Mohamed",
    type: "Physical",
    day: "21",
    month: "June",
    time: "From 7pm To 11pm",
    patients: "48/50",
    address: "8 Makrem Ebeid St, Nasr city",
  },
];

const ClinicDetailes = {
  date: "2020-10-20",
  type: "Virtual",
  time: "From 5pm To 10pm",
  patients: "48/50",
};

const PatientsData = [
  {
    name: "Hassan Mohamed",
    age: 28,
    gender: "Male",
    date: "2020-12-15",
    spoke: "South Sinai -ER",
    Specialty: "Neurology",
    duration: "18 Dayes",
    type: "Diabetes",
    national_id: 123456789,
    complaint: "complaint complaint complaint",
  },
  {
    name: "Ahmed Mahmoud",
    age: 35,
    gender: "Male",
    date: "2020-12-16",
    spoke: "South Sinai -ER",
    Specialty: "Neurology",
    duration: "21 Dayes",
    type: "Diabetes",
    national_id: 987654321,
    complaint: "complaint complaint complaint",
  },
];
const DoctorsData = [
  {
    name: "Hassan Mohamed",
    age: 28,
    gender: "Male",
    role: "Resident Doctor",
    spoke: "South Sinai -ER",
    Specialty: "Neurology",
  },
];

const ConsultantsData = [
  {
    name: "Hassan Mohamed",
    age: 28,
    gender: "Male",
    role: "Consultant",
    spoke: "South Sinai -ER",
    Specialty: "Neurology",
  },
  {
    name: "Ali Mostafa",
    age: 28,
    gender: "Male",
    role: "Consultant",
    spoke: "South Sinai -ER",
    Specialty: "Neurology",
  },
  {
    name: "Mohamed Mohamed",
    age: 28,
    gender: "Male",
    role: "Consultant",
    spoke: "South Sinai -ER",
    Specialty: "Neurology",
  },
];

export {
  CriticalCases,
  SpokesCases,
  MyClinics,
  ClinicDetailes,
  PatientsData,
  DoctorsData,
  ConsultantsData,
};
