import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CTextarea,
  CCard,
  CCardHeader,
  CCardBody,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isObject, isArray } from "helpers/helpers";
import { updateMedicalHistory } from "actions/episodes/medicalHistory";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import LegalGuardianInfo from "./LegalGuardianInfo";
import i18next from "i18next";

const MedicalHistory = () => {
  const { episodeId } = useParams();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const medicalHistory = useSelector((state) => state.medicalHistory);
  const userId = useSelector((state) => state.auth.jwt.user.id);
  const { register, handleSubmit, reset, setValue, watch } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (medicalHistory && medicalHistory.editable) {
      const defaultValues = {
        chief_complaint: medicalHistory.chief_complaint,
        examination: medicalHistory.examination,
        present_illness_history: medicalHistory.present_illness_history,
        ...medicalHistory.admissionSurveyInstance,
      };
      reset(defaultValues);
    }
  }, [medicalHistory, reset]);

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const onSubmit = (formData) => {
    dispatch(
      updateMedicalHistory(
        {
          ...formData,
          survey_instance_id: medicalHistory.admissionSurveyInstance
            ? medicalHistory.admissionSurveyInstance.id
            : null,
          patient_id: medicalHistory.patient_id,
          editor_id: userId,
        },
        episodeId
      )
    );
    toggleEdit();
  };

  const renderForm = () => (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={!edit}>
        <CFormGroup row>
          <CCol md="12">
            <h5>{i18next.t("Chief Compliant")}:</h5>
            <CTextarea
              name="chief_complaint"
              id="chief_complaint"
              rows="9"
              innerRef={register}
            />
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol md="12">
            <h5>{i18next.t("History Of Present Illness")}:</h5>
            <CTextarea
              name="present_illness_history"
              id="present_illness_history"
              rows="9"
              innerRef={register}
            />
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol md="12">
            <h5>{i18next.t("Examination")}: </h5>
            <CTextarea
              name="examination"
              id="examination"
              rows="9"
              innerRef={register}
            />
          </CCol>
        </CFormGroup>
        <SurveyForm
          register={register}
          setValue={setValue}
          watch={watch}
          groups={
            medicalHistory.admissionSurveyInstance &&
            medicalHistory.admissionSurveyInstance.survey
              ? medicalHistory.admissionSurveyInstance.survey.groups
              : []
          }
        />
        {edit && (
          <CCol class="text-align-center" md="auto">
            <CButton type="submit" style={{ width: "10%" }} color="primary">
              Save
            </CButton>
          </CCol>
        )}
      </fieldset>
    </CForm>
  );
  const renderAnswerScore = (question, questionAnswer) => {
    if (question.sub_questions && question.sub_questions.length > 0) {
      return questionAnswer.answer_value
        ? `${i18next.t("Total")}: ${questionAnswer.answer_value}`
        : "-";
    }
    return questionAnswer.answer_value && !isNaN(+questionAnswer.answer_value)
      ? `${("Score")}: ${questionAnswer.answer_value}`
      : "-";
  };

  const renderAnswerBody = (answerBody) => {
    let ans = JSON.parse(answerBody);
    if (isArray(ans)) {
      return ans.map((a) => {
        const obj = JSON.parse(a);
        return <tr>{obj.label}</tr>;
      });
    }
    try {
      ans = JSON.parse(ans);
      return isObject(ans) ? ans.label : ans;
    } catch {
      return ans;
    }
  };

  const renderGroupQuestions = (questions) => {
    if (questions) {
      return questions.map((question) => {
        const questionAnswer = question.surveys_answers
          ? question.surveys_answers[0]
          : null;
        return (
          <>
            <tr>
              <td>
                <strong>{question.question}</strong>
              </td>
              <td>
                {questionAnswer
                  ? renderAnswerBody(questionAnswer.answer_body)
                  : "NA"}
              </td>
              <td>
                {questionAnswer
                  ? renderAnswerScore(question, questionAnswer)
                  : "NA"}
              </td>
            </tr>
            {question.sub_questions
              ? renderGroupQuestions(question.sub_questions)
              : ""}
          </>
        );
      });
    }
    return <></>;
  };

  const renderSurveyGroups = () => {
    const { admissionSurveyInstance } = medicalHistory;
    if (
      admissionSurveyInstance &&
      admissionSurveyInstance.survey &&
      admissionSurveyInstance.survey.groups
    ) {
      return admissionSurveyInstance.survey.groups.map((group) => (
        <>
          <tr>
            <td />
            <td>
              <h3>{group.group_title}</h3>
            </td>
            <td />
          </tr>
          {renderGroupQuestions(group.questions)}
        </>
      ));
    }
    return "";
  };

  const renderTable = () => (
    <>
      {/* desktop */}
      <table className="table table-striped" style={{ textAlign: "left" }}>
        <tbody>
          <tr>
            <td>
              <strong>{i18next.t("History of present illness")}</strong>
            </td>
            <td>{medicalHistory.present_illness_history}</td>
          </tr>
          <tr>
            <td>
              <strong>{i18next.t("Chief Complaint")}</strong>
            </td>
            <td>{medicalHistory.chief_complaint}</td>
          </tr>
          <tr>
            <td>
              <strong>{i18next.t("Examination")}</strong>
            </td>
            <td>{medicalHistory.examination}</td>
          </tr>
          {renderSurveyGroups()}
        </tbody>
      </table>
      {/* mobile */}
      <div className="row hide-for-desktop ml-0">
        <div className="col-auto">
          <strong className="medical-history-title">
            {i18next.t("History of present illness")}
          </strong>
          <p>{medicalHistory.present_illness_history}</p>
          <strong className="medical-history-title">{i18next.t("Chief Complaint")}</strong>
          <p>{medicalHistory.chief_complaint}</p>
          <strong className="medical-history-title">{i18next.t("Examination")}</strong>
          <p>{medicalHistory.examination}</p>
          {renderSurveyGroups()}
        </div>
      </div>
    </>
  );

  const renderData = () => {
    if (medicalHistory.editable) {
      return renderForm();
    }
    return renderTable();
  };
  return (
    <CCard className="text-left flex-row border-0">
      <CCard className="col-12 border-0" style={{ padding: "0px", margin: "auto" }}>
        <CCardHeader
          row
          className="border-0 text-theme-blue"
          style={{ padding: "0px" }}
        >
          <CCol md="12">
            <h3 className="table-title" md="12">
            {i18next.t("medical history")}
            </h3>
          </CCol>
          {medicalHistory.editable && (
            <CCol className="text-align-center" md="auto">
              <CButton
              className="d-color"
                type="reset"
                style={{
                  "background-color": "rgb(48, 197, 182)",
                  "border-color": "rgb(48, 197, 182)",
                  width: "11em",
                }}
                color="info"
                onClick={toggleEdit}
              >
                <CIcon name="cil-pencil" /> {i18next.t("Edit")}
              </CButton>
            </CCol>
          )}
        </CCardHeader>

        <CCardBody
          className="legal-guardian-info"
          style={{ minHeight: "auto" }}
        >
          {renderData()}{" "}
        </CCardBody>
        <CCol md="12">
          <LegalGuardianInfo />
        </CCol>
      </CCard>
    </CCard>
  );
};

export default MedicalHistory;
