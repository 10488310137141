import Home from "./views/home/Home";
import Dashboard from "./views/dashboard/Dashboard";
import Users from "./views/users/Users";
import Patients from "./views/users/Patients";
import CreateUser from "./views/users/CreateUser";
import EditUser from "./views/users/EditUser";
import User from "./views/users/User";
import AssignUser from "./views/users/AssignUser";
import Documents from "./views/users/Documents";
import CreateDocument from "./views/users/CreateDocument";
import EditProfile from "./views/users/EditProfile";
import Roles from "./views/roles/screens/Roles";
import CreateRole from "./views/roles/screens/CreateRole";
import EditRole from "./views/roles/screens/EditRole";
import Role from "./views/roles/screens/Role";
import ListClinics from "./views/clinics/screens/ListClinics";
import CreateClinic from "./views/clinics/screens/CreateClinic";
import EditClinic from "./views/clinics/screens/EditClinic";
import MyClinics from "./views/clinics/screens/MyClinics";
import ClinicAppointments from "./views/clinics/screens/ClinicAppointments";
import ListAppointments from "./views/pages/appointments/screens/ListAppointments";
import ListCancelledAppointments from "./views/pages/appointments/screens/ListCancelledAppointments";
import CreateAppointment from "./views/pages/appointments/screens/CreateAppointment";
import EditAppointment from "./views/pages/appointments/screens/EditAppointment";
// Lobby
import Lobby from "./views/lobby/LobbyFactory";
// Vacations
import ListVacations from "./views/clinics/screens/ListVacations";
import CreateVacation from "./views/clinics/screens/CreateVacation";
import EditVacation from "./views/clinics/screens/EditVacation";
// Hospitals refactoring
import CreateHospital from "./views/hospitals/screens/CreateHospital";
import EditHospital from "./views/hospitals/screens/EditHospital";
import Hospital from "./views/hospitals/screens/Hospital";
import Hospitals from "./views/hospitals/screens/Hospitals";
// Levels
import CreateLevel from "./views/levels/screens/CreateLevel";
import Level from "./views/levels/screens/Level";
import EditLevel from "./views/levels/screens/EditLevel";
import Levels from "./views/levels/screens/Levels";
// Specialties
import CreateSpecialty from "./views/specialties/screens/CreateSpecialty";
import Specialty from "./views/specialties/screens/Specialty";
import EditSpecialty from "./views/specialties/screens/EditSpecialty";
import Specialties from "./views/specialties/screens/Specialties";
// Labs
import CreateLab from "./views/labs/screens/CreateLab";
import Lab from "./views/labs/screens/Lab";
import EditLab from "./views/labs/screens/EditLab";
import Labs from "./views/labs/screens/Labs";
// Radiology Centers
import CreateRadiologyCenter from "./views/radiology-centers/screens/CreateRadiologyCenter";
import RadiologyCenter from "./views/radiology-centers/screens/RadiologyCenter";
import EditRadiologyCenter from "./views/radiology-centers/screens/EditRadiologyCenter";
import RadiologyCenters from "./views/radiology-centers/screens/RadiologyCenters";
// Cities
import CreateCity from "./views/cities/screens/CreateCity";
import EditCity from "./views/cities/screens/EditCity";
import City from "./views/cities/screens/City";
import Cities from "./views/cities/screens/Cities";
// Specialties Form
import CreateSpecialtiesForm from "./views/specialties-forms/screens/CreateSpecialtiesForm";
import EditSpecialtiesForm from "./views/specialties-forms/screens/EditSpecialtiesForm";
import SpecialtiesForms from "./views/specialties-forms/screens/SpecialtiesForms";
import SurveyInstances from "./views/pages/patient/screens/survey/SurveyInstances"
import CreateSurveyInstances from "./views/pages/patient/screens/survey/Create"

import AppointmentDelete from "./views/pages/appointments/screens/AppointmentDelete";
import Appointment from "./views/pages/patient/screens/appointments/Appointment";
import SearchForClinic from "./views/pages/patient/screens/appointments/SearchForClinic";
import SelectAppointment from "./views/pages/patient/screens/appointments/SelectAppointment";
import SelectSlot from "./views/pages/patient/screens/appointments/SelectSlot";
import PaymentMethod from "./views/pages/payment/screens/PaymentMethod";
import Card from "./views/pages/payment/screens/Card";
import Fawry from "./views/pages/payment/screens/Fawry";
import FawryDetails from "./views/pages/payment/screens/FawryDetails";
import Complaint from "./views/pages/complaint/Complaint";
import EditComplaint from "./views/pages/complaint/EditComplaint";
import AppointmentDetails from "./views/pages/patient/screens/appointments/AppointmentDetails";
import ClinicLobby from "./views/pages/patient/screens/clinic-process/ClinicLobby";
import ClinicLobbyCheck from "./views/pages/patient/screens/clinic-process/ClinicLobbyCheck";
import LobbyMessage from "./views/pages/patient/screens/clinic-process/LobbyMessage";
import Video from "./views/pages/patient/screens/clinic-process/Video";
import TimeLine from "./views/pages/patient/screens/timeline/TimeLine";
import TimeLineAppointment from "./views/pages/patient/screens/timeline/TimeLineAppointment";
import LabAndRad from "./views/pages/patient/screens/timeline/LabAndRad";
import Prescription from "./views/pages/patient/screens/timeline/Prescription";
import Diagnosis from "./views/pages/patient/screens/timeline/Diagnosis";
import SelectSpoke from "./views/episode-management/screens/spoke/cases/SelectSpoke";
import Cases from "./views/episode-management/screens/spoke/cases/Cases";
import CreateEpisode from "./views/episode-management/screens/spoke/cases/Create";
import Case from "./views/episode-management/screens/spoke/cases/Case";
import DischargeForm from "./views/episode-management/screens/spoke/cases/discharge-form/DischargeForm";
import StartCall from "./views/physician/screens/clinics/StartCall";
import VideoCall from "./views/physician/screens/clinics/VideoCall";
// Rounds
import ListRounds from "./views/rounds/ListRounds";
import CreateRound from "./views/rounds/CreateRound";
import EditRound from "./views/rounds/EditRound";
import ShowRound from "./views/rounds/ShowRound";
import MyRounds from "./views/rounds/process/MyRounds";
import RoundDetails from "./views/rounds/process/RoundDetails";
import CalendarView from "./views/calendar/Calendar";
import RoundConference from "./views/rounds/process/RoundConference";
import permissions from "./constants/permissions";
// surveys
import CreateQuestion from "./views/surveys/questions/CreateQuestion";
import Questions from "./views/surveys/questions/Questions";
import EditQuestion from "./views/surveys/questions/EditQuestion";
import MyRequests from "./views/requests/MyRequests";
// import SQLBuilder from "./views/reports/screens/SQLBuilder";
import OldReport from "./views/oldReports/screens/report";
import OldReports from "./views/oldReports/screens/reports";
import DetailedReport from "./views/oldReports/screens/DetailedReport";
import Reports from "./views/reports/screens/reports";
import Report from "./views/reports/screens/report";
import PublicSurveys from "views/specialties-forms/screens/PublicSurveys";
import SendSurveys from "views/specialties-forms/screens/SendSurveys";
import SharedForms from "views/specialties-forms/screens/SharedForms";
import AppointmentQuickPayment from "views/pages/patient/screens/appointments/AppointmentQuickPayment";
// notifications
import MyNotifications from "./views/notifications/MyNotifications";
import PublicRounds from "views/rounds/public/PublicRounds";
import ShowQuestion from "views/surveys/questions/ShowQuestion";

import MatchedSentSurveys from "views/pages/patient/screens/matched-sent-surveys/MatchedSentSurveys";
import AnswerMatchedSurvey from "views/pages/patient/screens/matched-sent-surveys/AnswerMatchedSurvey";
import EditMatchedSurveyInstance from "views/pages/patient/screens/matched-sent-surveys/EditMatchedSurveyInstance";
import SurveyStatistics from "views/specialties-forms/screens/SurveyStatistics";
import Paymob from "views/pages/payment/screens/Paymob";
import PaymobPaymentDetails from "views/pages/payment/screens/PaymonPaymentDetails";
import { exact } from "prop-types";

import NoPermission from "views/pages/noPermission/NoPermission";
import { path } from "d3";
import Page404 from "views/pages/page404/Page404";

const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    permission: permissions.homePermission,
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    permission: permissions.dashboardPermission,
    component: Dashboard,
  },
  {
    path: "/users",
    exact: true,
    name: "Users",
    permission: permissions.listUsers,
    component: Users,
  },
  // {
  //   path: "/users/patient",
  //   exact: true,
  //   name: "Patients",
  //   permission: permissions.listPatients,
  //   component: Patients,
  // },
  {
    path: "/users/create",
    exact: true,
    name: "Create User",
    permission: permissions.createUser,
    component: CreateUser,
  },
  {
    path: "/users/:id/edit",
    exact: true,
    name: "Edit User",
    permission: permissions.updateUser,
    component: EditUser,
  },
  {
    path: "/users/:id/view",
    exact: true,
    name: "User Details",
    permission: permissions.showUser,
    component: User,
  },
  {
    path: "/users/:id/assign",
    exact: true,
    name: "Assign User",
    permission: permissions.assignUserToHospitals,
    component: AssignUser,
  },
  {
    path: "/users/:userId/documents",
    exact: true,
    name: "Documents",
    permission: permissions.showDocument,
    component: Documents,
  },
  {
    path: "/users/:userId/documents/create",
    exact: true,
    name: "Create Document",
    permission: permissions.createDocument,
    component: CreateDocument,
  },
  {
    path: "/profile/edit",
    exact: true,
    name: "Edit Profile",
    permission: permissions.updateProfile,
    component: EditProfile,
  },
  {
    path: "/roles",
    exact: true,
    name: "Roles",
    permission: permissions.listRoles,
    component: Roles,
  },
  {
    path: "/roles/create",
    exact: true,
    name: "Create Role",
    permission: permissions.createRole,
    component: CreateRole,
  },
  {
    path: "/roles/:id/edit",
    exact: true,
    name: "Edit Role",
    permission: permissions.updateRole,
    component: EditRole,
  },
  {
    path: "/roles/:id/show",
    exact: true,
    name: "Role Details",
    permission: permissions.showRole,
    component: Role,
  },
  // Levels
  {
    path: "/levels/create",
    exact: true,
    name: "Create Level",
    permission: permissions.createLevel,
    component: CreateLevel,
  },
  {
    path: "/levels/:id/show",
    exact: true,
    name: "Level Detaile",
    permission: permissions.showLevel,
    component: Level,
  },
  {
    path: "/levels/:id/edit",
    exact: true,
    name: "Edit Level",
    permission: permissions.updateLevel,
    component: EditLevel,
  },
  {
    path: "/levels",
    exact: true,
    name: "Experience",
    permission: permissions.listLevels,
    component: Levels,
  },
  // Specialties
  {
    path: "/specialties/create",
    exact: true,
    name: "Create Specialty",
    permission: permissions.createSpecialty,
    component: CreateSpecialty,
  },
  {
    path: "/specialties/:id/show",
    exact: true,
    name: "Specialty Detaile",
    permission: permissions.showSpecialty,
    component: Specialty,
  },
  {
    path: "/specialties/:id/edit",
    exact: true,
    name: "Edit Specialty",
    permission: permissions.updateSpecialty,
    component: EditSpecialty,
  },
  {
    path: "/specialties",
    exact: true,
    name: "Specialties",
    permission: permissions.listSpecialties,
    component: Specialties,
  },
  // Labs
  {
    path: "/service/providers/labs/create",
    exact: true,
    name: "Create Lab",
    permission: permissions.createLab,
    component: CreateLab,
  },
  {
    path: "/service/providers/labs/:id/show",
    exact: true,
    name: "Lab Detaile",
    permission: permissions.showLab,
    component: Lab,
  },
  {
    path: "/service/providers/labs/:id/edit",
    exact: true,
    name: "Edit Lab",
    permission: permissions.updateLab,
    component: EditLab,
  },
  {
    path: "/service/providers/labs",
    exact: true,
    name: "Labs",
    permission: permissions.listLabs,
    component: Labs,
  },

  // Radiology Centers
  {
    path: "/service/providers/radiology-centers/create",
    exact: true,
    name: "Create Radiology Center",
    permission: permissions.createRadiology,
    component: CreateRadiologyCenter,
  },
  {
    path: "/service/providers/radiology-centers/:id/show",
    exact: true,
    name: "Radiology Center Detaile",
    permission: permissions.showRadiology,
    component: RadiologyCenter,
  },
  {
    path: "/service/providers/radiology-centers/:id/edit",
    exact: true,
    name: "Edit Radiology Center",
    permission: permissions.updateRadiology,
    component: EditRadiologyCenter,
  },
  {
    path: "/service/providers/radiology-centers",
    exact: true,
    name: "Radiology Centers",
    permission: permissions.listRadiology,
    component: RadiologyCenters,
  },

  // cities
  {
    path: "/cities/create",
    exact: true,
    name: "Create City",
    permission: permissions.createCity,
    component: CreateCity,
  },
  {
    path: "/cities/:id/show",
    exact: true,
    name: "City Detaile",
    permission: permissions.showCity,
    component: City,
  },
  {
    path: "/cities/:id/edit",
    exact: true,
    name: "Edit City",
    permission: permissions.updateCity,
    component: EditCity,
  },
  {
    path: "/cities",
    exact: true,
    name: "Cities",
    permission: permissions.listCities,
    component: Cities,
  },

  // Specialties Forms
  {
    path: "/specialties-forms/create",
    exact: true,
    name: "Create Specialty Form",
    permission: permissions.createSpecialtiesForm,
    component: CreateSpecialtiesForm,
  },
  {
    path: "/specialties-forms/:id/edit",
    exact: true,
    name: "Edit Specialty Form",
    permission: permissions.updateSpecialtiesForm,
    component: EditSpecialtiesForm,
  },
  {
    path: "/specialties-forms",
    exact: true,
    name: "Specialty Forms",
    permission: permissions.listSpecialtiesForms,
    component: SpecialtiesForms,
  },

  {
    path: "/clinics",
    exact: true,
    name: "Clinics",
    permission: permissions.listClinics,
    component: ListClinics,
  },
  {
    path: "/my-clinics",
    exact: true,
    name: "My Clinics",
    permission: permissions.listMyClinics,
    component: MyClinics,
  },
  {
    path: "/clinics/my-clinics/:id/appointments/:date",
    exact: true,
    name: "ClinicAppointments",
    permission: permissions.listClinicAppointments,
    component: ClinicAppointments,
  },
  {
    path: "/clinics/create",
    exact: true,
    name: "New Clinic",
    permission: permissions.createClinic,
    component: CreateClinic,
  },
  {
    path: "/clinics/edit/:id",
    exact: true,
    name: "Edit Clinic",
    permission: permissions.updateClinic,
    component: EditClinic,
  },
  {
    path: "/vacations",
    exact: true,
    name: "List Vacation",
    permission: permissions.listVacations,
    component: ListVacations,
  },
  {
    path: "/vacations/create",
    exact: true,
    name: "Create Vacation",
    permission: permissions.createVacation,
    component: CreateVacation,
    EditVacation,
  },
  {
    path: "/vacations/edit/:id",
    exact: true,
    name: "Edit Vacation",
    permission: permissions.updateVacation,
    component: EditVacation,
  },
  // Lobby
  {
    path: "/lobby/:token",
    exact: true,
    name: "Lobby",
    permission: permissions.viewLobby,
    component: Lobby,
  },
  {
    path: "/service/providers/hospitals",
    exact: true,
    name: "List Locations",
    permission: permissions.listHospitals,
    component: Hospitals,
  },
  {
    path: "/service/providers/hospitals/create",
    exact: true,
    name: "New Location",
    permission: permissions.createHospital,
    component: CreateHospital,
  },
  {
    path: "/service/providers/hospitals/:id/view",
    exact: true,
    name: "Location Details",
    permission: permissions.showHospital,
    component: Hospital,
  },
  {
    path: "/service/providers/hospitals/:id/edit",
    exact: true,
    name: "Edit Location",
    permission: permissions.updateHospital,
    component: EditHospital,
  },
  {
    path: "/appointments",
    exact: true,
    name: "List Appointment",
    permission: permissions.listAppointments,
    component: ListAppointments,
  },
  {
    path: "/appointments/create",
    exact: true,
    name: "New Appointment",
    permission: permissions.createAppointment,
    component: CreateAppointment,
  },
  {
    path: "/appointments/:id/delete",
    exact: true,
    name: "Delete Appointment",
    permission: permissions.deleteAppointment,
    component: AppointmentDelete,
  },
  {
    path: "/appointments/:id/edit",
    exact: true,
    name: "Edit Appointment",
    permission: permissions.updateAppointment,
    component: EditAppointment,
  },
  {
    path: "/patient/appointments",
    exact: true,
    name: "Appointment",
    permission: permissions.scheduleAppointment,
    component: Appointment,
  },
  {
    path: "/patient/survey-instances",
    exact: true,
    name: "Survey Instances",
    permission: permissions.patientListSurveyInstances,
    component: SurveyInstances,
  },
  {
    path: "/patient/survey-instance/create",
    exact: true,
    name: "Create Survey Instances",
    permission: permissions.patientCreateSurveyInstance,
    component: CreateSurveyInstances,
  },

  {
    path: "/patient/quick-payment/:appointmentCode",
    exact: true,
    name: "Appointment Quick Payment",
    permission: permissions.scheduleAppointment,
    component: AppointmentQuickPayment,
  },
  {
    path: "/cancelled-appointments",
    exact: true,
    name: "List Cancelled Appointments",
    permission: permissions.listAppointments,
    component: ListCancelledAppointments,
  },
  {
    path: "/patient/clinic/search",
    exact: true,
    name: "SearchForClinic",
    permission: permissions.scheduleAppointment,
    component: SearchForClinic,
  },
  {
    path: "/patient/appointment/select",
    exact: true,
    name: "SelectAppointment",
    permission: permissions.scheduleAppointment,
    component: SelectAppointment,
  },
  {
    path: "/patient/slot/select",
    exact: true,
    name: "SelectSlot",
    permission: permissions.scheduleAppointment,
    component: SelectSlot,
  },
  {
    path: "/payment/methods",
    exact: true,
    name: "PaymentMethod",
    permission: permissions.scheduleAppointment,
    component: PaymentMethod,
  },
  {
    path: "/payment/card",
    exact: true,
    name: "Card",
    permission: permissions.scheduleAppointment,
    component: Fawry,
  },
  {
    path: "/payment/fawry",
    exact: true,
    name: "Fawry",
    permission: permissions.scheduleAppointment,
    component: Fawry,
  },
  {
    path: "/payment/fawry/details",
    exact: true,
    name: "Fawry Details",
    permission: permissions.scheduleAppointment,
    component: FawryDetails,
  },
  {
    path: "/payment/paymob",
    exact: true,
    name: "Paymob checkout",
    permission: permissions.scheduleAppointment,
    component: Paymob,
  },
  {
    path: "/payment/paymob/details",
    exact: true,
    name: "Paymob details",
    permission: permissions.scheduleAppointment,
    component: PaymobPaymentDetails,
  },
  {
    path: "/complaint/create",
    exact: true,
    name: "Complaint",
    permission: permissions.scheduleAppointment,
    component: Complaint,
  },
  {
    path: "/complaint/:appointmentId/edit",
    exact: true,
    name: "Edit Complaint",
    permission: permissions.scheduleAppointment,
    component: EditComplaint,
  },
  {
    path: "/patient/appointments/:id/show",
    exact: true,
    name: "AppointmentDetails",
    permission: permissions.patientShowAppointment,
    component: AppointmentDetails,
  },
  {
    path: "/patient/clinic/lobby/:lobbySession",
    exact: true,
    name: "ClinicLobby",
    permission: permissions.patientEnterLobby,
    component: ClinicLobby,
  },
  {
    path: "/patient/clinic/lobby/check/:appointmentId",
    exact: true,
    name: "ClinicLobbyCheck",
    permission: permissions.patientEnterLobby,
    component: ClinicLobbyCheck,
  },
  {
    path: "/patient/lobby/message",
    exact: true,
    name: "LobbyMessage",
    permission: permissions.patientEnterLobby,
    component: LobbyMessage,
  },
  {
    path: "/patient/video/:lobbySession",
    exact: true,
    name: "Video",
    permission: permissions.patientEnterLobby,
    component: Video,
  },
  {
    path: "/patient/timeline",
    exact: true,
    name: "TimeLine",
    permission: permissions.patientTimeLine,
    component: TimeLine,
  },
  {
    path: "/patient/timeline/appointment/:id/show",
    exact: true,
    name: "TimeLineAppointment",
    permission: permissions.patientTimeLine,
    component: TimeLineAppointment,
  },
  {
    path: "/patient/timeline/appointment/:id/lab-rad",
    exact: true,
    name: "LabAndRad",
    permission: permissions.patientTimeLine,
    component: LabAndRad,
  },
  {
    path: "/patient/timeline/appointment/:id/prescription",
    exact: true,
    name: "Prescription",
    permission: permissions.patientTimeLine,
    component: Prescription,
  },
  {
    path: "/patient/timeline/appointment/:id/diagnosis",
    exact: true,
    name: "Diagnosis",
    permission: permissions.patientTimeLine,
    component: Diagnosis,
  },
  {
    path: "/episodes/select-spoke",
    exact: true,
    name: "Select Location",
    permission: permissions.listEpisodes,
    component: SelectSpoke,
  },
  {
    path: "/public/rounds",
    exact: true,
    name: "Public Rounds",
    component: PublicRounds,
  },
  {
    path: "/episodes/cases/:hospitalId",
    exact: true,
    name: "Cases",
    permission: permissions.listEpisodes,
    component: Cases,
  },
  {
    path: "/physician/clinic/start/call/:id",
    exact: true,
    name: "StartCall",
    permission: permissions.startClinic,
    component: StartCall,
  },
  {
    path: "/physician/call/:token",
    exact: true,
    name: "Video Call",
    permission: permissions.startClinic,
    component: VideoCall,
  },
  {
    path: "/episodes/spock/cases/:hospitalId/create",
    exact: true,
    name: "Create Episode",
    permission: permissions.createEpisode,
    component: CreateEpisode,
  },
  {
    path: "/episodes/spok/case/:episodeId",
    exact: true,
    name: "Case",
    permission: permissions.showEpisode,
    component: Case,
  },
  {
    path: "/episodes/spok/case/:episodeId/discharge-form",
    exact: true,
    name: "DischargeForm",
    permission: permissions.closeEpisode,
    component: DischargeForm,
  },
  {
    path: "/rounds",
    exact: true,
    name: "Rounds",
    permission: permissions.listRounds,
    component: ListRounds,
  },
  {
    path: "/rounds/create",
    exact: true,
    name: "Create",
    permission: permissions.createRound,
    component: CreateRound,
  },
  {
    path: "/rounds/edit/:id",
    exact: true,
    name: "Edit",
    permission: permissions.updateRound,
    component: EditRound,
  },
  {
    path: "/rounds/show/:id",
    exact: true,
    name: "Show",
    permission: permissions.showRound,
    component: ShowRound,
  },
  {
    path: "/rounds/my-rounds",
    exact: true,
    name: "My Rounds",
    permission: permissions.showRound,
    component: MyRounds,
  },
  {
    path: "/rounds/attendee/rounds",
    exact: true,
    name: "My Rounds",
    permission: permissions.showRound,
    component: MyRounds,
		props: {attendee: true}
  },
  {
    path: "/rounds/my-rounds/:id/details",
    exact: true,
    name: "Round Details",
    permission: permissions.showRound,
    component: RoundDetails,
  },
  {
    path: "/calendar",
    exact: true,
    name: "Calendar",
    permission: permissions.showCalendar,
    component: CalendarView,
  },
  {
    path: "/requests",
    exact: true,
    name: "Requests",
    permission: permissions.listRequests,
    component: MyRequests,
  },
  // {
  //   path: "/SQLBuilder",
  //   exact: true,
  //   name: "SQLBuilder",
  //   permission: permissions.listReports,
  //   component: SQLBuilder,
  // },
  {
    path: "/reports",
    exact: true,
    name: "Reports",
    permission: permissions.listReports,
    component: Reports,
  },
  {
    path: "/old-reports",
    exact: true,
    name: "OldReports",
    permission: permissions.listReports,
    component: OldReports,
  },
  {
    path: "/old-reports/:type",
    exact: true,
    name: "Report",
    permission: permissions.showReport,
    component: OldReport,
  },

  {
    path: "/old-reports/:type/details/:modelId",
    exact: true,
    name: "Report Details",
    permission: permissions.showReport,
    component: DetailedReport,
  },
  {
    path: "/reports/:id/result",
    exact: true,
    name: "Report Result",
    permission: permissions.showResult,
    component: Report,
  },
  {
    path: "/round/:sessionToken/conference",
    exact: true,
    name: "Round Conference",
    permission: permissions.showRound,
    component: RoundConference,
  },
  {
    path: "/questions/create",
    exact: true,
    name: "Create Question",
    permission: permissions.createQuestion,
    component: CreateQuestion,
  },
  {
    path: "/questions",
    exact: true,
    name: "Questions",
    permission: permissions.listQuestions,
    component: Questions,
  },
  {
    path: "/questions/:id/edit",
    exact: true,
    name: "Edit Question",
    permission: permissions.updateQuestion,
    component: EditQuestion,
  },
  {
    path: "/questions/:id/show",
    exact: true,
    name: "Show Question",
    permission: permissions.showQuestion,
    component: ShowQuestion,
  },
  {
    path: "/shared-forms",
    exact: true,
    name: "List Shared Public Survey Forms",
    permission: permissions.managePublicSurveys,
    component: SharedForms,
  },
  {
    path: "/public-surveys",
    exact: true,
    name: "List Public Survey",
    permission: permissions.managePublicSurveys,
    component: PublicSurveys,
  },
  {
    path: "/send-surveys",
    exact: true,
    name: "List Sent Surveys",
    permission: permissions.managePublicSurveys,
    component: SendSurveys,
  },
  {
    path: "/survey-statistics",
    exact: true,
    name: "List Sent Surveys",
    permission: permissions.managePublicSurveys,
    component: SurveyStatistics,
  },
  {
    path: "/my-notifications",
    exact: true,
    name: "Notifications",
    permission: permissions.listMyNotifications,
    component: MyNotifications,
  },
  {
    path: "/matched-sent-surveys",
    exact: true,
    name: "Matched Sent Surveys",
    permission: permissions.patientListSurveyInstances,
    component: MatchedSentSurveys,
  },
  {
    path: "/Answer-Survey/:id/:surveyId",
    exact: true,
    name: "Answer Sent Surveys",
    permission: permissions.patientCreateSurveyInstance,
    component: AnswerMatchedSurvey,
  },
  {
    path: "/Edit-Survey/:id",
    exact: true,
    name: "Edit Sent Surveys",
    permission: permissions.patientCreateSurveyInstance,
    component: EditMatchedSurveyInstance,
  },
  {
    path: "/no-permission",
    name: "No Permission",
    component: NoPermission,
  },
];

export default routes;
