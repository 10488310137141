import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CCard,
  CCardBody,
  CDataTable,
  CPagination,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { fetchPastClinics } from "actions/past-medical-history/pastMedicalHistory";
import ClinicSummary from "./ClinicSummary";
import i18next from "i18next";

const PastClinics = ({patientId}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [appointment, setAppointment] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const clinics = useSelector((state) =>
    state.pastMedicalHistory.clinics
      ? state.pastMedicalHistory.clinics.data
      : []
  );
  const meta = useSelector((state) =>
    state.pastMedicalHistory.clinics
      ? state.pastMedicalHistory.clinics.meta
      : null
  );

  const pageChange = (newPage) => {
    dispatch(fetchPastClinics(patientId, newPage));
    setPage(newPage);
  };

  const showSummary = (item: Object) => {
    setAppointment(item);
    setShowModal(true);
  };

  const renderActions = (item: Object) => (
    <td>
      <CDropdown variant="btn-group">
        <CDropdownToggle
          className="action-toggle m-0"
          color="light"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="c-icon"
            role="img"
          >
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
              className="ci-primary"
            />
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
              className="ci-primary"
            />
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
              className="ci-primary"
            />
          </svg>
        </CDropdownToggle>
        <CDropdownMenu className="p-0" placement="right-end">
          <CDropdownItem
            onClick={() => showSummary(item)}
            className="text-info"
          >
            <CIcon content={cilSearch} />
            <span className="mx-1">{i18next.t("show")}</span>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </td>
  );
  useEffect(() => {
    dispatch(fetchPastClinics(patientId));
  }, [dispatch, patientId]);

  return (
    // <CCard>
    //   {/* <CCardHeader>
    //     <CRow>
    //       <CCol md={6}>Past Episodes</CCol>
    //       <CCol md={6}>
    //         <CLink onClick={() => setActiveScreen(null)}>History</CLink>
    //       </CCol>
    //     </CRow>
    //   </CCardHeader> */}
    //   <CCardBody>
    //     <CRow >{render(episodes)}</CRow>
    //   </CCardBody>
    // </CCard>

    <CCol xs="12" style={{ marginTop: "3px" }}>
      <CCard>
        <CCardBody>
          <CDataTable
            items={clinics}
            noItemsViewSlot={i18next.t("No Available Items")}
            fields={[
              { key: "clinic", label: i18next.t("Clinic") },
              { key: "doctor", label: i18next.t("Doctor") },
              { key: "specialty", label: i18next.t("Specialty") },
              { key: "date", label: i18next.t("Appointment Date") },
              { key: "status", label: i18next.t("status") },
              { key: "actions", label: i18next.t("Actions") },
            ]}
            striped
            scopedSlots={{
              date: (item) => <td>{`${item.schedule_time}`}</td>,
              clinic: (item) => (
                <td className="episode-bold-text">{`${item.clinic}`}</td>
              ),
              status:(item)=>(
                <td>{`${item.status_text}`}</td>
              ),
              actions: renderActions,
            }}
          />
          <ClinicSummary
            appointment={appointment}
            setShowModal={setShowModal}
            showModal={showModal}
          />
          <CPagination
            activePage={page}
            onActivePageChange={pageChange}
            pages={meta ? meta.last_page : 1}
            doubleArrows={false}
            align="center"
          />
        </CCardBody>
      </CCard>
    </CCol>
  );
};
export default PastClinics;
