import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createCity, fetchCountries } from "actions/cities/cities";
import CityForm from "../form/CityForm";
import i18next from "i18next";

/**
 * @param props
 */
function CreateCity() {
  const history = useHistory();
  const label = `${i18next.t("Create New City")}`;
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.cities.redirect);
  const countries = useSelector((state) => state.countries);

  const onSubmit = (formData) => {
    dispatch(createCity(formData));
  };

  if (redirect) {
    history.push("/cities");
  }

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  return (
    <CityForm
      onSubmit={onSubmit}
      label={label}
      countries={countries.data ? countries.data : []}
    />
  );
}

export default CreateCity;
