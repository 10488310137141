import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createLevel } from "actions/levels/levels";
import LevelForm from "../form/LevelForm";
import i18next from "i18next";

/**
 * @param props
 */
function CreateLevel() {
  const history = useHistory();
  const label = `${i18next.t("Create New Level Of Experience")}`;
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.levels.redirect);

  const onSubmit = (formData) => {
    dispatch(createLevel(formData));
  };

  if (redirect) {
    history.push("/levels");
  }

  return <LevelForm onSubmit={onSubmit} label={label} />;
}

export default CreateLevel;
