import { Api, getTokenHeader } from "services/Api";
import { RESET_SPECIALTIES_FORM } from "actions/specialties-form/types";
import {
  FETCH_FOLLOW_UP_UTILITIES,
  CREATE_FOLLOW_UP,
  FETCH_FOLLOW_UP,
  UPDATE_FOLLOW_UP,
  FETCH_FOLLOW_UPS,
  DELETE_FOLLOW_UP,
} from "./types";

export const fetchFollowUpUtilities = () => async (dispatch) => {
  const response = await Api.get("/api/admin/follow-ups/utilities", {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_FOLLOW_UP_UTILITIES, payload: response.data });
};

export const createFollowUp = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(`/api/admin/survey-instances`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_FOLLOW_UP, payload: response.data });
  dispatch({ type: RESET_SPECIALTIES_FORM });
};

export const fetchFollowUp = (id: string) => async (dispatch) => {
  const response = await Api.get(`/api/admin/survey-instance/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_FOLLOW_UP, payload: response.data });
};

export const updateFollowUp = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(`/api/admin/survey-instance/${id}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_FOLLOW_UP, payload: response.data });
};

export const fetchFollowUps = (
  model_id: String,
  model_type: string,
  type: string,
  page: string,
  limit: string
): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/survey-instances`, {
    ...getTokenHeader(),
    params: { model_id, model_type, type, page, limit },
  });
  dispatch({ type: FETCH_FOLLOW_UPS, payload: response.data });
  dispatch({ type: RESET_SPECIALTIES_FORM });
};

export const deleteFollowUp = (id: string) => async (dispatch) => {
  await Api.delete(`/api/admin/follow-ups/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_FOLLOW_UP, payload: id });
};
