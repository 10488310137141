import React, {Fragment} from "react";
import { CCol, CFormGroup, CCardTitle } from "@coreui/react";
import SurveyQuestions from "./SurveyQuestions";

const SurveyForm = ({ groups, register, setValue, watch }) => {
  if (groups) {
    return groups.map((group) => (
      <Fragment key={`fg-${group.group_title}`} >
        <h3 className="header-label-title ">{group.group_title}</h3>
        <br></br>
        <CFormGroup key={`gp-${group.group_title}`} row className="my-2">
          <CCol md={12}>
            <SurveyQuestions
              questions={group.questions}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          </CCol>
        </CFormGroup>
      </Fragment>
    ));
  }
  return "";
};

export default SurveyForm;
