import { Api, getTokenHeader } from "services/Api";
import {
  CREATE_VACATION,
  FETCH_VACATION,
  UPDATE_VACATION,
  FETCH_VACATIONS,
  DELETE_VACATION,
} from "./types";

export const createVacation = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(`/api/admin/vacations`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_VACATION, payload: response.data });
};

export const fetchVacation = (id: number): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/vacation/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_VACATION, payload: response.data });
};

export const updateVacation = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(`/api/admin/vacations/${id}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_VACATION, payload: response.data });
};

export const fetchVacations = (page: string, limit: string): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/vacations?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_VACATIONS, payload: response.data });
};

export const deleteVacation = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/vacations/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_VACATION, payload: id });
};
