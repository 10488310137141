// @flow
import { Api, getTokenHeader } from "services/Api";
import {
  FETCH_PAST_EPISODES,
  FETCH_PAST_CLINICS,
  FETCH_PAST_LABS,
  FETCH_PAST_RADS,
} from "./types";

export const fetchPastEpisodes = (id: number, page: number): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/past-medical-history/${id}/episodes?page=${page}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_PAST_EPISODES, payload: response.data });
};

export const fetchPastClinics = (id: number, page: number): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/past-medical-history/${id}/clinics?page=${page}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_PAST_CLINICS, payload: response.data });
};

export const fetchPastLabs = (id: number, page: number): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/past-medical-history/${id}/labs?page=${page}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_PAST_LABS, payload: response.data });
};

export const fetchPastRads = (id: number, page: number): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/past-medical-history/${id}/rads?page=${page}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_PAST_RADS, payload: response.data });
};
