import React from "react";
import i18next from "i18next";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CListGroup,
  CListGroupItem,
} from "@coreui/react";

const renderInvestigationTypes = (investigation_types) => {
  if (investigation_types) {
    return (
      <>
        <CListGroup>
          {investigation_types.map((type) => (
            <CListGroupItem color="dark">{type.name}</CListGroupItem>
          ))}
        </CListGroup>
      </>
    );
  }
  return <></>;
};

const renderInvestigation = (investigation, statusList) => {
  if (investigation) {
    return (
      <>
        <table className="table table-striped table-hover">
          <tbody>
            <tr>
              <td>
                <strong className="labels">{i18next.t("Author")}</strong>
              </td>
              <td>{`${investigation.doctor.first_name} ${investigation.doctor.last_name}`}</td>
            </tr>
            <tr>
              <td>
                <strong className="labels">{i18next.t("Note")}</strong>
              </td>
              <td>
                {investigation.order_notes
                  ? investigation.order_notes
                  :i18next.t("No Additional Notes")}
              </td>
            </tr>
            <tr>
              <td>
                <strong className="labels">{i18next.t("status")}</strong>
              </td>
              <td className="labels">
                {statusList
                  ? statusList.find(
                      (status) => status.id === investigation.status
                    ).title
                  : ""}
              </td>
            </tr>
            <tr>
              <td>
                <strong className="labels">{i18next.t("Types")}</strong>
              </td>
              <td>
                {renderInvestigationTypes(investigation.investigation_types)}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
  return <></>;
};

const Show = ({ investigation, showModal, toggleShowModal, statusList }) =>
  showModal ? (
    <CModal show={showModal} size="lg">
      <h4 className="table-title">{i18next.t("Investigation Details")}</h4>
      <CModalBody>{renderInvestigation(investigation, statusList)}</CModalBody>
      <CModalFooter>
        <CButton type="reset" color="danger" onClick={toggleShowModal}>
          {i18next.t("Cancel")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    <></>
  );
export default Show;
