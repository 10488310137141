import React, { useState, useEffect } from "react";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CInputCheckbox,
} from "@coreui/react";
import { getColumnOptions } from "helpers/helpers";

const TablesSection = ({
  schema,
  selectedTable,
  setSelectedTable,
  selectedJoins,
  setSelectedJoins,
  register,
  forceSelectedjoins,
  setForceSelectedjoins,
  defaultColumns,
  setValue,
  defaultGrouping,
  defaultFunctions,
  defaultLabels,
  selectedGroup,
  setSelectedGroup,
  defaultJoins,
}) => {
  const [selectedColumnsFunction, setSelectedColumnsFunction] = useState([]);
  const [checkColumn, setCheckColumn] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState([]);
  const [selectedJoinsLabels, setSelectedJoinsLabels] = useState([]);

  useEffect(() => {
    const columnsWithValues = Object.keys(defaultColumns).filter(
      (key) => defaultColumns[key]
    );
    const functionsWithValues = Object.keys(defaultFunctions).filter(
      (key) => defaultFunctions[key]
    );

    setCheckColumn(columnsWithValues);
    setSelectedFunction(functionsWithValues);

    const groupColumns = defaultGrouping.columns_from;
    if (groupColumns && groupColumns.length > 0) {
      const { table } = groupColumns[0];

      setSelectedGroup(table);
      const columnss = getColumns(schema, table);
      setSelectedColumnsFunction(columnss);
    }
    setSelectedJoinsLabels(defaultJoins);
  }, [defaultColumns, schema,]);

  useEffect(() => {
    // Set default values for functions and labels
    Object.entries(defaultFunctions).map(([key, value]) => {
      setValue(`function['${key}']`, value);
    });
    Object.entries(defaultLabels).map(([key, value]) => {
      setValue(`label['${key}']`, value);
    });

    // ...
  }, [defaultFunctions, defaultLabels, setValue]);

  function getColumns(schema, selectedTable) {
    const columns = schema?.columns.filter((column) => {
      if (column.table === selectedTable) {
        return true;
      }
    });

    return columns.map((column) => column.name);
  }


  function handleSelectFunctionChange(event, columnName) {
    const value = `${columnName}`;
    setCheckColumn((prevColumns) => [...prevColumns, value]);
    setSelectedFunction((prevFunction) => {
      if (prevFunction.includes(columnName)) {
        return prevFunction.filter((column) => column !== columnName);
      }
      return [...prevFunction, columnName];
    });
  }
  // when select columns
  function handleSelectColumn(event, columnName) {
    setCheckColumn((prevColumns) => {
      if (prevColumns.includes(columnName)) {
        setValue(`label['${columnName}']`, '');
        setValue(`function['${columnName}']`, '');
        return prevColumns.filter((column) => column !== columnName);
      }
      return [...prevColumns, columnName];
    });
  }

  // 3th step when select array of joins group 
  const handleJoinSelection = (event, table) => {
    const { checked, value } = event.target;
    if (checked) {
      if (!selectedJoins.includes(table)) {
        setSelectedJoins((prevJoins) => [...prevJoins, table]);
      }
      
      setSelectedJoinsLabels((prevSelectedJoinsLabels) => [
        ...prevSelectedJoinsLabels,
        value,
      ]);
    } else {
      setSelectedJoinsLabels((prevSelectedJoinsLabels) =>
      prevSelectedJoinsLabels.filter((join) => join !== value)
    );
    
    const areOtherTableJoinsSelected = selectedJoinsLabels.some(
      (joinLabel, index) => joinLabel !== value && selectedJoins[index] === table
    );
  
    if (!areOtherTableJoinsSelected) {
      setSelectedJoins((prevJoins) => prevJoins.filter((join) => join !== table));
    }
    }
  
  };

  // second step when select grouping 
  function handleSelectGroupChange(event) {
    setSelectedFunction([]);
    setCheckColumn([]);
    setSelectedColumnsFunction([]);
    setSelectedJoinsLabels([])
    setSelectedJoins([])
    setForceSelectedjoins([])
    const selectedValue = event.target.value;
    const grouping = schema.tables
      .find((table) => table.name === selectedTable)
      .group_by.find((group) => group.label === selectedValue);
    const groupJoins = grouping.joins;
    let joinsLabel = [];
    joinsLabel = groupJoins.map((join) => join.label);
    const joinTablesName = [];

    schema.tables.map((table) => {
      table.joins.map((join) => {
        if (joinsLabel.includes(join.label)) {
          joinTablesName.push(join.table);
        }
      });
    });
    setForceSelectedjoins(joinsLabel);
    const groupTablesColumns = grouping.columns_from[0].table;
    setSelectedGroup(groupTablesColumns);
    const columnss = getColumns(schema, groupTablesColumns);
    (schema.columns).map((column) => {
      setValue(`function['${column.name}']`, '');

    });
    setSelectedColumnsFunction(columnss);

    setSelectedJoins((prevSelectedJoins) => [...prevSelectedJoins, ...joinTablesName]);
  }
  // render main data (table of grouping)
  const renderGrouping = (value) => {
    const grouping = schema?.tables.find((table) => table.name === value)
      .group_by;
    return (
      <CSelect
        name="grouping"
        innerRef={register({ required: true })}
        onChange={(e) => handleSelectGroupChange(e)}
      >
        <option value="" selected disabled>No Grouping</option>
        {grouping.map((group) => (
          <option key={group.label} value={group.label}>
            {group.label}
          </option>
        ))}
      </CSelect>
    );
  };

  // render More details (tables of joins)
  const renderJoins = (value) => {
    const { joins } = schema.tables.find((table) => table.name === value);
    return joins.map((join) => (
      <CCol key={join?.label} inline className="d-flex flex-row">
        <CInputCheckbox
          data-label={join.label}
          id={join.label}
          name="joins"
          value={join.label}
          onClick={(e) => {
            handleJoinSelection(e, join.table);
          }}
          innerRef={register}
          checked={
            forceSelectedjoins.includes(join.label) ||
            selectedJoinsLabels.includes(join.label)
          }
          disabled={forceSelectedjoins.includes(join.label)}
        />
        <CLabel
          variant="checkbox"
          className="form-check-label labels"
          htmlFor={join.label}
        >
          {join.label}
        </CLabel>
      </CCol>
    ));
  };

  // first step when select basic table
  const handleTableSelection = (event) => {
    setSelectedJoins([]);
    setSelectedJoinsLabels([])
    setSelectedColumnsFunction([]);
    setCheckColumn([]);
    setSelectedGroup("");
    setForceSelectedjoins([]);
    setSelectedTable(event.target.value);
  };

  return (
    <div>
      <CFormGroup row>
        <CCol xs="12" md="12">
          <CLabel htmlFor="table" className="labels required">
            Basic Report
          </CLabel>
          <CSelect
            name="table"
            onChange={handleTableSelection}
            innerRef={register({ required: true })}
          >
            <option value="" key="0">
              Select Table
            </option>
            {schema?.tables?.map((table) => (
              <option key={table.name} value={table.name}>
                {table.label}
              </option>
            ))}
          </CSelect>
        </CCol>
      </CFormGroup>
      {selectedTable && (
        <>

          <CFormGroup>
            <CCol md={12}>
              <CLabel className="labels required">Main Data</CLabel>
              {renderGrouping(selectedTable)}
            </CCol>
          </CFormGroup>
          <CFormGroup>
            <CCol md={12}>
              <CLabel className="labels">More Details</CLabel>
              {renderJoins(selectedTable)}
            </CCol>
          </CFormGroup>
        </>
      )}
      <CFormGroup>
        <CCol>
          {selectedTable && (
            <>
              <CRow>
                <CCol md={4}>
                  <CRow>
                    <CCol md={2} />
                    <CCol md={6}>
                      <CLabel
                        className="labels"
                        style={{ marginLeft: "-1.25rem" }}
                      >
                        Columns
                      </CLabel>
                    </CCol>
                    <CCol md={4} />
                  </CRow>
                </CCol>
                <CCol md={4}>
                  <CLabel htmlFor="label">Label</CLabel>
                </CCol>
                <CCol md={4}>
                  <CLabel>Functions</CLabel>
                </CCol>
              </CRow>

              <CFormGroup row>
                <CCol>
                  {getColumnOptions(
                    schema,
                    selectedTable,
                    selectedJoins,
                    selectedGroup
                  ).map((column, index) => (
                    <CRow key={column.name} className="mb-3">
                      <CCol md={4}>
                        <CRow>
                          <CCol md={2} />
                          <CCol md={6}>
                            <CInputCheckbox
                              data-label={column.name}
                              id={`column['${column.name}']`}
                              name={`column['${column.name}']`}
                              value={column.name}
                              onClick={(e) =>
                                handleSelectColumn(e, column.name)
                              }
                              innerRef={register}
                              checked={checkColumn.includes(`${column.name}`)}
                            />
                            <CLabel
                              variant="checkbox"
                              className="form-check-label labels"
                              htmlFor={`column['${column.name}']`}
                            >
                              {column.label}
                            </CLabel>
                          </CCol>
                          <CCol md={4} />
                        </CRow>
                      </CCol>
                      <CCol md={4}>
                        <CInput
                          type="text"
                          id={`label['${column.name}']`}
                          name={`label['${column.name}']`}
                          placeholder="Label"
                          innerRef={register}
                          onChange={(e) =>
                            handleSelectFunctionChange(e, column.name)
                          }
                        />
                      </CCol>
                      {!selectedColumnsFunction.includes(column.name) && (
                        <CCol md={4}>
                          <CSelect
                            id={`function['${column.name}']`}
                            name={`function['${column.name}']`}
                            onChange={(e) =>
                              handleSelectFunctionChange(e, column.name)
                            }
                            innerRef={register({
                              required: checkColumn.includes(column.name),
                            })}
                          >
                            <option value="">Select Function</option>
                            {column.aggregate.map((functionn) => (
                              <option
                                key={functionn.value}
                                value={functionn.value}
                              >
                                {functionn.label}
                              </option>
                            ))}
                          </CSelect>
                          {checkColumn.includes(column.name) &&
                            !selectedFunction.includes(column.name) && (
                              <div style={{ color: "red" }}>
                                Function is required
                              </div>
                            )}
                        </CCol>
                      )}
                    </CRow>
                  ))}
                </CCol>
              </CFormGroup>
            </>
          )}
        </CCol>
      </CFormGroup>
    </div>
  );
};

export default TablesSection;
