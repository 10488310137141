import React, { useEffect }  from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCard,
  CCardBody,
  CRow,
  CCol,
  CContainer,
} from "@coreui/react";

import TimeLineAppointmentCard from "./TimeLineAppointmentCard";
import DoctorOrders from "./../clinic-process/DoctorOrders";
import {fetchAppointment} from "actions/appointments/patientAppointment";

const TimeLineAppointment = ({ match }) => {
  const dispatch = useDispatch();
  const appointment = useSelector((state) => state.patientAppointments.appointment);
  useEffect(() => {
      dispatch(fetchAppointment(match.params.id));
  }, [dispatch, match.params.id]);
  return <CContainer>
    <CRow>
      <CCol xs="12">
        <CCard>
          <CCardBody>
            <CCard>
              <CCardBody>
              {appointment && <TimeLineAppointmentCard appointment={appointment} />}
                <br />
                <CRow>
                  <CCol xs="12">
                    {appointment && <DoctorOrders appointment={appointment}/>}
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
}

export default TimeLineAppointment;
