import React from "react";
import { CCol, CFormGroup, CLabel, CSelect, CInput } from "@coreui/react";

const SurveyDropDownQuestion = (props) => {
  const {
    question,
    register,
    setValue,
    questionName,
    questionValueName,
  } = props;
  const calculateAnswerValue = (event) => {
    const ansObj = JSON.parse(event.target.value);
    setValue(questionValueName, ansObj.value);
  };

  const renderAnswers = (answers) =>
    answers.map((answer) => (
      <option
        value={JSON.stringify({ label: answer.label, value: answer.value })}
      >
        {answer.label}
      </option>
    ));

  return (
    <CCol md={12}>
      <br />
      <CFormGroup row>
        <CLabel className="labels" htmlFor={`survey_question_${question.id}`}>
          <h6>{question.question}</h6>
        </CLabel>
        <CInput type="hidden" innerRef={register} name={questionValueName} />
        <CSelect
          custom
          id={`survey_question_${question.id}`}
          name={questionName}
          innerRef={register}
          onChange={calculateAnswerValue}
        >
          <option value="" selected disabled>
            Select 
          </option>
          {renderAnswers(question.answers)}
        </CSelect>
      </CFormGroup>
    </CCol>
  );
};

export default SurveyDropDownQuestion;
