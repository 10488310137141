import React from "react";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import { withRouter } from "react-router-dom";
const client = ZoomMtgEmbedded.createClient();
const crypto = require("crypto"); // crypto comes with Node.js

class ZoomComponent extends React.Component {
  constructor(props) {
    super(props);
    this.meetingNumber = props.meetingNumber;
    this.passWord = props.passWord;
    this.userName = props.userName;
    this.role = props.role;
    this.apiKey = props.zoomCredentials.apiKey;
    this.leaveUrl = props.zoomCredentials.leaveUrl;
    this.signature = props.zoomCredentials.signature;
    this.userEmail = "";
    this.registrantToken = "";
  }

  historyUnListen() {
    this.unlistenFunc();
    client.leaveMeeting();
  }

  componentDidMount() {
    this.unlistenFunc = this.props.history.listen(() => {
      this.historyUnListen();
    });
    const meetingSDKElement = document.getElementById("meetingSDKElement");
    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      customize: {
        meetingInfo: ["topic", "host"],
        toolbar: {
          buttons: [
            {
              text: "Custom Button",
              className: "CustomButton",
              onClick: () => {
                console.log("custom button");
              },
            },
          ],
        },
      },
    });
    console.log("componenet did mount function", client);
  }

  getSignature(e) {
    e.preventDefault();
    document.getElementById("zoom-join-controls").style.display = "none";
    if (this.signature) {
      this.startMeeting(this.signature);
      document.getElementById("meetingSDKElement").style.zIndex = 0;
      document.getElementById("meetingSDKElement").style.top = "85px";
      document.getElementById("meetingSDKElement").style.left = "50%";
      document.getElementById("meetingSDKElement").style.position = "absolute";
    } else {
      console.error("error empty signature");
    }
  }

  startMeeting(signature) {
    const joinObj = {
      signature,
      sdkKey: this.apiKey,
      meetingNumber: this.meetingNumber,
      password: this.passWord,
      userName: this.userName,
    };
    client.join(joinObj);
  }

  render() {
    return (
      <div>
        <main id="zoom-join-controls" className="round-vedio m-0 p-0">
          <button
            className="btn btn-lg w-100 d-color"
            style={{
              textAlign: "center",
              boxShadow: "2px 16px 6px #35488d;",
              width: "30%",
            }}
            type="button"
            id="startMeetingBtn"
            onClick={(event) => this.getSignature(event)}
          >
          Start Audiovisual

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="c-sidebar-nav-icon" role="img" style={{marginLeft: '1rem'}}><path fill="var(--ci-primary-color, currentColor)" d="M461,132,360,171.277V72H16V440H360V332.723L461,372h35V132Zm3,206.833-136-52.89V408H48V104H328V218.057l136-52.89Z" class="ci-primary"></path></svg>
        </button>
        </main>
      </div>
    );
  }
}

export default withRouter(ZoomComponent);
