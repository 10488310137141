import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CDataTable,
  CCardHeader,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";
import find from "lodash/find";
import {
  fetchPrescriptionUtilities,
  fetchPrescriptions,
} from "actions/prescriptions/prescriptions";
import { fetchAppointmentRecommendations } from "actions/appointments/patientAppointment";
import { fetchInvestigations } from "actions/episodes/investigations";
import { TYPE_APPOINTMENT } from "constants/constants";
import Investigation from "views/episode-management/screens/spoke/cases/tabs/investigation/Investigation";
import { cilPrint } from "@coreui/icons/js/index";
import PrescriptionPrint from "../../partials/PrescriptionPrint";
import InvestigationPrint from "../../partials/InvestigationPrint";

const SideBar = () => (
  <CNav variant="tabs" vertical className="nav-container">
    <CNavItem>
      <CNavLink className="navchange" data-tab="Investigation">
        {i18next.t("Investigation")}
      </CNavLink>
    </CNavItem>
    <CNavItem>
      <CNavLink className="navchange" data-tab="Prescription">
        {i18next.t("Prescriptions")} {i18next.t("and")}{" "}
        {i18next.t("Recommendations")}
      </CNavLink>
    </CNavItem>
  </CNav>
);

const DoctorOrders = ({ appointment }) => {
  const dispatch = useDispatch();
  const prescriptions = useSelector((state) => state.prescriptions.data);
  const utilities = useSelector((state) => state.prescriptionUtilities);
  const investigationData = useSelector((state) => state.investigation);
  const recommendations = useSelector(
    (state) => state.patientAppointments.recommendations
  );
  const [printPrescriptionModal, setPrintPrescriptionModal] = useState(false);
  const [printInvestigationModal, setPrintInvestigationModal] = useState(false);
  const getPrescriptionsList = () => {
    if (prescriptions) {
      return prescriptions.map((item) => {
        const timeUnit = find(utilities.time_units, {
          id: parseInt(item.frequency_time_unit, 10),
        });
        const Ifrequency = `Once every ${item.frequency} ${
          timeUnit?.title ? timeUnit.title : "NA"
        }`;
        const route = find(utilities.routes, {
          id: parseInt(item.route, 10),
        });
        const Iroute = route ? route.title : item.route;
        return { ...item, frequency: Ifrequency, route: Iroute };
      });
    }
    return [];
  };
  const fields = [
    { key: "drug", label: i18next.t("drug") },
    { key: "statusText", label: i18next.t("status") },
    { key: "dosage", label: i18next.t("Dosage") },
    { key: "frequency", label: i18next.t("frequency") },
  ];
  const preparedPrescriptionsList = getPrescriptionsList();

  const TabsContent = () => (
    <CTabContent>
      <CTabPane className="navchange" data-tab="Investigation">
        <CCard>
          <CCardHeader row>
            <CCol md="12">
              <h4 className="table-title">{i18next.t("Investigation")}</h4>
            </CCol>
            <CRow>
            <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
              <CButton
                onClick={() => setPrintInvestigationModal('lab')}
                className="avh-btn text-decoration-none text-white avh-font-bold btn-form"
              >
                <CIcon
                  content={cilPrint}
                  className="calls-section-icon"
                />
                {i18next.t("Print Labs")}
              </CButton>
            </CCol>
            <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
            <CButton
                onClick={() => setPrintInvestigationModal('rad')}
                className="avh-btn text-decoration-none text-white avh-font-bold btn-form"
              >
                <CIcon
                  content={cilPrint}
                  className="calls-section-icon"
                />
                {i18next.t("Print Radiology")}

              </CButton>
            </CCol>
            <InvestigationPrint
                investigationData={investigationData}
                appointment={appointment}
                modal={printInvestigationModal}
                setModel={setPrintInvestigationModal}
              />
            </CRow>
          </CCardHeader>
          <CCardBody>
            <Investigation
              modelId={appointment.id}
              patientId={appointment.patient_id}
              isPatientView
            />
          </CCardBody>
        </CCard>
      </CTabPane>
      <CTabPane className="navchange" data-tab="Prescription">
        <CCard>
          <CCardHeader row>
            <CCol md="12">
              <h4 className="table-title">
                {i18next.t("Prescriptions")} {i18next.t("and")}{" "}
                {i18next.t("Recommendations")}
              </h4>
            </CCol>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CButton
                onClick={() => setPrintPrescriptionModal(true)}
                className="avh-btn text-decoration-none text-white avh-font-bold btn-form"
              >
                <CIcon content={cilPrint} className="calls-section-icon" />
                {i18next.t("Print")}
              </CButton>
              <PrescriptionPrint
                prescriptionList={preparedPrescriptionsList}
                appointment={appointment}
                recommendations={recommendations}
                modal={printPrescriptionModal}
                setModel={setPrintPrescriptionModal}
                label={i18next.t("Print Prescription Preview")}
              />
            </CCol>
          </CCardHeader>
          <CCardBody>
            <CCard>
              <CCardHeader row>
                <CCol md="12">
                  <h4 className="table-title">
                    {i18next.t("Recommendations")}
                  </h4>
                </CCol>
              </CCardHeader>
              <CCardBody>
                <CCard>
                  <CCardBody>
                    {recommendations || (
                      <h4 className="text-align-center not-found">
                        <strong>
                          {i18next.t("No Available Recommendations For Now")}
                        </strong>
                      </h4>
                    )}
                  </CCardBody>
                </CCard>
              </CCardBody>
            </CCard>
            <CDataTable
              items={preparedPrescriptionsList}
              fields={fields}
              noItemsViewSlot={i18next.t("No Available Items")}
              striped
              scopedSlots={{
                drug: (item) => <td>{item.drug.drug_name}</td>,
              }}
            />
          </CCardBody>
        </CCard>
      </CTabPane>
    </CTabContent>
  );

  const onActiveTabChange = (activeTab) => {
    switch (activeTab) {
      case "Investigation":
        dispatch(fetchInvestigations(appointment.id, TYPE_APPOINTMENT));
        break;
      case "Prescription":
      case "Recommendations":
        dispatch(fetchAppointmentRecommendations(appointment.id));
        dispatch(fetchPrescriptionUtilities());
        dispatch(fetchPrescriptions(appointment.id, TYPE_APPOINTMENT));
        break;
      default:
        // TBD - Add the default active tab here!
        break;
    }
  };
  return (
    <CCard style={{ border: "1px" }}>
      <CCardBody>
        <CRow>
          <CTabs
            activeTab="Examination"
            onActiveTabChange={(activeTab) => onActiveTabChange(activeTab)}
          >
            <CCol md="3" className="text-dark bg-secondary">
              <SideBar />
            </CCol>
            <CCol md="9" className="text-dark bg-light">
              {TabsContent()}
            </CCol>
          </CTabs>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default DoctorOrders;
