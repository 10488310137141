import React, { useEffect } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CAlert,
  CCol,
  CRow,
  CCard,
  CCardBody,
  CButton,
  CLink,
  CWidgetIcon,
} from "@coreui/react";
import Avatar from "views/partials/Avatar";
import {
  patientFetchClinicSlots,
  getAppointmentPrice,
} from "actions/appointments/patientAppointment";
import { PATIENT_APPOINTMENT_SELECTION } from "actions/appointments/types";
import CIcon from "@coreui/icons-react";
import {
  cilBuilding,
  cilCalendar,
  cilCash,
  cilClock,
  cilHeart,
  cilUser,
} from "@coreui/icons";
import i18next from "i18next";

const getUTCDate = (date = new Date()) =>
  new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

const AppointmentCard = ({
  patientAppointmentSelection,
  patientClinicSlots,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    slots,
    slots_error,
    reserved_appointment,
    reserved_slot,
    reserved_slot_user,
  } = patientClinicSlots;

  const handleSelectSlot = (selectedSlot) => {
    dispatch({
      type: PATIENT_APPOINTMENT_SELECTION,
      payload: { schedule_time: selectedSlot },
    });
    history.push("/complaint/create");
  };

  const renderSlotsList = () =>
    Object.keys(slots).map((key) => (
      <CCol xs="12" md="3" className="mb-2">
        <CButton
          onClick={() => handleSelectSlot(key)}
          className="btn btn-primary bg-dark-blue text-light"
          style={{ width: "100%", height: "110%" }}
        >
          {/* {slots[key]} */}
          {`${slots[key].split(" ")[1].split(":")[0]}` < 12
            ? `${slots[key].split(" ")[0]}  ${
                slots[key].split(" ")[1].split(":")[0] % 12
              }:${slots[key].split(" ")[1].split(":")[1]} ${i18next.t("AM")} ${
                slots[key].split(" ")[2]
              } `
            : `${slots[key].split(" ")[0]}  ${
                slots[key].split(" ")[1].split(":")[0] % 12
              }:${slots[key].split(" ")[1].split(":")[1]} ${i18next.t("PM")} ${
                slots[key].split(" ")[2]
              } `}
          {`${slots[key].split(" ")[3].split(":")[0]}` < 12
            ? `${slots[key].split(" ")[3].split(":")[0] % 12}:${
                slots[key].split(" ")[3].split(":")[1]
              } ${i18next.t("AM")}  `
            : ` ${slots[key].split(" ")[3].split(":")[0] % 12}:${
                slots[key].split(" ")[3].split(":")[1]
              } ${i18next.t("PM")} `}
        </CButton>
      </CCol>
    ));

  const checkSlotsError = () => {
    if (slots && Object.values(slots).length > 0) {
      return renderSlotsList();
    }
    return (
      <h4 className="text-align-center not-found">
        <strong>{slots_error}</strong>
      </h4>
    );
  };

  const checkReservedSlot = () => {
    if (reserved_appointment) {
      return (
        <div>
          <h4 className="text-align-center not-found">
            <strong>
              {" "}
              You Already have Scheduled Appointment At {reserved_slot_user}
            </strong>
          </h4>

          <br />
          <h4 className="text-align-center not-found">
            <strong>
              {" "}
              If You Want change It Choose Another Slot Or Approve Your Current
              Schedule
            </strong>
          </h4>
          <br />
          <div className="text-align-center">
            <CButton
              onClick={() => handleSelectSlot(reserved_slot)}
              className="btn btn-primary text-light"
            >
              Approve
            </CButton>
            <br />
          </div>
          <br />
          <br />
        </div>
      );
    }
  };
  // const appointmentDate = patientAppointmentSelection.date;
  // const [year, month, day] = appointmentDate.split("-");
  // const date = getUTCDate(new Date(year, month, day));
  // const formattedAppointmentDate = format(date, "yyyy-MM-dd HH:mm");
  return (
    <>
      <CRow>
        <CCol>
          <CCard className="card-3d" textColor="primary">
            <CCardBody>
              <CRow className="align-items-center">
                <CCol md="2">
                  <CRow>
                    <CCol xs="12">
                      <Avatar
                        src={
                          patientAppointmentSelection.doctor
                            ? patientAppointmentSelection.doctor.avatar
                            : ""
                        }
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        className="float-left"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="10">
                  <CRow className="justify-content-center">
                    <CCol md="4" className="d-flex flex-column">
                      <CWidgetIcon
                        text={
                          patientAppointmentSelection.doctor
                            ? patientAppointmentSelection.doctor.name
                            : ""
                        }
                        header={`${i18next.t("Name")}`}
                        color="dark-blue"
                      >
                        <CIcon width={24} content={cilUser} />
                      </CWidgetIcon>
                    </CCol>
                    <CCol md="4" className="d-flex flex-column">
                      <CWidgetIcon
                        text={
                          patientAppointmentSelection.doctor
                            ? patientAppointmentSelection.doctor.specialty
                            : ""
                        }
                        header={`${i18next.t("Specialty")}`}
                        color="dark-blue"
                      >
                        <CIcon width={24} content={cilHeart} />
                      </CWidgetIcon>
                    </CCol>
                    <CCol
                      md="4"
                      className="d-flex flex-column align-self-center"
                    >
                      <CWidgetIcon
                        text={patientAppointmentSelection.date_formatted}
                        header={`${i18next.t("date")}`}
                        color="dark-blue"
                      >
                        <CIcon width={24} content={cilCalendar} />
                      </CWidgetIcon>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="3" className="d-flex flex-column">
                      <CWidgetIcon
                        text={
                          `${
                            patientAppointmentSelection.time
                              .split(" ")[1]
                              .split(":")[0]
                          }` < 12
                            ? `  ${
                                patientAppointmentSelection.time
                                  .split(" ")[1]
                                  .split(":")[0] % 12
                              }:${
                                patientAppointmentSelection.time
                                  .split(" ")[1]
                                  .split(":")[1]
                              } ${i18next.t("AM")}  `
                            : `  ${
                                patientAppointmentSelection.time
                                  .split(" ")[1]
                                  .split(":")[0] % 12
                              }:${
                                patientAppointmentSelection.time
                                  .split(" ")[1]
                                  .split(":")[1]
                              } ${i18next.t("PM")}  `
                        }
                        header={`${i18next.t("From")}`}
                        color="dark-blue"
                      >
                        <CIcon width={24} content={cilClock} />
                      </CWidgetIcon>
                    </CCol>
                    <CCol md="3" className="d-flex flex-column">
                      <CWidgetIcon
                        text={
                          `${
                            patientAppointmentSelection.time
                              .split(" ")[3]
                              .split(":")[0]
                          }` < 12
                            ? `${
                                patientAppointmentSelection.time
                                  .split(" ")[3]
                                  .split(":")[0] % 12
                              }:${
                                patientAppointmentSelection.time
                                  .split(" ")[3]
                                  .split(":")[1]
                              }  ${i18next.t("AM")} `
                            : ` ${
                                patientAppointmentSelection.time
                                  .split(" ")[3]
                                  .split(":")[0] % 12
                              }:${
                                patientAppointmentSelection.time
                                  .split(" ")[3]
                                  .split(":")[1]
                              } ${i18next.t("PM")} `
                        }
                        header={`${i18next.t("To")}`}
                        color="dark-blue"
                      >
                        <CIcon width={24} content={cilClock} />
                      </CWidgetIcon>
                    </CCol>
                    <CCol md="3" className="d-flex flex-column">
                      <CWidgetIcon
                        text={patientAppointmentSelection.type}
                        header={`${i18next.t("Type")}`}
                        color="dark-blue"
                      >
                        <CIcon width={24} content={cilBuilding} />
                      </CWidgetIcon>
                    </CCol>
                    <CCol md="3" className="d-flex flex-column">
                      <CWidgetIcon
                        text={patientAppointmentSelection.price}
                        header={`${i18next.t("Price")}`}
                        color="dark-blue"
                      >
                        <CIcon width={24} content={cilCash} />
                      </CWidgetIcon>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CCard>
        <CCardBody>
          <h4 className="table-title">{i18next.t("Available Slots")}</h4>
          <CRow style={{ "justify-content": "center" }}>
            {checkReservedSlot()}
          </CRow>
          <br />
          <CRow style={{ "justify-content": "center" }}>
            {checkSlotsError()}
          </CRow>
          <br />
          <CRow style={{ "justify-content": "center" }}>
            <CCol xs="2">
              <CLink href="/patient/appointment/select">
                <CButton color="danger" size="lg" className="m-2">
                  {i18next.t("Cancel")}
                </CButton>
              </CLink>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

const SelectSlot = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const patientAppointmentSelection = useSelector(
    (state) => state.patientAppointmentSelection
  );
  const patientClinicSlots = useSelector((state) => state.patientClinicSlots);
  useEffect(() => {
    if (patientAppointmentSelection.clinic_id) {
      const { clinic_id, date } = patientAppointmentSelection;
      dispatch(patientFetchClinicSlots(clinic_id, date));
      dispatch(getAppointmentPrice(clinic_id, date));
    } else {
      history.push("/patient/clinic/search");
    }
  }, [dispatch, history]); // eslint-disable-line

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-title">
            {i18next.t("Select Slot")}
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          <AppointmentCard
            patientClinicSlots={patientClinicSlots}
            patientAppointmentSelection={patientAppointmentSelection}
          />
        </CCol>
      </CRow>
    </>
  );
};

export default SelectSlot;
