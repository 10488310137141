// @flow
import { Api, getTokenHeader } from "services/Api";
import { GENERATE_ACCESS_TOKEN } from "./types";

export const generateAccessToken = (): Object => async (
  dispatch
) => {
  const response = await Api.get(`/api/admin/chat/accessToken`, {
    ...getTokenHeader(),
  });

  dispatch({ type: GENERATE_ACCESS_TOKEN, payload: response.data });
};

export const addCoordinatorsToConversation = (
  formData: Object
): Object => async () => {
  Api.post(`/api/admin/chat/coordinators`, formData, {
    ...getTokenHeader(),
  });
};

export const addDoctorsToConversation = (formData: Object): Object => async (
) => {
  Api.post(`/api/admin/chat/doctors`, formData, {
    ...getTokenHeader(),
  });
};
