import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CAlert,
} from "@coreui/react";
import AsyncSelect from "react-select/async";
import { Api, getIcdTokenHeader } from "services/Api";
import { insertIcds, icdAuth, fetchAppointmenIcds } from "actions/icd/icd";
import IcdList from "views/partials/IcdList";
import i18next from "i18next";

const Diagnostics = ({ appointment }) => {
  const { id: appointmentId, patient } = appointment;
  const dispatch = useDispatch();
  const icd_list = useSelector((state) => state.icd.icd_list);
  const defaultValues = { icd_codes: icd_list };
  const [icdErrorMsg, setIcdErrorMessage] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);

  const { handleSubmit, control, watch, reset } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    dispatch(fetchAppointmenIcds(appointment.id));
    dispatch(icdAuth());
  }, [appointment.id, dispatch]);

  useEffect(() => {
    reset({ icd_codes: icd_list });
  }, [icd_list, reset]);

  const onSubmit = async (formData) => {
    try {
      const preparedIcdCodes = formData.icd_codes.map((item) => ({
        title: item.title,
        theCode: item.theCode,
        matchingPVs: item.matchingPVs,
      }));
      await dispatch(
        insertIcds(appointmentId, {
          patient_id: patient.id,
          icd_codes: preparedIcdCodes,
        })
      ); // Await the dispatch function if it's a promise
      setAlertVisible(true); // Show the alert on successful submission
      reset(formData); // Reset the form to the submitted values
      setTimeout(() => {
        setAlertVisible(false);
      }, 5000);
    } catch (error) {
      // Handle error if needed
      console.error("Update failed", error);
    }
  };

  const getIcdOptions = async (inputValue) => {
    const url = `https://id.who.int/icd/release/11/2021-05/mms/search?q=${inputValue}`;
    const response = await Api.get(url, getIcdTokenHeader());
    if (response.data.error) {
      setIcdErrorMessage(response.data.errorMessage);
      return [];
    }
    setIcdErrorMessage(false);
    return response.data.destinationEntities
      ? response.data.destinationEntities
      : [];
  };

  const removeHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

  return (
    <CCard>
      {alertVisible && (
        <CAlert color="success" dismissible>
          {i18next.t("Saved Successfully")}
        </CAlert>
      )}
      <CCardHeader row className="diagnostics-header">
        <CCol md="8">
          <strong>{i18next.t("Diagnostics")}</strong>
        </CCol>
      </CCardHeader>
      <CCardBody>
        <CForm onSubmit={handleSubmit(onSubmit)}>
          <CFormGroup row>
            <CCol md="12">
              <CLabel>{i18next.t("ICD Codes")}</CLabel>
              <Controller
                control={control}
                id="icd_codes"
                name="icd_codes"
                isMulti
                cacheOptions
                loadOptions={(inputValue) => getIcdOptions(inputValue)}
                getOptionLabel={(option) =>
                  `${option.theCode} : ${removeHtmlTags(option.title)}`
                }
                getOptionValue={(option) => option.id}
                as={AsyncSelect}
              />
            </CCol>
            {icdErrorMsg && <span className="alert-danger">{icdErrorMsg}</span>}
          </CFormGroup>
          <CFormGroup row>
            <CCol md="12">
              <CButton type="submit" size="sm" color="primary">
                {i18next.t("Save")}
              </CButton>
            </CCol>
          </CFormGroup>
        </CForm>
        {watch("icd_codes") && <IcdList icdList={watch("icd_codes")} />}
      </CCardBody>
    </CCard>
  );
};

export default Diagnostics;
