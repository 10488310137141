import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../scss/style.scss";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import {
  fetchSpecialties,
  deleteSpecialty,
} from "actions/specialties/specialties";
import { LIMIT } from "constants/constants";
import CIcon from "@coreui/icons-react";
import { cilPencil, cilSearch, cilTrash } from "@coreui/icons";
import i18next from "i18next";

const Specialties = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const dispatch = useDispatch();
  const specialties = useSelector((state) => state.specialties.data);
  const meta = useSelector((state) => state.specialties.meta);
  let LIMIT = 100;

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/specialties?page=${newPage}`);
    }
  };

  const handleDeleteSpecialty = (id: string, event) => {
    event.stopPropagation();
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This Specialty.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteSpecialty(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchSpecialties(page, LIMIT));
  }, [dispatch, page]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">{i18next.t("specialties")}</div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            href="/specialties/create"
            className="create-button float-right wi"
          >
            {i18next.t("New Specialty")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={specialties}
                noItemsViewSlot={i18next.t("No Available Items")}
                fields={[
                  // { key: "id", _classes: "font-weight-bold" },
                  { key: "name", label:  i18next.t("Name") },
                  { key: "actions", label:  i18next.t("Actions") },
                ]}
                hover
                striped
                itemsPerPage={LIMIT}
                activePage={page}
                clickableRows
                scopedSlots={{
                  actions: (item) => (
                    <td className="table-specialties-field">
                      <CDropdown variant="btn-group">
                        <CDropdownToggle
                          className="action-toggle m-0"
                          color="light"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="c-icon"
                            role="img"
                          >
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                              class="ci-primary"
                            ></path>
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                              class="ci-primary"
                            ></path>
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                              class="ci-primary"
                            ></path>
                          </svg>
                        </CDropdownToggle>
                        <CDropdownMenu className="p-0">
                          <CDropdownItem
                            href={`/specialties/${item.id}/edit`}
                            className="text-primary"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <CIcon content={cilPencil} />
                            <span class="mx-1">{i18next.t("Edit")}</span>
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={(event) =>
                              handleDeleteSpecialty(item.id, event)
                            }
                            className="text-danger"
                          >
                            <CIcon content={cilTrash} />
                            <span class="mx-1">{i18next.t("Delete")}</span>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                }}
                onRowClick={(row) => {
                  history.push(`/specialties/${row.id}/show`);
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Specialties;
