import { Api, getTokenHeader } from "services/Api";
import {
  START_CALL,
  NEXT_CALL,
  COMPLETE_CURRENT,
  UPDATE_EXAMINATION,
  UPDATE_RECOMMENDATIONS,
} from "./types";

export const startCall = (lobbyToken: String): Object => async (dispatch) => {
  const response = await Api.post(
    `/api/admin/room/${lobbyToken}/create`,
    {},
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: START_CALL, payload: response.data });
};
export const nextCall = (lobbyToken: String): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/room/${lobbyToken}/next`, {
    ...getTokenHeader(),
  });
  dispatch({ type: NEXT_CALL, payload: response.data });
};

export const updateExamination = (appointmentId, formData) => async (
  dispatch
) => {
  const response = await Api.put(
    `/api/admin/appointments/${appointmentId}/update-examination`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: UPDATE_EXAMINATION, payload: response.data });
};

export const updateRecommendations = (appointmentId, formData) => async (
  dispatch
) => {
  const response = await Api.put(
    `/api/admin/appointments/${appointmentId}/update-recommendations`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: UPDATE_RECOMMENDATIONS, payload: response.data });
};

export const endCall = (lobbyToken: String): Object => async () => {
  await Api.get(`/api/admin/room/${lobbyToken}/end`, {
    ...getTokenHeader(),
  });
};

export const completeCurrent = (lobbyToken: String): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/room/${lobbyToken}/complete-current`,
    getTokenHeader()
  );
  dispatch({ type: COMPLETE_CURRENT, payload: response.data });
};

export const abortCurrent = (lobbyToken: String): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/room/${lobbyToken}/abort-current`,
    getTokenHeader()
  );
  dispatch({ type: COMPLETE_CURRENT, payload: response.data });
};

export const startWith = (
  lobbyToken: String,
  appointmentId: String
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/room/${lobbyToken}/start-with/${appointmentId}`,
    getTokenHeader()
  );
  dispatch({ type: START_CALL, payload: response.data });
};
