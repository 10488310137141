import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CLink,
  CCardHeader,
  CCardTitle,
  CCardSubtitle,
} from "@coreui/react";
import { fetchPastRads } from "actions/past-medical-history/pastMedicalHistory";
import InvestigationsList from "./InvestigationsList";

const render = (data) =>
  data.map((item) => (
    <CCol xs="12" md="6">
      <CCard
        className="bg-secondary py-3 text-dark-"
        style={{ color: "black", borderRadius: "15px" }}
      >
        <CCardBody>
          <CRow>
            <CCol xs="12">
              <CCardTitle>{item.id}</CCardTitle>
            </CCol>
            <CCol xs="12">
              <CCardSubtitle>{item.date}</CCardSubtitle>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  ));
const PastRads = ({ patientId, setActiveScreen }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const rads = useSelector((state) =>
    state.pastMedicalHistory.rads ? state.pastMedicalHistory.rads.data : []
  );
  const pages = useSelector((state) =>
  state.pastMedicalHistory.rads
    ? state.pastMedicalHistory.rads.last_page
    : 1
);

const pageChange = (newPage) => {
  dispatch(fetchPastRads(patientId, newPage));
  setPage(newPage);
};
  useEffect(() => {
    dispatch(fetchPastRads(patientId));
  }, [dispatch, patientId]);

  return (
    <CCard>
      {/* <CCardHeader>
        <CRow>
          <CCol md={6}>Past Rads</CCol>
          <CCol md={6}>
            <CLink onClick={() => setActiveScreen(null)}>History</CLink>
          </CCol>
        </CRow>{" "}
      </CCardHeader> */}
      <CCardBody>
        <InvestigationsList
          investigations={rads}
          activePage={page}
          pageChange={pageChange}
          pages={pages}
        />
      </CCardBody>
    </CCard>
  );
};
export default PastRads;
