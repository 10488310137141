export const LIMIT = 10;
export const LARGE_LIMIT = 50;
export const UNAUTHORIZED_STATUS_CODE = 401;
export const BAD_REQUEST = 400;
export const SUCCESS_STATUS_CODE = 200;
export const SEVERITY = ["danger", "warning", "success"];

export const STATUS_COLOR = [
   "warning",
   "success",
   "secondary",
   "danger",
];
export const REQUEST_STATUS = {
  1: "opened",
  2: "running",
  3: "ended",
  4: "rejected",
};

export const REQUEST_SEVERITY = {
  0: "unstable",
  1: "stable",
};
export const REQUEST_SEVERITY_COLORS = [
     "danger",
     "success",
];
export const ENGLISH_TEXT_CODE = "en";
export const ARABIC_TEXT_CODE = "ar";
export const TOGGLE_LANGUAGE = "TOGGLE_LANGUAGE";
export const EPISODES = "episodes";
export const LABS = "labs";
export const RADS = "rads";
export const PRESCRIPTION = "prescription";
export const STATUS_GIVEN = "Given";
export const STATUS_NOT_GIVEN = "Not Given";
export const STATUS_GENERATED = "Not Executed";
export const OLD_PRESCRIPTION = "old_prescription";
export const TYPE_DISCHARGE_PRESCRIPTION = "discharge";
export const TYPE_EPISODE = "episode";
export const TYPE_APPOINTMENT = "appointment";
export const PRESCRIPTION_STATUS_ACTIVE = 2;
export const DOCTOR_SPECIALITY_FORM = "doctor";

export const REQUEST_STATUS_OPENED = 1;

export const IS_ONLINE = 1;
export const USER_LOGGED_IN = "UserLoggedIn";
export const USER_LOGGED_OUT = "UserLoggedOut";
export const ATTACHMENT_TYPE_LAB = "lab";
export const ATTACHMENT_TYPE_RAD = "rad";
export const ATTACHEMNT_TYPE_OTHERS = "others";
export const ROUND_STATUS_SCHEDULED = 1;
export const ROUND_STATUS_STARTED = 3;
export const FOLLOW_UP_PLANS = "FOLLOW_UP_PLANS";
export const CREATE_FOLLOW_UP_PLAN = "CREATE_FOLLOW_UP_PLAN";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const ATTENDANCE_TYPE_LEAVING = "leaving";
export const ATTENDANCE_TYPE_ENTERING = "entering";

export const VACATION_TYPE_DOCTOR = 1;
export const VACATION_TYPE_CLINIC = 2;
export const VACATION_TYPE_HOSPITAL = 3;
export const VACATION_TYPE_NATIONAL = 4;

export const APPOINTMENT_STATUS_CANCELED = 2;

export const SURVEY_AVAILABILITY_HOSPITAL = 1;
export const SURVEY_AVAILABILITY_PUBLIC = 2;
export const SURVEY_AVAILABILITY_RESTRICTED = 3;

export const LOBBY_STATE_IN_LOBBY = 1;
export const LOBBY_STATE_COMPLETED = 4;

export const SUPER_ADMIN_ROLE = "Super Admin";

export const MODEL_TYPE_APPOINTMENT = "appointment";

export const PAYMENT_STATUS = { UNPAID: 1, PAID: 2 };

export const TIME_ZONES = ["Africa/Cairo", "Africa/Mogadishu"];

export const EPISODE_STATUS_CLOSED = 1;
export const EPISODE_STATUS_OPENED = 0;
