import React from "react";
import { useSelector } from "react-redux";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CCol,
  CRow,
  CListGroup,
  CListGroupItem,
  CImg,
  CCardTitle,
  CCard,
  CCardBody,
  CCardSubtitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { getAge } from "helpers/helpers";
import logo from "./../../../../logo.png";
import "./Print.css";
import i18next from "i18next";
import { useLocation } from "react-router-dom";

const PrescriptionPrint = ({ prescriptionList, recommendations={}, modal, setModel, label, appointment }) => {
 console.log(prescriptionList)
  const location = useLocation();
  const printProcess = () => {
    var elem = document.getElementById("printBody");
    var domClone = elem.cloneNode(true);
    // domClone.classList.remove("modal-body");

    var $printSection = document.getElementById("printSection");

    if (!$printSection) {
      var $printSection = document.createElement("div");
      $printSection.style.width = "100%";
      $printSection.style.height = "100%";
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
    }

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
  };

  const printPatientDetails = (logoUrl) => {
    return (
      <CCard className="text-theme-blue header-repeated-print" style={{zIndex:`1`, backgroundColor: `rgba(255, 255, 255, 0.9)`}}>
        <CCardBody>
          <CRow>
            <CCol
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="d-flex flex-column"
            >
              <CRow>
                <CCardTitle className="avh-font-bold  w-fcontent">
                  {i18next.t("Name")} :{" "}
                  {appointment.patient}
                </CCardTitle>
              </CRow>
              <CRow>
                <CCardTitle className="avh-font-bold float-left w-fcontent">
                  {i18next.t("Age")} : {getAge(appointment.patient_birth_date)} Years
                </CCardTitle>
              </CRow>
              <CRow>
                <CCardTitle className="avh-font-bold  w-fcontent">
                  {i18next.t("Specialty")} : {appointment.speciality}
                </CCardTitle>
              </CRow>
              <CRow>
                <CCardTitle
                  className=" avh-font-bold float-left w-fcontent"
                >
                  {i18next.t("Clinic")} : {appointment.clinic}
                </CCardTitle>
              </CRow>
              <CRow>
                <CCardTitle className="avh-font-bold float-left w-fcontent">
                  {i18next.t("Date")} : {appointment.schedule_time}
                </CCardTitle>
              </CRow>
              <CRow>
                <CCardTitle className="avh-font-bold float-left w-fcontent">
                  {i18next.t("Diagnosis")} :{" "}
                  {appointment.examination
                    ? appointment.examination
                    : i18next.t("No Available Examination")}
                </CCardTitle>
              </CRow>
            </CCol>
            <CCol
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              className="d-flex flex-column"
//              style={{zIndex:`99999`, backgroundImage:`url(${logoUrl})`, backgroundSize:`contain`, backgroundRepeat:'no-repeat', backgroundPosition:`center center`, borderTop:`blue 12px solid`,borderBottom:`green 12px solid`}}
            >
              <CImg
                src={logoUrl}
                align="center"
                fluid
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    );
  };
  let logoUrl = process.env.REACT_APP_SERVICE_URL + appointment.logo || logo;
  return modal ? (
    <CModal
      show={modal}
      onClose={() => {
        setModel(false);
        document.getElementById("printSection").remove();
      }}
      size="xl"
    >
      <h4 className="header-label-title d-print-none">{label}</h4>
      <CModalBody id="printBody" style={{backgroundImage:`url(${logoUrl})`, backgroundColor:`rgba(256, 256, 256, 0)`, width:"100%"}}>
        <CListGroup>
          {printPatientDetails(logoUrl)}
          <table>
            <thead><tr><td>
              <div class="header-space">&nbsp;</div>
            </td></tr></thead>
            <tbody><tr><td>
          <CCard className="text-theme-blue" style={{backgroundColor:`rgba(256, 256, 256, 0.9)`}}>
            <CCardBody>
              {prescriptionList &&
              prescriptionList.map(
                (item) =>
                  item.status == 2 && (
                    <CListGroupItem>
                      <CRow>
                        <CCol
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="d-flex flex-column"
                        >
                          <CCardTitle className="avh-font-bold  w-fcontent">
                            ℞: {item.drug.drug_name} ({item.drug.composition})
                          </CCardTitle>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="d-flex flex-column"
                        >
                          <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                            {item.dosage} {item.form} {item.frequency} {item.route}
                          </CCardSubtitle>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="d-flex flex-column"
                        >
                          <CCardBody className="avh-font-bold float-left w-fcontent">
                            {item.notes}
                          </CCardBody>
                          <br></br>
                        </CCol>
                        
                      </CRow>
                      {item.created_at && <CRow>
                        <CCol
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="d-flex flex-column"
                        >
                          <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                            {i18next.t("Creation Date")} : {item.created_at}
                          </CCardSubtitle>
                        </CCol>
                      </CRow>}
                      {item.end_date && <CRow>
                        <CCol
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="d-flex flex-column"
                        >
                          <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                            {i18next.t("End Date")} : {item.end_date}
                          </CCardSubtitle>
                        </CCol>
                      </CRow>}
                    </CListGroupItem>
                  )
              )}
              {recommendations && <CListGroupItem>
                  <CRow>
                    <CCol
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="d-flex flex-column"
                    >
                      <CCardTitle className="avh-font-bold  w-fcontent">
                      {i18next.t("Recommendations")}: 
                      </CCardTitle>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="d-flex flex-column"
                    >
                      <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                        {recommendations}
                      </CCardSubtitle>
                    </CCol>
                  </CRow>
                </CListGroupItem>
              }
              <CListGroupItem>
                <CRow>
                  <CCol
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="d-flex flex-column"
                  >
                    <CCardTitle className="avh-font-bold  w-fcontent">
                      {i18next.t("Doctor's Signature")}: 
                    </CCardTitle>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="d-flex flex-column"
                  >
                    <CCardSubtitle>
                      {prescriptionList[0].doctor.first_name} {prescriptionList[0].doctor.last_name}
                    </CCardSubtitle>
                  </CCol>
                </CRow>
              </CListGroupItem>
            </CCardBody>
          </CCard>
          </td></tr></tbody>
        </table>
        </CListGroup>
        <CCardSubtitle>
          {/* TO DO show parent logo
          <CImg
            src={logo}
            style={{
              display: "block",
              "margin-right": "auto",
              height: "5em",
              width: "50%",
            }}
          />
          */}
        </CCardSubtitle>
      </CModalBody>
      <CModalFooter className="d-print-none">
        <CButton color="primary" onClick={printProcess}>
          <CIcon name="cil-Print" />
          {i18next.t("Print")}
        </CButton>
        <CButton
          type="reset"
          color="danger"
          onClick={() => {
            setModel(false);
          }}
        >
          {i18next.t("Cancel")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    ""
  );
};

export default PrescriptionPrint;
