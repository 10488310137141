import React, { useState } from "react";
import { useSelector } from "react-redux";
import CreateVitalSign from "./Create";
import EditVitalSigns from "./Edit";
import List from "./List";

const VitalSigns = ({ episodeStatus }) => {
  const [modal, setModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const vitalSign = useSelector((state) => state.vitalSigns.vitalSign);
  const [vitalSignId, setVitalSignId] = useState(null);
  const toggle = () => {
    setModal(!modal);
  };
  if (isEditing && vitalSign && vitalSign.id === vitalSignId) {
    return (
      <div className="w-100">
        <EditVitalSigns
          toggle={toggle}
          modal={modal}
          vitalSign={vitalSign}
          setIsEditing={setIsEditing}
        />
        <List
          toggle={toggle}
          setIsEditing={setIsEditing}
          setVitalSignId={setVitalSignId}
        />
      </div>
    );
  }
  return (
    <div className="w-100">
      <CreateVitalSign toggle={toggle} modal={modal} />
      <List
        toggle={toggle}
        episodeStatus={episodeStatus}
        setIsEditing={setIsEditing}
        setVitalSignId={setVitalSignId}
      />
    </div>
  );
};
export default VitalSigns;
