import { Api, getTokenHeader } from "services/Api";
import setQueryString from "helpers/helpers";
import { LIST_COORDINATORS, CLEAR_COORDINATORS_LIST } from "./types";

export const fetchCoordinators = (filters: Object = {}): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/coordinators/list?${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: LIST_COORDINATORS, payload: response.data });
};

export const clearCoordinatorsList = () => async (dispatch) => {
  dispatch({ type: CLEAR_COORDINATORS_LIST, payload: [] });
};
