import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CCard,
  CCardBody,
  CSelect,
  CInputRadio,
  CButton,
  CTextarea,
  CInputCheckbox,
} from "@coreui/react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { Api, getTokenHeader } from "services/Api";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { TimeZoneSelect } from "views/partials/AsyncSelectComponents";
import { FormCardFooter } from "../../pages/partials/partials";
import SharedUserModal from "./SharedUserModal";
import SpokeSection from "./SpokesSection";
import i18next from "i18next";
import {
  getValidation,
  getClassName,
  customStyles,
} from "./RoundFormValidation";
import { useHistory } from "react-router-dom";

const RoundForm = ({
  round,
  onSubmit,
  spokes,
  specialties,
  units,
  organizers,
  consultants,
  handleSpecialtyChange,
  handleSpokeChange,
  formTitle,
}) => {
    let defaultValues = {};
    if (round) {
      defaultValues = round;
    }
  const [attendeeModal, setAttendeeModal] = useState(false);
  const [speakersModal, setSpeakersModal] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    errors,
  } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const ValidationRules = getValidation(watch);
  const history = useHistory();
  useEffect(() => {
    reset(round);
  }, [reset, round]);
  const onSharedAttendeeSubmit = (SA) => {
    const oldSharedAttendess = watch("shared_attendees")
      ? watch("shared_attendees")
      : [];
    setValue("shared_attendees", [...oldSharedAttendess, SA]);
    setAttendeeModal(false);
  };
  const onSharedSpeakerSubmit = (SS) => {
    const oldSharedSpeaker = watch("shared_speakers")
      ? watch("shared_speakers")
      : [];
    setValue("shared_speakers", [...oldSharedSpeaker, SS]);
    setSpeakersModal(false);
  };

  const getPatientOptions = async (inputValue) => {
    console.log(">>>", watch());
    let excludedIds = [];
    if (watch("organizer")) {
      excludedIds = [...excludedIds, watch("organizer").id];
    }
    if (watch("spokes")) {
      let consultantsIds = [];
      watch("spokes").map((spoke) => {
        const temp = spoke.consultants.map((consultant) => consultant.id);
        consultantsIds = [...consultantsIds, ...temp];
      });
      excludedIds = [...excludedIds, ...consultantsIds];
    }
    if (watch("mandatory_speakers")) {
      const mandatorySpeakersIds = watch("mandatory_speakers").map(
        (item) => item.id
      );
      excludedIds = [...excludedIds, ...mandatorySpeakersIds];
    }
    if (watch("mandatory_attendees")) {
      const mandatoryAttendeesIds = watch("mandatory_attendees").map(
        (item) => item.id
      );
      excludedIds = [...excludedIds, ...mandatoryAttendeesIds];
    }
    const url = `/api/admin/patients/list?q=${inputValue}&excludedIds=${JSON.stringify(
      excludedIds
    )}`;
    const options = await Api.get(url, {
      ...getTokenHeader(),
    }).then((response) => response.data.data);
    return options || [];
  };
  const onCancel = () => {
    history.push(`/rounds`);
  };
  return (
    <>
      <CRow className="justify-content-between align-items-center my-1"></CRow>
      <CRow>
        <CCol xs="12">
          <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
            <CCard>
              <CCardBody>
                <CCol xs="12">
                  <h4 class="header-label-title">{formTitle}</h4>
                </CCol>
                <CCol xs="12">
                  <h4 className="header-label">{i18next.t("Round Info")}</h4>
                </CCol>
                <CFormGroup row>
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <CLabel className="float-left w-fcontent labels required">
                      {i18next.t("Title")}
                    </CLabel>
                    <CInput
                      className={getClassName(watch, errors, "title")}
                      type="text"
                      id="title"
                      name="title"
                      innerRef={register(ValidationRules.title)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <CLabel className="float-left w-fcontent labels required">
                      {i18next.t("Specialty")}
                    </CLabel>
                    <CSelect
                      custom
                      className={getClassName(watch, errors, "specialty_id", 0)}
                      name="specialty_id"
                      id="specialty_id"
                      innerRef={register(ValidationRules.specialty_id)}
                      // onChange={()=>{handleSpecialtyChange}}
                      onChange={(event) => {
                        setValue('organizer','')
                        handleSpecialtyChange(event)
                      }}
                      defaultValue={0}
                    >
                      <option key="0" value={0} selected disabled>
                        {i18next.t("Select Specialty")}
                      </option>
                      {RenderSelectOptions(specialties)}
                    </CSelect>
                  </CCol>
                  <CCol
                    sm={12}
                    md={6}
                    xs={12}
                    lg={4}
                    xl={4}
                    className="d-flex flex-column"
                  >
                    <CLabel className="float-left w-fcontent labels required">
                      {i18next.t("Organizer")}
                    </CLabel>
                    <Controller
                      rules={ValidationRules.organizer}
                      styles={customStyles(!errors.organizer)}
                      control={control}
                      id="organizer"
                      name="organizer"
                      autocomplete
                      options={organizers}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      as={Select}
                    />
                  </CCol>
                </CFormGroup>
                <CFormGroup row className="align-items-center">
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <CLabel className="float-left w-fcontent labels required">
                      {i18next.t("Department")}
                    </CLabel>
                    <CSelect
                      custom
                      name="unit"
                      id="unit"
                      className={getClassName(watch, errors, "unit")}
                      innerRef={register(ValidationRules.unit)}
                    >
                      <option key="0" selected disabled>
                        {i18next.t("Select Unit")}
                      </option>
                      {RenderSelectOptions(units)}
                    </CSelect>
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <CLabel className="float-left w-fcontent labels required">
                      {i18next.t("Date")}
                    </CLabel>
                    <CInput
                      className={getClassName(watch, errors, "date")}
                      type="date"
                      name="date"
                      innerRef={register(ValidationRules.date)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <TimeZoneSelect
                      control={control}
                      className={getClassName(watch, errors, "time_zone")}
                      rules={{ ...ValidationRules.time_zone }}
                      label={i18next.t("Time Zone")}
                      name="time_zone"
                    />
                  </CCol>
                </CFormGroup>
                <CFormGroup row className="align-items-center">
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <CLabel className="float-left w-fcontent labels required">
                      {i18next.t("From")}
                    </CLabel>
                    <CInput
                      type="time"
                      name="from"
                      className={getClassName(watch, errors, "from")}
                      innerRef={register(ValidationRules.from)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={4} xl={4}>
                    <CLabel className="float-left w-fcontent labels required">
                      {i18next.t("To")}
                    </CLabel>
                    <CInput
                      type="time"
                      name="to"
                      className={getClassName(watch, errors, "to")}
                      innerRef={register(ValidationRules.to)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={2} xl={2}>
                    <CLabel className="labels required">{i18next.t("Type")}</CLabel>
                    <br></br>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio
                        custom
                        id="inline-radio1"
                        name="type"
                        value="virtual"
                        innerRef={register(ValidationRules.type)}
                        className={getClassName(watch, errors, "type")}
                      />
                      <CLabel variant="custom-checkbox" htmlFor="inline-radio1">
                        {i18next.t("Virtual Round")}
                      </CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio
                        custom
                        id="inline-radio2"
                        name="type"
                        value="physical"
                        innerRef={register(ValidationRules.type)}
                        className={getClassName(watch, errors, "type")}
                      />
                      <CLabel variant="custom-checkbox" htmlFor="inline-radio2">
                        {i18next.t("Physical Round")}
                      </CLabel>
                    </CFormGroup>
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={2} xl={2}>
                    <CFormGroup className="invisible" variant="custom-checkbox" inline>
                      <CInputCheckbox
                        custom
                        id="is_public"
                        name="is_public"
                        innerRef={register}
                      />
                      <CLabel variant="custom-checkbox" htmlFor="is_public">
                        {i18next.t("Is Public")}
                      </CLabel>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
                    <CLabel className="float-left w-fcontent">
                      {i18next.t("Description")}
                    </CLabel>
                    <CTextarea
                      id="description"
                      name="description"
                      innerRef={register}
                    />
                  </CCol>
                </CFormGroup>
                <CCol xs="12">
                  <h4 className="header-label">{i18next.t("Location Info")}</h4>
                </CCol>
                <CFormGroup row>
                  <SpokeSection
                    errors={errors}
                    control={control}
                    register={register}
                    handleSpokeChange={handleSpokeChange}
                    spokes={spokes}
                    consultants={
                      watch("organizer")
                        ? Object.values(consultants).map((consultant) => (
                          consultant.filter(
                              (con) => con.id !== watch("organizer").id
                          )
                        ))
                        : consultants
                    }
                    setValue={setValue}
                  />
                </CFormGroup>
                <CCol xs="12">
                  <h4 className="header-label">{i18next.t("Speakers Info")}</h4>
                </CCol>
                <CFormGroup row>
                  <CCol
                    sm={12}
                    md={5}
                    xs={12}
                    lg={5}
                    xl={5}
                    className="d-flex flex-column"
                  >
                    <CLabel className="float-left w-fcontent labels required">
                      {i18next.t("Speakers")}
                    </CLabel>
                    <Controller
                      rules={ValidationRules.mandatory_speakers}
                      styles={customStyles(!errors.mandatory_speakers)}
                      control={control}
                      id="mandatory_speakers"
                      name="mandatory_speakers"
                      cacheOptions
                      isMulti
                      loadOptions={(inputValue) =>
                        getPatientOptions(inputValue)
                      }
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name}`
                      }
                      getOptionValue={(option) => option.id}
                      as={<AsyncSelect />}
                    />
                  </CCol>
                  <CCol
                    sm={12}
                    md={5}
                    xs={12}
                    lg={5}
                    xl={5}
                    className="d-flex flex-column"
                  >
                    <CLabel
                      htmlFor="shared_speakers"
                      className="float-left w-fcontent"
                    >
                      {i18next.t("Guest Speakers")}
                    </CLabel>
                    <Controller
                      control={control}
                      id="shared_speakers"
                      name="shared_speakers"
                      isMulti
                      autocomplete
                      options={[]}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.email}
                      as={Select}
                    />
                  </CCol>
                  <CCol
                    style={{ alignSelf: "center", marginTop: "2em" }}
                    sm={12}
                    md={2}
                    xs={12}
                    lg={2}
                    xl={2}
                  >
                    <CButton
                      style={{
                        "background-color": "rgb(48, 197, 182)",
                        "border-color": "rgb(48, 197, 182)",
                        width: "5.5em",
                        marginBottom: "2em",
                      }}
                      color="info"
                      onClick={() => setSpeakersModal(true)}
                    >
                      {i18next.t("Add")}
                    </CButton>
                  </CCol>
                </CFormGroup>
                <CCol xs="12">
                  <h4 className="header-label">{i18next.t("Attendees Info")}</h4>
                </CCol>
                <CFormGroup row>
                  <CCol
                    sm={12}
                    md={5}
                    xs={12}
                    lg={5}
                    xl={5}
                    className="d-flex flex-column"
                  >
                    <CLabel className="float-left w-fcontent">{i18next.t("Attendees")}</CLabel>
                    <Controller
                      control={control}
                      id="mandatory_attendees"
                      name="mandatory_attendees"
                      cacheOptions
                      isMulti
                      loadOptions={(inputValue) =>
                        getPatientOptions(inputValue)
                      }
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name}`
                      }
                      getOptionValue={(option) => option.id}
                      as={<AsyncSelect />}
                    />
                  </CCol>
                  <CCol
                    sm={12}
                    md={5}
                    xs={12}
                    lg={5}
                    xl={5}
                    className="d-flex flex-column"
                  >
                    <CLabel
                      htmlFor="shared_attendees"
                      className="float-left w-fcontent"
                    >
                      {i18next.t("Guest Attendees")}
                    </CLabel>
                    <Controller
                      control={control}
                      id="shared_attendees"
                      name="shared_attendees"
                      isMulti
                      autocomplete
                      options={[]}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.email}
                      as={Select}
                    />
                  </CCol>
                  <CCol
                    style={{ alignSelf: "center", marginTop: "2em" }}
                    sm={12}
                    md={2}
                    xs={12}
                    lg={2}
                    xl={2}
                  >
                    <CButton
                      style={{
                        "background-color": "rgb(48, 197, 182)",
                        "border-color": "rgb(48, 197, 182)",
                        width: "5.5em",
                        marginBottom: "2em",
                      }}
                      color="info"
                      onClick={() => setAttendeeModal(true)}
                    >
                      {i18next.t("Add")}
                    </CButton>
                  </CCol>
                </CFormGroup>
              </CCardBody>
              <FormCardFooter onCancel={onCancel} />
            </CCard>
          </CForm>
        </CCol>
      </CRow>
      <SharedUserModal
        onSubmit={onSharedAttendeeSubmit}
        onCancel={() => setAttendeeModal(false)}
        modal={attendeeModal}
        FormTitle={i18next.t("Add Shared Attendee")}
      />
      <SharedUserModal
        onSubmit={onSharedSpeakerSubmit}
        onCancel={() => setSpeakersModal(false)}
        modal={speakersModal}
        FormTitle={i18next.t("Add Shared Speaker")}
      />
    </>
  );
};

export default RoundForm;
