import {
  FETCH_CLINIC_UTILITIES,
  CREATE_CLINIC,
  FETCH_CLINIC,
  UPDATE_CLINIC,
  FETCH_CLINICS,
  DELETE_CLINIC,
  FETCH_MY_CLINICS,
} from "actions/clinics/types";
import { UNAUTHORIZED_STATUS_CODE } from "constants/constants";

/**
 * @param state
 * @param action
 */
export const clinicsUtilities = (state: Object = {}, action: Object) => {
  switch (action.type) {
    case FETCH_CLINIC_UTILITIES:
      return { ...action.payload };
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export default function clinics(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_CLINIC:
    case UPDATE_CLINIC:
      if(action.payload.status == UNAUTHORIZED_STATUS_CODE){
        return {...state, redirect: false}
      }
      return { clinic: action.payload.data, redirect: true };
    case FETCH_CLINIC:
      return { clinic: action.payload.data };
    case FETCH_CLINICS:
      return { ...action.payload };
    case DELETE_CLINIC:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case FETCH_MY_CLINICS:
      return {
        ...state,
        myClincs: action.payload,
      };
    default:
      return state;
  }
}
