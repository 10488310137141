// @flow
import { Api, getTokenHeader } from "services/Api";
import { FETCH_REPORT,
   FETCH_REPORTS,
   CREATE_REPORT,
   UPDATE_REPORT,
   DELETE_REPORT,
   FETCH_OLD_REPORT,
   FETCH_OLD_REPORTS,
   FETCH_SCHEMA,
   GENERATE_REPORT
   } from "./types";

export const fetchOldReport = (params): Object => async (dispatch) => {
  const { type, ...filters } = params;
  const response = await Api.get(`/api/admin/report/${type}`, {
    params: filters,
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_OLD_REPORT, payload: response.data });
};

export const fetchReports = ( page: Number ): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/reports-new?page=${page}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_REPORTS, payload: response.data });
};
export const fetchOldReports = (): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/reports`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_OLD_REPORTS, payload: response.data });
};

export const fetchDetailedReport = (params): Object => async (dispatch) => {
  const { type, modelId, ...filters } = params;
  const response = await Api.get(
    `/api/admin/report/${type}/details/${modelId}`,
    {
      params: filters,
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_OLD_REPORT, payload: response.data });
};

  export const createReport = (data: Object,name: String, description:string): Object => async (dispatch) => {

  const query = JSON.stringify(data);
  const response = await Api.post(`/api/admin/reports`,{ query, name, description }, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_REPORT, payload: response.data });
};



export const fetchReport = (id: string) => async (dispatch) => {
  const response = await Api.get(`/api/admin/reports/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_REPORT, payload: response.data });
};

export const updateReport = (data: Object,name: String, description:string, id: string): Object => async (dispatch) => {
  const query = JSON.stringify(data);
  const response = await Api.put(`/api/admin/reports/${id}`, { query, name, description }, {
    ...getTokenHeader(),
  });


  dispatch({ type: UPDATE_REPORT, payload: response.data });
};

export const deleteReport = (id: string) => async (dispatch) => {
  await Api.delete(`/api/admin/reports/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_REPORT, payload: id });
};

export const generateReport = (id: Number, filterData: string): Object => async (dispatch) => {

console.log('filterData',filterData)
  const response = await Api.get(`/api/admin/reports/generate/${id}?${filterData}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: GENERATE_REPORT, payload: response.data });
};



export const fetchSchema = (): Object => async (dispatch) => {
  let response = await Api.get(`/api/admin/reports/config`, {
    ...getTokenHeader(),
  });
response =JSON.parse(response.data)
  dispatch({ type: FETCH_SCHEMA, payload: response });
};
