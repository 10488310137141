import React from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CRow,
  CCol,
  CCollapse,
  CNav,
  CNavItem,
  CTabPane,
  CNavLink,
  CTabContent,
  CTabs,
} from "@coreui/react";

import { AppointmentData, Labs, Rads } from "../../partials/AppointmentData";
import { AppointmentCardContainer } from "../../../partials/partials";

class LabAndRad extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Labs, Rads };
  }

  toggleLabs = (e, index) => {
    const { Labs: stateLabs } = this.state;
    stateLabs[index].collapse = !stateLabs[index].collapse;
    this.setState({ Labs: stateLabs });
    e.preventDefault();
  };

  toggleRads = (e, index) => {
    const { Rads: stateRads } = this.state;
    stateRads[index].collapse = !stateRads[index].collapse;
    this.setState({ Rads: stateRads });
    e.preventDefault();
  };

  renderItems = (items, toggleFunction) =>
    items.map((item, index) => (
      <CCard key={item.id} style={{ border: "0px" }}>
        <CCardHeader
          className="text-dark bg-light"
          style={{ marginTop: "1%", borderRadius: "10px" }}
        >
          <CRow>
            <CCol xs="10">
              <CCardTitle>{item.title}</CCardTitle>
            </CCol>
            <CCol xs="2">
              <CCardTitle>
                <button
                  type="button"
                  className="btn btn-outline-info"
                  onClick={(e) => toggleFunction(e, index)}
                  data-index={index}
                >
                  <i
                    className={
                      items[index].collapse
                        ? "cil-chevron-bottom"
                        : "cil-chevron-right"
                    }
                    data-index={index}
                  />
                </button>
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardHeader>
        <CardBody item={item} collapse={items[index].collapse} />
      </CCard>
    ));

  render() {
    const { Rads: stateRads, Labs: stateLabs } = this.state;
    return (
      <AppointmentCardContainer
        title="Lab and Radiology"
        appointmentData={AppointmentData}
      >
        <CTabs activeTab="labs">
          <CNav variant="tabs">
            <CNavItem>
              <CNavLink data-tab="labs">Labs</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink data-tab="rads">Radiology</CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane data-tab="labs">
              {this.renderItems(stateLabs, this.toggleLabs)}
            </CTabPane>
            <CTabPane data-tab="rads">
              {this.renderItems(stateRads, this.toggleRads)}
            </CTabPane>
          </CTabContent>
        </CTabs>
      </AppointmentCardContainer>
    );
  }
}

const CardBody = (props) => {
  const { item, collapse } = props;
  return (
    <CCollapse show={collapse}>
      <CCardBody
        className="text-dark bg-secondary"
        style={{
          marginTop: "0.2%",
          paddingTop: "1%",
          borderRadius: "10px",
        }}
      >
        <CRow>
          <CCol xs="4">
            <CCardTitle>Date</CCardTitle>
          </CCol>
          <CCol xs="4">
            <CCardTitle>Result</CCardTitle>
          </CCol>
          <CCol xs="4">
            <CCardTitle>Attachment</CCardTitle>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="4">{item.date}</CCol>
          <CCol md="4">{item.result}</CCol>
          <CCol md="4">Attachment</CCol>
        </CRow>
        <CRow>
          <CCol md="6">
            <CCardTitle>Notes</CCardTitle>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6">Notes Notes Notes</CCol>
          <CCol md="6" />
        </CRow>
      </CCardBody>
    </CCollapse>
  );
};

export default LabAndRad;
