import React, { useState, useEffect } from 'react';
import ReportForm from '../forms/ReportForm';
import { createReport, fetchReports, fetchSchema } from "actions/reports/reports";
import { useSelector, useDispatch } from "react-redux";

const CreateReport = ({ modal, toggle }) => {
  const schema = useSelector((state) => state.report.schema);

  const [selectedTable, setSelectedTable] = useState("");
  const [selectedJoins, setSelectedJoins] = useState([]);

  const dispatch = useDispatch();
useEffect(() => {
  dispatch(fetchSchema());
}, []);

   const onCancel = () => {
    setSelectedTable('')
    toggle();
    dispatch(fetchReports(1))
  };
  const onSubmit = (formData) => {

    const conditions = (formData.conditions?formData.conditions:[] ).map((condition) => {
      if (typeof condition.value === "object" && condition.value.id) {
        return { ...condition, value: condition.value.id,conditionsObject: condition.value };
      } else {
        return { ...condition, conditionsObject: null };
      }
    });
    const data = {
      grouping: formData.grouping,
      order: formData.order,
      table: formData.table,
      conditions: conditions,
      joins: formData.joins,
      column: formData.column,
      function: formData.function,
      direction: formData.direction,
      label: formData.label,
    };
    const name = formData.name
    const description = formData.description

    dispatch(createReport(data,name,description));
    toggle();  
    setSelectedTable('')
    setSelectedJoins([])
    dispatch(fetchReports())

  };

  return (
    <div>
      <ReportForm
        schema={schema}
        selectedTable={selectedTable}
        setSelectedTable={setSelectedTable}
        selectedJoins={selectedJoins}
        setSelectedJoins={setSelectedJoins}
        onSubmit={onSubmit}
        modal={modal}
        onCancel={onCancel}
        title="Create Report"
        toggle={toggle}

      />
    </div>
  );
};

export default CreateReport;
