import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { updateVitalSign } from "actions/vital-signs/vitalSigns";
import VitalSignsForm from "./form/Form";
import i18next from "i18next";

const EditVitalSigns = ({ toggle, modal, setIsEditing, vitalSign }) => {
  const lable = `${i18next.t("Edit Vital Signs")}`;
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.vitalSignUtilities);
  const staffId = useSelector((state) => state.auth.jwt.user.id);
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      date: format(formData.date, "Y-MM-d HH:mm"),
      editor_id: staffId,
    };
    dispatch(updateVitalSign(data, vitalSign.id));
    setIsEditing(false);
    toggle();
  };

  const onCancel = () => {
    toggle();
    setIsEditing(false);
  };
  return (
    <VitalSignsForm
      utilities={utilities}
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal}
      vitalSign={vitalSign}
      lable={lable}
    />
  );
};
export default EditVitalSigns;
