import React from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
  CImg,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { cilNewspaper, cilSearch } from "@coreui/icons";
import { getAge } from "helpers/helpers";
import CIcon from "@coreui/icons-react";
import { cilAccountLogout } from "@coreui/icons";
import Avatar from "views/partials/Avatar";
import { EPISODE_STATUS_OPENED } from "constants/constants";
import "../../../../../scss/style.scss"
import { useLocation } from "react-router-dom";
import i18next from "i18next";

const PatientData = ({ episode, discharge}) => {
  const location = useLocation() 
  return (
  <CRow>
    <CCol>
      <CCard className="text-theme-blue border-0">
        <CCardBody className="p-0 hide-for-mobile">
          {/* for desktop */}
          <CRow className="align-items-center justify-content-between">
            <CCol md="auto">
              <CRow>
                <CCol xs="12">
                  <Avatar
                    src={episode.patient.avatar}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    className="float-left"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="auto">
              <CRow>
                <CCol xs="auto" className="d-flex flex-column">
                  <CCardTitle className="avh-font-bold float-left w-fcontent">
                    {`${episode.patient.first_name} ${episode.patient.last_name}`}
                  </CCardTitle>
                  <CCardSubtitle className="float-left w-fcontent">
                    {episode.specialty.name}
                  </CCardSubtitle>
                </CCol>
                <CCol xs="auto" className="d-flex flex-column">
                  <CCardTitle className="avh-font-bold float-left w-fcontent">
                      {i18next.t("Age")}
                    
                  </CCardTitle>
                  <CCardSubtitle className="float-left w-fcontent">
                    {getAge(episode.patient.birth_date)}
                  </CCardSubtitle>
                </CCol>
                <CCol xs="auto" className="d-flex flex-column hide-for-mobile">
                  <CCardTitle className="avh-font-bold float-left w-fcontent">
                    {i18next.t("hospital")}
                  </CCardTitle>
                  <CCardSubtitle className="float-left w-fcontent">
                    {episode.spock.name}
                  </CCardSubtitle>
                </CCol>
                <CCol xs="auto" className="d-flex flex-column">
                  <CCardTitle className="avh-font-bold float-left w-fcontent">
                    {i18next.t("Check In")}
                  </CCardTitle>
                  <CCardSubtitle className="float-left w-fcontent">
                    {episode.created_at}
                  </CCardSubtitle>
                </CCol>
              </CRow>
            </CCol>
            {discharge ? (
              <CCol>
                {/* <CCardTitle>Chief Complaint</CCardTitle>
              <CCardSubtitle>{episode.chief_complaint}</CCardSubtitle> */}
                {episode.status === EPISODE_STATUS_OPENED && <CLink
                  className="avh-btn px-4 py-3 text-decoration-none text-white avh-font-bold float-right d-color"
                  href={'/episodes/spok/case/' + episode.id + '/discharge-form?logo=' + location.search.split("=")[1]}
                >
                  <CIcon
                    content={cilAccountLogout}
                    className="discharge-icon mx-1"
                  ></CIcon>
                  {i18next.t("DISCHARGE")}
                </CLink>
                }
              </CCol>
            ) : (
              <CCol></CCol>
            )}
          </CRow>
        </CCardBody>
        {/* for mobile */}
        <CCardBody className="p-0 hide-for-desktop">
          <CRow className=" justify-content-between">
            <CCol md="2">
              <CRow className="align-center">
                <CCol sm="2" xs="2" md="2">
                  <Avatar
                    src={episode.patient.avatar}
                    style={{
                      borderRadius: "50%",
                    }}
                    className="float-left mobile-avatar"
                  />
                </CCol>
                <CCol
                  xs="6"
                  md="3"
                  className="d-flex flex-column text-no-wrap case-margin-left"
                >
                  <CCardTitle className="header-title-style ">
                    {`${episode.patient.first_name} ${episode.patient.last_name}`}
                  </CCardTitle>
                  <CCardSubtitle className="float-left w-fcontent text-mobile-size">
                    {episode.specialty.name}
                  </CCardSubtitle>
                </CCol>
                <CCol>
                  {episode.status === EPISODE_STATUS_OPENED && <CDropdown variant="btn-group" style={{ textAlign: "end" }}>
                    <CDropdownToggle
                      className="action-toggle m-0"
                      color="light"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="c-icon"
                        role="img"
                      >
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                          className="ci-primary"
                        ></path>
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                          className="ci-primary"
                        ></path>
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                          className="ci-primary"
                        ></path>
                      </svg>
                    </CDropdownToggle>
                    <CDropdownMenu className="p-0">
                      <CDropdownItem
                        href={`/episodes/spok/case/${episode.id}/discharge-form`}
                        className="text-primary"
                      >
                        <CIcon content={cilAccountLogout} />
                        <span className="mx-1">{i18next.t("DISCHARGE")}</span>
                      </CDropdownItem>
                    </CDropdownMenu>
                    </CDropdown>}
                </CCol>
              </CRow>
            </CCol>
            <CCol md="auto mt-4">
              <CRow>
                <CCol xs="2" className="d-flex flex-column">
                  <CCardTitle className="header-title-style">Age</CCardTitle>
                  <CCardSubtitle className="float-left w-fcontent text-mobile-size">
                    {getAge(episode.patient.birth_date)}
                  </CCardSubtitle>
                </CCol>
                <CCol xs="3" className="d-flex flex-column hide-for-mobile">
                  <CCardTitle className="header-title-style">
                    {i18next.t("Hospital")}
                  </CCardTitle>
                  <CCardSubtitle className="float-left w-fcontent text-mobile-size">
                    {episode.spock.name}
                  </CCardSubtitle>
                </CCol>
                <CCol xs="7" className="d-flex flex-column">
                  <CCardTitle className="header-title-style">
                    {i18next.t("Check In")}
                  </CCardTitle>
                  <CCardSubtitle className="float-left w-fcontent text-mobile-size">
                    {episode.created_at}
                  </CCardSubtitle>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
);
}
export default PatientData;
