import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchUtilities, fetchUser, updateUser } from "actions/users/users";
import { removeEmptyPassword } from "helpers/helpers";
import { fetchCities, fetchCountryCities } from "actions/cities/cities";
import Loading from "views/partials/Loading";
import i18next from "i18next";
import UserForm from "./form/UserForm";

const EditUser = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.utilities);
  const user = useSelector((state) => state.users.user);
  const redirect = useSelector((state) => state.users.redirect);
  const cities = useSelector((state) => state.cities);
  const label = `${i18next.t("Edit User")}`;

  useEffect(() => {
    dispatch(fetchUtilities());
    dispatch(fetchCities(false, 30));
    dispatch(fetchUser(match.params.id));
    if (user && user.countryobj) {
      dispatch(fetchCountryCities(user.countryobj.id));
    }
  }, [dispatch, match.params.id]);

  const onSubmit = async(formData) => {
    await dispatch(updateUser(removeEmptyPassword(formData), match.params.id));
  };

  const onCountryChange = (e) => {
    dispatch(fetchCountryCities(e.target.value));
  };

  if (redirect) {
    history.push("/users");
  }

  if (user && user.id === parseInt(match.params.id, 10)) {
    return (
      <UserForm
        onSubmit={onSubmit}
        roles={utilities.roles || []}
        levels={utilities.levels || []}
        specialties={utilities.specialties || []}
        countries={utilities.countries || []}
        cities={cities.data || []}
        onCountryChange={onCountryChange}
        label={label}
        user={user}
        hospitals={utilities.hospitals || []}
      />
    );
  }
  return <Loading/>
};

export default EditUser;
