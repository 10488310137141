import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCard,
  CCardBody,
  CDataTable,
  CCardHeader,
  CLink,
} from "@coreui/react";
import {
  activeReceivedRequest,
  startRequest,
  rejectRequest,
} from "actions/requests/requests";
import { REQUEST_STATUS_OPENED } from "constants/constants";
import { END_REQUEST } from "actions/requests/types";
import RejectRequestNote from "./RejectRequestNote";
import i18next from "i18next";

const ActiveReceivedRequests = ({ episodeId }) => {
  const [modal, setModal] = useState(false);
  const [activeRequest, setActiveRequest] = useState(null);
  const dispatch = useDispatch();
  const staffId = useSelector((state) => state.auth.jwt.user.id);
  const requests = useSelector((state) =>
    state.requests.receivedRequests.filter(
      (item) => item.episode_id == episodeId
    )
  );
  const Start = (id) => {
    const request = requests.find((item) => item.id === id);
    dispatch(startRequest(request));
  };

  const toggle = () => {
    setModal(!modal);
  };

  const onSubmit = (formData) => {
    const data = {
      ...formData,
      model_id: episodeId,
    };
    dispatch(rejectRequest(data, activeRequest));
    toggle();
  };

  const onCancel = () => {
    setActiveRequest(null);
    toggle();
  };

  const Reject = (id) => {
    const request = requests.find((item) => item.id === id);
    setActiveRequest(request);
    toggle();
  };

  useEffect(() => {
    dispatch(
      activeReceivedRequest({
        episode_id: episodeId,
        status: REQUEST_STATUS_OPENED,
      })
    );
    window.Echo.channel(`episode_active_requests.${episodeId}`).listen(
      "EndRequest",
      (eventData) => {
        console.log("EndRequest ?? ", eventData);
        dispatch({ type: END_REQUEST, payload: eventData.request });
      }
    );
  }, [dispatch, episodeId]);

  return (
    <>
      <CCard>
        <CCardHeader>
          <h4 className="table-title">{i18next.t("requests")}</h4>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            items={requests || []}
            fields={[
              { key: "name", label:  i18next.t("name") },
              { key: "actions", label:  i18next.t("Actions") },
            ]}
            hover
            striped
            noItemsViewSlot={i18next.t("No Available Requests")}
            scopedSlots={{
              name: (item) => (
                <td>{`${item.sender.first_name} ${item.sender.last_name}`}</td>
              ),
              actions: (item) => (
                <>
                  <td>
                    <td>
                      <CLink
                        onClick={() => Start(item.id)}
                        className="btn d-color"
                        style={{
                          width: "7em",
                        }}
                      >
                        {i18next.t("Start")}
                      </CLink>
                    </td>
                    <td>
                      <CLink
                        onClick={() => Reject(item.id)}
                        className="btn btn-danger"
                      >
                        {i18next.t("Reject")}
                      </CLink>
                    </td>
                  </td>
                </>
              ),
            }}
          />
        </CCardBody>
      </CCard>
      <RejectRequestNote
        modal={modal}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  );
};

export default ActiveReceivedRequests;
