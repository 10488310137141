import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchSpecialtiesForm,
  createSpecialtiesForm,
  fetchSpecialtiesFormUtilities,
} from "actions/specialties-form/specialtiesForm";
import { SURVEY_AVAILABILITY_HOSPITAL, SURVEY_AVAILABILITY_RESTRICTED } from "constants/constants";
import SpecialtiesForm from "../form/SpecialtiesForm";
import ClonePublicSurveyForm from "../form/ClonePublicSurveyForm";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import i18next from "i18next";

/**
 * @param props
 */
function CreateSpecialtiesForms() {
  const history = useHistory();
  const label = `${i18next.t("Create New Survey")}`;
  const dispatch = useDispatch();
  const utilities = useSelector((state) => state.specialtiesFormUtilities);
  const redirect = useSelector((state) => state.specialtiesForm.redirect);
  const specialtyForm = useSelector(
    (state) => state.specialtiesForm.specialtyForm
  );
  const [clonedPublicSurvey, setClonedPublicSurvey] = useState(null);

  const onSubmit = (formData, e) => {
    e.preventDefault();
    const data = {
      ...formData,
      hospital_id: formData.hospital ? formData.hospital.id : null,
      survey_availability: SURVEY_AVAILABILITY_HOSPITAL,
    };
    if (data.draft != 2) {
      confirmAlert({
        title: `${i18next.t("Are you sure?")}`,
        message: `${i18next.t("that form is ready to be submitted?")}`,
        buttons: [
          {
            label: `${i18next.t("Yes")}`,
            onClick: () => {
              dispatch(createSpecialtiesForm(data));
              // history.push("/specialties-forms")
            },
          },
          {
            label: `${i18next.t("No")}`,
            onClick: () => {},
          },
        ],
      });
    } else {
      dispatch(createSpecialtiesForm(data));
      // history.push("/specialties-forms")
    }
  };

  const onClonePublicSurvey = () => {
    if (clonedPublicSurvey && clonedPublicSurvey.id) {
      dispatch(fetchSpecialtiesForm(clonedPublicSurvey.id));
    }
  };

  if (redirect) {
    history.push("/specialties-forms");
  }

  useEffect(() => {
    dispatch(fetchSpecialtiesFormUtilities());
  }, [dispatch]);

  const checkIsRestrictedSurvey =
    clonedPublicSurvey &&
    specialtyForm &&
    specialtyForm.survey_availability === SURVEY_AVAILABILITY_RESTRICTED;

  return (
    <>
      <ClonePublicSurveyForm
        setClonedPublicSurvey={setClonedPublicSurvey}
        onClonePublicSurvey={onClonePublicSurvey}
      />
      <SpecialtiesForm
        specialtyForm={
          clonedPublicSurvey && specialtyForm ? specialtyForm : null
        }
        restricted={checkIsRestrictedSurvey}
        parent_id={
          clonedPublicSurvey && specialtyForm ? specialtyForm.id : null
        }
        onSubmit={onSubmit}
        specialties={utilities.specialties || []}
        types={utilities.types || []}
        label={label}
        hospitals={utilities.hospitals || []}
        fields={utilities.fields || []}
      />
    </>
  );
}

export default CreateSpecialtiesForms;
