import React, { useEffect  } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CLink,
  CWidgetIcon,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

import { useDispatch, useSelector } from "react-redux";
import { onMessage } from "firebase/messaging";
import messaging from "services/firebase";


const OldHome = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.auth.jwt.user.id);

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm={12} md={6}>
                <CLink href="/requests">
                  <CWidgetIcon
                    className="home-icons"
                    header={i18next.t("requests")}
                    color="primary"
                  >
                    <CIcon name="cil-microphone" size="xl" />
                  </CWidgetIcon>
                </CLink>
              </CCol>
              <CCol sm={12} md={6}>
                <CLink href="/clinics">
                  <CWidgetIcon
                    className="home-icons"
                    header={i18next.t("clinics")}
                    color="primary"
                  >
                    <CIcon name="cilHospital" size="xl" />
                  </CWidgetIcon>
                </CLink>
              </CCol>
              <CCol sm={12} md={6}>
                <CLink href="/rounds">
                  <CWidgetIcon
                    className="home-icons"
                    header={i18next.t("rounds")}
                    color="primary"
                  >
                    <CIcon
                      name="cil-video"
                      size="xl"
                    />
                  </CWidgetIcon>
                </CLink>
              </CCol>
              <CCol sm={12} md={6}>
                <CLink href="/episodes/select-spoke">
                  <CWidgetIcon
                    style={{ "text-decoration": "none" }}
                    className="home-icons"
                    header={i18next.t("locations")}
                    color="primary"
                  >
                    <CIcon name="cilLocationPin" size="xl" />
                  </CWidgetIcon>
                </CLink>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
}

export default OldHome;
