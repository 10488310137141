import React from "react";
import { useForm } from "react-hook-form";
import {
  CFormGroup,
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CCol,
  CLabel,
  CTextarea,
} from "@coreui/react";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import i18next from "i18next";

const EditFollowUpForm = ({
  onSubmit,
  modal,
  followUp,
  FormTitle,
  onCancel,
}) => {
  let defaultValues = {};
  if (followUp) {
    defaultValues = followUp;
  }
  const { register, handleSubmit, setValue, watch } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { survey } = followUp;
  return (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} size="lg">
        <h4 className="header-label-title">{FormTitle}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CLabel>{i18next.t("Note")}</CLabel>
              <CTextarea
                name="note"
                id="note"
                rows="5"
                placeholder={i18next.t("Notes")}
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
          <SurveyForm
            register={register}
            setValue={setValue}
            watch={watch}
            groups={survey ? survey.groups : []}
          />
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
            {i18next.t("Save")}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  );
};
export default EditFollowUpForm;
