import React, {useEffect, useState} from 'react';
import { Api, getTokenHeader } from "services/Api";
import { useHistory } from "react-router-dom";
//import SuperTreeview from 'react-super-treeview';
import SuperTreeview from 'views/super-tree-view/SuperTreeview';
//import 'react-super-treeview/dist/style.css';

const Hubs = ({hubs}) => {
    const history = useHistory();
    const [data, setData] = useState(hubs);
    
    const onExpandToggle = (node, depth) => {
        if(node.isExpanded === true && !node.children){
            // This will show the loading sign
            node.isChildrenLoading = true;
            const url = `/api/admin/hospitals/${node.id}/children`;
            Api.get(url, {
                ...getTokenHeader(),
            }).then((response) => {
                if(data[depth]){
                // Remove loading sign
                data[depth].isChildrenLoading = false;
                // Make sure node is expanded
                data[depth].isExpanded = true;
                // Set Children data that you potentially
                // got from an API response
                data[depth].children = response.data.data;
                // Update state
                setData(data)
                }
            });
        }
    }
    useEffect(() => {
        setData(hubs);
      }, [hubs]);

    const onNodeClick = (node, depth) => {
        console.log(node)
        history.push(`/episodes/cases/${node.id}`);
    }
    

    return (
        <SuperTreeview
        data={ data }
        onClickCb={onNodeClick}
        onUpdateCb={(updatedData) => {
            setData(updatedData)
        }}
        onExpandToggleCb={onExpandToggle}
        isCheckable={()=>(false)}
        isDeletable={()=>(false)}
    />
    )
}

export default Hubs;
