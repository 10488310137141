import React from "react";
import {
  CCol,
  CButton,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
  CBadge,
} from "@coreui/react";
import {
  LOBBY_STATE_COMPLETED,
  LOBBY_STATE_IN_LOBBY,
} from "constants/constants";
import { cilMediaPlay } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Avatar from "views/partials/Avatar";
import { getAge } from "helpers/helpers";
import { cilDoor, cilHistory } from "@coreui/icons/js/index";

const iconStyle = {
  fontSize: "1.3rem",
  width: "1.3rem",
  height: "1.3rem",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
};

const PatientCard = ({ patient, handleStartWith, startable, toggleMedicalHistory }) => {
  const relatedAppointment = patient.appointment
    ? patient.appointment
    : patient.currentAppointment;

  const renderCompletedFlag = () => {
    if (
      relatedAppointment &&
      relatedAppointment.lobby_state === LOBBY_STATE_COMPLETED
    ) {
      return <CBadge color="info">Completed Appointments</CBadge>;
    }
    return "";
  };

  const renderButtons = () => {
    if(startable && relatedAppointment) {
      return (
        <CButton
          color="success"
          onClick={() => handleStartWith(patient)}
        >
          <CIcon content={cilMediaPlay} className="mx-1" style={iconStyle} ></CIcon>
          Start
        </CButton>
      );
    }
    return "";
  };

  return (
    <>
      <CCard className="my-1 text-theme-blue clinic-card">
        <CCardBody className="py-1">
          <CRow className="align-items-center justify-content-between">
            <CCol md="1">
              <CRow>
                <CCol xs="12">
                  <Avatar
                    src={patient.avatar}
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      objectFit: "cover"
                    }}
                    className="float-left"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="2">
              <CRow className="align-items-center">
                <CCol md="6" className="d-flex flex-column">
                  <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                    {`${patient.first_name} ${patient.last_name}`}
                  </CCardTitle>
                  <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                    {relatedAppointment.cause ? relatedAppointment.cause : "Cause Not Found"}
                  </CCardSubtitle>
                </CCol>
                <CCol md="3" className="d-flex flex-column">
                  <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">Age</CCardTitle>
                  <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                    {patient.birth_date ? getAge(patient.birth_date) : "-"}
                  </CCardSubtitle>
                </CCol>
                <CCol md="3" className="d-flex flex-column">
                  <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">Order</CCardTitle>
                  <CCardSubtitle className="clinic-subtitle float-left w-fcontent">{relatedAppointment ? relatedAppointment.lobby_order : "-"}</CCardSubtitle>
                </CCol>
              </CRow>
            </CCol>
            <CCol md="2">
              <CRow className="justify-content-end align-items-center">
                <CCol xs="12" className="d-flex flex-column">
                  {Number(relatedAppointment.lobby_state) === LOBBY_STATE_IN_LOBBY && (
                    <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent lobby">
                      <span>
                        <CIcon style={iconStyle} content={cilDoor} />
                        <div className="w-fcontent">In Lobby</div>
                      </span>
                    </CCardTitle>
                  )}
                  <CCardTitle className="clinic-subtitle avh-font-bold float-left w-fcontent">
                    {renderCompletedFlag()}
                  </CCardTitle>
                </CCol>
              </CRow>
            </CCol>
            <CCol md="3">
              <CRow className="justify-content-end align-items-center">
                <CCol xs="12" className="d-flex flex-column">
                  {relatedAppointment.complaint ? relatedAppointment.complaint.substring(0,100) : "NO COMPLAINT"}
                </CCol>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow className="justify-content-end align-items-center">
                <CCol xs="6" className="d-flex flex-column">
                  <CButton className="avh-btn px-4 py-3 text-decoration-none text-white avh-font-bold float-right" onClick={() => toggleMedicalHistory(patient.id)}>
                    <CIcon content={cilHistory} className="discharge-icon mx-1 w-fcontent"></CIcon>
                    MEDICAL HISTORY
                  </CButton>
                </CCol>
              </CRow>
              <CRow className="justify-content-end align-items-center">
                <CCol xs="6" className="d-flex flex-column">
                  {renderButtons()}
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

const Patients = ({ patients, handleStartWith, startable, toggleMedicalHistory }) => {
  const renderPatientList = () =>
    patients.map((patient) => (
      <PatientCard
        patient={patient}
        handleStartWith={handleStartWith}
        startable={startable}
        toggleMedicalHistory={toggleMedicalHistory}
      />
    ));

  return (
    <CRow>
      <CCol> {renderPatientList()}</CCol>
    </CRow>
  );
};

export default Patients;
