import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchRoundUtilities, createRound } from "actions/rounds/rounds";
import { fetchDoctors, clearDoctorsList,fetchConsDoctors } from "actions/doctors/doctors";
import RoundForm from "./form/RoundForm";
import i18next from "i18next";

/**
 * @param {*} props required props
 * @returns {*}  appointment form component
 * @class
 */
function CreateRound() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [locationList, setLocationList] = useState([]);
  const doctors = useSelector((state) => state.doctors);
  const consultants = useSelector(
    (state) => state.consultantDoctors.consultantDoctors
  );
  const roundUtilities = useSelector((state) => state.roundsUtilities);
  const redirect = useSelector((state) => state.rounds.redirect);
  const { spokes, specialties, units } = roundUtilities;

  useEffect(() => {
    dispatch(fetchRoundUtilities());
    dispatch(clearDoctorsList());
  }, [dispatch]);

  const handleSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty: event.target.value });
    dispatch(fetchDoctors({ specialty: event.target.value }));
  };

  const handleSpokeChange = (event, index) => {
    setLocationList([...locationList, event.target.value]);
    dispatch(fetchConsDoctors({ hospital: event.target.value }, index));
  };

  const onSubmit = (formData, e) => {
    e.preventDefault();
    const preparedData = {
      ...formData,
      organizer_id: formData.organizer ? formData.organizer.id : null,
      shared_speakers: JSON.stringify(formData.shared_speakers),
      mandatory_speakers: formData.mandatory_speakers
        ? formData.mandatory_speakers.map((m) => m.id)
        : [],
      shared_attendees: JSON.stringify(formData.shared_attendees),
      mandatory_attendees: formData.mandatory_attendees
        ? formData.mandatory_attendees.map((m) => m.id)
        : [],
      time_zone: formData.time_zone.value
        ? formData.time_zone.value
        : formData.time_zone,
      // from:
      //   +formData.from.split(":")[0] > 12
      //     ? `${formData.from.split(":")[0] % 12}`.length === 1
      //       ? `0${formData.from.split(":")[0] % 12}:${
      //           formData.from.split(":")[1]
      //         }`
      //       : `${formData.from.split(":")[0] % 12}:${
      //           formData.from.split(":")[1]
      //         }`
      //     : formData.from,
      // to:
      //   +formData.to.split(":")[0] > 12
      //     ? `${formData.to.split(":")[0] % 12}`.length === 1
      //       ? `0${formData.to.split(":")[0] % 12}:${formData.to.split(":")[1]}`
      //       : `${formData.to.split(":")[0] % 12}:${formData.to.split(":")[1]}`
      //     : formData.to,
    };
    dispatch(createRound(preparedData));
  };

  if (redirect) {
    history.push("/rounds");
  }

  return (
    <RoundForm
      onSubmit={onSubmit}
      formTitle={i18next.t("Create New Round")}
      spokes={spokes || []}
      specialties={specialties || []}
      units={units || []}
      organizers={doctors.data || []}
      consultants={consultants || []}
      handleSpokeChange={handleSpokeChange}
      handleSpecialtyChange={handleSpecialtyChange}
      />
  );
}

export default CreateRound;
