import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CLink,
} from "@coreui/react";
import {
  fetchPublicSurveys,
  deleteSpecialtiesForm,
  addPublicSurvey,
} from "actions/specialties-form/specialtiesForm";
import { LIMIT } from "constants/constants";
import PublicSurveyForm from "../form/AddPublicSurveyForm";
import i18next from "i18next";

const SharedForms = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);

  const [showModal, setShowModal] = useState(false);

  const surveys = useSelector((state) => state.specialtiesForm.data);
  const meta = useSelector((state) => state.specialtiesForm.meta);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/specialties-forms?page=${newPage}`);
    }
  };

  const handleDeleteLevel = (id: string) => {
    confirmAlert({
      title: "Are you sure?",
      message: "You Want To Delete This Specialty Form.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteSpecialtiesForm(id));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchPublicSurveys(page, LIMIT));
  }, [dispatch, page]);

  const handleAddPublicFrom = (formData) => {
    const preparedData = { ...formData, survey: formData.survey.id };
    dispatch(addPublicSurvey(preparedData));
    dispatch(fetchPublicSurveys(page, LIMIT));
    toggleShowModal();
  };
  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">{i18next.t("Shared Forms")}</div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            className="create-button float-right wi"
            onClick={toggleShowModal}
          >
            New Shared form
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <PublicSurveyForm
          onSubmit={handleAddPublicFrom}
          onCancel={toggleShowModal}
          modal={showModal}
        />
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={surveys || []}
                fields={[
                  "title",
                  "specialty",
                  "type",
                  "survey_availability",
                  "actions",
                ]}
                hover
                noItemsViewSlot="No Available Items"
                striped
                scopedSlots={{
                  actions: (item) => (
                    <td>
                      <a
                        href={`/specialties-forms/${item.id}/edit`}
                        className="btn"
                        style={{
                          marginRight: "5px",
                          "background-color": "rgb(48, 197, 182)",
                          "border-color": "rgb(48, 197, 182)",
                          color: "white",
                          width: "7em",
                        }}
                      >
                        Edit
                      </a>
                      <CButton
                        onClick={() => handleDeleteLevel(item.id)}
                        color="danger"
                      >
                        Delete
                      </CButton>
                    </td>
                  ),
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default SharedForms;
