import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchCity, updateCity, fetchCountries } from "actions/cities/cities";
import CityForm from "../form/CityForm";
import Loading from "views/partials/Loading";
import i18next from "i18next";

const EditCity = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const city = useSelector((state) => state.cities.city);
  const redirect = useSelector((state) => state.cities.redirect);
  const countries = useSelector((state) => state.countries);
  const label = `${i18next.t("Edit City")}`;

  const onSubmit = (formData) => {
    dispatch(updateCity(formData, match.params.id));
  };

  useEffect(() => {
    dispatch(fetchCity(match.params.id));
    dispatch(fetchCountries());
  }, [dispatch, match.params.id]);

  if (redirect) {
    history.push("/cities");
  }

  if (city) {
    return (
      <CityForm
        onSubmit={onSubmit}
        label={label}
        city={city}
        countries={countries.data ? countries.data : []}
      />
    );
  }
  return <Loading/>
};

export default EditCity;
