import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CButton,
  CModal,
  CModalFooter,
  CModalBody,
} from "@coreui/react";
import {
  patientLeavedLobby,
  fetchLobbyAppointments,
} from "actions/lobby/lobby";
import { startWith } from "actions/calls/calls";
import Patients from "views/lobby/components/Patients";
import ChatRooms from "views/communications/chat/ChatRooms";
import i18next from "i18next";
import PastMedicalHistory from "../episode-management/screens/spoke/cases/tabs/pastMedicalHistory/PastMedicalHistory";
import { fetchHospital } from "actions/hospitals/hospitals";

const PhysicalLobby = ({ match, hideActions, hideLobby }) => {
  const dispatch = useDispatch();
  const histroy = useHistory();
  // const lobby = useSelector((state) => state.lobby);
  const loggedInUser = useSelector((state) => state.auth.jwt.user.id);
  const lobbyState = useSelector((state) => state.lobby);
  const hospital = useSelector((state) => state.hospitals.hospital);

  const totalAppointments = lobbyState.appointments
    ? lobbyState.appointments
    : [];
    const [medicalHistory, setMedicalHistory] = useState(false);
    const [patientId, setPatientId] = useState(false);
    const toggleMedicalHistory  = (patientId) => {
      setPatientId(patientId)
      setMedicalHistory(!medicalHistory);
    };
  useEffect(() => {
    dispatch(fetchLobbyAppointments(match.params.token));
    dispatch(fetchHospital(lobbyState.lobby.clinic.hospital_id));
  }, []);

  const handleStartWith = (patient) => {
    const appointmentId = patient.currentAppointment.id;
    dispatch(startWith(match.params.token, appointmentId));
    dispatch(patientLeavedLobby(patient.id));
    if (hideLobby) {
      hideLobby();
    }
    histroy.push(`/physician/call/${lobbyState.lobby.session_token}`);
  };

  const getPatientsList = () =>
    totalAppointments.map((appointment) => ({
      ...appointment.patient,
      currentAppointment: appointment,
    }));

  if (lobbyState.lobby) {
    return (
      <>
        <CRow>
          <CCol>
            <CCard
              className="bg-dark-blue text-light"
              style={{ borderRadius: "15px" }}
            >
              <CCardBody>
                <CRow className="text-center">
                  <CCol xs="12">
                    <CCardTitle
                      className="bg-dark"
                      style={{
                        borderRadius: "10px",
                        width: "50%",
                        marginLeft: "25%",
                      }}
                    >
                      {match.params.date}
                    </CCardTitle>
                    <CCardTitle>
                      {`Total Patients ( ${totalAppointments.length} )`}
                    </CCardTitle>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs="12">
                    <CCardTitle>
                      {`From  ${lobbyState.lobby.from} To ${lobbyState.lobby.to}`}
                    </CCardTitle>
                  </CCol>
                </CRow>
                {!hideActions && (
                  <CRow className="align-items-center mt-3">
                    <CCol xs="4">
                      <CButton
                        href={`/physician/call/${lobbyState.lobby.session_token}`}
                        color="success"
                        variant="outline">
                        Enter The Room
                      </CButton>
                    </CCol>
                    <CCol xs="4">

                    </CCol>
                    <CCol xs="4">
                      <CButton color="danger" className="mt-0" variant="outline">{i18next.t("Cancel")}</CButton>
                    </CCol>
                  </CRow>
                )}
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md={6}>
            <ChatRooms />
            <CModal
          show={medicalHistory}
          onClose={() => setMedicalHistory(false)}
          size="xl"
        >
          <CModalBody>
            {patientId && totalAppointments && <PastMedicalHistory patientId={patientId} hospital={hospital} patient={totalAppointments.filter((app) => app.patient.id == patientId)[0].patient} />}
          </CModalBody>
          <CModalFooter>
              <CButton
                type="reset"
                color="danger"
                onClick={() => setMedicalHistory(false)}
              >
                Close
              </CButton>
          </CModalFooter>
        </CModal>
          </CCol>
        </CRow>
        <CRow className="justify-content-between align-items-center my-1">
          <CCol xs="12">
            <div className="float-left table-title">Patients List</div>
          </CCol>
          <CCol xs="12">
              <Patients
              patients={getPatientsList()}
              handleStartWith={handleStartWith}
              startable
              toggleMedicalHistory={toggleMedicalHistory}
            />
          </CCol>
        </CRow>
      </>
    );
  }
  return <></>;
};

export default PhysicalLobby;
