import React from "react";
import i18next from "i18next";

const requiredMessage = `${i18next.t("Field is required")}`;

export const getValidation = (watch) => {
  const validation = {
    name: {
      required: requiredMessage,
      minLength: {
        value: 3,
        message: `${i18next.t("cannot be less than 3 characters")}`,
      },
      pattern: {
        value: /^[A-Za-z]+/i,
        message: `${i18next.t("cannot include numbers")}`,
      },
    },
    email: {
      required: requiredMessage,
      pattern: {
        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i,
        message: `${i18next.t("invalid Email")}`,
      },
    },
    id: {
      required: requiredMessage,
      // minLength: {
      //   value: 14,
      //   message: "cannot be less or more than 14 numbers",
      // },
      // maxLength: {
      //   value: 14,
      //   message: "cannot be less or more than 14 numbers",
      // },
    },
    password: {
      required: requiredMessage,
      minLength: {
        value: 8,
        message: `${i18next.t("cannot be less than 8 characters")}`,
      },
    },
    require: {
      required: requiredMessage,
    },
  };

  return watch, validation;
};
export const writeErrorMessage = (errortarget) => (
  <p className="error" style={{ color: "red" }}>
    {errortarget ? errortarget.message : ""}
  </p>
);
export const getClassName = (inputName, defaultValue, watch, errors) => {
  const targetValue = watch(inputName);
  if (errors[inputName]) {
    return "is-invalid";
  }
  if (defaultValue == watch(inputName)) {
    return "";
  }
  if (watch(inputName)) {
    return "is-valid";
  }
  return "";
};
