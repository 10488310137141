import setQueryString from "helpers/helpers";
import { Api, getTokenHeader } from "services/Api";
import {
  FETCH_CLINIC_UTILITIES,
  CREATE_CLINIC,
  FETCH_CLINIC,
  UPDATE_CLINIC,
  FETCH_CLINICS,
  DELETE_CLINIC,
  FETCH_MY_CLINICS,
  START_CLINIC,
} from "./types";

export const fetchClinicUtilities = () => async (dispatch) => {
  const response = await Api.get("/api/admin/clinics/utilities", {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_CLINIC_UTILITIES, payload: response.data });
};

export const createClinic = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(`/api/admin/clinics`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_CLINIC, payload: response.data });
};

export const fetchClinic = (id: number): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/clinic/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_CLINIC, payload: response.data });
};

export const updateClinic = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(`/api/admin/clinics/${id}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_CLINIC, payload: response.data });
};

export const fetchClinics = (
  page: string,
  limit: string,
  filters: Object = {}
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/clinics?page=${page}&limit=${limit}${setQueryString(filters)}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_CLINICS, payload: response.data });
};

export const deleteClinic = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/clinics/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_CLINIC, payload: id });
};

export const fetchMyClinics = (page: string, limit: string): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/clinics/my-clinics?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_MY_CLINICS, payload: response.data });
};

export const startClinic = (clinicId): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/clinics/${clinicId}/start`,
    getTokenHeader()
  );
  console.log("start Clinic", clinicId);
  dispatch({ type: START_CLINIC, payload: response.data });
};
