import React, { useEffect } from "react";
import {
  CCol,
  CCard,
  CCardBody,
  CListGroup,
  CListGroupItem,
  CRow,
} from "@coreui/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CLINIC_TYPE_VIRTUAL } from "constants/clinic";
import Payments from "./PaymentData";

const PaymentMethod = () => {
  const history = useHistory();
  const patientAppointmentSelection = useSelector(
    (state) => state.patientAppointmentSelection
  );
  useEffect(() => {
    if (!patientAppointmentSelection.clinic_id) {
      history.push("/patient/clinic/search");
    }
    if (patientAppointmentSelection.price == 0) {
      history.push("/payment/fawry/details?&referenceNumber=4133&orderAmount=0&orderStatus=CASH&statusDescription=Operation%20done%20successfully");
    }
  }, [history, patientAppointmentSelection]);

  const getPaymentMethods = () => {
    if (
      patientAppointmentSelection &&
      patientAppointmentSelection.type === CLINIC_TYPE_VIRTUAL
    ) {
      return Payments.filter((item) => item.name !== "Cash");
    }
    return Payments;
  };

  const render = () => {
    const methods = getPaymentMethods();
    return methods.map((item) => (
      <CListGroupItem
        href= {(item.name.toLowerCase() !== 'cash') ? `/payment/${item.name.toLowerCase()}?methodname=${item.name.toLowerCase()}` : `/payment/fawry/details?&referenceNumber=4133&orderAmount=0&orderStatus=CASH&statusDescription=Operation%20done%20successfully`}
        key={item.id}
        className="bg-secondary py-3 text-black-50 font-weight-bold"
      >
        {item.name}
      </CListGroupItem>
    ));
  };

  return (
    <CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol xs="12">
                <h4 className="header-label-title">Select Payment Method</h4>
              </CCol>
            </CRow>
            <CCol xs="12" md="12">
              <CCard>
                <CCardBody>
                  <CListGroup>{render()}</CListGroup>
                </CCardBody>
              </CCard>
            </CCol>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default PaymentMethod;
