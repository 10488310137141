import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { LABS, RADS, OLD_PRESCRIPTION } from "constants/constants";

import { fetchEpisodeUtilities } from "actions/episodes/episodes";
import LabOrders from "./LabOrders";
import RadOrders from "./RadOrders";
import OldPrescription from "./OldPrescription";
import DischargeSummary from "./DischargeSummary";

const Screens = ({ episode, onCancel }) => {
  const dispatch = useDispatch();
  const [activeScreen, setActiveScreen] = useState(null);

  switch (activeScreen) {
    case RADS:
      return (
        <RadOrders
          setActiveScreen={setActiveScreen}
          onCancel={onCancel}
          episode={episode}
        />
      );
    case LABS:
      return (
        <LabOrders
          setActiveScreen={setActiveScreen}
          onCancel={onCancel}
          episode={episode}
        />
      );
    case OLD_PRESCRIPTION:
      return (
        <OldPrescription
          setActiveScreen={setActiveScreen}
          onCancel={onCancel}
          episode={episode}
        />
      );
    default:
      dispatch(fetchEpisodeUtilities());
      return (
        <DischargeSummary setActiveScreen={setActiveScreen} episode={episode} />
      );
  }
};
export default Screens;
