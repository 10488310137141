import React from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CDataTable,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { cilChevronRight, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useDispatch } from "react-redux";
import { patientCancelAppointments } from "actions/appointments/patientAppointment";
import { confirmAlert } from "react-confirm-alert";
import i18next from "i18next";

const AppointmentCardGroup = (Props) => {
  const { color, type, Appointments } = Props;
  const dispatch = useDispatch();
  const fields = [
    { key: "schedule_time", label: i18next.t("Schedule Time") },
    { key: "doctor", label: i18next.t("Doctor") },
    { key: "speciality", label: i18next.t("Specialty") },
    { key: "clinic_type", label: i18next.t("Clinic Type") },
  ];

  const handleCancelAppointment = (id: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want to Cancel This Appointment.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(patientCancelAppointments(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
        },
      ],
    });
  };

  const renderStartButton = (appointment) => {
    const { can_start, id } = appointment;
    if (can_start) {
      return (
        <>
          <CDropdown variant="btn-group">
            <CDropdownToggle
              className="action-toggle m-0"
              color="light"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="c-icon"
                role="img"
              >
                <path
                  fill="var(--ci-primary-color, currentColor)"
                  d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                  className="ci-primary"
                />
                <path
                  fill="var(--ci-primary-color, currentColor)"
                  d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                  className="ci-primary"
                />
                <path
                  fill="var(--ci-primary-color, currentColor)"
                  d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                  className="ci-primary"
                />
              </svg>
            </CDropdownToggle>
            <CDropdownMenu className="p-0">
              <CDropdownItem
                href={`/patient/clinic/lobby/check/${id}`}
                className="text-primary"
              >
                <CIcon content={cilChevronRight} />
                <span className="mx-1">{i18next.t("Start")}</span>
              </CDropdownItem>
              {appointment.patient_cancellable ? (
                <CDropdownItem
                  onClick={() => handleCancelAppointment(id)}
                  className="text-danger"
                >
                  <CIcon content={cilTrash} />
                  <span className="mx-1">{i18next.t("Cancel")}</span>
                </CDropdownItem>
              ) : (
                ""
              )}
            </CDropdownMenu>
          </CDropdown>
        </>
      );
    }
    return (
      <CDropdown variant="btn-group">
        <CDropdownToggle
          className="action-toggle m-0"
          color="light"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="c-icon"
            role="img"
          >
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
              className="ci-primary"
            />
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
              className="ci-primary"
            />
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
              className="ci-primary"
            />
          </svg>
        </CDropdownToggle>
        <CDropdownMenu className="p-0">
          <CDropdownItem
            href={`/complaint/${id}/edit`}
            className="text-primary"
          >
            <CIcon content={cilChevronRight} />
            <span className="mx-1">{i18next.t("Edit Complaint")}</span>
          </CDropdownItem>
          {appointment.patient_cancellable ? (
            <CDropdownItem
              onClick={() => handleCancelAppointment(id)}
              className="text-danger"
            >
              <CIcon content={cilTrash} />
              <span className="mx-1">{i18next.t("Cancel")}</span>
            </CDropdownItem>
          ) : (
            ""
          )}
        </CDropdownMenu>
      </CDropdown>
    );
  };
  const getShowUrl = (item) =>
    type === "Upcoming Appointments"
      ? `/patient/appointments/${item.id}/show`
      : `/patient/timeline/appointment/${item.id}/show`;

  const renderActions = (item) => {
    if (type === "Upcoming Appointments") {
      return renderStartButton(item);
    }
    return (
      <td>
        <a
          href={getShowUrl(item)}
          style={{ marginRight: "1em" }}
          className="btn btn-primary"
        >
          {i18next.t("show")}
        </a>
      </td>
    );
  };

  return (
    <CCard borderColor={color}>
      <CCardBody>
        <CDataTable
          items={Appointments}
          noItemsViewSlot={i18next.t("No Available Items")}
          fields={
            type === "Upcoming Appointments"
              ? [
                  ...fields,
                  { key: "starts_in", label: i18next.t("Starts In") },
                  { key: "actions", label: i18next.t("Actions") },
                ]
              : [...fields, { key: "actions", label: i18next.t("Actions") }]
          }
          hover
          striped
          clickableRows
          scopedSlots={{
            actions: renderActions,
            schedule_time: (item) => (
              <td>
                {`${+item?.schedule_time?.split(" ")[1].split(":")[0]}` < 12
                  ? `${item?.schedule_time?.split(" ")[0]} ${
                      item?.schedule_time?.split(" ")[1].split(":")[0] % 12
                    }:${
                      item?.schedule_time?.split(" ")[1].split(":")[1]
                    } ${i18next.t("AM")}`
                  : `${item?.schedule_time?.split(" ")[0]} ${
                      item?.schedule_time?.split(" ")[1].split(":")[0] % 12
                    }:${
                      item?.schedule_time?.split(" ")[1].split(":")[1]
                    } ${i18next.t("PM")}`}
              </td>
            ),
          }}
        />
      </CCardBody>
    </CCard>
  );
};

export default AppointmentCardGroup;
