import React from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CSelect,
  CTextarea,
} from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import i18next from "i18next";
import { getClassName } from "views/pages/register/RegisterFormValidation";
import getValidation from "views/clinics/form/ClinicFormValidation";

const FollowUpForm = ({
  onSubmit,
  modal,
  followUp,
  FormTitle,
  onCancel,
  specialtyFormsList,
  onSpecialtyFormChange,
  specialties,
  specialtyForm,
  onSpecialtyChange,
}) => {
  let defaultValues = {};
  if (followUp) {
    defaultValues = followUp;
  }
  const { register, handleSubmit, setValue, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const Validation = getValidation(watch);

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} size="lg">
        <h4 className="header-label-title">{FormTitle}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel className="labels required">{i18next.t("Specialty")}</CLabel>
              <CSelect
                className={`${getClassName(
                  "specialty_id",
                  "Select Specialty",
                  watch,
                  errors
                )}`}
                custom
                name="specialty_id"
                id="specialty_id"
                innerRef={register(Validation.specialty_id)}
                onChange={onSpecialtyChange}
              >
                <option value="" selected disabled>
                  {i18next.t("Select Specialty")}
                </option>
                {RenderSelectOptions(specialties)}
              </CSelect>
              <CLabel className="labels required">{i18next.t("Specialty Form")}</CLabel>
              <CSelect
                className={`${getClassName(
                  "specialty_form_id",
                  "Select Specialty Form",
                  watch,
                  errors
                )}`}
                custom
                name="specialty_form_id"
                id="specialty_form_id"
                innerRef={register(Validation.specialty_form_id)}
                onChange={onSpecialtyFormChange}
              >
                <option value="" >
                  {i18next.t("Select Specialty Form")}
                </option>
                {RenderSelectOptions(specialtyFormsList || [])}
              </CSelect>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel className="float-left labels">{i18next.t("Note")}</CLabel>
              <CTextarea
                name="note"
                id="note"
                rows="3"
                placeholder={i18next.t("Notes")}
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
          <SurveyForm
            register={register}
            setValue={setValue}
            watch={watch}
            groups={specialtyForm ? specialtyForm.groups : []}
          />
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
            {i18next.t("Save")}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};
export default FollowUpForm;
