import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CLink,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { cilPencil, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { fetchRoles } from "actions/roles/roles";
import { LARGE_LIMIT as LIMIT } from "constants/constants";
import "../../../scss/style.scss";
import i18next from "i18next";

const Roles = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.roles.data);
  const meta = useSelector((state) => state.roles.meta);

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/roles?page=${newPage}`);
    }
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchRoles(page, LIMIT));
  }, [dispatch, page]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">
            {i18next.t("roles")}
          </div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            href="/roles/create"
            className="create-button float-right wi"
          >
            {i18next.t("New Role")}
          </CButton>
        </CCol>
      </CRow>
      <CCard>
        <CCardBody>
          <CDataTable
            items={roles}
            fields={[
              // "name", "actions"
              { key: "name", label:  i18next.t("name") },
              { key: "actions", label:  i18next.t("Actions") },
            ]}
            noItemsViewSlot={i18next.t("No Available Items")}
            hover
            striped
            itemsPerPage={LIMIT}
            activePage={page}
            clickableRows
            onRowClick={(item) => history.push(`/roles/${item.id}/show`)}
            scopedSlots={{
              actions: (item) =>
                item.code === "Super Admin" ? (
                  <td></td>
                ) : (
                  <td>
                    <CDropdown variant="btn-group">
                      <CDropdownToggle
                        className="action-toggle m-0"
                        color="light"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          class="c-icon"
                          role="img"
                        >
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
                            class="ci-primary"
                          ></path>
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
                            class="ci-primary"
                          ></path>
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
                            class="ci-primary"
                          ></path>
                        </svg>
                      </CDropdownToggle>
                      <CDropdownMenu className="p-0">
                        <CDropdownItem
                          href={`/roles/${item.id}/edit`}
                          className="text-primary"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <CIcon content={cilPencil} />
                          <span class="mx-1">{i18next.t("Edit")}</span>
                        </CDropdownItem>
                        {/* <CDropdownItem onClick={() => handleDeleteCity(item.id)} className="text-danger">
                        <CIcon content={cilTrash} />
                          <span class="mx-1">Delete</span>
                        </CDropdownItem> */}
                      </CDropdownMenu>
                    </CDropdown>
                  </td>
                ),
            }}
          />
          <CPagination
            activePage={page}
            onActivePageChange={pageChange}
            pages={meta ? meta.last_page : 1}
            doubleArrows={false}
            align="center"
          />
        </CCardBody>
      </CCard>
    </>
  );
};

export default Roles;
