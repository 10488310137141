import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchPermissions, fetchRole, updateRole } from "actions/roles/roles";
import RoleForm from "../form/RoleForm";
import Loading from "views/partials/Loading";
import i18next from "i18next";

const EditRole = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const label = `${i18next.t("Edit Role")}`;
  const permissions = useSelector((state) => state.permissions);
  const role = useSelector((state) => state.roles.role);
  const redirect = useSelector((state) => state.roles.redirect);

  const onSubmit = (formData) => {
    dispatch(updateRole(formData, match.params.id));
  };

  if (redirect) {
    history.push("/roles");
  }

  useEffect(() => {
    dispatch(fetchRole(match.params.id));
    dispatch(fetchPermissions());
  }, [dispatch, match.params.id]);

  if (role) {
    return (
      <RoleForm
        label={label}
        onSubmit={onSubmit}
        permissions={permissions}
        role={role}
      />
    );
  }
  return <Loading/>
};

export default EditRole;
