import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import i18next from "i18next";
import {
  CCol,
  CContainer,
  CRow,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CSelect,
  CTextarea,
  CCollapse,
  CDataTable,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilArrowThickRight, cilArrowThickBottom } from "@coreui/icons";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import AsyncSelect from "react-select/async";
import { Api, getIcdTokenHeader } from "services/Api";
import { useDispatch, useSelector } from "react-redux";
import { icdAuth } from "actions/icd/icd";
import { getClassName } from "views/pages/register/RegisterFormValidation";
import getValidation from "views/clinics/form/ClinicFormValidation";
import RenderSelectOptions from "../../../../../partials/RenderSelectOptions";

const SpecialtyForm = ({
  onSubmit,
  specialties,
  discharge_reasons,
  specialtyForm,
  onSpecialtyChange,
  onCancel,
  specialtyFormsList,
  onSpecialtyFormChange,
  dischargData,
  setDischargData,
}) => {
  const dispatch = useDispatch();
  const episodeIcds = useSelector(
    (state) => state.episodeUtilities.episodeIcds
  );
  const previousDiagnostics = useSelector(
    (state) => state.episodeUtilities.previousDiagnostics
  );

  const dischargeFormChange = (element, e) => {
    setDischargData(getValues());
  };

  const innerSubmit = (form) => {
    setDischargData(getValues());
    onSubmit(form);
  };
  const defaultValues = dischargData;

  useEffect(() => {
    dispatch(icdAuth());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    errors,
  } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const Validation = getValidation(watch);

  useEffect(() => {
    console.log("icd icd_codes", episodeIcds);
    setValue("icd_codes", episodeIcds);
  }, [episodeIcds, setValue]);

  const [showDiagnostics, setShowDiagnostics] = useState(false);
  const [icdErrorMsg, setIcdErrorMessage] = useState(false);
  const getIcdOptions = async (inputValue) => {
    const url = `https://id.who.int/icd/release/11/2021-05/mms/search?q=${inputValue}`;
    const response = await Api.get(url, getIcdTokenHeader());
    console.log("get ICD11 options", response);
    if (response.data.error) {
      setIcdErrorMessage(response.data.errorMessage);
      return [];
    }
    setIcdErrorMessage(false);
    return response.data.destinationEntities
      ? response.data.destinationEntities
      : [];
  };
  const removeHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

  const renderPreviousIcds = () => {
    if (previousDiagnostics && previousDiagnostics.length > 0) {
      return (
        <CCard style={{ border: "0px" }}>
          <CRow>
            <CCol xs="12">
              <h4 className="header-label-title">
                {i18next.t("Previous Diagnoses")}
                <CIcon
                  content={
                    showDiagnostics ? cilArrowThickBottom : cilArrowThickRight
                  }
                  onClick={() => setShowDiagnostics(!showDiagnostics)}
                  onKeyDown={() => setShowDiagnostics(!showDiagnostics)}
                />
              </h4>
            </CCol>
          </CRow>
          <CCollapse show={showDiagnostics}>
            <CDataTable
              items={previousDiagnostics}
              noItemsViewSlot={i18next.t("No Available Items")}
              fields={[
                { key: "code", label: i18next.t("code") },
                { key: "title", label: i18next.t("title") },
              ]}
              scopedSlots={{
                title: (item) => <span>{removeHtmlTags(item.title)}</span>,
              }}
            />
          </CCollapse>
        </CCard>
      );
    }
    return "";
  };

  return (
    <CContainer>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <h2 className="header-label-title">
            {i18next.t("Examination of Discharge")}
          </h2>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CForm
            onSubmit={handleSubmit(innerSubmit)}
            className="form-horizontal"
          >
            <CCard>
              <CCardBody>
                <CFormGroup row>
                  <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                    <CRow className="mb-2">
                      <CCol>
                        <CLabel className="labels required">
                          {i18next.t("Discharge Reason")}
                        </CLabel>
                        <CSelect
                          className={`${getClassName(
                            "discharge_reason",
                            "Discharge Reason",
                            watch,
                            errors
                          )}`}
                          custom
                          name="discharge_reason"
                          id="discharge_reason"
                          onChange={dischargeFormChange}
                          innerRef={register(Validation.specialty_id)}
                        >
                          <option key="0" value="" selected disabled>
                            {i18next.t("Discharge Reason")}
                          </option>
                          {RenderSelectOptions(discharge_reasons)}
                        </CSelect>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                    <CRow className="mb-2 d-flex flex-row">
                      <CCol className="d-flex flex-column">
                        <CLabel className="labels">
                          {i18next.t("ICD Codes")}
                        </CLabel>
                        <Controller
                          control={control}
                          rules={{ required: "please choose an option" }}
                          id="icd_codes"
                          name="icd_codes"
                          isMulti
                          cacheOptions
                          loadOptions={(inputValue) =>
                            getIcdOptions(inputValue)
                          }
                          getOptionLabel={(option) =>
                            `${
                              option.theCode ? option.theCode : option.code
                            } : ${removeHtmlTags(option.title)}`
                          }
                          getOptionValue={(option) => option.id}
                          as={AsyncSelect}
                        />
                        {icdErrorMsg && (
                          <span className="alert-danger">{icdErrorMsg}</span>
                        )}
                      </CCol>
                    </CRow>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                    <CLabel className="labels required">
                      {i18next.t("Select Specialty")}
                    </CLabel>
                    <CSelect
                      className={`${getClassName(
                        "specialty_id",
                        "Select Specialty",
                        watch,
                        errors
                      )}`}
                      custom
                      name="specialty_id"
                      id="specialty"
                      innerRef={register(Validation.specialty_id)}
                      onChange={onSpecialtyChange}
                    >
                      <option key="0" value="" selected disabled>
                        {i18next.t("Select Specialty")}
                      </option>
                      {RenderSelectOptions(specialties)}
                    </CSelect>
                    <CLabel className="labels required">
                      {i18next.t("Specialty Form")}
                    </CLabel>
                    <CSelect
                      className={`${getClassName(
                        "specialty_form_id",
                        "Select Specialty Form",
                        watch,
                        errors
                      )}`}
                      custom
                      name="specialty_form_id"
                      id="specialty_form_id"
                      innerRef={register(Validation.specialty_form_id)}
                      onChange={onSpecialtyFormChange}
                    >
                      <option value="" selected disabled>
                        {i18next.t("Select Specialty Form")}
                      </option>
                      {RenderSelectOptions(specialtyFormsList || [])}
                    </CSelect>
                  </CCol>
                  <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
                    <CLabel className="float-left labels">
                      {i18next.t("Note")}
                    </CLabel>
                    <CTextarea
                      name="note"
                      id="note"
                      rows="5"
                      onChange={dischargeFormChange}
                      placeholder={i18next.t("Notes")}
                      innerRef={register}
                    />
                  </CCol>
                  <CCol>
                    <SurveyForm
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      groups={specialtyForm ? specialtyForm.groups : []}
                    />
                  </CCol>
                </CFormGroup>
                {renderPreviousIcds()}
              </CCardBody>
              <div className="my-2 float-right" style={{ textAlign: "end" }}>
                <CButton type="submit" color="primary" className="my-0 mx-1">
                  {i18next.t("Next")}
                </CButton>
                <CButton
                  color="danger"
                  className="my-0 mx-1"
                  onClick={onCancel}
                >
                  {i18next.t("Cancel")}
                </CButton>
              </div>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default SpecialtyForm;
