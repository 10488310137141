import React from "react";
import { CCol, CRow, CCallout } from "@coreui/react";
import { getAge } from "helpers/helpers";

const CaseCard = ({ roundCase, patient, cardColor }) => (
  <CCallout
    color={cardColor || "sucess"}
    className="bg-secondary"
    style={{ borderLeftWidth: "20px", borderRadius: "5px", padding: "2%" }}
  >
    <CRow>
      <CCol xs="12">
        <CRow>
          <CCol className="d-flex flex-column justify-content-center  text-left">
            <strong>
              {`${patient.first_name[0].toUpperCase()}. ${patient.last_name[0].toUpperCase()}.`}
            </strong>
          </CCol>
          <CCol className="d-flex flex-column justify-content-center text-left">
            <strong>Age </strong> {getAge(patient.birth_date)}
          </CCol>
          <CCol className="d-flex flex-column justify-content-center  text-left">
            <strong>Gender </strong> {patient.gender}
          </CCol>
          <CCol className="d-flex flex-column justify-content-center  text-left">
            <strong>Location </strong> {roundCase.case_location}
          </CCol>
          <CCol className="d-flex flex-column justify-content-center  text-left">
            <strong>Date</strong>
            {roundCase.case_date}
          </CCol>
          <CCol className="d-flex flex-column justify-content-center  text-left">
            <strong>Round Consultation </strong>
            {roundCase.round_consultation_text}
          </CCol>
          <br />
        </CRow>
      </CCol>
    </CRow>
  </CCallout>
);
export default CaseCard;