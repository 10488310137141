import React from "react";
import {
  CCol,
  CInput,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupAppend,
  CInputGroupText,
  CButton,
  CFormGroup,
  CLabel,
  CContainer,
  CCardFooter,
  CImg,
  CCardSubtitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import TimeLineAppointmentCard from "../patient/screens/timeline/TimeLineAppointmentCard";
import i18next from "i18next";

export const Lobby = (props) => {
  const { children } = props;
  return (
    <>
      <CRow>
        <CCol>
          <CRow>
            <CCol xs="12">
              <CCard>
                <CCardBody>{children}</CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </>
  );
};

export const SearchCard = (props) => {
  const { children } = props;
  return (
    <>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol xs="4" float-right>
                  <CInput placeholder="Search" />
                </CCol>
              </CRow>
            </CCardHeader>
            {children}
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

// used in page 404 & 500
export const ErrorPageButtons = () => (
  <CInputGroup className="input-prepend">
    <CInputGroupPrepend>
      <CInputGroupText>
        <CIcon name="cil-magnifying-glass" />
      </CInputGroupText>
    </CInputGroupPrepend>
    <CInput size="16" type="text" placeholder="What are you looking for?" />
    <CInputGroupAppend>
      <CButton color="info">Search</CButton>
    </CInputGroupAppend>
  </CInputGroup>
);

// currently used in login & register page
export const PrependInputUser = (props) => (
  <CInputGroup className="mb-3">
    <CInputGroupPrepend>
      <CInputGroupText>
        <CIcon name="cil-user" />
      </CInputGroupText>
    </CInputGroupPrepend>
    <CInput {...props} />
  </CInputGroup>
);

export const AppointmentCardContainer = (props) => {
  const { children, title, appointmentData } = props;
  return (
    <CContainer>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CCardBody>
              <CCard>
                <CCardBody className="text-dark">
                  <TimeLineAppointmentCard appointmentData={appointmentData} />
                  <br />
                  <CCardTitle>{title}</CCardTitle>
                  {children}
                </CCardBody>
              </CCard>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export const FormCardFooter = ({ onCancel }) => (
  <CFormGroup row>
    <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
      <CButton
        type="submit"
        style={{ width: "100%" }}
        color="primary"
        className="mx-1"
      >
        {i18next.t("Save")}
      </CButton>
    </CCol>
    <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
      <CButton
        type="reset"
        style={{ width: "100%" }}
        color="danger"
        className="mx-1"
        onClick={onCancel}
      >
        {i18next.t("Cancel")}
      </CButton>
    </CCol>
  </CFormGroup>
);

export const FormWhiteCardFooter = (history) => (
  <CCardFooter>
    <CFormGroup row>
      <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
        <CButton style={{ width: "100%" }} color="primary" type="submit">
          {i18next.t("Save")}
        </CButton>
      </CCol>
      <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
        <CButton
          style={{ width: "100%" }}
          color="danger"
          type="reset"
          onClick={history.onCancel.push(history.onCancel.location.pathname)}
        >
          {i18next.t("Cancel")}
        </CButton>
      </CCol>
    </CFormGroup>
  </CCardFooter>
);

export const AppointmentDetailsRow = (props) => {
  const { appointment, imgSrc, imgStyle } = props;
  return (
    <CRow>
      <CCol xs="3">
        <CImg src={imgSrc} style={imgStyle} />
      </CCol>
      <CCol xs="9">
        <CRow>
          <CCol xs="6">
            <CRow>
              <CCol xs="12">
                <CCardTitle>{appointment.doctor}</CCardTitle>
              </CCol>
              <br />
              <CCol xs="12">
                <CCardSubtitle>{appointment.speciality}</CCardSubtitle>
              </CCol>
              <br />
              <CCol xs="12">
                <CCardSubtitle>{appointment.clinic_type}</CCardSubtitle>
              </CCol>
            </CRow>
          </CCol>
          <CCol xs="6">
            <CRow>
              <CCol xs="12">
                <CCardTitle>
                  {`${+appointment.schedule_time.split(" ")[1].split(":")[0]}` <
                  12
                    ? `${appointment.schedule_time.split(" ")[0]} ${
                        appointment.schedule_time.split(" ")[1].split(":")[0] %
                        12
                      }:${
                        appointment.schedule_time.split(" ")[1].split(":")[1]
                      } AM`
                    : `${appointment.schedule_time.split(" ")[0]} ${
                        appointment.schedule_time.split(" ")[1].split(":")[0] %
                        12
                      }:${
                        appointment.schedule_time.split(" ")[1].split(":")[1]
                      } PM`}
                </CCardTitle>
              </CCol>
              <br />
              <CCol xs="12">
                <CCardSubtitle>Price : {appointment.price}</CCardSubtitle>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  );
};
