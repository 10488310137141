import React from "react";
import ReactFlow, { MiniMap, Controls, Background } from "react-flow-renderer";
import { hierarchyToReactFlow } from "helpers/helpers";

const nodeStyle = {
  background: "#fff",
  color: "#3f5192",
  padding: "25px",
  borderRadius: "8px",
  border: "1px solid #000",
  width: "250px",
  height: "150px",
  display: "flex",
  flexDirection: "column",
};

const textStyle = {
  fontSize: "12px",
  gap: "5px",
  textAlign: "left",
};

const createNodeLabel = (data, type) => (
  <div style={nodeStyle}>
    <div style={{ fontWeight: "bold" }}>{type}: {data.provider_name}</div>
    <div style={textStyle}>
      <div>Total Orders: {data.total_orders}</div>
      <div>Today's Orders: {data.todays_orders}</div>
      <div>(Today Executed/Pending)</div>
    </div>
  </div>
);

const LabFigures = ({ dashboard }) => {
  let all_nodes = [];
  let all_edges = [];
  if (dashboard.data.labs) {
    const hierarchy = {...dashboard.data.labs, provider_id: 0, hospitals: Object.values(dashboard.data_for_hospitals.labs)};
    const {nodes, edges} = hierarchyToReactFlow(hierarchy, node => createNodeLabel(node, 'lab'), 330, 200, 0, 50, 'provider_id', 'lab');
    all_nodes = all_nodes.concat(nodes);
    all_edges = all_edges.concat(edges);
  }
  if (dashboard.data.radiology) {
    const hierarchy = {...dashboard.data.radiology, provider_id: 0, hospitals: Object.values(dashboard.data_for_hospitals.radiology)};
    const {nodes, edges} = hierarchyToReactFlow(hierarchy, node => createNodeLabel(node, 'radiology'), 330, 200, 990, 50, 'provider_id', 'rad');
    all_nodes = all_nodes.concat(nodes);
    all_edges = all_edges.concat(edges);
  }
  console.log(all_nodes, all_edges);
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <ReactFlow key={'lab'} nodes={all_nodes} edges={all_edges} fitView>
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default LabFigures;
