import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CHeaderNavLink } from "@coreui/react";
import { useTranslation } from 'react-i18next';
import {ENGLISH_TEXT_CODE, ARABIC_TEXT_CODE, TOGGLE_LANGUAGE} from "../constants/constants";

const Language = () => {
  const {i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  let defaultFlag = "عربي";
  if (document.dir === "rtl") {
    defaultFlag = "English";
  }
  const [flag, setFlag] = useState(defaultFlag);
  const toggleLanguage = () => {
    if (language === ENGLISH_TEXT_CODE) {
      setFlag("English");
      document.dir = "rtl";
      i18n.changeLanguage(ARABIC_TEXT_CODE);
      dispatch({ type: TOGGLE_LANGUAGE, payload: ARABIC_TEXT_CODE });
    } else {
      setFlag("عربي");
      document.dir = "ltr";
      i18n.changeLanguage(ENGLISH_TEXT_CODE);
      dispatch({ type: TOGGLE_LANGUAGE, payload: ENGLISH_TEXT_CODE });
    }
  };

return <CHeaderNavLink onClick={toggleLanguage}>{flag}</CHeaderNavLink>;
};

export default Language;
