import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import i18next from "i18next";
import "../../../scss/style.scss";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CCard,
  CCardBody,
  CSelect,
  CInputRadio,
  CTextarea,
  CCardHeader,
  CCardFooter,
  CButton,
  CSpinner,
} from "@coreui/react";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import Select from "react-select";
import { TimeZoneSelect } from "views/partials/AsyncSelectComponents";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { CLINIC_TYPE_PHYSICAL, CLINIC_TYPE_VIRTUAL } from "constants/clinic";
import ClinicOccurrences from "./ClinicOccurrences";
import getValidation from "./ClinicFormValidation";
import { getClassName } from "views/pages/register/RegisterFormValidation";
import { useHistory } from "react-router-dom";

const Clinic = ({
  onSubmit,
  clinic,
  levels,
  label,
  specialties,
  time_units,
  hospitals,
  handleSpecialtyChange,
  handleLevelChange,
  handleSpokeChange,
  doctors,
  coordinators,
}) => {
  let defaultValues = {
    start_date: new Date(),
    end_date: new Date(2100, 1, 1),
  };
  if (clinic) {
    defaultValues = {
      ...clinic,
      start_date: parseISO(clinic.start_date),
      end_date: clinic.end_date ? parseISO(clinic.end_date) : null,
    };
  }
  const { register, handleSubmit, control, setValue, watch, errors ,formState} = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const [clinicType, setClinicType] = useState(false);
  const Validation = getValidation(watch);
  const { isSubmitting } = formState;

  const handelOnchange = (e) => {
    setClinicType(e.target.value === CLINIC_TYPE_PHYSICAL);
  };

  const renderAddress = () => {
    if (clinicType || (clinic && clinic.type === CLINIC_TYPE_PHYSICAL)) {
      return (
        <CCol md="12" style={{ height: "auto" }}>
          <CLabel className="labels" htmlFor="address">
            {i18next.t("Address")}
          </CLabel>
          <CTextarea id="address" name="address" innerRef={register} />
        </CCol>
      );
    }
    return "";
  };
  useEffect(() => {
    if (
      !clinic ||
      (clinic && clinic.coordinators && !clinic.coordinators.length)
    ) {
      setValue("coordinators", coordinators);
    }
  }, [coordinators, clinic, setValue]);

  const history = useHistory();
  const onCancel = () => {
    history.push("/clinics");
  };
  return (
    <>
      <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
        <CCard className="m-0 p-0">
          <CCardHeader>
            <CCol xs="12">
              <h4 className="header-label-title">{label}</h4>
            </CCol>
          </CCardHeader>
          <CCardBody style={{ fontSize: "smaller" }}>
            <CRow>
              <CCol xs="12">
                <CRow>
                  <CCol xs="12">
                    <h4 className="header-label">{i18next.t("Clinic Info")}</h4>
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required">{i18next.t("Title")}</CLabel>
                    <CInput
                      className={`mb-3 ${getClassName(
                        "title",
                        "title",
                        watch,
                        errors
                      )}`}
                      type="text"
                      id="title"
                      name="title"
                      innerRef={register(Validation.title)}
                    />
                  </CCol>

                  <CCol xs="6">
                    <CLabel className="labels required">{i18next.t("Location")}</CLabel>
                    <CSelect
                      className={`mb-3 ${getClassName(
                        "hospital_id",
                        "hospital_id",
                        watch,
                        errors
                      )}`}
                      custom
                      name="hospital_id"
                      id="hospital_id"
                      innerRef={register(Validation.hospital_id)}
                      onChange={handleSpokeChange}
                    >
                      <option value="" selected disabled>
                        {i18next.t("Select Location")}
                      </option>
                      {RenderSelectOptions(hospitals)}
                    </CSelect>
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required">{i18next.t("Experience")}</CLabel>
                    <CSelect
                      custom
                      className={`mb-3 ${getClassName(
                        "level_id",
                        "level_id",
                        watch,
                        errors
                      )}`}
                      name="level_id"
                      id="level_id"
                      innerRef={register(Validation.level_id)}
                      onChange={handleLevelChange}
                    >
                      <option key="0" value="" selected disabled>
                        {i18next.t("Select Experience")}
                      </option>
                      {RenderSelectOptions(levels)}
                    </CSelect>
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required">{i18next.t("Clinic Specialty")}</CLabel>
                    <CSelect
                      custom
                      className={`mb-3 ${getClassName(
                        "specialty_id",
                        "specialty_id",
                        watch,
                        errors
                      )}`}
                      name="specialty_id"
                      id="specialty_id"
                      onChange={handleSpecialtyChange}
                      innerRef={register(Validation.specialty_id)}
                    >
                      <option key="0" value="" selected disabled>
                        {i18next.t("Select Specialty")}
                      </option>
                      {RenderSelectOptions(specialties)}
                    </CSelect>
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required">{i18next.t("Doctor")}</CLabel>
                    <CSelect
                      className={`mb-3 ${getClassName(
                        "doctor_id",
                        "doctor_id",
                        watch,
                        errors
                      )}`}
                      custom
                      name="doctor_id"
                      id="doctor_id"
                      innerRef={register(Validation.doctor_id)}
                    >
                      {RenderSelectOptions(doctors)}
                    </CSelect>
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required" htmlFor="price">
                      {i18next.t("Price")}
                    </CLabel>
                    <CInput
                      className={`mb-3 ${getClassName(
                        "price",
                        "price",
                        watch,
                        errors
                      )}`}
                      type="number"
                      id="price"
                      name="price"
                      min={0}
                      innerRef={register(Validation.price)}
                    />
                  </CCol>
                  <CCol xs="6">
                    <CLabel
                      className="d-flex flex-column labels"
                      style={{ textAlign: "initial", color: "#35488d" }}
                    >
                      {i18next.t("Coordinator")}
                    </CLabel>
                    <Controller
                      rules={{
                        required: "at least one Coordinator is required.",
                      }}
                      className={`mb-3 ${getClassName(
                        "coordinators",
                        "coordinators",
                        watch,
                        errors
                      )}`}
                      control={control}
                      id="drug"
                      name="coordinators"
                      cacheOptions
                      autocomplete
                      isMulti
                      options={coordinators}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      as={Select}
                    />
                    <span className="alert-danger">
                      {errors.coordinators && errors.coordinators.message}
                    </span>
                  </CCol>
                  <CCol xs="6" className="d-flex flex-column">
                    <CLabel className="labels required">{i18next.t("Start Date")}</CLabel>
                    <Controller
                      control={control}
                      rules={Validation.start_date}
                      name="start_date"
                      valueName="selected"
                      render={(props) => (
                        <DatePicker
                          minDate={new Date()}
                          maxDate={watch("end_date")}
                          className={`form-control ${getClassName(
                            "start_date",
                            "start_date",
                            watch,
                            errors
                          )}`}
                          onChange={(e) => props.onChange(e)}
                          selected={props.value}
                          dateFormat="dd/MM/yyyy"
                          />
                      )}
                    />
                  </CCol>
                  <CCol xs="6" className="d-flex flex-column mb-3">
                    <CLabel className="labels required">{i18next.t("End Date")}</CLabel>
                    <Controller
                      control={control}
                      rules={Validation.end_date}
                      name="end_date"
                      valueName="selected"
                      render={(props) => (
                        <DatePicker
                          minDate={watch("start_date")}
                          className={`form-control ${getClassName(
                            "end_date",
                            "end_date",
                            watch,
                            errors
                          )}`}
                          onChange={(e) => props.onChange(e)}
                          selected={props.value}
                          dateFormat="dd/MM/yyyy"
                          />
                      )}
                    />
                  </CCol>
                  <CCol xs="6" className="d-flex flex-column mb-3">
                    <TimeZoneSelect
                      label={i18next.t("Time Zone")}
                      control={control}
                      rules={Validation.time_zone}
                      name="time_zone"
                      isValid={!errors.time_zone}
                    />
                  </CCol>
                  <CCol xs="6" className="d-inline-flex">
                    <CLabel className="labels clinic-type required">{i18next.t("Clinic Type")}</CLabel>

                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio
                        className={getClassName("type", "type", watch, errors)}
                        custom
                        id="virtual"
                        name="type"
                        value={CLINIC_TYPE_VIRTUAL}
                        innerRef={register(Validation.type)}
                        onClick={(e) => handelOnchange(e)}
                      />
                      <CLabel variant="custom-checkbox" htmlFor="virtual">
                        {i18next.t("Virtual")}
                      </CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio
                        className={getClassName("type", "type", watch, errors)}
                        custom
                        id="physical"
                        name="type"
                        value={CLINIC_TYPE_PHYSICAL}
                        innerRef={register(Validation.type)}
                        onClick={(e) => handelOnchange(e)}
                      />
                      <CLabel variant="custom-checkbox" htmlFor="physical">
                        {i18next.t("Physical")}
                      </CLabel>
                    </CFormGroup>
                    <CFormGroup row>{renderAddress()}</CFormGroup>
                  </CCol>
                  <CCol xs="12" style={{ "margin-top": "2.5em" }}>
                    <h4 className="header-label">{i18next.t("Follow Up")}</h4>
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required" htmlFor="follow_up_price">
                      {i18next.t("Follow Up Price")}
                    </CLabel>
                    <CInput
                      className={getClassName(
                        "follow_up_price",
                        "follow_up_price",
                        watch,
                        errors
                      )}
                      type="number"
                      min={0}
                      id="follow_up_price"
                      name="follow_up_price"
                      innerRef={register(Validation.follow_up_price)}
                    />
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required" htmlFor="follow_up_period">
                      {i18next.t("Follow Up Period")}
                    </CLabel>
                    <CInput
                      className={getClassName(
                        "follow_up_period",
                        "follow_up_period",
                        watch,
                        errors
                      )}
                      type="number"
                      min={0}
                      id="follow_up_period"
                      name="follow_up_period"
                      innerRef={register(Validation.follow_up_period)}
                    />
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required">{i18next.t("Follow Up Unit")}</CLabel>
                    <CSelect
                      className={`mb-3 ${getClassName(
                        "follow_up_unit",
                        "follow_up_unit",
                        watch,
                        errors
                      )}`}
                      custom
                      name="follow_up_unit"
                      id="follow_up_unit"
                      innerRef={register(Validation.follow_up_unit)}
                    >
                      <option key="0" value="" selected disabled>
                        {i18next.t("Select Time")}
                      </option>
                      {RenderSelectOptions(time_units)}
                    </CSelect>
                  </CCol>
                  <CCol xs="12" style={{ "margin-top": "2.5em" }}>
                    <h4 className="header-label">
                      {i18next.t("Clinic Cancellation Tolerance")}
                    </h4>
                  </CCol>
                  <CCol xs="6">
                    <CLabel
                      className="labels required"
                      htmlFor="physician_cancellation_tolerance"
                    >
                      {i18next.t("Physician")}
                    </CLabel>
                    <CInput
                      className={`mb-3 ${getClassName(
                        "physician_cancellation_tolerance",
                        "physician_cancellation_tolerance",
                        watch,
                        errors
                      )}`}
                      type="number"
                      min={0}
                      id="physician_cancellation_tolerance"
                      name="physician_cancellation_tolerance"
                      innerRef={register(
                        Validation.physician_cancellation_tolerance
                      )}
                    />
                  </CCol>

                  <CCol xs="6">
                    <CLabel className="labels required">{i18next.t("Physician Unit")}</CLabel>
                    <CSelect
                      className={`mb-3 ${getClassName(
                        "physician_tolerance_unit",
                        "physician_tolerance_unit",
                        watch,
                        errors
                      )}`}
                      custom
                      name="physician_tolerance_unit"
                      id="physician-unit"
                      innerRef={register(Validation.physician_tolerance_unit)}
                    >
                      <option key="0" selected disabled>
                        {i18next.t("Select Unit")}
                      </option>
                      {RenderSelectOptions(time_units)}
                    </CSelect>
                  </CCol>
                  <CCol xs="6">
                    <CLabel
                      className="labels required"
                      htmlFor="patient_cancellation_tolerance"
                    >
                      {i18next.t("Patient")}
                    </CLabel>
                    <CInput
                      className={`mb-3 ${getClassName(
                        "patient_cancellation_tolerance",
                        "patient_cancellation_tolerance",
                        watch,
                        errors
                      )}`}
                      type="number"
                      min={0}
                      id="patient_cancellation_tolerance"
                      name="patient_cancellation_tolerance"
                      innerRef={register(
                        Validation.patient_cancellation_tolerance
                      )}
                    />
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required">{i18next.t("Patient Unit")}</CLabel>
                    <CSelect
                      className={`mb-3 ${getClassName(
                        "patient_tolerance_unit",
                        "patient_tolerance_unit",
                        watch,
                        errors
                      )}`}
                      custom
                      name="patient_tolerance_unit"
                      id="patient-unit"
                      innerRef={register(Validation.patient_tolerance_unit)}
                    >
                      <option key="0" selected disabled>
                        {i18next.t("Select Unit")}
                      </option>
                      {RenderSelectOptions(time_units)}
                    </CSelect>
                  </CCol>
                  <CCol xs="12" style={{ "margin-top": "2.5em" }}>
                    <h4 className="header-label">{i18next.t("Clinic last appointment")}</h4>
                  </CCol>
                  <CCol xs="6">
                    <CLabel
                      className="labels required"
                      htmlFor="last_appointment_tolerance"
                    >
                      {i18next.t("Last Appointment Tolerance")}
                    </CLabel>
                    <CInput
                      className={`mb-3 ${getClassName(
                        "last_appointment_tolerance",
                        "last_appointment_tolerance",
                        watch,
                        errors
                      )}`}
                      type="number"
                      id="last_appointment_tolerance"
                      name="last_appointment_tolerance"
                      min={-100}
                      innerRef={register(Validation.last_appointment_tolerance)}
                    />
                  </CCol>
                  <CCol xs="6">
                    <CLabel className="labels required">{i18next.t("Tolerance Unit")}</CLabel>
                    <CSelect
                      className={`mb-3 ${getClassName(
                        "last_appointment_unit",
                        "last_appointment_unit",
                        watch,
                        errors
                      )}`}
                      custom
                      name="last_appointment_unit"
                      id="last-appointment-unit"
                      innerRef={register(Validation.last_appointment_unit)}
                    >
                      <option key="0" selected disabled>
                        {i18next.t("Select Unit")}
                      </option>
                      {RenderSelectOptions(time_units)}
                    </CSelect>
                  </CCol>
                </CRow>
              </CCol>
              <CCol xs="12" style={{ "margin-top": "3.5em" }}>
                <CRow>
                  <CCol xs="12">
                    <h4 className="header-label">{i18next.t("Appointment Basic Info")}</h4>
                  </CCol>
                  <CCol xs="12">
                    <ClinicOccurrences
                      register={register}
                      clinic={clinic}
                      watch={watch}
                      Validation={Validation}
                      getClassName={getClassName}
                      setValue={setValue}
                      errors={errors}
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CFormGroup row>
              <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                <CButton
                  style={{ width: "100%" }}
                  color="primary"
                  type="submit"
                  disabled={ isSubmitting }
                  >
                    {isSubmitting ? (
                  <>
                    <CSpinner color="primary" size="sm" variant="grow" />
                    <span style={{ color: "dark" }}>{i18next.t("Loading")}...</span>
                  </>
                ) : (
                  <span>{i18next.t("Save")}</span>
                )}
                </CButton>
              </CCol>
              <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                <CButton
                  style={{ width: "100%" }}
                  color="danger"
                  type="reset"
                  onClick={onCancel}
                >
                  {i18next.t("Cancel")}
                </CButton>
              </CCol>
            </CFormGroup>
          </CCardFooter>
        </CCard>
      </CForm>
    </>
  );
};

export default Clinic;
