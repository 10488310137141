import { Api, getTokenHeader } from "services/Api";
import { FETCH_MATCHED_SURVEYES,FETCH_MATCHED_SURVEYE } from "./types";


export const fetchMatchedSurveys  = (
    page: string,
    limit: string,
  ): Object => async (dispatch) => {
    const response = await Api.get(`/api/admin/list-sent-surveys?page=${page}&limit=${limit}`, {
        ...getTokenHeader(),

    });
  
    dispatch({ type: FETCH_MATCHED_SURVEYES, payload: response.data });
  };


  export const fetchMatchedSurvey  =(id: string): Object => async(dispatch) => {
    const response = await Api.get(`/api/admin/matched-survey/${id}`, {
        ...getTokenHeader(),

    });
  
    dispatch({ type: FETCH_MATCHED_SURVEYE, payload: response.data });
  };




