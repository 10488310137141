import React from "react";
import { useDispatch } from "react-redux";
import { CButton } from "@coreui/react";
import { closeEpisode } from "actions/episodes/episodes";
import { PRESCRIPTION } from "constants/constants";
import i18next from "i18next";

const End = ({ episode, onCancel, setActiveScreen }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeEpisode(episode.id));
  };

  return (
    <>
      <div className="my-2 float-right">
        <CButton
          style={{
            backgroundColor: "#30c5b6",
            borderColor: "#30c5b6",
            color: "white",
            width:"7em"
          }}
          className="mx-1"
          onClick={() => setActiveScreen(PRESCRIPTION)}
        >
          {i18next.t("Previous")}
        </CButton>
        <CButton color="primary" className="mx-1" onClick={handleClose}>
          {i18next.t("Done")}
        </CButton>
        <CButton color="danger" className="mx-1" onClick={onCancel}>
        {i18next.t("Cancel")}
        </CButton>
      </div>
    </>
  );
};
export default End;
