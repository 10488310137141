import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CLabel,
  CRow,
  CContainer,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Avatar from "views/partials/Avatar";
import { logout } from "actions/auth/auth";
import i18next from "i18next";

const TheHeaderDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const avatar = useSelector((state) =>
    state.auth.jwt ? state.auth.jwt.user.avatar : ""
  );
  const user_full_name = useSelector((state) => {
    const user = state.auth.jwt
      ? state.auth.jwt.user
      : { first_name: "", last_name: "" };
    return `${user.first_name} ${user.last_name}`;
  });
  const handleLogout = () => {
    dispatch(logout());
  };
  const showProfile = () => {
    history.push("/profile/edit");
  };
  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CContainer>
          <CRow style={{ justifyContent: "center" }}>
            <div className="c-avatar">
              <Avatar src={avatar} className="c-avatar-img" />
            </div>
          </CRow>
          <CRow>{user_full_name}</CRow>
        </CContainer>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem onClick={showProfile}>
          <CIcon name="cil-user" className="mfe-2" />
          {i18next.t("Edit Profile")}
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem onClick={handleLogout}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          {i18next.t("Sign Out")}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
