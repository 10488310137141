import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CCol, CRow, CCard, CCardBody, CButton, CAlert } from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { logPaymentResponse } from "actions/payments/payments";
import { editAppointment } from "actions/appointments/appointment";
import { MODEL_TYPE_APPOINTMENT, PAYMENT_STATUS } from "constants/constants";
import { resetPatientAppointmentSelection } from "actions/appointments/patientAppointment";

const FawryDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const patientAppointmentSelection = useSelector(
    (state) => state.patientAppointmentSelection
  );
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const referenceNumber = search.match(/referenceNumber=([0-9]+)/, "");
  const orderAmount = search.match(/orderAmount=([0-9]+)/, "");
  const orderStatus = search.match(/orderStatus=([a-zA-Z]+)/, "");
  const statusDesc = query.get("statusDescription");
  const handleNextButton = () => {
    if (referenceNumber) {
      dispatch(resetPatientAppointmentSelection());
      history.push(`/patient/appointments`);
    }
  };

  useEffect(() => {
    if (referenceNumber) {
      dispatch(
        logPaymentResponse({
          model_id: patientAppointmentSelection.appointment_id,
          model_type: MODEL_TYPE_APPOINTMENT,
          response: search,
        })
      );
    }
    if (orderStatus) {
      dispatch(
        editAppointment(patientAppointmentSelection.appointment_id, {
          payment_status: PAYMENT_STATUS[orderStatus[1]],
        })
      );
    }
  }, [referenceNumber, orderStatus]);

  return (
    <>
      <CRow>
        <CCol lg={12}>
          <CCard>
            <CCardBody>
              <h4 className="table-title">Payment Details</h4>
              <table className="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td className="labels">Amount</td>
                    <td>
                      <strong>{orderAmount ? orderAmount[1] : ""}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="labels">Reference Number</td>
                    <td>
                      <strong>
                        {referenceNumber ? (
                          referenceNumber[1]
                        ) : (
                          <CAlert color="danger">
                            Payment has been failed due to {statusDesc}
                          </CAlert>
                        )}
                      </strong>
                    </td>
                  </tr>
                  <tr className="text-align-center">
                    <td></td>
                    <td>
                      <CButton onClick={handleNextButton} color="primary">
                        Next
                      </CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
export default FawryDetails;
