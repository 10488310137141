import React, { useState } from "react";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInputCheckbox,
  CInput,
} from "@coreui/react";

const SurveyCheckboxQuestion = (props) => {
  const {
    question,
    register,
    setValue,
    watch,
    questionName,
    questionValueName,
  } = props;
  const answerInitialValue = watch ? watch(questionValueName) : 0;
  const [answerTotalValue, setAnswerTotalValue] = useState(
    answerInitialValue ? Number(answerInitialValue) : 0
  );
  const calculateAnswerValue = (event) => {
    let total = answerTotalValue || answerInitialValue;
    setAnswerTotalValue(total);
    const ansObj = JSON.parse(event.target.value);
    const ansValue = Number(ansObj.value);
    let newTotal;
    if (isNaN(ansValue)) {
      // Concatenate string answers
      let values=[];
      if(total){
        values = total.split(",");
      }
      if (event.target.checked) {
        values.push(ansObj.value);
      } else {
        const index = values.indexOf(ansObj.value);
        if (index !== -1) {
          values.splice(index, 1);
        }
      }
      newTotal = values.join(",");
      setValue(questionValueName, newTotal);

    } else {
      // Sum numeric answers

      const numericValue = Number(ansValue) || 0;
      total = Number(total) || 0;

      newTotal = event.target.checked
        ? total + numericValue
        : total - numericValue;
      setValue(questionValueName, numericValue);
    }
    setAnswerTotalValue(newTotal);
  };
  const renderAnswers = (answers) =>
    answers.map((answer) => (
      <CCol key={question.value} inline>
        <CInputCheckbox
          data-label={answer.label}
          id={`${question.id}-${answer.value}`}
          name={questionName}
          value={JSON.stringify({ label: answer.label, value: answer.value })}
          innerRef={register}
          onClick={calculateAnswerValue}
        />
        <CLabel
          variant="checkbox"
          className="form-check-label labels"
          htmlFor={`${question.id}-${answer.value}`}
        >
          {answer.label}
        </CLabel>
      </CCol>
    ));

  return (
    <CCol md={5} className="d-flex flex-column px-0">
      <br />
      <h6 className="float-left w-fcontent">{`${question.question}`}</h6>
      <CFormGroup className="mx-0 px-3">
        <CInput type="hidden" innerRef={register} name={questionValueName} />
        {renderAnswers(question.answers)}
      </CFormGroup>
    </CCol>
  );
};

export default SurveyCheckboxQuestion;
