import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCol,
  CCard,
  CCardBody,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDataTable,
  CPagination,
} from "@coreui/react";
import { fetchPastEpisodes } from "actions/past-medical-history/pastMedicalHistory";
import { cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import VisitCard from "./VisitCard";
import DischargeFormSummary from "./DischargeFormSummary";
import i18next from "i18next";

const render = (data) =>
  data.map((item) => (
    <CCol xs="12" md="4">
      <CLink href="#" style={{ textDecoration: "none", color: "inherit" }}>
        <VisitCard visit={item} />
      </CLink>
    </CCol>
  ));
const PastEpisodes = ({ patientId, setActiveScreen }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [episode, setEpisode] = useState(null);
  const episodes = useSelector((state) =>
    state.pastMedicalHistory.episodes
      ? state.pastMedicalHistory.episodes.data
      : []
  );
  const meta = useSelector((state) =>
    state.pastMedicalHistory.episodes
      ? state.pastMedicalHistory.episodes.meta
      : null
  );
  const showSummary = (item: Object) => {
    setEpisode(item);
    setShowModal(true);
  };

  const pageChange = (newPage) => {
    dispatch(fetchPastEpisodes(patientId, newPage));
    setPage(newPage);
  };

  const renderActions = (item: Object) => (
    <td>
      <CDropdown variant="btn-group">
        <CDropdownToggle
          className="action-toggle m-0"
          color="light"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="c-icon"
            role="img"
          >
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"
              class="ci-primary"
            ></path>
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"
              class="ci-primary"
            ></path>
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"
              class="ci-primary"
            ></path>
          </svg>
        </CDropdownToggle>
        <CDropdownMenu className="p-0" placement="right-end">
          <CDropdownItem
            onClick={() => {
              showSummary(item);
            }}
            className="text-info"
          >
            <CIcon content={cilSearch} />
            <span class="mx-1">{i18next.t("show")}</span>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </td>
  );
  useEffect(() => {
    dispatch(fetchPastEpisodes(patientId));
  }, [dispatch, patientId]);

  return (
    // <CCard>
    //   {/* <CCardHeader>
    //     <CRow>
    //       <CCol md={6}>Past Episodes</CCol>
    //       <CCol md={6}>
    //         <CLink onClick={() => setActiveScreen(null)}>History</CLink>
    //       </CCol>
    //     </CRow>
    //   </CCardHeader> */}
    //   <CCardBody>
    //     <CRow >{render(episodes)}</CRow>
    //   </CCardBody>
    // </CCard>

    <CCol xs="12">
      <CCard className="past-medical-history-col-mobile">
        <CCardBody>
          <CDataTable
            items={episodes}
            noItemsViewSlot={i18next.t("No Available Items")}
            fields={[
              // { key: "author", label: "Locations" },
              { key: "author", label: i18next.t("locations") },
              { key: "actions", label:  i18next.t("Actions") },
            ]}
            striped
            scopedSlots={{
              author: (item) => (
                <td>
                  <p className="episode-bold-text">{`${item.spock.name}`} </p>{" "}
                  <p>{`${item.created_at}`}</p>
                </td>
              ),
              actions: renderActions,
            }}
          />
        </CCardBody>
      </CCard>
      <DischargeFormSummary
        episode={episode}
        setShowModal={setShowModal}
        showModal={showModal}
      />

      <CCard className="past-medical-history-col-desktop">
        <CCardBody>
          <CDataTable
            items={episodes}
            fields={[
              { key: "author", label: i18next.t("locations") },
              { key: "Date", label: i18next.t("date") },
              { key: "actions", label: i18next.t("Actions") },
            ]}
            noItemsViewSlot={i18next.t("No Available Items")}
            striped
            scopedSlots={{
              author: (item) => (
                <td className="episode-bold-text">{`${item.spock.name}`}</td>
              ),
              Date: (item) => <td>{`${item.created_at}`}</td>,
              actions: renderActions,
            }}
          />
          <CPagination
            activePage={page}
            onActivePageChange={pageChange}
            pages={meta ? meta.last_page : 1}
            doubleArrows={false}
            align="center"
          />
        </CCardBody>
      </CCard>
    </CCol>
  );
};
export default PastEpisodes;
