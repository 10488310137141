import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CTextarea,
  CCardTitle,
  CSelect,
} from "@coreui/react";
import {
  getClassName,
  getValidation,
} from "views/pages/register/RegisterFormValidation";
import i18next from "i18next";
import Select from "react-select";
import { customStyles } from "views/rounds/form/RoundFormValidation";

const InvestigationForm = ({
  onSubmit,
  onCancel,
  modal,
  FormTitle,
  investigation,
  investigationTypes,
}) => {
  const { register, innerRef, handleSubmit, reset, watch, errors, control } =
    useForm({
      mode: "onBlur",
    });

  const Validation = getValidation(watch);
  useEffect(() => {
    if (investigation) {
      reset(investigation);
    } else {
      reset({ date: new Date().toISOString().substr(0, 10) });
    }
  }, [investigation, reset]);

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CInput type="hidden" name="episode_id" innerRef={register} value="1" />
      <CModal show={modal} size="lg">
        <CCardTitle className="header-label-title">{FormTitle}</CCardTitle>
        <CModalBody>
          <CFormGroup row>
            <CCol xs={12} md={6} lg={6} xl={6}>
              <CLabel className="labels required">{i18next.t("Type")}</CLabel>
              {/* <Select
                required
                options={investigationTypes}
                onClick={h()}
                isMulti
                rules={{ required: 'Please select an option'}}

                styles={{color:"red"}}
                placeholder="Select Type"
                id="investigation_types"
                name="investigation_types"
                register={register}
                innerRef={register(Validation.require)}
              > */}
              {/* {RenderSelectOptions(investigationTypes || [])} */}
              <Controller
                control={control}
                id="investigation_types"
                name="investigation_types"
                isMulti
                options={investigationTypes}
                rules={{ required: "please choose an option" }}
                styles={customStyles(!errors.investigation_types)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                as={Select}
              />
            </CCol>
            <CCol xs={12} md={6} lg={6} xl={6}>
              <CLabel className="labels">{i18next.t("date")}</CLabel>
              <CInput
                className={`${getClassName(
                  "date",
                  i18next.t("date"),
                  watch,
                  errors
                )}`}
                type="date"
                id="date"
                name="date"
                placeholder={`${i18next.t("date")}`}
                innerRef={register(Validation.require)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} md={12} lg={12} xl={12}>
              <CLabel className="labels">{i18next.t("Order Notes")}</CLabel>
              <CTextarea
                id="order_notes"
                name="order_notes"
                innerRef={register}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
             {i18next.t("Save")}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default InvestigationForm;
