import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { createVacation } from "actions/vacations/vacations";
import VacationForm from "../form/VacationForm";
import i18next from "i18next";

/**
 * @returns {*} vacation form
 * @class
 */
function CreateVacation() {
  const history = useHistory();
  const label = `${i18next.t("Add New Vacation")}`;
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.vacations.redirect);
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      date_from: format(formData.date_from, "Y-MM-dd"),
      date_to: format(formData.date_to, "Y-MM-dd"),
      doctor_id: formData.doctor ? formData.doctor.id : null,
      hospital_id: formData.hospital ? formData.hospital.id : null,
      clinic_id: formData.clinic ? formData.clinic.id : null,
      country_id: formData.country ? formData.country.id : null,
    };
    dispatch(createVacation(data));
  };

  if (redirect) {
    history.push("/vacations");
  }

  return (
    <>
      <VacationForm onSubmit={onSubmit} label={label} />
    </>
  );
}

export default CreateVacation;
