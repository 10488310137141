import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CSelect,
} from "@coreui/react";
import Select from "react-select";
import { customStyles } from "views/rounds/form/RoundFormValidation";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import i18next from "i18next";

const StaffForm = (props) => {
  const {
    onSubmit,
    modal,
    onCancel,
    rolesList,
    handleSelectRoleChange,
    excludedUsers,
  } = props;
  const { handleSubmit, register, control, setValue, watch, errors } = useForm({
    mode: "onBlur",
  });

  const Validation = getValidation(watch);

  const renderRoleOptions = () =>
  rolesList && rolesList.map((item) => (
    <option key={item.id} value={item.code}>
        {item.name}
      </option>
    ));

  const onRoleChange = (event) => {
    setValue("users", null);
    handleSelectRoleChange(event);
  };

  return (
    <CForm
      onSubmit={handleSubmit(onSubmit)}
      method="post"
      className="form-horizontal"
    >
      <CModal show={modal} size="lg">
        <h4 className="header-label-title">{i18next.t("Add New Members To Staff")}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
              <CLabel htmlFor="role" className="required">{i18next.t("Role")}</CLabel>
              <CSelect
                className={`${getClassName("role", "role", watch, errors)}`}
                innerRef={register(Validation.require)}
                id="role"
                name="role"
                defaultValue={0}
                onChange={onRoleChange}
              >
                <option key="0" value="" selected disabled>
                  {i18next.t("Select Role")}
                </option>
                {renderRoleOptions()}
              </CSelect>
            </CCol>
            <CCol xs={12} sm={12} md={12} lg={6} xl={6}>
              <CLabel htmlFor="users" className="required">{i18next.t("User")}</CLabel>
              <Controller
                control={control}
                id="users"
                name="users"
                isMulti
                styles={customStyles(!errors.users)}
                rules={Validation.require}
                cacheOptions
                options={excludedUsers}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                getOptionValue={(option) => option.id}
                defaultValue={null}
                as={Select}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
            {i18next.t("Save")}
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  );
};

export default StaffForm;
