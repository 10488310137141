import React, { useEffect } from "react";
import i18next from "i18next";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
  CImg,
  CLink,
  CCardFooter,
  CButton,
  CLabel,
} from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAppointment } from "actions/appointments/patientAppointment";

const Cancel = (e) => {
  console.log(e);
};
const renderStartButton = (appointment) => {
  const { can_start, starts_in, id } = appointment;
  if (can_start) {
    return (
      <>
        <CCol xs="3" md="3" />
        <CCol xs="4" md="7">
          <CLink
            href={`/patient/clinic/lobby/check/${id}`}
            style={{ marginLeft: "70px" }}
          >
            <CButton className="d-color">Start</CButton>
          </CLink>
        </CCol>
      </>
    );
  }
  return (
    <>
      <CCol xs="3" />
      <CCol xs="7">
        <CCardSubtitle className="bg-secondary py-3 text-center font-weight-bold">
          {starts_in}
        </CCardSubtitle>
      </CCol>
    </>
  );
};
const render = (appointment) => (
  <CCard>
    <CCardBody>
      <CRow>
        <CCol xs="3" md="2">
          <CImg
            src="https://picsum.photos/200/300?random=5"
            style={{ width: "80%", height: "80%", borderRadius: "50%" }}
          />
        </CCol>
        <CCol xs="9" md="9">
          <CCol xs="12">
            <CCardTitle className="text-primary">
              Doctor Name: {appointment.doctor}
            </CCardTitle>
          </CCol>
          <CRow>
            <CCol xs="6">
              <CCardSubtitle className="text-primary">
                Speciality: {appointment.speciality}
              </CCardSubtitle>
            </CCol>
            <CCol xs="6">
              <CCardSubtitle className="text-primary">
                Clinic Type: {appointment.clinic_type}
              </CCardSubtitle>
            </CCol>
            <br />
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="3" />
        <CCol xs="7">
          <CCardSubtitle className="bg-dark py-3 text-center font-weight-bold">
            Appointment Time :{" "}
            {`${+appointment.schedule_time.split(" ")[1].split(":")[0]}` < 12
              ? `${appointment.schedule_time.split(" ")[0]} ${
                  appointment.schedule_time.split(" ")[1].split(":")[0] % 12
                }:${appointment.schedule_time.split(" ")[1].split(":")[1]} AM`
              : `${appointment.schedule_time.split(" ")[0]} ${
                  appointment.schedule_time.split(" ")[1].split(":")[0] % 12
                }:${appointment.schedule_time.split(" ")[1].split(":")[1]} PM`}
          </CCardSubtitle>
        </CCol>
      </CRow>
      <br />
      <CRow>
        <CCol xs="3" />
        <CCol xs="7">
          <CCard>
            <CCardBody className="text-align-center">
              <CCardSubtitle>
                Appointment Price : {appointment.price}
              </CCardSubtitle>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>{renderStartButton(appointment)}</CRow>
    </CCardBody>
    <CCardFooter style={{ "text-align": "end" }}>
      <CLink href="/patient/appointments">
        <CButton color="primary" onClick={(e) => Cancel(e)}>
          {i18next.t("back")}
        </CButton>
      </CLink>
    </CCardFooter>
  </CCard>
);
const AppointmentCard = (Props) => (
  <CRow>
    <CCol lg="12">{render(Props.Appointment, Props.isStarted)}</CCol>
  </CRow>
);

const AppointmentDetails = () => {
  const dispatch = useDispatch();
  const { id: appointmentId } = useParams();
  const patientAppointment = useSelector((state) =>
    state.appointments ? state.patientAppointments.appointment : {}
  );
  useEffect(() => {
    dispatch(fetchAppointment(appointmentId));
  }, [dispatch, appointmentId]);

  return (
    <>
      <CRow>
        <CCol>
          <CRow>
            <CCol xs="12">
              <CCard>
                <h4 className="table-title">Appointment Details</h4>

                <CCardBody>
                  {patientAppointment && (
                    <AppointmentCard
                      Appointment={patientAppointment}
                      isStarted
                    />
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </>
  );
};

export default AppointmentDetails;
