import React from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLocationPin, cilScreenDesktop } from "@coreui/icons";
import i18next from "i18next";

const RoundCard = ({ round,year,date_background }) => {
  const d = new Date();
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const currentYear = d.getFullYear();
  const today = currentYear + "-" + month + "-" + day

  return(<>
  <CRow>
    <CCol>
      <CCard className={(round.date_original) >= (today) ? "text-theme-blue p-4 clinic-card-green" : "text-theme-blue p-4 clinic-card"}>
        <CCardBody className="p-0">
          <CRow className="align-items-center justify-content-between">
            <CCol>
              <CRow>
                <CCol className="d-flex flex-column">
                  <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                    {round.day}
                  </CCardTitle>
                  <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                    {round.month} - {year}
                  </CCardSubtitle>
                </CCol>
                <CCol className="d-flex flex-column">
                  <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                    {i18next.t("TITLE")}
                  </CCardTitle>
                  <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                    {round.title}
                  </CCardSubtitle>
                </CCol>
                <CCol className="d-flex flex-column">
                  <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                    {i18next.t("TIME")}
                  </CCardTitle>
                  <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                    {typeof(round.from) === 'string' && (<>
                    {`${round.from.split(":")[0]}` < 12
                      ? `${round.from.split(":")[0] % 12}:${
                          round.from.split(":")[1]
                        } ${i18next.t("AM")} - `
                      : `${round.from.split(":")[0] % 12}:${
                          round.from.split(":")[1]
                        } ${i18next.t("PM")} - `}
                    {`${round.to.split(":")[0]}` < 12
                      ? `${round.to.split(":")[0] % 12}:${
                          round.to.split(":")[1]
                        } ${i18next.t("AM")}  `
                      : ` ${round.to.split(":")[0] % 12}:${
                          round.to.split(":")[1]
                        } ${i18next.t("PM")} `}
                        </>
                        )}
                  </CCardSubtitle>
                </CCol>
                <CCol className="d-flex flex-column">
                  <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                    {i18next.t("Specialty")}
                  </CCardTitle>
                  <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                    {round.specialtyName || i18next.t("No Specialty")}
                  </CCardSubtitle>
                </CCol>
                <CCol className="d-flex flex-column">
                  <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                    {i18next.t("Unit")}
                  </CCardTitle>
                  <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                    {round.unit || ("No Unit")}
                  </CCardSubtitle>
                </CCol>
                <CCol className="d-flex flex-column">
                  <CRow>
                    <CCol xs="auto" className="d-flex flex-column">
                      {round.type == "virtual" ? (
                        <CIcon
                          className="clinic-icon"
                          content={cilScreenDesktop}
                        />
                      ) : (
                        <CIcon
                          className="clinic-icon"
                          content={cilLocationPin}
                        />
                      )}
                    </CCol>
                    <CCol xs="auto" className="d-flex flex-column p-0">
                      <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                        {i18next.t("TYPE")}
                      </CCardTitle>
                      <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                        {round.type}
                      </CCardSubtitle>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  </>

)};

export default RoundCard;
