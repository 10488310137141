import React from "react";
import {
  CCard,
  CCardBody,
  CRow,
  CCol,
  CNav,
  CNavItem,
  CTabPane,
  CNavLink,
  CTabContent,
  CTabs,
  CContainer,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faIdCardClip } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import VitalChart from "./VitalChart";

const VitalSidebar = ({ children, chartColWidth = 8 }) => (
  <CCard className="border-0 w-100">
    <CCardBody className="text-dark p-0">
      <CContainer fluid>
        <CRow>
          <CTabs activeTab="List">
            <CCol md={3} xs={12} className="px-0 sidebar-tabs-wrap">
              <CNav variant="tabs" className="flex-column">
                <CNavItem>
                  <CNavLink
                    className="text-body font-weight-bold p-3 d-flex align-items-center"
                    data-tab="List"
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="mr-2 episode-icon-desktop"
                    />
                    <span className="d-none d-md-inline">
                      {i18next.t("List")}
                    </span>
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    className="text-body font-weight-bold p-3 d-flex align-items-center"
                    data-tab="Charts"
                  >
                    <FontAwesomeIcon
                      icon={faIdCardClip}
                      className="mr-2 episode-icon-desktop"
                    />
                    <span className="d-none d-md-inline">
                      {i18next.t("Charts")}
                    </span>
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CCol>
            <CCol md={9} xs={12} className="p-0">
              <CCard className="border-0 p-0 m-0">
                <CTabContent>
                  <CTabPane data-tab="List">{children}</CTabPane>
                  <CTabPane data-tab="Charts">
                    <VitalChart chartColWidth={chartColWidth} />
                  </CTabPane>
                </CTabContent>
              </CCard>
            </CCol>
          </CTabs>
        </CRow>
      </CContainer>
    </CCardBody>
  </CCard>
);

export default VitalSidebar;
