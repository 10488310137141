import { Api, getTokenHeader } from "services/Api";
import {
  CREATE_ROUND,
  FETCH_ROUND,
  UPDATE_ROUND,
  FETCH_ROUNDS,
  DELETE_ROUND,
  FETCH_ROUND_UTILITIES,
  FETCH_MY_ROUNDS,
  FETCH_ROUND_PATIENTS,
  FETCH_PUBLIC_ROUNDS,
  START_ROUND,
  GET_CONFERENCE_DATA,
  FILTER_CASES,
  FETCH_ATTENDEE_ROUNDS,
  SAVE_CONSULTATION_NOTE,
  HIDE_CONSULTATION_NOTE_NOTIFICATION,
  FETCH_USER_ROUND,
} from "./types";

export const createRound = (formData: Object): Object => async (dispatch) => {
  const response = await Api.post(
    `/api/admin/rounds`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: CREATE_ROUND, payload: response.data });
};

export const fetchRound = (id: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/rounds/${id}`, getTokenHeader());
  dispatch({ type: FETCH_ROUND, payload: response.data });
};
export const fetchUserRound = (id: string): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/round-details/${id}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_USER_ROUND, payload: response.data });
};
export const deleteRound = (id: string): Object => async (dispatch) => {
  await Api.delete(`/api/admin/rounds/${id}`, getTokenHeader());
  dispatch({ type: DELETE_ROUND, payload: id });
};

export const updateRound = (id: string, formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.put(
    `/api/admin/rounds/${id}`,
    formData,
    getTokenHeader()
  );
  dispatch({ type: UPDATE_ROUND, payload: response.data });
};

export const fetchRounds = (page: string, limit: string): Object => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/rounds?page=${page}&limit=${limit}`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_ROUNDS, payload: response.data });
};

export const fetchRoundUtilities = () => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/rounds/utilities`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_ROUND_UTILITIES, payload: response.data });
};

export const fetchMyRounds = () => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/rounds/my-rounds`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_MY_ROUNDS, payload: response.data });
};

export const fetchRoundPatients = (roundId: string) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/rounds/${roundId}/patients`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_ROUND_PATIENTS, payload: response.data });
};

export const fetchRoundsCalendar = (currentDate: String): Object => async (
  dispatch
) => {
  const headers = currentDate
    ? { params: { currentDate }, ...getTokenHeader() }
    : getTokenHeader();
  const response = await Api.get(`/api/admin/rounds/calendar`, headers);
  dispatch({ type: FETCH_ROUNDS, payload: response.data });
};

export const fetchPublicRounds = () => async (dispatch) => {
  const response = await Api.get(`/api/admin/rounds/public`, getTokenHeader());
  dispatch({ type: FETCH_PUBLIC_ROUNDS, payload: response.data });
};

export const registerAttendee = (formData: Object): Object => () => {
  Api.post(`/api/admin/rounds/register`, formData, {});
};

export const startRound = (roundId: string) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/rounds/${roundId}/start`,
    getTokenHeader()
  );
  dispatch({ type: START_ROUND, payload: response.data });
};

export const getConferenceData = (sessionToken: string) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/rounds/${sessionToken}/conference`,
    getTokenHeader()
  );
  dispatch({ type: GET_CONFERENCE_DATA, payload: response.data });
};

export const filterCases = (
  caseType: string,
  patientId: string,
  roundId: string
) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/rounds/cases?type=${caseType}&patient_id=${patientId}&round_id=${roundId}`,
    getTokenHeader()
  );
  dispatch({ type: FILTER_CASES, payload: response.data });
};

export const fetchAttendeeRounds = () => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/rounds/attendee-rounds`,
    getTokenHeader()
  );
  dispatch({ type: FETCH_ATTENDEE_ROUNDS, payload: response.data });
};

export const publishActiveCase = (roundId, activeCaseData) => async (
  dispatch
) => {
  await Api.post(
    `/api/admin/rounds/${roundId}/active-case`,
    activeCaseData,
    getTokenHeader()
  );
};

export const addConsultationNote = (roundId, data) => async (dispatch) => {
  const response = await Api.post(
    `/api/admin/rounds/${roundId}/add-consultation`,
    data,
    getTokenHeader()
  );
  dispatch({ type: SAVE_CONSULTATION_NOTE, payload: response.data });
};

export const switchRoundSpeaker = (roundId, data) => async () => {
  await Api.post(
    `/api/admin/rounds/${roundId}/switch-speaker`,
    data,
    getTokenHeader()
  );
};

export const updateAttendance = (roundId, data) => async () => {
  await Api.post(
    `/api/admin/rounds/${roundId}/attendance-update`,
    data,
    getTokenHeader()
  );
};

export const endRound = (roundId) => async () => {
  await Api.get(`/api/admin/rounds/${roundId}/end`, getTokenHeader());
};

export const submitRoundRate = (formData) => async () => {
  Api.post("/api/admin/rate/round", formData, getTokenHeader());
};
