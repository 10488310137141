import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CPagination

} from "@coreui/react";
import { listMyNotifications, readNotification } from "actions/notifications/notifications";
import { LIMIT } from "constants/constants";
import {
  REQUESTCREATED,
  NOTIFICATION_TYPE_APPOINTMENT,
  NOTIFICATION_TYPE_FOLLOWUP,
  NOTIFICATION_TYPE_REQUEST_CALL,
  NOTIFICATION_TYPE_ROUND,
  NOTIFICATION_TYPE_USER
} from "constants/notifications";
import i18next from "i18next";

const MyNotification = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.myNotifications || {});
  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/my-notifications?page=${newPage}`);
    }
  };

const handleNotificationClick = (item) => {
  console.log(item)
    dispatch(readNotification(item.id));
    const notification = JSON.parse(item.notification);
    switch (notification.type) {
      case NOTIFICATION_TYPE_REQUEST_CALL:
        history.push(`/episodes/spok/case/${notification.request.episode_id}`);
        break;
      case NOTIFICATION_TYPE_APPOINTMENT:
      case NOTIFICATION_TYPE_FOLLOWUP:
        history.push(`/patient/appointments/${notification.appointment.id}/show`);
        break;
      case NOTIFICATION_TYPE_ROUND:
        history.push(`/rounds/my-rounds/${notification.round.id}/details`);
        break;
      case NOTIFICATION_TYPE_USER:
        history.push(notification.data);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(listMyNotifications(page, LIMIT));
  }, [dispatch, page]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">{i18next.t("Notifications")}</div>
        </CCol>
        <CCol>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={notifications.data}
                fields={[
                      { key: "notification", label:  i18next.t("notification") },

                ]}
                noItemsViewSlot={i18next.t("No Available Items")}
                hover
                striped
                clickableRows
                scopedSlots={{
                  notification: (item) => <td>{JSON.parse(item.notification).message}</td>,
                }}
                onRowClick = {handleNotificationClick}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={notifications ? notifications.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default MyNotification;
