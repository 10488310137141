import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchRadiologyCenter,
  updateRadiologyCenter,
} from "actions/radiology-centers/radiologyCenters";
import { fetchCountries, fetchCities } from "actions/cities/cities";
import RadiologyCenterForm from "../form/RadiologyCenterForm";
import Loading from "views/partials/Loading";
import i18next from "i18next";

const EditRadiology = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const radiologyCenter = useSelector(
    (state) => state.radiologyCenters.radiologyCenter
  );
  const redirect = useSelector((state) => state.radiologyCenters.redirect);
  const cities = useSelector((state) => state.cities);
  const countries = useSelector((state) => state.countries);
  const label = `${i18next.t("Edit Radiology")}`;

  const onSubmit = async (formData) => {
    await dispatch(updateRadiologyCenter(formData, match.params.id));
  };

  useEffect(() => {
    dispatch(fetchRadiologyCenter(match.params.id));
    dispatch(fetchCountries());
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (radiologyCenter && radiologyCenter.country_id) {
      dispatch(fetchCities(radiologyCenter.country_id));
    }
  }, [dispatch, radiologyCenter]);

  if (redirect) {
    history.push("/service/providers/radiology-centers");
  }

  const onCountryChange = (e) => {
    dispatch(fetchCities(e.target.value));
  };

  if (radiologyCenter) {
    return (
      <RadiologyCenterForm
        onSubmit={onSubmit}
        radiologyCenter={radiologyCenter}
        agreementView={radiologyCenter.agreement}
        label={label}
        countries={countries.data ? countries.data : []}
        cities={cities.data ? cities.data : []}
        onCountryChange={onCountryChange}
      />
    );
  }
  return <Loading/>
};

export default EditRadiology;
