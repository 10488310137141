import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import notfound from "./../../../notfound.jpg"
import i18next from "i18next";
const Page404 = () => (
  <div className="flex-row align-items-center">
    <CContainer>
      <CRow className="justify-content-center">
        <CCol md="6">
          <div style={{width: '100%', height:'70%', marginBottom: '1rem'}}>
            <img src={notfound} alt="notFound"
            style={{width: '100%', height:'100%'}}
            />
          </div>
          <div className="clearfix">
            <h4 className="text-align-center">
              <strong>
                {i18next.t("The page you are looking for was not found")}
              </strong>
            </h4>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
);

export default Page404;
