import React from "react";
import { CFooter, CImg } from "@coreui/react";
import footerIcon from "../assets/icons/Medicine-Ainshams-University.png"

const TheFooter = () => (
  <CFooter fixed={false}>
    <div>
      <span className="ml-1">&copy; 2021 AIN SHAMS UNIVERSITY </span>
      <a href="http://www.asuvh.com/" target="_blank" rel="noopener noreferrer">
        <CImg src={footerIcon} style={{height:"30px"}} />
      </a>
      <span> Phone: +20 105-013-6035</span>
    </div>
  </CFooter>
);

export default React.memo(TheFooter);
