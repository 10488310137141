import { Api, getTokenHeader } from "services/Api";
import setQueryString from "helpers/helpers";
import {
  CREATE_HOSPITAL,
  FETCH_HOSPITAL,
  UPDATE_HOSPITAL,
  FETCH_HOSPITALS,
  DELETE_HOSPITAL,
  FETCH_HOSPITAL_STAFF,
  MY_HOSPITALS,
  FETCH_HOSPITAL_EXCLUDED_USERS,
  FETCH_HOSPITAL_LOGO,
  FETCH_HOSPITAL_AGREEMENT,
  DELETE_HOSPITAL_MEMBER,
} from "./types";

const prepareHospitalData = (formData) => {
  const newFormData = new FormData();
  newFormData.append("name", formData.name);
  newFormData.append("phone", formData.phone);
  newFormData.append("address", formData.address);
  newFormData.append("country", formData.country);
  newFormData.append("city", formData.city);
  newFormData.append("time_zone", formData.time_zone);
  newFormData.append("description", formData.description);
  newFormData.append("is_hub", formData.is_hub ? 1 : 0);
  newFormData.append("agreement", formData.agreement[0]);
  newFormData.append("logo", formData.logo[0]);
  newFormData.append("c_name", formData.c_name);
  newFormData.append("c_phone", formData.c_phone);
  newFormData.append("c_email", formData.c_email);
  if (!formData.is_hub && formData.parent) {
    newFormData.append("parent", formData.parent.value);
  }
  return newFormData;
};

const prepareHospitalUpdateMediaData = (hospitalData) => {
  const formData = new FormData();
  formData.append("agreement", hospitalData.agreement[0]);
  formData.append("logo", hospitalData.logo[0]);
  return formData;
};

export const createHospital = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/hospitals`,
    prepareHospitalData(formData),
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: CREATE_HOSPITAL, payload: response.data });
};

export const fetchHospital = (id: string): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/hospital/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_HOSPITAL, payload: response.data });
};

export const fetchlogo = (url): Object => async (dispatch) => {
  const response = await Api.get(`${url}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_HOSPITAL_LOGO, payload: response.data });
};
export const fetchagreement = (url): Object => async (dispatch) => {
  const response = await Api.get(`${url}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_HOSPITAL_AGREEMENT, payload: response.data });
};

export const updateHospital = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(
    `/api/admin/hospitals/${id}`,
    {
      ...formData,
      is_hub: formData.is_hub ? 1 : 0,
      parent: formData.parent ? formData.parent.value : null,
    },
    {
      ...getTokenHeader(),
    }
  );

  await Api.post(
    `/api/admin/hospitals/${id}/update-media`,
    prepareHospitalUpdateMediaData(formData),
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: UPDATE_HOSPITAL, payload: response.data });
};

export const fetchHospitals = (
  page: number,
  limit: number,
  filtters: Object = {}
): any => async (dispatch): Promise<Object> => {
  const response = await Api.get(
    `/api/admin/hospitals?page=${page}&limit=${limit}${setQueryString(
      filtters
    )}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_HOSPITALS, payload: response.data });
};

export const deleteHospital = (id: number): Object => async (dispatch) => {
  await Api.delete(`/api/admin/hospitals/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_HOSPITAL, payload: id });
};

export const fetchHospitalStaff = (
  id: number,
  page: number = 1
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/hospitals/${id}/staff?page=${page}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_HOSPITAL_STAFF, payload: response.data });
};

export const addHospitalStaff = (id, formData) => async (dispatch) => {
  const response = await Api.post(
    `/api/admin/hospitals/${id}/staff`,
    formData,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_HOSPITAL_STAFF, payload: response.data });
};

export const fetchHospitalExcludedUsers = (labId, role) => async (dispatch) => {
  const respons = await Api.get(
    `/api/admin/hospitals/${labId}/role/${role}/autocomplete`,
    { ...getTokenHeader() }
  );
  dispatch({ type: FETCH_HOSPITAL_EXCLUDED_USERS, payload: respons.data });
};

export const myHospitals = (): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/hospitals/my`, {
    ...getTokenHeader(),
  });
  dispatch({ type: MY_HOSPITALS, payload: response.data });
};

export const  deleteHospitalMember = (id: string ,memberId: string) => async (dispatch) => {
  await Api.delete(`/api/admin/hospitals/${id}/${memberId}/staff`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_HOSPITAL_MEMBER, payload: memberId });
};