const requiredMessage = "Field is required";
const requiredIf = (inputValue, getValue, otherName, otherValue) => {
  if (otherValue) {
    return getValue(otherName) === otherValue ? !!inputValue : true;
  }
  if (!getValue(otherName)) {
    // required if other is null
    return !!inputValue;
  }
  return true;
};

const getValidation = (getValues) => ({
  hospital_id: { required: requiredMessage },
  first_name: { required: requiredMessage, maxLength: 255 },
  last_name: { required: requiredMessage, maxLength: 255 },
  email: {
    required: requiredMessage,
    maxLength: 255,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "invalid email address",
    },
  }, // 'string|email|max:255|unique:users,email',
  phone: {
    validate: (value) => requiredIf(value, getValues, "guardian_phone"),
    maxLength: 255,
  },
  password: {
    minLength: {
      value: 8,
      message: "cannot be less than 8 characters",
    },
  },
  file: {
    required: requiredMessage,
    pattern: {
      value: /(\.(png|jpg|jpeg|pdf))$/i,
      message: "invalid file",
    },
  },
  file2: {
    pattern: {
      value: /(\.(png|jpg|jpeg|pdf))$/i,
      message: "invalid file",
    },
  },
  uuid: { required: requiredMessage },
  gender: { required: requiredMessage },
  birth_date: { required: requiredMessage },
  // guardian_first_name: 'string|max:255',
  // guardian_last_name: 'string|max:255',
  // guardian_email: 'string|email|max:255',
  // guardian_address: 'string',
  guardian_uuid: {
    validate: (value) => requiredIf(value, getValues, "phone"),
    maxLength: 255,
  },
  guardian_phone: {
    validate: (value) => requiredIf(value, getValues, "phone"),
    maxLength: 255,
  },
  specialty_id: { required: requiredMessage },
  department_id: { required: requiredMessage },
  severity: { required: requiredMessage },
  bed: { required: requiredMessage },
  chief_complaint: { required: requiredMessage },
});
export default getValidation;
