import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  CContainer,
  CCol,
  CRow,
  CCard,
  CCardBody,
  CForm,
  CFormGroup,
  CLabel,
  CCardFooter,
  CButton,
  CTextarea,
  CDataTable,
  CButtonGroup
} from "@coreui/react";
import {
  patientAddComplain,
  patientFetchAttachments,
  patientAddComplainAttachment,
  patientDeleteAttachment,
  patientFetchAttachment,
  patientUpdateComplainAttachment,
  patientCreateAppointment,
} from "actions/appointments/patientAppointment";
import { confirmAlert } from "react-confirm-alert";
import fileDownload from "js-file-download";
import { Api, getTokenHeader } from "services/Api";
import AttachmentForm from "./AttachmentForm";
// import { resetPatientAppointmentSelection, patientCreateAppointment } from 'actions/appointments/patientAppointment';
import i18next from "i18next";

const ComplaintForm = ({ appointmentId, onSubmit, appointmentBind = {} }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [attachmentEdit, setAttachmentEdit] = useState(false);
  const attachments = useSelector(
    (state) => state.patientAppointmentAttachments
  );
  const attachmentsArray = Object.values(attachments);

  useEffect(() => {
    if (appointmentId) {
      console.log("tttt",appointmentId)
      dispatch(patientFetchAttachments(appointmentId));
    }
  }, [appointmentId]);

  const toggle = () => {
    setAttachmentEdit(false);
    setModal(!modal);
  };

  const handleAddAttachment = async (formData) => {
    const preparedFormData = new FormData();
    preparedFormData.append("attachment_name", formData.attachment_name);
    preparedFormData.append("attachment_type", formData.attachment_type);
    if (formData.attachment_file) {
      preparedFormData.append("attachment_file", formData.attachment_file[0]);
    }

    if (attachmentEdit) {
      dispatch(
        await patientUpdateComplainAttachment(attachmentEdit.id, preparedFormData)
      );
    } else {
      await dispatch(patientAddComplainAttachment(appointmentId, preparedFormData));
    }
    toggle();
  };

  const handleDeleteAttachment = (attachmentId) => {
    confirmAlert({
      title: "Are you sure?",
      message: "You Want To Delete This Attachment.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(patientDeleteAttachment(attachmentId));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleEditAttachment = (attachmentId) => {
    dispatch(patientFetchAttachment(attachmentId));
    setAttachmentEdit(attachments[attachmentId]);
    setModal(true);
  };

  const handleDownloadDocument = async (attachment) => {
    const response = await Api.get(
      `/api/patient/appointments/complaint/attachments/media/${attachment.media_id}`,
      {
        ...getTokenHeader(),
        responseType: "blob",
      }
    );
    fileDownload(response.data, attachment.media[0].file_name);
  };

  const renderAttachmentsActions = (attachment) => (
    <>
        <CButtonGroup responsive >
            <CButton
              onClick={() => handleEditAttachment(attachment.id)}
              className="d-color"
              style={{ width: "7em", "margin-right": "7px" }}
            >
              {i18next.t("Edit")}
            </CButton>
            <CButton
              onClick={() => handleDownloadDocument(attachment)}
              color="primary"
              style={{ "margin-right": "7px" }}
            >
              {i18next.t("show")}
            </CButton>
            <CButton
              onClick={() => handleDeleteAttachment(attachment.id)}
              color="danger"
            >
              {i18next.t("Delete")}
            </CButton>
        </CButtonGroup>
    </>
  );

  const renderAttachments = () => (
    <>
      <CDataTable
        items={attachmentsArray}
        noItemsViewSlot={i18next.t("No Available Items")}
        fields={[
          { key: "name", label:  i18next.t("name") },
          { key: "type", label:  i18next.t("type") },
          { key: "actions", label:  i18next.t("Actions") },
        ]}
        striped
        scopedSlots={{
          name: (item) => <td>{`${item.name}`}</td>,
          type: (item) => <td>{`${item.type}`}</td>,
          actions: (item) => renderAttachmentsActions(item),
        }}
      />
      {/* <CFormGroup row className="bg-secondary py-3">
        <CCol xs="3">Name</CCol>
        <CCol xs="3">Type</CCol>
        <CCol xs="6">Actions</CCol>
      </CFormGroup> */}
      {/* {attachmentsArray.map((item) => (
        <CFormGroup row className="bg-light py-3" key={item.id}>
          <CCol md="3">{item.name}</CCol>
          <CCol md="3">{item.type}</CCol>
          <CCol md="6">{renderAttachmentsActions(item)}</CCol>
        </CFormGroup>
      ))} */}
    </>
  );

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: appointmentBind,
  });
  const history = useHistory();
  const onCancel = () => {
    history.push(`/patient/appointments`);
  };

  return (
    <CContainer>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CForm
              onSubmit={handleSubmit(onSubmit)}
              className="form-horizontal"
            >
              <h4 className="header-label-title">{i18next.t("Complaint")}</h4>
              <CCardBody>
                <CRow>
                  <CCol xs="12">
                    <CFormGroup>
                      <CLabel className="labels" htmlFor="complaint">
                        {i18next.t("Describe Your Complaint")}
                      </CLabel>
                      <CTextarea
                        name="complaint"
                        id="complaint"
                        innerRef={register}
                        rows="9"
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol className="text-align-center" xs="12">
                    <CFormGroup row>
                      <CCol xs="12">
                        <CButton className="d-color" onClick={toggle}>
                          {i18next.t("Add Attachment")}
                        </CButton>
                      </CCol>
                    </CFormGroup>
                  </CCol>
                  <CCol xs="12">
                    {attachmentsArray.length > 0 && renderAttachments()}
                  </CCol>
                </CRow>
                <CCardFooter>
                  <CFormGroup row>
                    <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                      <CButton
                        type="submit"
                        color="primary"
                        className="btn-form"
                      >
                          {i18next.t("Save")}
                      </CButton>
                    </CCol>
                    <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                      <CButton
                        type="reset"
                        color="danger"
                        className="btn-form"
                        onClick={onCancel}
                      >
                        {i18next.t("Cancel")}
                        
                      </CButton>
                    </CCol>
                  </CFormGroup>
                </CCardFooter>
              </CCardBody>
            </CForm>
          </CCard>
        </CCol>
      </CRow>
      <AttachmentForm
        onSubmit={handleAddAttachment}
        onCancel={toggle}
        modal={modal}
        attachment={attachmentEdit}
      />
    </CContainer>
  );
};
export default ComplaintForm;
