import {
  USER_LOGIN_SUCCEEDED,
  USER_LOGOUT,
  REFRESH_CURRENT_TOKEN,
  FORGET_PASSWROD,
  RESET_PASSWROD,
  RESEND_ACTIVATION,
  ACTIVATE,
  OPEN_SIGN_IN_MODAL,
  CLOSE_SIGN_IN_MODAL,
  LOGIN_FAILURE,
} from "actions/auth/types";


const initialState = {
  isSignInModalOpen: false,
  failure: false
};
/**
 * @param state
 * @param action
 */
export default function auth(state = initialState, action: Object): Object {
  switch (action.type) {
    case FORGET_PASSWROD:
    return {...state,...action.resetredirect };
    break;

    case RESET_PASSWROD:
    case RESEND_ACTIVATION:
    case ACTIVATE:
      return {...state,...action.payload};

    break;
    case USER_LOGIN_SUCCEEDED:
    case REFRESH_CURRENT_TOKEN:
      return { ...state, ...action.payload, failure: false };
    case USER_LOGOUT:
      return {};
    case OPEN_SIGN_IN_MODAL:
      return { ...state, isSignInModalOpen: true };
    case CLOSE_SIGN_IN_MODAL:
      return { ...state, isSignInModalOpen: false };
    case LOGIN_FAILURE:
      return {...state, failure: true}
    case "CHANGE_TOKEN":
      return {...state, access_token: '123456789'}
    default:
      return state;
  }
}
