import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { fetchVacations, deleteVacation } from "actions/vacations/vacations";
import { LIMIT } from "constants/constants";
import { cilOptions, cilPencil, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import i18next from "i18next";

const ListVacations = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const dispatch = useDispatch();
  const vacations = useSelector((state) => state.vacations.data);
  const meta = useSelector((state) => state.vacations.meta);
  const fields = [
    {key:"title",label:i18next.t("title")},
    {key:"date_from",label:i18next.t("date from")},
    {key:"date_to",label:i18next.t("date to")},
    {key: "type_text", label: i18next.t("type text")},
    {key: "related_name", label: i18next.t("Related Name")},
    {key: "actions", label: i18next.t("Actions")}
    ];

  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/vacations?page=${newPage}`);
    }
  };

  const handleDeleteVacation = (id: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This vacation.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteVacation(id));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    dispatch(fetchVacations(page, LIMIT));
  }, [dispatch, page]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">{i18next.t("vacations")}</div>
        </CCol>
        <CCol>
          <CButton
            color="success"
            href="/vacations/create"
            className="create-button float-right wi"
          >
            {i18next.t("New Vacation")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={vacations || []}
                fields={fields}
                noItemsViewSlot={i18next.t("No Available Items")}
                hover
                striped
                scopedSlots={{
                  actions: (item) => (
                    <td>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle
                          className="action-toggle m-0"
                          color="light"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <CIcon content={cilOptions} />
                        </CDropdownToggle>
                        <CDropdownMenu className="p-0">
                          <CDropdownItem
                            href={`/vacations/edit/${item.id}`}
                            className="text-primary"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <CIcon content={cilPencil} />
                            <span class="mx-1">{i18next.t("Edit")}</span>
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={() => handleDeleteVacation(item.id)}
                            className="text-danger"
                          >
                            <CIcon content={cilTrash} />
                            <span class="mx-1">{i18next.t("Delete")}</span>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default ListVacations;
