import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CInputRadio,
} from "@coreui/react";
import AsyncSelect from "react-select/async";
import { Api, getTokenHeader } from "services/Api";
import { customStyles } from "views/rounds/form/RoundFormValidation";
import { getValidation } from "views/hospitals/form/ServiceProviderValidation";

const PublicSurveyForm = ({ onSubmit, onCancel, modal }) => {
  const { register, handleSubmit, control, watch, errors } = useForm({
    mode: "onBlur",
  });

  const getSurveysOptions = async (inputValue) => {
    let options = [];
    const url = `/api/admin/surveys?title=${inputValue}`;
    options = await Api.get(url, {
      ...getTokenHeader(),
    }).then((response) => response.data.data);
    return options;
  };

  const Validation = getValidation(watch);

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} onClose={onCancel} size="lg">
        <h4 className="header-label-title">Add Public Survey</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
              <CLabel className="labels required">Survey</CLabel>
              <Controller
                control={control}
                cacheOptions
                rules={Validation.require}
                styles={customStyles(!errors.survey)}
                name="survey"
                loadOptions={(inputValue) => getSurveysOptions(inputValue)}
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.id}
                as={AsyncSelect}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingTop: "3%" }}
            >
              <CLabel className="labels required">Availability</CLabel>
            </CCol>
            <CCol
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingTop: "3%" }}
            >
              <CFormGroup variant="custom-checkbox">
                <CInputRadio
                  custom
                  id="inline-radio1"
                  name="survey_availability"
                  value={2}
                  innerRef={register}
                  checked
                />
                <CLabel
                  variant="custom-checkbox"
                  className="labels"
                  htmlFor="inline-radio1"
                >
                  <strong>Public</strong>
                </CLabel>
              </CFormGroup>
            </CCol>
            <CCol
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingTop: "3%" }}
            >
              <CFormGroup variant="custom-checkbox">
                <CInputRadio
                  custom
                  id="inline-radio2"
                  name="survey_availability"
                  value={3}
                  innerRef={register}
                />
                <CLabel variant="custom-checkbox" htmlFor="inline-radio2">
                  <strong>Restricted</strong>
                </CLabel>
              </CFormGroup>
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton type="submit" color="primary">
            Save
          </CButton>
          <CButton type="reset" color="danger" onClick={onCancel}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};

export default PublicSurveyForm;
