import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import { fetchRole } from "actions/roles/roles";
import i18next from "i18next";

const Role = ({ match }) => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.roles.role);

  useEffect(() => {
    dispatch(fetchRole(match.params.id));
  }, [dispatch, match.params.id]);

  const renderRole = () => {
    if (role) {
      return (
        <>
          <table className="table table-striped table-hover">
            <tbody>
              <tr>
                <td className="labels">{i18next.t("Role Name")}</td>
                <td>
                  <strong>{role.name}</strong>
                </td>
              </tr>
              <tr>
                <td className="labels">{i18next.t("Desciption")}</td>
                <td>
                  <strong>
                    {role.permissions.length !== 0 ? (
                      <Permissions permissions={role.permissions} />
                    ) : (
                      i18next.t("No Available Data")
                    )}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
    return (
      <h4 className="text-align-center not-found">
        <strong>{i18next.t("Not Found")}</strong>
      </h4>
    );
  };
  const Permissions = ({ permissions }) =>
    permissions.map((item) => (
      <CCol
        style={{ marginBottom: ".5em" }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <span style={{ textTransform: "capitalize" }}>
          - {item.replace("-", " ")}
        </span>
      </CCol>
    ));
  return (
    <CRow>
      <CCol lg={12}>
        <CCard>
          <h4 className="table-title">{i18next.t("Role Details")}</h4>
          <CCardBody>{renderRole()}</CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Role;
