import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  fetchRadiologyCenter,
  fetchRadiologyStaff,
  fetchRadiologyExcudedUsers,
  addRadiologyStaff,
  deleteRadMember,
} from "actions/radiology-centers/radiologyCenters";
import Staff from "views/partials/Staff";
import InvestigationTab from "views/labs/screens/investigationTab";
import ObjectDetails from "views/partials/ObjectDetails";
import { confirmAlert } from "react-confirm-alert";
import i18next from "i18next";

const Radiology = ({ match }) => {
  const listItems = ["name", "phone", "address", "description"];
  const dispatch = useDispatch();
  const radiologyCenter = useSelector(
    (state) => state.radiologyCenters.radiologyCenter
  );
  const staff = useSelector((state) => state.radiologyCenters.staff);
  const excludedUsers = useSelector((state) =>
    state.radiologyCenters.excludedUsers
      ? state.radiologyCenters.excludedUsers
      : []
  );
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    dispatch(fetchRadiologyCenter(match.params.id));
  }, [dispatch, match.params.id]);

  const toggle = () => {
    setModal(!modal);
  };

  const onActiveTabChange = (activeTab) => {
    setTab(activeTab);
    switch (activeTab) {
      case "staff":
        dispatch(fetchRadiologyStaff(match.params.id));
        break;
      default:
        break;
    }
  };

  const onActivePageChange = (activePage) => {
    setPage(activePage);
    switch (tab) {
      case "staff":
        dispatch(fetchRadiologyStaff(match.params.id, activePage));
        break;
      default:
        break;
    }
  };

  const handleSelectRoleChange = (event) => {
    dispatch(fetchRadiologyExcudedUsers(match.params.id, event.target.value));
  };

  const prepareFormData = (data) => {
    const usersIds = data.users ? data.users.map((item) => item.id) : [];
    return { ...data, users: usersIds };
  };

  const onSubmit = (formData) => {
    dispatch(addRadiologyStaff(match.params.id, prepareFormData(formData)));
    toggle();
  };
  const handleRemoveMember = (memberId: string) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Remove This Staff Member.")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteRadMember(match.params.id, memberId));
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  if (radiologyCenter) {
    return (
      <CRow>
        <CCol lg={12}>
          <CCard>
            <CCardBody>
              <CTabs
                activeTab="details"
                onActiveTabChange={(activeTab) => onActiveTabChange(activeTab)}
              >
                <CNav variant="tabs">
                  <CNavItem>
                    <CNavLink className="navchange" data-tab="details">{i18next.t("Details")}</CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink className="navchange" data-tab="staff">{i18next.t("Staff")}</CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink className="navchange" data-tab="investigations">
                      {i18next.t("Investigations")}
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent>
                  <CTabPane data-tab="details">
                    <ObjectDetails
                      object={radiologyCenter}
                      listItems={listItems}
                    />
                  </CTabPane>
                  <CTabPane data-tab="staff">
                    <Staff
                      staff={staff}
                      onActivePageChange={onActivePageChange}
                      page={page}
                      onSubmit={onSubmit}
                      modal={modal}
                      toggle={toggle}
                      handleSelectRoleChange={handleSelectRoleChange}
                      excludedUsers={excludedUsers}
                      handleRemoveMember={handleRemoveMember}
                      staffMembers={radiologyCenter.staffMembers} 
                      medicalProvider='manage-Radiology-member'

                    />
                  </CTabPane>
                  <CTabPane data-tab="investigations">
                    <InvestigationTab
                      centerType={2}
                      centerId={match.params.id}
                    />
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }
  return (
    <h4 className="text-align-center not-found">
      <strong>Not Found</strong>
    </h4>
  );
};

export default Radiology;
