import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import { CCard, CCardBody } from "@coreui/react";
import QuestionForm from "views/surveys/questions/components/QuestionForm";
import {
  fetchQuestion,
  updateQuestion,
} from "actions/surveys/questions/questions";
import { COMPOSITE } from "constants/questions";
import Loading from "views/partials/Loading";

/**
 * @param props
 * @param props.match
 */
function EditQuestion({ match }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const question = useSelector((state) => state.questions.question);
  const redirect = useSelector((state) => state.questions.redirect);
  const subQuestions = useSelector((state) => state.questions.subQuestions);
  const label = i18next.t("Edit Question");
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      category_id: 1,
    };
    dispatch(updateQuestion(data, match.params.id));
  };

  if (redirect) {
    history.push("/questions");
  }

  useEffect(() => {
    dispatch(fetchQuestion(match.params.id));
  }, [dispatch, match.params.id]);
  if (question) {
    return (
      <CCard>
        <CCardBody>
          <QuestionForm onSubmit={onSubmit} label={label} question={question} />
        </CCardBody>
      </CCard>
    );
  }
  return <Loading/>
}

export default EditQuestion;
