import React, { useState } from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CCard,
  CCardBody,
  CRow,
  CCol,
  CButton,
} from "@coreui/react";
import ActiveReceivedRequests from "views/requests/ActiveReceivedRequests";
import Consultants from "views/episode-management/screens/spoke/cases/Consultants";
import CIcon from "@coreui/icons-react";
import { cilPhone } from "@coreui/icons";
import { useSelector } from "react-redux";

const CallsSection = ({ episodeId, episode, calling }) => {
  const [showContent, setShowContent] = useState(calling);
  const notifications = useSelector(
    (state) => state.requests.receivedRequests || {}
  );
  const style = {
    zIndex: "15",
    backgroundColor: "#fff",
    top: "10%",
    right: "0",
    left: "98%",
  };
  console.log("minimized>>>", showContent);
  console.log("ffff>>>", notifications);

  const toggleCallSection = (e) => {
    setShowContent(!showContent);
  };
  return (
    <>
      <div className="calls-section">
        <CCol md="12" className={["calls-section-content", showContent || notifications.length!==0 ? "d-block" : "hide-content"].join(" ")}>
          <CCard className="card-3d">
            <CCardBody className="p-0">
              <CRow>
                <CCol xs="12">
                  <ActiveReceivedRequests episodeId={episodeId} />
                </CCol>
                <CCol xs="12">
                  <Consultants hospitalId={episode.hospital_id} />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CRow className="justify-content-end">
          {!showContent ? (
          <CButton onClick={toggleCallSection} className="calls-section-btn call-section">
            <CIcon content={cilPhone} className="calls-section-icon" />
          </CButton>
          ) : (
          <CButton onClick={toggleCallSection} style={{backgroundColor:'#e55353'}} className="calls-section-btn call-section">
            <CIcon content={cilPhone} className="calls-section-icon" />
          </CButton>
        )}
        </CRow>
      </div>
      {/* <CSidebar
        style={style}
        show={false}
        minimize
        onMinimizeChange={(val) => setShowContent(!showContent)}
      >

        <CSidebarMinimizer className="c-d-md-down-none" />
      </CSidebar> */}
    </>
  );
};

export default CallsSection;
