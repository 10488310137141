import React, { useEffect, useState } from "react";
import find from "lodash/find";
import { useForm, Controller } from "react-hook-form";
import {
  CCol,
  CContainer,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CBadge,
  CCard,
  CCardBody,
  CSelect,
  CSwitch,
  CCardFooter,
  CButton,
} from "@coreui/react";
import AsyncSelect from "react-select/async";
import { Api, getTokenHeader } from "services/Api";
import "../../../../scss/style.scss";

import PatientSimpleForm from "./PatientSimpleForm";
import { getClassName } from "views/hospitals/form/ServiceProviderValidation";
import getValidation from "views/episode-management/screens/spoke/cases/forms/EpisodeFormValidation";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

const AppointmentForm = (props) => {
  const [nationalVacationCheck, setNationalVacationCheck] = useState(false);
  const [newPatientCheck, setNewPatientCheck] = useState(false);
  const {
    appointment,
    specialties,
    doctors,
    clinics,
    slots,
    onSelectSpecialty,
    onSelectDoctor,
    onSelectClinic,
    onSubmit,
    clinic_type_virtual,
    national_vacations,
  } = props;
  const {
    register,
    handleSubmit: reactHockSubmit,
    control,
    reset,
    setValue,
    errors,
    watch,
  } = useForm({
    defaultValues: appointment,
  });

  useEffect(() => {
    if (appointment) {
      reset(appointment);
    }
  }, [appointment]);

  const history = useHistory();

  const onCancel = () => {
    history.push("/appointments");
  };

  const onDateChange = () => {
    const isNationalVaction = national_vacations.indexOf(watch("date")) >= 0;
    setNationalVacationCheck(isNationalVaction);
    setValue("doctor_id", null);
    if (!isNationalVaction && Number(watch("specialty_id")) !== 0) {
      onSelectSpecialty(watch("specialty_id"), watch("date"));
    }
  };

  const onSpecialityChange = () => {
    if (!nationalVacationCheck) {
      onSelectSpecialty(watch("specialty_id"), watch("date"));
    }
  };

  const Specialties = () =>
    specialties.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));

  const DoctorsList = () =>
    doctors.map((item) => (
      <option key={item.id} value={item.id}>
        {item.fullName}
      </option>
    ));

  const ClinicList = () =>
    clinics.map((item) => (
      <option key={item.id} value={item.id}>
        {item.title}
      </option>
    ));

  const getPatientOptions = async (inputValue) => {
    const url = `/api/admin/patients/list?q=${inputValue}`;
    const response = await Api.get(url, getTokenHeader());
    return response.data.data ? response.data.data : [];
  };

  const renderClinicDetails = () => {
    const temp = watch("clinic_id") ? watch("clinic_id") : 0;
    const clinic = find(clinics, { id: parseInt(temp, 10) });
    if (clinic) {
      if (clinic.type === clinic_type_virtual) {
        return (
          <CFormGroup row>
            <CCol md="6" className=" divText mt-4">
              <CLabel className="labels">{i18next.t("Clinic Type")} : </CLabel>
              <CBadge className="badge badge-secondary ml-3 labels">
                Virtual
              </CBadge>
            </CCol>
            <CCol md="6">
              <CLabel className="labels">{i18next.t("Slots")}</CLabel>
              <CSelect
                custom
                value={watch("schedule_time") ? watch("schedule_time") : ""}
                innerRef={register({ required: "slot is required" })}
                id="schedule_time"
                name="schedule_time"
                placeholder="Select Slot"
              >
                {Object.keys(slots).map((item) => (
                  <option key={item} value={item}>
                    {`${slots[item].split(" ")[1].split(":")[0]}` < 12
                      ? `${slots[item].split(" ")[0]}  ${
                          slots[item].split(" ")[1].split(":")[0] % 12
                        }:${slots[item].split(" ")[1].split(":")[1]} ${i18next.t("AM")} ${
                          slots[item].split(" ")[2]
                        } `
                      : `${slots[item].split(" ")[0]}  ${
                          slots[item].split(" ")[1].split(":")[0] % 12
                        }:${slots[item].split(" ")[1].split(":")[1]} ${i18next.t("PM")} ${
                          slots[item].split(" ")[2]
                        } `}
                    {`${slots[item].split(" ")[3].split(":")[0]}` < 12
                      ? `${slots[item].split(" ")[3].split(":")[0] % 12}:${
                          slots[item].split(" ")[3].split(":")[1]
                        } ${i18next.t("AM")}  `
                      : ` ${slots[item].split(" ")[3].split(":")[0] % 12}:${
                          slots[item].split(" ")[3].split(":")[1]
                        } ${i18next.t("PM")} `}
                  </option>
                ))}
              </CSelect>
              {!(slots && Object.values(slots).length > 0) && (
                <>
                  <span style={{ color: "red" }}>{errors.slots_error}</span>
                  <br />
                </>
              )}
            </CCol>
          </CFormGroup>
        );
      }
      return (
        <CFormGroup row>
          <CCol md="6" className=" divText mt-4">
            <CLabel className="labels">{i18next.t("Clinic Type")} : </CLabel>
            <CBadge className="badge badge-secondary ml-3 labels">
              {i18next.t("Physical")}
            </CBadge>
          </CCol>
          <CCol md="6" className=" divText mt-4">
            <CLabel className="labels">{i18next.t("Address")} : </CLabel>
            <CBadge className="badge badge-secondary ml-3 labels">
              {clinic.address ? clinic.address : i18next.t("No Available Address")}
            </CBadge>
          </CCol>
          <CCol md="6">
            <CLabel className="labels">{i18next.t("Slots")}</CLabel>
            <CSelect
              custom
              value={watch("schedule_time") ? watch("schedule_time") : ""}
              innerRef={register({ required: "slot is required" })}
              id="schedule_time"
              name="schedule_time"
              placeholder="Select Slot"
            >
              {Object.keys(slots).map((item) => (
                <option key={item} value={item}>
                  {slots[item]}
                </option>
              ))}
            </CSelect>
            {!(slots && Object.values(slots).length > 0) && (
              <>
                <span style={{ color: "red" }}>{errors.slots_error}</span>
                <br />
              </>
            )}
          </CCol>
        </CFormGroup>
      );
    }
    return "";
  };

  const renderFooter = () => {
    if (nationalVacationCheck) {
      return (
        <CCardFooter>
          <CRow>
            <CCol>
              <h4 className="text-align-center not-found">
                <strong>
                  {i18next.t("The Selected Date Is A National Vacation, Please Choose Another Date")}.
                </strong>
              </h4>
            </CCol>
          </CRow>
        </CCardFooter>
      );
    }
    return (
      <CCardFooter>
        <CFormGroup row>
          <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
            <CButton type="submit" color="primary" className="mx-1 btn-form">
              {i18next.t("Save")}
            </CButton>
          </CCol>
          <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
            <CButton
              color="danger"
              type="reset"
              onClick={onCancel}
              className="mx-1 btn-form"
            >
              {i18next.t("Cancel")}
            </CButton>
          </CCol>
        </CFormGroup>
      </CCardFooter>
    );
  };

  const renderPatientSection = () => {
    const Validation = getValidation(watch);
    if (newPatientCheck) {
      return (
        <PatientSimpleForm
          Validation={Validation}
          watch={watch}
          errors={errors}
          register={register}
          setValue={setValue}
        />
      );
    }
    return (
      <CCol xs={12} sm={12} md={9} lg={9} xl={9} className="left-side">
        <CLabel className="labels">{i18next.t("Patient")}</CLabel>
        <Controller
          control={control}
          id="patient"
          name="patient"
          cacheOptions
          loadOptions={(inputValue) => getPatientOptions(inputValue)}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name}`
          }
          getOptionValue={(option) => option.id}
          as={AsyncSelect}
        />
      </CCol>
    );
  };

  return (
    <CContainer>
      <CRow>

        <CCol xs="12">
          <CForm
            onSubmit={reactHockSubmit(onSubmit)}
            className="form-horizontal"
          >
            <CCard>
              <h4 className="header-label-title">{i18next.t("Create New Appointment")}</h4>
              <CCardBody>
                <CCol xs="12">
                  <h4 className="header-label">{i18next.t("Appointment Basic Info")}</h4>
                </CCol>
                <CFormGroup row className="add-new-appontment-titels">
                  <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                    <CLabel className="labels required">{i18next.t("Date")}</CLabel>
                    <CInput
                      value={
                        watch("date")
                          ? watch("date")
                          : new Date().toISOString().substr(0, 10)
                      }
                      onChange={onDateChange}
                      innerRef={register({ required: "Date is required" })}
                      className={`${getClassName(
                        "date",
                        "date",
                        watch,
                        errors
                      )}`}
                      type="date"
                      id="date"
                      name="date"
                      placeholder="date"
                      min={new Date().toISOString().substr(0, 10)}
                    />
                  </CCol>
                  <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                    <CLabel className="labels required">{i18next.t("Specialty")}</CLabel>
                    <CSelect
                      custom
                      innerRef={register({ required: "specialty is required" })}
                      className={`${getClassName(
                        "specialty_id",
                        "specialty_id",
                        watch,
                        errors
                      )}`}
                      onChange={onSpecialityChange}
                      id="specialty_id"
                      name="specialty_id"
                    >
                      <option value="" key="0">
                        {i18next.t("Select Specialty")}
                      </option>
                      {Specialties()}
                    </CSelect>
                  </CCol>
                  <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                    <CLabel className="labels required">{i18next.t("Doctor")}</CLabel>
                    <CSelect
                      custom
                      onChange={() =>
                        onSelectDoctor(watch("doctor_id"), watch("date"))
                      }
                      innerRef={register({ required: "doctor is required" })}
                      name="doctor_id"
                      id="doctor_id"
                      className={`${getClassName(
                        "doctor_id",
                        "doctor_id",
                        watch,
                        errors
                      )}`}
                    >
                      <option value="" key="0">
                        {i18next.t("Select Doctor")}
                      </option>
                      {DoctorsList()}
                    </CSelect>
                    {watch("specialty_id") !== 0 && errors.doctors_error && (
                      <span style={{ color: "red" }}>
                        {errors.doctors_error}
                      </span>
                    )}
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs="12">
                    <h4 className="header-label"> {i18next.t("Payment Info")}</h4>
                  </CCol>
                  <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                    <CLabel className="labels required">{i18next.t("Clinic")}</CLabel>
                    <CSelect
                      className={`${getClassName(
                        "clinic_id",
                        "clinic_id",
                        watch,
                        errors
                      )}`}
                      custom
                      onChange={() =>
                        onSelectClinic(watch("clinic_id"), watch("date"))
                      }
                      innerRef={register({ required: "clinic is required" })}
                      name="clinic_id"
                      id="clinic_id"
                    >
                      <option value="" key="0">
                        {i18next.t("Select Clinic")}
                      </option>
                      {ClinicList()}
                    </CSelect>
                    {watch("doctor_id") !== 0 && errors.clinics_error && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors.clinics_error}
                        </span>
                        <br />
                      </>
                    )}
                  </CCol>
                  <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                    <CLabel className="labels required">{i18next.t("Payment")}</CLabel>
                    <CSelect
                      custom
                      innerRef={register({ required: "payment is required" })}
                      name="payment_type"
                      className={`${getClassName(
                        "payment_type",
                        "payment_type",
                        watch,
                        errors
                      )}`}
                      placeholder="Select Payment"
                      id="payment_type"
                    >
                      <option key="paid" value="1">
                        {i18next.t("Paid")}
                      </option>
                      <option key="free" value="2">
                        {i18next.t("Free")}
                      </option>
                    </CSelect>
                  </CCol>
                  <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                    {watch("payment_type") === "1" && (
                      <>
                        <CLabel className="labels required">{i18next.t("Price")}</CLabel>
                        <CInput
                          className={`${getClassName(
                            "price",
                            "price",
                            watch,
                            errors
                          )}`}
                          placeholder={i18next.t("Write Price")}
                          type="number"
                          innerRef={register({ required: "price is required" })}
                          id="price"
                          name="price"
                        />
                      </>
                    )}
                  </CCol>
                </CFormGroup>
                <CCol xs="12">
                  <h4 className="header-label">{i18next.t("Patient Info")}</h4>
                </CCol>
                <CFormGroup row>
                  <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CLabel
                      style={{ fontSize: "1.3em" }}
                      htmlFor="newPatientCheck"
                      className="mx-3 labels"
                    >
                      {i18next.t("Create New Patient")}
                    </CLabel>
                    <CSwitch
                      style={{ "margin-top": "0.9em" }}
                      id="newPatientCheck"
                      name="newPatientCheck"
                      className="mr-3 left-side"
                      size="lg"
                      color="primary"
                      shape="pill"
                      innerRef={register}
                      onChange={(e) => setNewPatientCheck(e.target.checked)}
                    />
                  </CCol>
                </CFormGroup>
                {renderPatientSection()}
                {renderClinicDetails()}
              </CCardBody>
              {renderFooter()}
            </CCard>
          </CForm>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default AppointmentForm;
