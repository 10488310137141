import React from "react";
import { useSelector } from "react-redux";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CCol,
  CRow,
  CListGroup,
  CListGroupItem,
  CImg,
  CCardTitle,
  CCard,
  CCardBody,
  CCardSubtitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { getAge } from "helpers/helpers";
import logo from "./../../../../logo.png";
import i18next from "i18next";
import { useLocation } from "react-router-dom";

const InvestigationPrint = ({ investigationData, modal, setModel, appointment }) => {
  const location = useLocation();
  const printProcess = () => {
    var elem = document.getElementById("printSection");

    var domClone = elem.cloneNode(true);

    var $printSection = document.getElementById("printSection");

    if (!$printSection) {
      var $printSection = document.createElement("div");
      $printSection.style.width = "100%";
      $printSection.style.height = "100%";
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
    }

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
  };

  const printPatientDetails = () => {
    return (
      <CCard className="text-theme-blue">
        <h4 className="table-title">{i18next.t("Patient Info")}</h4>
        <CCardBody>
          <CRow>
            <CCol
              CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column"
            >
              <CCardTitle className="avh-font-bold  w-fcontent">
              {i18next.t("Name")} :{" "}
                {appointment.patient}
              </CCardTitle>
            </CCol>
            <CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column"
            >
              <CCardTitle className="avh-font-bold float-left w-fcontent">
              {i18next.t("Age")} : {getAge(appointment.patient_birth_date)} {i18next.t("Years")}
              </CCardTitle>
            </CCol>
          </CRow>
          <CRow>
            <CCol
              CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column"
            >
              <CCardTitle className="avh-font-bold  w-fcontent">
              {i18next.t("Specialty")} : {appointment.speciality}
              </CCardTitle>
            </CCol>
            <CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column"
            >
              <CCardTitle
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" avh-font-bold float-left w-fcontent"
              >
               {i18next.t("Clinic")} : {appointment.clinic}
              </CCardTitle>
            </CCol>
          </CRow>
          <CRow>
            <CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column hide-for-mobile"
            >
              <CCardTitle className="avh-font-bold float-left w-fcontent">
              {i18next.t("date")} : {appointment.schedule_time}
              </CCardTitle>
            </CCol>
            <CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column hide-for-mobile"
            >
              <CCardTitle className="avh-font-bold float-left w-fcontent">
              {i18next.t("Diagnosis")} :{" "}
                {appointment.examination
                  ? appointment.examination
                  : i18next.t("No Available Diagnosis")}
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    );
  };
  let data = false;
  if (modal == 'lab') data  = investigationData.labInvestigations;
  if (modal == 'rad') data  = investigationData.radInvestigations;
  return modal ? (
    <CModal
      show={modal}
      onClose={() => {
        setModel(false);
      }}
      size="xl"
    >
      {modal == 'lab' && <h4 className="header-label-title">{i18next.t("Print Lab Preview")}</h4>}
      {modal == 'rad' && <h4 className="header-label-title">{i18next.t("Print Radiology Preview")}</h4>}
      <CModalBody id="printSection">
        <CListGroup>
          <CImg
            src={ process.env.REACT_APP_SERVICE_URL + appointment.logo || logo}
            style={{
              display: "block",
              "margin-left": "auto",
              "margin-right": "auto",
              height: "15em",
            }}
          />
          {printPatientDetails()}
          <CCard className="text-theme-blue">
          {modal == 'lab' && <h4 className="table-title">{i18next.t("Lab Details")}</h4>}
          {modal == 'rad' && <h4 className="table-title">{i18next.t("Radiology Details")}</h4>}
            <CCardBody>
              {(data.length ) &&
              data.map(
                (item) =>
                  (
                    <CListGroupItem>
                      <CRow>
                        <CCol
                          CCol
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="d-flex flex-column"
                        >
                          <CCardTitle className="avh-font-bold  w-fcontent">
                            {item.investigation_types[0].name}
                          </CCardTitle>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="d-flex flex-column"
                        >
                          <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                          {i18next.t("Notes")} : {item.order_notes}
                          </CCardSubtitle>
                        </CCol>
                      </CRow>
                    </CListGroupItem>
                  )
              )}
              {(data.length )&&
              <CListGroupItem>
                <CRow>
                  <CCol
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="d-flex flex-column"
                  >
                    <CCardTitle className="avh-font-bold  w-fcontent">
                    {i18next.t("Doctor's Signature")}: 
                    </CCardTitle>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="d-flex flex-column"
                  >
                    <CCardSubtitle>
                      {data[0].doctor.first_name} {data[0].doctor.last_name}
                    </CCardSubtitle>
                  </CCol>
                </CRow>
              </CListGroupItem>  }          
            </CCardBody>
          </CCard>
        </CListGroup>
        <CCardSubtitle>
          {/* TO DO show parent logo
          <CImg
            src={logo}
            style={{
              display: "block",
              "margin-right": "auto",
              height: "5em",
              width: "50%",
            }}
          />
          */}
        </CCardSubtitle>
      </CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={printProcess}>
          <CIcon name="cil-Print" />
          {i18next.t("Print")}
        </CButton>
        <CButton
          type="reset"
          color="danger"
          onClick={() => {
            setModel(false);
          }}
        >
          {i18next.t("Cancel")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    ""
  );
};

export default InvestigationPrint;
