import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createHospital } from "actions/hospitals/hospitals";
import { fetchCities, fetchCountries, fetchCountryCities } from "actions/cities/cities";
import { Api, getTokenHeader } from "../../../services/Api";
import HospitalForm from "../form/HospitalForm";
import { TIME_ZONES } from "constants/constants";
import i18next from "i18next";

/**
 * @param props
 */
function CreateHospital() {
  const history = useHistory();
  const utilities = useSelector((state) => state.utilities);
  const countries = useSelector((state) => state.countries);
  const cities = useSelector((state) => state.cities);
  const redirect = useSelector((state) => state.hospitals.redirect);
  const lable = `${i18next.t("Create New Location")}`;
  const dispatch = useDispatch();

  const onSubmit = async (formData) => {
    const preparedData = {
      ...formData,
      time_zone: formData.time_zone.value
        ? formData.time_zone.value
        : formData.time_zone,
    };
    await dispatch(createHospital(preparedData));
  };

  const onCountryChange = (e) => {
    dispatch(fetchCountryCities(e.target.value));
  };

  const getOptions = async (inputValue) => {
    let options = [];
    const url = `/api/admin/hospitals?q=${inputValue}&is_hub=1`;
    options = await Api.get(url, {
      ...getTokenHeader(),
    }).then((response) =>
      response.data.data.map((item) => ({ value: item.id, label: item.name }))
    );
    return options;
  };

  if (redirect) {
    history.push("/service/providers/hospitals");
  }
  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchCities());
  }, [dispatch]);

  return (
    <HospitalForm
      onSubmit={onSubmit}
      lable={lable}
      getOptions={getOptions}
      countries={countries.data || []}
      cities={cities.data || []}
      onCountryChange={onCountryChange}
      time_zones={TIME_ZONES}
    />
  );
}

export default CreateHospital;
