import React from "react";
import { Api } from "services/Api";
import DiagnosisForm from "./Form";

const CreateDiagnosis = ({ toggle, modal }) => {
  const onSubmit = (formData) => {
    Api.post(`symptomsAndDiagnosis`, formData)
      .then((response) => {
        if (response.data && response.statusText === "Created") {
          console.log("success");
          toggle();
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  const onCancel = () => {
    toggle();
  };
  return (
    <DiagnosisForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      toggle={toggle}
      modal={modal}
      FormTitle="Create Diagnosis"
    />
  );
};
export default CreateDiagnosis;
