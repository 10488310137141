import { Api, getTokenHeader } from "../../services/Api";
import {
  CREATE_DISCHARGE_FORM,
  FETCH_EPISODE_LAB_ORDERS,
  FETCH_EPISODE_RAD_ORDERS,
  FETCH_EPISODE_APPOINTMENTS,
} from "./types";

export const createDischargeForm = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/episodes/dischargeForm`,
    formData,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: CREATE_DISCHARGE_FORM, payload: response.data });
};

export const fetchLabOrders = (
  episodeId: String,
  page: string,
  limit: string
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/episodes/${episodeId}/dischargeForm/labOrders?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_EPISODE_LAB_ORDERS, payload: response.data });
};

export const fetchRadOrders = (
  episodeId: String,
  page: string,
  limit: string
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/episodes/${episodeId}/dischargeForm/radOrders?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_EPISODE_RAD_ORDERS, payload: response.data });
};

export const fetchAppointments = (
  episodeId: String,
  page: string,
  limit: string
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/episodes/${episodeId}/dischargeForm/appointments?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_EPISODE_APPOINTMENTS, payload: response.data });
};
