import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CButton,
} from "@coreui/react";
import { fetchRadOrders } from "actions/discharge-form/dischargeForm";
import { OLD_PRESCRIPTION, LABS } from "constants/constants";
import i18next from "i18next";

const RadOrders = ({ setActiveScreen, onCancel, episode }) => {
  const dispatch = useDispatch();
  const { episodeId } = useParams();
  const fields = [
    {key: "radiology_orders_names",label: i18next.t("Radiology Orders Names")},
    {key: "date",label: i18next.t("date")},
    ];
  const radOrders = useSelector((state) => state.dischargeForm.rads);

  useEffect(() => {
    dispatch(fetchRadOrders(episode.id));
  }, [dispatch, episode.id]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="table-title">{i18next.t("Imaging Orders")}</div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CCardBody>
              <CDataTable
                items={radOrders}
                fields={fields}
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                itemsPerPage={5}
                pagination
                scopedSlots={{
                  radiology_orders_names: (item) => (
                    <td>{`${item.investigationTypeText}`}</td>
                  ),
                  date: (item) => <td>{item.date}</td>,
                }}
              />
            </CCardBody>
          </CCard>
          <div className="my-2 float-right">
            <CButton
              style={{
                backgroundColor: "#30c5b6",
                borderColor: "#30c5b6",
                color: "white",
                "width":"7em"
              }}
              className="mx-1"
              onClick={() => setActiveScreen(LABS)}
            >
              {i18next.t("Previous")}
            </CButton>
            <CButton
              color="primary"
              className="mx-1"
              onClick={() => setActiveScreen(OLD_PRESCRIPTION)}
            >
              {i18next.t("next")}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </>
  );
};
export default RadOrders;
