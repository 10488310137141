import React, { useEffect, useState } from "react";
import { Api, getTokenHeader } from "services/Api";
import VideoChatRoom from "./VideoChatRoom";

const VideoChat = ({ roomName }) => {
  const [token, setToken] = useState(null);
  console.log("roomName>>" + roomName); 
  useEffect(() => {
    Api.post(
      `/api/admin/meeting/accessToken`,
      { room_name: roomName },
      getTokenHeader()
    )
      .then((response) => {
        setToken(response.data.token);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [roomName]);

  return token ? <VideoChatRoom roomName={roomName} token={token} /> : <></>;
};
export default VideoChat;
