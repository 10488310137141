import React from "react";
import { useState } from "react";

import { CCol, CFormGroup, CInput, CSwitch } from "@coreui/react";

import { DAYS } from "constants/clinic";
import "../../../scss/style.scss";
import { getIndicatorPerDay } from "helpers/helpers";
import i18next from "i18next";

const ClinicOccurrences = ({
  register,
  watch,
  Validation,
  getClassName,
  setValue,
  errors,
}) => {
  const [dayPeriod, setDayPeriod] = useState({});
  const renderTime = (item, type) => {
    return (
      <CInput
        className={getClassName(
          `${item}_${type}`,
          `${item}_${type}`,
          watch,
          errors
        )}
        type="time"
        id={`${item}_${type}`}
        min={type === "to" ? watch(`${item}_from`) : null}
        name={`${item}_${type}`}
        innerRef={register(Validation[`${item}_${type}`])}
        onChange={(e) =>
          setDayPeriod((dayPeriod) => ({
            ...dayPeriod,
            [item]: { ...dayPeriod[item], [type]: e.target.value },
          }))
        }
      />
    );
  };

  const renderSlotTime = (item) => (
    <CInput
      className={getClassName(
        `${item}_slot_time`,
        `${item}_slot_time`,
        watch,
        errors
      )}
      type="number"
      min={15}
      name={`${item}_slot_time`}
      innerRef={register(Validation[`${item}_slot_time`])}
      onChange={(e) =>
        setDayPeriod((dayPeriod) => ({
          ...dayPeriod,
          [item]: { ...dayPeriod[item], slotTime: e.target.value },
        }))
      }
    />
  );

  const onDayStatusChange = (item) => {
    if (!watch(item)) {
      console.log("reset");
      setValue(`${item}_from`, null);
      setValue(`${item}_to`, null);
      setValue(`${item}_slot_time`, null);
    }
  };

  const renderIndicator = (item) => {
    let indicator = 0;
    if (
      typeof dayPeriod[item] === "object" &&
      dayPeriod[item].hasOwnProperty("from") &&
      dayPeriod[item].hasOwnProperty("to") &&
      dayPeriod[item].hasOwnProperty("slotTime")
    ) {
      indicator = getIndicatorPerDay(
        dayPeriod[item].from,
        dayPeriod[item].to,
        dayPeriod[item].slotTime
      );
    }
    return indicator;
  };

  const Rows = DAYS.map((item) => (
    <CFormGroup row className="bg-light py-3 pl-3 occurence-style" key={item}>
      <CCol md="1">
        <CSwitch
          onChange={() => onDayStatusChange(item)}
          name={item}
          className="mr-1"
          color="primary"
          shape="pill"
          innerRef={register}
        />
      </CCol>
      <CCol md="11">
        <fieldset className="row" disabled={!watch(item)}>
          <CCol
            md="2"
            style={{ display: "inherit", textTransform: "capitalize" }}
          >
            {item}
          </CCol>
          <CCol md="3">{renderTime(item, "from")}</CCol>
          <CCol md="3">{renderTime(item, "to")}</CCol>
          <CCol md="3">{renderSlotTime(item)}</CCol>
          <CCol md="1">{renderIndicator(item)}</CCol>
        </fieldset>
      </CCol>
    </CFormGroup>
  ));
  return (
    <>
      <CFormGroup row />
      <CFormGroup
        row
        className="badge badge-secondary clinci-occurrences-header"
      >
        <CCol md="1">{i18next.t("Active")}</CCol>
        <CCol md="11">
          <div className="row">
            <CCol md="2">{i18next.t("Day")}</CCol>
            <CCol md="3">{i18next.t("From")}</CCol>
            <CCol md="3">{i18next.t("To")}</CCol>
            <CCol md="3">{i18next.t("Slot Time")}</CCol>
            <CCol md="1">{i18next.t("#")}</CCol>
          </div>
        </CCol>
      </CFormGroup>
      {Rows}
    </>
  );
};

export default ClinicOccurrences;
