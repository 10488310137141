import React from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardSubtitle,
  CButton,
  CCardTitle,
} from "@coreui/react";
import { cilDoor } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { cilHistory } from "@coreui/icons/js/index";
import Avatar from "views/partials/Avatar";
import { getAge } from "helpers/helpers";
import { LOBBY_STATE_IN_LOBBY } from "constants/constants";
import i18next from "i18next";

const iconStyle = {
  fontSize: "1.3rem",
  width: "1.3rem",
  height: "1.3rem",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
};
// patient card appears in the agenda page
const PatientCard = ({ appointments, toggleMedicalHistory }) => {
  return (appointments.data?appointments.data:appointments).map((item) => (
    <CCard className="my-1 text-theme-blue clinic-card">
      <CCardBody className="py-1">
        <CRow className="align-items-center justify-content-between">
          <CCol md="1">
            <CRow>
              <CCol xs="12">
                <Avatar
                  src={item.patient.avatar}
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  className="float-left"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="4">
            <CRow className="align-items-center">
              <CCol xs="4" className="d-flex flex-column">
                <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                  {`${item.patient.first_name} ${item.patient.last_name}`}
                </CCardTitle>
                <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                  {item.cause ? item.cause : "Cause Not Found"}
                </CCardSubtitle>
              </CCol>
              <CCol xs="2" className="d-flex flex-column">
                <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                  {i18next.t("Age")}
                </CCardTitle>
                <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                  {item.patient.birth_date
                    ? getAge(item.patient.birth_date)
                    : "-"}
                </CCardSubtitle>
              </CCol>
              <CCol xs="4" className="d-flex flex-column">
                <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                  {i18next.t("Time")}
                </CCardTitle>
                <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                  {`${+item?.schedule_time?.split(" ")[1].split(":")[0]}` < 12
                    ? `${item?.schedule_time?.split(" ")[0]} ${
                        item?.schedule_time?.split(" ")[1].split(":")[0] % 12
                      }:${item?.schedule_time?.split(" ")[1].split(":")[1]} AM`
                    : `${item?.schedule_time?.split(" ")[0]} ${
                        item?.schedule_time?.split(" ")[1].split(":")[0] % 12
                      }:${item?.schedule_time?.split(" ")[1].split(":")[1]} PM`}
                </CCardSubtitle>
              </CCol>
              <CCol xs="2" className="d-flex flex-column">
                <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent">
                  Order
                </CCardTitle>
                <CCardSubtitle className="clinic-subtitle float-left w-fcontent">
                  {item.lobby_order ? item.lobby_order : "None"}
                </CCardSubtitle>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="1">
            <CRow className="justify-content-end align-items-center">
              <CCol xs="12" className="d-flex flex-column">
                {Number(item.lobby_state) === LOBBY_STATE_IN_LOBBY && (
                  <CCardTitle className="clinic-title avh-font-bold float-left w-fcontent lobby">
                    <span>
                      <CIcon style={iconStyle} content={cilDoor} />
                      <span>In Lobby</span>
                    </span>
                  </CCardTitle>
                )}
              </CCol>
            </CRow>
          </CCol>
          <CCol md="2">
            <CRow className="justify-content-end align-items-center">
              <CCol xs="12" className="d-flex flex-column">
                {item.complaint
                  ? item.complaint.substring(0, 100)
                  : i18next.t("NO COMPLAINT")}
              </CCol>
            </CRow>
          </CCol>
          <CCol md="4">
            <CButton className="avh-btn px-4 py-3 text-decoration-none text-white avh-font-bold float-right"
            type="button"
            onClick={() => toggleMedicalHistory(item.patient.id)}>
              <CIcon
                content={cilHistory}
                className="discharge-icon mx-1"
              ></CIcon>
              {i18next.t("medical history")}
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  ));
}
const Patients = ({ appointments, toggleMedicalHistory }) => (
  <PatientCard appointments={appointments} toggleMedicalHistory={toggleMedicalHistory}/>
);

export default Patients;
