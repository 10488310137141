import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CRow,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CSwitch,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import BasicInputs from "./inputs/BasicInputs";
import StaffInputs from "./inputs/StaffInputs";
import "../../../scss/style.scss";
import { getValidation } from "views/hospitals/form/ServiceProviderValidation";
import { useHistory } from "react-router-dom";
import i18next from "i18next";


const User = ({
  onSubmit,
  user,
  roles,
  levels,
  specialties,
  label,
  countries,
  cities,
  onCountryChange,
  hospitals,
}) => {

  let defaultValues = {};
  if (user) {
    defaultValues = { ...user, avatar: "" };
  }
  const { register, handleSubmit, control, setValue, watch, errors,formState } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { isSubmitting } = formState;
  const [isStaff, setIsStaff] = useState(false);
  const handelOnchange = (e) => {
    setIsStaff(e.target.checked);
  };

  useEffect(() => {
    if (user) setIsStaff(user.is_staff_member);
  }, [user]);

  // const Validation = getValidation(watch);
  const history = useHistory();

  const onCancel = () => {
    history.push("/users");
  };

  return (
    <CRow>
      <CCol xs="12">
        <CCard>
          <CForm
            onSubmit={handleSubmit(onSubmit)}
            method="post"
            className="form-horizontal"
            encType="multipart/form-data"
          >
            <CCard>
              <CCardHeader>
                <h4 className="header-label-title">{label}</h4>
              </CCardHeader>
              <CCardBody>
                <BasicInputs
                  register={register}
                  control={control}
                  user={user}
                  uuid_picture={user?.uuidimg}
                  avatar={user?.avatar}
                  roles={roles}
                  countries={countries}
                  onCountryChange={onCountryChange}
                  cities={cities}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                />

                <CFormGroup row>
                  <CCol
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="my-2"
                  >
                    <CLabel style={{fontSize:"1.3em"}} htmlFor="is_staff_member" className="mx-3 labels">
                      {i18next.t("Is Health Care Staff")}
                    </CLabel>
                    <CSwitch
                      id="is_staff_member"
                      labelOn={i18next.t("Yes")}
                      labelOff={i18next.t("No")}
                      name="is_staff_member"
                      className="mr-3"
                      size="lg"
                      color="primary"
                      shape="pill"
                      innerRef={register}
                      onChange={(e) => handelOnchange(e)}
                    />
                  </CCol>
                </CFormGroup>
                <StaffInputs
                  register={register}
                  control={control}
                  isStaff={isStaff}
                  specialties={specialties}
                  levels={levels}
                  hospitals={hospitals}
                  user={user}
                  watch={watch}
                  errors={errors}
                />
              </CCardBody>
              <CCardFooter>
                <CFormGroup row>
                  <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CButton
                      type="submit"
                      color="primary"
                      className="mx-1 btn-form"
                      disabled={ isSubmitting }
                    >
                      {isSubmitting ? (
                    <>
                      <CSpinner color="primary" size="sm" variant="grow" />
                      <span style={{ color: "dark" }}>{i18next.t("Loading")}...</span>
                    </>
                  ) : (
                    <span>{i18next.t("Save")}</span>
                  )}
                    </CButton>
                  </CCol>
                  <CCol xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CButton
                      color="danger"
                      type="reset"
                      onClick={onCancel}
                      className="mx-1 btn-form"
                    >
                      {i18next.t("Cancel")}
                    </CButton>
                  </CCol>
                </CFormGroup>
              </CCardFooter>
            </CCard>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default User;
