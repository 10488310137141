import React from "react";
import { CCol, CFormGroup, CLabel, CInput, CRow } from "@coreui/react";
import { TYPE_TEXT, TYPE_INTEGER, TYPE_FLOAT } from "constants/questions";

const SurveyTextQuestion = (props) => {
  const {
    question,
    register,
    setValue,
    questionName,
    questionValueName,
  } = props;
  const calculateAnswerValue = (event) => {
    setValue(questionValueName, event.target.value);
  };

  const renderSwitch = (data_type) => {
    switch (Number(data_type)) {
      case TYPE_TEXT:
        return (
          <>
            <CInput
              type="hidden"
              innerRef={register}
              name={questionValueName}
            />

            <CInput
              type="text"
              id={`survey_question_${question.id}`}
              name={questionName}
              innerRef={register}
              onBlur={calculateAnswerValue}
            />
          </>
        );
      case TYPE_INTEGER:
        return (
          <>
            <CInput
              type="hidden"
              innerRef={register}
              name={questionValueName}
            />
            <CInput
              type="number"
              id={`survey_question_${question.id}`}
              name={questionName}
              innerRef={register}
              onBlur={calculateAnswerValue}
            />
          </>
        );
      case TYPE_FLOAT:
        return (
          <>
            <CInput
              type="hidden"
              innerRef={register}
              name={questionValueName}
            />

            <CInput
              type="number"
              step="0.0100000"
              id={`survey_question_${question.id}`}
              name={questionName}
              innerRef={register}
              onBlur={calculateAnswerValue}
            />
          </>
        );
      default:
        return "";
    }
  };

  return (
    <CCol md={12}>
      <br />
      <CLabel
        className="float-left labels"
        htmlFor={`survey_question_${question.id}`}
      >
        <h6>{question.question}</h6>
      </CLabel>
      {renderSwitch(question.data_type)}
    </CCol>
  );
};

export default SurveyTextQuestion;
