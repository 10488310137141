import { Api, getTokenHeader } from "services/Api";
import {
  FETCH_LOBBY,
  FETCH_LOBBY_APPOINTMENTS,
  PATIENT_JOINED_LOBBY,
  PATIENT_LEAVED_LOBBY,
  LIST_JOINED_PATIENTS,
} from "./types";

export const fetchLobby = (token: String): Object => async (dispatch) => {
  const response = await Api.get(`/api/admin/lobby/${token}/show`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_LOBBY, payload: response.data });
};
export const fetchLobbyAppointments = (token: String): Object => async (
  dispatch
) => {
  const response = await Api.get(`/api/admin/lobby/${token}/appointments`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_LOBBY_APPOINTMENTS, payload: response.data });
};

export const patientJoinedLobby = (patient: Object): Object => async (
  dispatch
) => {
  dispatch({ type: PATIENT_JOINED_LOBBY, payload: patient });
};

export const patientLeavedLobby = (patient: Object): Object => async (
  dispatch
) => {
  dispatch({ type: PATIENT_LEAVED_LOBBY, payload: patient });
};

export const listJoinedpatients = (patients: Array): Object => async (
  dispatch
) => {
  dispatch({ type: LIST_JOINED_PATIENTS, payload: patients });
};
