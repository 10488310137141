import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCol,
  CDataTable,
  CButton,
  CModal,
  CModalFooter,
  CModalBody,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
  CRow,
  CCardHeader,
  CCard,
} from "@coreui/react";
import { useForm } from "react-hook-form/dist/index";
import {
  assignInvestigation,
  getCenterInvestigations,
} from "actions/episodes/investigations";
import ExecuteInvestigation from "./executInvestigation";
import {
  getClassName,
  getValidation,
} from "views/hospitals/form/ServiceProviderValidation";
import i18next from "i18next";

const InvestigationTab = ({ centerType, centerId }) => {
  const [assignModal, setAssignModal] = useState(false);
  const [processInvestigation, setProcessInvestigation] = useState(null);
  const dispatch = useDispatch();
  const centerInvestigations = useSelector(
    (state) => state.investigation.centerInvestigations
  );

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    dispatch(getCenterInvestigations(centerType, centerId));
  }, [centerType, centerId, dispatch]);

  const loadCenterInvestigations = () => {
    dispatch(getCenterInvestigations(centerType, centerId));
  };

  const onAssignInvestigation = (formData) => {
    dispatch(assignInvestigation(centerType, centerId, formData));
    dispatch(getCenterInvestigations(centerType, centerId));
    setAssignModal(false);
    dispatch(getCenterInvestigations(centerType, centerId));
  };

  const Validation = getValidation(watch);

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-between align-items-center my-1">
                <CCol md="6">
                  <div className="float-left table-title">{i18next.t("Investigations")}</div>
                </CCol>
                <CCol md="6">
                  <CButton
                    style={{ width: "13em" }}
                    type="button"
                    onClick={() => setAssignModal(true)}
                    className="create-button float-right m-0 wi btn btn-success"
                  >
                    {i18next.t("Assign Investigation")}
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CDataTable
              items={centerInvestigations || []}
              fields={[
                { key: "code", label:  i18next.t("code") },
                { key: "investigationType", label:  i18next.t("Investigation Type") },
                { key: "status", label:  i18next.t("Status") },
                { key: "order_notes", label:  i18next.t("Order Notes") },
                { key: "actions", label:  i18next.t("Actions") },
              ]}
              noItemsViewSlot={i18next.t("No Available Items")}
              hover
              striped
              scopedSlots={{
                actions: (item) => (
                  <td>
                    <CButton
                      onClick={() => setProcessInvestigation(item)}
                      color="warning"
                    >
                      {i18next.t("Process")}
                    </CButton>
                  </td>
                ),
              }}
            />
          </CCard>
        </CCol>
      </CRow>
      <CModal
        show={assignModal}
        onClose={() => setAssignModal(false)}
        size="lg"
      >
        <CForm
          onSubmit={handleSubmit(onAssignInvestigation)}
          method="post"
          className="form-horizontal"
        >
          <h4 className="header-label-title">{i18next.t("Assign Investigation")}</h4>
          <CModalBody>
            <CFormGroup row>
              <CCol sm={12} md={12} xs={12} lg={12} xl={12}>
                <CLabel htmlFor="investigation_code" className="required">
                  {i18next.t("Investigation Code")}
                </CLabel>
                <CInput
                  className={`${getClassName(
                    "investigation_code",
                    "investigation_code",
                    watch,
                    errors
                  )}`}
                  type="text"
                  innerRef={register(Validation.require)}
                  id="investigation_code"
                  name="investigation_code"
                />
              </CCol>
            </CFormGroup>
          </CModalBody>
          <CModalFooter>
            <CButton type="submit" color="primary">
              {i18next.t("Save")}
            </CButton>
            <CButton
              type="reset"
              color="danger"
              onClick={() => setAssignModal(false)}
            >
              {i18next.t("Cancel")}
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
      <ExecuteInvestigation
        modal={processInvestigation}
        external={false}
        investigation={processInvestigation}
        onCancel={() => setProcessInvestigation(null)}
        loadCenterInvestigations={loadCenterInvestigations}
      />
    </>
  );
};
export default InvestigationTab;
