import React from "react";
import { Controller } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CInputFile,
  CInputRadio,
  CRow,
  CInputCheckbox,
} from "@coreui/react";
import Select from "react-select";
import generator from "generate-password";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import { Api, getTokenHeader } from "services/Api";
import { TimeZoneSelect } from "views/partials/AsyncSelectComponents";
import getValidation from "views/episode-management/screens/spoke/cases/forms/EpisodeFormValidation";
import { getClassName } from "views/hospitals/form/ServiceProviderValidation";
import { calculateAge } from "helpers/helpers";
import i18next from "i18next";

const BasicInputs = ({
  register,
  control,
  user,
  uuid_picture,
  avatar,
  roles,
  countries,
  onCountryChange,
  cities,
  setValue,
  watch,
  errors,
  isStaff,

}) => {
  const autoGeneratePassword = (e) => {
    let password = e.target.checked
      ? generator.generate({ length: 10, numbers: true })
      : "";
    setValue("password", password);
  };
  const getCountriesOptions = async (inputValue) => {
    let options = [];
    const url = `/api/admin/country?q=${inputValue}`;
    options = await Api.get(url, {
      ...getTokenHeader(),
    }).then((response) => response.data.data);
    return options;
  };

  const Validation = getValidation(watch);

  const handleBirthDateChange = (e) => {
    const birthDate = e.target.value;
    let age = calculateAge(birthDate);
    setValue("age",age)
  };
  if(user?.birth_date){
    let age = calculateAge(user.birth_date);
    setValue("age",age)
  }
  return (
    <>
      <CFormGroup row>
        <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
          <CRow className="justify-content-start">
            <CCol xs="12">
              <h4 className="header-label">{i18next.t("General Info")}</h4>
            </CCol>
          </CRow>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
              <CLabel
                htmlFor="first_name"
                className="float-left labels required"
              >
                {i18next.t("First Name")}
              </CLabel>
              <CInput
                className={`${getClassName(
                  "first_name",
                  "first_name",
                  watch,
                  errors
                )}`}
                placeholder={i18next.t("Enter Your First Name")}
                type="text"
                id="first_name"
                name="first_name"
                innerRef={register(Validation.first_name)}
              />
            </CCol>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
              <CLabel
                htmlFor="last_name"
                className="float-left labels required"
              >
                {i18next.t("Last Name")}
              </CLabel>
              <CInput
                className={`${getClassName(
                  "last_name",
                  "last_name",
                  watch,
                  errors
                )}`}
                placeholder={i18next.t("Enter Your Last Name")}
                type="text"
                id="last_name"
                name="last_name"
                innerRef={register(Validation.last_name)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
              <CLabel htmlFor="email" className="float-left labels required">
                {i18next.t("Email")}
              </CLabel>
              <CInput
                className={`${getClassName("email", "email", watch, errors)}`}
                type="email"
                id="email"
                name="email"
                placeholder={i18next.t("Enter Your Email")}
                autoComplete="email"
                innerRef={register(Validation.email)}
              />
            </CCol>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
              <CLabel htmlFor="uuid" className="float-left labels required">
                {i18next.t("National ID")}
              </CLabel>
              <CInput
                className={`${getClassName("uuid", "uuid", watch, errors)}`}
                type="number"
                id="uuid"
                name="uuid"
                placeholder={i18next.t("Enter Your National Id Number")}
                innerRef={register(Validation.uuid)}
              />
            </CCol>
            {/* <CCol xs="12" md="3" className="my-2">
            <CLabel htmlFor="password" className="float-left labels">Password</CLabel>
            <CInput
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              innerRef={register}
            />
          </CCol>
          <CCol xs="12" md="3" className="my-2">
          <CFormGroup variant="custom-checkbox" inline>
                      <CInputCheckbox
                        custom
                        id="auto-generate-password"
                        name="auto-generate-password"
                        onChange={autoGeneratePassword}
                      />
                      <CLabel variant="custom-checkbox" htmlFor="auto-generate-password">
                      Auto Generate
                      </CLabel>
                    </CFormGroup>
          </CCol> */}
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
              <div className="d-flex flex-column">
                <CLabel
                  htmlFor="roles"
                  className="float-left labels required"
                  style={{ width: "fit-content" }}
                >
                  {i18next.t("Role")}
                </CLabel>
                <Controller
                  control={control}
                  id="roles"
                  name="roles"
                  isMulti
                  required
                  cacheOptions
                  options={roles}
                  value={user ? user.roles : []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  as={Select}
                />
              </div>
            </CCol>
            {/* <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
            <CLabel htmlFor="uuid" className="float-left labels">National ID</CLabel>
            <CInput type="text" id="uuid" name="uuid" innerRef={register} />
          </CCol> */}
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
              <CRow>
                <CCol xs="12">
                  <CLabel className="float-left labels required">{i18next.t("Gender")}</CLabel>
                </CCol>
              </CRow>
              <CFormGroup
                variant="custom-radio"
                inline
                className="float-left labels"
              >
                <CInputRadio
                  className={`${getClassName(
                    "gender",
                    "gender",
                    watch,
                    errors
                  )}`}
                  custom
                  id="inline-radio1"
                  name="gender"
                  value="male"
                  innerRef={register(Validation.gender)}
                />
                <CLabel variant="custom-checkbox" htmlFor="inline-radio1">
                  {i18next.t("Male")}
                </CLabel>
              </CFormGroup>
              <CFormGroup
                variant="custom-radio"
                inline
                className="float-left labels"
              >
                <CInputRadio
                  className={`${getClassName(
                    "gender",
                    "gender",
                    watch,
                    errors
                  )}`}
                  custom
                  id="inline-radio2"
                  name="gender"
                  value="female"
                  innerRef={register(Validation.gender)}
                />
                <CLabel variant="custom-checkbox" htmlFor="inline-radio2">
                  {i18next.t("Female")}
                </CLabel>
              </CFormGroup>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2 ">
              <CLabel htmlFor="password" className="float-left labels required">
                {i18next.t("Password")}
              </CLabel>
              <CInput
                className={`${getClassName(
                  "password",
                  "password",
                  watch,
                  errors
                )}`}
                type="password"
                id="password"
                name="password"
                placeholder={i18next.t("Enter Your Password")}
                innerRef={register(Validation.password)}
              />
            </CCol>
            <CCol
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="my-2 auto-generate-password"
            >
              <CFormGroup variant="custom-checkbox" inline>
                <CInputCheckbox
                  custom
                  id="auto-generate-password"
                  name="auto-generate-password"
                  onChange={autoGeneratePassword}
                />
                <CLabel
                  variant="custom-checkbox"
                  htmlFor="auto-generate-password"
                >
                  {i18next.t("Auto Generate")}
                </CLabel>
              </CFormGroup>
            </CCol>

            {/* <CCol xs="12" md="6" className="my-2">
            <CLabel className="float-left labels">Mobile No.</CLabel>
            <CInput type="tel" id="phone" name="phone" innerRef={register} />
          </CCol> */}
          </CFormGroup>
        </CCol>
        <CCol xs={12} sm={12} md={12} lg={12} xl={12}>
          <CRow className="justify-content-start">
            <CCol xs="12">
              <h4 className="header-label">{i18next.t("Contact Info")}</h4>
            </CCol>
          </CRow>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
              <CLabel className="float-left labels required">{i18next.t("Mobile No")}.</CLabel>
              <CInput type="tel" id="phone" name="phone" required={isStaff} innerRef={register} />
            </CCol>
            {/* <CCol xs="12" md="6" className="my-2">
            <div className="d-flex flex-column">
              <CLabel htmlFor="roles" className="float-left" style={{ width: "fit-content" }}>Role</CLabel>
              <Controller
                control={control}
                id="roles"
                name="roles"
                isMulti
                cacheOptions
                options={roles}
                value={user ? user.roles : []}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                as={Select}
              />
            </div>
          </CCol> */}
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
            <CLabel className="labels required" htmlFor="country">
                    {i18next.t("Country")}
                  </CLabel>
                  <CSelect
                    custom
                    name="country"
                    id="country"
                    onChange={onCountryChange}
                    className={`${getClassName(
                      "country",
                      "country",
                      watch,
                      errors
                    )}`}
                    innerRef={register(Validation.bed)}
                  >
                    <option key="0" value="" disabled>
                      {i18next.t("Select Country")}
                    </option>
                    {user && user.countryobj
                      ? RenderSelectOptions(countries, user.countryobj)
                      : RenderSelectOptions(countries)}
                  </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
            <CLabel htmlFor="city" className="labels required">
                    {i18next.t("City")}
                  </CLabel>
                  <CSelect
                    className={`${getClassName("city", "city", watch, errors)}`}
                    innerRef={register(Validation.bed)}
                    custom
                    name="city"
                    id="city"
                  >
                    <option key="0" value="" disabled>
                      {i18next.t("Select City")}
                    </option>
                    {user && user.cityobj
                      ? RenderSelectOptions(cities, user.cityobj)
                      : RenderSelectOptions(cities)}
                  </CSelect>
            </CCol>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
              <CLabel htmlFor="address" className="float-left labels required">
                {i18next.t("Address")}
              </CLabel>
              <CInput
                className={`${getClassName(
                  "address",
                  "address",
                  watch,
                  errors
                )}`}
                type="text"
                id="address"
                name="address"
                innerRef={register(Validation.first_name)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xxs={12} sm={12} md={6} lg={6} xl={6} className="my-2 ">
              <CLabel htmlFor="avatar" className="float-left labels">
                {i18next.t("Profile Picture")}
              </CLabel>
              {avatar ? (
              <>
                <CRow>
                  <CCol>
                    <img src={avatar} height={200} width={200} />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
              <CInputFile
                className={`${getClassName("avatar", "avatar", watch, errors)}`}
                style={{ marginBottom: ".5em", top: "0" }}
                id="avatar"
                class="form-control file-upload-button"
                name="avatar"
                innerRef={register(Validation.file2)}
                required={isStaff}
              />
              <CLabel
                style={{
                  right: "1em",
                  left: "1em",
                }}
                htmlFor="avatar"
                variant="custom-file"
              >
                {(watch("avatar") &&
                  watch("avatar")[0] &&
                  watch("avatar")[0].name) ||
                  "Choose file..."}
              </CLabel>
              {errors.avatar?.message ? (
                <strong style={{ color: "red" }}>
                  {i18next.t("The Profile Picture must be a file of type image: jpeg, jpg, png.")}
                </strong>
              ) : (
                <strong>
                  {i18next.t("The Profile Picture must be a file of type image: jpeg, jpg, png.")}

                </strong>
              )}

                  </CCol>
                </CRow>
              </>
              )  : ( <><CInputFile
                className={`${getClassName("avatar", "avatar", watch, errors)}`}
                style={{ top: "0" }}
                id="avatar"
                class="form-control file-upload-button"
                name="avatar"
                innerRef={register}
                required={isStaff}

              />
              <CLabel
                style={{
                  top: "1em",
                  right: "1em",
                  left: "1em",
                  marginTop: ".5em",
                }}
                htmlFor="avatar"
                variant="custom-file"
              >
                {(watch("avatar") &&
                  watch("avatar")[0] &&
                  watch("avatar")[0].name) ||
                  "Choose file..."}
              </CLabel>
              {errors.avatar?.message && isStaff ? (
                <strong style={{ color: "red" }}>
                  {i18next.t("The Profile Picture must be a file of type image: jpeg, jpg, png.")}
                </strong>
              ) : (
                <strong>
                   {i18next.t("The Profile Picture must be a file of type image: jpeg, jpg, png.")}
                </strong>
              )}</>
              )}
            </CCol>
            <CCol xs={12} sm={12} md={6} lg={6} xl={6} className="my-2">
              <CLabel
                htmlFor="uuid_picture"
                className="float-left labels required"
              >
                {i18next.t("ID Scan")}
              </CLabel>
              {uuid_picture ? (
              <>
                <CRow>
                  <CCol>
                    <img src={uuid_picture} height={200} width={200} />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CInputFile
                      className={`${getClassName(
                        "uuid_picture",
                        "uuid_picture",
                        watch,
                        errors
                      )}`}
                      style={{ marginBottom: ".5em", top: "0" }}
                      id="uuid_picture"
                      class="form-control file-upload-button"
                      name="uuid_picture"
                      innerRef={register(Validation.file2)}
                    />
                    <CLabel
                      style={{
                        right: "1em",
                        left: "1em",
                      }}
                      className={`${getClassName(
                        "uuid_picture",
                        "uuid_picture",
                        watch,
                        errors
                      )}`}
                      htmlFor="uuid_picture"
                      variant="custom-file"
                    >
                      {(watch("uuid_picture") &&
                        watch("uuid_picture")[0] &&
                        watch("uuid_picture")[0].name) ||
                        "Choose file..."}
                    </CLabel>
                    {errors.uuid_picture?.message ? (
                      <strong style={{ color: "red" }}>
                        {i18next.t("The ID Scan must be a file of type image: jpeg, jpg, png.")}
                      </strong>
                    ) : (
                      <strong>
                        {i18next.t("The ID Scan must be a file of type image: jpeg, jpg, png.")}
                      </strong>
                    )}
                </CCol>
              </CRow>
              </>
              )  : (<>
              <CInputFile
                className={`${getClassName(
                  "uuid_picture",
                  "uuid_picture",
                  watch,
                  errors
                )}`}
                style={{ top: "0" }}
                id="uuid_picture"
                class="form-control file-upload-button"
                name="uuid_picture"
                innerRef={register(Validation.file)}
              />
              <CLabel
                style={{
                  top: "1em",
                  right: "1em",
                  left: "1em",
                  marginTop: ".5em",
                }}
                className={`${getClassName(
                  "uuid_picture",
                  "uuid_picture",
                  watch,
                  errors
                )}`}
                htmlFor="uuid_picture"
                variant="custom-file"
              >
                {(watch("uuid_picture") &&
                  watch("uuid_picture")[0] &&
                  watch("uuid_picture")[0].name) ||
                  "Choose file..."}
              </CLabel>
              {errors.uuid_picture?.message ? (
                <strong style={{ color: "red" }}>
                  {i18next.t("The ID Scan must be a file of type image: jpeg, jpg, png.")}
                </strong>
              ) : (
                <strong>
                  {i18next.t("The ID Scan must be a file of type image: jpeg, jpg, png.")}
                </strong>
              )}
              </>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs={12} sm={12} md={12} lg={12} xl={12} className="my-2">
              <TimeZoneSelect
                label={i18next.t("Time Zone")}
                control={control}
                rules={Validation.time_zone}
                isValid={!errors.time_zone}
                name="time_zone"
              />
            </CCol>
          </CFormGroup>

            <CFormGroup row>
            <CCol sm={12} md={6} xs={12} lg={6} xl={6} className="my-2">
                    <CLabel className="required">{i18next.t("Birth Date")}</CLabel>
                    <CInput
                      // className={getClassName("birth_date")}
                      type="date"
                      id="birth-date"
                      name="birth_date"
                      innerRef={register}
                      onChange={handleBirthDateChange}
                      max={new Date().toISOString().substr(0, 10)}
                    />
                  </CCol>
                  <CCol sm={12} md={6} xs={12} lg={6} xl={6} className="my-2">
                    <CLabel>{i18next.t("Age")}</CLabel>
                    <CInput
                      type="text"
                      id="age"
                      name="age"
                      innerRef={register}
                      readOnly
                    />
                  </CCol>
          </CFormGroup>
        </CCol>
      </CFormGroup>
      {/* <CFormGroup row>
      <CCol xs="12" md="3">
        <CLabel htmlFor="first_name">First Name</CLabel>
        <CInput
          type="text"
          id="first_name"
          name="first_name"
          innerRef={register}
        />
      </CCol>
      <CCol xs="12" md="3">
        <CLabel htmlFor="last_name">Last Name</CLabel>
        <CInput
          type="text"
          id="last_name"
          name="last_name"
          innerRef={register}
        />
      </CCol>
      <CCol xs="12" md="3">
        <CLabel htmlFor="email">Email</CLabel>
        <CInput
          type="email"
          id="email"
          name="email"
          placeholder="Enter Email"
          autoComplete="email"
          innerRef={register}
        />
      </CCol>
      <CCol xs="12" md="3">
        <CLabel htmlFor="password">Password</CLabel>
        <CInput
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          innerRef={register}
        />
      </CCol>
    </CFormGroup>
    <CFormGroup row>
      <CCol xs="12" md="3">
        <CLabel htmlFor="roles">Role</CLabel>
        <Controller
          control={control}
          id="roles"
          name="roles"
          isMulti
          cacheOptions
          options={roles}
          value={user ? user.roles : []}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          as={Select}
        />
      </CCol>
      <CCol xs="12" md="3">
        <CLabel htmlFor="country">Country</CLabel>
        <CSelect
          custom
          name="country"
          id="country"
          onChange={onCountryChange}
          innerRef={register}
        >
          <option key="0" selected disabled>
            Select Country
          </option>
          {RenderSelectOptions(countries)}
        </CSelect>
      </CCol>
      <CCol xs="12" md="3">
        <CLabel htmlFor="city">City</CLabel>
        <CSelect custom name="city" id="city" innerRef={register}>
          <option key="0" selected disabled>
            Select City
          </option>
          {RenderSelectOptions(cities)}
        </CSelect>
      </CCol>
      <CCol xs="12" md="3">
        <CLabel htmlFor="address">Address</CLabel>
        <CInput type="text" id="address" name="address" innerRef={register} />
      </CCol>
    </CFormGroup>
    <CFormGroup row>
      <CCol xs="12" md="3">
        <CLabel htmlFor="uuid">National ID</CLabel>
        <CInput type="text" id="uuid" name="uuid" innerRef={register} />
      </CCol>
      <CCol xs="12" md="3">
        <CLabel htmlFor="uuid_picture">ID Scan</CLabel>
        <CInputFile id="uuid_picture" name="uuid_picture" innerRef={register} />
      </CCol>
      <CCol xs="12" md="3">
        <CLabel>Profile Picture</CLabel>
        <CInputFile id="avatar" name="avatar" innerRef={register} />
      </CCol>
      <CCol md="1" style={{ paddingTop: "3%" }}>
        <CLabel>Gender</CLabel>
      </CCol>
      <CCol md="2" style={{ paddingTop: "3%" }}>
        <CFormGroup variant="custom-radio" inline>
          <CInputRadio
            custom
            id="inline-radio1"
            name="gender"
            value="male"
            innerRef={register}
          />
          <CLabel variant="custom-checkbox" htmlFor="inline-radio1">
            Male
          </CLabel>
        </CFormGroup>
        <CFormGroup variant="custom-radio" inline>
          <CInputRadio
            custom
            id="inline-radio2"
            name="gender"
            value="female"
            innerRef={register}
          />
          <CLabel variant="custom-checkbox" htmlFor="inline-radio2">
            Female
          </CLabel>
        </CFormGroup>
      </CCol>
    </CFormGroup>
    <CFormGroup row>
      <CCol xs="12" md="3">
        <CLabel>Mobile No.</CLabel>
        <CInput type="tel" id="phone" name="phone" innerRef={register} />
      </CCol>
    </CFormGroup> */}
    </>
  );
};

export default BasicInputs;
