import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import find from "lodash/find";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CDataTable,
  CRow,
  CLink,
  CSelect,
  CDropdown,
  CDropdownToggle,
  CDropdownItem,
  CDropdownMenu,
  CButton,
} from "@coreui/react";
import {
  fetchPrescription,
  updatePrescriptionStatus,
  fetchPrescriptions,
} from "actions/prescriptions/prescriptions";
import { TYPE_EPISODE } from "constants/constants";
import CIcon from "@coreui/icons-react";
import {
  cilBrowser,
  cilCheckCircle,
  cilOptions,
  cilPencil,
  cilPrint,
  cilSearch,
  cilXCircle,
} from "@coreui/icons";
import PrescriptionPrint from "./print/Print";
import { TYPE_DISCHARGE_PRESCRIPTION, TYPE_APPOINTMENT } from "constants/constants";

const List = ({
  toggle,
  setIsEditing,
  setPrescriptionId,
  setModifiedId,
  toggleShowModal,
  episodeStatus,
  title,
  type,
  showStatus = true,
  showCreate = false
}) => {
  if (type === TYPE_DISCHARGE_PRESCRIPTION) {
    showCreate = true;
  }
  const [printModal, setPrintModal] = useState(false);
  const { episodeId } = useParams();
  const dispatch = useDispatch();
  const prescriptions = useSelector((state) => state.prescriptions.data);
  const utilities = useSelector((state) => state.prescriptionUtilities);
  const getPrescriptionsList = () => {
    if (prescriptions) {
      return prescriptions.map((item) => {
        const list = { ...item };

        const timeUnit = find(utilities.time_units, {
          id: parseInt(item.frequency_time_unit, 10),
        });

        list.frequency = `Once every ${list.frequency} ${timeUnit ? timeUnit.title : "NA"
          }`;
        const route = find(utilities.routes, {
          id: parseInt(item.route, 10),
        });
        list.route = route ? route.title : item.route;

        return list;
      });
    }
    return [];
  };
  const fields = [
    { key: "drug", label:  i18next.t("drug") },
    { key: "dosage", label:  i18next.t("Dosage") },
    { key: "frequency", label:  i18next.t("frequency") },
    { key: "form", label:  i18next.t("Form") },
    { key: "route", label:  i18next.t("Route") },
    { key: "created_at", label:  i18next.t("Created At") },
    { key: "end_date", label:  i18next.t("End Date") },
    { key: "author", label:  i18next.t("Author") },
    { key: "actions", label:  i18next.t("Actions") },
  ];
  if (type == TYPE_APPOINTMENT) {
    fields.splice(fields.indexOf("author"), 1);
  }
  if (showStatus && type != TYPE_APPOINTMENT) {
    fields.push({ key: "statusText", label: i18next.t("status") });
  }

  const EditPrescription = (id: string) => {
    dispatch(fetchPrescription(id));
    toggle();
    setIsEditing(true);
    setPrescriptionId(id);
    console.log(id);
  };
  const ModifyPrescription = (item) => {
    dispatch(fetchPrescription(item.id));
    setModifiedId(item.id);
    toggle();
  };
  const updateStatus = (id: string, status: string) => {
    dispatch(updatePrescriptionStatus({ status }, id));
  };

  const ShowPrescription = (id: string) => {
    dispatch(fetchPrescription(id));
    setPrescriptionId(id);
    toggleShowModal();
  };

  const handlStatusChange = (e) => {
    console.log(e.target.value);
    dispatch(
      fetchPrescriptions(episodeId, TYPE_EPISODE, null, null, e.target.value)
    );
  };

  const renderActions = (item: Object) => (
    <td>
      <CDropdown variant="btn-group">
        {(item.editable ||
          Number(item.status) === Number(utilities.status_draft) ||
          Number(item.status) === Number(utilities.status_active) ||
          Number(item.status) === Number(utilities.status_hold)) && (
            <CDropdownToggle
              className="action-toggle m-0"
              color="light"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CIcon content={cilOptions} />
            </CDropdownToggle>
          )}
        <CDropdownMenu className="py-0" placement="right-end">
          {(item.editable ||
            Number(item.status) === Number(utilities.status_draft)) && (
              <CDropdownItem
                onClick={() => EditPrescription(item.id)}
                className="text-primary"
              >
                <CIcon content={cilPencil}></CIcon>
                <span className="mx-1">{i18next.t("Edit")}</span>
              </CDropdownItem>
            )}
          {Number(item.status) === Number(utilities.status_active) && (
            <>
              {episodeStatus === 0 ? (
                <>
                  <CDropdownItem
                    onClick={() => ModifyPrescription(item)}
                    className="text-primary"
                  >
                    <CIcon content={cilPencil}></CIcon>
                    <span className="mx-1">{i18next.t("Modify")}</span>
                  </CDropdownItem>
                  <CDropdownItem
                    onClick={() => updateStatus(item.id, utilities.status_hold)}
                    className="text-dark"
                  >
                    <CIcon content={cilBrowser}></CIcon>
                    <span className="mx-1">{i18next.t("Hold")}</span>
                  </CDropdownItem>
                  <CDropdownItem
                    onClick={() =>
                      updateStatus(item.id, utilities.status_discontinued)
                    }
                    className="text-danger"
                  >
                    <CIcon content={cilXCircle}></CIcon>
                    <span className="mx-1">{i18next.t("Discontinue")}</span>
                  </CDropdownItem>
                </>
              ) : (
                ""
              )}
              <CDropdownItem
                onClick={() => ShowPrescription(item.id)}
                className="text-info"
              >
                <CIcon content={cilSearch}></CIcon>
                <span className="mx-1">{i18next.t("show")}</span>
              </CDropdownItem>
            </>
          )}
          {Number(item.status) === Number(utilities.status_hold) && (
            <>
              <CDropdownItem
                onClick={() => updateStatus(item.id, utilities.status_active)}
                className="text-success"
              >
                <CIcon content={cilCheckCircle}></CIcon>
                <span className="mx-1">{i18next.t("Activate")}</span>
              </CDropdownItem>
            </>
          )}
        </CDropdownMenu>
      </CDropdown>
    </td>
  );
  const preparedPrescriptionsList = getPrescriptionsList();
  return (
    <>
      <CCard className="border-0">
        <CCardBody className="text-dark p-0">
          <CRow>
            <CCol xs="12">
              <CCardHeader>
                <CRow>
                {type === TYPE_EPISODE && <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CSelect custom onChange={handlStatusChange}>
                      <option key="0" selected >
                        {i18next.t("Select Status")}
                      </option>
                      <option
                        key={utilities.status_draft}
                        value={utilities.status_draft}
                      >
                        {i18next.t("Draft")}
                      </option>
                      <option
                        key={utilities.status_active}
                        value={utilities.status_active}
                      >
                        {i18next.t("Active")}
                      </option>
                      <option
                        key={utilities.status_hold}
                        value={utilities.status_hold}
                      >
                        {i18next.t("Hold")}
                      </option>
                      <option
                        key={utilities.status_discontinued}
                        value={utilities.status_discontinued}
                      >
                        {i18next.t("Discontinued")}
                      </option>
                      <option
                        key={utilities.status_modified}
                        value={utilities.status_modified}
                      >
                        {i18next.t("Modified")}
                      </option>
                      <option
                        key={utilities.status_completed}
                        value={utilities.status_completed}
                      >
                        {i18next.t("Completed")}
                      </option>
                    </CSelect>
                  </CCol>}
                  <CCol xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CButton
                      onClick={() => setPrintModal(true)}
                      className="avh-btn text-decoration-none text-white avh-font-bold btn-form"
                    >
                      <CIcon
                        content={cilPrint}
                        className="calls-section-icon"
                      />
                      {i18next.t("Print Prescriptions")}
                    </CButton>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody className="position-relative">
                <CDataTable
                  items={preparedPrescriptionsList}
                  noItemsViewSlot={i18next.t("No Available Items")}
                  fields={fields}
                  striped
                  responsive={true}
                  scopedSlots={{
                    drug: (item) => <td>{item.drug.drug_name}</td>,
                    end_date: (item) => (
                      <td>
                        {item.end_date ? item.end_date : "NA"}
                      </td>
                    ),
                    created_at: (item) => (
                      <td>
                        {item.created_at ? item.created_at.substring(0, 16) : "NA"}
                      </td>
                    ),
                    form: (item) => <td>{item.form}</td>,
                    author: (item) => (
                      <td>
                        {`${item.doctor.first_name} ${item.doctor.last_name}`}
                      </td>
                    ),
                    actions: renderActions,
                  }}
                />
                {episodeStatus === 0 || showCreate ? (
                  <CLink
                    className="d-inline-block avh-btn-add py-3 text-decoration-none text-white avh-font-bold text-uppercase float-right"
                    style={{position:"relative", zIndex:150}}
                    onClick={toggle}
                  >
                    +
                  </CLink>
                ) : (
                  ""
                )}
              </CCardBody>
            </CCol>
          </CRow>
          <PrescriptionPrint
            prescriptionList={preparedPrescriptionsList}
            modal={printModal}
            setModel={setPrintModal}
            label={i18next.t("Print Prescription Preview")}
          />
        </CCardBody>
      </CCard>
    </>
  );
};
export default List;
