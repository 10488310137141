import React, {useRef} from "react";
import { useForm } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CLabel,
  CForm,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CSelect,
  CTextarea,
  CInput,
} from "@coreui/react";
import RenderSelectOptions from "views/partials/RenderSelectOptions";
import SurveyForm from "views/partials/survey-components/SurveyForm";
import i18next from "i18next";
import { getClassName } from "views/pages/register/RegisterFormValidation";
import getValidation from "views/clinics/form/ClinicFormValidation";

const PublicSurveyForm = ({
  onSubmit,
  modal,
  followUp,
  FormTitle,
  onCancel,
  specialties,
  hospitals,
  specialtyForm,
  specialtyFormsList,
  onSpecialtyFormChange,
  onSpecialtyChange,
  onLocationChange,
}) => {
  let defaultValues = {};
  if (followUp) {
    defaultValues = followUp;
  }
  const { register, handleSubmit, setValue, watch, errors } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const Validation = getValidation(watch);
  const ref = useRef(null);
  const saveDraft = () => {
    setValue("draft", 2);
    const buttonRef = ref.current && ref.current.children[0];
    buttonRef.click()
  }

  return modal ? (
    <CForm onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <CModal show={modal} size="lg">
        <h4 className="header-label-title">{FormTitle}</h4>
        <CModalBody>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel className="labels required">{i18next.t("locations")}</CLabel>
              <CSelect
                className={`${getClassName(
                  "locations_id",
                  "Select Location",
                  watch,
                  errors
                )}`}
                custom
                name="locations_id"
                id="locations_id"
                innerRef={register(Validation.locations_id)}
                onChange={onLocationChange}
              >
                <option value="">
                {i18next.t("Select Location")}
                </option>
                {RenderSelectOptions(hospitals)}
              </CSelect>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel className="labels required">{i18next.t("Specialty")} </CLabel>
              <CSelect
                className={`${getClassName(
                  "specialty_id",
                  "Select Specialty",
                  watch,
                  errors
                )}`}
                custom
                name="specialty_id"
                id="specialty_id"
                innerRef={register(Validation.specialty_id)}
                onChange={onSpecialtyChange}
              >
                <option value="" >
                {i18next.t("Select Specialty")}
                </option>
                {RenderSelectOptions(specialties)}
              </CSelect>
              <CLabel className="labels required">{i18next.t("Specialty Form")}</CLabel>
              <CSelect
                className={`${getClassName(
                  "specialty_form_id",
                  "Select Specialty Form",
                  watch,
                  errors
                )}`}
                custom
                name="specialty_form_id"
                id="specialty_form_id"
                innerRef={register(Validation.specialty_form_id)}
                onChange={onSpecialtyFormChange}
              >
                <option value="" >
                {i18next.t("Select Specialty Form")}
                  
                </option>
                {RenderSelectOptions(specialtyFormsList || [])}
              </CSelect>


                
              <CLabel className="float-left labels">{i18next.t("Note")}</CLabel>
              <CTextarea
                name="note"
                id="note"
                rows="3"
                placeholder={i18next.t("Note")}
                innerRef={register}
              />
            </CCol>


          </CFormGroup>
          <SurveyForm
            register={register}
            setValue={setValue}
            watch={watch}
            groups={specialtyForm ? specialtyForm.groups : []}
          />
        </CModalBody>
        <CModalFooter>
          <CInput type="hidden" innerRef={register} name="draft" />
          <CButton type="button" onClick={saveDraft} color="info">
                {i18next.t("Save Draft")}
          </CButton>
          <div ref={ref}>
            <CButton type="submit" color="primary" >
              {i18next.t("Submit")}
            </CButton>
          </div>
          <CButton type="reset" color="danger" onClick={onCancel}>
          {i18next.t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  ) : (
    ""
  );
};
export default PublicSurveyForm;
