import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
  CSpinner,
  CLink,
} from "@coreui/react";
import { END_REQUEST, REJECT_REQUEST } from "actions/requests/types";
import { endRequest } from "actions/requests/requests";
import VideoChat from "views/communications/VideoChat";
import Avatar from "views/partials/Avatar";
import i18next from "i18next";

const UserData = ({ user }) => {
  if (user) {
    return (
      <CCard className="text-dark bg-light" style={{ borderRadius: "15px" }}>
        <CCardBody>
          <CRow>
            <CCol xs="4" md="3">
              <Avatar
                src={user.avatar}
                style={{ width: "80%", height: "80%", borderRadius: "70%" }}
              />
            </CCol>
            <CCol xs="8" md="9">
              <CRow>
                <CCol xs="12">
                  <CCardTitle>
                    {`${user.first_name} ${user.last_name}`}
                  </CCardTitle>
                </CCol>
                <CCol xs="12">
                  <CCardSubtitle>{user.Specialty}</CCardSubtitle>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    );
  }
  return <></>;
};

const Request = () => {
  const dispatch = useDispatch();
  const [startCall, setStartCall] = useState(false);
  const loggedInUser = useSelector((state) => state.auth.jwt.user.id);
  const request = useSelector((state) => state.requests.request);
  const user =
    request.sender_id === loggedInUser ? request.receiver : request.sender;
  const End = () => {
    dispatch(endRequest(request));
  };
  useEffect(() => {
    console.log("request");
    if (request && loggedInUser === request.receiver.id) {
      setStartCall(true);
    }
    window.Echo.join(`request.${request.id}`)
      .joining((user) => {
        if (user && user.id === request.receiver.id) {
          setStartCall(true);
        }
      })
      .error((error) => {
        console.error(error);
      });

    window.Echo.channel(`episode_active_requests.${request.episode_id}`).listen(
      "RejectRequest",
      (eventData) => {
        dispatch({ type: REJECT_REQUEST, payload: eventData.request });
        alert(eventData.note);
      }
    );
  }, [dispatch, loggedInUser, request]);

  if (startCall) {
    return (
      <CCard>
        <CRow>
          <CCol md="12">
            <CCard
              className="text-dark bg-secondary"
              style={{ borderRadius: "15px" }}
            >
              <CCardBody>
                <UserData user={user} />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <VideoChat roomName={`request.${request.id}`} />
        </CRow>
        <CRow className="text-align-center">
          <CCol md="12">
            <CLink onClick={() => End()} className="btn btn-danger">
              {i18next.t("End")}
            </CLink>
          </CCol>
        </CRow>
      </CCard>
    );
  }
  return (
    <CRow className="text-align-center">
      <CCol md="12">
        <CSpinner className="spinner" />
      </CCol>
      <CCol md="12">{i18next.t("Waiting")}...</CCol>
      <CCol md="12">
        <CLink onClick={() => End()} className="btn btn-danger">
          {i18next.t("Cancel")}
        </CLink>
      </CCol>
    </CRow>
  );
};

export default Request;
