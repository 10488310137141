import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import { fetchCity } from "actions/cities/cities";
import i18next from "i18next";

const City = ({ match }) => {
  const dispatch = useDispatch();
  const city = useSelector((state) => state.cities.city);

  useEffect(() => {
    dispatch(fetchCity(match.params.id));
  }, [dispatch, match.params.id]);

  if (city) {
    return (
      <CRow>
        <CCol lg={12}>
          <CCard>
            <h4 className="table-title">{i18next.t("City Details")}</h4>
            <CCardBody>
              <table className="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td className="labels">{i18next.t("Name")}</td>
                    <td>
                      <strong>{city.name}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="labels">{i18next.t("Name In Arabic")}</td>
                    <td>
                      <strong>{city.name_ar}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }
  return (
    <h4 className="text-align-center not-found">
      <strong>{i18next.t("Not Found")}</strong>
    </h4>
  );
};

export default City;
