import React from "react";
import { useSelector } from "react-redux";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CCol,
  CRow,
  CListGroup,
  CListGroupItem,
  CImg,
  CCardTitle,
  CCard,
  CCardBody,
  CCardSubtitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { getAge } from "helpers/helpers";
import logo from "../../../../../../../../logo.png";
import "./Print.css";
import { useLocation } from "react-router-dom";
import i18next from "i18next";

const PrescriptionPrint = ({ prescriptionList, modal, setModel, label }) => {
  const episode = useSelector((state) => state.episodes.episode);
  const location = useLocation();

  const printProcess = () => {
    var elem = document.getElementById("printSection");
    console.log("HERE>>>", elem);
    var domClone = elem.cloneNode(true);

    var $printSection = document.getElementById("printSection");

    if (!$printSection) {
      var $printSection = document.createElement("div");
      $printSection.style.width = "100%";
      $printSection.style.height = "100%";
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
    }

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
  };

  const printPatientDetails = () => {
    return (
      <CCard className="text-theme-blue">
        <h4 className="table-title">{i18next.t("Patient Info")}</h4>
        <CCardBody>
          <CRow>
            <CCol
              CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column"
            >
              <CCardTitle className="avh-font-bold  w-fcontent">
                {i18next.t("Name")} :{" "}
                {`${episode.patient.first_name} ${episode.patient.last_name}`}
              </CCardTitle>
            </CCol>
            <CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column"
            >
              <CCardTitle className="avh-font-bold float-left w-fcontent">
                {i18next.t("Age")} : {getAge(episode.patient.birth_date)}
              </CCardTitle>
            </CCol>
          </CRow>
          <CRow>
            <CCol
              CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column"
            >
              <CCardTitle className="avh-font-bold  w-fcontent">
                {i18next.t("Specialty")} : {episode.specialty.name}
              </CCardTitle>
            </CCol>
            <CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column"
            >
              <CCardTitle
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" avh-font-bold float-left w-fcontent"
              >
                {i18next.t("hospital")} : {episode.spock.name}
              </CCardTitle>
            </CCol>
          </CRow>
          <CRow>
            <CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column hide-for-mobile"
            >
              <CCardTitle className="avh-font-bold float-left w-fcontent">
                {i18next.t("Check In")} : {episode.created_at}
              </CCardTitle>
            </CCol>
            <CCol
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column hide-for-mobile"
            >
              <CCardTitle className="avh-font-bold float-left w-fcontent">
                {i18next.t("Examination")} :{" "}
                {episode.examination
                  ? episode.examination
                  : i18next.t("No Available Examination")}
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    );
  };

  return modal ? (
    <CModal
      show={modal}
      onClose={() => {
        setModel(false);
      }}
      size="xl"
    >
      <h4 className="header-label-title">{label}</h4>
      <CModalBody id="printSection">
        <CListGroup>
          <CImg
            src={process.env.REACT_APP_SERVICE_URL + location.search.split("=")[1] || logo}
            style={{
              display: "block",
              "margin-left": "auto",
              "margin-right": "auto",
              height: "15em",
            }}
          />
          {printPatientDetails()}
          <CCard className="text-theme-blue">
            <h4 className="table-title">{i18next.t("Prescription Details")}</h4>
            <CCardBody>
              {prescriptionList &&
                prescriptionList.map(
                  (item) =>
                    item.status == 2 && (
                      <CListGroupItem>
                        <CRow>
                          <CCol
                            CCol
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="d-flex flex-column"
                          >
                            <CCardTitle className="avh-font-bold  w-fcontent">
                              {i18next.t("Drug Name")} : {item.drug.drug_name}
                            </CCardTitle>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="d-flex flex-column"
                          >
                            <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                              {i18next.t("Dosage")} : {item.dosage}
                            </CCardSubtitle>
                          </CCol>
                          <CCol
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="d-flex flex-column"
                          >
                            <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                              {item.frequency}
                            </CCardSubtitle>
                            <br></br>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="d-flex flex-column"
                          >
                            <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                              {i18next.t("Route")} : {item.route}
                            </CCardSubtitle>
                          </CCol>
                          <CCol
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="d-flex flex-column"
                          >
                            <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                              {item.form}
                            </CCardSubtitle>
                            <br></br>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol
                            className="d-flex flex-column"
                          >
                            <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                              {item.notes}
                            </CCardSubtitle>
                            <br></br>
                          </CCol>
                        </CRow>
                        <CRow className="pb-2">
                          <CCol
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="d-flex flex-column"
                          >
                            <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                              {i18next.t("Creation Date")} :{" "}
                              {item.created_at
                                ? item.created_at
                                : i18next.t("No Specific Date")}
                            </CCardSubtitle>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="d-flex flex-column"
                          >
                            <CCardSubtitle className="avh-font-bold float-left w-fcontent">
                              {i18next.t("End Date")} :{" "}
                              {item.end_date
                                ? item.end_date
                                : i18next.t("No Specific Date")}
                            </CCardSubtitle>
                          </CCol>
                        </CRow>
                      </CListGroupItem>
                    )
                )}
            </CCardBody>
          </CCard>
        </CListGroup>
        <CCardSubtitle>
        {/* TO DO show parent logo
          <CImg
            src={logo}
            style={{
              display: "block",
              "margin-right": "auto",
              height: "5em",
              width: "50%",
            }}
          />
          */}
        </CCardSubtitle>
      </CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={printProcess}>
          <CIcon name="cil-Print" />
          {i18next.t("Print")}
        </CButton>
        <CButton
          type="reset"
          color="danger"
          onClick={() => {
            setModel(false);
          }}
        >
          {i18next.t("Cancel")}
        </CButton>
      </CModalFooter>
    </CModal>
  ) : (
    ""
  );
};

export default PrescriptionPrint;
