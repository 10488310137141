// @flow
import { SET_ERROR, HIDE_ERROR } from "./types";

/**
 * @param {any} error the body of the error
 * @returns {object} action object
 */
export function setError(error: string): Object {
  return {
    type: SET_ERROR,
    error,
  };
}

/**
 * @returns {object} action object
 */
export function hideError(): Object {
  return {
    type: HIDE_ERROR,
  };
}
