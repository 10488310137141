import React from "react";
import {
  CCardBody,
  CCardTitle,
  CRow,
  CCol,
  CCardSubtitle,
  CContainer,
} from "@coreui/react";

import { AppointmentData } from "../../partials/AppointmentData";
import { AppointmentCardContainer } from "../../../partials/partials";

const RenderMedicines = () => {
  const medicines = [
    { id: 1, name: "Panadol", dose: "1 Tab every 12 hours" },
    { id: 2, name: "Panadol", dose: "1 Tab every 12 hours" },
    { id: 3, name: "Panadol", dose: "1 Tab every 12 hours" },
  ];
  return medicines.map((item) => (
    <CRow key={item.id} style={{ marginTop: "1%" }}>
      <CCol md="4">
        <CCardSubtitle>{item.name}</CCardSubtitle>
      </CCol>
      <CCol md="8">
        <CCardSubtitle>{item.dose}</CCardSubtitle>
      </CCol>
    </CRow>
  ));
};

const Prescription = () => (
  <AppointmentCardContainer
    title="Prescriptions"
    appointmentData={AppointmentData}
  >
    <CContainer>
      <CCardBody
        className="text-dark bg-secondary"
        style={{
          marginTop: "0.2%",
          marginBottom: "0.5%",
          paddingTop: "1%",
          borderRadius: "10px",
        }}
      >
        <CRow>
          <CCol md="12">
            <CCardTitle>{AppointmentData.name}</CCardTitle>
          </CCol>
          <CCol md="4">
            <CCardTitle>{AppointmentData.speciality}</CCardTitle>
          </CCol>
          <CCol md="4">
            <CCardSubtitle>{AppointmentData.date}</CCardSubtitle>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="4">
            <CCardTitle>Products</CCardTitle>
          </CCol>
          <CCol md="8">
            <CCardTitle>Dose</CCardTitle>
          </CCol>
        </CRow>
        <RenderMedicines />
        <CRow>
          <CCol md="6">
            <CCardTitle>Notes</CCardTitle>
          </CCol>
          <CCol md="6">
            <CCardTitle>Attachment</CCardTitle>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="">Notes Notes Notes</CCol>
          <CCol md="6">Attachments</CCol>
        </CRow>
        <CRow>
          <CCol md="">Notes Notes Notes</CCol>
          <CCol md="6">Attachments</CCol>
        </CRow>
      </CCardBody>
    </CContainer>
  </AppointmentCardContainer>
);

export default Prescription;
