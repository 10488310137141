import React, { useState } from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardHeader,
  CCallout,
  CCardTitle,
  CCollapse,
  CCardText,
  CCardSubtitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilChevronBottom, cilChevronRight } from "@coreui/icons";

const IcdList = ({ icdList }) => {
  console.log(icdList);
  const [activeCollapse, setActiveCollapse] = useState(null);

  const toggleCollapse = (theCode) => {
    if (activeCollapse === theCode) {
      setActiveCollapse(null);
    } else {
      setActiveCollapse(theCode);
    }
  };

  const removeHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

  const renderMatchingTerms = (matchingTerms) =>
    matchingTerms.map((term) => removeHtmlTags(term.label));

  const renderIcd = (item) => (
    <CCallout key={item.id} className="bg-secondary investigation-card">
      <CRow>
        <CCol md="12" className="mt-1">
          <CCardSubtitle>Title:</CCardSubtitle>
        </CCol>
        <CCol md="12" className="mb-1">{removeHtmlTags(item.title)}</CCol>
        {item.matchingPVs && (
          <>
            <CCol md="12">
              <CCardSubtitle>Matching Terms:</CCardSubtitle>
            </CCol>
            <CCol md="12">
              <CCardText>{renderMatchingTerms(item.matchingPVs)}</CCardText>
            </CCol>
          </>
        )}
      </CRow>
    </CCallout>
  );
  return icdList.map((item) => (
    <CCard key={item.theCode} style={{ border: "0px" }}>
      <CCardHeader
        className="text-dark bg-light icdlist-wrap"
        style={{ marginTop: "1%", borderRadius: "10px" }}
      >
        <CRow>
          <CCol xs="10">
            <CCardTitle>{item.theCode}</CCardTitle>
          </CCol>
          <CCol xs="2">
            <CCardTitle className="text-right">
              {/* <CIcon content={cilChevronBottom}  />
              <CIcon content={cilChevronRight} /> */}

              <CIcon
                name={
                  item.theCode === activeCollapse
                    ? "cil-chevron-bottom"
                    : "cil-chevron-right"
                }
                onClick={() => toggleCollapse(item.theCode)}
                onKeyDown={() => toggleCollapse(item.theCode)}
                role="link"
                tabIndex="0"
                style={{ cursor: "pointer" }}
              />
            </CCardTitle>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCollapse show={item.theCode === activeCollapse}>
        {renderIcd(item)}
      </CCollapse>
    </CCard>
  ));
};
export default IcdList;
