import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CCol,
  CRow,
  CModal,
  CModalFooter,
  CModalBody,
  CCard,
  CCardBody,
  CCardTitle,
  CButton,
} from "@coreui/react";
import {
  fetchLobby,
  patientJoinedLobby,
  patientLeavedLobby,
  listJoinedpatients,
  fetchLobbyAppointments,
} from "actions/lobby/lobby";
import { startWith } from "actions/calls/calls";
import Patients from "views/lobby/components/Patients";
import ChatRooms from "views/communications/chat/ChatRooms";
import AppointmentsList from "views/clinics/screens/components/Patients"; // agenda page patient cards
import moment from "moment";
import PastMedicalHistory from "../episode-management/screens/spoke/cases/tabs/pastMedicalHistory/PastMedicalHistory";
import i18next from "i18next";
import { fetchHospital } from "actions/hospitals/hospitals";
const Lobby = ({ match, hideActions, hideLobby }) => {
  const dispatch = useDispatch();
  const histroy = useHistory();
  // const lobby = useSelector((state) => state.lobby);
  const loggedInUser = useSelector((state) => state.auth.jwt.user.id);
  const lobbyState = useSelector((state) => state.lobby);
  const hospital = useSelector((state) => state.hospitals.hospital);

  const [medicalHistory, setMedicalHistory] = useState(false);
  const [patientId, setPatientId] = useState(false);
  const toggleMedicalHistory  = (patientId) => {
    setPatientId(patientId)
    setMedicalHistory(!medicalHistory);
  };
  const onlinePatients = lobbyState.patients
    ? Object.values(lobbyState.patients)
    : [];
  const onlinePatientsIds = lobbyState.patients
    ? Object.keys(lobbyState.patients)
    : [];
  const totalAppointments = lobbyState.appointments ? lobbyState.appointments : [];

  useEffect(() => {
    if (!lobbyState.lobby) {
      dispatch(fetchLobby(match.params.token));
    } else {
      dispatch(fetchLobbyAppointments(match.params.token));
      window.Echo.join(`lobby.${lobbyState.lobby.id}`)
        .here((users) => {
          dispatch(
            listJoinedpatients(users.filter((item) => item.id !== loggedInUser))
          );
        })
        .joining((user) => {
          dispatch(patientJoinedLobby(user));
        })
        .leaving((user) => {
          dispatch(patientLeavedLobby(user.id));
        })
        .error((error) => {
          console.error(error);
        });
    }
    dispatch(fetchHospital(lobbyState.lobby.clinic.hospital_id));
  }, [dispatch, lobbyState.lobby, loggedInUser, match.params.token]);

  // useEffect(() => {
  //   if(lobbyState){
  //   }
  // }, [dispatch, lobbyState.lobby]);
  const handleStartWith = (patient) => {
    const appointmentId = patient.currentAppointment.id;
    dispatch(startWith(match.params.token, appointmentId));
    dispatch(patientLeavedLobby(patient.id));
    if (hideLobby) {
      hideLobby();
    }
    histroy.push(`/physician/call/${lobbyState.lobby.session_token}`);
  };

  const getOfflineAppointments = () =>
    totalAppointments.filter(
      (appointment) =>
        onlinePatientsIds.indexOf(String(appointment.patient_id)) < 0
    );
  if (lobbyState.lobby) {
    return (
      <>
        <CRow>
          <CCol>
            <CCard
              className="bg-dark-blue text-light"
              style={{ borderRadius: "15px" }}
            >
              <CCardBody>
                <CRow className="text-center">
                  <CCol xs="12">
                    <CCardTitle
                      className="bg-dark"
                      style={{
                        borderRadius: "10px",
                        width: "50%",
                        marginLeft: "25%",
                      }}
                    >
                      {match.params.date}
                    </CCardTitle>
                    <CCardTitle>
                      {`${i18next.t("Total Patients")} ( ${totalAppointments.length} )  -  ${i18next.t("Online")} ( ${onlinePatients.length} )`}
                    </CCardTitle>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs="12">
                    <CCardTitle>
                      {`${i18next.t("From")} ${moment(lobbyState.lobby.from,'HH:mm:ss').format("hh:mm A")} ${i18next.t("To")} ${moment(lobbyState.lobby.to,'HH:mm:ss').format("hh:mm A")}`}
                    </CCardTitle>
                  </CCol>
                </CRow>
                {!hideActions && (
                  <CRow className="align-items-center mt-3">
                    <CCol xs="6">
                      <CButton
                        href={`/physician/call/${lobbyState.lobby.session_token}`}
                        color="success"
                        >
                        {i18next.t("Enter The Room")}
                      </CButton>
                    </CCol>
                    <CCol xs="6">
                      <CButton color="danger" className="mt-0 float-right" >{i18next.t("Cancel")}</CButton>
                    </CCol>
                  </CRow>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <ChatRooms appointments={totalAppointments}/>
        <CModal
          show={medicalHistory}
          onClose={() => setMedicalHistory(false)}
          size="xl"
        >
          <CModalBody>
            {patientId && totalAppointments && <PastMedicalHistory patientId={patientId} patient={totalAppointments.filter((app) => app.patient.id == patientId)[0].patient} hospital={hospital} />}
          </CModalBody>
          <CModalFooter>
              <CButton
                type="reset"
                color="danger"
                onClick={() => setMedicalHistory(false)}
              >

                {i18next.t("Close")}
              </CButton>
          </CModalFooter>
        </CModal>
        <CRow className="justify-content-between align-items-center my-1">
          <CCol xs="12">
            <div className="float-left table-title">
              {i18next.t("Online Patients")}
            </div>
          </CCol>
          <CCol xs="12">
            <Patients
              patients={onlinePatients}
              handleStartWith={handleStartWith}
              startable
              toggleMedicalHistory={toggleMedicalHistory}
            />
          </CCol>
          <CCol xs="12">
            <div className="float-left table-title">
              {i18next.t("Offline Patients")}
            </div>
          </CCol>
          <CCol xs="12">
            <AppointmentsList appointments={getOfflineAppointments()} toggleMedicalHistory={toggleMedicalHistory} />
          </CCol>
        </CRow>
      </>

    );
  }
  return <></>;
};

export default Lobby;
