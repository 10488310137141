import { Api, getTokenHeader } from "services/Api";
import {
  FETCH_VITAL_SIGN_UTILITIES,
  CREATE_VITAL_SIGN,
  FETCH_VITAL_SIGN,
  UPDATE_VITAL_SIGN,
  FETCH_VITAL_SIGNS,
  DELETE_VITAL_SIGN,
} from "./types";

export const fetchVitalSignUtilities = () => async (dispatch) => {
  const response = await Api.get("/api/admin/vital-signs/utilities", {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_VITAL_SIGN_UTILITIES, payload: response.data });
};

export const createVitalSign = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(`/api/admin/vital-signs`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: CREATE_VITAL_SIGN, payload: response.data });
};

export const fetchVitalSign = (id: string) => async (dispatch) => {
  const response = await Api.get(`/api/admin/vital-sign/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_VITAL_SIGN, payload: response.data });
};

export const updateVitalSign = (formData: Object, id: string): Object => async (
  dispatch
) => {
  const response = await Api.put(`/api/admin/vital-signs/${id}`, formData, {
    ...getTokenHeader(),
  });
  dispatch({ type: UPDATE_VITAL_SIGN, payload: response.data });
};

export const fetchVitalSigns = (
  episodeId: String,
  page: string,
  limit: string
): Object => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/episodes/${episodeId}/vital-signs?page=${page}&limit=${limit}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_VITAL_SIGNS, payload: response.data });
};

export const deleteVitalSign = (id: string) => async (dispatch) => {
  await Api.delete(`/api/admin/vital-signs/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_VITAL_SIGN, payload: id });
};
