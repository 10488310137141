import { Api, getTokenHeader } from "services/Api";
import setQueryString from "helpers/helpers";
import {
  CREATE_RADIOLOGY_CENTER,
  FETCH_RADIOLOGY_CENTER,
  UPDATE_RADIOLOGY_CENTER,
  FETCH_RADIOLOGY_CENTERS,
  DELETE_RADIOLOGY_CENTER,
  FETCH_RADIOLOGY_STAFF,
  FETCH_RADIOLOGY_EXCLUDED_USERS,
  DELETE_RAD_MEMBER,
} from "./types";

const prepareRadiologyCenterData = (radiologyCenterData) => {
  const formData = new FormData();
  formData.append("agreement", radiologyCenterData.agreement[0]);
  formData.append("name", radiologyCenterData.name);
  formData.append("phone", radiologyCenterData.phone);
  formData.append("address", radiologyCenterData.address);
  formData.append("description", radiologyCenterData.description);
  formData.append("country_id", radiologyCenterData.country_id);
  formData.append("city_id", radiologyCenterData.city_id);
  formData.append("c_name", radiologyCenterData.c_name);
  formData.append("c_phone", radiologyCenterData.c_phone);
  formData.append("c_email", radiologyCenterData.c_email);
  return formData;
};

const prepareRadiologyMediaData = (radiologyData) => {
  const RadiologyMediaData = new FormData();
  RadiologyMediaData.append("agreement", radiologyData.agreement[0] || '');
  return RadiologyMediaData;
};

export const createRadiologyCenter = (formData: Object): Object => async (
  dispatch
) => {
  const response = await Api.post(
    `/api/admin/radiology-centers`,
    prepareRadiologyCenterData(formData),
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: CREATE_RADIOLOGY_CENTER, payload: response.data });
};

export const fetchRadiologyCenter = (id: string) => async (dispatch) => {
  const response = await Api.get(`/api/admin/radiology-center/${id}`, {
    ...getTokenHeader(),
  });
  dispatch({ type: FETCH_RADIOLOGY_CENTER, payload: response.data });
};

export const updateRadiologyCenter = (
  formData: Object,
  id: string
): Object => async (dispatch) => {
  const response = await Api.put(
    `/api/admin/radiology-centers/${id}`,
    formData,
    {
      ...getTokenHeader(),
    }
  );
  await Api.post(
    `/api/admin/radiology-centers/${id}/update-media`,
    prepareRadiologyMediaData(formData),
    { ...getTokenHeader() }
  );
  dispatch({ type: UPDATE_RADIOLOGY_CENTER, payload: response.data });
};

export const fetchRadiologyCenters = (page, limit, filters = {}) => async (
  dispatch
) => {
  const response = await Api.get(
    `/api/admin/radiology-centers?page=${page}&limit=${limit}${setQueryString(
      filters
    )}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_RADIOLOGY_CENTERS, payload: response.data });
};

export const deleteRadiologyCenter = (id: string) => async (dispatch) => {
  await Api.delete(`/api/admin/radiology-centers/${id}`, {
    ...getTokenHeader(),
  });

  dispatch({ type: DELETE_RADIOLOGY_CENTER, payload: id });
};

export const fetchRadiologyStaff = (id, page = 1) => async (dispatch) => {
  const response = await Api.get(
    `/api/admin/radiology/${id}/staff?page=${page}`,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_RADIOLOGY_STAFF, payload: response.data });
};

export const fetchRadiologyExcudedUsers = (labId, role) => async (dispatch) => {
  const respons = await Api.get(
    `/api/admin/radiology/${labId}/role/${role}/autocomplete`,
    { ...getTokenHeader() }
  );
  dispatch({ type: FETCH_RADIOLOGY_EXCLUDED_USERS, payload: respons.data });
};

export const addRadiologyStaff = (id, formData) => async (dispatch) => {
  const response = await Api.post(
    `/api/admin/radiology/${id}/staff`,
    formData,
    {
      ...getTokenHeader(),
    }
  );
  dispatch({ type: FETCH_RADIOLOGY_STAFF, payload: response.data });
};
export const deleteRadMember = (id: string ,memberId: string) => async (dispatch) => {
  await Api.delete(`/api/admin/radiology/${id}/${memberId}/staff`, {
    ...getTokenHeader(),
  });
  dispatch({ type: DELETE_RAD_MEMBER, payload: memberId });
};
