import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CLink,
} from "@coreui/react";
import {
  fetchPatientsSurveys,
  fetchSurveyUtilities,
} from "actions/patients/patients";
import { RESET_SPECIALTIES_FORM } from "actions/specialties-form/types";
import { fetchFollowUp } from "actions/follow-ups/followUps";
import { LIMIT } from "constants/constants";
import i18next from "i18next";
import Edit from "./Edit";
import Show from "../../../../episode-management/screens/spoke/cases/tabs/followUp/Show";
import FiltersPublicSection from "./form/FiltersPublicSection";
import PreviewSurvey from "../../../../specialties-forms/form/PreviewSurvey";

const SurveyInstances = () => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [surveyInstanceId, setSurveyInstanceId] = useState(null);
  const surveyInstance = useSelector((state) => state.followUps.followUp);
  const showSurveyInstance = (id: string) => {
    if (id) {
      dispatch(fetchFollowUp(id));
      setSurveyInstanceId(id);
      toggleShowModal();
    }
  };
  const editSurveyInstance = (id: string) => {
    if (id) {
      dispatch(fetchFollowUp(id));
      setSurveyInstanceId(id);
      toggleEditModal();
    }
  };
  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  const toggleEditModal = () => {
    setEditModal(!editModal);
    //    dispatch({ type: RESET_SPECIALTIES_FORM });
  };

  const history = useHistory();
  const type = "survey";
  const [modal, setModal] = useState(false);
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [previewSurvey, setPreviewSurvey] = useState(false);
  const dispatch = useDispatch();
  const patientsSurvey = useSelector((state) => state.patients.data);
  const meta = useSelector((state) => state.patients.meta);
  const pageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`/patient/survey-instances?page=${newPage}`);
    }
  };
  const handleDeleteLevel = (id: string) => {
    confirmAlert({
      title: "Are you sure?",
      message: "You Want To Delete This Public Survey.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // dispatch(deleteSpecialtiesForm(id));//patients
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
    dispatch(fetchSurveyUtilities());
    dispatch(fetchPatientsSurveys(page, LIMIT));
  }, [dispatch, currentPage, page]);

  const onFilter = (filters) => {
    dispatch(fetchPatientsSurveys(page, LIMIT, filters));
  };

  const onClosePreview = () => {
    setPreviewSurvey(false);
    dispatch(fetchPatientsSurveys(page, LIMIT));
  };

  return (
    <>
      <Show
        followUp={surveyInstance}
        followUpId={surveyInstanceId}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
      />
      <Edit
        surveyInstance={surveyInstance}
        surveyInstanceId={surveyInstanceId}
        toggle={toggleEditModal}
        modal={editModal}
      />
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-label-title">
            {i18next.t("My Survey Submissions")}
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <FiltersPublicSection onFilter={onFilter} />
            </CCardHeader>

            <CCardBody>
              <CDataTable
                items={patientsSurvey?.data || []}
                fields={[
                  { key: "id", label: i18next.t("id") },
                  { key: "title", label: i18next.t("title") },
                  { key: "specialty", label: i18next.t("specialty") },
                  { key: "hospital", label: i18next.t("hospital") },
                  { key: "date", label: i18next.t("date") },
                  { key: "actions", label: i18next.t("Actions") },
                ]}
                hover
                noItemsViewSlot={i18next.t("No Available Items")}
                striped
                scopedSlots={{
                  title: (item) => <td>{item.survey.title}</td>,
                  specialty: (item) => <td>{item.specialty?.name}</td>,
                  hospital: (item) => <td>{item.survey.hospital.name}</td>,
                  date: (item) => <td>{item.created_at}</td>,
                  actions: (item) => (
                    <td>
                      <CButton
                        onClick={() => showSurveyInstance(item.id)}
                        style={{ marginRight: "5px" }}
                        className="btn btn-primary"
                      >
                        {i18next.t("Preview")}
                      </CButton>
                      {item.editable && (
                        <a
                          onClick={() => editSurveyInstance(item.id)}
                          style={{
                            marginRight: "5px",
                            backgroundColor: "rgb(48, 197, 182)",
                            borderColor: "rgb(48, 197, 182)",
                            color: "white",
                            width: "7em",
                          }}
                          className="btn"
                        >
                          {i18next.t("Edit")}
                        </a>
                      )}
                    </td>
                  ),
                }}
              />
              <CPagination
                activePage={page}
                onActivePageChange={pageChange}
                pages={meta ? meta.last_page : 1}
                doubleArrows={false}
                align="center"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <PreviewSurvey
          surveyId={previewSurvey}
          onCancel={onClosePreview}
          modal={previewSurvey}
        />
      </CRow>
    </>
  );
};

export default SurveyInstances;
