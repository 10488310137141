import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CCardTitle,
  CCardFooter,
  CButton,
} from "@coreui/react";
import { fetchPrescriptions } from "actions/prescriptions/prescriptions";
import { PRESCRIPTION, TYPE_EPISODE, RADS } from "constants/constants";
import i18next from "i18next";
const OldPrescription = ({ setActiveScreen, onCancel, episode }) => {
  const dispatch = useDispatch();
  const prescriptions = useSelector((state) => state.prescriptions.data);
  const fields = [
    { key:"drug", label:  i18next.t("Drug Name") },
    { key:"statusText", label:  i18next.t("status") },
    { key: "dosage", label:  i18next.t("Dosage") },
    { key: "frequency", label:  i18next.t("frequency") },
    { key: "notes", label:  i18next.t("Notes") },
  ];

  useEffect(() => {
    dispatch(fetchPrescriptions(episode.id, TYPE_EPISODE));
  }, [dispatch, episode.id]);

  return (
    <>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="table-title">{i18next.t("Current Prescriptions")}</div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          <CCard>
            <CCardBody>
              <CDataTable
                items={prescriptions}
                noItemsViewSlot={i18next.t("No Available Items")}
                fields={fields}
                striped
                itemsPerPage={50}
                pagination
                scopedSlots={{
                  drug: (item) => <td>{item.drug.drug_name}</td>,
                  form: (item) => <td>{item.form.name}</td>,
                }}
              />
            </CCardBody>
          </CCard>
          <div className="my-2 float-right">
            <CButton
              style={{
                backgroundColor: "#30c5b6",
                borderColor: "#30c5b6",
                color: "white",
                width:"7em"
              }}
              className="mx-1"
              onClick={() => setActiveScreen(RADS)}
            >
              {i18next.t("Previous")}
            </CButton>
            <CButton
              color="primary"
              className="mx-1"
              onClick={() => setActiveScreen(PRESCRIPTION)}
            >
              {i18next.t("Next")}
            </CButton>
            <CButton color="danger" className="mx-1" onClick={onCancel}>
            {i18next.t("Cancel")}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </>
  );
};
export default OldPrescription;
